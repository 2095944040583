import { ClockIcon } from '@components/general/icons/search';
import { useUserContext } from '@context/UserContext';
import { useUpdateUserMutation } from '@gql/generated/generated';
import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { styles } from './style';

interface SuggestionProps {
  suggestion: string;
  icon: React.ReactNode;
  onPress?: () => void;
}

const Suggestion = ({ suggestion, icon, onPress }: SuggestionProps) => (
  <TouchableOpacity style={styles.suggestionTouch} onPress={onPress}>
    {icon}
    <Text style={[styles.title, styles.suggestionText]}>{suggestion}</Text>
  </TouchableOpacity>
);

interface Props {
  onClose: () => void;
  querySuggestions: any[];
}

const EmptySearch = ({ onClose, querySuggestions }: Props) => {
  const navigate = useNavigate();

  const { currentUser } = useUserContext();

  const recentSearches = currentUser?.recentSearches?.slice(0, 3) || [];

  const [updateUser] = useUpdateUserMutation();

  const handleClick = (url: string) => {
    navigate(url);
    onClose();
  };

  const handleClearHistory = () => {
    updateUser({
      variables: {
        input: {
          user: {
            recentSearch: 'CLEAR_SEARCH_HISTORY',
          },
        },
      },
    });
  };

  return (
    <>
      <View style={styles.searchHead}>
        <View style={styles.webTitleWrap}>
          <Text style={styles.title}>Recent Searches</Text>
          {recentSearches.length ? (
            <TouchableOpacity onPress={handleClearHistory}>
              <Text style={styles.clearHistoryText}>Clear History</Text>
            </TouchableOpacity>
          ) : null}
        </View>
        {recentSearches.length ? (
          recentSearches.map((item, index) => (
            <Suggestion key={`${item}${index}`} suggestion={item} icon={<ClockIcon />} onPress={() => handleClick(`/search/results/${item}`)} />
          ))
        ) : (
          <Text style={[styles.subtitle, styles.searchSubtitle]}>You have no recent searches</Text>
        )}
      </View>
      <View style={styles.searchBottom}>
        <View style={styles.webTitleWrap}>
          <Text style={styles.title}>Suggestions</Text>
        </View>
        <Text style={[[styles.subtitle, styles.suggestionSubtitle]]}>Try searching for</Text>
        {querySuggestions.length
          ? querySuggestions
              .slice(0, 3)
              .map((item) => (
                <Suggestion
                  key={item.query}
                  suggestion={item.query}
                  icon={<ClockIcon />}
                  onPress={() => handleClick(`/search/results/${item.query}`)}
                />
              ))
          : null}
        <View style={styles.seeAllWrap}>
          <TouchableOpacity onPress={() => handleClick('/search/suggestions')}>
            <Text style={styles.clearHistoryText}>See all suggestions</Text>
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};

export { EmptySearch };
