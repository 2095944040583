import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const BriefcaseFillIcon = (props: SvgProps) => (
  <Svg width={13} height={12} fill="none" {...props}>
    <Path
      fill="#5C5F62"
      fillRule="evenodd"
      d="m3.986.977-.768 1.69H1.98a1 1 0 0 0-1 1V4.92l.336.168a12.667 12.667 0 0 0 11.33 0l.334-.168V3.667a1 1 0 0 0-1-1h-1.237L9.975.977A1.667 1.667 0 0 0 8.458 0H5.503C4.85 0 4.256.382 3.986.977Zm1.517.356c-.13 0-.25.077-.303.196l-.518 1.138h4.596l-.517-1.138a.333.333 0 0 0-.303-.196H5.503Z"
      clipRule="evenodd"
    />
    <Path
      fill="#5C5F62"
      d="M12.98 6.409a13.992 13.992 0 0 1-5.333 1.335v.923a.667.667 0 0 1-1.333 0v-.923A13.993 13.993 0 0 1 .98 6.409V11a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V6.409Z"
    />
  </Svg>
);

export { BriefcaseFillIcon };
