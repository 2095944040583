import { TableDropdown } from '@components/back-office/tables/table-dropdown';
import { UserType } from '@gql/generated/generated';
import { Checkbox } from '@shopify/polaris';

import { useQueryFilters } from '../useGridFilters';

export const UserTypesFilter = () => {
  const { filters, setFilterValue } = useQueryFilters(['userTypes']);

  const value = filters.userTypes || [];

  const userTypeItems = [
    {
      id: UserType.FREE,
      content: UserType.FREE,
      prefix: <Checkbox label="Free" labelHidden checked={value?.includes(UserType.FREE)} />,
    },
    {
      id: UserType.CLUB,
      content: UserType.CLUB,
      prefix: <Checkbox label="Club" labelHidden checked={value?.includes(UserType.CLUB)} />,
    },
    {
      id: UserType.PLUS,
      content: UserType.PLUS,
      prefix: <Checkbox label="Plus" labelHidden checked={value?.includes(UserType.PLUS)} />,
    },
  ];

  return (
    <TableDropdown
      placeholder={value.length > 0 ? value?.join(', ') : 'User Type'}
      items={userTypeItems}
      onSelectItem={(id) => {
        if (id === UserType.ALL && value?.length === 3) {
          setFilterValue('userTypes', []);
        } else if (id === UserType.ALL) {
          setFilterValue('userTypes', [UserType.FREE, UserType.CLUB, UserType.PLUS]);
        } else if (value?.includes(id as UserType)) {
          setFilterValue(
            'userTypes',
            value.filter((el: string) => el !== id)
          );
        } else {
          setFilterValue('userTypes', [...(value || []), id as UserType]);
        }
      }}
      minWidth={120}
      onReset={value?.length > 0 ? () => setFilterValue('userTypes', []) : undefined}
      isSelected={!!filters.userTypes}
    />
  );
};
