import { ActionList, Popover } from '@shopify/polaris';
import { ActionListItemDescriptor } from '@shopify/polaris/build/ts/latest/src/types';
import React, { useState } from 'react';
import { Platform, StyleProp, Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import RNPopover from 'react-native-popover-view';

import { styles } from './style';
import { DotsButton } from '../dots-button/DotsButton';

const reactNativeOptions = ['Save', 'Assign', 'Share'];

interface Props {
  actionItems:
    | ActionListItemDescriptor[]
    | (
        | {
            content: string;
            prefix?: JSX.Element;
            onAction: () => void;
            helpText?: undefined;
          }
        | {
            helpText: JSX.Element;
            prefix?: JSX.Element;
            onAction: () => Promise<void>;
            content?: undefined;
          }
      )[];
  stylesPopover?: StyleProp<ViewStyle>;
}

const DotsSaveMenu = ({ actionItems, stylesPopover }: Props) => {
  const [showDotsMenu, setShowDotsMenu] = useState(false);
  const DotMenuButton = (
    <DotsButton
      onPress={() => {
        document.body.click();
        setShowDotsMenu(!showDotsMenu);
      }}
    />
  );

  const handleOptionPress = () => {
    setShowDotsMenu(false);
  };

  const actions = actionItems?.map((item) => ({
    ...item,
    onAction: () => {
      item.onAction && item.onAction();
      handleOptionPress();
    },
  }));

  if (Platform.OS === 'web') {
    return (
      <View style={stylesPopover}>
        <Popover
          active={showDotsMenu}
          activator={DotMenuButton}
          onClose={() => setShowDotsMenu(!showDotsMenu)}
          preferredAlignment="right"
          fixed={false}
        >
          <ActionList actionRole="menuitem" items={actions} />
        </Popover>
      </View>
    );
  }

  return (
    <RNPopover
      isVisible={showDotsMenu}
      onRequestClose={() => setShowDotsMenu(false)}
      from={
        <TouchableOpacity onPress={() => setShowDotsMenu(true)}>
          <DotsButton />
        </TouchableOpacity>
      }
    >
      <View style={styles.options}>
        {reactNativeOptions.map((item) => (
          <TouchableOpacity key={item} style={styles.item} onPress={() => new Error('Not implemented')}>
            <View style={styles.leftBorder} />
            <View style={styles.itemContent}>
              <Text style={styles.itemText} ellipsizeMode="tail" numberOfLines={1}>
                {item}
              </Text>
            </View>
          </TouchableOpacity>
        ))}
      </View>
    </RNPopover>
  );
};

export { DotsSaveMenu };
