import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  headerSearch: {
    display: 'flex',
    flexDirection: 'row',
  },
  searchTitle: {
    fontFamily: 'OpenSans_700Bold',
    fontSize: 12,
    lineHeight: 24,
    color: '#6E757C',
    marginTop: 32,
    marginRight: 14,
  },
  searchBar: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    lineHeight: 15,
    color: '#6E757C',
    width: 230,
    height: 36,
    paddingLeft: 32,
    paddingRight: 15,
    paddingVertical: 9,
    borderColor: '#C9CCCF',
    borderWidth: 1,
    borderRadius: 4,
  },
  searchIcon: {
    width: 16,
    height: 16,
    paddingTop: 2,
  },
});
