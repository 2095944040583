import { InputError, Props as InputErrorProps } from '@components/general/form/input-error';
import { Label, Props as LabelProps } from '@components/general/form/label';
import React from 'react';
import { TextInput, TextInputProps, View } from 'react-native';

import { styles } from './style';

export interface Props extends LabelProps, InputErrorProps, TextInputProps {
  onChangeText?: (value: string) => void;
}

const Input = (props: Omit<Props, 'name'>) => {
  const { label, error, isOptional, onChangeText } = props;
  return (
    <View>
      {label ? <Label label={label} isOptional={isOptional} /> : null}
      <TextInput
        {...props}
        style={[styles.input, error ? styles.inputError : null]}
        placeholderTextColor="#6D7175"
        underlineColorAndroid="transparent"
        onChangeText={onChangeText}
      />
      {error && <InputError error={error} />}
    </View>
  );
};

export { Input };
