import { Text, View, Image } from 'react-native';

import { ModalDropx } from './modal-input-dropbox/ModalDropx';
import { styles } from './styles';

interface PromptModalProps {
  input: boolean;
  title: string;
  image?: string | null;
  location?: string;
  reviews?: string;
  categories?: string[];
}

const PromptModalContent = ({ input = false, title, image, location, reviews, categories }: PromptModalProps) => {
  return (
    <View style={[input ? styles.lowerViewInputBorder : null]}>
      <View style={[styles.lowerView, input ? styles.lowerViewAssign : null]}>
        <View style={styles.lowerViewIamgeWrap}>
          <Image source={{ uri: image || '' }} style={styles.lowerViewImage} />
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.lowerViewText}>{title}</Text>
          {location ? <Text style={[styles.lowerViewTextDeregister, input ? styles.lowerViewTextDeregisterAltColor : null]}>{location}</Text> : null}
          {input ? <Text style={styles.lowerViewTextDeregister}>{reviews}</Text> : null}
        </View>
      </View>
      {input ? <ModalDropx categories={categories} /> : null}
    </View>
  );
};

export { PromptModalContent };
