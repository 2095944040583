import { SearchItem } from '@components/search/search-item';
import { Content, Training, Event, Partner } from '@gql/generated/generated';
import dayjs from 'dayjs';
import React from 'react';
import { ActivityIndicator, Linking, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { styles } from './style';
import { formatSubtitle, getLessonCount, roundToHours } from '../shared/SearchItemHelpers';
import type { AlgoliaProps, UserAccessProps } from '../useAlgoliaSearch';

interface WrapperProps {
  title: string;
  children: React.ReactNode;
  isBottomBorderVisible?: boolean;
}

const Wrapper = ({ title, children, isBottomBorderVisible }: WrapperProps) => (
  <View style={[styles.wrapper, isBottomBorderVisible && styles.wrapperBorder]}>
    <View style={styles.titleWrap}>
      <Text style={styles.title}>{title}</Text>
    </View>
    {children}
  </View>
);

interface Props {
  query: string;
  onClose: () => void;
  eventsResults?: (Event & AlgoliaProps & UserAccessProps)[];
  trainingsResults?: (Training & AlgoliaProps & UserAccessProps)[];
  contentResults?: (Content & AlgoliaProps & UserAccessProps)[];
  partnersResults?: (Partner & AlgoliaProps & UserAccessProps)[];
  isLoading: boolean;
}

const ModuleSearch = ({ query, onClose, eventsResults, trainingsResults, contentResults, partnersResults, isLoading }: Props) => {
  const navigate = useNavigate();

  const handlePressSearchItem = (userHasAccess: boolean | undefined, url: string) => {
    // check for undefined userHasAccess in case of some funky alternative algolia search somewhere
    const hasAccess = userHasAccess === true || userHasAccess === undefined;
    if (hasAccess) {
      navigate(url);
    } else {
      Linking.openURL('https://therecruitmentnetwork.com/membership/');
    }
    onClose();
  };

  return (
    <View>
      {isLoading ? (
        <View style={{ padding: 12 }}>
          <ActivityIndicator size="large" color="#213470" />
        </View>
      ) : contentResults && contentResults?.length > 0 ? (
        <Wrapper title="Content" isBottomBorderVisible>
          {contentResults?.map(({ coverImage, objectID, title, publishedAt, categories, description, userHasAccess }) => {
            const formattedDate = dayjs(publishedAt).format('DD MMM YY');
            const href = `content/${objectID}`;
            return (
              <SearchItem
                key={objectID}
                hasPadding
                asset={coverImage}
                title={title || ''}
                date={formattedDate}
                categories={categories?.map((cat: string) => cat.split(':')[0])}
                onPress={() => handlePressSearchItem(userHasAccess, href)}
                href={href}
                {...{ query, description }}
                userHasAccess={userHasAccess}
              />
            );
          })}
        </Wrapper>
      ) : null}
      {eventsResults && eventsResults.length > 0 ? (
        <Wrapper title="Events" isBottomBorderVisible>
          {eventsResults.map(({ dateFrom, objectID, coverImage, title, type, description, userHasAccess }) => {
            const href = `events/${objectID}`;
            const formattedDate = dayjs(dateFrom).format('DD MMM YY • HH:mm');

            return (
              <SearchItem
                key={objectID}
                hasPadding
                asset={coverImage}
                title={title || ''}
                date={formattedDate}
                subtitle={type || ''}
                onPress={() => handlePressSearchItem(userHasAccess, href)}
                href={href}
                {...{ query, description }}
                userHasAccess={userHasAccess}
              />
            );
          })}
        </Wrapper>
      ) : null}

      {trainingsResults && trainingsResults.length > 0 ? (
        <Wrapper title="Training">
          {trainingsResults.map(
            ({
              objectID,
              coverImage,
              title,
              averageStars,
              comments,
              shareCount,
              durationInSeconds,
              modules,
              overview: description,
              userHasAccess,
            }) => {
              const href = `training/${objectID}`;
              return (
                <SearchItem
                  key={objectID}
                  hasPadding
                  asset={coverImage}
                  title={title}
                  date={`${roundToHours(durationInSeconds)} hrs total • ${getLessonCount(modules)} lessons`}
                  isRating
                  subtitle={formatSubtitle({
                    averageStars,
                    comments,
                    shareCount,
                  })}
                  onPress={() => handlePressSearchItem(userHasAccess, href)}
                  href={href}
                  {...{ query, description }}
                  userHasAccess={userHasAccess}
                />
              );
            }
          )}
        </Wrapper>
      ) : null}

      {partnersResults && partnersResults.length > 0 ? (
        <Wrapper title="Partners">
          {partnersResults.map(({ objectID, logo, title, createdAt, userHasAccess }) => {
            const formattedDate = dayjs(createdAt).format('DD MMM YY');
            const href = `marketplace/${objectID}`;
            return (
              <SearchItem
                key={objectID}
                hasPadding
                asset={logo}
                title={title ?? ''}
                date={formattedDate}
                isRating
                onPress={() => handlePressSearchItem(userHasAccess, href)}
                href={href}
                {...{ query }}
                userHasAccess={userHasAccess}
              />
            );
          })}
        </Wrapper>
      ) : null}
      <View style={styles.seeAllWrap}>
        <TouchableOpacity onPress={() => handlePressSearchItem(true, `/search/results/${query}`)}>
          <Text style={styles.seeAllText}>
            See results for <Text style={styles.seeAllTextBold}>{query}</Text>
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export { ModuleSearch };
