import { Recommendation, Roadmap, RoadmapQuestion, Section, FeedbackItem, RoadmapPulseSection, RoadmapPulseQuestion } from '@gql/generated/generated';

const getNonEmptyAnswersPercentage = (questions: RoadmapQuestion[] | RoadmapPulseQuestion[]) => {
  const nonEmptyCount = questions.reduce((count, question) => {
    return count + (question?.answer?.value ? 1 : 0);
  }, 0);

  return ((nonEmptyCount / questions.length) * 100).toFixed(0);
};

const getQuestionAndAnswerCount = (section: Section[] | RoadmapPulseSection[]) =>
  section.reduce(
    (acc, el) => {
      return {
        questionCount: acc.questionCount + el.questions.length,
        answerCount:
          acc.answerCount +
          el.questions.reduce((currentAnswerCount, item) => {
            return item?.answer?.value ? currentAnswerCount + 1 : currentAnswerCount;
          }, 0),
      };
    },
    { questionCount: 0, answerCount: 0 }
  );

const getQuarterDetails = () => {
  const today = new Date();
  const currentQuarter = Math.floor((today.getMonth() + 3) / 3);
  const nextQuarterStartDate = currentQuarter === 4 ? new Date(today.getFullYear() + 1, 1, 1) : new Date(today.getFullYear(), currentQuarter * 3, 1);

  const nextQuarter = currentQuarter === 4 ? 1 : currentQuarter + 1;

  return { nextQuarter, currentQuarter, nextQuarterStartDate };
};

const getCurrentQuarter = () => {
  const today = new Date();
  const currentQuarter = Math.floor((today.getMonth() + 3) / 3);

  return currentQuarter.toString();
};

const getCurrentRoadmapItemsFromData = (roadmap: Roadmap) => {
  if (!roadmap) return {};
  const currentNorthstar = roadmap?.northStar?.find((el) => el.year === new Date().getFullYear().toString()) || roadmap?.northStar?.at(-1);

  const currentGoal =
    roadmap?.goal?.find((el) => el.year === new Date().getFullYear().toString() && el.quarter === getCurrentQuarter()) || roadmap?.goal?.at(-1);

  const currentPulse =
    roadmap?.pulse?.find((el) => el.year === new Date().getFullYear().toString() && el.quarter === getCurrentQuarter()) || roadmap?.pulse?.at(-1);

  return {
    currentNorthstar,
    currentGoal,
    currentPulse,
    pulseSections: currentPulse?.sections,
    goalSections: currentGoal?.sections,
    northStarSections: currentNorthstar?.sections,
  };
};

function getFeedbackForSection(sectionId?: string | null, recommendations?: Recommendation[] | null): FeedbackItem[] {
  if (!recommendations || !sectionId) return [];
  return recommendations
    .filter((r) => r.id.startsWith(sectionId))
    .reduce<FeedbackItem[]>((acc, r: Recommendation) => {
      if (r?.feedback) {
        acc.push(...r.feedback);
      }
      return acc;
    }, []);
}

const getRecommendationsAndFeedbackForPulseResult = (
  sectionId: string | null | undefined,
  score: number,
  recommendations: Recommendation[] | null | undefined
) => {
  const sectionRecommendation = recommendations?.find((recommendation) => sectionId && recommendation.id.startsWith(sectionId));

  const recommendationsForSection = sectionRecommendation?.recommendations || [];

  const [trainingRecommendations, contentRecommendations] = [
    recommendationsForSection?.filter((el) => el?.type === 'trainings'),
    recommendationsForSection?.filter((el) => el?.type === 'content'),
  ];

  const feedbacks = getFeedbackForSection(sectionId, recommendations);
  const feedbackForScore = feedbacks.find((feedback) => feedback.score === score);

  return {
    recommendationsForSection,
    trainingRecommendations,
    contentRecommendations,
    feedbackForScore,
  };
};

export {
  getNonEmptyAnswersPercentage,
  getQuestionAndAnswerCount,
  getQuarterDetails,
  getCurrentQuarter,
  getCurrentRoadmapItemsFromData,
  getRecommendationsAndFeedbackForPulseResult,
};
