import { AssetType } from '@gql/generated/generated';

export const convertMimeTypeToAssetType = (mimeType?: string | null) => {
  if (!mimeType) return AssetType.UNKNOWN;
  if (mimeType.includes('image')) {
    return AssetType.IMAGE;
  }
  if (mimeType.includes('video')) {
    return AssetType.VIDEO;
  }
  if (mimeType.includes('audio')) {
    return AssetType.AUDIO;
  }
  if (mimeType.includes('pdf')) {
    return AssetType.PDF;
  }
  if (mimeType.includes('ms-excel') || mimeType.includes('spreadsheetml')) {
    return AssetType.EXCEL;
  }
  if (mimeType.includes('ms-powerpoint') || mimeType.includes('presentationml')) return AssetType.POWERPOINT;

  if (mimeType.includes('msword') || mimeType.includes('wordprocessingml')) {
    return AssetType.WORD;
  }

  return AssetType.UNKNOWN;
};
