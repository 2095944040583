import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    minHeight: 100,
  },
  input: {
    borderWidth: 1,
    borderColor: '#BABFC3',
    borderRadius: 4,
    height: 36,
    paddingLeft: 12,
    paddingRight: 8,
    paddingTop: 8,
    paddingBottom: 8,
    fontFamily: 'OpenSans_400Regular',
    color: '#0D1738',
    fontSize: 14,
    minHeight: 100,
  },
});
