import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  detailContainer: {
    marginVertical: 16,
  },
  contactTitle: {
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
    lineHeight: 20,
    color: '#0D1738',
    marginBottom: 8,
  },
  contactEmailPhone: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 16,
    lineHeight: 24,
    color: '#202223',
  },
  contactRolLastMember: {
    flexDirection: 'row',
    marginTop: 16,
  },
  contactRolLastMemberCol: {
    flexDirection: 'column',
    flexGrow: 1,
  },
  contactRolLastMemberColTitleDetail: {
    fontSize: 16,
    color: '#202223',
  },
});
