import { AlignLeftIcon, EventIcon, HomeIcon, MarketplaceIcon, RoadmapIcon, TrainingIcon } from '@components/general/icons';
import { SearchIcon } from '@components/general/icons/SearchIcon';
import { BellIcon } from '@components/general/icons/header-icons/BellIcon';
import { TouchableOpacityLink } from '@components/general/touchable-opacity-link';
import { AccountBox } from '@components/navbar/AccountBox';
import { NotificationBox } from '@components/navbar/NotificationBox';
import { NotificationBox as NotificationBoxNative } from '@components/navbar/NotificationBox/NotificationBox.native';
import { SearchBarLayoutContextProvider, useSearchBarLayoutContext } from '@components/search/search-bar-layout-context';
import { useUserContext } from '@context/UserContext';
import { Notification, useCountUnreadNotificationsQuery, useGetNotificationsQuery } from '@gql/generated/generated';
import { useFeatureFlag } from '@hooks/useFeatureFlag';
import { AccountMobile } from '@pages/account/account-mobile';
import { SearchModal } from '@pages/search/search-modal';
import { Popover } from '@shopify/polaris';
import { FEATURE_FLAGS } from '@utils/featureFlags';
import { getNotificationPollInterval, getPopulatedNotification } from '@utils/notificationUtils';
import { userProfileImage } from '@utils/userProfileImage';
import React, { useState, useEffect } from 'react';
import { Image, Platform, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { useLocation, useNavigate } from 'react-router-native';

import { ids, styles } from './style';
import MobileLogo from '../../../assets/Navbar/mobileLogo.png';
import Logo from '../../../assets/img/logo.png';
import { auth } from '../../firebase';

interface NavItemProps {
  icon?: React.ReactNode;
  title?: string;
  onPress?: () => void;
  href?: string;
  active?: boolean;
}

const NavItem = ({ icon, title, onPress, href, active }: NavItemProps) => (
  <TouchableOpacityLink style={styles.wrapToContent} onPress={onPress} href={href}>
    {icon}
    <Text style={[styles.navTitle, active && { color: '#0D1738' }]}>{title}</Text>
    {active && <View style={styles.navActiveBottom} />}
  </TouchableOpacityLink>
);

type NavbarProps = {
  userRole: 'admin' | 'user';
  isSignedIn: boolean;
  isVerified: boolean;
};

export const Navbar = (props: NavbarProps) => (
  <SearchBarLayoutContextProvider>
    <_Navbar {...props} />
  </SearchBarLayoutContextProvider>
);

const _Navbar = ({ userRole, isSignedIn, isVerified }: NavbarProps) => {
  const { currentUser } = useUserContext();
  const [query, setQuery] = useState('');
  const [isSearchModalVisible, setIsSearchModalVisible] = useState(false);
  const [isAccountMenuVisible, setIsAccountMenuVisible] = useState(false);
  const [isNotificationMenuVisible, setIsNotificationMenuVisible] = useState(false);
  const [isAccountMobileVisible, setIsAccountMobileVisible] = useState(false);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { searchBarRef, onSearchBarLayout } = useSearchBarLayoutContext();
  const [unreadNotificationsOnly, setUnreadNotificationsOnly] = useState(false);
  const isRoadmapEnabled = useFeatureFlag([FEATURE_FLAGS.ROADMAP]);

  const handleOnFocus = () => {
    setIsHovered(true);
    setIsSearchModalVisible(true);
  };

  useEffect(() => {
    setIsAccountMenuVisible(false);
    setIsNotificationMenuVisible(false);
  }, [pathname]);

  const getColorForCurrentPath = (itemPath: string) => (pathname === itemPath ? '#0D1738' : '#5C5F62');

  const { data: notificationsRaw, refetch: refetchNotifications } = useGetNotificationsQuery({
    variables: {
      userId: currentUser?.id || '',
      limit: 10,
      unreadOnly: unreadNotificationsOnly,
    },
    skip: !currentUser,
    pollInterval: getNotificationPollInterval(),
  });

  const { data: unreadNotificationData, refetch } = useCountUnreadNotificationsQuery({
    variables: {
      userId: currentUser?.id || '',
    },
    skip: !currentUser,
    pollInterval: getNotificationPollInterval(),
  });

  const notifications =
    notificationsRaw && Array.isArray(notificationsRaw?.getNotifications) ? notificationsRaw.getNotifications.filter((el) => el !== null) : [];

  const unreadCount =
    unreadNotificationData?.countUnreadNotifications?.__typename === 'Count' ? unreadNotificationData.countUnreadNotifications.count : 0;
  const hasUnreadNotifications = !!unreadCount;

  useEffect(() => {
    if (currentUser) {
      refetchNotifications();
    }
  }, [currentUser, refetchNotifications, unreadNotificationsOnly]);

  if (!isSignedIn) {
    return (
      <View style={styles.unAuthenticatedContainer} dataSet={{ media: ids.unAuthenticatedContainer }}>
        <TouchableOpacity onPress={() => navigate('/')}>
          <Image source={Logo} style={styles.unAuthLogo} dataSet={{ media: ids.logo }} />
        </TouchableOpacity>
      </View>
    );
  } else if (isVerified) {
    return (
      <View style={styles.wrap}>
        <View style={[styles.inner, userRole === 'user' ? { maxWidth: 1204 } : null]}>
          <View
            style={[styles.mainContainer, userRole === 'admin' ? { paddingLeft: 42, paddingRight: 24 } : null]}
            dataSet={{ media: ids.mainContainer }}
          >
            <TouchableOpacity onPress={() => navigate('/')}>
              <Image source={Logo} style={styles.logo} dataSet={{ media: ids.logo }} />
              <Image source={MobileLogo} style={styles.mobileLogo} dataSet={{ media: ids.mobileLogo }} />
            </TouchableOpacity>
            {userRole === 'user' && (
              <View
                style={[styles.searchContainer, isHovered && styles.searchContainerHover]}
                dataSet={{ media: ids.searchContainer }}
                onLayout={onSearchBarLayout}
                ref={searchBarRef}
              >
                <SearchIcon />
                <TextInput
                  style={styles.searchbar}
                  dataSet={{ media: ids.searchbar }}
                  placeholder="Search for anything"
                  onFocus={() => handleOnFocus()}
                  onBlur={() => setIsHovered(false)}
                  onChangeText={(query) => setQuery(query)}
                  onSubmitEditing={({ nativeEvent: { text: query } }) => {
                    navigate(`/search/results/${query}`);
                    setIsSearchModalVisible(false);
                  }}
                  value={query}
                />
              </View>
            )}
            {userRole === 'user' && (
              <View style={styles.rightContainer}>
                <View style={styles.menuWrap} dataSet={{ media: ids.menuWrap }}>
                  <View style={styles.menuItem}>
                    <NavItem
                      icon={<HomeIcon color={getColorForCurrentPath('/')} />}
                      title="Feed"
                      href="/"
                      onPress={() => navigate('/')}
                      active={pathname === '/'}
                    />
                  </View>
                  <View style={styles.menuItem} dataSet={{ media: ids.navbtn }}>
                    <NavItem
                      icon={<AlignLeftIcon color={getColorForCurrentPath('/content')} />}
                      title="Content"
                      href="/content"
                      onPress={() => navigate('/content')}
                      active={pathname === '/content'}
                    />
                  </View>
                  <View style={styles.menuItem}>
                    <NavItem
                      icon={<EventIcon color={getColorForCurrentPath('/events')} />}
                      title="Events"
                      href="/events"
                      onPress={() => navigate('/events')}
                      active={pathname === '/events'}
                    />
                  </View>
                  <View style={styles.menuItem}>
                    <NavItem
                      icon={<TrainingIcon color={getColorForCurrentPath('/training')} />}
                      title="Training"
                      href="/training"
                      onPress={() => navigate('/training')}
                      active={pathname === '/training'}
                    />
                  </View>
                  <View style={styles.menuItem}>
                    <NavItem
                      icon={<MarketplaceIcon color={getColorForCurrentPath('/marketplace')} />}
                      title="Partners"
                      href="/marketplace"
                      onPress={() => navigate('/marketplace')}
                      active={pathname === '/marketplace'}
                    />
                  </View>
                  {isRoadmapEnabled && (
                    <View style={styles.menuItem}>
                      <NavItem
                        icon={<RoadmapIcon color={getColorForCurrentPath('/roadmap')} />}
                        title="Roadmap"
                        href="/roadmap"
                        onPress={() => navigate('/roadmap')}
                        active={pathname === '/roadmap'}
                      />
                    </View>
                  )}
                </View>
                <View style={styles.profileWrap}>
                  {Platform.OS === 'web' ? (
                    <Popover
                      active={isNotificationMenuVisible}
                      activator={
                        <TouchableOpacity
                          style={[styles.notification, styles.userTouch]}
                          onPress={() => {
                            setIsAccountMenuVisible(false);
                            setIsNotificationMenuVisible(!isNotificationMenuVisible);
                          }}
                        >
                          {hasUnreadNotifications ? <View style={styles.redDot} /> : null}
                          <BellIcon />
                        </TouchableOpacity>
                      }
                      onClose={() => setIsNotificationMenuVisible(false)}
                      preferredAlignment="right"
                    >
                      <NotificationBox
                        notifications={notifications as any as Notification[]}
                        userId={currentUser?.id || ''}
                        onClose={() => setIsNotificationMenuVisible(false)}
                        refetchFn={refetch}
                        unreadFn={setUnreadNotificationsOnly}
                      />
                    </Popover>
                  ) : (
                    <TouchableOpacity
                      style={[styles.notification, styles.userTouch]}
                      onPress={() => {
                        setIsAccountMenuVisible(false);
                        setIsNotificationVisible(true);
                      }}
                    >
                      <View style={styles.redDot} />
                      <BellIcon />
                    </TouchableOpacity>
                  )}
                  {/* TODO: For quick testing sign-in/out. Should be removed when designs for dropdown are updated */}
                  {Platform.OS === 'web' ? (
                    <Popover
                      active={isAccountMenuVisible}
                      activator={
                        <TouchableOpacity
                          style={styles.notification}
                          onPress={() => {
                            setIsNotificationMenuVisible(false);
                            setIsAccountMenuVisible(!isAccountMenuVisible);
                          }}
                        >
                          <Image source={userProfileImage(currentUser)} style={styles.avatar} />
                        </TouchableOpacity>
                      }
                      onClose={() => setIsAccountMenuVisible(false)}
                      preferredAlignment="right"
                    >
                      <AccountBox />
                    </Popover>
                  ) : (
                    <TouchableOpacity
                      style={styles.notification}
                      onPress={() => {
                        setIsNotificationMenuVisible(false);
                        setIsAccountMobileVisible(true);
                      }}
                    >
                      <Image source={userProfileImage(currentUser)} style={styles.avatar} />
                    </TouchableOpacity>
                  )}
                </View>
              </View>
            )}
            {userRole === 'admin' && (
              <View style={styles.adminWrapper} dataSet={{ media: ids.adminWrapper }}>
                <Text style={styles.welcomeMsg}>Welcome, {currentUser?.accountInfo?.firstName}</Text>
                <TouchableOpacityLink style={[styles.wrapToContentAdmin]} href="/settings" onPress={() => navigate('/settings')}>
                  <Image source={userProfileImage(currentUser)} style={styles.avatar} />
                </TouchableOpacityLink>
              </View>
            )}
          </View>
        </View>
        <SearchModal query={query} isVisible={isSearchModalVisible} onClose={() => setIsSearchModalVisible(false)} />
        <AccountMobile isVisible={isAccountMobileVisible} onClose={() => setIsAccountMobileVisible(false)} />
        <NotificationBoxNative isVisible={isNotificationVisible} onClose={() => setIsNotificationVisible(false)} />
      </View>
    );
  } else {
    return null;
  }
};
