import { Text, TouchableOpacity, View } from 'react-native';

import { styles } from './style';

interface Props {
  tabs: string[];
  onAction: (id: string) => void;
  activeTab: string;
}

export const GenericTabs = ({ tabs, onAction, activeTab }: Props) => {
  return (
    <View style={styles.tabsContainer}>
      {tabs.map((tab, index) => (
        <TouchableOpacity key={index} style={styles.tabButton} onPress={() => onAction(tab)}>
          <Text style={activeTab === tab ? styles.tabButtonTextActive : styles.tabButtonText}>{tab}</Text>
          {activeTab === tab ? <View style={styles.tabButtonActive} /> : null}
        </TouchableOpacity>
      ))}
    </View>
  );
};
