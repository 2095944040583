import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  downloadList: {
    position: 'absolute',
    backgroundColor: 'white',
    top: 40,
    borderRadius: 8,
    filter: 'drop-shadow(0px 1px 2px rgba(0,0,0,0.15))',
    overflow: 'hidden',
    padding: 8,
    maxWidth: '100%',
  },
  downloadContainer: {
    position: 'relative',
    zIndex: 10,
    maxWidth: '100%',
  },
  buttonStyle: {
    ...(Platform.OS === 'web' && {
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 12,
      paddingRight: 16,
    }),
    backgroundColor: 'white',
    width: 'fit-content',
  },
  actionList: {
    ':hover': {
      backgroundColor: '#F2F7FE',
    },
    padding: 8,
    backgroundColor: 'inherit',
    maxWidth: '100%',
  },
  downloadActivatorStyle: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 8,
  },
  saveButtonText: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    color: '#202223',
    lineHeight: 20,
    marginLeft: 4,
    whiteSpace: 'nowrap',
  },
});
