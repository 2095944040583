import { mediaQuery } from '@utils/misc';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
    paddingTop: 20,
    paddingBottom: 40,
  },
  topicsBox: {
    backgroundColor: '#F9F9F9',
    padding: 20,
    width: '100%',
  },
  topicBoxTitle: {
    color: '#0D1738',
    fontSize: 16,
    marginBottom: 24,
    fontFamily: 'OpenSans_600SemiBold',
  },
  topicItem: {
    flexDirection: 'row',
  },
  dot: {
    height: 4,
    width: 4,
    borderRadius: 2,
    backgroundColor: '#202223',
    marginTop: 8,
  },
  checkIcon: {
    marginTop: 8,
  },
  topicItemsWrap: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  eachItem: {
    width: '48%',
  },
  topicItemText: {
    color: '#202223',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    marginLeft: 12,
    flex: 1,
  },
  topicItemTextMargin: {
    marginBottom: 8,
  },
  detailText: {
    marginTop: 20,
    marginBottom: 20,
    color: '#0D1738',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
  },
  splitTopicWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    [mediaQuery.forMobile]: {
      flexDirection: 'column',
    },
  },
  eachTopic: {
    width: '48%',
    [mediaQuery.forMobile]: {
      width: '100%',
      marginBottom: 8,
    },
  },
});
