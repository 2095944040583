import ResetButton from '@components/back-office/tables/table-header/ResetButton';
import { Button, DatePicker, Popover, Range } from '@shopify/polaris';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import { View } from 'react-native';

import { styles } from './style';

const today = new Date();

const hoursOfDay: string[] = []; // Results will go here
const midnight = new Date(new Date().setHours(0, 0, 0, 0)).getHours();

// Loop from current hour number to 23
for (let i = midnight; i < 24; i++) {
  const hours = i < 10 ? '0' + i : i;
  hoursOfDay.push(hours + ':00');
  hoursOfDay.push(hours + ':30'); // Put loop counter into array with "00" next to it
}

interface FormikDatePickerDropdownProps {
  placeholderText: string;
  onChange: (date: Range) => void;
  dateValue?: number | null;
  onlyFutureDates?: boolean;
  disabled?: boolean;
  onReset?: () => void;
}

const FormikDatePickerDropdown = ({ dateValue, placeholderText, onChange, onlyFutureDates, disabled, onReset }: FormikDatePickerDropdownProps) => {
  const [showDateFrom, setShowDateFrom] = React.useState(false);

  const [{ pickerMonth, pickerYear }, setDate] = useState({
    pickerMonth: today.getMonth(),
    pickerYear: today.getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: today,
    end: today,
  });

  const handleMonthChange = useCallback((pickerMonth: any, pickerYear: any) => setDate({ pickerMonth, pickerYear }), []);

  return (
    <Popover
      active={showDateFrom}
      activator={
        <Button disclosure="select" fullWidth onClick={() => setShowDateFrom(!showDateFrom)} disabled={disabled} monochrome={!!dateValue}>
          {dateValue ? dayjs(new Date(dateValue)).format('D MMM YY') : placeholderText}
        </Button>
      }
      onClose={() => setShowDateFrom(!showDateFrom)}
    >
      <ResetButton onReset={onReset} />
      <View style={styles.wrapper}>
        <DatePicker
          month={pickerMonth}
          year={pickerYear}
          onChange={(v: Range) => {
            onChange(v);
            setSelectedDates(v);
            setShowDateFrom(false);
          }}
          onMonthChange={handleMonthChange}
          selected={selectedDates}
          disableDatesBefore={onlyFutureDates ? today : undefined}
        />
      </View>
    </Popover>
  );
};

export { FormikDatePickerDropdown };
