import { ButtonGeneral, ButtonVariant } from '@components/general/button-general';
import { CrossIcon } from '@components/general/icons';
import React from 'react';
import { Modal, Platform, TouchableOpacity, View } from 'react-native';

import { styles } from './style.web';

interface JoinEventModalProps {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  calendlyLink?: string | null;
  googleFormLink?: string | null;
}

const JoinEventModalWeb = ({ isVisible, setIsVisible, calendlyLink, googleFormLink }: JoinEventModalProps) => {
  return (
    <Modal transparent visible={isVisible} onRequestClose={() => setIsVisible(false)}>
      <TouchableOpacity style={styles.outerContainer} onPressIn={() => setIsVisible(false)}>
        <View style={styles.innerContainer}>
          <ButtonGeneral onPress={() => setIsVisible(false)} variant={ButtonVariant.Secondary} style={styles.crossButton}>
            <CrossIcon />
          </ButtonGeneral>
          {Platform.OS === 'web' && calendlyLink ? <iframe src={calendlyLink} width="100%" frameBorder="0" style={styles.iframe} /> : null}
          {Platform.OS === 'web' && googleFormLink ? <iframe src={googleFormLink} width="100%" frameBorder="0" style={styles.iframe} /> : null}
        </View>
      </TouchableOpacity>
    </Modal>
  );
};

export { JoinEventModalWeb };
