import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  textInput: {
    borderWidth: 1,
    borderColor: '#D1D5DB',
    borderRadius: 8,
    paddingVertical: 5,
    paddingHorizontal: 5,
  },
  label: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 9.4,
    lineHeight: 12,
    color: '#6B7280',
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    marginLeft: 13,
    marginTop: -5,
    paddingHorizontal: 5,
  },
});
