import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  buttonStyle: {
    width: Platform.OS === 'web' ? '100%' : '100%',
    ...(Platform.OS === 'web' && {
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 12,
      paddingRight: 12,
      marginBottom: 8,
    }),
  },
  saveButtonText: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    color: '#202223',
    lineHeight: 20,
    marginLeft: 4,
    whiteSpace: 'nowrap',
  },

  plusIcon: {
    width: 12,
    height: 12,
    marginRight: 8,
  },

  savedText: {
    color: '#1653CE',
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 20,
  },
});
