import { CaretUp } from '@components/general/icons/account-icons/CaretUp';
import { ContentBox } from '@components/general/layouts/content-box';
import { Text, View } from 'react-native';

import { styles } from './style';

interface Props {
  title: string;
  statisticData: string;
  footerTextCard: string;
  isCompletion?: boolean;
  isLastCheck?: boolean;
  isNextReminder?: boolean;
  isNorthStartRemainder?: boolean;
  isCurrentScore?: boolean;
}

export const StatisticCard = ({
  title,
  statisticData,
  footerTextCard,
  isCompletion,
  isLastCheck,
  isNextReminder,
  isNorthStartRemainder,
  isCurrentScore,
}: Props) => {
  return (
    <ContentBox style={{ padding: 20, flex: 1, marginRight: 16 }}>
      <Text style={styles.mainTitle}>{title}</Text>

      <Text style={styles.statisticText}>{statisticData}</Text>

      {isCompletion && <Text style={styles.footerTextCard}>{`On ${footerTextCard}`}</Text>}
      {isLastCheck && <Text style={styles.footerTextCard}>{`Next Check ${footerTextCard}`}</Text>}
      {isNextReminder && isNorthStartRemainder && (
        <View style={{ flexDirection: 'row' }}>
          <Text style={[styles.footerTextCard, { marginRight: 4, color: '#D72C0D' }]}>{footerTextCard}</Text>
          <Text style={styles.footerTextCard}>to complete</Text>
        </View>
      )}
      {isNextReminder && !isNorthStartRemainder && <Text style={styles.footerTextCard}>{`${footerTextCard}`}</Text>}
      {isCurrentScore && (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <CaretUp fill="#23827F" />
          <Text style={[styles.textGreenBold]}>{footerTextCard}</Text>
          <Text style={styles.textRegularGrey}> than last check</Text>
        </View>
      )}
    </ContentBox>
  );
};
