import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  icon: { width: 12, height: 12 },
  options: {
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 8,
    shadowColor: 'rgba(0, 0, 0, 0.15)',
    shadowRadius: 4,
    shadowOffset: { height: 4, width: 0 },
    shadowOpacity: 0.5,
    width: 160,
  },
  item: {
    backgroundColor: '#FFFFFF',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 36,
    borderRadius: 8,
  },
  leftBorder: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    marginLeft: 5,
    height: '100%',
    width: 3,
  },
  selectedLeftBorder: {
    backgroundColor: '#2C6ECB',
  },
  itemContent: {
    height: '100%',
    borderRadius: 4,
    width: '97%',
    justifyContent: 'center',
    paddingLeft: 8,
  },
  itemText: {
    fontSize: 14,
    color: '#202223',
    width: '100%',
  },
  savedText: {
    color: '#1653CE',
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 20,
  },
  spinnerContainer: {
    marginTop: 4,
  },
});
