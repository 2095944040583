import StyleSheet from 'react-native-media-query';

export const { styles } = StyleSheet.create({
  resultsFooter: {
    alignItems: 'center',
    borderTop: '2px solid #E4E5E7',
    backgroundColor: '#FAFBFB',
    height: 52,
    justifyContent: 'center',
    textAlign: 'center',
  },
  subduedText: {
    color: '#6D7175',
  },
});
