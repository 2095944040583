import { JobTitle, useGetJobTitlesQuery } from '@gql/generated/generated';
import { useFormikContext } from 'formik';

import { ButtonLayout } from '../../../profile/button-layout/ButtonLayout';
import { DropMenuFormik } from '../../../profile/form-elements/drop-menu-formik/DropMenuFormik';
import { InputFormikContainer } from '../../../profile/form-elements/input-formik/InputFormikContainer';

interface formProps {
  closeEditMode: () => void;
}

const JobForm = ({ closeEditMode }: formProps) => {
  const { handleSubmit } = useFormikContext();
  const { data } = useGetJobTitlesQuery();

  const jobTitles = data?.getJobTitles?.jobTitles?.map((jobTitle: JobTitle) => ({
    value: jobTitle.name,
  }));

  const submitCloseHandler = () => {
    handleSubmit();
    closeEditMode();
  };

  return (
    <>
      <InputFormikContainer label="Job Title">
        <DropMenuFormik formikKey="jobPosition" placeholder="Job Role" data={jobTitles || []} />
      </InputFormikContainer>
      <ButtonLayout closeEditMode={closeEditMode} submitCloseHander={submitCloseHandler} />
    </>
  );
};

export { JobForm };
