import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  radioBorder: {
    width: 20,
    height: 20,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: '#8C9196',
    alignItems: 'center',
    justifyContent: 'center',
  },
  active: {
    borderColor: '#2C6ECB',
  },
  inner: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: '#2C6ECB',
  },
  title: {
    color: '#0D1738',
    fontSize: 16,
    fontFamily: 'OpenSans_400Regular',
    marginLeft: 8,
  },
  radioItem: {
    width: '33%',
  },
  multipleRadio: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    ...(Platform.OS === 'web' && { rowGap: 14 }),
  },
});
