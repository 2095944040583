import { GridPage } from '@components/back-office/grid/GridPage';
import { useQueryFilters } from '@components/back-office/grid/useGridFilters';
import { APP_URL } from '@env';
import { ContentType, Status, useAdminGetAllContentQuery, useDeleteContentMutation, useUpsertContentMutation } from '@gql/generated/generated';
import { IndexTableHeading } from '@shopify/polaris/build/ts/latest/src/components/IndexTable';
import { NonEmptyArray } from '@shopify/polaris/build/ts/latest/src/types';
import { textStyles } from '@styles/text';
import { getUniqueCategories, pastTensify, truncateString } from '@utils/misc';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Linking, Text, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { DeleteConfirmationModal } from '../../../components/back-office/editor/delete-confirmation-modal';

const CONTENT_ITEMS_PER_PAGE = 11;

const activeFilters = ['search', 'status', 'dateFrom', 'dateTo', 'categories', 'userTypes', 'contentTypes'];

export const ContentBackOffice = () => {
  const navigation = useNavigate();
  const [contentIdToBeDeleted, setContentIdToBeDeleted] = useState<string | undefined>();

  const { page, filters } = useQueryFilters(activeFilters);

  const {
    data: queryResult,
    loading,
    refetch,
  } = useAdminGetAllContentQuery({
    variables: {
      page,
      limit: CONTENT_ITEMS_PER_PAGE,
      filters,
    },
  });

  const data = queryResult?.adminGetAllContent?.content;
  const totalItems = queryResult?.adminGetAllContent?.total;
  const hasNextPage = queryResult?.adminGetAllContent?.hasNextPage;

  const tableHeadings: NonEmptyArray<IndexTableHeading> = [
    { title: 'Title' },
    { title: 'Author' },
    { title: 'Category' },
    { title: 'Last modified' },
    { title: 'User Type' },
    { title: 'Status' },
    { title: '' },
  ];

  const [deleteContent] = useDeleteContentMutation({
    update: (store, _result, options) => {
      const id = options?.variables?.ids[0];
      if (id) {
        const normalizedId = store.identify({
          id,
          __typename: `${ContentType.CONTENT}`,
        });
        store.evict({ id: normalizedId });
        store.gc();
      }
    },
  });
  const [archiveContent] = useUpsertContentMutation({
    update: (store, _result, options) => {
      const itemId = options?.variables?.input?.id;
      if (itemId) {
        store.modify({
          id: `${ContentType.CONTENT}:${itemId}`,
          fields: {
            status() {
              return Status.ARCHIVE;
            },
          },
        });
      }
    },
  });

  const handleMenuItem = async (itemId: string, optionID: string) => {
    switch (optionID) {
      case 'View':
        return Linking.openURL(`${APP_URL}/content/${itemId}` as string);
      case 'Edit':
        return navigation(`/content-editor/${itemId}`, {
          state: {
            title: 'Edit Content',
            id: itemId,
          },
        });
      case 'Archive':
        await archiveContent({
          variables: {
            input: {
              id: itemId,
              status: Status.ARCHIVE,
            },
          },
        });
        break;
      case 'Delete':
        setContentIdToBeDeleted(itemId);
        break;
      default:
        break;
    }
  };

  const handlePressDelete = async () => {
    if (contentIdToBeDeleted) {
      await deleteContent({ variables: { ids: [contentIdToBeDeleted] } });
    }
  };

  const rows = data?.length
    ? data.map((item) => {
        return {
          id: item.id,
          title: (
            <View style={{ width: 350, paddingVertical: '8px' }}>
              <Text style={textStyles.semiBoldSmall}>{item.title ?? '-'}</Text>
            </View>
          ),
          author: item.authorName,
          categories: truncateString(getUniqueCategories(item.categories).join(', '), 20),
          date: dayjs(item.updatedAt ?? item.publishedAt).format('DD/MM/YYYY'),
          userType: item.userType?.join(', '),
          status: pastTensify(item.status),
        };
      })
    : [];
  return (
    <>
      <DeleteConfirmationModal
        title="Are you sure you want to delete this content?"
        onClose={() => setContentIdToBeDeleted(undefined)}
        onDeleteConfirm={async () => {
          await handlePressDelete();
          setContentIdToBeDeleted(undefined);
        }}
        visible={!!contentIdToBeDeleted}
      />
      <GridPage
        title="Content"
        menuItems={[
          { id: 'View', content: 'View' },
          { id: 'Edit', content: 'Edit' },
          { id: 'Archive', content: 'Archive' },
          { id: 'Delete', content: 'Delete' },
        ]}
        newLink={{
          url: '/content-editor',
          title: 'Add New Content',
          label: 'Add Content',
        }}
        activeFilters={activeFilters}
        rows={rows}
        loading={loading}
        tableHeadings={tableHeadings}
        onBulkAction={() => refetch()}
        onMenuItem={handleMenuItem}
        pagination={{
          perPage: CONTENT_ITEMS_PER_PAGE,
          page,
          totalItems,
          hasNextPage,
        }}
      />
    </>
  );
};
