import { NotificationSwitch } from '@components/account/notification-switch';
import { ButtonGeneral } from '@components/general/button-general';
import { IndexPageHeader } from '@components/general/index-page-header';
import { Wrapper } from '@components/general/layouts/wrapper/Wrapper';
import React, { useState } from 'react';
import { Switch, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { styles } from './style';

enum ItemDuration {
  seven_days = '7 days',
  fourteen_days = '14 days',
  thirty_days = '30 days',
}

interface RadioProps {
  isSelected?: boolean;
  onSelect?: () => void;
}
const Radio = ({ isSelected, onSelect }: RadioProps) => (
  <TouchableOpacity style={[styles.radioBorder, isSelected && styles.active]} onPress={onSelect}>
    {isSelected && <View style={styles.inner} />}
  </TouchableOpacity>
);

interface RadioBoxProps {
  onChange?: () => void;
  title: string;
  isSelected?: boolean;
  type: 'switch' | 'radio';
}

const RadioBox = ({ onChange, title, type, isSelected }: RadioBoxProps) => (
  <TouchableOpacity style={styles.radio} onPress={onChange}>
    <Text style={styles.text}>{title}</Text>
    {type === 'radio' ? (
      <Radio isSelected={isSelected} onSelect={onChange} />
    ) : (
      <Switch value={isSelected} onChange={onChange} trackColor={{ true: '#2C6ECB' }} />
    )}
  </TouchableOpacity>
);

const SavedItems = () => {
  const navigate = useNavigate();
  const [notificationValues, setNotificationValues] = useState({
    events: false,
    feed: false,
    training: false,
    feedDuration: ItemDuration.seven_days,
    eventDuration: ItemDuration.thirty_days,
  });

  return (
    <Wrapper>
      <View style={styles.wrap}>
        <View>
          <IndexPageHeader title="Saved Items" onPressBack={() => navigate(-1)} />
          <View style={styles.itemWrap}>
            <RadioBox
              title="Feed"
              type="switch"
              isSelected={notificationValues.feed}
              onChange={() =>
                setNotificationValues({
                  ...notificationValues,
                  feed: !notificationValues.feed,
                })
              }
            />
            <RadioBox
              title={ItemDuration.seven_days}
              type="radio"
              isSelected={notificationValues.feedDuration === ItemDuration.seven_days}
              onChange={() =>
                setNotificationValues({
                  ...notificationValues,
                  feedDuration: ItemDuration.seven_days,
                })
              }
            />
            <RadioBox
              title={ItemDuration.fourteen_days}
              type="radio"
              isSelected={notificationValues.feedDuration === ItemDuration.fourteen_days}
              onChange={() =>
                setNotificationValues({
                  ...notificationValues,
                  feedDuration: ItemDuration.fourteen_days,
                })
              }
            />
            <RadioBox
              title={ItemDuration.thirty_days}
              type="radio"
              isSelected={notificationValues.feedDuration === ItemDuration.thirty_days}
              onChange={() =>
                setNotificationValues({
                  ...notificationValues,
                  feedDuration: ItemDuration.thirty_days,
                })
              }
            />
          </View>
          <View style={styles.itemWrap}>
            <RadioBox
              title="Events"
              type="switch"
              isSelected={notificationValues.events}
              onChange={() =>
                setNotificationValues({
                  ...notificationValues,
                  events: !notificationValues.events,
                })
              }
            />
            <RadioBox
              title={ItemDuration.seven_days}
              type="radio"
              isSelected={notificationValues.eventDuration === ItemDuration.seven_days}
              onChange={() =>
                setNotificationValues({
                  ...notificationValues,
                  eventDuration: ItemDuration.seven_days,
                })
              }
            />
            <RadioBox
              title={ItemDuration.fourteen_days}
              type="radio"
              isSelected={notificationValues.eventDuration === ItemDuration.fourteen_days}
              onChange={() =>
                setNotificationValues({
                  ...notificationValues,
                  eventDuration: ItemDuration.fourteen_days,
                })
              }
            />
            <RadioBox
              title={ItemDuration.thirty_days}
              type="radio"
              isSelected={notificationValues.eventDuration === ItemDuration.thirty_days}
              onChange={() =>
                setNotificationValues({
                  ...notificationValues,
                  eventDuration: ItemDuration.thirty_days,
                })
              }
            />
          </View>
          <NotificationSwitch
            title="Training"
            isActive={notificationValues.training}
            onChange={() =>
              setNotificationValues({
                ...notificationValues,
                training: !notificationValues.training,
              })
            }
          />
        </View>
        <View style={styles.buttonWrap}>
          <ButtonGeneral label="Save" />
        </View>
      </View>
    </Wrapper>
  );
};

export { SavedItems };
