import { Button, TextField } from '@shopify/polaris';
import { textStyles } from '@styles/text';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Text, View } from 'react-native';

import { styles } from './style';

interface FormikListProps {
  fieldName: string;
  title: string;
  placeholder: string;
  initialNumberOfItems: number;
  subtitle?: string;
}

interface ListItemProps {
  placeholder: string;
  fieldName: string;
  index: number;
}

const ListItem = ({ placeholder, fieldName, index }: ListItemProps) => {
  const { setFieldValue, values, setFieldTouched } = useFormikContext<any>();

  const handleTextFieldChange = (value: string) => setFieldValue(`${fieldName}[${index}]`, value);

  const handleClearButtonClick = () => setFieldValue(`${fieldName}[${index}]`, '');

  return (
    <View style={styles.itemContainer}>
      <TextField
        label=""
        labelHidden
        value={values[fieldName][index]}
        onChange={handleTextFieldChange}
        clearButton
        onClearButtonClick={handleClearButtonClick}
        autoComplete="off"
        placeholder={placeholder}
        onFocus={() => setFieldTouched(fieldName, true)}
      />
    </View>
  );
};

const FormikList = ({ fieldName, title, subtitle, initialNumberOfItems, placeholder }: FormikListProps) => {
  const [additionalItems, setAdditionalItems] = useState(0);
  const { getFieldMeta } = useFormikContext<any>();

  const listErrors = getFieldMeta(fieldName).error as unknown as string | string[];

  const items = Array.from(Array(initialNumberOfItems + additionalItems));

  return (
    <>
      <Text style={[textStyles.semiBoldSmall, { marginBottom: subtitle ? 4 : 16 }]}>{title}</Text>
      {subtitle ? <Text style={[textStyles.subduedTextMedium, { marginBottom: 16 }]}>{subtitle}</Text> : null}
      <View>
        {items.map((_, index) => {
          return <ListItem placeholder={placeholder} fieldName={fieldName} index={index} key={index} />;
        })}
      </View>
      {listErrors ? <Text style={textStyles.error}>{typeof listErrors === 'string' ? listErrors : listErrors?.filter(Boolean)[0]}</Text> : null}
      <View
        style={{
          marginTop: 16,
        }}
      >
        <Button onClick={() => setAdditionalItems(additionalItems + 1)}>+ Add More</Button>
      </View>
    </>
  );
};

export { FormikList };
