import { mediaQuery } from '@utils/misc';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  optionsContainerDatePicker: {
    width: '300px',
    height: 'auto',
    overflow: 'hidden',
    padding: '20px',
  },
  optionsContainer: { width: 'max-content' },
  icon: { height: 20, width: 20, marginRight: 5 },
  filterText: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 20,
    color: '#202223',
    marginTop: 2,
  },
  addFilterButtonWrap: {
    width: 'auto',
    [mediaQuery.forMobile]: {
      width: '100%',
    },
  },
  filterRow: {
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
    ...({ gap: '8px' } as any),
    [mediaQuery.forMobile]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  addFilterText: {
    fontSize: 14,
    color: '#202223',
    fontFamily: 'OpenSans_600SemiBold',
    marginLeft: 4,
  },
  activatorItemWrap: {
    marginTop: 0,
    [mediaQuery.forMobile]: {
      marginTop: 8,
    },
  },
});
