import { ButtonGeneral, ButtonVariant } from '@components/general/button-general';
import { SubmitListener } from '@components/general/submit-listener-formik';
import { useEmployerGetRoadmapQuery } from '@gql/generated/generated';
import { getCurrentRoadmapItemsFromData } from '@pages/roadmap/utils';
import { Button, Popover } from '@shopify/polaris';
import { Form, Formik } from 'formik';
import { Dispatch, useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { Categories } from './Categories';
import { Subcategories } from './Subcategories';
import { formatPulseCategoriesAsActionListItems, formatSubcategoriesAsActionListItems } from './utils';

type Props = {
  selectedSubcategories: string[];
  setSelectedSubcategories: Dispatch<string[]>;
  placeholderText?: string;
  withSlimButton?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
};

export const CategoryButton = ({
  selectedSubcategories = [],
  setSelectedSubcategories,
  placeholderText = 'Category',
  withSlimButton,
  isSelected,
  disabled = false,
}: Props) => {
  const [displayCategorySelector, setDisplayCategorySelector] = useState(false);

  const { data: { employerGetRoadmap: roadmapData } = {}, loading: loadingRoadmap } = useEmployerGetRoadmapQuery();

  const { currentPulse } = (roadmapData && getCurrentRoadmapItemsFromData(roadmapData)) || {};

  const [selectedCategory, setSelectedCategory] = useState('');

  const PlainButton = (
    <Button fullWidth textAlign="left" onClick={() => setDisplayCategorySelector(!displayCategorySelector)} monochrome={isSelected}>
      {placeholderText}
    </Button>
  );

  const SlimButton = (
    <View>
      <ButtonGeneral
        style={{
          backgroundColor: '#233876',
          padding: '8px, 16px, 8px, 12px',
          minWidth: 113,
        }}
        variant={ButtonVariant.Secondary}
        onPress={() => setDisplayCategorySelector(!displayCategorySelector)}
      >
        <Text
          style={{
            fontSize: 14,
            color: '#fff',
            fontFamily: 'OpenSans_600SemiBold',
            marginLeft: 4,
          }}
        >
          {placeholderText}
        </Text>
      </ButtonGeneral>
    </View>
  );

  const initialValues: { [key: string]: boolean } = Object.fromEntries(selectedSubcategories.map((subcategoryId) => [subcategoryId, true]));

  const categoryItems = formatPulseCategoriesAsActionListItems({
    sections: currentPulse?.sections || [],
    setSelectedCategory,
  });

  const selectedSubcategoryItems = [
    ...(formatSubcategoriesAsActionListItems(currentPulse?.sections.find(({ id }) => id === selectedCategory)?.questions || []) || []),
  ];

  return (
    <TouchableOpacity
      style={{
        backgroundColor: '#FFFFFF',
        height: 36,
      }}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values) => {
          const selected = Object.entries(values)
            .filter(([_, value]) => value)
            .map(([key]) => key);
          setSelectedSubcategories(selected);
        }}
      >
        {(formik) => (
          <Popover
            active={displayCategorySelector}
            activator={withSlimButton ? SlimButton : PlainButton}
            onClose={() => {
              setDisplayCategorySelector(!displayCategorySelector);
              setSelectedCategory('');
            }}
            preferredAlignment="right"
          >
            <Form>
              <SubmitListener formik={formik} />
              {!selectedCategory && (
                <Categories
                  {...{
                    formik,
                    setSelectedSubcategories,
                    items: categoryItems,
                  }}
                />
              )}
              {selectedCategory && (
                <Subcategories
                  {...{
                    setSelectedCategory,
                    selectedSubcategories,
                    setFieldValue: formik.setFieldValue,
                    items: selectedSubcategoryItems,
                    disabled,
                  }}
                />
              )}
            </Form>
          </Popover>
        )}
      </Formik>
    </TouchableOpacity>
  );
};
