import { LeftArrowTwo } from '@components/general/icons';
import { ContentBox } from '@components/general/layouts/content-box';
import { Wrapper } from '@components/general/layouts/wrapper/Wrapper';
import React from 'react';
import { Dimensions, Text, TouchableOpacity, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { ids, styles } from './style';

interface WrapProps {
  children: React.ReactNode;
  isKeyboardAvoidingView?: boolean;
}

const Wrap = ({ children, isKeyboardAvoidingView }: WrapProps) => {
  if (isKeyboardAvoidingView) {
    return <KeyboardAwareScrollView>{children}</KeyboardAwareScrollView>;
  } else {
    return <>{children}</>;
  }
};

interface Props extends WrapProps {
  onPressBack?: () => void;
  title?: string;
  subtitle?: string;
}

const AuthWrapper = ({ children, onPressBack, title, subtitle, isKeyboardAvoidingView }: Props) => (
  <Wrapper style={styles.wrap} dataSetMedia={ids.wrap}>
    <Wrap isKeyboardAvoidingView={isKeyboardAvoidingView}>
      <View style={styles.backTouchWrap} dataSet={{ media: ids.backTouchWrap }}>
        {onPressBack ? (
          <TouchableOpacity onPress={onPressBack} style={styles.backTouch}>
            <LeftArrowTwo />
          </TouchableOpacity>
        ) : null}
      </View>
      <View style={styles.contentBoxWrap}>
        <ContentBox disableShadow={Dimensions.get('window').width < 768} style={styles.contentBox} dataSetMedia={ids.contentBox}>
          <View>
            {title ? (
              <Text style={styles.title} dataSet={{ media: ids.title }}>
                {title}
              </Text>
            ) : null}
            {subtitle ? (
              <Text style={styles.subtitle} dataSet={{ media: ids.subtitle }}>
                {subtitle}
              </Text>
            ) : null}
            {children}
          </View>
        </ContentBox>
      </View>
    </Wrap>
  </Wrapper>
);

export { AuthWrapper };
