import { ActionedRecommendation, RecommendedContentOrTraining } from '@gql/generated/generated';

export const getRecommendationsPercentageComplete = (
  sectionId: string,
  recommendationsForScore: RecommendedContentOrTraining[],
  actionedRecommendations: ActionedRecommendation[]
) => {
  const numCompleteRecommendations =
    recommendationsForScore.filter((recommendation) => isActionedRecommendationComplete(sectionId, recommendation, actionedRecommendations)).length ??
    0;
  if (recommendationsForScore.length === 0) {
    return 0;
  }
  return Math.floor((numCompleteRecommendations / recommendationsForScore.length) * 100);
};

export const isActionedRecommendationComplete = (
  sectionId: string,
  recommendation: RecommendedContentOrTraining,
  actionedRecommendations: ActionedRecommendation[]
) => {
  return actionedRecommendations.some(
    (actionedRecommendation) =>
      actionedRecommendation.id === recommendation.itemId &&
      actionedRecommendation.sectionId === sectionId &&
      actionedRecommendation.status === 'Complete'
  );
};
