import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { styles } from './style';

interface Props {
  isSelected?: boolean;
  title?: string;
  onSelect?: () => void;
  content?: React.ReactNode;
}

const FilterRadioButton = ({ isSelected, title, onSelect, content }: Props) => (
  <TouchableOpacity style={styles.wrap} onPress={onSelect}>
    <View style={[styles.radioBorder, isSelected && styles.active]}>{isSelected && <View style={styles.inner} />}</View>
    {content ? content : <Text style={styles.title}>{title}</Text>}
  </TouchableOpacity>
);

export { FilterRadioButton };
