import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  container: {},
  cellContainer: {
    paddingVertical: 16,
    paddingLeft: 16,
    width: '33.3%',
  },
  headerText: {
    fontSize: 12,
    color: '#6D7175',
    fontFamily: 'OpenSans_600SemiBold',
    lineHeight: 20,
    paddingLeft: 16,
    width: '33.3%',
  },
  dataText: {
    fontSize: 14,
    color: '#202223',
    fontFamily: 'OpenSans_400Regular',
    lineHeight: 20,
  },
});
