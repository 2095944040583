import { SideBar } from '@components/back-office/side-bar';
import { LogoutPopupBack } from '@components/popup/LogoutPopupBack';
import { SettingsCreatedPopup } from '@components/popup/SettingsCreatedPopup';
import { ScoreAssociationsProvider } from '@context/ScoreAssociations';
import { ToastContextProvider } from '@context/ToastContext';
import { AnalyticsContent } from '@pages/back-office/analytics-back-office/analytics-content';
import { AnalyticsEvent } from '@pages/back-office/analytics-back-office/analytics-event';
import { AnalyticsGeneral } from '@pages/back-office/analytics-back-office/analytics-general';
import { AnalyticsTraining } from '@pages/back-office/analytics-back-office/analytics-training';
import { AnalyticsWrapper } from '@pages/back-office/analytics-back-office/analytics-wrapper';
import { AnalyticsViewRequest } from '@pages/back-office/analytics-back-office/anlytics-view-request';
import { Frame } from '@shopify/polaris';
import { UpdateContext } from '@utils/UpdateContext';
import { FEATURE_FLAGS } from '@utils/featureFlags';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Platform, TouchableOpacity, View } from 'react-native';
import { Route, Routes } from 'react-router-native';

import { ContentBackOffice } from './content-back-office';
import { ContentEditor } from './content-back-office/content-editor/ContentEditor';
import { EventsBackOffice } from './events-back-office';
import { EventsEditor } from './events-back-office/events-editor/EventsEditor';
import { HelpBackOffice } from './help-back-office';
import { MarketplaceBackOffice } from './marketplace-back-office';
import { PartnersEditor } from './marketplace-back-office/partners-editor/PartnersEditor';
import { PollsBackOffice } from './polls-back-office';
import { PollEditor } from './polls-back-office/poll-editor';
import { PollResults } from './polls-back-office/poll-results';
import { PostsBackOffice } from './posts-back-office';
import { PostEditor } from './posts-back-office/post-editor';
import { RoadmapBackOffice } from './roadmap-back-office';
import { ScoreAssociations } from './roadmap-back-office/score-associations/ScoreAssociations';
import { ScoreAssociationsEdit } from './roadmap-back-office/score-associations-edit/ScoreAssociationsEdit';
import { AddEditRecommendations } from './roadmap-back-office/score-ranking';
import { ScoreRanking } from './roadmap-back-office/score-ranking/ScoreRanking';
import { ViewRoadMapLayout } from './roadmap-back-office/view-roadmap-layout';
import { SettingsBackOffice } from './settings-back-office';
import { styles } from './style';

// Pages import

import { TrainingsBackOffice } from './trainings-back-office';
import { TrainingsEditor } from './trainings-back-office/trainings-editor';
import { UsersBackOffice } from './users-back-office';
import { UsersEditor } from './users-back-office/users-editor';
import { MembershipRequest } from './users-back-office/users-membership-request';
import LogoWhite from '../../../assets/img/LogoWhite.png';
import { RouteFeatureFlag } from '../../RouteFeatureFlag';

interface WrapProps {
  children: React.ReactNode;
}

const Wrap = ({ children }: WrapProps) =>
  Platform.OS === 'web' ? (
    <Frame
      logo={{
        width: 179,
        contextualSaveBarSource: LogoWhite,
      }}
    >
      {children}
    </Frame>
  ) : (
    <>{children}</>
  );

export const BackOffice = () => {
  const [indexSelected, setIndexSelected] = useState(0);
  const [logout, setLogout] = useState(false);
  const [isPopUpBackSettings, setIsBackSettings] = useState(false);
  const [isPopUpBackPassword, setIsBackPassword] = useState(false);

  const hasUpdated = useContext(UpdateContext);

  return (
    <Wrap>
      <Helmet>
        <title>TRN World Back Office</title>
      </Helmet>
      <View style={styles.wrapper}>
        {(isPopUpBackSettings || isPopUpBackPassword) && (
          <View
            style={{
              zIndex: 2,
              position: 'absolute',
              marginHorizontal: '35%',
              marginVertical: '15%',
            }}
          >
            <SettingsCreatedPopup
              props={{
                setIsBackSettings,
                isPopUpBackSettings,
                isSignedIn: true,
                isPopUpBackPassword,
                setIsBackPassword,
              }}
            />
          </View>
        )}
        {logout && (
          <View
            style={{
              zIndex: 2,
              position: 'absolute',
              marginHorizontal: '35%',
              marginVertical: '15%',
            }}
          >
            <LogoutPopupBack logout={logout} setLogout={setLogout} />
          </View>
        )}
        <TouchableOpacity
          onPress={() => {
            if (isPopUpBackSettings) setIsBackSettings(false);
            if (isPopUpBackPassword) setIsBackPassword(false);
            if (logout) setLogout(false);
          }}
          style={
            (isPopUpBackSettings || isPopUpBackPassword || logout) && {
              backgroundColor: 'rgba(112, 116, 121, 0.78)',
              width: '100%',
              height: '100%',
              position: 'absolute',
              zIndex: 1,
            }
          }
        />
        <View style={styles.container}>
          <View style={[styles.pageWrapper]}>
            <SideBar indexSelected={indexSelected} setIndexSelected={setIndexSelected} setLogout={setLogout} />
            <View
              style={[
                {
                  marginTop: !hasUpdated.state ? -15 : 5,
                },
                {
                  marginLeft: 254,
                  marginTop: 55,
                  padding: 24,
                  paddingTop: 20,
                  // width: Dimensions.get("window").width - 254,
                  flex: 1,
                },
              ]}
            >
              <ToastContextProvider>
                <Routes>
                  <Route path="/" element={<ContentBackOffice />} />
                  <Route path="/content" element={<ContentBackOffice />} />
                  <Route path="/events" element={<EventsBackOffice />} />
                  <Route path="/training" element={<TrainingsBackOffice />} />
                  <Route path="/posts" element={<PostsBackOffice />} />
                  <Route path="/polls" element={<PollsBackOffice />} />
                  <Route path="/marketplace" element={<MarketplaceBackOffice />} />
                  <Route path="/marketplace/add-partner" element={<PartnersEditor />} />
                  <Route path="/marketplace/:id" element={<PartnersEditor />} />
                  {/* TODO: Users back office */}
                  <Route path="/users" element={<UsersBackOffice />} />
                  <Route path="/Roadmap" element={<RoadmapBackOffice />} />
                  <Route path="/Roadmap/:id" element={<ViewRoadMapLayout />} />
                  <Route path="/Roadmap/:id/:plan" element={<ViewRoadMapLayout />} />

                  <Route
                    path="/Roadmap/score-recommendations"
                    element={
                      <RouteFeatureFlag
                        component={
                          <ScoreAssociationsProvider>
                            <ScoreAssociations />
                          </ScoreAssociationsProvider>
                        }
                        featureFlags={[FEATURE_FLAGS.ROADMAP_PULSE_SCORE_ASSNS]}
                      />
                    }
                  />
                  <Route
                    path="/Roadmap/score-recommendations/edit/:categoryId/:subcategoryId/:score"
                    element={
                      <RouteFeatureFlag
                        component={
                          <ScoreAssociationsProvider>
                            <ScoreAssociationsEdit />
                          </ScoreAssociationsProvider>
                        }
                        featureFlags={[FEATURE_FLAGS.ROADMAP_PULSE_SCORE_ASSNS]}
                      />
                    }
                  />

                  <Route path="/Roadmap/score-ranking" element={<ScoreRanking />} />
                  <Route path="/Roadmap/score-ranking/add-edit-content/:categoryId/:subcategoryId/:score" element={<AddEditRecommendations />} />
                  <Route path="/users/member-request" element={<MembershipRequest />} />

                  <Route path="/user-editor" element={<UsersEditor />} />
                  <Route path="/user-editor/:id" element={<UsersEditor />} />
                  <Route path="/roadmap" element={<RoadmapBackOffice />} />
                  <Route
                    path="/settings"
                    element={<SettingsBackOffice setIsBackPassword={setIsBackPassword} setIsBackSettings={setIsBackSettings} />}
                  />
                  <Route path="/help" element={<HelpBackOffice />} />
                  <Route path="/content-editor" element={<ContentEditor />} />
                  <Route path="/content-editor/:id" element={<ContentEditor />} />

                  <Route path="/trainings-editor" element={<TrainingsEditor />} />
                  <Route path="/trainings-editor/:id" element={<TrainingsEditor />} />
                  <Route path="/events-editor" element={<EventsEditor />} />
                  <Route path="/events-editor/:id" element={<EventsEditor />} />
                  <Route path="/post-editor" element={<PostEditor />} />
                  <Route path="/post-editor/:id" element={<PostEditor />} />
                  <Route path="/poll-editor" element={<PollEditor />} />
                  <Route path="/poll-editor/:id" element={<PollEditor />} />
                  <Route path="/poll-results/:id" element={<PollResults />} />
                  <Route path="/user-editor" element={<UsersEditor />} />
                  <Route path="/analytics" element={<AnalyticsWrapper />}>
                    <Route path="" element={<AnalyticsGeneral />} />
                    <Route path="/analytics/general" element={<AnalyticsGeneral />} />
                    <Route path="/analytics/content" element={<AnalyticsContent />} />
                    <Route path="/analytics/events" element={<AnalyticsEvent />} />
                    <Route path="/analytics/training" element={<AnalyticsTraining />} />
                    <Route path="/analytics/:type/:id" element={<AnalyticsViewRequest />} />
                  </Route>
                </Routes>
              </ToastContextProvider>
            </View>
          </View>
          <View style={styles.footer} />
        </View>
      </View>
    </Wrap>
  );
};
