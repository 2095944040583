import React from 'react';
import { Text, View } from 'react-native';

import { styles } from './style';

export enum PillVariant {
  Light,
  DarkGrey,
  DarkBlue,
  GreyBlue,
  Outline,
  Gold,
  Slim,
  LightBlue,
  Green,
  Red,
  Highlight,
  SurfaceSuccess,
}

interface PillProps {
  variant?: PillVariant;
  text?: string | null;
  children?: React.ReactNode;
  withMarginRight?: boolean;
  isCategory?: boolean;
  /**
   * Allow the pill to shrink in a flex layout
   */
  allowShrink?: boolean;
  isMatch?: boolean;
}

const getPillBackgroundFromVariant = (variant: PillVariant) => {
  switch (variant) {
    case PillVariant.Light:
      return styles.lightGrey;
    case PillVariant.DarkGrey:
      return styles.darkGrey;
    case PillVariant.DarkBlue:
      return styles.darkBlue;
    case PillVariant.GreyBlue:
      return styles.greyBlue;
    case PillVariant.Outline:
      return styles.outline;
    case PillVariant.Gold:
      return styles.gold;
    case PillVariant.Slim:
      return styles.slim;
    case PillVariant.LightBlue:
      return styles.lightBlue;
    case PillVariant.Green:
      return styles.green;
    case PillVariant.Red:
      return styles.red;
    case PillVariant.Highlight:
      return styles.highlight;
    case PillVariant.SurfaceSuccess:
      return styles.surfaceSuccess;
    default:
      return styles.lightGrey;
  }
};

const Pill = ({ variant = PillVariant.Light, text, children = null, withMarginRight, isCategory, allowShrink, isMatch }: PillProps) => (
  <View
    style={[
      styles.pill,
      getPillBackgroundFromVariant(variant),
      withMarginRight ? styles.withMarginRight : null,
      allowShrink ? styles.allowShrink : null,
      { maxWidth: isCategory ? 92 : 'fit-content' },
    ]}
  >
    {children}
    {text ? (
      <Text
        numberOfLines={1}
        ellipsizeMode="tail"
        style={[
          styles.text,
          variant === PillVariant.DarkBlue ||
          variant === PillVariant.Highlight ||
          variant === PillVariant.Gold ||
          variant === PillVariant.LightBlue ||
          variant === PillVariant.Green ||
          variant === PillVariant.Red
            ? styles.lightText
            : undefined,
          variant === PillVariant.Slim && styles.slimText,
          isMatch && {
            fontFamily: 'OpenSans_700Bold',
          },
        ]}
      >
        {text}
      </Text>
    ) : null}
  </View>
);

export { Pill };
