import { useUserContext } from '@context/UserContext';
import { ListType, SavedItemType, SavedListAction, useGetUserAssignmentsQuery, useUpdateSavedListMutation } from '@gql/generated/generated';

const useAssignment = () => {
  const { currentUser } = useUserContext();
  const {
    data: userAssignments,
    loading: loadingUserAssignments,
    refetch: refetchUserAssignments,
  } = useGetUserAssignmentsQuery({
    skip: !currentUser?.id,
    variables: {
      input: {
        limit: 9,
        page: 0,
        type: SavedItemType.ALL,
      },
    },
  });

  const currentUserAssignments = userAssignments?.getUserAssignments?.assignedItems;
  const eventItems = currentUserAssignments?.filter((item) => item.type === 'Events');
  const contentItems = currentUserAssignments?.filter((item) => item.type === 'Content');
  const trainingItems = currentUserAssignments?.filter((item) => item.type === 'Trainings');

  const [updateSavedList] = useUpdateSavedListMutation();

  const handleCompletedStatus = async (id: string, type: string) => {
    await updateSavedList({
      variables: {
        input: {
          action: SavedListAction.COMPLETE,
          item: {
            id,
            type,
          },
          listType: ListType.ASSIGNED_ITEMS,
        },
      },
    });
    await refetchUserAssignments();
  };

  const removeAssignments = async (id: string, type?: string | null) => {
    if (!type) {
      return console.error('type is required!');
    }
    await updateSavedList({
      variables: {
        input: {
          action: SavedListAction.REMOVE,
          item: {
            id,
            type,
          },
          listType: ListType.ASSIGNED_ITEMS,
        },
      },
    });
    await refetchUserAssignments();
  };

  return {
    currentUser,
    userAssignments,
    loadingUserAssignments,
    refetchUserAssignments,
    currentUserAssignments,
    eventItems,
    contentItems,
    trainingItems,
    handleCompletedStatus,
    removeAssignments,
  };
};

export { useAssignment };
