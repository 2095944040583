import { pluralize } from './misc';

const fromSecondsToHours = (time: number) => Math.ceil((time || 0) / 60 / 60);

const fromSecondsToNearestHalfHour = (time: number) => {
  const hours = Math.floor(time / 3600);
  const mins = time % 3600;
  if (mins > 44) {
    return `${hours + 1} ${pluralize(hours + 1, 'hr')}`;
  } else if (mins > 15) {
    return `${hours}.5 ${pluralize(hours, 'hr')}`;
  } else {
    return `${hours} ${pluralize(hours, 'hr')}`;
  }
};

export const fromSecondsToHoursOrMins = (time?: number) => {
  if (!time) return `0 mins`;
  const mins = Math.ceil(time / 60);
  const roundedHours = fromSecondsToHours(time);
  if (mins <= 59) {
    return `${mins} ${pluralize(mins, 'min')}`;
  } else {
    return `${roundedHours} ${pluralize(roundedHours, 'hr')}`;
  }
};

export const fromMinutesToNearestHalfDay = (mins: number) => {
  if (mins === 0) {
    return 'N/A';
  } else if (mins < 60 * 12) {
    return '< 0.5 days';
  } else {
    return `${Math.round((mins / 60 / 24) * 2) / 2} days`;
  }
};

export { fromSecondsToHours, fromSecondsToNearestHalfHour };
