import { RoadmapDataTable } from '@components/back-office/roadmap/data-table';
import { TableDropdown } from '@components/back-office/tables/table-dropdown';
import { ContentBox } from '@components/general/layouts/content-box';
import { useState } from 'react';
import { Text, View } from 'react-native';

import { styles } from './style';

const quarters = [
  { id: 'Q1', content: 'Q1' },
  { id: 'Q2', content: 'Q2' },
  { id: 'Q3', content: 'Q3' },
  { id: 'Q4', content: 'Q4' },
];
const years = [
  { id: '2023', content: '2023' },
  { id: '2022', content: '2022' },
  { id: '2021', content: '2021' },
  { id: '2020', content: '2020' },
  { id: '2019', content: '2019' },
];

const headers = { status: 'Status', subcategory: 'SubCategory' };
const rows = [
  { title: 'Doing', content: 'Business Development and Marketing' },
  { title: 'Work In Progress', content: 'Sales and B2B' },
  { title: 'Completed', content: 'Tenders, Proposals and Pitches' },
  { title: 'Doing', content: 'Building a Sales Culture' },
  { title: 'Doing', content: 'Sales Systems and Technology' },
  { title: 'Doing', content: 'Business Development and Marketing' },
];

export const PrioritySection = () => {
  const [selectedPriorityQuarters, setSelectedPriorityQuarters] = useState('Q2');
  const [selectedPriorityYear, setSelectedPriorityYear] = useState('2022');

  return (
    <ContentBox style={{ flex: 1 }}>
      <View style={{ padding: 20 }}>
        <View style={[styles.row]}>
          <Text style={styles.mainTitle}>Priorities</Text>
          <View style={styles.row}>
            <TableDropdown items={quarters} placeholder={selectedPriorityQuarters} onSelectItem={(id) => setSelectedPriorityQuarters(id)} />
            <TableDropdown items={years} placeholder={selectedPriorityYear} onSelectItem={(id) => setSelectedPriorityYear(id)} />
          </View>
        </View>
        <Text style={styles.regularGreyText}>Latest activity from the user</Text>
      </View>
      {/* table */}
      <RoadmapDataTable headers={[headers]} rows={rows} />
    </ContentBox>
  );
};
