import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
  },
  label: {
    color: '#6D7175',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    marginBottom: 4,
  },
  value: {
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 16,
  },
});
