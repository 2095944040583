import { FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon, WebIcon } from '@components/general/icons/social-icons';
import { IndexPageHeader } from '@components/general/index-page-header';
import { Wrapper } from '@components/general/layouts/wrapper/Wrapper';
import { ListModal } from '@components/general/modals/list-modal';
import { Pill, PillVariant } from '@components/general/pill';
import { useGetPartnerQuery } from '@gql/generated/generated';
import { useIndexPageDetail } from '@hooks/useIndexPageDetail';
import { getUniqueCategories } from '@utils/misc';
import { ModalContent } from '@utils/models';
import React from 'react';
import { Dimensions, Image, Linking, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import RenderHtml from 'react-native-render-html';
import { useLocation, useNavigate, useParams } from 'react-router-native';

import { styles } from './styles';

interface SocialIconProps {
  icon: React.ReactNode;
  onPress: () => void;
}

const SocialIcon = ({ icon, onPress }: SocialIconProps) => <TouchableOpacity onPress={onPress}>{icon}</TouchableOpacity>;

const MarketplaceIndividualViewNative = () => {
  const { id } = useParams() as { id: string };
  const navigation = useNavigate();
  const { state, pathname } = useLocation();

  const { listModalType, setListModalType, handleItemsSelect, handleListModalItems } = useIndexPageDetail();

  const { data } = useGetPartnerQuery({
    variables: { getPartnerId: id },
    skip: !id,
  });
  const partner = data?.getPartner;

  const { content, title, logo, categories, links } = partner || state;
  const { facebook, instagram, linkedin, twitter, website } = links || {};

  return (
    <Wrapper>
      <IndexPageHeader
        title={title || ''}
        onPressBack={() => navigation(-1)}
        onSave={() => setListModalType(ModalContent.save)}
        onShare={() => setListModalType(ModalContent.share)}
      />
      <ScrollView contentContainerStyle={styles.mobileInner}>
        <View style={styles.imageContainer}>
          <Image source={{ uri: logo?.file || '' }} style={styles.mobileImage} />
        </View>
        <Text style={styles.title}>{title || ''}</Text>
        <Text style={styles.industryText}>{categories ? getUniqueCategories(categories)[0] : ''}</Text>
        <View style={styles.pillWrap}>
          <Pill variant={PillVariant.DarkBlue} text="Platinum" withMarginRight />
        </View>
        <View style={styles.socialsWrap}>
          <SocialIcon icon={<WebIcon />} onPress={() => Linking.openURL(website)} />
          <SocialIcon icon={<FacebookIcon />} onPress={() => Linking.openURL(facebook)} />
          <SocialIcon icon={<InstagramIcon />} onPress={() => Linking.openURL(instagram)} />
          <SocialIcon icon={<TwitterIcon />} onPress={() => Linking.openURL(twitter)} />
          <SocialIcon icon={<LinkedInIcon />} onPress={() => Linking.openURL(linkedin)} />
        </View>
        {content ? <RenderHtml source={{ html: content }} contentWidth={Dimensions.get('window').width} /> : null}
      </ScrollView>
      <ListModal
        isVisible={listModalType !== ModalContent.closed}
        items={handleListModalItems(pathname)}
        onClose={() => setListModalType(ModalContent.closed)}
        onSelectItem={handleItemsSelect}
      />
    </Wrapper>
  );
};

export { MarketplaceIndividualViewNative };
