import { SideCardWithTitle } from '@components/back-office/cards';

import { FileUploadContextProvider, FileUploadFilenameInput, FileUploadFormDecorator, GenericDropZone } from './GenericDropzone';
import type { Asset, AssetInstruction, MimeType } from './GenericDropzoneTypes';

const FileUploaderWithTitle = ({
  title,
  subtitle,
  ...props
}: FileUploaderProps & {
  title: string;
  subtitle: string;
}) => {
  return (
    <SideCardWithTitle subtitle={subtitle} title={title}>
      <FileUploader {...props} />
    </SideCardWithTitle>
  );
};

type FileUploaderProps = {
  initialAsset?: Asset | null;
  assetInstruction: AssetInstruction;
  formikFieldname?: string;
  actionHint?: string;
  validFileTypes?: MimeType[];
  fileName?: {
    title: string;
    placeholder: string;
  };
  removeFromUI?: () => void;
  errorMessage?: string;
};

const FileUploader = ({
  initialAsset,
  assetInstruction,
  formikFieldname,
  actionHint,
  validFileTypes,
  fileName,
  removeFromUI,
  errorMessage,
}: FileUploaderProps) => {
  return (
    <FileUploadContextProvider initialAsset={initialAsset ?? null} assetInstruction={assetInstruction}>
      {formikFieldname ? <FileUploadFormDecorator fieldName={formikFieldname} /> : null}

      {fileName ? <FileUploadFilenameInput {...fileName} /> : null}

      <GenericDropZone
        actionHint={actionHint}
        validFileTypes={validFileTypes}
        removeFromUI={removeFromUI}
        errorMessage={errorMessage}
        formikFieldname={formikFieldname}
      />
    </FileUploadContextProvider>
  );
};

export { FileUploaderWithTitle, FileUploader };
