import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  itemWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 12,
    paddingBottom: 12,
  },
  itemText: {
    color: '#202223',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    marginLeft: 14,
  },
  itemTextRed: {
    color: '#D72C0D',
  },
  successTitleWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  successTitle: {
    fontSize: 16,
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
    marginLeft: 12,
  },
  successDescription: {
    fontSize: 14,
    color: '#202223',
    fontFamily: 'OpenSans_400Regular',
    marginTop: 16,
  },
  undoText: {
    color: '#2C6ECB',
  },
  itemsWrap: {
    marginTop: 12,
  },
});
