import { PlayIcon } from '@components/general/icons/org-icons';
import { InfoIcon } from '@components/general/icons/roadmap';
import { CircularProgress } from '@components/roadmap/circular-progress';
import { pluralize } from '@utils/misc';
import React, { useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { styles } from './style';

interface ProgressTextProps {
  count: number;
  text: string;
  isDisabled: boolean;
}

const ProgressText = ({ count, text, isDisabled }: ProgressTextProps) => (
  <Text style={[styles.progressText, { color: isDisabled ? '#8B9197' : '#0D1738' }]}>
    <Text style={styles.progressTextBold}>{count}&nbsp;</Text>
    {text}
  </Text>
);

const getPercent = (answeredQuestions?: number, questions?: number) => {
  if (!answeredQuestions || !questions) return 0;
  return Math.round((answeredQuestions / questions) * 100);
};

interface Props {
  title: string;
  subtitle: string;
  sectionsTotal: number;
  isDisabled: boolean;
  questionsTotal: number;
  minuteTotal: number;
  totalQuestions: number;
  totalAnsweredQuestions: number;
  onPlay?: () => void;
  onPressInfo: () => void;
}

const RoadmapBlock = ({
  title,
  subtitle,
  isDisabled,
  sectionsTotal,
  questionsTotal,
  minuteTotal,
  totalQuestions,
  totalAnsweredQuestions,
  onPlay,
  onPressInfo,
}: Props) => {
  const [hovered, setHovered] = useState(false);
  return (
    <View style={[styles.wrap, { borderTopColor: isDisabled ? '#575959' : '#0D1738' }]}>
      <View style={styles.titleWrap}>
        <Text style={[styles.title, { color: isDisabled ? '#8B9197' : '#0D1738' }]}>{title}</Text>
        <TouchableOpacity
          onPress={onPressInfo}
          // @ts-ignore
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <InfoIcon scale={1.5} fill={hovered ? 'darkgray' : '#BABEC3'} />
        </TouchableOpacity>
      </View>
      <Text style={[styles.subtitle, { color: isDisabled ? '#8B9197' : '#0D1738' }]}>{subtitle}</Text>
      <View style={styles.progressWrap}>
        <View style={styles.progressBox}>
          <CircularProgress isTitleVisible={!isDisabled} isDisabled={isDisabled} percent={getPercent(totalAnsweredQuestions, totalQuestions)} />
        </View>
        <View>
          <ProgressText count={sectionsTotal} isDisabled={isDisabled} text={pluralize(sectionsTotal, 'Section')} />
          <ProgressText count={questionsTotal} isDisabled={isDisabled} text={pluralize(questionsTotal, 'Question')} />
          <ProgressText count={minuteTotal} isDisabled={isDisabled} text={pluralize(minuteTotal, 'Minute')} />
        </View>
      </View>
      {!isDisabled && (
        <Text style={styles.questionAnsweredText}>
          {totalAnsweredQuestions}/{totalQuestions} Questions answered
        </Text>
      )}
      <View style={styles.playWrap}>
        {!isDisabled && (
          <>
            <View style={styles.playProgressWrap}>
              <View
                style={[
                  styles.playProgressInner,
                  {
                    width: `${getPercent(totalAnsweredQuestions, totalQuestions)}%`,
                  },
                ]}
              >
                <Text style={styles.playProgressCount}>{totalAnsweredQuestions}</Text>
              </View>
            </View>
            <TouchableOpacity style={styles.playTouch} onPress={onPlay}>
              <PlayIcon color="#FFFFFF" />
            </TouchableOpacity>
          </>
        )}
      </View>
      {isDisabled && (
        <View
          style={{
            position: 'absolute',
            bottom: 20,
            right: 20,
            backgroundColor: '#EDEEEF',
            paddingHorizontal: 12,
            paddingVertical: 8,
            borderRadius: 50,
            width: 'fit-content',
          }}
        >
          <Text
            style={{
              color: '#5C5F62',
            }}
          >
            Coming soon
          </Text>
        </View>
      )}
    </View>
  );
};

export { RoadmapBlock };
