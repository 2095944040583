import { GOOGLE_MAPS_API_KEY } from '@env';
import React from 'react';

interface GoogleMapProps {
  location: string;
}

const GoogleMap = ({ location }: GoogleMapProps) => (
  <iframe
    width="100%"
    height="450"
    style={{ border: 0 }}
    loading="lazy"
    allowFullScreen
    referrerPolicy="no-referrer-when-downgrade"
    src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_API_KEY}&q=${location}`}
  />
);

export { GoogleMap };
