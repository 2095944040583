import { IndexTable } from '@shopify/polaris';
import { Text, TouchableOpacity } from 'react-native';

import { styles } from './style';

interface Props {
  id: string;
  percentage: string;
  date: string | null;
  onAction: (id: string) => void;
}

export const PlanContainer = ({ id, percentage, date, onAction }: Props) => {
  return (
    <IndexTable.Cell>
      <TouchableOpacity onPress={() => onAction(id)}>
        <Text style={styles.textRegularBlue}>{percentage}</Text>
      </TouchableOpacity>
      <Text style={styles.textRegularGray}>{date ? date : '-'}</Text>
    </IndexTable.Cell>
  );
};
