import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  title: {
    marginBottom: 8,
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 26,
    lineHeight: 32,
    color: '#0D1738',
    textAlign: 'center',
  },
  subtitle: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    color: '#6E757C',
    textAlign: 'center',
  },
  registerLink: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 16,
    color: '#2C6ECB',
  },
  loginTouch: {
    marginTop: 16,
  },
});
