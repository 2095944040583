import { FilterCheckbox } from '@components/filter-mobile/filter-checkbox';
import { FilterWrapper } from '@components/filter-mobile/filter-wrapper';
import React, { useState } from 'react';
import { FlatList } from 'react-native';

export enum DurationOptions {
  one = '0-1 Hour',
  two = '1-3 Hour',
  three = '3-6 Hour',
  four = '6-17 Hour',
  five = '17+ Hour',
}

const durationOptions = [DurationOptions.one, DurationOptions.two, DurationOptions.three, DurationOptions.four, DurationOptions.five];

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSave: (duration: DurationOptions[]) => void;
}

const Duration = ({ isVisible, onClose, onSave }: Props) => {
  const [durations, setDurations] = useState<DurationOptions[]>([]);

  const handleSelect = (duration: DurationOptions) => {
    if (durations.includes(duration)) {
      const index = durations.indexOf(duration);
      const durationsCopy: DurationOptions[] = [...durations];
      durationsCopy.splice(index, 1);
      setDurations(durationsCopy);
    } else {
      setDurations([...durations, duration]);
    }
  };

  const renderItem = ({ item }: { item: DurationOptions }) => (
    <FilterCheckbox title={item} isSelected={durations.includes(item)} onToggle={() => handleSelect(item)} />
  );

  return (
    <FilterWrapper
      headerTitle="Duration"
      isVisible={isVisible}
      onClose={onClose}
      buttonTitle="Save"
      isDoneButtonDisabled={!durations.length}
      onDone={() => durations.length && onSave(durations)}
    >
      <FlatList data={durationOptions} renderItem={renderItem} keyExtractor={(item) => item} />
    </FilterWrapper>
  );
};

export { Duration };
