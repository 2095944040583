import { useEffect, useState } from 'react';

import { getObjects } from '../services/algolia';

export function useGetObjectsAlgolia(objects: { indexName: string; id: string }[]) {
  const [objectsState, setObjectsState] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function resetState() {
    setObjectsState([]);
  }

  useEffect(() => {
    if (objects && Array.isArray(objects) && objects.length) {
      (async () => {
        try {
          setIsLoading(true);
          const data = await getObjects(objects);
          const dataFiltered = data.filter((d) => !!d);
          if (dataFiltered.length > 0) {
            setObjectsState(dataFiltered);
          }
        } catch (err) {
          console.error('ERROR - useGetObjectsAlgolia():', err);
        } finally {
          setIsLoading(false);
        }
      })();
    } else {
      setObjectsState([]);
    }
  }, [objects.length]);

  return [objectsState, isLoading, resetState] as [any[], boolean, () => void];
}
