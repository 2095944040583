import { CheckBox } from '@components/checkbox';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { Image, Text, TextInput, TouchableOpacity, View } from 'react-native';

import { styles } from './style';
import Eye from '../../../../assets/img/eye.png';
import EyeOff from '../../../../assets/img/eyeOff.png';
import { FieldTopLabel } from '../../general/field-top-label';

export const PasswordSettings = ({ setIsBackPassword }: any) => {
  const password = nanoid(16);

  const [passwordHide, setPasswordHide] = useState(true);

  const [genPassToggled, setGenPassToggled] = useState(true);
  const [genPassEmailToggled, setGenPassEmailToggled] = useState(true);

  return (
    <View style={styles.flexRow}>
      <View style={styles.personalInfo}>
        <Text style={styles.detailTitle}>Password</Text>
        <Text style={styles.subTitle}>Update your password here</Text>
        <View style={styles.flexRow}>
          <View>
            <FieldTopLabel secureTextEntry label="Current Password *" size={230} />
            <FieldTopLabel secureTextEntry label="New Password *" size={230} />
            <FieldTopLabel secureTextEntry label="Confirm Password *" size={230} />
          </View>
          <View style={{ marginLeft: 80 }}>
            <View style={[styles.wrapperCheckbox, { marginBottom: 15 }]}>
              <View style={styles.borderCheckbox}>
                {/* @ts-ignore FIXME: bad component */}
                <CheckBox
                  boxStyle={styles.checkbox}
                  colorActive="#6E757C"
                  value={genPassToggled}
                  onChangeValue={() => {
                    if (genPassToggled) setGenPassToggled(false);
                    else setGenPassToggled(true);
                  }}
                />
              </View>
              <Text
                style={[
                  styles.labelCheckbox,
                  {
                    marginTop: 3,
                    marginLeft: 4,
                    width: 300,
                    lineHeight: 16,
                  },
                ]}
              >
                Use generated password
              </Text>
            </View>
            <TextInput style={styles.generatedPassword} editable={false} value={password} selectTextOnFocus secureTextEntry={passwordHide} />
            <Text style={styles.pswStrength}>Strong</Text>
            {passwordHide ? (
              <TouchableOpacity onPress={() => setPasswordHide(false)}>
                <Image source={Eye} style={styles.hidePsw} />
              </TouchableOpacity>
            ) : (
              <TouchableOpacity onPress={() => setPasswordHide(true)}>
                <Image source={EyeOff} style={styles.hidePsw} />
              </TouchableOpacity>
            )}
            <View style={[styles.wrapperCheckbox, { marginBottom: 15 }]}>
              <View style={styles.borderCheckbox}>
                {/* @ts-ignore FIXME: bad component */}
                <CheckBox
                  boxStyle={styles.checkbox}
                  colorActive="#6E757C"
                  value={genPassEmailToggled}
                  onChangeValue={() => {
                    if (genPassEmailToggled) setGenPassEmailToggled(false);
                    else setGenPassEmailToggled(true);
                  }}
                />
              </View>
              <Text
                style={[
                  styles.labelCheckbox,
                  {
                    marginTop: 3,
                    marginLeft: 4,
                    width: 300,
                    lineHeight: 16,
                  },
                ]}
              >
                Send me an email notification with generated password
              </Text>
            </View>
          </View>
        </View>
        <View style={[styles.flexRowEnd, { marginTop: 360 }]}>
          <TouchableOpacity>
            <Text style={styles.actionBtn}>Cancel</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setIsBackPassword(true)}>
            <Text style={styles.actionBtn}>Save</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};
