import { CategoryButton } from '@components/back-office/tables/category-button';
import { SearchBar } from '@components/back-office/tables/search-bar';
import { Pagination } from '@shopify/polaris';
import { DiscountsMinor, ProductsMinor } from '@shopify/polaris-icons';
import { Dispatch, SetStateAction, useState } from 'react';
import { View } from 'react-native';

import { DropButton } from './drop-button/DropButton';
import { menuOptionsPartner } from './drop-button/menuOptions';
import { SwitchButton } from './switch-button/SwitchButton';

interface MarketplaceHeadMenuProps {
  hasNextPage: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  specialOffer: boolean;
  setSpecialOffer: Dispatch<SetStateAction<boolean>>;
  partnerType: string[];
  setPartnerType: Dispatch<SetStateAction<string[]>>;
  searchTerm: string | null;
  setSearchTerm: Dispatch<SetStateAction<string | null>>;
  setCategories: any;
}

const MarketplaceHeadMenu = ({
  hasNextPage,
  page,
  setPage,
  specialOffer,
  setSpecialOffer,
  partnerType,
  setPartnerType,
  searchTerm,
  setSearchTerm,
  setCategories,
}: MarketplaceHeadMenuProps) => {
  const [displayCategoryModal, setDisplayCategoryModal] = useState(false);

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 40,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <SearchBar
          setSearchTerm={(value: string) => {
            setPage(1);
            setSearchTerm(value);
          }}
          searchTerm={searchTerm}
          placeholder="Search by name"
          searchBarContainerStyles={{
            marginRight: 8,
          }}
        />
        <CategoryButton
          setSelectedCat={setCategories}
          selectedCat={[]}
          displayCategoryModal={displayCategoryModal}
          setDisplayCategoryModal={setDisplayCategoryModal}
          fromUserApp
          withSlimButton
        />
        {/* // TODO Temp disabling partner types see https://trello.com/c/fSds74pG/311-hide-the-gold-silver-bronze-partnership-tags  */}
        {/* <DropButton
          title="Partner Type"
          Icon={ProductsMinor}
          options={menuOptionsPartner}
          selected={partnerType}
          setSelected={setPartnerType}
          containerStyles={{
            marginLeft: 8,
          }}
        /> */}
        <SwitchButton
          title="Special Offer"
          Icon={DiscountsMinor}
          isActive={specialOffer}
          setIsActive={setSpecialOffer}
          containerStyles={{
            marginLeft: 8,
          }}
        />
      </View>
      <Pagination hasPrevious={page > 1} onPrevious={() => setPage(page - 1)} hasNext={hasNextPage} onNext={() => setPage(page + 1)} />
    </View>
  );
};

export { MarketplaceHeadMenu };
