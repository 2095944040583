import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
  },
  content: {
    paddingTop: 20,
    paddingBottom: 40,
    paddingLeft: 40,
    paddingRight: 40,
  },
  description: {
    color: '#6D7175',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    marginBottom: 20,
  },
  submitWrap: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  buttonWrap: {
    flexDirection: 'row',
  },
  rowInputWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
    zIndex: 1,
  },
  inputItem: {
    width: '49%',
  },
  textAreaWrap: {
    marginBottom: 16,
  },
});
