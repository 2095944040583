import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const TrashIcon = ({ fill = '#D72C0D', ...props }: SvgProps) => (
  <Svg width={14} height={16} fill={fill} {...props}>
    <Path
      d="M5 2.103C5 1.003 5.895.11 7 .11s2 .893 2 1.994h4c.552 0 1 .447 1 .997s-.448.997-1 .997H1c-.552 0-1-.446-1-.997 0-.55.448-.997 1-.997h4ZM2 12.617V6.109h2v6.508a.5.5 0 0 0 .5.498H6V6.11h2v7.007h1.5a.5.5 0 0 0 .5-.499V6.109h2v6.508a2.496 2.496 0 0 1-2.5 2.492h-5c-1.38 0-2.5-1.116-2.5-2.492Z"
      fill={fill}
    />
  </Svg>
);

export { TrashIcon };
