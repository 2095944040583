import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const EyeIcon = (props: SvgProps) => (
  <Svg width={16} height={12} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.928 5.628C15.836 5.399 13.611 0 8 0 2.389 0 .163 5.399.072 5.628a1.017 1.017 0 0 0 0 .744C.162 6.601 2.388 12 8 12c5.611 0 7.836-5.399 7.928-5.628a1.017 1.017 0 0 0 0-.744ZM8 10a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm0-6a2 2 0 1 0 .002 4.001A2 2 0 0 0 8 4Z"
      fill="#0D1738"
    />
  </Svg>
);

export { EyeIcon };
