import { Platform } from 'react-native';

import { NotificationSettings as NotificationSettingsWeb } from './NotificationSettings';
import { NotificationSettingsNative } from './NotificationSettings.native';

const NotificationSettings = Platform.select({
  native: () => NotificationSettingsNative,
  default: () => NotificationSettingsWeb,
})();

export { NotificationSettings };
