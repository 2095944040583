import { ActivityIndicatorTRN } from '@components/general/activity-indicator/ActivityIndicator';
import { PulseCategoryItem } from '@components/roadmap/pulse-category-item';
import { PulseResultWrapper } from '@components/roadmap/pulse-result-wrapper';
import { RoadmapPulseContext } from '@context/RoadmapPulseContext';
import { useContext, useMemo } from 'react';
import { View } from 'react-native';
import { useNavigate, useParams } from 'react-router-native';

import { ids, styles } from './style';
import { getQuestionScore, getSubCategoryRecommendation } from './utils';

export const PulseResultPlan = () => {
  const { sectionId } = useParams();
  const navigate = useNavigate();
  const roadmapPulseContext = useContext(RoadmapPulseContext);
  if (!roadmapPulseContext) throw new Error('You must provide roadmap pulse context');

  const { scoreAssns, selectedPulse, isLoading } = roadmapPulseContext;

  const subCategories = useMemo(() => {
    if (!scoreAssns) return [];
    const category = scoreAssns.find((c) => c.name === sectionId);
    if (!category) return [];
    return category.subCategories;
  }, [scoreAssns.length]);

  const pulseQuestions = useMemo(() => {
    if (!selectedPulse) return [];
    const section = selectedPulse.sections.find((s) => s.name === sectionId);
    if (!section) return [];
    return section.questions;
  }, [selectedPulse]);

  const subCategoryScores = useMemo(() => {
    if (!pulseQuestions) return [];
    return pulseQuestions.map((q) => ({ name: q.subcategory, score: getQuestionScore(q) }));
  }, [pulseQuestions]);

  const allFeedback = useMemo(() => {
    if (!subCategories || !subCategoryScores) return [];
    return subCategories
      .map((subCategory) => {
        const subCategoryScore = subCategoryScores.find((s) => s.name === subCategory.name);
        const feedbacks = subCategory.levels.reduce((acc, l) => {
          if (l.score <= subCategoryScore?.score! && l.feedbackTitle && l.feedbackText) {
            acc.push({
              name: subCategory.name!,
              score: l.score,
              feedbackTitle: l.feedbackTitle,
              feedbackText: l.feedbackText,
            });
          }
          return acc;
        }, [] as { name: string; score: number; feedbackText: string; feedbackTitle: string }[]);
        feedbacks.sort((a, b) => b.score - a.score);
        return feedbacks[0];
      })
      .filter((s) => !!s);
  }, [subCategories.length, subCategoryScores.length]);

  const totalScore = useMemo(() => {
    if (!subCategoryScores) return 0;
    return subCategoryScores.reduce((acc, category) => {
      acc += category.score;
      return acc;
    }, 0);
  }, [subCategoryScores?.length]);

  function getFeedback(subCategoryId: string | null) {
    if (!subCategoryId) return null;
    const feedback = allFeedback.find((f) => f.name === subCategoryId);
    return feedback;
  }

  function goToResult() {
    navigate(`/roadmap/pulse/${selectedPulse?.id}/result`);
  }

  if (isLoading) {
    return <ActivityIndicatorTRN isFullscreen />;
  }

  return (
    <PulseResultWrapper title={sectionId} scoreOutOf100={totalScore} onBack={goToResult}>
      <View style={styles.itemsWrap} dataSet={{ media: ids.itemsWrap }}>
        {!isLoading &&
          subCategoryScores &&
          subCategories.map((scoreAssnsSubCategory) => {
            const subCategoryScore = subCategoryScores.find((s) => s.name === scoreAssnsSubCategory.name);
            const { content, trainings } = getSubCategoryRecommendation(scoreAssnsSubCategory, subCategoryScore?.score!);
            const feedback = getFeedback(scoreAssnsSubCategory?.name ?? null);
            return (
              <PulseCategoryItem
                sectionId={scoreAssnsSubCategory.name!}
                key={scoreAssnsSubCategory.name!}
                title={scoreAssnsSubCategory.name!}
                score={subCategoryScore?.score ?? 0}
                percent={null}
                contentPieces={content.length}
                trainingCourses={trainings.length}
                maxScore={5}
                feedbackTitle={feedback?.feedbackTitle}
                feedbackText={feedback?.feedbackText}
                subCategoryId={scoreAssnsSubCategory.name}
                type="subcategory"
              />
            );
          })}
      </View>
    </PulseResultWrapper>
  );
};
