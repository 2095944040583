import Svg, { Path } from 'react-native-svg';

const MenuIcon = () => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    stroke-width="2"
    scale={0.8}
    stroke="currentColor"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <Path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <Path d="M4 6l16 0" />
    <Path d="M4 12l16 0" />
    <Path d="M4 18l16 0" />
  </Svg>
);

export { MenuIcon };
