import { mediaQuery } from '@utils/misc';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
    marginTop: 40,
  },
  instructorInfoWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  instructorPhoto: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    width: 40,
    borderRadius: 20,
    backgroundColor: '#E4E5E7',
    marginRight: 8,
  },
  initials: {
    color: '#FFFFFF',
    fontSize: 20,
    fontFamily: 'OpenSans_600SemiBold',
    textTransform: 'capitalize',
  },
  aboutText: {
    color: '#6E757C',
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
  },
  instructorName: {
    color: '#0D1738',
    fontSize: 20,
    fontFamily: 'OpenSans_600SemiBold',
  },
  descriptionWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    [mediaQuery.forMobile]: {
      flexDirection: 'column',
    },
  },
  regularText: {
    color: '#0D1738',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
  },
  description: {
    width: '60%',
    [mediaQuery.forMobile]: {
      width: '100%',
      marginBottom: 8,
    },
  },
  iconWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  iconText: {
    marginLeft: 10,
  },
  socialsWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    [mediaQuery.forMobile]: {
      marginTop: 8,
      marginBottom: 20,
    },
  },
  socialIcon: {
    marginRight: 12,
  },
});
