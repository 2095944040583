import { useFormikContext } from 'formik';
import { View } from 'react-native';

import { ButtonLayout } from '../../button-layout/ButtonLayout';
import { InputFormik } from '../../form-elements/input-formik/InputFormik';
import { InputFormikContainer } from '../../form-elements/input-formik/InputFormikContainer';

interface formProps {
  closeEditMode: () => void;
}

const AddressForm = ({ closeEditMode }: formProps) => {
  const { handleSubmit } = useFormikContext();

  const submitCloseHander = () => {
    handleSubmit();
    closeEditMode();
  };

  return (
    <>
      <InputFormikContainer label="Address">
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <InputFormik formikKey="houseNumber" placeholder="House No." />
          <InputFormik formikKey="addressOne" placeholder="Street Name" />
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <InputFormik formikKey="addressTwo" placeholder="Address (optional)" />
          <InputFormik formikKey="postCode" placeholder="Postcode" />
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <InputFormik formikKey="city" placeholder="City" />
          <InputFormik formikKey="country" placeholder="Country" />
        </View>
      </InputFormikContainer>
      <ButtonLayout closeEditMode={closeEditMode} submitCloseHander={submitCloseHander} />
    </>
  );
};

export { AddressForm };
