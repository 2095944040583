import { PlayIcon } from '@components/general/icons/org-icons';
import { CircularProgress } from '@components/roadmap/circular-progress';
import { getRoadmapProgressColor } from '@utils/misc';
import React, { useEffect, useRef } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { styles } from './style';

interface Props {
  title: string;
  percent: number;
  totalQuestion: number;
  onPlay?: () => void;
  hidePlay?: boolean;
}

const PlanBlock = ({ title, percent, totalQuestion, onPlay, hidePlay = false }: Props) => {
  /* 
    The following is a hack to get the circular progress to rerender completely when percent changes
    as a bug in the library means it will not update the progress bar without this hack
  */
  const [hasViewWrapper, setHasViewWrapper] = React.useState(false);
  const prevPercent = useRef(0);

  useEffect(() => {
    if (prevPercent.current !== percent) {
      setHasViewWrapper(!hasViewWrapper);
      prevPercent.current = percent;
    }
  }, [percent]);

  const progressCircle = hasViewWrapper ? (
    <View>
      <CircularProgress percent={percent} />
    </View>
  ) : (
    <CircularProgress percent={percent} />
  );

  return (
    <View style={[styles.wrap, { borderTopColor: getRoadmapProgressColor(percent).color }]}>
      <View style={styles.topWrap}>
        <View style={styles.circularWrap}>
          {/* Hack to get circular progress to rerender completely */}
          {progressCircle}
        </View>
        <View>
          <Text style={[styles.percentageText, { color: getRoadmapProgressColor(percent).fillColor }]}>{percent}%</Text>
          <Text style={styles.doneText}>Done</Text>
          <Text style={styles.questionsText}>
            From {totalQuestion} question{totalQuestion > 1 ? 's' : ''}
          </Text>
        </View>
      </View>
      <View style={styles.playWrap}>
        <Text style={styles.title}>{title}</Text>
        {!hidePlay && (
          <TouchableOpacity testID="play" style={styles.playTouch} onPress={onPlay}>
            <PlayIcon color="#FFFFFF" />
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

export { PlanBlock };
