import React from 'react';
import { View, ViewStyle } from 'react-native';

import { styles } from './style';

interface Props {
  children: React.ReactNode;
  style?: ViewStyle;
  dataSetMedia?: string;
}

const Wrapper = ({ children, style, dataSetMedia }: Props) => (
  <View style={styles.wrap}>
    <View style={[styles.inner, style && style]} dataSet={{ media: dataSetMedia || '' }}>
      {children}
    </View>
  </View>
);

export { Wrapper };
