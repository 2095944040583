import { Autocomplete, Button, LegacyStack, Tag } from '@shopify/polaris';
import { ActionListItemDescriptor, OptionDescriptor } from '@shopify/polaris/build/ts/latest/src/types';
import { useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';

interface FormikAutocompleteSelectProps {
  fieldName: string;
  label: string;
  options: OptionDescriptor[];
  multiSelect?: boolean;
  actionBefore?: ActionListItemDescriptor & {
    /** Specifies that if the label is too long it will wrap instead of being hidden  */
    wrapOverflow?: boolean;
  };
}

const FormikAutocompleteSelect = ({ fieldName, label, options, multiSelect = false, actionBefore }: FormikAutocompleteSelectProps) => {
  const { setFieldValue, values } = useFormikContext<any>();

  const [showTextField, setShowTextField] = useState(true);

  const deselectedOptions = useMemo(() => options, []);

  const [inputValue, setInputValue] = useState('');
  const [availableOptions, setAvailableOptions] = useState(deselectedOptions);

  useEffect(() => {
    if (!multiSelect && values[fieldName]) {
      setShowTextField(false);
    }
  }, []);

  const updateText = useCallback(
    (value: string) => {
      setInputValue(value);
      if (value === '') {
        setAvailableOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = deselectedOptions.filter(
        (option: OptionDescriptor) => typeof option.label === 'string' && option.label.match(filterRegex)
      );
      setAvailableOptions(resultOptions);
    },
    [deselectedOptions]
  );

  const removeTag = useCallback(
    (tag: never) => () => {
      const options = [...values[fieldName]];
      options.splice(options.indexOf(tag), 1);
      setFieldValue(fieldName, options);
    },
    [values[fieldName]]
  );

  const tags =
    multiSelect && values[fieldName] && values[fieldName].length > 0 ? (
      <LegacyStack spacing="extraTight" alignment="center">
        {values[fieldName].map((option: any) => {
          const tagLabel = options.find((el) => el.value === option)?.label;
          return (
            // @ts-ignore: Unreachable code error
            <Tag key={`option${option}`} onRemove={removeTag(option)}>
              {tagLabel}
            </Tag>
          );
        })}
      </LegacyStack>
    ) : null;

  const textField = showTextField ? (
    <Autocomplete.TextField autoComplete="off" onChange={updateText} label={label} value={inputValue} placeholder="Type 3 letters" />
  ) : (
    <View style={{ marginVertical: 4 }}>
      <Button
        onClick={() => {
          setFieldValue(fieldName, '');
          setShowTextField(true);
        }}
        fullWidth
      >
        {options.find((el) => el.value === values[fieldName])?.label as string}
      </Button>
    </View>
  );

  return (
    <>
      <Autocomplete
        allowMultiple={multiSelect}
        options={availableOptions}
        selected={values[fieldName] ?? ''}
        textField={textField}
        onSelect={(value) => {
          setFieldValue(fieldName, multiSelect ? value : value[0]);
          !multiSelect && setShowTextField(false);
        }}
        actionBefore={actionBefore}
      />
      {multiSelect ? <View style={{ marginTop: 8, marginBottom: 12 }}>{tags}</View> : null}
    </>
  );
};

export { FormikAutocompleteSelect };
