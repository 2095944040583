import { ClockIconSmall } from '@components/general/icons';
import { Row } from '@components/general/row';
import { Event, Training } from '@gql/generated/generated';
import { useGetFile } from '@hooks/useGetFileUrl';
import { convertSecondsToDisplay } from '@utils/TIme';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { ImageBackground, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { ids, styles } from './style';

interface FeaturedEventsProps {
  data?: Event[] | Training[] | null;
  infoCardWidth?: string;
  isTraining?: boolean;
}

const useFeaturedEvents = (data?: Event[] | Training[] | null) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (!data || data.length < 2) {
      return;
    }
    const timeout = setTimeout(() => {
      const isLastEvent = selectedIndex === data.length - 1;
      setSelectedIndex(isLastEvent ? 0 : selectedIndex + 1);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [data, selectedIndex]);

  return {
    selectedIndex,
    setSelectedIndex,
  };
};

export const FeaturedEvents = ({ data, infoCardWidth, isTraining }: FeaturedEventsProps) => {
  const navigate = useNavigate();
  const { selectedIndex, setSelectedIndex } = useFeaturedEvents(data);
  const selectedTraining = data?.[selectedIndex] as Training;
  const selectedEvent = data?.[selectedIndex] as Event;

  const { fileUrl } = useGetFile(selectedEvent.coverImage?.storagePath || selectedTraining.coverImage?.storagePath);

  if (!data?.length) {
    return null;
  }

  const dateFromAsDate = selectedEvent?.dateFrom ? new Date(selectedEvent?.dateFrom) : '';
  const startTime = dayjs(dateFromAsDate).format('h:mm a');
  const startDate = dayjs(dateFromAsDate).format('D');
  const startMonth = dayjs(dateFromAsDate).format('MMMM');

  const selectedTrainingDuration = convertSecondsToDisplay(selectedTraining?.durationInSeconds || 0);
  const selectedTrainingNumLessons = selectedTraining?.numLessons || 0;

  return (
    <TouchableOpacity onPress={() => navigate(`/${isTraining ? 'training' : 'events'}/${isTraining ? selectedTraining.id : selectedEvent.id}`)}>
      <ImageBackground
        source={{
          uri: fileUrl,
        }}
        resizeMode="cover"
        style={styles.image}
        imageStyle={styles.roundedImage}
      >
        {data.length > 1 ? (
          <View style={styles.dotsContainer}>
            {data.map((item: any, index: number) => {
              return (
                <TouchableOpacity
                  style={[styles.dot, index === selectedIndex && styles.selectedDot]}
                  onPress={() => setSelectedIndex(index)}
                  key={item.id}
                />
              );
            })}
          </View>
        ) : null}
        <View style={[styles.infoCard, infoCardWidth ? { width: infoCardWidth } : {}]} dataSet={{ media: ids.infoCard }}>
          {isTraining ? (
            <View>
              <Text style={styles.titleText}>{selectedTraining.title}</Text>
              <Text style={styles.trainingDescription} numberOfLines={2}>
                {selectedTraining.shortDescription}
              </Text>
              <Text style={styles.lessonsText}>
                {`${selectedTrainingDuration} `}
                {' • '}
                {selectedTrainingNumLessons! > 1 ? `${selectedTrainingNumLessons} lessons` : `${selectedTrainingNumLessons} lesson`}
              </Text>
            </View>
          ) : (
            <>
              <View style={styles.flexRow}>
                <View style={styles.leftSideBox}>
                  <Text style={styles.dateNumber}>{startDate}</Text>
                  <Text style={styles.titleText}>{startMonth}</Text>
                </View>
                <View style={styles.rightSideBox}>
                  <Text style={styles.titleText}>{selectedEvent.title}</Text>
                  <Row style={{ alignItems: 'center' }}>
                    <ClockIconSmall style={{ marginRight: 3 }} />
                    <Text style={styles.timeText}>{startTime}</Text>
                  </Row>
                </View>
              </View>
            </>
          )}
        </View>
      </ImageBackground>
    </TouchableOpacity>
  );
};
