import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  subContainer: {
    textAlign: 'left',
    paddingHorizontal: 40,
    paddingTop: 40,
  },
  name: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 9.4,
    lineHeight: 12,
    color: '#6B7280',
    position: 'absolute',
    zIndex: 1,
    marginLeft: 10,
    marginTop: -5.3,
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 3,
  },
  lastName: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 9.4,
    lineHeight: 12,
    color: '#6B7280',
    position: 'absolute',
    zIndex: 1,
    marginLeft: 160,
    marginTop: -5.3,
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 3,
  },
  email: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 9.4,
    lineHeight: 12,
    color: '#6B7280',
    position: 'absolute',
    zIndex: 1,
    marginLeft: 10,
    marginTop: 46.3,
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 3,
  },
  input: {
    borderWidth: 1,
    borderColor: '#D1D5DB',
    borderRadius: 4,
    padding: 12,
    marginBottom: 12,
    height: 36,
  },
  inputSmall: {
    borderWidth: 1,
    borderColor: '#D1D5DB',
    borderRadius: 8,
    padding: 12,
    marginBottom: 15,
    width: 138,
    marginRight: 12,
    height: 36,
  },
  disabledInput: {
    borderWidth: 1,
    borderColor: '#D2D5D8',
    borderRadius: 4,
    padding: 12,
    marginBottom: 12,
    height: 36,
    backgroundColor: '#FAFBFB',
    color: '#6D7175',
  },
  forgotPassword: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 9.4,
    lineHeight: 24,
    color: '#1D6AFF',
    textAlign: 'right',
    marginTop: -10,
    marginBottom: 10,
  },
  nextButton: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 20,
    color: '#FFFFFF',
    backgroundColor: '#0D1738',
    borderRadius: 35,
    paddingVertical: 8,
    textAlign: 'center',
  },
  register: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 16,
    marginHorizontal: 20,
  },
  noAccount: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#202223',
  },
  registerLink: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#2C6ECB',
  },
  nameFields: {
    display: 'flex',
    flexDirection: 'row',
  },
  question: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 12,
    lineHeight: 24,
    color: '#0D1738',
  },
  roles: {
    display: 'flex',
    flexDirection: 'row',
  },
  buttonRole: {
    width: 138,
    height: 32,
    textAlign: 'center',
    backgroundColor: '#F2F4F8',
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#A3AAB0',
    marginBottom: 15,
    marginRight: 12,
    paddingTop: 9,
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 10.5,
    lineHeight: 15,
    color: '#0D1738',
  },
  buttonRoleSelected: {
    width: 138,
    height: 32,
    textAlign: 'center',
    backgroundColor: '#8497AD',
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#8497AD',
    marginBottom: 20,
    marginRight: 12,
    paddingTop: 9,
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 10.5,
    lineHeight: 15,
    color: '#FFFFFF',
  },
  progress: {
    marginVertical: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  barDone: {
    width: 8,
    height: 8,
    borderRadius: 100,
    marginHorizontal: 4,
    backgroundColor: '#0D1738',
  },
  barLeft: {
    width: 8,
    height: 8,
    borderRadius: 100,
    marginHorizontal: 4,
    borderWidth: 2,
    borderColor: '#BDC1CC',
  },
  inputWrap: {
    marginBottom: 16,
  },
  submitButton: {
    ...(Platform.OS === 'web' && { height: 36 }),
  },
});
