import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const FilterIcon = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path
      d="M0 3a1 1 0 0 1 1-1h2a1 1 0 0 1 0 2H1a1 1 0 0 1-1-1ZM6 3a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM6 13a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM0 13a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1ZM12 8a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1ZM0 8a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1Z"
      fill="#5C5F62"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM11 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      fill="#5C5F62"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM5 16a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      fill="#5C5F62"
    />
  </Svg>
);

export { FilterIcon };
