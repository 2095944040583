import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  filterText: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 20,
    color: '#202223',
    marginTop: 2,
  },
});
