import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  container: {
    borderTopWidth: 1,
    borderTopColor: '#EDEEEF',
    paddingBottom: 40,
    paddingTop: 20,
    paddingHorizontal: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  accountStatusButtonText: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    textDecorationLine: 'underline',
  },
});
