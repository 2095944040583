import { stripTypename } from '@apollo/client/utilities';
import { namedOperations, useGetPrioritiesBoardQuery, useUpdatePrioritiesBoardMutation } from '@gql/generated/generated';
import { getValuable } from '@utils/graphql';
import { useEffect, useState } from 'react';

import { TBoard } from './Priorities';

export const useBoard = () => {
  const [board, _setBoard] = useState<TBoard>({});

  const { loading, error, data: boardDataFromPrioritiesQuery } = useGetPrioritiesBoardQuery({ notifyOnNetworkStatusChange: true });

  const [updatePrioritiesBoard, { loading: updating }] = useUpdatePrioritiesBoardMutation({
    refetchQueries: [namedOperations.Query.GetPrioritiesBoard],
  });

  useEffect(() => {
    if (!boardDataFromPrioritiesQuery) {
      return;
    }

    const sanitizedBoard: TBoard = Object.fromEntries(
      Object.entries(stripTypename(boardDataFromPrioritiesQuery?.getPrioritiesBoard) || {}).map(([quarterKey, quarterContent]) => [
        quarterKey.replace('_', ''), // graphql numeric key prefix hack
        getValuable(stripTypename(quarterContent || {})),
      ])
    );

    _setBoard(sanitizedBoard);
  }, [boardDataFromPrioritiesQuery]);

  const setBoard = (board: TBoard) => {
    _setBoard(board);
    const boardWithPrefixedQuarters = Object.fromEntries(Object.entries(board).map(([key, value]) => [`_${key}`, value]));
    updatePrioritiesBoard({ variables: { board: boardWithPrefixedQuarters } });
  };

  return { board, setBoard, loading, updating };
};
