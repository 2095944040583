import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mainTitle: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 20,
    lineHeight: 28,
    color: '#0D1738',
  },
  regularGreyText: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#6D7175',
  },
  // contentTracking
  categoryTags: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    lineHeight: 16,
    color: '#0D1738',
    paddingVertical: 2,
    paddingHorizontal: 8,
    borderRadius: 15,
    marginRight: 5,
  },
  categoryTagBG: {
    backgroundColor: '#E4E5E7',
  },
  categoryTagActive: {
    color: '#FFFFFF',
    backgroundColor: '#2C6ECB',
  },
});
