import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  headerContainer: {
    borderBottomWidth: 1,
    borderBottomColor: '#E1E3E5',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 8,
    paddingHorizontal: 40,
  },
  headerTitle: {
    fontSize: 12,
    fontFamily: 'OpenSans_600SemiBold',
    lineHeight: 20,
    color: '#6D7175',
  },
  headerMainTitle: {
    fontSize: 12,
    fontFamily: 'OpenSans_600SemiBold',
    lineHeight: 20,
    color: '#6D7175',
  },
  jobPositionContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
    width: 180,
  },
  headerButton: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 30,
  },
  cardsWrapper: {
    marginBottom: 40,
  },
  dropdownButton: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
    width: 36,
    height: 36,
    alignItems: 'center',
    borderRadius: 8,
    border: '1px solid #c5c5c5',
  },
  wrapper: {
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: 40,
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#E1E3E5',
  },
  rowContainer: {
    flexDirection: 'row',
    flexGrow: 2,
    flexShrink: 1,
    flexBasis: 310,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  avatarContainer: {
    position: 'relative',
    width: 40,
    height: 40,
    marginRight: 16,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 50,
  },
  status: {
    position: 'absolute',
    width: 12,
    height: 12,
    bottom: 0,
    right: 0,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#fff',
  },
  displayNone: {
    display: 'none',
  },
  online: {
    backgroundColor: '#AEE9D1',
  },
  away: {
    backgroundColor: '#FED3D1',
  },
  nameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 180,
  },
  textContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 140,
    alignSelf: 'center',
  },
  textContainerName: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    color: '#0D1738',
    lineHeight: 20,
  },
  textContainerInfo: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    color: '#0D1738',
    lineHeight: 20,
  },
  tagContainer: {
    backgroundColor: '#D3E5FE',
    borderRadius: 15,
    marginLeft: 8,
  },
  tagTitle: {
    fontSize: 12,
    paddingVertical: 2,
    paddingHorizontal: 8,
    fontFamily: 'OpenSans_400Regular',
    lineHeight: 16,
    color: '#0D1738',
  },
});
