import { editPageStyles } from '@styles/BackOffice/editPageStyles';
import { Text, TouchableOpacity, View } from 'react-native';

interface Props {
  title: string;
  editButton?: boolean;
  editMode?: boolean;
  setEditMode?: () => void;
}

export const UserGeneralHeader = ({ title, editButton = false, editMode, setEditMode }: Props) => {
  return (
    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <Text style={editPageStyles.sectionTitle}>{title}</Text>
      {editButton && (
        <TouchableOpacity onPress={setEditMode}>
          <Text style={editPageStyles.sectionEditTitle}>{editMode === true ? 'Cancel' : 'Edit'}</Text>
        </TouchableOpacity>
      )}
    </View>
  );
};
