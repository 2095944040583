import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { ISvgProps } from '../../../types';

const InstagramIconDark = (props: ISvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none" {...props}>
    <Path
      fill="#5C5F62"
      d="M12 .159c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 4.5c-2.037 0-2.292.008-3.092.045-.799.036-1.344.163-1.82.348a3.677 3.677 0 0 0-1.33.866 3.677 3.677 0 0 0-.864 1.328c-.186.477-.312 1.022-.349 1.82-.036.8-.045 1.056-.045 3.093s.009 2.292.045 3.092c.037.798.163 1.343.349 1.82.191.494.448.912.865 1.329a3.68 3.68 0 0 0 1.328.865c.477.185 1.022.312 1.82.349.8.036 1.056.045 3.093.045s2.292-.009 3.092-.045c.798-.037 1.344-.164 1.82-.349a3.677 3.677 0 0 0 1.33-.865c.416-.417.673-.835.864-1.328.186-.478.312-1.023.349-1.821.036-.8.045-1.055.045-3.092s-.009-2.293-.045-3.093c-.037-.798-.163-1.343-.349-1.82a3.676 3.676 0 0 0-.865-1.328 3.677 3.677 0 0 0-1.328-.866c-.477-.185-1.023-.312-1.82-.348-.8-.037-1.056-.045-3.093-.045zm0 1.351c2.003 0 2.24.008 3.03.044.732.033 1.129.155 1.393.258.35.136.6.299.863.561.262.262.425.512.56.862.103.265.226.662.259 1.393.036.79.044 1.028.044 3.03 0 2.003-.008 2.24-.044 3.031-.033.732-.156 1.129-.258 1.393-.136.35-.299.6-.561.862a2.323 2.323 0 0 1-.863.561c-.264.103-.661.225-1.392.259-.791.036-1.028.043-3.031.043s-2.24-.007-3.03-.043c-.732-.034-1.129-.156-1.393-.259-.35-.136-.6-.298-.863-.56a2.322 2.322 0 0 1-.56-.863c-.103-.264-.226-.661-.259-1.393-.036-.79-.044-1.028-.044-3.03 0-2.003.008-2.24.044-3.031.033-.731.156-1.128.258-1.393.136-.35.299-.6.561-.862.263-.262.513-.425.863-.561.264-.103.661-.225 1.392-.258C9.76 6.018 9.997 6.01 12 6.01zm0 2.297a3.851 3.851 0 1 0 0 7.703 3.851 3.851 0 0 0 0-7.703zm0 6.352a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm4.904-6.504a.9.9 0 1 1-1.8 0 .9.9 0 0 1 1.8 0z"
    />
  </Svg>
);

export { InstagramIconDark };
