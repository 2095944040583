import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  tableWrapper: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    width: '100%',
    shadowColor: 'rgba(0, 0, 0, 0.15)',
    shadowRadius: 4,
    shadowOffset: { height: 0.5, width: 0 },
    marginVertical: 20,
  },
  headersContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#E1E3E5',
  },
  headerNumber: {
    width: 40,
  },
  headerTitle: {
    flexBasis: 226,
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 20,
    color: '#6D7175',
  },
  headerRest: {
    flex: 1,
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 20,
    color: '#6D7175',
  },
  rowContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#E1E3E5',
  },
  rowTitle: {
    flexBasis: 226,
    flexShrink: 1,
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#202223',
  },
  rowRest: {
    flex: 1,
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#202223',
  },
  rowTitleNumber: {
    flexBasis: 40,
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#202223',
  },
});
