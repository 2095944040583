import { InputError, Props as InputErrorProps } from '@components/general/form/input-error';
import { Label, Props as LabelProps } from '@components/general/form/label';
import { SelectCaretIcon } from '@components/general/icons/SelectCaretIcon';
import { ListModal } from '@components/general/modals/list-modal';
import { LegacyCard, OptionList, Select } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { FlatList, Platform, Text, TouchableOpacity, View } from 'react-native';

import { styles } from './style';
import { useSelectOption } from './useSelectOption';

export interface ISelectOption<T = string> {
  label: T;
  value: T;
}

export interface Props extends LabelProps, InputErrorProps {
  isMultiple?: boolean;
  onChange: (selected: string) => void;
  value: string;
  options: ISelectOption[];
  placeholder?: string;
  setFirstOptionAsDefault?: boolean;
}

const SelectOption = ({ label, isOptional, error, value, options, onChange, isMultiple, placeholder, setFirstOptionAsDefault = false }: Props) => {
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const { handleSelect, checkSelection } = useSelectOption(value, onChange, isMultiple);

  const handleOptionPress = (value: string) => {
    handleSelect(value);
    if (!isMultiple) {
      setIsOptionsVisible(false);
    }
  };

  useEffect(() => {
    if (setFirstOptionAsDefault && !value && options && options.length > 0) {
      handleSelect(options[0].value);
    }
  }, [options]);

  const keyExtractor = (item: ISelectOption, index: number) => item.value;

  const renderItem = ({ item }: { item: ISelectOption }) => (
    <TouchableOpacity key={item.value} style={styles.item} onPress={() => handleOptionPress(item.value)}>
      <View style={[styles.leftBorder, checkSelection(item.value) && styles.selectedLeftBorder]} />
      <View style={[styles.itemContent, checkSelection(item.value) && styles.itemSelected]}>
        <Text style={styles.itemText} ellipsizeMode="tail" numberOfLines={1}>
          {item.label}
        </Text>
      </View>
    </TouchableOpacity>
  );

  if (Platform.OS === 'web') {
    return (
      <>
        {isMultiple ? (
          <View>
            {label && <Label label={label} isOptional={isOptional} />}
            <LegacyCard>
              <OptionList
                title={placeholder}
                onChange={(selected) => onChange(selected.join(', '))}
                options={options}
                selected={value ? value.split(', ') : []}
                allowMultiple
              />
            </LegacyCard>
          </View>
        ) : (
          <Select
            label={label}
            placeholder={placeholder}
            error={!!error}
            options={options}
            value={value}
            onChange={(selected) => onChange(selected)}
          />
        )}
      </>
    );
  } else {
    return (
      <View style={styles.wrap}>
        {label && <Label label={label} isOptional={isOptional} />}
        <TouchableOpacity style={styles.selectBox} onPress={() => setIsOptionsVisible(!isOptionsVisible)}>
          <Text style={value ? styles.selectedText : styles.placeholder} ellipsizeMode="tail" numberOfLines={1}>
            {value || placeholder}
          </Text>
          <SelectCaretIcon />
        </TouchableOpacity>
        <ListModal isVisible={isOptionsVisible} onClose={() => setIsOptionsVisible(false)}>
          <View style={styles.options}>
            <FlatList data={options} keyExtractor={keyExtractor} renderItem={renderItem} />
          </View>
        </ListModal>
        {error && <InputError error={error} />}
      </View>
    );
  }
};

export { SelectOption };
