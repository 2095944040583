import { DotsButton } from '@components/general/dots-button';
import { Row } from '@components/general/row';
import { TouchableOpacityLink } from '@components/general/touchable-opacity-link';
import { useGetFile } from '@hooks/useGetFileUrl';
import { ActionList, Popover } from '@shopify/polaris';
import React from 'react';
import { Image, Text, View } from 'react-native';

import { styles } from './style';
import DefaultCover from '../../../../../assets/img/notifications/DefaultCover.png';

interface NotificationItemProps {
  unread?: boolean;
  image?: string;
  title: string;
  description: string | React.ReactNode;
  bottomText: string;
  href?: string;
  onPress: () => void;
  withDotsButton?: boolean;
  onPressView?: () => void;
  onPressRead?: () => void;
  notificationStatus?: React.ReactNode;
  notificationStatusColor?: string;
}

const NotificationItem = ({
  unread,
  image,
  title,
  description,
  bottomText,
  href,
  onPress,
  withDotsButton = false,
  onPressView,
  onPressRead,
  notificationStatus,
  notificationStatusColor,
}: NotificationItemProps) => {
  const [showDotsMenu, setShowDotsMenu] = React.useState(false);

  const { fileUrl } = useGetFile(image);

  const DotMenuButton = <DotsButton onPress={() => setShowDotsMenu(!showDotsMenu)} />;

  return (
    <View style={[styles.itemWrap, unread && styles.itemActive]}>
      <TouchableOpacityLink style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }} href={href} onPress={onPress}>
        <View style={styles.dotWrap}>{unread ? <View style={styles.dot} /> : null}</View>
        <View style={styles.itemContent}>
          <View style={styles.itemImageWrap}>
            <Image source={fileUrl ? { uri: fileUrl } : DefaultCover} style={styles.itemImage} />
          </View>
          <View style={styles.itemDetail}>
            <Row>
              <Text style={styles.itemTitle}>{title} </Text>
              {notificationStatus && notificationStatusColor ? (
                <Text
                  style={{
                    fontFamily: 'OpenSans_600SemiBold',
                    fontSize: 12,
                    lineHeight: 20,
                    marginLeft: 4,
                    color: notificationStatusColor,
                  }}
                >
                  {notificationStatus}
                </Text>
              ) : null}
            </Row>
            <Text style={styles.itemDescription} numberOfLines={1}>
              {description}
            </Text>
            <Text style={styles.itemInfo} numberOfLines={1}>
              {bottomText}
            </Text>
          </View>
        </View>
      </TouchableOpacityLink>
      {/* TODO: add mobile popover */}
      {withDotsButton ? (
        <View style={{ marginRight: 10 }}>
          <View>
            <Popover active={showDotsMenu} activator={DotMenuButton} onClose={() => setShowDotsMenu(!showDotsMenu)} preferredAlignment="right" fixed>
              <ActionList
                actionRole="menuitem"
                items={[
                  {
                    content: 'View',
                    onAction: () => onPressView && onPressView(),
                  },
                  {
                    content: 'Mark as read',
                    onAction: () => onPressRead && onPressRead(),
                  },
                ]}
              />
            </Popover>
          </View>
        </View>
      ) : null}
    </View>
  );
};

export { NotificationItem };
