import { FormField } from '@components/general/form/form-field';
import { QuestionModal } from '@components/roadmap/question-modal';
import { useUpdateRoadmapMutation } from '@gql/generated/generated';
import { IFieldTypes, ISubmodule } from '@utils/models';
import { Formik, FormikValues } from 'formik';
import { useState } from 'react';
import { Text, View } from 'react-native';

import { styles } from './style';

function convertObjectToAnswers(inputObj: FormikValues) {
  return Object.entries(inputObj).reduce(
    (accumulator, [key, value]) => {
      if (key === 'answers') {
        return { answers: [...accumulator.answers, ...value] };
      } else {
        const existingAnswerIndex = accumulator.answers.findIndex((answerObj) => answerObj.questionId === key);

        if (existingAnswerIndex > -1) {
          return {
            answers: [
              ...accumulator.answers.slice(0, existingAnswerIndex),
              { questionId: key, answer: JSON.stringify(value) },
              ...accumulator.answers.slice(existingAnswerIndex + 1),
            ],
          };
        } else {
          return {
            answers: [...accumulator.answers, { questionId: key, answer: JSON.stringify(value) }],
          };
        }
      }
    },
    { answers: [] as any[] }
  );
}

interface Props {
  isVisible: boolean;
  onClose: () => void;
  submodule: ISubmodule;
  roadmapSection: string;
}

// The `multiple_text` type uses an array so we serialise it into json to send to the backend.
const serialiseArrayAnswer = (answer: string | string[]): string => {
  return Array.isArray(answer) ? JSON.stringify(answer) : answer;
};

const deserialiseArrayAnswer = (answer: string): any => {
  try {
    const json = JSON.parse(answer);
    if (Array.isArray(json)) {
      return json;
    }
  } catch (err) {
    console.error('ERROR - FormModal - deserialiseArrayAnswer():', err);
  }
  return answer;
};

const FormModal = ({ submodule, isVisible, onClose, roadmapSection }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const [updateRoadmap, { loading }] = useUpdateRoadmapMutation();

  const getInitialValues = () =>
    submodule.questions.map((el) => {
      return {
        questionId: el.id,
        answer: deserialiseArrayAnswer(el.answer as string),
      };
    });

  const onSubmit = async (values: FormikValues) => {
    if (submodule.questions.length - 1 > currentIndex) {
      setCurrentIndex(currentIndex + 1);
    } else {
      // TODO: add types
      const converted = convertObjectToAnswers(values);

      await updateRoadmap({
        variables: {
          input: {
            [roadmapSection]: converted.answers.map((answer) => ({
              ...answer,
              answer: serialiseArrayAnswer(answer.answer),
            })),
          },
        },
      });
      setCurrentIndex(0);
      onClose();
    }
  };

  return (
    <Formik initialValues={{ answers: getInitialValues() }} onSubmit={onSubmit} enableReinitialize>
      {({ values, handleSubmit, errors, setFieldValue, touched }) => {
        return (
          <QuestionModal
            isVisible={isVisible}
            onClose={onClose}
            title={submodule.name}
            subtitle={`${currentIndex + 1} out of ${submodule.questions.length}`}
            secondaryButtonTitle={currentIndex > 0 ? 'Back' : undefined}
            primaryButtonTitle={currentIndex === submodule.questions.length - 1 ? 'Submit' : 'Next'}
            onPrimaryButtonPress={handleSubmit}
            onSecondaryButtonPress={() => currentIndex > 0 && setCurrentIndex(currentIndex - 1)}
            isLoading={loading}
          >
            <View style={styles.wrap}>
              {submodule.questions.map(
                (field, index) =>
                  index === currentIndex && (
                    <View key={field.id}>
                      {field.question && <Text style={styles.title}>{field.question}</Text>}
                      {field.description && <Text style={styles.description}>{field.description}</Text>}
                      <FormField
                        questionIndex={0}
                        field={field.type as IFieldTypes}
                        setFieldValue={(value) => {
                          const updateAnswers = values.answers.map((el: any) => ({
                            ...el,
                            answer: el.questionId === field.id ? value : el.answer,
                          }));

                          setFieldValue('answers', updateAnswers);
                        }}
                        fieldProps={{
                          value:
                            values.answers.find((element) => {
                              return element.questionId === field.id;
                            })?.answer || '',
                          placeholder: field.placeholder,
                          options: field.options,
                          label: '',
                          // @ts-ignore
                          error: errors[field.id] && touched[field.id] ? (errors[field.id] as string) : undefined,
                        }}
                      />
                    </View>
                  )
              )}
            </View>
          </QuestionModal>
        );
      }}
    </Formik>
  );
};

export { FormModal };
