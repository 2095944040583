import { ButtonGeneral } from '@components/general/button-general';
import { SelectOption } from '@components/general/form/select-option';
import { ContentBox } from '@components/general/layouts/content-box';
import { dateFromMillis } from '@utils/misc';
import React, { useState } from 'react';
import { Image, Text, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { styles } from './style';

interface ItemRowProps {
  name?: string | null;
  image?: string | null;
  date: string;
  message: string;
  isOnline: boolean;
  onView?: () => void;
}

const ItemRow = ({ name, image, date, message, isOnline, onView }: ItemRowProps) => (
  <View style={styles.itemRow}>
    <View style={styles.firstItemCell}>
      <View>
        <Image style={styles.userImage} source={{ uri: image || '' }} />
        <View style={[styles.presenceCircle, { backgroundColor: isOnline ? '#AEE9D1' : '#FED3D1' }]} />
      </View>
      <Text style={styles.userName}>{name}</Text>
    </View>
    <View style={styles.itemCellTwo}>
      <Text style={styles.cellText}>{date}</Text>
    </View>
    <View style={styles.itemCellThree}>
      <Text style={styles.cellText} ellipsizeMode="tail" numberOfLines={1}>
        {message}
      </Text>
    </View>
    <View style={styles.lastItemCell}>
      <ButtonGeneral label="View" onPress={onView} />
    </View>
  </View>
);

const TableHead = () => (
  <View style={styles.cellWrap}>
    <View style={styles.firstItemCell}>
      <Text style={styles.cellTitle}>User</Text>
    </View>
    <View style={styles.itemCellTwo}>
      <Text style={styles.cellTitle}>Date</Text>
    </View>
    <View style={styles.itemCellThree}>
      <Text style={styles.cellTitle}>Message</Text>
    </View>
    <View style={styles.lastItemCell} />
  </View>
);

const options = [
  { label: 'This Week', value: '7' },
  { label: 'Last 15 days', value: '15' },
];

interface Props {
  title: string;
  requests?:
    | {
        id: string;
        date: string;
        message: string;
        user: {
          userId: string;
          name?: string | null;
          img?: string | null;
          isOnline?: boolean | null;
        };
      }[]
    | null;
  refetch: (value: number) => void;
}

const AnalyticsRequest = ({ title, requests, refetch }: Props) => {
  const navigate = useNavigate();

  const [value, setValue] = useState('7');

  return (
    <ContentBox>
      <View style={styles.head}>
        <Text style={styles.title}>{title}</Text>
        <View style={styles.selectWrap}>
          <SelectOption
            onChange={(val) => {
              setValue(val);
              refetch(Number(val));
            }}
            value={value}
            options={options}
          />
        </View>
      </View>
      <View style={styles.tableWrap}>
        <View style={styles.tableInner}>
          <TableHead />
          {requests?.map((item) => (
            <ItemRow
              key={item.id}
              name={item.user.name}
              image={item.user.img}
              date={dateFromMillis(Number(item.date))}
              message={item.message}
              isOnline={item.user.isOnline || false}
              onView={() => navigate(item.id, { state: { request: item } })}
            />
          ))}
        </View>
      </View>
    </ContentBox>
  );
};
export { AnalyticsRequest };
