import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  head: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
  title: {
    color: '#0D1738',
    fontSize: 20,
    fontFamily: 'OpenSans_600SemiBold',
  },
  partner: {
    width: '100%',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 8,
    paddingBottom: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  partnerImage: {
    borderWidth: 1,
    borderColor: '#EDEEEF',
    borderRadius: 4,
    marginRight: 8,
    height: 52,
    width: 52,
    resizeMode: 'contain',
  },
  partnerName: {
    fontSize: 14,
    color: '#000000',
    fontFamily: 'OpenSans_600SemiBold',
    marginBottom: 4,
  },
  partnerCategory: {
    fontSize: 12,
    color: '#0D1738',
    fontFamily: 'OpenSans_400Regular',
  },
  viewAll: {
    borderTopWidth: 1,
    borderTopColor: '#EDEEEF',
    padding: 20,
  },
  viewAllTouch: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  viewAllText: {
    fontSize: 14,
    color: '#202223',
    marginRight: 10,
    fontFamily: 'OpenSans_400Regular',
  },
});
