import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { useGetUserQuery, User, PendingEmailVerification } from '@gql/generated/generated';
import { useGetAuthTokenAndUserId } from '@hooks/useGetAuthTokenAndUserId';
import { useHubSpotSession } from '@hooks/useHubSpotTracking';
import { usePostHogSession } from '@hooks/usePostHogSession';
import { getDefaultAvatar } from '@utils/misc';
import { createContext, ReactNode, useContext } from 'react';
import { ImageSourcePropType } from 'react-native';

interface UserContextProviderProps {
  children?: ReactNode;
}

export interface ContextUser extends User {
  defaultAvatar: ImageSourcePropType;
}

const UserContext = createContext<{
  currentUser?: ContextUser;
  isFreeUser?: boolean;
  loadingCurrentUser: boolean;
  pendingEmailVerification: PendingEmailVerification | null;
  checkUserHasAccess: (variables?: string[]) => boolean;
  refetch?: (variables?: Partial<OperationVariables>) => Promise<ApolloQueryResult<{ getUser: any }>>;
}>({
  currentUser: undefined,
  pendingEmailVerification: null,
  isFreeUser: false,
  checkUserHasAccess: () => false,
  loadingCurrentUser: false,
});

const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const { userId } = useGetAuthTokenAndUserId();

  const {
    data: queryResults,
    loading: loadingCurrentUser,
    refetch,
  } = useGetUserQuery({
    skip: !userId,
    fetchPolicy: 'cache-and-network',
  });

  const defaultAvatar = getDefaultAvatar(queryResults?.getUser?.defaultAvatarIndex || 1);

  const checkUserHasAccess = (userType: string[]) => {
    const lcUserType = userType?.map((t) => t.toLowerCase());
    return lcUserType?.includes('free') || lcUserType?.includes(queryResults?.getUser?.subscription?.plan?.toLowerCase() as string);
  };

  usePostHogSession(queryResults?.getUser as User);
  useHubSpotSession(queryResults?.getUser as User);

  const values = {
    currentUser: queryResults?.getUser ? { ...queryResults?.getUser, defaultAvatar } : undefined,
    pendingEmailVerification: queryResults?.getPendingEmailVerification,
    isFreeUser: queryResults?.getUser?.subscription?.plan?.toLowerCase() === 'free',
    loadingCurrentUser,
    checkUserHasAccess,
    refetch,
  };

  //@ts-ignore - now getting warnings about legacy dateAssigned property that is potentially missing from some user assignedItems, this can be ignored for now but needs resolving
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

const useUserContext = () => useContext(UserContext);

export { UserContextProvider, useUserContext };
