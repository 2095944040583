import { DoubleArrow } from '@components/general/icons/DoubleArrow';
import { ActionList, Popover } from '@shopify/polaris';
import { Field, FormikProps, FormikValues, useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { styles } from './styles';

interface CheckMenuFormikProps {
  data: { value: string; label: string }[];
  formikKey: string;
  title: string;
}

const CheckMenuFormik = ({ data, formikKey, title }: CheckMenuFormikProps) => {
  const { values }: FormikProps<FormikValues> = useFormikContext();

  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const activator = (
    <View style={styles.dropMenuContainer}>
      <TouchableOpacity
        onPress={toggleActive}
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Text numberOfLines={1}>{values[formikKey]?.length > 0 ? values[formikKey].join(', ') : 'Please Select'}</Text>
        <DoubleArrow />
      </TouchableOpacity>
    </View>
  );

  const actionListItems = data.map((item) => {
    return {
      helpText: (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Field
            type="checkbox"
            name={formikKey}
            value={item.value}
            // checked={values[formikKey].includes(item.value)}
          />
          <Text
            style={{
              marginLeft: 8,
            }}
          >
            {item.value}
          </Text>
        </View>
      ),
    };
  });

  return (
    <View
      style={{
        paddingHorizontal: 40,
      }}
    >
      <Text
        style={{
          marginBottom: 4,
          fontFamily: 'OpenSans_500Medium',
          fontSize: 17,
          color: '#202223',
        }}
      >
        {title}
      </Text>
      <Popover active={active} activator={activator} onClose={toggleActive} preferredPosition="below" preferredAlignment="left" fullWidth>
        <ActionList actionRole="menuitem" items={actionListItems} />
      </Popover>
    </View>
  );
};

export { CheckMenuFormik };
