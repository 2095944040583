import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { ISvgProps } from '../../../../types';

const KeyIcon = ({ color = '#fff', ...props }: ISvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={9} fill="none" {...props}>
    <Path
      fill={color}
      d="M11.995.674a3.939 3.939 0 0 0-2.618.997 4.102 4.102 0 0 0-1.336 2.507H4.5c-.14-.55-.47-1.03-.93-1.35a2.247 2.247 0 0 0-2.998.39A2.358 2.358 0 0 0 0 4.762C0 5.33.203 5.88.57 6.306a2.247 2.247 0 0 0 2.998.39c.46-.32.791-.8.93-1.35h3.543A4.13 4.13 0 0 0 8.98 7.44a3.991 3.991 0 0 0 1.897 1.244c.736.22 1.518.22 2.254-.002a3.992 3.992 0 0 0 1.895-1.247 4.13 4.13 0 0 0 .935-2.096 4.168 4.168 0 0 0-.323-2.28 4.062 4.062 0 0 0-1.478-1.74 3.938 3.938 0 0 0-2.164-.646zm0 7.008a2.813 2.813 0 0 1-1.587-.492 2.907 2.907 0 0 1-1.052-1.31 2.98 2.98 0 0 1-.163-1.688 2.94 2.94 0 0 1 .782-1.495c.4-.408.909-.686 1.463-.799a2.8 2.8 0 0 1 1.65.166c.523.221.969.596 1.283 1.076a2.966 2.966 0 0 1-.355 3.687 2.826 2.826 0 0 1-2.02.855z"
    />
  </Svg>
);

export { KeyIcon };
