import React from 'react';
import { View, ViewProps } from 'react-native';

import { ids, styles } from './style';

const Row = ({ children, style, ...rest }: ViewProps) => (
  <View style={[styles.row, style]} {...rest} dataSet={{ media: ids.row }}>
    {children}
  </View>
);

export { Row };
