import { TableDropdown } from '@components/back-office/tables/table-dropdown';

import { useQueryFilters } from '../useGridFilters';

const sortByItems = [
  { id: 'Newest first', content: 'Newest first' },
  { id: 'Oldest first', content: 'Oldest first' },
  { id: 'Highest score', content: 'Highest score' },
  { id: 'Lowest score', content: 'Lowest score' },
];

export const SortByFilter = () => {
  const { filters, setFilterValue } = useQueryFilters(['sortBy']);
  return (
    <TableDropdown
      placeholder={filters?.sortBy ? filters?.sortBy : 'Sort by'}
      items={sortByItems}
      onSelectItem={(id) => setFilterValue('sortBy', id)}
      minWidth={130}
      isSelected={!!filters.sortBy}
    />
  );
};
