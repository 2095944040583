import { Recommendations, RoadmapTemplate, useGetAllRecommendationsQuery } from '@gql/generated/generated';

export const getCategoriesFromRoadmapTemplate = (roadmapTemplate: RoadmapTemplate) =>
  roadmapTemplate?.pulse.map(({ id, name }) => ({
    id,
    content: name,
  })) || [];

export const getSubcategoriesFromRoadmapTemplate = (roadmapTemplate: RoadmapTemplate, selectedDropdownItem?: string) =>
  roadmapTemplate.pulse.find(({ id }) => id === selectedDropdownItem)?.subcategories || [];

export type Params = {
  categoryId: string;
  subcategoryId: string;
  score: string;
};

export const getSectionIdFromParams = ({ categoryId, subcategoryId, score }: Params) => `${categoryId}/${subcategoryId}/${score}`;

export const useGetAllRecommendations = () => {
  const { data: allRecommendations, loading } = useGetAllRecommendationsQuery();

  const recommendations = (allRecommendations?.getAllRecommendations as Recommendations)?.recommendations || [];

  return { recommendations, loading };
};
