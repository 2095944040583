import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
  },
  titleWrap: {
    width: '100%',
    paddingLeft: 40,
    paddingTop: 40,
  },
  title: {
    color: '#202223',
    fontSize: 20,
    fontFamily: 'OpenSans_600SemiBold',
  },
  tabItem: {
    padding: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabTitle: {
    color: '#6D7175',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
  },
  tabTitleSelected: {
    color: '#202223',
  },
  bottomBorder: {
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    width: '100%',
    height: 3,
  },
  selectedBottomBorder: {
    backgroundColor: '#2C6ECB',
  },
  tabSelection: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
});
