import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  titleWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
  },

  backOfficeHeaderTitle: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 26,
    lineHeight: 32,
    color: '#0D1738',
  },

  // add edit content
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    marginBottom: 12,
  },
  headerMainTitle: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 26,
    lineHeight: 32,
    color: '#0D1738',
    marginBottom: 16,
    marginRight: 16,
  },
  headerBackBtn: {
    borderWidth: 1,
    borderColor: '#BABFC3',
    borderRadius: 4,
    width: 36,
    height: 36,
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerArrowLeft: {
    width: 7,
    height: 12,
  },
  wrapper: {
    paddingVertical: 40,
    paddingHorizontal: 64,
  },
  sectionWrapper: {
    paddingHorizontal: 20,
    marginBottom: 42,
  },
  textRegularDark: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#202223',
  },
  textBoldDarkSmall: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 20,
    color: '#0D1738',
  },
  textBoldDark: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 16,
    lineHeight: 20,
    color: '#202223',
  },
  categoryTags: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    lineHeight: 16,
    color: '#0D1738',
    paddingVertical: 2,
    paddingHorizontal: 8,
    borderRadius: 15,
    marginRight: 5,
    backgroundColor: '#E4E5E7',
  },
});
