import { TitleBox } from '@components/account/title-box';
import { ContentBox } from '@components/general/layouts/content-box';
import { AllMembersContainerList } from '@components/org/members/all-member-list-container';
import { AllMemberCards } from '@components/org/members/all-members-cards';
import React from 'react';
import { useNavigate } from 'react-router-native';

const AllMembers = () => {
  const navigate = useNavigate();

  return (
    <ContentBox>
      <TitleBox title="All Members" backTitle="Back to Members" hasBottomBorder onPressBack={() => navigate(-1)} />
      <AllMembersContainerList />
      <AllMemberCards />
    </ContentBox>
  );
};

export { AllMembers };
