import { useUserContext } from '@context/UserContext';
import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY } from '@env';
import { Content, Event, Partner, Training, useUpdateUserMutation } from '@gql/generated/generated';
import { useFeatureFlag } from '@hooks/useFeatureFlag';
import algoliaClient from 'algoliasearch/lite';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { formatAsAlgoliaFilters, getIncludedSubscriptionLevels } from './utils';

export type AlgoliaProps = {
  path: string;
  objectID: string;
};

export type UserAccessProps = {
  userHasAccess?: boolean;
  userType?: string[];
};

const algolia = algoliaClient(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);

const querySuggestionsIndex = algolia.initIndex('all-data-index-query-suggestions');

const getQuerySuggestions = (query: any, hitsPerPage: number, params = {}) =>
  querySuggestionsIndex.search(query, {
    hitsPerPage,
    ...params,
  });

type THits =
  | (Event & AlgoliaProps & UserAccessProps)
  | (Training & AlgoliaProps & UserAccessProps)
  | (Content & AlgoliaProps & UserAccessProps)
  | (Partner & AlgoliaProps & UserAccessProps);

type Props = {
  query?: string;
  hitsPerPage?: number;
  visibleHitsPerPage?: number;
  indexName?: string;
  filterResultsByUserSubscriptionPlan?: boolean;
  publishedOnly?: boolean;
};

const useAlgoliaSearch = ({
  query,
  hitsPerPage = 40,
  // because of zombie incomplete records in Algolia (without a path field) we have to ask for a larger number
  // of hitsPerPage and then use visibleHitsPerPage to decide how many to show
  visibleHitsPerPage = 6,
  indexName: index = 'all-data-index',
  filterResultsByUserSubscriptionPlan: filterBySubscription = true,
  publishedOnly = true,
}: Props) => {
  const { currentUser: user, isFreeUser } = useUserContext();
  const userSubscriptionLevel = user?.subscription?.plan;

  let indexName = index;
  let filterResultsByUserSubscriptionPlan = filterBySubscription;

  // POSTHOG FEATURE FLAG START for all-data-index searches
  const isFreeUsersSearchShowPremium = useFeatureFlag(['free-users-search-show-premium']);
  const isFreeUsersSearchDerankPremium = useFeatureFlag(['free-users-search-derank-premium']);

  if (isFreeUser && indexName === 'all-data-index') {
    if (isFreeUsersSearchShowPremium) {
      filterResultsByUserSubscriptionPlan = false;

      if (isFreeUsersSearchDerankPremium) {
        indexName = 'all-data-index_free_desc';
      }
    }
  }
  // POSTHOG FEATURE FLAG END

  // TODO this logic (for setting userHasAccess) should be on the backend as a more robust implementation,
  // and also to be able to only send a small subset of data to the client for no-access search results.
  // It has been placed on the frontend as the all-data-index is currently consumed directly from the frontend
  // and a backend implementation was out of scope
  const addUserAccessToResults = useCallback(
    (hits: THits[]) =>
      hits.map((hit) => {
        const lcHitUserType = hit.userType?.map((h) => h.toLowerCase());
        const isFreeHit = lcHitUserType?.includes('free') || lcHitUserType?.includes('all');
        return {
          ...hit,
          userHasAccess: isFreeUser ? isFreeHit : true,
        };
      }),
    [isFreeUser]
  );

  const [results, setResults] = useState<THits[]>([]);
  const [querySuggestions, setQuerySuggestions] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [updateUser] = useUpdateUserMutation();

  const updateRecentSearches = useCallback(
    (queryString: string) => {
      updateUser({
        variables: {
          input: {
            user: {
              recentSearch: queryString,
            },
          },
        },
      }).catch(console.error);
    },
    [updateUser]
  );

  const updateResults = useMemo(
    () =>
      debounce(async (query) => {
        if (filterResultsByUserSubscriptionPlan && !userSubscriptionLevel) {
          return;
        }

        try {
          setIsLoading(true);
          const index = algolia.initIndex(indexName);
          const filtersArr = [];
          if (publishedOnly) {
            filtersArr.push('status:Publish');
          }
          // NB we don't filterResultsByUserSubscriptionPlan for free users
          // instead we fetch all results from a pre-sorted replica
          if (filterResultsByUserSubscriptionPlan) {
            filtersArr.push(formatAsAlgoliaFilters(getIncludedSubscriptionLevels(userSubscriptionLevel || '')));
          }
          const filters = filtersArr.join(' AND ');

          const { hits } = await index.search<THits>(query, {
            filters,
            hitsPerPage,
          });
          setResults(addUserAccessToResults(hits));
        } catch (err) {
          console.log({ err });
        } finally {
          updateRecentSearches(query);
          setIsLoading(false);
        }
      }, 350),
    [filterResultsByUserSubscriptionPlan, hitsPerPage, indexName, updateRecentSearches, userSubscriptionLevel, addUserAccessToResults, publishedOnly]
  );

  useEffect(() => {
    if (query) {
      updateResults(query);
    }
  }, [query, updateResults]);

  useEffect(() => {
    getQuerySuggestions(query, hitsPerPage)
      .then(({ hits }: any) => {
        setQuerySuggestions(hits);
      })
      .catch(() => {
        // ignore
      });
  }, [hitsPerPage, query]);

  // pre-filter results into ones that can actually be displayed on the page
  // TODO - this only partially works because we currently only get one page of results in a search. If we introduce search pagination then we need to set up an attribute in algolia.
  // Also this FE filtering will work less well as we have more and more past events in all-data-index
  let pageResults;

  // Filter out any zombie records without a path field
  pageResults = results.filter((r) => !!r.path);

  // Filter out any event results which occurred in the past
  const twentyFourHoursAgo = Date.now() - 60 * 60 * 24 * 1000;
  pageResults = pageResults.filter((result) => {
    // keep if not an event
    if (result.path && !result.path?.includes('Events')) {
      return true;
    }
    // keep if an event's dateFrom is newer than twenty-four-hours-ago
    if (result.path && result.path?.includes('Events')) {
      return (result as Event)?.dateFrom && ((result as Event)?.dateFrom || 0) >= twentyFourHoursAgo;
    }
    return false;
  });

  let eventsResults = pageResults.filter((result) => result.path?.includes('Events') || false) as (Event & AlgoliaProps & UserAccessProps)[];
  let contentResults = pageResults.filter((result) => result.path?.includes('Content') || false) as (Content & AlgoliaProps & UserAccessProps)[];
  let trainingsResults = pageResults.filter((result) => result.path?.includes('Training') || false) as (Training & AlgoliaProps & UserAccessProps)[];
  let partnersResults = pageResults.filter((result) => result.path?.includes('Partners') || false) as (Partner & AlgoliaProps & UserAccessProps)[];
  let allResults = [...eventsResults, ...contentResults, ...trainingsResults, ...partnersResults];

  const numEvents = Math.ceil((eventsResults.length / allResults.length) * visibleHitsPerPage);
  let numContent = Math.ceil((contentResults.length / allResults.length) * visibleHitsPerPage);
  const numTrainings = Math.ceil((trainingsResults.length / allResults.length) * visibleHitsPerPage);
  const numPartners = Math.ceil((partnersResults.length / allResults.length) * visibleHitsPerPage);
  const totalVisibleResults = numEvents + numContent + numTrainings + numPartners;

  // if too many content results
  if (totalVisibleResults > visibleHitsPerPage && numContent >= totalVisibleResults / 2) {
    numContent = numContent - (totalVisibleResults - visibleHitsPerPage);
  }

  eventsResults = eventsResults.slice(0, numEvents);
  contentResults = contentResults.slice(0, numContent);
  trainingsResults = trainingsResults.slice(0, numTrainings);
  partnersResults = partnersResults.slice(0, numPartners);
  allResults = [...eventsResults, ...contentResults, ...trainingsResults, ...partnersResults];

  return {
    results: allResults,
    eventsResults,
    contentResults,
    trainingsResults,
    partnersResults,
    querySuggestions,
    isLoading,
    isFreeUser,
  };
};

export { useAlgoliaSearch };
