import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  moduleWrap: {
    borderTopWidth: 1,
    borderTopColor: '#EDEEEF',
  },
  itemRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 40,
    paddingRight: 40,
    width: '100%',
  },
  itemCell: {
    width: '10%',
  },
  itemCellTwo: {
    width: '27%',
  },
  firstItemCell: {
    width: '3%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  lastItemCell: {
    width: '10%',
    alignItems: 'flex-end',
  },
  cellText: {
    fontSize: 14,
    color: '#0D1738',
    fontFamily: 'OpenSans_400Regular',
    width: '100%',
  },
  touchBox: {
    borderWidth: 1,
    borderColor: '#D2D5D8',
    borderRadius: 4,
    height: 36,
    width: 36,
    alignItems: 'center',
    justifyContent: 'center',
  },
  trainingWrap: {
    borderTopWidth: 1,
    borderTopColor: '#EDEEEF',
    width: '100%',
  },
  trainingInner: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 40,
    paddingRight: 40,
    justifyContent: 'space-between',
  },
  trainingModuleWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  trainingModuleTitle: {
    color: '#0D1738',
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
  },
  trainingModuleIndex: {
    marginRight: 16,
  },
  trainingInfoWrap: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 16,
    paddingBottom: 16,
    flexDirection: 'row',
    ...(Platform.OS === 'web' && { columnGap: 16 }),
  },
  trainingModuleTitleWrap: {
    width: '150px',
  },
  lessonsText: {
    color: '#6D7175',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
  },
  cellTitle: {
    fontSize: 12,
    fontFamily: 'OpenSans_600SemiBold',
    color: '#6D7175',
  },
  progressItemWrap: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    ...(Platform.OS === 'web' && { columnGap: 20 }),
  },
  progressBar: {
    height: 32,
    borderRadius: 4,
    backgroundColor: '#E4E5E7',
    width: 461,
  },
  progressInner: {
    height: '100%',
    borderRadius: 4,
    backgroundColor: '#2C6ECB',
    justifyContent: 'center',
  },
  progressInnerInactive: {
    backgroundColor: '#D72C0D',
  },
  progressInnerText: {
    color: '#FFFFFF',
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
    paddingLeft: 16,
    paddingRight: 8,
  },
  viewTouch: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  viewText: {
    marginLeft: 6,
  },
  viewClose: {
    color: '#D72C0D',
  },
  lessonsWrap: {
    flex: 1,
    flexDirection: 'column',
    ...(Platform.OS === 'web' && { gap: 8 }),
  },
  head: {
    padding: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 20,
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
  },
  selectWrap: {
    width: 160,
  },
  tableWrap: {
    overflow: 'scroll',
  },
  tableInner: {
    minWidth: 800,
    flex: 1,
  },
  leftHeadWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchBox: {
    borderWidth: 1,
    borderColor: '#C9CCCF',
    borderRadius: 4,
    height: 36,
    width: 230,
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 8,
    paddingLeft: 8,
    paddingRight: 8,
  },
  searchIcon: {
    width: '12%',
  },
  searchInput: {
    width: '88%',
    height: '100%',
    ...(Platform.OS === 'web' && { outline: 'none' }),
  },
  usersPopover: {
    height: 248,
    width: 553,
    maxWidth: '100%',
    overflow: 'scroll',
    padding: 8,
    ...(Platform.OS === 'web' && { gap: 8 }),
  },
  userItem: {
    flexDirection: 'row',
    alignItems: 'center',
    ...(Platform.OS === 'web' && { columnGap: 8 }),
  },
  userItemCellOne: {
    width: '5%',
  },
  userItemCell: {
    width: '28.4%',
  },
  userItemLastCell: {
    width: '10%',
  },
  userName: {
    fontSize: 12,
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
  },
  userCellText: {
    fontSize: 12,
    color: '#0D1738',
    fontFamily: 'OpenSans_400Regular',
  },
  userImageWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  userImage: {
    height: 40,
    width: 40,
    borderRadius: 20,
    marginRight: 8,
  },
});
