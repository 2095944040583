import { InputError } from '@components/general/form/input-error';
import { ErrorIcon } from '@components/general/icons';
import React from 'react';
import { View } from 'react-native';

import { styles } from './style';

interface Props {
  error?: string;
}

const ErrorBox = ({ error }: Props) => (
  <View style={styles.errorTextContainer}>
    <View style={styles.errorIconWrap}>
      <ErrorIcon />
    </View>
    <View style={styles.errorTextWrap}>
      <InputError error={error} />
    </View>
  </View>
);

export { ErrorBox };
