import { ContentBox } from '@components/general/layouts/content-box';
import { AnalyticsHeader } from '@components/org/analytics/analytics-header';
import { AnalyticsMemberCount } from '@components/org/analytics/analytics-member-count';
import { AnalyticsMembersOverview } from '@components/org/analytics/analytics-members-overview';
import { AnalyticsTrainingProgress } from '@components/org/analytics/analytics-training-progress';
import { useMembersAnalytics } from '@components/org/analytics/useMembersAnalytics';
import React from 'react';

const AnalyticsMembers = () => {
  const {
    activeMembersToday,
    averageTimeSpentToday,
    newMembersToday,
    selected,
    setSelected,
    dataForGraph,
    refetchData,
    dataForExport,
    loading,
    users,
    page,
    setPage,
    refetchTraining,
  } = useMembersAnalytics();

  return (
    <ContentBox>
      <AnalyticsHeader title="Members" excelData={dataForExport} />
      <AnalyticsMemberCount newMembers={newMembersToday} activeMembersToday={activeMembersToday} averageTimeSpentToday={averageTimeSpentToday} />
      <AnalyticsMembersOverview selected={selected} setSelected={setSelected} overviewData={dataForGraph} refetch={refetchData} />
      <AnalyticsTrainingProgress loading={loading} page={page} setPage={setPage} refetch={refetchTraining} users={users} />
    </ContentBox>
  );
};

export { AnalyticsMembers };
