// Import the functions you need from the SDKs you need
import {
  FIREBASE_CONFIG_API_KEY,
  FIREBASE_CONFIG_APP_ID,
  FIREBASE_CONFIG_AUTH_DOMAIN,
  FIREBASE_CONFIG_MEASUREMENT_ID,
  FIREBASE_CONFIG_MESSAGING_SENDER_ID,
  FIREBASE_CONFIG_PROJECT_ID,
  FIREBASE_CONFIG_STORAGE_BUCKET,
} from '@env';
import { getAnalytics, isSupported as isAnalyticsSupported } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: FIREBASE_CONFIG_API_KEY,
  authDomain: FIREBASE_CONFIG_AUTH_DOMAIN,
  projectId: FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_CONFIG_MESSAGING_SENDER_ID,
  appId: FIREBASE_CONFIG_APP_ID,
  measurementId: FIREBASE_CONFIG_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

isAnalyticsSupported().then((isSupported) => {
  if (isSupported) {
    getAnalytics(app);
  }
});

const auth = getAuth(app);
const storage = getStorage(app);

export { app, auth, storage };
