import { FormSection } from '@components/back-office/editor/form-section/FormSection';
import { FormikDatePickerDropdown } from '@components/back-office/editor/formik-date-picker-dropdown/FormikDatePickerDropdown';
import { FormikInput } from '@components/back-office/editor/formik-input';
import { FormikList } from '@components/back-office/editor/formik-list';
import { FormikPublishTools } from '@components/back-office/editor/formik-publish-tools/FormikPublishTools';
import { FormikSaveBar } from '@components/back-office/editor/formik-save-bar';
import { FormikTextArea } from '@components/back-office/editor/formik-text-area/FormikTextArea';
import { useToast } from '@context/ToastContext';
import { PollStatus, useAdminGetPollQuery, useCreatePollMutation, useUpdatePollMutation } from '@gql/generated/generated';
import { Icon } from '@shopify/polaris';
import { ChevronLeftMinor } from '@shopify/polaris-icons';
import { UpdateContext } from '@utils/UpdateContext';
import { Formik } from 'formik';
import React, { useContext } from 'react';
import { ActivityIndicator, Platform, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate, useParams } from 'react-router-native';

import { styles } from './style';

const PollEditor = () => {
  const { addToast } = useToast();

  const [createPoll] = useCreatePollMutation();

  const [updatePoll] = useUpdatePollMutation();

  const navigation = useNavigate();
  const UpdateStatus = useContext(UpdateContext);

  const { id } = useParams() as { id: string };

  const { data: existingPollData, loading: loadingExistingPoll } = useAdminGetPollQuery({
    variables: { id },
    skip: !id,
  });

  const existingPoll = existingPollData?.adminGetPoll;

  if (loadingExistingPoll) {
    return (
      <View
        style={{
          height: Platform.OS === 'web' ? '100vh' : '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  }

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Formik
        initialValues={{
          id: existingPoll?.id,
          pollQuestion: existingPoll?.pollQuestion ?? '',
          pollOptions: existingPoll?.pollOptions ? existingPoll.pollOptions.map((option) => option.text) : [],
          description: existingPoll?.description ?? '',
          status: existingPoll?.status ?? 'Draft',
          pollStatus: existingPoll?.pollStatus ?? PollStatus.DEFAULT,
          closeDate: existingPoll?.closeDate ?? Date.now(),
        }}
        // TODO: validationSchema
        onSubmit={async (values) => {
          const { id: existingPollId, closeDate, description, pollOptions, pollQuestion, status } = values;

          if (existingPollId) {
            const res = await updatePoll({
              variables: {
                input: {
                  id: existingPollId,
                  closeDate,
                  description,
                  pollOptions,
                  pollQuestion,
                  status,
                },
              },
            });

            if (res?.data?.updatePoll?.id) {
              addToast('success', 'Poll Sucessfully Updated!');
              navigation('/polls');
            }
          } else {
            const res = await createPoll({
              variables: {
                input: {
                  closeDate,
                  description,
                  pollOptions,
                  pollQuestion,
                  status,
                },
              },
            });
            if (res?.data?.createPoll?.id) {
              addToast('success', 'Poll Sucessfully Added!');
              setTimeout(() => navigation(`/polls`), 1500);
            }
          }
        }}
      >
        {(props) => {
          return (
            <>
              <FormikSaveBar />
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 12,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    UpdateStatus.update(0);
                    navigation(-1);
                  }}
                  style={{
                    borderWidth: 1,
                    borderColor: '#BABFC3',
                    borderRadius: 4,
                    width: 36,
                    height: 36,
                    marginRight: 8,
                  }}
                >
                  <Icon source={ChevronLeftMinor} />
                </TouchableOpacity>
                <Text style={styles.title}>{id ? 'Update' : 'Add New'} Poll</Text>
              </View>
              <View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                    }}
                  >
                    <FormSection>
                      <View style={{ marginBottom: 16 }}>
                        <FormikInput fieldName="pollQuestion" label="Poll Question" placeholder="Add a question here" />
                      </View>
                      <View style={{ marginBottom: 16 }}>
                        <FormikList title="Poll Options" subtitle="" fieldName="pollOptions" initialNumberOfItems={4} placeholder="Poll Option" />
                      </View>
                      <View style={{ marginBottom: 16 }}>
                        <FormikTextArea fieldName="description" label="Description" maxLength={150} placeholder="Add poll description here" />
                      </View>
                      <Text
                        style={{
                          fontSize: 14,
                          lineHeight: 20,
                          fontFamily: 'OpenSans_600SemiBold',
                        }}
                      >
                        Close On
                      </Text>
                      <FormikDatePickerDropdown
                        dateValue={props.values.closeDate}
                        placeholderText="Select Date"
                        onChange={(v) => props.setFieldValue('closeDate', v.start.getTime())}
                        onlyFutureDates
                      />
                    </FormSection>
                  </View>
                  <View style={{ marginLeft: 20 }}>
                    <FormikPublishTools withClosePoll withCategories={false} withUserType={false} withJobRoles={false} />
                  </View>
                </View>
              </View>
            </>
          );
        }}
      </Formik>
    </View>
  );
};

export { PollEditor };
