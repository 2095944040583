import React from 'react';
import { Text, View } from 'react-native';

import { ids, styles } from './style';

export interface Props {
  label?: string;
  isOptional?: boolean;
}

const Label = ({ label, isOptional }: Props) => (
  <View style={styles.wrap} dataSet={{ media: ids.wrap }}>
    <Text style={styles.label}>{label}</Text>
    {isOptional && <Text style={styles.optional}>Optional</Text>}
  </View>
);

export { Label };
