import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path, SvgProps } from 'react-native-svg';

interface Props extends SvgProps {
  color?: string;
}

const TrainingIcon = (props: Props) => (
  <Svg width={21} height={21} fill="none" {...props}>
    <G clipPath="url(#a)" fill={props.color || '#5C5F62'}>
      <Path d="m.5 6.475 10 5L19.25 7.1v10.625h1.25V6.475l-10-5-10 5Z" />
      <Path d="M4.25 9.049V14.6l6.25 3.125 6.25-3.125V9.05l-6.25 3.125-6.25-3.125Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" transform="translate(.5 .225)" d="M0 0h20v20H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export { TrainingIcon };
