import { ButtonGeneral, ButtonType } from '@components/general/button-general';
import { Input } from '@components/general/form/input';
import { ISelectOption, SelectOption } from '@components/general/form/select-option';
import { AuthWrapper } from '@components/sign-up/auth-wrapper';
import { ErrorBox } from '@components/sign-up/error-box';
import { AccountType, useCreateUserMutation, useGetJobTitlesQuery, useGetOrganisationByDomainQuery } from '@gql/generated/generated';
import { EN_COUNTRIES, EN_UK_COUNTIES, TCountryNameWithUKCountiesEn } from '@pages/back-office/analytics-back-office/world';
import { getErrorMessage } from '@utils/misc';
import { AuthError } from 'firebase/auth';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Linking, Text, View } from 'react-native';
import { useNavigate } from 'react-router-native';
import * as Yup from 'yup';

import { ids, styles } from './style';
import { FormValues } from '../SignUp';

interface Props {
  prevValues: FormValues;
  onPressBack: () => void;
}

export interface MoreDetailsForm {
  companyName: string;
  jobPosition: string;
  country: TCountryNameWithUKCountiesEn | '';
}

const countries = [...EN_UK_COUNTIES, ...EN_COUNTRIES];

const MoreDetails = ({ prevValues, onPressBack }: Props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const { data: jobTitlesData } = useGetJobTitlesQuery();

  const jobTitles = jobTitlesData?.getJobTitles?.jobTitles?.map((jobTitle) => ({
    label: jobTitle.name,
    value: jobTitle.name,
  }));

  const [createUser] = useCreateUserMutation();
  const { data: organisationData } = useGetOrganisationByDomainQuery({
    variables: { email: prevValues.email },
    skip: prevValues.accountType === AccountType.EMPLOYER || prevValues.email === '',
  });

  const organisationId =
    organisationData?.getOrganisationByDomain && organisationData.getOrganisationByDomain.__typename === 'OrganisationByDomain'
      ? organisationData?.getOrganisationByDomain?.id
      : undefined;

  // first 2 conditions needs duplicating to make tslint happy.
  const companyName =
    organisationData?.getOrganisationByDomain && organisationData.getOrganisationByDomain.__typename === 'OrganisationByDomain'
      ? organisationData?.getOrganisationByDomain?.companyName
      : undefined;

  const onSubmit = async (formikValues: MoreDetailsForm) => {
    setIsLoading(true);
    try {
      const { accountType, email, password, phoneNumber, firstName, lastName } = prevValues;

      const result = (
        await createUser({
          variables: {
            input: {
              accountType,
              email,
              phoneNumber,
              firstName,
              lastName,
              password,
              organisationId,
              ...formikValues,
            },
          },
        })
      ).data?.createUser;

      if (!result) return;

      // TODO Posthog tracking here for user creation

      if (result.__typename === 'User' && result.id) {
        setIsLoading(false);
        navigate(`/verify-email`, { state: { userId: result.id, email } });
        return;
      }

      throw result;
    } catch (error) {
      const authError = error as AuthError;
      setErrorMsg(getErrorMessage(authError));

      setIsLoading(false);
    }
  };

  const handleTermsAndPrivacy = () => {
    Linking.openURL(`https://therecruitmentnetwork.com/privacy-policy`);
  };

  return (
    <AuthWrapper title="Tell us more about your business" subtitle="Last step to go!" onPressBack={onPressBack}>
      <View>
        <Formik
          initialValues={{
            companyName: companyName || '',
            jobPosition: '',
            country: '',
          }}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={Yup.object().shape({
            companyName: Yup.string().required('This field is required'),
            jobPosition: Yup.string().required('This field is required'),
            country: Yup.string().required('This field is required'),
          })}
        >
          {({ values: val, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
            <View>
              {errorMsg !== '' ? <ErrorBox error={errorMsg} /> : null}
              <View style={styles.inputWrap}>
                <Input
                  placeholder="Company Name"
                  value={val.companyName}
                  editable={!organisationId}
                  selectTextOnFocus={!organisationId}
                  onChangeText={handleChange('companyName')}
                  error={touched.companyName && errors.companyName ? (errors.companyName as string) : undefined}
                />
              </View>
              <View style={styles.inputWrap}>
                <SelectOption
                  placeholder="Job Title"
                  options={jobTitles || []}
                  value={val.jobPosition}
                  onChange={(selected) => setFieldValue('jobPosition', selected)}
                  error={touched.jobPosition && errors.jobPosition ? (errors.jobPosition as string) : undefined}
                />
              </View>
              <View style={styles.inputWrap}>
                <SelectOption
                  placeholder="Head Office Location"
                  options={countries as ISelectOption<TCountryNameWithUKCountiesEn>[]}
                  value={val.country}
                  onChange={(selected) => setFieldValue('country', selected)}
                  error={touched.country && errors.country ? (errors.country as TCountryNameWithUKCountiesEn) : undefined}
                />
              </View>
              <ButtonGeneral bold label="Sign Up" isLoading={isLoading} onPress={handleSubmit} type={ButtonType.user} style={styles.submitButton} />
            </View>
          )}
        </Formik>
        <View style={styles.register} dataSet={{ media: ids.register }}>
          <Text style={styles.noAccount}>
            By signing up, I agree to the
            <Text style={styles.registerLink} onPress={() => handleTermsAndPrivacy()}>
              &nbsp;Privacy Policy
            </Text>
          </Text>
        </View>
      </View>
    </AuthWrapper>
  );
};

export { MoreDetails };
