import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { ISvgProps } from '../../../../types';

const TwitterIcon = ({ color = '#0D1738', ...props }: ISvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={17} height={14} fill="none" {...props}>
    <Path
      fill={color}
      d="M14.476 3.74c.006.146.007.292.007.438 0 4.418-3.108 9.519-8.792 9.519-1.746 0-3.369-.557-4.737-1.504.242.027.487.047.737.047 1.447 0 2.78-.537 3.838-1.438-1.352-.02-2.494-.994-2.887-2.319a2.828 2.828 0 0 0 1.397-.06C2.624 8.12 1.56 6.769 1.56 5.146v-.04c.416.245.894.397 1.4.417-.83-.603-1.376-1.63-1.376-2.789 0-.609.152-1.185.42-1.682C3.528 3.079 5.806 4.41 8.374 4.55a3.641 3.641 0 0 1-.08-.762c0-1.848 1.383-3.346 3.09-3.346.89 0 1.692.405 2.255 1.054a5.8 5.8 0 0 0 1.964-.808 3.304 3.304 0 0 1-1.36 1.848 5.795 5.795 0 0 0 1.775-.524 6.604 6.604 0 0 1-1.541 1.73z"
    />
  </Svg>
);

export { TwitterIcon };
