import { mediaQuery } from '@utils/misc';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  rightSideContainer: {
    flexDirection: 'row',
    flex: 1,
    [mediaQuery.forMobile]: {
      borderWidth: 1,
      borderColor: 'red',
    },
  },
  image: {
    marginRight: 40,
  },
  paymentInfoContainer: {
    flexDirection: 'row',
    marginTop: 2,
    marginBottom: 30,
  },
  paymentInfo: {
    marginRight: 20,
  },
  paymentInfoTitle: {
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
    color: '#202223',
    lineHeight: 20,
  },
  paymentInfoValue: {
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    color: '#202223',
    lineHeight: 20,
  },
  editButton: {
    color: '#2C6ECB',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    lineHeight: 20,
  },
  payButton: {
    backgroundColor: '#0D1738',
    borderRadius: 35,
    alignItems: 'center',
    flex: 78 / 100,
  },
  payButtonText: {
    fontSize: 16,
    fontFamily: 'OpenSans_600SemiBold',
    lineHeight: 20,
    color: '#FFFFFF',
    paddingVertical: 12,
    paddingHorizontal: 24,
  },
  containerBottomBorder: {
    borderBottomColor: '#EDEEEF',
    borderBottomWidth: 1,
  },
  paymentMethodHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 34,
  },
  paymentMethodTitle: {
    fontSize: 20,
    fontFamily: 'OpenSans_600SemiBold',
    lineHeight: 28,
    color: '#202223',
  },
  billingDetailsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 37,
  },
  billingDetailsSubTitle: {
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
    lineHeight: 20,
    color: '#0D1738',
  },
  billingDetailsTitle: {
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    lineHeight: 20,
    color: '#202223',
  },
  billingDetailsInfo: {
    fontSize: 16,
    fontFamily: 'OpenSans_400Regular',
    lineHeight: 24,
    color: '#202223',
  },
  yourPurchaseHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 36,
  },
  yourPurchasePlanContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 16,
  },
  yourPurchasePlanTitle: {
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    lineHeight: 20,
    color: '#202223',
  },
  yourPurchasePlanValue: {
    alignSelf: 'flex-end',
    fontSize: 16,
    fontFamily: 'OpenSans_600SemiBold',
    lineHeight: 20,
    color: '#202223',
  },
  yourPurchasePlanPrice: {
    alignSelf: 'flex-end',
    fontSize: 16,
    fontFamily: 'OpenSans_600SemiBold',
    lineHeight: 20,
    color: '#202223',
  },
  paymentFooterContainer: {
    marginTop: 20,
    flexDirection: 'row',
    paddingHorizontal: 40,
    justifyContent: 'space-between',
  },
  paymentFooterText: {
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    lineHeight: 20,
    color: '#6D7175',
  },
});
