import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  title: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 26,
    lineHeight: 32,
    color: 'red',
  },
  buttonWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#5C5F62',
    borderRadius: 4,
    paddingVertical: 2,
    paddingHorizontal: 10,
  },
  buttonTextPlus: {
    color: '#5C5F62',
    marginRight: 8,
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 4,
  },
  buttonText: {
    color: '#202223',
    fontSize: 14,
    fontFamily: 'Opensans_600SemiBold',
  },
});
