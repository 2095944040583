import { FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon, WebIcon } from '@components/general/icons/social-icons';
import { Image } from '@components/general/image-from-storage';
import { DocumentAsset } from '@gql/generated/generated';
import { getUniqueCategories } from '@utils/misc';
import React from 'react';
import { Linking, Text, TouchableOpacity, View } from 'react-native';
// import { Pill, PillVariant } from "@components/general/pill";

import { styles } from './styles';

interface SocialIconProps {
  Icon: React.ReactNode;
  onPress: () => void;
}

interface MarketplaceIndividualItemProps {
  isSideBar?: boolean;
  title?: string | null;
  image?: DocumentAsset | null;
  categories?: string[] | null;
  partnerType?: string | null;
  socialLinks?: {
    facebook?: string | null;
    instagram?: string | null;
    linkedin?: string | null;
    twitter?: string | null;
    website?: string | null;
  } | null;
}

const MarketplaceIndividualItem = ({
  title,
  image,
  categories,
  // partnerType,
  socialLinks,
}: MarketplaceIndividualItemProps) => {
  const SocialIcon = ({ Icon, onPress }: SocialIconProps) => {
    return (
      <TouchableOpacity
        style={{
          marginRight: 12,
        }}
        onPress={onPress}
      >
        {Icon}
      </TouchableOpacity>
    );
  };
  const { facebook, instagram, linkedin, twitter, website } = socialLinks || {};

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image path={image?.storagePath} style={styles.image} />
      </View>
      <View style={styles.contentContainer}>
        <View
          style={{
            marginBottom: 4,
          }}
        >
          <View style={styles.titleContainer}>
            <Text style={styles.title}>{title}</Text>
          </View>
          <Text style={styles.categories}>{categories && categories.length > 0 ? getUniqueCategories(categories)[0] : ''}</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {/* // TODO Temp disabling partner types see https://trello.com/c/fSds74pG/311-hide-the-gold-silver-bronze-partnership-tags  */}
          {/* {partnerType && (
            <Pill
              variant={PillVariant.DarkBlue}
              text={partnerType}
              withMarginRight
            />
          )} */}
        </View>

        <View style={styles.socialLinksContainer}>
          {website ? <SocialIcon Icon={<WebIcon />} onPress={() => Linking.openURL(website)} /> : null}
          {facebook ? <SocialIcon Icon={<FacebookIcon />} onPress={() => Linking.openURL(facebook)} /> : null}
          {instagram ? <SocialIcon Icon={<InstagramIcon />} onPress={() => Linking.openURL(instagram)} /> : null}
          {twitter ? <SocialIcon Icon={<TwitterIcon />} onPress={() => Linking.openURL(twitter)} /> : null}
          {linkedin ? <SocialIcon Icon={<LinkedInIcon />} onPress={() => Linking.openURL(linkedin)} /> : null}
        </View>
      </View>
    </View>
  );
};

export { MarketplaceIndividualItem };
