import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const PostShareIcon = (props: SvgProps) => (
  <Svg width={14} height={14} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 8.6c-.646 0-1.23.259-1.662.675L5.37 7.29c.012-.096.03-.19.03-.29 0-.1-.018-.195-.03-.29l3.968-1.986A2.389 2.389 0 0 0 11 5.4c1.323 0 2.4-1.077 2.4-2.4 0-1.324-1.077-2.4-2.4-2.4A2.403 2.403 0 0 0 8.6 3c0 .1.017.194.03.29L4.66 5.275A2.389 2.389 0 0 0 3 4.6 2.403 2.403 0 0 0 .6 7C.6 8.323 1.676 9.4 3 9.4c.645 0 1.23-.26 1.661-.676L8.63 10.71c-.012.096-.03.19-.03.29 0 1.324 1.077 2.4 2.4 2.4 1.324 0 2.4-1.076 2.4-2.4 0-1.323-1.076-2.4-2.4-2.4Z"
      fill="#6D7175"
    />
  </Svg>
);

export { PostShareIcon };
