import { mediaQuery } from '@utils/misc';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  scrollContent: {
    padding: 20,
    paddingBottom: 80,
  },
  largeBoldText: {
    fontSize: 20,
    lineHeight: 28,
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
  },
  descriptionContainer: {
    marginBottom: 40,
  },
  mapContainer: {
    marginVertical: 20,
  },
  buttonText: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: 'OpenSans_600SemiBold',
    marginLeft: 6,
  },
  buttonsContainer: {
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: '#EDEEEF',
    paddingBottom: 40,
    marginBottom: 40,
  },
  subduedText: {
    fontSize: 12,
    lineHeight: 16,
    color: '#6D7175',
  },
  wrapper: {
    paddingTop: 16,
    height: '100%',
    [mediaQuery.forMobile]: {
      paddingTop: 0,
    },
  },
  coverImage: {
    height: 221,
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  coverImageStyle: {
    borderRadius: 8,
  },
  coverImagePill: {
    padding: 8,
  },
  eventTitle: {
    color: '#0D1738',
    fontSize: 20,
    fontFamily: 'OpenSans_600SemiBold',
    marginBottom: 18,
  },
  eventItems: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  eventItemText: {
    color: '#6D7175',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    marginLeft: 10,
  },
  eventDateText: {
    color: '#2C6ECB',
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
    marginLeft: 10,
  },
  locationText: {
    color: '#2C6ECB',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    textDecorationLine: 'underline',
    marginLeft: 10,
  },
  saveButtonText: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    color: '#202223',
  },
  buttonWrap: {
    marginBottom: 16,
  },
  jobRoleWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: 16,
  },
  jobRoleText: {
    marginRight: 4,
    fontSize: 12,
    fontFamily: 'OpenSans_600SemiBold',
    color: '#0D1738',
  },
  roleItem: {
    marginBottom: 4,
  },
  aboutSpeakerWrap: {
    marginTop: 20,
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: '#EDEEEF',
    paddingBottom: 12,
  },
  description: {
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
  },
  aboutSpeakerTitle: {
    fontSize: 16,
    fontFamily: 'OpenSans_600SemiBold',
    color: '#0D1738',
    marginBottom: 8,
  },
  speakerName: {
    color: '#6D7175',
    marginLeft: 8,
  },
  speakerDescription: {
    color: '#0D1738',
  },
  followSpeakerText: {
    color: '#0D1738',
    fontSize: 12,
    fontFamily: 'OpenSans_600SemiBold',
  },
  socialIconTouch: {
    marginLeft: 8,
  },
  relatedEventLocation: {
    color: '#6D7175',
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
  },
  registerBottom: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    backgroundColor: '#FFFFFF',
    padding: 12,
    paddingTop: 4,
    paddingBottom: 20,
  },
  savedEventWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  savedEventDescription: {
    color: '#0D1738',
  },
  savedTouch: {
    color: '#2C6ECB',
    textDecorationLine: 'underline',
  },
});
