import { ButtonGeneral, ButtonType } from '@components/general/button-general';
import { AuthWrapper } from '@components/sign-up/auth-wrapper';
import React from 'react';
import { View } from 'react-native';
import { useNavigate } from 'react-router-native';

const ResetSuccess = () => {
  const navigate = useNavigate();
  return (
    <AuthWrapper title="Password reset" subtitle="Your password has been successfully reset Click below to login." onPressBack={() => navigate(-1)}>
      <View>
        <ButtonGeneral label="Continue" onPress={() => navigate('/login')} type={ButtonType.user} />
      </View>
    </AuthWrapper>
  );
};

export { ResetSuccess };
