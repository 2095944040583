import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { ISvgProps } from '../../../types';

const LinkedInIcon = (props: ISvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/Svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color || '#AEB5BC'}
      fillRule="evenodd"
      d="M4.707 3.5C4.04 3.5 3.5 4.04 3.5 4.707v14.586c0 .667.54 1.207 1.207 1.207h14.586c.667 0 1.207-.54 1.207-1.207V4.707c0-.667-.54-1.207-1.207-1.207H4.707zm2.609 5.28a1.475 1.475 0 100-2.95 1.475 1.475 0 000 2.95zm2.825 1.09h2.444v1.119s.663-1.327 2.468-1.327c1.61 0 2.943.793 2.943 3.21v5.098h-2.532v-4.48c0-1.426-.762-1.582-1.342-1.582-1.204 0-1.41 1.038-1.41 1.768v4.294h-2.57v-8.1zm-1.54 0h-2.57v8.1H8.6v-8.1z"
      clipRule="evenodd"
    />
  </Svg>
);

export { LinkedInIcon };
