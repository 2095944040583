import { ButtonGeneral, ButtonVariant } from '@components/general/button-general';
import { Row } from '@components/general/row';
import { Module, useDeleteModuleMutation } from '@gql/generated/generated';
import { textStyles } from '@styles/text';
import { removeItemAtIndex } from '@utils/Array';
import { customFirestoreId } from '@utils/misc';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Text, View } from 'react-native';

import { TrainingModule } from './TrainingModule';
import { styles } from './style';
import { DeleteConfirmationModal } from '../delete-confirmation-modal';

export const Curriculum = ({ modules, trainingId }: { modules: Module[]; trainingId: string }) => {
  const [moduleToDelete, setModuleToDelete] = useState<{
    index: number;
    id: string;
  } | null>(null);

  const { values, setFieldValue } = useFormikContext<any>();

  const handleDeleteModule = (index: number, id: string) => {
    setModuleToDelete({ index, id });
  };

  const [deleteRemoteModule] = useDeleteModuleMutation();

  const deleteModule = async () => {
    if (moduleToDelete) {
      setFieldValue('modules', removeItemAtIndex(values.modules, moduleToDelete.index));
      await deleteRemoteModule({
        variables: {
          moduleId: moduleToDelete.id,
          trainingId,
        },
      });

      setModuleToDelete(null);
    }
  };

  return (
    <View style={styles.container}>
      <Row
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 8,
        }}
      >
        <Text style={textStyles.semiBoldMedium}>Curriculum</Text>
        <ButtonGeneral
          variant={ButtonVariant.Primary}
          label="Add Module"
          onPress={() =>
            setFieldValue('modules', [
              ...modules,
              {
                id: customFirestoreId(),
                moduleDescription: '',
                moduleTitle: '',
                lessons: [],
              },
            ])
          }
        />
      </Row>
      <Text style={[textStyles.subduedTextMedium, { marginBottom: 16 }]}>Start putting together your course by creating modules and lessons.</Text>
      {modules.map((module, index: number) => {
        return (
          <TrainingModule
            key={module.id}
            index={index}
            module={module}
            onDeleteModule={() => handleDeleteModule(index, module.id)}
            trainingId={trainingId}
          />
        );
      })}

      <DeleteConfirmationModal
        title="Are you sure you want to delete this module?"
        onDeleteConfirm={() => deleteModule()}
        visible={!!moduleToDelete}
        onClose={() => setModuleToDelete(null)}
      />
    </View>
  );
};
