import { Toast as PolarisToast } from '@shopify/polaris';

interface ToastProps {
  active: boolean;
  text: string;
  onDismiss: () => void;
  error?: boolean;
  duration?: number;
}

const Toast = ({ active, text, onDismiss, error, duration }: ToastProps) => {
  if (!active) {
    return null;
  }
  return <PolarisToast content={text} onDismiss={onDismiss} error={error} duration={duration} />;
};

export { Toast };
