import { ButtonGeneral, ButtonType } from '@components/general/button-general';
import { Input } from '@components/general/form/input';
import { AuthWrapper } from '@components/sign-up/auth-wrapper';
import { Formik } from 'formik';
import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';
import * as Yup from 'yup';

import { ids, styles } from './style';
import { FormValues } from '../SignUp';

export interface PasswordForm {
  password: string;
  confirmPassword: string;
}

interface Props {
  values: FormValues;
  onPressBack: () => void;
  onPressForward: (values: PasswordForm) => void;
}

const CreatePassword = ({ values, onPressBack, onPressForward }: Props) => {
  const navigate = useNavigate();

  const onSubmit = (formikValues: PasswordForm) => {
    onPressForward(formikValues);
  };

  return (
    <AuthWrapper title="Create your password" subtitle="Must be at least 8 characters" onPressBack={onPressBack}>
      <View>
        <Formik
          initialValues={{
            password: values.password || '',
            confirmPassword: values.confirmPassword || '',
          }}
          onSubmit={onSubmit}
          validationSchema={Yup.object().shape({
            password: Yup.string().min(8, 'Password should be more than 7 characters').required('This field is required'),
            confirmPassword: Yup.string()
              .oneOf([Yup.ref('password'), null], 'Passwords do not match')
              .required('Re-Enter password'),
          })}
        >
          {({ values, handleChange, handleSubmit, touched, errors }) => (
            <View>
              <View style={styles.inputWrap}>
                <Input
                  secureTextEntry
                  placeholder="Password"
                  value={values.password}
                  onChangeText={handleChange('password')}
                  error={touched.password && errors.password ? (errors.password as string) : undefined}
                />
              </View>
              <View style={styles.inputWrap}>
                <Input
                  secureTextEntry
                  placeholder="Confirm Password"
                  value={values.confirmPassword}
                  onChangeText={handleChange('confirmPassword')}
                  error={touched.confirmPassword && errors.confirmPassword ? (errors.confirmPassword as string) : undefined}
                />
              </View>
              <ButtonGeneral bold label="Set Password" onPress={handleSubmit} type={ButtonType.user} style={styles.submitButton} />
            </View>
          )}
        </Formik>
        <View style={styles.register} dataSet={{ media: ids.register }}>
          <Text style={styles.noAccount}>Already have an account?</Text>
          <TouchableOpacity onPress={() => navigate('/login')}>
            <Text style={styles.registerLink}>&nbsp;Login</Text>
          </TouchableOpacity>
        </View>
      </View>
    </AuthWrapper>
  );
};

export { CreatePassword };
