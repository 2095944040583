import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { ISvgProps } from '../../../../types';

const DrawingPinIcon = ({ color = '#fff', ...props }: ISvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} fill="none" {...props}>
    <Path
      fill={color}
      d="m10.966 1 4.8 4.801a.802.802 0 0 1-.052 1.18l-4.52 3.765c-.124 3.285-1.834 4.236-2.036 4.337a.8.8 0 0 1-.923-.15L5.6 12.3l-4.234 4.234a.8.8 0 1 1-1.132-1.131l4.235-4.235-2.635-2.634a.801.801 0 0 1-.15-.924c.1-.202 1.053-1.912 4.336-2.036l3.765-4.52a.799.799 0 0 1 .58-.286.773.773 0 0 1 .601.234z"
    />
  </Svg>
);

export { DrawingPinIcon };
