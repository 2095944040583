import { AccountType } from '@gql/generated/generated';
import * as Yup from 'yup';

const firstAndLastNameFields = {
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
};

const commonUserFields = {
  email: Yup.string().email('Invalid email').required(),
  phoneNumber: Yup.string().required(),
  accountType: Yup.mixed<AccountType>().oneOf(Object.values(AccountType)).required(),
};

export const newMembervalidationSchema = Yup.object({
  ...firstAndLastNameFields,
  ...commonUserFields,
  jobPosition: Yup.string().required(),
  country: Yup.string().required(),
});

export const createAccountValidationSchema = Yup.object({
  ...firstAndLastNameFields,
  ...commonUserFields,
});

// organisationId and companyName are mutually exclusive,
// so using Yup.lazy to avoid cyclic dependency errors on page load
export const userEditorValidationSchema = Yup.object({
  ...commonUserFields,
  name: Yup.string().required(),
  registrationDate: Yup.date().required(),
  organisationId: Yup.lazy(() =>
    Yup.string().when('companyName', {
      is: (name: string) => !name,
      then: (schema) => schema.required('Organisation selection is required'),
    })
  ),
  companyName: Yup.lazy(() =>
    Yup.string().when('organisationId', {
      is: (id: string) => !id,
      then: (schema) => schema.required('Company name is required'),
    })
  ),
  subscriptionPlan: Yup.string().required(),
});
