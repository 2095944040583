import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrapper: {
    minHeight: '100%',
  },
  container: {
    width: '100%',
    height: '100vh',
    alignItems: 'flex-start',
  },
  footer: {
    height: 1,
    width: '100%',
  },
  pageWrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 50,
    width: '100%',
  },
});
