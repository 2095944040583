import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  popupBackGround: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  popup: {
    width: 501,
    ...(Platform.OS === 'web' && { height: 'max-content' }),
    paddingBottom: 40,
    backgroundColor: '#FFFFFF',
    borderRadius: 15,
    zIndex: 2,
    position: 'absolute',
    textAlign: 'center',
    '@media (max-width: 565px)': {
      width: '95%',
    },
    marginTop: 108,
  },
  title: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 22,
    lineHeight: 28,
    color: '#0D1738',
    marginTop: 37,
    marginBottom: 8,
  },
  subTitle: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    lineHeight: 17,
    color: '#6E757C',
  },
  tutorial: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  tutoChoice: {
    textAlign: 'center',
    marginTop: 32,
    marginBottom: 10,
  },
  tutoBtn: {
    width: 117,
    height: 80,
    borderWidth: 1,
    borderColor: '0D1738',
    borderRadius: 15,
    marginHorizontal: 37,
    marginBottom: 10,
  },
  lightBulb: {
    width: 54,
    height: 54,
    marginTop: 12,
    marginLeft: 32,
  },
  grid: {
    width: 45,
    height: 45,
    marginTop: 16,
    marginLeft: 36,
  },
  skip: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 9.4,
    lineHeight: 24,
    color: '#A3A9B0',
    textDecorationLine: 'underline',
    marginTop: 17,
  },
  wrapLink: {
    ...(Platform.OS === 'web' && { width: 'max-content' }),
    ...(Platform.OS === 'web' && { height: 'max-content' }),
    marginHorizontal: 'auto',
  },
  titleChoice: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 10.5,
    lineHeight: 15,
    color: '#0D1738',
    marginBottom: 6,
  },
  subTitleChoice: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 10.5,
    lineHeight: 15,
    color: '#6E757C',
    width: 126,
    marginLeft: 34,
  },
  closeBtn: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 10.5,
    lineHeight: 15,
    color: '#FFFFFF',
    backgroundColor: '#0D1738',
    width: 230,
    height: 32,
    textAlign: 'center',
    paddingTop: 8,
    borderRadius: 5,
  },
});
