import React from 'react';
import { Modal, ModalProps, View } from 'react-native';

import { styles } from './style';

export enum BottomModalVariant {
  FitContent,
  Large,
}

interface BottomModalProps extends ModalProps {
  variant: BottomModalVariant;
}

const BottomModal = ({ children, variant, ...modalProps }: BottomModalProps) => {
  return (
    <Modal {...modalProps}>
      <View style={[styles.modalContainer, variant === BottomModalVariant.Large && styles.largeModalContainer]}>
        <View style={[styles.contentContainer, variant === BottomModalVariant.Large && styles.largeContentContainer]}>{children}</View>
      </View>
    </Modal>
  );
};

export { BottomModal };
