import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

interface Props extends SvgProps {
  color?: string;
}

const RoadmapIcon = (props: Props) => (
  <Svg width={21} height={17} fill="none" {...props}>
    <Path
      d="M13.95 16.225a.998.998 0 0 1-.94-.658L9.935 7.113l-2.05 5.464a1.001 1.001 0 0 1-1.795.163L3.902 9.096 2.778 10.78a1.002 1.002 0 0 1-1.665-1.11l2-2.999a.966.966 0 0 1 .857-.445.998.998 0 0 1 .834.485L6.74 9.935l2.271-6.06a1 1 0 0 1 .938-.65h.005a1.001 1.001 0 0 1 .935.659l2.948 8.1L16.99.951c.12-.42.502-.715.94-.725.43-.026.834.268.97.684l2.002 6a1 1 0 1 1-1.897.631l-.98-2.933-3.113 10.889a1 1 0 0 1-.927.726h-.035"
      fill={props.color || '#5C5F62'}
    />
  </Svg>
);

export { RoadmapIcon };
