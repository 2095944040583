import { ContentBox } from '@components/general/layouts/content-box';
import { useUpdateNumRegistrationsMutation } from '@gql/generated/generated';
import { Link } from '@shopify/polaris';
import React, { useState } from 'react';
import { Text, View } from 'react-native';

import { styles } from './style';
import { ButtonGeneral } from '../../general/button-general';
import { JoinEventModalWeb } from '../modals/join-event-modal';

interface JoinEventBoxProps {
  calendlyLink?: string | null;
  googleFormLink?: string | null;
  isOnline?: boolean;
  eventId?: string | null;
  eventType?: string | null;
  instructions?: string | null;
  otherLink?: string | null;
}

const JoinEventBox = ({ calendlyLink, googleFormLink, isOnline, eventId, eventType, instructions, otherLink }: JoinEventBoxProps) => {
  const [showJoinEventModal, setShowJoinEventModal] = useState(false);

  const [register] = useUpdateNumRegistrationsMutation();

  return (
    <ContentBox style={styles.container}>
      {calendlyLink || googleFormLink ? (
        <JoinEventModalWeb
          googleFormLink={googleFormLink}
          calendlyLink={calendlyLink}
          isVisible={showJoinEventModal}
          setIsVisible={setShowJoinEventModal}
        />
      ) : null}
      <Text style={styles.title}>Join the event</Text>
      {instructions ? <Text style={styles.instructions}>{instructions}</Text> : null}
      {otherLink ? (
        <>
          <ButtonGeneral
            label="Register Now"
            onPress={() => {
              window.open(otherLink, '_blank');
            }}
            rounded
          />
          <Text style={styles.subduedTextSmall}>Clicking the button will open the registration page in a new tab.</Text>
        </>
      ) : null}
      <View>
        {calendlyLink || googleFormLink ? (
          <ButtonGeneral
            label="Register Now"
            onPress={() => {
              if (eventId && eventType) {
                register({ variables: { eventId, eventType } });
              }
              setShowJoinEventModal(true);
            }}
            rounded
          />
        ) : null}
      </View>
      {isOnline && (googleFormLink || calendlyLink) ? (
        <Text style={styles.subduedTextSmall}>The link to join this event will be sent to your email after registration is successful.</Text>
      ) : null}
    </ContentBox>
  );
};

export { JoinEventBox };
