import { ButtonGeneral, ButtonVariant } from '@components/general/button-general';
import { Input } from '@components/general/form/input';
import { InputError } from '@components/general/form/input-error';
import { TextArea } from '@components/general/form/text-area';
import { ModalContainer } from '@components/org/saved/modals/ModalContainer';
import { ConfirmationModalContent } from '@components/org/saved/modals/confirmation-modal/ConfirmationModalContent';
import { GenericModalLayout } from '@components/org/saved/modals/generic-modal-layout/GenericModalLayout';
import { useCreateRequestMutation } from '@gql/generated/generated';
import { Select } from '@shopify/polaris';
import { styles as sharedStyles } from '@styles/BackOffice/shared';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { useState } from 'react';
import { Modal, Text, View } from 'react-native';
import * as Yup from 'yup';

import { styles } from './style';

const categoryOptions = [
  { label: 'Back Office', value: 'Back Office' },
  { label: 'End User', value: 'End User' },
  { label: 'Other', value: 'Other' },
];

const HelpBackOffice = () => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [createRequest] = useCreateRequestMutation();

  const onSubmit = async (values: FormikValues, { resetForm }: FormikHelpers<{ category: string; subject: string; description: string }>) => {
    try {
      const { category, subject, description } = values;
      const input = { type: category, subject, description };
      await createRequest({
        variables: {
          input,
        },
      });
      setShowSuccessModal(true);
      resetForm();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <View style={styles.wrapper}>
      <Text style={sharedStyles.title}>Help</Text>
      <View style={sharedStyles.contentBox}>
        <Text style={sharedStyles.contentBoxTitle}>Contact our support team</Text>
        <Text style={styles.enquiryText}>
          For any questions please contact&nbsp;
          <Text style={styles.enquiryMail}>enquiries@neurotics.com</Text>
          &nbsp;Or use the form below
        </Text>
        <Formik
          initialValues={{ category: '', subject: '', description: '' }}
          onSubmit={onSubmit}
          validationSchema={Yup.object().shape({
            category: Yup.string().required('This field is required'),
            subject: Yup.string().required('This field is required'),
          })}
        >
          {({ values, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
            <>
              <View style={styles.rowInputWrap}>
                <View style={styles.inputItem}>
                  <Select
                    label=""
                    placeholder="Category"
                    value={values.category}
                    options={categoryOptions}
                    onChange={(value) => setFieldValue('category', value)}
                  />
                  {touched.category && errors.category && <InputError error={errors.category} />}
                </View>
                <View style={styles.inputItem}>
                  <Input
                    placeholder="Subject"
                    value={values.subject}
                    onChangeText={handleChange('subject')}
                    error={touched.subject && errors.subject ? errors.subject : undefined}
                  />
                </View>
              </View>
              <View style={styles.textAreaWrap}>
                <TextArea
                  placeholder="Please describe your issue"
                  value={values.description}
                  onChangeText={handleChange('description')}
                  error={touched.description && errors.description ? errors.description : undefined}
                />
              </View>
              <View style={styles.submitWrap}>
                <ButtonGeneral withMarginRight label="Cancel" variant={ButtonVariant.Secondary} />
                <ButtonGeneral onPress={handleSubmit} label="Send" />
              </View>
            </>
          )}
        </Formik>
      </View>
      <Modal visible={showSuccessModal} transparent>
        <ModalContainer>
          <GenericModalLayout
            title="Success"
            closeModal={() => setShowSuccessModal(false)}
            hasButtonLayout
            buttonName="Back to Help"
            onActionButtonPress={() => setShowSuccessModal(false)}
            withCancel={false}
          >
            <ConfirmationModalContent mainText="Your question has been sent, we will get back to you as soon as possible." secondaryText="" />
          </GenericModalLayout>
        </ModalContainer>
      </Modal>
    </View>
  );
};

export { HelpBackOffice };
