import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderColor: '#BABFC3',
    borderRadius: 4,
    height: 36,
    paddingLeft: 12,
    paddingRight: 8,
    fontFamily: 'OpenSans_400Regular',
    color: '#0D1738',
    fontSize: 14,
  },
  inputWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  countryCodeWrap: {
    width: '40%',
  },
  numberWrap: {
    width: '55%',
  },
  inputError: {
    backgroundColor: '#FFF4F4',
    borderColor: '#D82C0D',
  },
});
