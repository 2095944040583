import { TitleBox } from '@components/account/title-box';
import { ContentBox } from '@components/general/layouts/content-box';
import { NotificationItem } from '@components/navbar/NotificationBox/notification-item';
import { Tab } from '@components/org/saved/tabs/Tab';
import { TabModule } from '@components/org/saved/tabs/TabModule';
import { TabContentPosition } from '@components/training/training-tab';
import { useUserContext } from '@context/UserContext';
import {
  Notification,
  NotificationType,
  useGetNotificationsQuery,
  useCountAllUnreadNotificationTypesQuery,
  useMarkNotificationAsReadMutation,
} from '@gql/generated/generated';
import {
  getNotificationBottomText,
  getPopulatedNotification,
  getNotificationTextAndColorFromNotificationType,
  getNotificationPollInterval,
} from '@utils/notificationUtils';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View, Text } from 'react-native';
import { useNavigate } from 'react-router-native';

import { useCreateApolloClient } from '../../../../apolloClient';

type Tab = 'All' | 'Events' | 'Content' | 'Training';
const tabs = ['All', 'Events', 'Content', 'Training'];

const getTypeFromTab = (tab: Tab): NotificationType | undefined => {
  switch (tab) {
    case 'Content':
      return NotificationType.CONTENT;
    case 'Events':
      return NotificationType.EVENT;
    case 'Training':
      return NotificationType.TRAINING;
    default:
      return undefined;
  }
};

const AllNotifications = () => {
  const { currentUser } = useUserContext();
  const [activeTab, setActiveTab] = useState<Tab>('All');
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [populatedNotifications, setPopulatedNotifications] = useState<Notification[]>([]);
  const [populating, setPopulating] = useState(false);
  const navigate = useNavigate();

  const { data, loading, refetch } = useGetNotificationsQuery({
    variables: {
      userId: currentUser?.id || '',
      limit: 20,
      type: getTypeFromTab(activeTab),
    },
    skip: !currentUser,
    pollInterval: getNotificationPollInterval(),
  });

  const [mutation, _] = useMarkNotificationAsReadMutation();

  const handleTabPress = (tab: Tab) => {
    setActiveTab(tab);

    setPopulatedNotifications([]);

    refetch();
  };

  const { data: unreadData } = useCountAllUnreadNotificationTypesQuery({
    variables: {
      userId: currentUser?.id || '',
    },
    skip: !currentUser,
    pollInterval: getNotificationPollInterval(),
  });

  useEffect(() => {
    if (data && Array.isArray(data.getNotifications)) {
      setNotifications(data.getNotifications as Notification[]);
    }
  }, [data]);

  const unreadCount = (tab: string) => {
    switch (tab) {
      case 'All':
        return unreadData?.unreadAll.__typename == 'Count' ? unreadData.unreadAll.count : 0;
      case 'Content':
        return unreadData?.unreadContent.__typename == 'Count' ? unreadData.unreadContent.count : 0;
      case 'Events':
        return unreadData?.unreadEvents.__typename == 'Count' ? unreadData.unreadEvents.count : 0;
      case 'Training':
        return unreadData?.unreadTraining.__typename == 'Count' ? unreadData.unreadTraining.count : 0;
      default:
        return 0;
    }
  };

  const apolloClient = useCreateApolloClient();

  useEffect(() => {
    const populateNotifications = async () => {
      setPopulating(true);
      const ns = notifications || [];

      const notifs = await Promise.all(
        ns.map((notification) => {
          return getPopulatedNotification(apolloClient, notification, currentUser?.id || '');
        })
      );

      setPopulatedNotifications(notifs);
      setPopulating(false);
    };

    if (notifications.length > 0) {
      populateNotifications();
    }
  }, [notifications]);

  return (
    <>
      <View
        style={{
          flex: 1,
        }}
      >
        <ContentBox>
          <TitleBox title="See all Notifications" backTitle="Back to Notifications" onPressBack={() => navigate('/account/notification-settings')} />
          <View style={{ marginBottom: 32 }}>
            <TabModule withTimeDropDown={false}>
              {tabs.map((tab) => (
                <Tab key={tab} title={tab} notification={unreadCount(tab)} active={activeTab === tab} onPress={() => handleTabPress(tab as Tab)} />
              ))}
            </TabModule>
          </View>
          {loading || !populatedNotifications || populatedNotifications.length === 0 ? (
            <View
              style={{
                width: '100%',
                paddingHorizontal: 130,
                paddingVertical: 24,
              }}
            >
              <Text
                style={{
                  fontFamily: 'OpenSans_600SemiBold',
                  fontSize: 14,
                  lineHeight: 20,
                }}
              >
                {loading || populating ? <>Loading Notifications</> : <>No new notifications</>}
              </Text>
            </View>
          ) : (
            <View style={{ minWidth: '100%' }}>
              {populatedNotifications.map((notification) => {
                const { text, color } = getNotificationTextAndColorFromNotificationType(notification);

                return (
                  <NotificationItem
                    key={notification.id}
                    unread={!notification.read}
                    image={notification.coverImage?.storagePath}
                    title={notification.title || ''}
                    description={notification.details.description || ''}
                    notificationStatus={text}
                    notificationStatusColor={color}
                    bottomText={getNotificationBottomText(notification)}
                    onPress={async () => {
                      await mutation({
                        variables: {
                          userId: currentUser?.id || '',
                          id: notification.id,
                        },
                      });

                      navigate(notification.url || '');
                    }}
                  />
                );
              })}
            </View>
          )}
        </ContentBox>
      </View>
    </>
  );
};

export { AllNotifications };
