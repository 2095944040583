import { SubscriptionPlanType } from '@gql/generated/generated';
import { createContext, ReactNode, useState } from 'react';

import { useUserContext } from './UserContext';

interface SubscriptionPlanContextProviderProps {
  children?: ReactNode;
}

interface subscriptionPlanContextProps {
  selectedPlan: string;
  setSelectedPlan: (selectedPlan: 'Free' | 'Plus' | 'Club') => void;
}

const subscriptionPlanContext = createContext<subscriptionPlanContextProps>({
  selectedPlan: 'Free',
  setSelectedPlan: () => {},
});

export const SubscriptionPlanContextProvider = ({ children }: SubscriptionPlanContextProviderProps) => {
  const { currentUser } = useUserContext();
  const [selectedPlan, setSelectedPlan] = useState(currentUser?.subscription?.plan ?? 'Free');
  return (
    <subscriptionPlanContext.Provider
      value={{
        selectedPlan,
        setSelectedPlan,
      }}
    >
      {children}
    </subscriptionPlanContext.Provider>
  );
};

export { subscriptionPlanContext };
