import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const NotificationIcon = (props: SvgProps) => (
  <Svg width={13} height={16} fill="none" {...props}>
    <Path
      d="M6.151 0a.8.8 0 0 1 .8.8v1.625l-.001.018-.002.023A4.801 4.801 0 0 1 10.951 7.2c0 2.475.5 3.45 1.279 4.968l.028.055a.396.396 0 0 1-.354.577H.398a.396.396 0 0 1-.354-.578l.028-.054C.851 10.65 1.351 9.675 1.351 7.2a4.801 4.801 0 0 1 4.003-4.734l-.002-.032a.66.66 0 0 1-.001-.034V.8a.8.8 0 0 1 .8-.8ZM7.751 14.4a1.6 1.6 0 0 1-3.2 0h3.2Z"
      fill="#0D1738"
    />
  </Svg>
);

export { NotificationIcon };
