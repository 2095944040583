import { ButtonGeneral, ButtonType } from '@components/general/button-general';
import { HtmlParser } from '@components/general/html-parser/HtmlParser';
import { LockIcon } from '@components/general/icons/training-icons';
import { MainBoxWithSidepanel } from '@components/general/layouts/main-box-with-sidepanel';
import { Wrapper } from '@components/general/layouts/wrapper/Wrapper';
import { Pill, PillVariant } from '@components/general/pill';
import { SideListBox } from '@components/general/side-list-box';
import { TooltipWithChild } from '@components/general/tooltip';
import { MarketplaceIndividualItem } from '@components/market-individual/marketplace-individual-item/MarketplaceIndividualItem';
import { useUserContext } from '@context/UserContext';
import { Partner, useGetAllPartnersQuery, useGetPartnerQuery } from '@gql/generated/generated';
import { getUniqueCategories } from '@utils/misc';
import { ActivityIndicator, Linking, Text, View } from 'react-native';
// import { Pill, PillVariant } from "@components/general/pill";
import { useNavigate, useParams } from 'react-router-native';

import { styles } from './styles';

const upgradeButtonClickHandler = () => Linking.openURL('https://therecruitmentnetwork.com/membership/');

const MarketplaceIndividualView = () => {
  const { id } = useParams() as { id: string };

  const navigate = useNavigate();
  const { currentUser } = useUserContext();

  const { data, loading: gettingPartner } = useGetPartnerQuery({
    variables: { getPartnerId: id },
    skip: !id,
  });

  const { data: allPartnersData, loading: loadingPartners } = useGetAllPartnersQuery({
    variables: {
      page: 1,
      limit: 5,
      filters: {
        categories: (data?.getPartner as Partner)?.categories || [],
        omitId: id,
      },
    },
    skip: !id,
  });

  const partners = allPartnersData?.getAllPartners.__typename === 'Partners' ? allPartnersData?.getAllPartners.partners : [];

  const partner = data?.getPartner.__typename === 'Partner' ? data.getPartner : null;

  const { links, title, logo, categories, content } = partner || {};

  const isSpecialOffer = partner?.specialOffer?.hasOffer || false;
  const userPlan = currentUser?.subscription?.plan || 'free';
  const userHasAccess = 'plus,club'.split(',').includes(userPlan.toLowerCase());

  const onRedeem = () => {
    partner?.specialOffer?.link && Linking.openURL(partner?.specialOffer?.link);
  };

  const { facebook, instagram, linkedin, twitter, website } = links || {};

  if (loadingPartners || gettingPartner) {
    return (
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  }

  return (
    <Wrapper
      style={{
        marginTop: 20,
      }}
    >
      <MainBoxWithSidepanel
        mainContent={
          <View>
            <View style={styles.upperSectionContainer}>
              {partner ? (
                <MarketplaceIndividualItem
                  title={title}
                  image={logo}
                  categories={categories}
                  partnerType={partner.partnerType}
                  socialLinks={{
                    facebook,
                    instagram,
                    linkedin,
                    twitter,
                    website,
                  }}
                />
              ) : null}
            </View>
            <View>
              {isSpecialOffer && (
                <View style={styles.specialOfferPill}>
                  <Pill variant={PillVariant.Red} text="Special Offer" />
                </View>
              )}
              <View>{partner?.specialOffer?.details}</View>
              {isSpecialOffer && (
                <TooltipWithChild toolTipText={userHasAccess ? `Redeem this offer` : `Please upgrade to access this offer`}>
                  <ButtonGeneral
                    label={userHasAccess ? 'Redeem' : `Upgrade now`}
                    icon={!userHasAccess ? <LockIcon color="#ffffff" /> : undefined}
                    type={ButtonType.user}
                    onPress={userHasAccess ? onRedeem : upgradeButtonClickHandler}
                    style={styles.button}
                  />
                </TooltipWithChild>
              )}
            </View>
            {content ? <HtmlParser htmlString={content} /> : null}
          </View>
        }
        sidePanelContent={
          partners ? (
            <SideListBox
              title="Related Partners"
              items={partners?.map((item) => ({
                title: item.title,
                image: item.logo,
                hasSpecialOffer: !!item.specialOffer?.details && item.specialOffer?.details !== '',
                id: item.id,
                onPress: () => navigate(`/marketplace/${item.id}`),
                bottomRow: (
                  <View style={styles.sidePanelContainer}>
                    {/* // TODO Temp disabling partner types see https://trello.com/c/fSds74pG/311-hide-the-gold-silver-bronze-partnership-tags  */}
                    {/* {item.partnerType && item.partnerType.length > 0 ? (
                      <Pill
                        variant={PillVariant.DarkBlue}
                        text={item.partnerType}
                        withMarginRight
                      />
                    ) : null} */}
                    <Text style={styles.sidePanelText}>{getUniqueCategories(item.categories)[0]}</Text>
                  </View>
                ),
              }))}
              linkLocation="/marketplace"
              linkText="View all Partners"
            />
          ) : null
        }
      />
    </Wrapper>
  );
};

export { MarketplaceIndividualView };
