import { ContextUser } from '@context/UserContext';
import { ImageSourcePropType } from 'react-native';

import Avatar from '../../assets/img/Avatar.png';
import OrgAvatar from '../../assets/img/avatars/OrgAvatar.png';

export const userProfileImage = (currentUser: ContextUser | null | undefined): ImageSourcePropType => {
  if (!currentUser) return Avatar;
  return currentUser.profilePhotoUrl ? { uri: currentUser.profilePhotoUrl } : currentUser.defaultAvatar;
};

export const userCompanyImage = (currentUser: ContextUser | null | undefined): ImageSourcePropType => {
  if (!currentUser) return OrgAvatar;
  const orgImageUrl = currentUser?.accountInfo?.companyInfo?.orgLogoUrl;
  return orgImageUrl ? { uri: orgImageUrl } : OrgAvatar;
};
