import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  jobRoleText: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 12,
    lineHeight: 16,
    color: '#0D1738',
    marginRight: 4,
    flexShrink: 0,
  },
  jobRolesRow: {
    alignItems: 'center',
    marginBottom: 12,
    flexDirection: 'row',
    display: 'flex',
    gap: 4,
  },
  rolesListContainer: {
    padding: 2,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: 200,
  },
  rolesListItem: {
    alignSelf: 'flex-start',
    marginRight: 6,
    marginBottom: 6,
  },
  rolesListText: {
    color: '#202223',
    fontSize: 12,
  },
});
