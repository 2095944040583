import { ButtonGeneral } from '@components/general/button-general';
import { IndexPageHeader } from '@components/general/index-page-header';
import React from 'react';
import { Modal, View } from 'react-native';

import { styles } from './style';

interface Props {
  headerTitle: string;
  onClose?: () => void;
  isVisible: boolean;
  buttonTitle: string;
  onDone?: () => void;
  isDoneButtonDisabled?: boolean;
  headerRightContent?: React.ReactNode;
  children: React.ReactNode;
}

const FilterWrapper = ({ headerTitle, onClose, isVisible, buttonTitle, onDone, isDoneButtonDisabled, headerRightContent, children }: Props) => (
  <Modal visible={isVisible} animationType="slide">
    <View style={styles.wrap}>
      <View>
        <IndexPageHeader title={headerTitle} onPressBack={onClose} rightContent={headerRightContent} />
        {children}
      </View>
      <View style={styles.buttonWrap}>
        <ButtonGeneral label={buttonTitle} onPress={onDone} disabled={isDoneButtonDisabled} />
      </View>
    </View>
  </Modal>
);

export { FilterWrapper };
