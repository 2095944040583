import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const PlusIcon = (props: SvgProps) => (
  <Svg width={12} height={12} fill="none" {...props}>
    <Path d="M6 0a1 1 0 0 0-1 1v4H1a1 1 0 1 0 0 2h4v4a1 1 0 1 0 2 0V7h4a1 1 0 1 0 0-2H7V1a1 1 0 0 0-1-1Z" fill="#5C5F62" />
  </Svg>
);

export { PlusIcon };
