import React, { useState } from 'react';
import { Text, View } from 'react-native';
import { Dropdown } from 'react-native-element-dropdown';

import { styles } from './style';

export const DropdownTopLabel = ({
  label,
  size,
  options,
  heightContainer,
}: {
  options: { label: string; value: string }[];
  label: string;
  size: number;
  heightContainer?: number;
}) => {
  const [input, setInput] = useState((options && options[0]) || '');

  return (
    <View>
      <Dropdown
        style={[styles.dropdown, { width: size }]}
        dropdownPosition="bottom"
        containerStyle={{ ...(heightContainer && { height: heightContainer }) }}
        selectedTextStyle={[styles.selectedText, { width: size - 48 }]}
        placeholderStyle={{
          width: size - 48,
        }}
        data={options}
        labelField="label"
        valueField="value"
        placeholder=""
        onChange={(val) => setInput(val)}
        value={input}
      />
      <Text style={styles.label}>{label}</Text>
    </View>
  );
};
