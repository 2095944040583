import * as React from 'react';
import Svg, { Circle, Defs, G, Path } from 'react-native-svg';

import { ISvgProps } from '../../../types';

interface ClockIconProps extends ISvgProps {
  backgroundColor?: string;
}

const ClockIcon = ({ backgroundColor = '#EBEDF0', ...props }: ClockIconProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <G filter="url(#a)">
      <Circle cx={16} cy={12} r={12} fill={backgroundColor} />
      <Path
        fill="#6E757C"
        d="M16 3.32a8.68 8.68 0 1 1 0 17.36 8.68 8.68 0 0 1 0-17.36zm0 1.736a6.944 6.944 0 1 0 0 13.888 6.944 6.944 0 0 0 0-13.888zm0 .868c.445 0 .812.335.862.767l.006.101v4.34h4.34c.445 0 .812.335.862.767l.006.101a.868.868 0 0 1-.767.862l-.101.006H16.13a.998.998 0 0 1-.992-.89l-.006-.108V6.792c0-.48.389-.868.868-.868z"
      />
    </G>
    <Defs />
  </Svg>
);

export { ClockIcon };
