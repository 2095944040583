import { GridPage } from '@components/back-office/grid/GridPage';
import { useQueryFilters } from '@components/back-office/grid/useGridFilters';
import { APP_URL } from '@env';
import { ContentType, Status, useAdminGetAllTrainingsQuery, useDeleteTrainingMutation, useUpsertTrainingMutation } from '@gql/generated/generated';
import { IndexTableHeading } from '@shopify/polaris/build/ts/latest/src/components/IndexTable';
import { NonEmptyArray } from '@shopify/polaris/build/ts/latest/src/types';
import { textStyles } from '@styles/text';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Linking, Text, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { DeleteConfirmationModal } from '../../../components/back-office/editor/delete-confirmation-modal';
import { getUniqueCategories, truncateString } from '../../../utils/misc';

const activeFilters = [
  'search',
  'status',
  'categories',
  'userTypes',
  // "isHighlighted",
];

export const TrainingsBackOffice = () => {
  const navigation = useNavigate();
  const [trainingIdToBeDeleted, setTrainingIdToBeDeleted] = useState<string | undefined>();

  const { page, filters } = useQueryFilters(activeFilters);

  const [deleteTraining] = useDeleteTrainingMutation({
    update: (store, _result, options) => {
      const id = options?.variables?.id;
      if (id) {
        const normalizedId = store.identify({
          id,
          __typename: `${ContentType.TRAINING}`,
        });
        store.evict({ id: normalizedId });
        store.gc();
      }
    },
  });
  const [archiveTraining] = useUpsertTrainingMutation({
    update: (store, _result, options) => {
      const itemId = options?.variables?.input?.id;
      if (itemId) {
        store.modify({
          id: `${ContentType.TRAINING}:${itemId}`,
          fields: {
            status() {
              return Status.ARCHIVE;
            },
          },
        });
      }
    },
  });

  const { data, loading, refetch } = useAdminGetAllTrainingsQuery({
    variables: {
      page,
      limit: 10,
      // @ts-ignore
      filters,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { trainings: trainingsData, hasNextPage, total } = data?.adminGetAllTrainings || {};

  const tableHeadings: NonEmptyArray<IndexTableHeading> = [
    { title: 'Title' },
    { title: 'Instructor' },
    { title: 'Students' },
    { title: 'Category' },
    { title: 'Last modified' },
    { title: 'User Type' },
    { title: '' },
  ];

  const rows =
    trainingsData?.map(({ id, title, userType, createdAt, categories, updatedAt, numEnrolledUsers, instructors }) => {
      return {
        id,
        title: (
          <View style={{ width: 350, paddingVertical: '8px' }}>
            <Text style={textStyles.semiBoldSmall}>{title ?? '-'}</Text>
          </View>
        ),
        instructor: instructors ? instructors.map((instructor) => instructor.name).join(', ') : '',
        students: numEnrolledUsers.toString(),
        categories: truncateString(getUniqueCategories(categories).join(', '), 20),
        date: dayjs(updatedAt ?? createdAt).format('DD/MM/YYYY'),
        userType: userType?.join(', '),
      };
    }) || [];

  const handleMenuClick = async (itemId: string, optionID: string) => {
    switch (optionID) {
      case 'View':
        return Linking.openURL(`${APP_URL}/training/${itemId}` as string);
      case 'Edit':
        return navigation(`/trainings-editor/${itemId}`);
      case 'Archive':
        await archiveTraining({
          variables: {
            input: {
              id: itemId,
              status: Status.ARCHIVE,
            },
          },
        });
        break;
      case 'Delete':
        setTrainingIdToBeDeleted(itemId);
        break;
      default:
        break; // TODO: fallthrough
    }
  };

  const handlePressDelete = async () => {
    if (trainingIdToBeDeleted) {
      await deleteTraining({ variables: { id: trainingIdToBeDeleted } });
    }
  };

  return (
    <>
      <DeleteConfirmationModal
        title="Are you sure you want to delete this training?"
        onClose={() => setTrainingIdToBeDeleted(undefined)}
        onDeleteConfirm={async () => {
          await handlePressDelete();
          setTrainingIdToBeDeleted(undefined);
        }}
        visible={!!trainingIdToBeDeleted}
      />
      <GridPage
        title="Trainings"
        menuItems={[
          { id: 'View', content: 'View' },
          { id: 'Edit', content: 'Edit' },
          { id: 'Archive', content: 'Archive' },
          { id: 'Delete', content: 'Delete' },
        ]}
        newLink={{
          url: '/trainings-editor',
          title: 'Add New Training',
          label: 'Add Training',
        }}
        activeFilters={activeFilters}
        rows={rows || []}
        loading={loading}
        tableHeadings={tableHeadings}
        onBulkAction={() => refetch()}
        onMenuItem={handleMenuClick}
        pagination={{
          perPage: 10,
          page,
          totalItems: total,
          hasNextPage,
        }}
      />
    </>
  );
};
