import React from 'react';
import { Text, TextInput, View } from 'react-native';

import { styles } from './style';

export const FieldTopLabel = ({ label, size, multiline, secureTextEntry, placeholder }: any) => {
  return (
    <View>
      <TextInput
        style={[
          styles.textInput,
          {
            width: size,
            height: multiline ? multiline * 30 : 36,
          },
        ]}
        secureTextEntry={secureTextEntry}
        multiline={multiline > 1}
        placeholder={placeholder}
      />
      <Text style={styles.label}>{label}</Text>
    </View>
  );
};
