import { ThreeDots } from '@components/general/icons/training-icons/ThreeDots';
import { ContentBox } from '@components/general/layouts/content-box';
import {
  Objective,
  RecommendedContentOrTraining,
  Roadmap,
  RoadmapQuestion,
  Section,
  useGetRecommendationDetailsQuery,
  useUpdateRoadmapObjectivesMutation,
} from '@gql/generated/generated';
import { ActionList, ActionListSection, Popover, Button, Checkbox, TextField } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { Platform, Text, TouchableOpacity, View } from 'react-native';
import { useDebounce } from '@hooks/useDebounce';

import { styles } from './style';
import { getRecommendationsPercentageComplete } from '@utils/getRecommendationsPercentageComplete';
import { isNotNull } from '@utils/isNotNull';

interface PriorityItemProps {
  // percent: number;
  title: string;
}

const PriorityItem = ({ title }: PriorityItemProps) => (
  <View style={styles.expandedItem}>
    {/* <PriorityPill percent={percent} isDefault /> */}
    <Text style={styles.expandedItemText}>{title}</Text>
  </View>
);

interface Props {
  title: string;
  // percent: number;
  // point: number;
  items: RoadmapQuestion[];
  // onChange: (destinationBoard: RoadmapPriorityBoardName) => void;
  // currentBoard: RoadmapPriorityBoardName;
  dropdownSections?: ActionListSection[];
  section: Section;
  roadmapData: Roadmap;
}

function generateObjectives(): Objective[] {
  return new Array(5).fill(0).map((_, i) => ({
    label: '',
    value: false,
  }));
}

function updateObjectives(objectives: Objective[], index: number, objective: Objective) {
  const newObjectives = [...objectives];
  newObjectives[index] = objective;
  return newObjectives.map((o) => ({ label: o.label, value: o.value }));
}

function ObjectivesList({
  subcategory,
  section,
  isObjectivesHidden,
}: {
  subcategory: RoadmapQuestion;
  section: Section;
  isObjectivesHidden: boolean;
}) {
  const [objectives, setObjectives] = useState(generateObjectives());
  const [updateRoadmapObjectives] = useUpdateRoadmapObjectivesMutation();

  useEffect(() => {
    const currentObjectives = subcategory?.objectives ?? generateObjectives();
    currentObjectives.forEach((currentObjective, index) => {
      setObjectives((prevState) => updateObjectives(prevState, index, currentObjective));
    });
  }, [subcategory]);

  async function updateObjective(newObjective: Objective, prevObjective: Objective, index: number) {
    if (!section?.id || !subcategory?.id) return;
    try {
      await updateRoadmapObjectives({
        variables: {
          input: {
            sectionId: section.id,
            questionId: subcategory.id,
            objectives: updateObjectives(objectives, index, newObjective),
          },
        },
      });
    } catch (err) {
      console.error('ERROR - failed to update objectives', err);
      setObjectives(updateObjectives(objectives, index, prevObjective));
      throw err;
    }
  }

  return (
    <>
      {!isObjectivesHidden && (
        <View style={{ marginTop: 10 }}>
          {objectives.map((o, i) => (
            <ObjectiveItem key={`${o.label}-${i}`} label={o.label ?? ''} value={o?.value ?? false} updateObjective={updateObjective} index={i} />
          ))}
        </View>
      )}
    </>
  );
}

function ObjectiveItem(
  props: Objective & { updateObjective: (newObjective: Objective, prevObjective: Objective, index: number) => Promise<void>; index: number }
) {
  const [valueState, setValueState] = useState(props.value ?? false);
  const [labelState, setLabelState] = useState(props.label ?? '');

  useDebounce(
    () => {
      const newObjective = { value: valueState, label: labelState };
      const prevObjective = { value: props.value, label: props.label };
      if (newObjective.label === props.label && newObjective.value === props.value) return;
      (async () => {
        try {
          await props.updateObjective(newObjective, prevObjective, props.index);
        } catch (err) {
          setLabelState(props.label);
          setValueState(props.value);
        }
      })();
    },
    500,
    [labelState]
  );

  async function toggleValue() {
    const newValue = !valueState;
    setValueState(newValue);

    const newObjective = { value: newValue, label: labelState };
    const prevObjective = { value: props.value, label: props.label };
    try {
      await props.updateObjective(newObjective, prevObjective, props.index);
    } catch (err) {
      setLabelState(props.label);
      setValueState(props.value);
    }
  }

  return (
    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: 5 }}>
      {/* @ts-ignore */}
      <View style={{ width: '15%', height: '100%', cursor: 'pointer', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
        <Checkbox label="" checked={valueState} onChange={toggleValue} />
      </View>
      <View style={{ width: '85%' }}>
        <TextField
          type="text"
          multiline
          maxLength={100}
          label=""
          autoComplete="off"
          disabled={valueState}
          value={labelState}
          onChange={(t) => setLabelState(t)}
        />
      </View>
    </View>
  );
}

export const Card = ({ title, items, dropdownSections, section, roadmapData }: Props) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const role = title.trim().toLocaleLowerCase().replaceAll(' ', '_');
  const subcategoryRole = items[0].subcategory?.trim().toLocaleLowerCase().replaceAll(' ', '_');
  const [isObjectivesHidden, setIsObjectivesHidden] = useState(true);
  const score = items[0].answer.currentScore ?? 1;
  const scores = items[0].options?.map((o) => o?.score ?? 1) ?? [];
  const maxScore = Math.max(...scores);

  const { data: recommendationDetailsData, loading: loadingRecommendationDetails } = useGetRecommendationDetailsQuery({
    variables: { sectionId: `${section.id}/${items[0].id}/${score}` },
  });
  const actionedRecommendations = roadmapData.pulse?.[0]?.actionedRecommendations ?? [];
  const recommendationsForScore: RecommendedContentOrTraining[] = recommendationDetailsData?.getRecommendationDetails.recommendations || [];
  const percentageComplete = getRecommendationsPercentageComplete(
    section.id as string,
    recommendationsForScore?.filter(isNotNull) ?? [],
    actionedRecommendations ?? []
  );

  function toggleIsObjectivesHidden() {
    setIsObjectivesHidden((prev) => !prev);
  }

  return (
    <ContentBox style={styles.contentBox} testId={`card-${role}-${subcategoryRole}`}>
      <View style={[styles.flexSpace, styles.topWrap]}>
        <Text style={styles.title}>{title}</Text>
        {Platform.OS === 'web' && !!dropdownSections?.length && (
          <Popover
            active={isDropdownVisible}
            activator={
              <TouchableOpacity onPress={() => setIsDropdownVisible(true)} style={styles.threeDotsTouch}>
                <ThreeDots />
              </TouchableOpacity>
            }
            onClose={() => setIsDropdownVisible(false)}
            preferredAlignment="left"
          >
            <ActionList actionRole="menuitem" sections={dropdownSections} />
          </Popover>
        )}
      </View>
      <View style={styles.expandedContent}>
        {items.map(({ subcategory }) => (
          <PriorityItem key={subcategory} title={subcategory ?? ''} />
        ))}
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
          <View
            style={{
              borderRadius: 20,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              paddingHorizontal: 8,
              paddingVertical: 2,
              backgroundColor: 'rgb(229, 239, 253)',
              marginRight: 5,
            }}
          >
            <Text style={{ color: 'rgb(13, 23, 56)' }}>
              {score}
              <Text style={{ fontSize: 10 }}>/{maxScore}</Text>
            </Text>
          </View>
          <View style={{ opacity: 0.8 }}>{percentageComplete ?? 0}% complete</View>
        </View>

        <View
          style={{
            width: 'fit-content',
            marginLeft: 'auto',
          }}
        >
          <Button onClick={toggleIsObjectivesHidden} plain id="toggle_objectives">
            {isObjectivesHidden ? 'Show' : 'Hide'} objectives
          </Button>
        </View>
      </View>
      <ObjectivesList subcategory={items[0]} section={section} isObjectivesHidden={isObjectivesHidden} />
    </ContentBox>
  );
};
