import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  radioContent: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 10,
  },
  rateWrap: {
    width: 106,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  radioTitle: {
    color: '#202223',
    fontSize: 16,
    fontFamily: 'OpenSans_400Regular',
    marginLeft: 10,
  },
});
