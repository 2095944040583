import { ButtonGeneral } from '@components/general/button-general';
import { CloseIcon } from '@components/general/icons/CloseIcon';
import React from 'react';
import { Pressable, StyleProp, Text, View, ViewStyle } from 'react-native';

import { styles } from './styles';

interface ModalRemoveProps {
  title?: string;
  buttonName?: string;
  hasButtonLayout?: boolean;
  withCancel?: boolean;
  children: React.ReactNode;
  withCustomSecondaryButton?: boolean;
  secondaryButtonText?: string;
  footerContainerStyle?: StyleProp<ViewStyle>;
  actionButtonStyle?: StyleProp<ViewStyle>;
  onActionCustomSecondaryButton?: () => void;
  closeModal?: () => void;
  onActionButtonPress?: (modalName: string, handleUndo?: () => Promise<void>) => void;
  handleSubmit?: () => void;
  loading?: boolean;
}

const GenericModalLayout = ({
  title = 'The content has been removed from your list',
  buttonName,
  hasButtonLayout = false,
  withCancel = true,
  withCustomSecondaryButton = false,
  secondaryButtonText = '',
  footerContainerStyle,
  actionButtonStyle,
  children,
  closeModal,
  onActionButtonPress,
  onActionCustomSecondaryButton,
  handleSubmit,
  loading,
}: ModalRemoveProps) => {
  return (
    <View style={styles.wrap}>
      <View style={styles.upperView}>
        <Text style={styles.upperViewText}>{title}</Text>
        <Pressable onPress={closeModal}>
          <CloseIcon />
        </Pressable>
      </View>
      {children}
      {hasButtonLayout ? (
        <View style={[styles.footerWrap, footerContainerStyle]}>
          {withCancel ? (
            <Pressable style={[styles.footerButton, styles.footerButtonCancel]} onPress={closeModal}>
              <Text style={[styles.footerButtonText, styles.footerButtonTextCancel]}>Cancel</Text>
            </Pressable>
          ) : null}
          {withCustomSecondaryButton && (
            <Pressable style={[styles.footerButton, styles.footerButtonCancel]} onPress={onActionCustomSecondaryButton}>
              <Text style={[styles.footerButtonText, styles.footerButtonTextCancel]}>{secondaryButtonText}</Text>
            </Pressable>
          )}

          {onActionButtonPress ? (
            <Pressable
              style={[styles.footerButton, styles.footerButtonConfirm, actionButtonStyle]}
              onPress={() => onActionButtonPress!('deregisteredConfirmation')}
            >
              <Text style={[styles.footerButtonText, styles.footerButtonTextConfirm]}>{buttonName}</Text>
            </Pressable>
          ) : null}
          {handleSubmit ? <ButtonGeneral onPress={handleSubmit} label={buttonName} isLoading={loading} /> : null}
        </View>
      ) : null}
    </View>
  );
};

export { GenericModalLayout };
