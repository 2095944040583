import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  lowerViewInputWrap: {
    paddingHorizontal: 20,
    marginBottom: 20,
    gap: 4,
  },
  lowerViewInputLabel: {
    fontWeight: '400',
    fontSize: 14,
    color: '#202223',
    lineHeight: 20,
  },
  lowerViewInput: {
    borderColor: '#C9CCCF',
    borderWidth: 1,
    borderRadius: 5,
    paddingVertical: 8,
    paddingRight: 20,
    paddingLeft: 10,
  },
  lowerViewDropboxMenu: {
    borderColor: '#EDEEEF',
    borderWidth: 1,
    borderRadius: 8,
  },
  lowerViewDropboxMenuSelection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
    flexWrap: 'wrap',
  },
  lowerViewDropboxMenuSelectionTextWrap: {
    backgroundColor: '#E4E5E7',
    paddingVertical: 2,
    paddingHorizontal: 8,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 5,
    marginBottom: 5,
  },
  lowerViewDropboxMenuSelectionText: {
    color: '#202223',
    marginRight: 5,
  },
});
