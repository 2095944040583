import React from 'react';
import { View } from 'react-native';
import { Outlet } from 'react-router-native';

import { styles } from './style';

const AnalyticsWrapper = () => {
  return (
    <View style={styles.wrap}>
      <Outlet />
    </View>
  );
};

export { AnalyticsWrapper };
