import { FormField } from '@components/general/form/form-field';
import { QuestionModal } from '@components/roadmap/question-modal';
import { RoadmapPulseContext } from '@context/RoadmapPulseContext';
import { useUpdateRoadmapPulseAnswerMutation } from '@gql/generated/generated';
import { ISubmodule } from '@utils/models';
import { Formik, FormikValues } from 'formik';
import { useContext, useState } from 'react';
import { Text, View } from 'react-native';

import { styles } from './style';

function convertObjectToAnswers(inputObj: FormikValues) {
  return Object.entries(inputObj).reduce(
    (accumulator, [key, answer]) => {
      if (key === 'answers') {
        return { answers: [...accumulator.answers, ...answer] };
      } else {
        const existingAnswerIndex = accumulator.answers.findIndex((answerObj) => answerObj.questionId === key);

        if (existingAnswerIndex > -1) {
          return {
            answers: [
              ...accumulator.answers.slice(0, existingAnswerIndex),
              { questionId: key, answer: JSON.stringify(answer) },
              ...accumulator.answers.slice(existingAnswerIndex + 1),
            ],
          };
        } else {
          return {
            answers: [...accumulator.answers, { questionId: key, answer: JSON.stringify(answer) }],
          };
        }
      }
    },
    { answers: [] as any[] }
  );
}

interface Props {
  isVisible: boolean;
  onClose: () => void;
  submodule: ISubmodule;
  roadmapSection: string;
  id: string;
}

const deserialiseArrayAnswer = (answer: string): any => {
  if (!answer) return '';
  if (typeof answer !== 'string') throw new Error('Provided answer is not a string');
  try {
    const json = JSON.parse(answer);
    if (Array.isArray(json)) {
      return json;
    }
  } catch (err) {
    console.error('ERROR - FormModalPulse - deserialiseArrayAnswer():', err);
  }
  return answer;
};

const FormModalPulse = ({ id, submodule, isVisible, onClose }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const roadmapPulseContext = useContext(RoadmapPulseContext);
  if (!roadmapPulseContext) throw new Error('We are not able to find roadmap pulse context');
  const { updateAnswerState } = roadmapPulseContext;
  const [updateRoadmapPulseAnswer, { loading: loadingRoadmapPulseAnswer }] = useUpdateRoadmapPulseAnswerMutation();

  const getInitialValues = () =>
    submodule.questions.map((el) => {
      const answer = el?.answer as string;
      if (!answer) {
        return {
          questionId: el.id,
          answer: null,
        };
      }
      return {
        questionId: el.id,
        answer: deserialiseArrayAnswer(answer),
      };
    });

  const onSubmit = async (values: FormikValues) => {
    try {
      const isLastIndex = submodule.questions.length - 1 === currentIndex;
      const converted = convertObjectToAnswers(values);
      const prevAnswer = submodule.questions[currentIndex].answer;
      const newAnswer = converted.answers[currentIndex].answer;
      const isChanged = prevAnswer !== newAnswer;

      if (!isChanged && isLastIndex) {
        setCurrentIndex(0);
        onClose();
        return;
      }

      if (!isChanged && !isLastIndex) {
        setCurrentIndex(currentIndex + 1);
        return;
      }

      const pulseId = id;
      const sectionId = submodule.id;
      const questionId = submodule.questions[currentIndex].id;
      const answerType = submodule.questions[currentIndex].answerType;
      const updatedRoadmapPulseAnswer = await updateRoadmapPulseAnswer({
        variables: {
          input: {
            pulseId,
            pulseAnswer: {
              sectionId,
              questionId,
              answerType: answerType!,
              answer: {
                value: newAnswer,
              },
            },
          },
        },
      });
      const updatedAnswer = updatedRoadmapPulseAnswer.data?.updateRoadmapPulseAnswer;
      if (!updatedAnswer) {
        throw new Error('Failed to update roadmap pulse answer');
      }
      updateAnswerState(updatedAnswer.sectionId, updatedAnswer.questionId, updatedAnswer.answer.value);
      if (isLastIndex) {
        setCurrentIndex(0);
        onClose();
        return;
      }
      setCurrentIndex(currentIndex + 1);
    } catch (err) {
      console.error('ERROR - FormModalPulse - onSubmit():', err);
    }
  };

  return (
    <Formik initialValues={{ answers: getInitialValues() }} onSubmit={onSubmit} enableReinitialize>
      {({ values, handleSubmit, errors, setFieldValue, touched }) => {
        return (
          <QuestionModal
            isVisible={isVisible}
            onClose={onClose}
            title={submodule.name}
            subtitle={`${currentIndex + 1} out of ${submodule.questions.length}`}
            secondaryButtonTitle={currentIndex > 0 ? 'Back' : undefined}
            primaryButtonTitle={currentIndex === submodule.questions.length - 1 ? 'Submit' : 'Next'}
            onPrimaryButtonPress={handleSubmit}
            onSecondaryButtonPress={() => currentIndex > 0 && setCurrentIndex(currentIndex - 1)}
            isLoading={loadingRoadmapPulseAnswer}
          >
            <View style={styles.wrap}>
              {submodule.questions.map(
                (field, index) =>
                  index === currentIndex && (
                    <View key={field.id}>
                      {field.question && <Text style={styles.title}>{field.question}</Text>}
                      {field.description && <Text style={styles.description}>{field.description}</Text>}
                      <FormField
                        questionIndex={0}
                        field={field.answerType!}
                        setFieldValue={(value) => {
                          const updateAnswers = values.answers.map((el: any) => ({
                            ...el,
                            answer: el.questionId === field.id ? value : el.answer,
                          }));

                          setFieldValue('answers', updateAnswers);
                        }}
                        fieldProps={{
                          value:
                            values.answers.find((element) => {
                              return element.questionId === field.id;
                            })?.answer || '',
                          placeholder: field.placeholder,
                          options: field.options,
                          label: '',
                          // @ts-ignore
                          error: errors[field.id] && touched[field.id] ? (errors[field.id] as string) : undefined,
                        }}
                      />
                    </View>
                  )
              )}
            </View>
          </QuestionModal>
        );
      }}
    </Formik>
  );
};

export { FormModalPulse };
