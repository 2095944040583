import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { ISvgProps } from '../../../types';

const LinkedInIconDark = (props: ISvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none" {...props}>
    <Path
      fill="#5C5F62"
      d="M12 24.159c-6.627 0-12-5.373-12-12 0-6.628 5.373-12 12-12s12 5.372 12 12c0 6.627-5.373 12-12 12zm-5.85-6.003h2.684v-7.99H6.15v7.99zM7.497 6.161C6.67 6.161 6 6.792 6 7.571c0 .78.67 1.41 1.497 1.41.826 0 1.496-.63 1.496-1.41 0-.779-.67-1.41-1.496-1.41zM18 13.614c0-3.156-1.807-3.7-2.919-3.7-1.11 0-1.723.388-2.428 1.327v-1.075h-2.68v7.99h2.68v-4.342c0-.916.42-1.813 1.424-1.813s1.251.897 1.251 1.791v4.364H18v-4.542z"
    />
  </Svg>
);

export { LinkedInIconDark };
