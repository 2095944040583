import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  flexOne: {
    flex: 1,
  },
  marginRight: {
    marginRight: 16,
  },
});
