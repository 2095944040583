import React from 'react';
import { Text } from 'react-native';

import { styles } from './style';

export interface Props {
  error?: string;
}

const InputError = ({ error }: Props) => <Text style={styles.error}>{error}</Text>;

export { InputError };
