import { mediaQuery } from '@utils/misc';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  title: {
    marginBottom: 20,
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 26,
    lineHeight: 32,
    color: '#0D1738',
    textAlign: 'center',
    [mediaQuery.forMobile]: {
      textAlign: 'left',
      width: '100%',
      marginTop: 0,
      marginBottom: 8,
    },
  },
  subtitle: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#6D7175',
    marginBottom: 40,
    textAlign: 'center',
    [mediaQuery.forMobile]: {
      textAlign: 'left',
      width: '100%',
      color: '#0D1738',
      fontSize: 16,
      marginBottom: 32,
    },
  },
  disableMargin: {
    marginBottom: 0,
  },
  registerLink: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    color: '#2C6ECB',
    lineHeight: 20,
  },
});
