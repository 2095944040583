import { SearchItem } from '@components/search/search-item';
import { Content, Training, Event, Partner } from '@gql/generated/generated';
import dayjs from 'dayjs';
import React from 'react';
import { Linking } from 'react-native';
import { useNavigate } from 'react-router-native';

import { formatSubtitle, getLessonCount, roundToHours } from '../shared/SearchItemHelpers';
import { AlgoliaProps, UserAccessProps } from '../useAlgoliaSearch';

const SearchItemType = ({
  hasPadding,
  query,
  searchResult,
}: {
  hasPadding?: boolean;
  query?: string;
  searchResult:
    | (Event & AlgoliaProps & UserAccessProps)
    | (Training & AlgoliaProps & UserAccessProps)
    | (Content & AlgoliaProps & UserAccessProps)
    | (Partner & AlgoliaProps & UserAccessProps);
}) => {
  const navigate = useNavigate();

  const handlePressSearchItem = (userHasAccess: boolean | undefined, url: string) => {
    // check for undefined userHasAccess in case of some funky alternative algolia search somewhere
    const hasAccess = userHasAccess === true || userHasAccess === undefined;
    if (hasAccess) {
      navigate(url);
    } else {
      Linking.openURL('https://therecruitmentnetwork.com/membership/');
    }
  };

  const { path } = searchResult;

  if (path?.includes('Training')) {
    const { coverImage, title, durationInSeconds, averageStars, comments, shareCount, objectID, modules, userHasAccess } =
      (searchResult as Training & AlgoliaProps & UserAccessProps) || {};
    const href = `/training/${objectID}`;

    return (
      <SearchItem
        hasPadding={hasPadding}
        asset={coverImage}
        title={title || ''}
        date={`${roundToHours(durationInSeconds)} hrs total • ${getLessonCount(modules)} lessons`}
        isRating
        subtitle={formatSubtitle({ averageStars, comments, shareCount })}
        onPress={() => handlePressSearchItem(userHasAccess, href)}
        href={href}
        query={query}
        userHasAccess={userHasAccess}
      />
    );
  }

  if (path?.includes('Content')) {
    const { coverImage, title, categories, objectID, publishedAt, userHasAccess } = (searchResult as Content & AlgoliaProps & UserAccessProps) || {};
    const href = `/content/${objectID}`;
    const formattedDate = dayjs(publishedAt).format('DD MMM YY');
    return (
      <SearchItem
        hasPadding={hasPadding}
        asset={coverImage}
        title={title || ''}
        date={formattedDate}
        categories={categories?.map((cat: string) => cat.split(':')[0])}
        visibleCategoryCount={5}
        onPress={() => handlePressSearchItem(userHasAccess, href)}
        href={href}
        query={query}
        userHasAccess={userHasAccess}
      />
    );
  }

  if (path?.includes('Events')) {
    const { coverImage, title, dateFrom, objectID, type, userHasAccess } = (searchResult as Event & AlgoliaProps & UserAccessProps) || {};
    const href = `/events/${objectID}`;
    const formattedDate = dayjs(dateFrom).format('DD MMM YY • HH:mm');

    return (
      <SearchItem
        hasPadding={hasPadding}
        asset={coverImage}
        title={title || ''}
        date={formattedDate}
        subtitle={type || ''}
        onPress={() => handlePressSearchItem(userHasAccess, href)}
        href={href}
        query={query}
        userHasAccess={userHasAccess}
      />
    );
  }

  if (path?.includes('Partners')) {
    const { title, objectID, logo, createdAt, userHasAccess } = (searchResult as Partner & AlgoliaProps & UserAccessProps) || {};
    const href = `/marketplace/${objectID}`;
    const formattedDate = dayjs(createdAt).format('DD MMM YY • HH:mm');

    return (
      <SearchItem
        hasPadding={hasPadding}
        asset={logo}
        date={formattedDate}
        title={title || ''}
        subtitle=""
        onPress={() => handlePressSearchItem(userHasAccess, href)}
        href={href}
        query={query}
        userHasAccess={userHasAccess}
      />
    );
  }

  return <></>;
};

export { SearchItemType };
