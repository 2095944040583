import { FilterCheckbox } from '@components/filter-mobile/filter-checkbox';
import { FilterWrapper } from '@components/filter-mobile/filter-wrapper';
import React, { useState } from 'react';
import { FlatList } from 'react-native';

export enum ContentTypeOptions {
  article = 'Article',
  pdfDocument = 'PDF Document',
  wordDocument = 'Word Document',
  excelDocument = 'Excel Document',
  powerPointDocument = 'PowerPoint Document',
  podcast = 'Podcast',
  powerplay = 'Powerplay',
  template = 'Template',
  tool = 'Tool',
  video = 'Video',
  webinarRoundtable = 'Webinar/Roundtable',
  trnPartnerContent = 'TRN Partner Content',
}

const contentTypeOptions = [
  ContentTypeOptions.article,
  ContentTypeOptions.pdfDocument,
  ContentTypeOptions.wordDocument,
  ContentTypeOptions.excelDocument,
  ContentTypeOptions.powerPointDocument,
  ContentTypeOptions.podcast,
  ContentTypeOptions.powerplay,
  ContentTypeOptions.template,
  ContentTypeOptions.tool,
  ContentTypeOptions.video,
  ContentTypeOptions.webinarRoundtable,
  ContentTypeOptions.trnPartnerContent,
];

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSave: (contentTypes: ContentTypeOptions[]) => void;
}

const ContentType = ({ isVisible, onClose, onSave }: Props) => {
  const [contentTypes, setContentTypes] = useState<ContentTypeOptions[]>([]);

  const handleSelect = (contentType: ContentTypeOptions) => {
    if (contentTypes.includes(contentType)) {
      const index = contentTypes.indexOf(contentType);
      const contentTypesCopy = [...contentTypes];
      contentTypesCopy.splice(index, 1);
      setContentTypes(contentTypesCopy);
    } else {
      setContentTypes([...contentTypes, contentType]);
    }
  };

  const renderItem = ({ item }: { item: ContentTypeOptions }) => (
    <FilterCheckbox title={item} isSelected={contentTypes.includes(item)} onToggle={() => handleSelect(item)} />
  );

  return (
    <FilterWrapper
      headerTitle="Content Types"
      isVisible={isVisible}
      onClose={onClose}
      buttonTitle="Save"
      isDoneButtonDisabled={!contentTypes.length}
      onDone={() => contentTypes.length && onSave(contentTypes)}
    >
      <FlatList data={contentTypeOptions} renderItem={renderItem} keyExtractor={(item) => item} />
    </FilterWrapper>
  );
};

export { ContentType };
