import { mediaQuery } from '@utils/misc';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
  },
  title: {
    color: '#0D1738',
    fontSize: 26,
    fontFamily: 'OpenSans_600SemiBold',
    marginBottom: 20,
  },
  description: {
    color: '#0D1738',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    marginBottom: 22,
  },
  instructorNameWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  instructorInitialsWrap: {
    backgroundColor: '#E4E5E7',
    height: 24,
    width: 24,
    borderRadius: 12,
    marginRight: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  instructorInitialsText: {
    fontSize: 12,
    color: '#FFFFFF',
    fontFamily: 'OpenSans_400Regular',
  },
  instructorName: {
    color: '#6D7175',
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
  },
  hoursWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
    justifyContent: 'space-between',
    [mediaQuery.forMobile]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  totalHoursText: {
    color: '#2C6ECB',
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
  },
  dateCreated: {
    color: '#6D7175',
    fontSize: 12,
    fontFamily: 'OpenSans_600SemiBold',
    marginLeft: 8,
    [mediaQuery.forMobile]: {
      marginLeft: 0,
      marginTop: 4,
    },
  },
  enrolledWrap: {
    backgroundColor: '#2C6ECB',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 15,
    marginLeft: 4,
    [mediaQuery.forMobile]: {
      marginLeft: 0,
      marginTop: 4,
    },
  },
  enrolledText: {
    color: '#FFFFFF',
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
  },
  reviewsWrap: {
    marginTop: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  reviewsText: {
    color: '#6D7175',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    marginLeft: 4,
  },
  jobRoleWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16,
    flexWrap: 'wrap',
  },
  jobRoleText: {
    color: '#0D1738',
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
  },
  roleWrap: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: '#E4E5E7',
    borderRadius: 35,
    marginLeft: 4,
  },
  roleText: {
    color: '#202223',
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
  },
  progressContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  progressBarOuter: {
    display: 'flex',
    flex: 1,
    backgroundColor: '#E4E5E7',
    height: 8,
    borderRadius: 4,
  },
  progressBarInner: {
    backgroundColor: '#2C6ECB',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    flex: 1,
  },
});
