import { FormSection } from '@components/back-office/editor/form-section/FormSection';
import { FormikInput } from '@components/back-office/editor/formik-input';
import { FormikPublishTools } from '@components/back-office/editor/formik-publish-tools/FormikPublishTools';
import { FormikRichText } from '@components/back-office/editor/formik-rich-text';
import { FormikSaveBar } from '@components/back-office/editor/formik-save-bar';
import { FormikTextArea } from '@components/back-office/editor/formik-text-area/FormikTextArea';
import { FileUploaderWithTitle } from '@components/back-office/editor/uploader/FileUploaders';
import { Toast } from '@components/general/toast';
import { useToast } from '@context/ToastContext';
import { CollectionName, useCreatePostMutation, useGetPostQuery, useUpdatePostMutation } from '@gql/generated/generated';
import { Icon } from '@shopify/polaris';
import { ChevronLeftMinor } from '@shopify/polaris-icons';
import { customFirestoreId } from '@utils/misc';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { useNavigate, useParams } from 'react-router-native';
import * as Yup from 'yup';

import { styles } from './style';

const PostEditor = () => {
  const { id: existingId } = useParams() as { id: string };
  const navigate = useNavigate();
  const { addToast } = useToast();

  const { data } = useGetPostQuery({
    variables: { id: existingId },
    skip: !existingId,
  });

  const post = data?.getPost;

  const postId = existingId || customFirestoreId();

  const [createPost] = useCreatePostMutation();
  const [updatePost] = useUpdatePostMutation();

  return (
    <View style={{ display: 'flex' }}>
      <Formik
        initialValues={{
          id: postId,
          title: post?.title ?? '',
          content: post?.content ?? '',
          summary: post?.summary ?? '',
          coverImage: post?.coverImage ?? null,
          status: post?.status ?? 'Draft',
          postType: post?.type ?? 'Announcement',
          link: post?.link ?? '',
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          title: Yup.string().when('postType', {
            is: 'News',
            then: Yup.string().required('This field is required'),
            otherwise: Yup.string(),
          }),
          link: Yup.string().when('postType', {
            is: 'News',
            then: Yup.string().required('This field is required'),
            otherwise: Yup.string(),
          }),
          summary: Yup.string().when('postType', {
            is: 'News',
            then: Yup.string().required('This field is required'),
            otherwise: Yup.string(),
          }),
          content: Yup.string().when('postType', {
            is: 'Announcement',
            then: Yup.string().required('This field is required'),
            otherwise: Yup.string(),
          }),
          postType: Yup.string().required('This field is required'),
        })}
        onSubmit={async (values) => {
          const { postType, id, title, content, summary, status, link } = values;

          if (existingId) {
            await updatePost({
              variables: {
                input: {
                  id,
                  title,
                  content,
                  summary,
                  status,
                  type: postType,
                  ...(link ? { link } : {}),
                },
              },
            });
            addToast('success', 'Post successfully updated');
            navigate('/posts');
          } else {
            await createPost({
              variables: {
                input: {
                  id,
                  title,
                  content,
                  summary,
                  status,
                  type: postType,
                  ...(link ? { link } : {}),
                },
              },
            });
            addToast('success', 'Post successfully added');
            navigate('/posts');
          }
        }}
      >
        {({ values }) => {
          return (
            <>
              <FormikSaveBar />
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 12,
                }}
              >
                <TouchableOpacity
                  style={{
                    borderWidth: 1,
                    borderColor: '#BABFC3',
                    borderRadius: 4,
                    width: 36,
                    height: 36,
                    marginRight: 8,
                  }}
                  onPress={() => {
                    navigate('/posts');
                  }}
                >
                  <Icon source={ChevronLeftMinor} />
                </TouchableOpacity>
                <Text style={styles.title}>Add New Post</Text>
              </View>
              <View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                    }}
                  >
                    <FormSection>
                      {values.postType === 'News' ? (
                        <FormikInput fieldName="title" label="Title" placeholder="Enter post title" />
                      ) : (
                        <FormikRichText fieldName="content" title="Content" placeholder="Add Content Here" toolbar={['link']} />
                      )}

                      {values.postType === 'News' ? (
                        <>
                          <FormikTextArea fieldName="summary" label="Summary Text" maxLength={150} placeholder="Add post summary" />
                          <FormikInput fieldName="link" label="Link" placeholder="Enter news link url" />
                        </>
                      ) : null}
                    </FormSection>
                  </View>
                  <View style={{ marginLeft: 20 }}>
                    <FormikPublishTools withJobRoles={false} withCategories={false} withUserType={false} withPostType />
                    <FileUploaderWithTitle
                      subtitle="This is the image / video that will be shown in the post"
                      title="Cover image / video"
                      initialAsset={post?.coverImage ?? null}
                      assetInstruction={{
                        instructionType: 'one-to-one',
                        collectionId: CollectionName.POSTS,
                        documentId: postId,
                        key: 'coverImage',
                      }}
                      formikFieldname="coverImage"
                      validFileTypes={['image/jpeg', 'image/png', 'video/mp4', 'video/webm']}
                    />
                  </View>
                </View>
              </View>
            </>
          );
        }}
      </Formik>
    </View>
  );
};

export { PostEditor };
