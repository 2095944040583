import { getDownloadURL, ref } from '@firebase/storage';
import { useEffect, useState } from 'react';

import { storage } from '../firebase';

export function useGetRoadmapVideos() {
  const [roadmapVideos, setRoadmapVideos] = useState<{ goal: string; northStar: string; pulse: string; tutorial: string } | null>(null);

  useEffect(() => {
    const videoPaths = {
      goal: 'roadmap/roadmap-intro/Goal-InfoGraphicVideo.mp4',
      northStar: '/roadmap/roadmap-intro/NorthStar-InfoGraphic.mp4',
      pulse: 'roadmap/roadmap-intro/Pulse-RoadMap.mp4',
      tutorial: 'roadmap/roadmap-intro/RoadMap-tutorial.mp4',
    };

    (async () => {
      try {
        const uris = await Promise.all(Object.values(videoPaths).map((path) => getDownloadURL(ref(storage, path))));
        const videoUris = Object.keys(videoPaths).reduce((accumulator, key, index) => {
          return { ...accumulator, [key]: uris[index] };
        }, {} as typeof videoPaths);
        setRoadmapVideos({ ...videoUris });
      } catch (err) {
        console.error('ERROR - useGetRoadmapVideos():', err);
      }
    })();
  }, []);

  return [roadmapVideos];
}
