import React from 'react';
import { Text, View } from 'react-native';

import { styles } from './style';

interface InitialsCircleProps {
  initials: string;
  backgroundColor?: string | number;
  size?: number;
  fontSize?: number;
}

export const INITIALS_CIRCLE_BACKGROUND_COLORS = ['#4CA1DC', '#FFC96B', '#E4E5E7', '#1653CE', '#41D5B8', '#FED3D1', '#C9CCD0'];

const InitialsCircle = ({ initials, backgroundColor, size, fontSize }: InitialsCircleProps) => {
  return (
    <View
      style={[
        styles.container,
        typeof backgroundColor === 'string'
          ? {
              backgroundColor,
            }
          : {
              backgroundColor: INITIALS_CIRCLE_BACKGROUND_COLORS[backgroundColor! % 6],
            },
        !!size && {
          width: size,
          height: size,
          borderRadius: size / 2,
        },
      ]}
    >
      <Text
        style={[
          styles.containerText,
          !!size && {
            lineHeight: size,
          },
          !!fontSize && {
            fontSize,
          },
        ]}
      >
        {initials}
      </Text>
    </View>
  );
};

export { InitialsCircle };
