import { mediaQuery } from '@utils/misc';
import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
    alignItems: 'center',
    ...(Platform.OS === 'web' && { position: 'fixed' }),
    ...(Platform.OS !== 'web' && { marginTop: 30 }),
    backgroundColor: '#fff',
    height: 53,
  },
  inner: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    [mediaQuery.forTabletDown]: {
      alignItems: 'center',
      justifyContent: Platform.OS === 'web' ? 'flex-start' : 'center',
      paddingTop: 10,
    },
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    [mediaQuery.forTabletDown]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  unAuthenticatedContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingTop: 16,
    paddingBottom: 16,
    backgroundColor: '#FFFFFF',
    [mediaQuery.forTabletDown]: {
      paddingLeft: 24,
      paddingRight: 24,
      display: 'none',
    },
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 271,
    height: 33,
    borderWidth: 1,
    borderColor: '#EDEEEF',
    borderRadius: 35,
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 40,
    [mediaQuery.forMobile]: {
      maxWidth: 210,
      marginTop: 0,
    },
  },
  searchContainerHover: {
    borderColor: '#2C6ECB',
    borderWidth: 2,
  },
  logo: {
    minWidth: 165,
    height: 27,
    display: 'flex',
    [mediaQuery.forTabletDown]: {
      display: 'none',
    },
  },
  mobileLogo: {
    display: 'none',
    minWidth: 19,
    height: 32,
    [mediaQuery.forTabletDown]: {
      display: Platform.OS === 'web' ? 'block' : 'flex',
    },
  },
  unAuthLogo: {
    width: 165,
    height: 27,
  },
  searchbar: {
    ':focus': {
      color: '#000000',
    },
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    color: '#6D7175',
    width: '90%',
    marginLeft: 6,
    ...(Platform.OS === 'web' && { outline: 'none' }),
    height: '100%',
  },
  navbtn: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 15,
    lineHeight: 18,
    color: '#0D1738',
    marginTop: 19,
    marginLeft: 41,
  },
  bell: {
    width: 19,
    height: 19,
    marginTop: 19,
    marginLeft: 45,
    marginRight: 13,
  },
  avatar: {
    width: 32,
    height: 32,
    borderRadius: 50,
    objectFit: 'cover',
  },
  polygon: {
    position: 'absolute',
    width: 7,
    height: 4,
    marginTop: 27,
    marginLeft: 103,
  },
  wrapToContent: {
    ...(Platform.OS === 'web' && { height: 'max-content' }),
    ...(Platform.OS === 'web' && { width: 'max-content' }),
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '95%',
    position: 'relative',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 8,
  },
  wrapToContentAdmin: {
    ...(Platform.OS === 'web' && { height: 'max-content' }),
    ...(Platform.OS === 'web' && { width: 'max-content' }),
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '95%',
    position: 'relative',
  },
  navTitle: {
    fontSize: 12,
    color: '#5C5F62',
    fontFamily: 'OpenSans_400Regular',
    marginTop: 4,
    marginBottom: 6,
  },
  navActiveBottom: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 3,
    borderTopRightRadius: 16,
    borderTopLeftRadius: 16,
    backgroundColor: '#0D1738',
  },
  adminWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuWrap: {
    display: 'flex',
    flexDirection: 'row',
    [mediaQuery.forTabletDown]: {
      display: 'none',
    },
  },
  menuItem: {
    marginRight: 8,
    marginLeft: 8,
  },
  profileWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  notification: {
    marginLeft: 8,
    backgroundColor: '#EDEEEF',
    height: 35,
    width: 35,
    borderRadius: 17.5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  redDot: {
    backgroundColor: '#D72C0D',
    height: 12,
    width: 12,
    borderRadius: 6,
    position: 'absolute',
    top: 0,
    right: 0,
  },
  userTouch: {
    marginLeft: 0,
  },
  welcomeMsg: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 15,
    lineHeight: 18.15,
    color: '#0D1738',
    marginRight: 8,
  },
  filterText: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 20,
    color: '#202223',
    marginTop: 2,
  },
});
