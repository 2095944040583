import { percentageChange } from '@components/org/analytics/helpers';
import { useGetAdminContentHubQuery, useGetAdminGeneralStatsQuery, useGetAdminPageTimeQuery } from '@gql/generated/generated';
import { useMemo } from 'react';

const boxTitles = ['Active Users', 'New Users', 'New Content', 'New Events', 'New Training'];

export const useAdminAnalyticsGeneral = () => {
  const { data } = useGetAdminGeneralStatsQuery();
  const { data: avgPageData, refetch: refetchPageTime, loading: loadingPageTime } = useGetAdminPageTimeQuery();

  const { data: contentData, refetch: refetchContentHub } = useGetAdminContentHubQuery();

  const boxItems = useMemo(() => {
    if (!data?.adminGetMemberStats.general)
      return boxTitles.map((item) => ({
        title: item,
      }));

    const { activeUsers, newUsers, newContent, newEvents, newTraining } = data?.adminGetMemberStats.general;
    return [
      {
        title: 'Active Users',
        count: activeUsers.curr,
        percent: percentageChange(activeUsers?.prev || 0, activeUsers.curr),
        percentInfo: 'than yesterday',
      },
      {
        title: 'New Users',
        count: newUsers.curr,
        percent: percentageChange(newUsers?.prev || 0, newUsers.curr),
        percentInfo: 'than last month',
      },
      {
        title: 'New Content',
        count: newContent.curr,
        countInfo: 'content',
        percent: percentageChange(newContent?.prev || 0, newContent.curr),
        percentInfo: 'than yesterday',
      },
      {
        title: 'New Events',
        count: newEvents.curr,
        countInfo: 'events',
        percent: percentageChange(newEvents?.prev || 0, newEvents.curr),
        percentInfo: 'than yesterday',
      },
      {
        title: 'New Training',
        count: newTraining.curr,
        countInfo: 'courses',
        percent: percentageChange(newTraining?.prev || 0, newTraining.curr),
        percentInfo: 'than yesterday',
      },
    ];
  }, [data?.adminGetMemberStats.general]);

  const dataForExport = useMemo(() => {
    return [
      {
        columns: [
          { label: 'Title', value: 'title' },
          { label: 'Avg. time (mins/member)', value: 'time' },
          { label: 'Total views', value: 'views' },
          { label: 'Unique views', value: 'unique' },
          { label: 'Type', value: 'type' },
          { label: 'Category', value: 'category' },
        ],
        data: contentData?.adminGetMemberStats.general?.contentHub.map((item) => {
          return {
            title: item.title,
            time: item.averageTimePerMemberMins,
            views: item.totalPageViews,
            unique: item.users?.length,
            type: item.type,
            category: item.categories?.join(','),
          };
        }),
        name: 'Content hub',
      },
      {
        columns: [
          { label: 'Date', value: 'date' },
          {
            label: 'Average time on content page (mins/member)',
            value: 'time',
          },
        ],
        data: avgPageData?.adminGetMemberStats.general?.averagePageTime.dates.map((date, i) => {
          return {
            date,
            time: avgPageData?.adminGetMemberStats.general?.averagePageTime.time[i],
          };
        }),
        name: 'Avg time',
      },
    ];
  }, [contentData, avgPageData]);

  return {
    boxItems,
    averagePageTime: avgPageData?.adminGetMemberStats.general?.averagePageTime,
    refetchPageTime,
    refetchContentHub,
    contentData: contentData?.adminGetMemberStats.general?.contentHub,
    dataForExport,
    loadingPageTime,
  };
};
