import { mediaQuery } from '@utils/misc';
import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  button: {
    borderWidth: 1,
    borderColor: '#0D1738',
    borderRadius: 4,
    height: 36,
    paddingLeft: 16,
    paddingRight: 16,
    minWidth: 68,
    backgroundColor: '#0D1738',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 1,
    [mediaQuery.forTabletDown]: {
      height: 44,
    },
  },
  userButton: {
    borderRadius: 35,
    height: Platform.OS === 'web' ? 30 : 44,
  },
  secondary: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#BABFC3',
  },
  disabled: {
    opacity: 0.4,
  },
  text: {
    color: '#FFFFFF',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
  },
  textSecondary: {
    color: '#202223',
  },
  marginRight: { marginRight: 8 },
  secondaryContainerHover: { borderColor: '#5C5F62' },
  labelText: {
    color: '#FFFFFF',
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
    lineHeight: 20,
  },
  rounded: {
    borderRadius: 35,
  },
});
