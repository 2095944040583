import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 40,
    paddingBottom: 3,
    paddingTop: 12,
  },
  containerBorder: {
    borderBottomColor: '#E5E5E5',
    borderBottomWidth: 1,
  },
});
