import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  paddingWrap: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 6,
    paddingBottom: 6,
    borderRadius: 4,
  },
  leftBox: {
    width: '20%',
    maxWidth: 64,
  },
  rightBox: {
    width: '80%',
  },
  image: {
    height: 56,
    width: 56,
    borderRadius: 4,
  },
  title: {
    color: '#000000',
    fontSize: 14,
    fontFamily: 'OpenSans_500Medium',
    marginBottom: 4,
  },
  highlightedTitle: {
    fontFamily: 'OpenSans_700Bold',
  },
  date: {
    color: '#2C6ECB',
    fontSize: 12,
    fontFamily: 'OpenSans_600SemiBold',
    marginBottom: 4,
  },
  pillWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  pillWrapSubtitle: {
    color: '#6D7175',
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
  },
  subtitle: {
    fontSize: 12,
    fontFamily: 'OpenSans_700Bold',
    marginBottom: 4,
  },
  categoriesRow: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    gap: 4,
    flex: 1,
  },
  categoryPill: {
    ':hover': {
      flex: 0,
      minWidth: 'initial',
    },
    flex: 1,
    maxWidth: 'fit-content',
  },
});
