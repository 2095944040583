import { gql } from "@apollo/client";

export const COMMENT_REPLY_FRAGMENT = gql`
  fragment CommentReplyFragment on Comment {
    id
    commentedItemId
    commentedItemType
    collectionName
    userId
    text
    createdAt
    likedBy
    userName
    userJobTitle
    userAvatarIndex
    userProfilePhotoUrl
    rating
    replies {
      id
      likedBy
      userId
      text
      createdAt
      userName
      userJobTitle
      userAvatarIndex
      userProfilePhotoUrl
    }
  }
`;

export const REPLY_FRAGMENT = gql`
  fragment ReplyFragment on Reply {
    id
    likedBy
    userId
    text
    createdAt
    userName
    userJobTitle
    userAvatarIndex
    userProfilePhotoUrl
  }
`;

export const COMMENT_FRAGMENT = gql`
  fragment CommentFragment on Comment {
    id
    commentedItemId
    commentedItemType
    collectionName
    userId
    text
    createdAt
    likedBy
    userName
    userJobTitle
    userAvatarIndex
    userProfilePhotoUrl
    rating
    replies {
      ...ReplyFragment
    }
  }
`;

gql`
  mutation getComments($input: GetCommentsInput!) {
    getComments(input: $input) {
      ...CommentFragment
    }
  }
`;

gql`
  mutation createComment($input: CommentInput!) {
    createComment(input: $input) {
      ...CommentFragment
    }
  }
`;

gql`
  mutation createReply($input: ReplyInput!) {
    createReply(input: $input) {
      ...CommentFragment
    }
  }
`;
gql`
  mutation updateCommentLikes($input: CommentLikeInput!) {
    updateCommentLikes(input: $input) {
      ... on Comment {
        ...CommentFragment
      }
      ... on Reply {
        ...ReplyFragment
      }
    }
  }
`;
