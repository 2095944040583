import { PaymentActivity } from '@components/account/subscription-plan/payment-activity/PaymentActivity';
import { SideCardWithTitle } from '@components/back-office/cards';
import { FormSection } from '@components/back-office/editor/form-section';
import { FormikDatePickerDropdown } from '@components/back-office/editor/formik-date-picker-dropdown/FormikDatePickerDropdown';
import { FormikDropzone } from '@components/back-office/editor/formik-dropzone';
import { FormikSaveBar } from '@components/back-office/editor/formik-save-bar';
import { OrderedTable } from '@components/back-office/tables/ordered-table';
import { TableDropdown } from '@components/back-office/tables/table-dropdown';
import { AnalyticCard } from '@components/back-office/users/analytic-card';
import { FormikCategoriesAvailable } from '@components/back-office/users/formik-categories-available';
import { UserGeneralHeader } from '@components/back-office/users/user-general-section-header';
import { UserPersonalInfo, UserPersonalInfoForm } from '@components/back-office/users/user-personal-info';
import { SelectOption } from '@components/general/form/select-option';
import { Row } from '@components/general/row';
import { ModalContainer } from '@components/org/saved/modals/ModalContainer';
import { ConfirmationModalContent } from '@components/org/saved/modals/confirmation-modal/ConfirmationModalContent';
import { GenericModalLayout } from '@components/org/saved/modals/generic-modal-layout/GenericModalLayout';
import { useToast } from '@context/ToastContext';
import {
  AccountType,
  useAdminGetUserQuery,
  useAdminSendVerificationEmailMutation,
  useAdminUpdateUserMutation,
  useAdminUpdateUserSubscriptionMutation,
  useCreateUserMutation,
  useEmployerGetGoCardlessCustomerQuery,
} from '@gql/generated/generated';
import { DropMenuFormik } from '@pages/account/profile/form-elements/drop-menu-formik/DropMenuFormik';
import { Checkbox } from '@shopify/polaris';
import { textStyles } from '@styles/text';
import { allCategories } from '@utils/constants';
import { userEditorValidationSchema } from '@utils/validators';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { useState } from 'react';
import { ActivityIndicator, Image, Modal, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate, useParams } from 'react-router-native';

import { OrgSection } from './OrgSection';
import { styles } from './style';
import { InitialValuesProps, categoriesAvailable } from './userInitialValues';
import { getAvailableCategories, getFirstAndLastName, getUnavailableCategories } from './utils';

const { events, ...restOfCategories } = allCategories;
const { social: _social, ...restOfEvents } = events;
const allUserCategories = { ...restOfCategories, events: { ...restOfEvents } };

let userRegistrationDate: Date;
let userRegistrationDateTimestamp: number;
const defaultRegistrationDate = new Date();
const getUserRegistrationDate = (timestamp: number | undefined) => {
  if (!timestamp) {
    return defaultRegistrationDate;
  }
  if (!userRegistrationDate || userRegistrationDateTimestamp !== timestamp) {
    userRegistrationDateTimestamp = timestamp;
    userRegistrationDate = new Date(Number(userRegistrationDateTimestamp));
  }
  return userRegistrationDate;
};

export const UsersEditor = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { addToast } = useToast();

  const [personalInfoEdit, setPersonalInfoEdit] = useState(!id);
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [isPaymentMenuOpen, setIsPaymentMenuOpen] = useState(true);

  const [createUser] = useCreateUserMutation();
  const [updateUser] = useAdminUpdateUserMutation();
  const [updateSub] = useAdminUpdateUserSubscriptionMutation();
  const [adminSendVerificationEmail, { loading }] = useAdminSendVerificationEmailMutation();

  const { data: goCardLessCustomerData } = useEmployerGetGoCardlessCustomerQuery();
  const { data, loading: gettingUser } = useAdminGetUserQuery({
    variables: { id: id! },
    skip: !id,
  });

  const user = data?.adminGetUser;
  const organisationId = user?.accountInfo?.companyInfo?.id;

  const goCardlessCustomer = goCardLessCustomerData?.employerGetGoCardlessCustomer;

  const accountInfo = user?.accountInfo;

  const subscriptionPlans = ['Free', 'Plus', 'Club'].map((plan) => ({
    value: plan,
  }));

  const handleOpenModal = (title: string, text: string) => {
    setModalText(text);
    setModalTitle(title);
    setShowModal(true);
  };

  const handleResendVerificationEmail = async () => {
    if (user?.id) {
      await adminSendVerificationEmail({
        variables: { input: { userId: user.id } },
      });
      addToast('success', `Email Sent to ${user?.accountInfo?.email}`);
    }
  };

  if (gettingUser) {
    return (
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
        }}
      >
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  }

  const initialValues = {
    id,
    profilePhotoUrl: user?.profilePhotoUrl,
    name: accountInfo ? `${accountInfo?.firstName} ${accountInfo?.lastName}` : '',
    email: accountInfo?.email || '',
    phoneNumber: accountInfo?.phoneNumber || '',
    addressOne: accountInfo?.addressOne,
    addressTwo: accountInfo?.addressTwo,
    postCode: accountInfo?.postCode,
    country: accountInfo?.country || '',
    city: accountInfo?.city,
    registrationDate: getUserRegistrationDate(user?.registrationDate),
    jobPosition: accountInfo?.companyInfo.jobPosition || '',
    organisationId,
    companyName: '',
    categoriesAvailable: user ? getAvailableCategories(allUserCategories, user?.blockedAccess) : categoriesAvailable,
    accountStatus: user?.status?.accountStatus ?? '',
    subscriptionPlan: user?.subscription?.plan || 'Free',
    suspendedFrom: user?.status?.suspendedFrom,
    suspendedTo: user?.status?.suspendedTo,
    withSuspensionEndDate: user?.status?.accountStatus === 'Suspend' && !!user?.status?.suspendedTo,
    accountType: user?.accountType ?? AccountType.EMPLOYEE,
  };

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues as typeof initialValues & InitialValuesProps}
        validationSchema={userEditorValidationSchema}
        onSubmit={async (values) => {
          try {
            const {
              name,
              email,
              phoneNumber,
              addressOne,
              addressTwo,
              postCode,
              country,
              city,
              registrationDate,
              jobPosition,
              accountType,
              profilePhotoUrl,
              categoriesAvailable,
              organisationId,
              companyName,
              suspendedFrom,
              suspendedTo,
              accountStatus,
              subscriptionPlan,
            } = values;

            const firstName = getFirstAndLastName(name).firstName;
            const lastName = getFirstAndLastName(name).lastName;
            const blockedAccess = getUnavailableCategories(allUserCategories, categoriesAvailable);

            const createUserInput = {
              firstName,
              lastName,
              email,
              phoneNumber,
              addressOne,
              addressTwo,
              postCode,
              country,
              city,
              jobPosition,
              blockedAccess,
              profilePhotoUrl,
              accountType,
              organisationId,
              companyName,
            };

            const updateUserInput = {
              ...createUserInput,
              registrationDate: registrationDate.getTime().toString(),
              accountStatus,
              suspendedFrom: accountStatus === 'Suspend' ? suspendedFrom : null,
              suspendedTo: accountStatus === 'Suspend' ? suspendedTo : null,
            };

            // if id, then we are updating an existing user
            if (id && organisationId) {
              const result = await updateUser({
                variables: {
                  input: {
                    user: updateUserInput,
                    id,
                  },
                },
              });

              let subUpdatedSuccessfully = true;

              const subResult = await updateSub({
                variables: {
                  id,
                  subscriptionPlan,
                },
              });

              if (!subResult.data?.adminUpdateUserSubscription?.id) {
                subUpdatedSuccessfully = false;
              }

              if (result?.data?.adminUpdateUser?.id) {
                addToast('success', 'User Successfully Updated!');

                if (!subUpdatedSuccessfully) {
                  addToast('error', 'Error updating user subscription plan!');
                }

                if (accountStatus === 'Suspend' && suspendedFrom) {
                  handleOpenModal(
                    'User Suspended',
                    `This user account has been suspended from ${dayjs(new Date(suspendedFrom)).format('DD/MM/YY')} until ${
                      suspendedTo ? dayjs(new Date(suspendedFrom)).format('DD/MM/YY') : 'further notice.'
                    } `
                  );
                } else if (accountStatus === 'Delete') {
                  handleOpenModal('User Deleted', `This user account has been deleted.`);
                }
              }
            } else {
              const result = (
                await createUser({
                  variables: {
                    input: {
                      ...createUserInput,
                      accountType: AccountType.EMPLOYEE,
                    },
                  },
                })
              ).data?.createUser;

              if (!result) return;

              if (result.__typename === 'User' && result.id) {
                // TODO Posthog tracking here for user creation

                addToast('success', 'User Sucessfully Added');
                setTimeout(() => navigate(`/users`), 1500);
                return;
              }

              throw result;
            }
          } catch (error: any) {
            console.log({ error });
            addToast(
              'error',
              `There was an error with your request. Please ensure that you have completed all fields.${error.message ? error.message : ''}`
            );
          }
        }}
      >
        {({ values, ...props }) => {
          return (
            <>
              {/* header */}
              <FormikSaveBar />
              <Modal visible={showModal} transparent>
                <ModalContainer>
                  <GenericModalLayout
                    title={modalTitle}
                    closeModal={() => {}}
                    hasButtonLayout
                    buttonName="Back to Users"
                    onActionButtonPress={() => navigate('/users')}
                    withCancel={false}
                  >
                    <ConfirmationModalContent mainText={modalText} secondaryText="" />
                  </GenericModalLayout>
                </ModalContainer>
              </Modal>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 12,
                }}
              >
                <TouchableOpacity style={styles.touchableOpacityButton} onPress={() => navigate(-1)}>
                  <Image source={require('/assets/img/ArrowLeft.png')} style={{ width: 7, height: 12 }} />
                </TouchableOpacity>
                <Text style={styles.headerTitle}>{values.id ? 'Edit' : 'Add'} User</Text>
              </View>

              {/* content */}
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {/* left Side */}
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  <FormSection>
                    <FormikCategoriesAvailable />
                  </FormSection>
                  <FormSection style={{ marginBottom: 20 }}>
                    <Text style={styles.sectionTitle}>Membership</Text>
                    <Text style={[styles.userTextInfo, { marginBottom: 4 }]}>Plan</Text>

                    <DropMenuFormik formikKey="subscriptionPlan" placeholder="Subscription Plan" data={subscriptionPlans} withoutVerticalMargin />

                    <PaymentActivity
                      payments={goCardlessCustomer?.subscription?.upComingPayments ?? []}
                      isMenuOpen={isPaymentMenuOpen}
                      setIsMenuOpen={() => setIsPaymentMenuOpen(!isPaymentMenuOpen)}
                      containerStyle={{ paddingHorizontal: 0 }}
                    />
                  </FormSection>
                  <FormSection>
                    <Text style={styles.sectionTitle}>Analytics</Text>

                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 20,
                        gap: 16,
                      }}
                    >
                      <AnalyticCard title="New Team Members" number={5} percentage={65} percentageLabel="than last month" />
                      <AnalyticCard title="Average Time Spend" number={39.5} percentage={29.5} numberLabel="Min" percentageLabel="than last month" />
                      <AnalyticCard
                        title="Average Invitations"
                        number={8.7}
                        percentage={29.5}
                        numberLabel="Invitation"
                        percentageLabel="than last month"
                      />
                    </View>

                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Text style={styles.userTextInfo}>Most Visited Pages</Text>

                      {/* TODO: check width Gonzalo the values here */}
                      <TableDropdown placeholder="Last 30 Days" items={[{ id: 'Last 30 days', content: 'Last 30 days' }]} />
                    </View>
                    <OrderedTable />
                  </FormSection>
                </View>

                {/* right side */}
                <View style={{ width: 315, marginLeft: 20 }}>
                  {/* user info */}
                  {user?.id && !user?.isVerified && (
                    <FormSection>
                      <TouchableOpacity style={styles.verificationButton} onPress={handleResendVerificationEmail}>
                        <>Resend Verification Email</>
                      </TouchableOpacity>
                    </FormSection>
                  )}
                  <FormSection>
                    <UserGeneralHeader
                      title="User"
                      editButton
                      editMode={personalInfoEdit}
                      setEditMode={() => {
                        setPersonalInfoEdit(!personalInfoEdit);
                      }}
                    />
                    {!personalInfoEdit ? (
                      <UserPersonalInfo
                        firstName={user?.accountInfo?.firstName}
                        lastName={user?.accountInfo?.lastName}
                        email={user?.accountInfo?.email}
                        phoneNumber={user?.accountInfo?.phoneNumber}
                        houseNumber={user?.accountInfo?.houseNumber}
                        addressOne={user?.accountInfo?.addressOne}
                        addressTwo={user?.accountInfo?.addressTwo}
                        postCode={user?.accountInfo?.postCode}
                        city={user?.accountInfo?.city}
                        registrationDate={user?.registrationDate ? new Date(user?.registrationDate) : undefined}
                      />
                    ) : (
                      <UserPersonalInfoForm />
                    )}
                  </FormSection>

                  {/* organization */}
                  <OrgSection editingExistingUser={!!id} />

                  {/* user management */}
                  <FormSection>
                    <UserGeneralHeader title="User Management" editButton={false} />
                    <Text style={[styles.userTextInfo, { marginBottom: 4 }]}>Status</Text>
                    <View style={{ marginBottom: 4 }}>
                      <SelectOption
                        onChange={(status) => props.setFieldValue('accountStatus', status)}
                        value={values.accountStatus}
                        options={[
                          { label: 'Active', value: 'Active' },
                          {
                            label: 'Temporarily Suspend',
                            value: 'Suspend',
                          },
                          { label: 'Delete User', value: 'Delete' },
                        ]}
                      />
                    </View>

                    {values.accountStatus === 'Delete' ? (
                      <View>
                        <Text style={textStyles.subduedTextMedium}>
                          Once saved, the user will be permanently removed from TRN platform. <br /> <br />
                          To keep the user data and history on the platform, you can set a temporarily suspend status for this user instead.
                        </Text>
                      </View>
                    ) : null}
                    {values.accountStatus === 'Suspend' ? (
                      <View>
                        <Text style={textStyles.subduedTextMedium}>
                          Once saved, the user will no longer have an access to any content, events and course on TRN platform during the suspension
                          period. <br /> <br />
                          Select the duration of time you'd like to suspend this user.
                        </Text>
                        <Text style={[styles.userTextInfo, { marginBottom: 4, marginTop: 10 }]}>Suspension Period</Text>
                        <Checkbox
                          label="Without ending period"
                          checked={values.accountStatus === 'Suspend' && !values.withSuspensionEndDate}
                          onChange={(value) => {
                            props.setFieldValue('withSuspensionEndDate', !values.withSuspensionEndDate);
                            if (value) {
                              props.setFieldValue('suspendedTo', null);
                            }
                          }}
                        />
                        <Row
                          style={{
                            justifyContent: 'space-between',
                            gap: 8,
                            marginTop: 4,
                          }}
                        >
                          <View style={{ flex: 1 }}>
                            <FormikDatePickerDropdown
                              dateValue={values.suspendedFrom}
                              placeholderText="From"
                              onChange={(v) => props.setFieldValue('suspendedFrom', v.start.toISOString())}
                            />
                          </View>
                          <View style={{ flex: 1 }}>
                            <FormikDatePickerDropdown
                              dateValue={values.suspendedTo}
                              placeholderText="To"
                              onChange={(v) => props.setFieldValue('suspendedTo', v.start.toISOString())}
                              disabled={!values.withSuspensionEndDate}
                            />
                          </View>
                        </Row>
                      </View>
                    ) : null}
                  </FormSection>

                  {/* profile picture */}
                  <SideCardWithTitle subtitle="This is the image that will be displayed a user’s profile picture." title="Profile Picture">
                    <FormikDropzone
                      fieldName="profilePhotoUrl"
                      actionHint="Accepts .jpg, and .png"
                      validFileTypes={['image/jpeg', 'image/png']}
                      storageBaseUrl="users/profilePhotos"
                      currentValue={values.profilePhotoUrl}
                    />
                  </SideCardWithTitle>
                </View>
              </View>
            </>
          );
        }}
      </Formik>
    </View>
  );
};
