import { ActivityIndicatorTRN } from '@components/general/activity-indicator/ActivityIndicator';
import { ButtonGeneral, ButtonType, ButtonVariant } from '@components/general/button-general';
import { PlayIcon } from '@components/general/icons/org-icons';
import { ContentBox } from '@components/general/layouts/content-box';
import { Wrapper } from '@components/general/layouts/wrapper/Wrapper';
import { RoadmapBlock } from '@components/roadmap/roadmap-block';
import { RoadmapTutorial } from '@components/roadmap/roadmap-tutorial';
import { RoadmapPulseContext } from '@context/RoadmapPulseContext';
import { useUserContext } from '@context/UserContext';
import { useEmployerGetRoadmapQuery, useUpdateRoadmapMutation } from '@gql/generated/generated';
import { useFeatureFlag } from '@hooks/useFeatureFlag';
import { useFirstVisitTutorial } from '@hooks/useFirstVisitTutorial';
import { useGetRoadmapVideos } from '@hooks/useGetRoadmapVideos';
import { FEATURE_FLAGS } from '@utils/featureFlags';
import { useContext, useState } from 'react';
import { ActivityIndicator, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { ids, styles } from './style';
import { getCurrentRoadmapItemsFromData, getQuestionAndAnswerCount } from '../utils';

const RoadmapIntro = () => {
  const navigate = useNavigate();
  const [roadmapVideos] = useGetRoadmapVideos();
  const [tutorialModal, setTutorialModal] = useState<{ videoUri?: string; title: string; subtitle: string } | null>(null);
  const roadmapPulseContext = useContext(RoadmapPulseContext);
  if (!roadmapPulseContext) throw new Error('You have not setup the provider');
  const { latestPulse, selectRoadmapPulse, isLoading: isRoadmapPulseLoading, isSelectedPulseLatest } = roadmapPulseContext;
  const isRoadmapPulseEnabled = useFeatureFlag([FEATURE_FLAGS.ROADMAP_PULSE]);
  const isRoadmapGoalEnabled = useFeatureFlag([FEATURE_FLAGS.ROADMAP_GOAL]);
  const isRoadmapNorthStarEnabled = useFeatureFlag([FEATURE_FLAGS.ROADMAP_NORTH_STAR]);
  const isRoadmapPrioritiesEnabled = useFeatureFlag([FEATURE_FLAGS.ROADMAP_PRIORITIES]);

  const { loadingCurrentUser } = useUserContext();

  const [updateViewedByOrg] = useUpdateRoadmapMutation({
    variables: {
      input: {
        viewedByOrganisation: true,
      },
    },
  });

  const { data, loading } = useEmployerGetRoadmapQuery({
    onCompleted: (roadmap) => {
      if (!roadmap?.employerGetRoadmap.viewedByOrganisation) {
        roadmapTutorialModal.videoUri && setTutorialModal(roadmapTutorialModal);
        updateViewedByOrg();
      }
    },
  });

  const roadmapTutorialModal = {
    videoUri: roadmapVideos?.tutorial,
    title: 'Roadmap',
    subtitle: 'The journey to business success and exploration',
  };

  const [setFirstVisitTutorial] = useFirstVisitTutorial({
    roadmapType: 'roadmap',
    deps: [roadmapVideos?.tutorial],
    cb: () => {
      setTutorialModal(roadmapTutorialModal);
    },
  });

  function onCloseTutorialModal() {
    setTutorialModal(null);
    setFirstVisitTutorial(false);
  }

  if (!data || loading || loadingCurrentUser || !roadmapVideos || isRoadmapPulseLoading) {
    return <ActivityIndicatorTRN isFullscreen />;
  }

  async function onPlay(path: string) {
    if (path === 'pulse' && !isSelectedPulseLatest) {
      await selectRoadmapPulse(latestPulse?.id!);
    }
    navigate(path);
  }

  const getRoadmapBlocks = () => {
    const sections = data?.employerGetRoadmap && getCurrentRoadmapItemsFromData(data?.employerGetRoadmap);
    const northStarSections = sections?.northStarSections ?? [];
    const pulseSections = latestPulse?.sections ?? [];
    const goalSections = sections?.goalSections ?? [];

    const northStarSectionsData = data && getQuestionAndAnswerCount(northStarSections);
    const goalSectionsData = data && getQuestionAndAnswerCount(goalSections);
    const pulseSectionsData = data && getQuestionAndAnswerCount(pulseSections);

    const response = [
      {
        title: 'North Star',
        subtitle: 'The 3 Year Plan',
        sections: northStarSections,
        sectionsData: northStarSectionsData,
        minuteTotal: 30,
        path: 'north-star',
        videoUri: roadmapVideos.northStar,
        isDisabled: !isRoadmapNorthStarEnabled,
      },
      {
        title: 'Goal',
        subtitle: 'The Annual Plan',
        sections: goalSections,
        sectionsData: goalSectionsData,
        minuteTotal: 15,
        path: 'goal',
        videoUri: roadmapVideos.goal,
        isDisabled: !isRoadmapGoalEnabled,
      },
      {
        title: 'Pulse',
        subtitle: 'Your business health check',
        sections: pulseSections,
        sectionsData: pulseSectionsData,
        minuteTotal: 10,
        path: 'pulse',
        videoUri: roadmapVideos.pulse,
        isDisabled: !isRoadmapPulseEnabled,
      },
    ];

    return response;
  };

  return (
    <Wrapper style={styles.wrapper} dataSetMedia={ids.wrapper}>
      <ContentBox style={styles.contentBox} dataSetMedia={ids.contentBox}>
        <View style={styles.titleWrap}>
          <Text style={styles.title}>Your Roadmap to Success</Text>
          <Text style={styles.description}>
            Shape your strategy, identify your priorities, create real focus, access the support you need and monitor progress. The TRN Roadmap
            provides a framework to build the traction you need to achieve your goals.
          </Text>
        </View>
        {isRoadmapPrioritiesEnabled && (
          <TouchableOpacity style={styles.priorities} onPress={() => navigate('/roadmap/priorities')}>
            <View>
              <Text style={styles.prioritiesTitle}>Priorities</Text>
              <Text style={styles.prioritiesSubtitle}>Your 90 day sprint</Text>
            </View>
            <View style={styles.prioritiesIcon}>
              <PlayIcon color="white" />
            </View>
          </TouchableOpacity>
        )}
        <View
          style={[styles.blockWrap, { justifyContent: getRoadmapBlocks().length > 1 ? 'space-evenly' : 'center' }]}
          dataSet={{ media: ids.blockWrap }}
        >
          {data && roadmapVideos && (
            <>
              {getRoadmapBlocks().map(({ title, isDisabled, subtitle, sections, sectionsData, minuteTotal, path, videoUri }) => (
                <RoadmapBlock
                  key={videoUri}
                  title={title}
                  subtitle={subtitle}
                  isDisabled={isDisabled}
                  sectionsTotal={sections.length}
                  questionsTotal={sectionsData?.questionCount ?? 0}
                  minuteTotal={minuteTotal}
                  totalQuestions={sectionsData?.questionCount ?? 0}
                  totalAnsweredQuestions={sectionsData?.answerCount ?? 0}
                  onPlay={() => onPlay(path)}
                  onPressInfo={() => videoUri && setTutorialModal({ videoUri, title, subtitle })}
                />
              ))}
            </>
          )}
        </View>
        {roadmapVideos.tutorial && (
          <ButtonGeneral
            label="Watch tutorial video"
            type={ButtonType.user}
            variant={ButtonVariant.Secondary}
            onPress={() => roadmapTutorialModal.videoUri && setTutorialModal(roadmapTutorialModal)}
          />
        )}
      </ContentBox>
      {tutorialModal && tutorialModal.videoUri && (
        <RoadmapTutorial
          isVisible={!!tutorialModal}
          onClose={onCloseTutorialModal}
          title={`Welcome to ${tutorialModal.title}`}
          subtitle={tutorialModal.subtitle}
          videoUri={tutorialModal.videoUri}
        />
      )}
    </Wrapper>
  );
};

export { RoadmapIntro };
