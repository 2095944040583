import { useFeatureFlag } from '@hooks/useFeatureFlag';
import { FEATURE_FLAGS } from '@utils/featureFlags';
import React, { useEffect, useRef } from 'react';
import { Animated, StyleSheet, useWindowDimensions, View } from 'react-native';

const Snowflake = ({ style, delay, duration, height }: { style: any; delay: number; duration: number; height: number }) => {
  const translateY = useRef(new Animated.Value(-100)).current;

  useEffect(() => {
    Animated.loop(
      Animated.timing(translateY, {
        toValue: height + 100, // height of screen + extra for smooth exit
        duration,
        delay,
        useNativeDriver: false,
      })
    ).start();
  }, [translateY, delay, duration]);

  return <Animated.Text style={[styles.snow, style, { transform: [{ translateY }] }]}>❄️</Animated.Text>;
};

const Snowfall = () => {
  const isLetItSnowEnabled = useFeatureFlag([FEATURE_FLAGS.LET_IT_SNOW]);

  const { width, height } = useWindowDimensions();
  if (!isLetItSnowEnabled) {
    return <></>;
  }

  const snowflakes = Array.from({ length: 50 });

  return (
    <View style={[styles.container, { width, height }]}>
      {snowflakes.map((_, index) => (
        <Snowflake
          key={index}
          height={height}
          delay={Math.random() * 10000} // Random delay for each flake
          duration={8000 + Math.random() * 7000} // Vary animation duration
          style={{
            fontSize: Math.random() * 20 + 20, // Vary font size
            left: `${Math.random() * 100}%`, // Random horizontal positioning
            filter: `blur(${Math.random() * 2}px)`,
          }}
        />
      ))}
    </View>
  );
};

export default Snowfall;

const styles = StyleSheet.create({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    overflow: 'hidden',
    zIndex: 0,
  },
  snow: {
    position: 'absolute',
    color: 'rgb(31, 33, 36)',
    transform: [{ translateY: 0 }],
  },
});
