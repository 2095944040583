import { AnalyticsCountBoxes } from '@components/back-office/analytics/analytics-count-boxes';
import { AnalyticsEventTable } from '@components/back-office/analytics/analytics-event-table';
import { AnalyticsHeader } from '@components/back-office/analytics/analytics-header';
import { AnalyticsRequest } from '@components/back-office/analytics/analytics-request';
import { ContentBox } from '@components/general/layouts/content-box';
import React from 'react';
import { View } from 'react-native';

import { styles, ids } from './style';
import { useEventAnalytics } from '../hooks/use-event-analytics';

const AnalyticsEvent = () => {
  const { boxItems, analyticsData, refetch, refetchContentRows, eventAnalytics, dataForExport } = useEventAnalytics();

  return (
    <>
      <AnalyticsHeader excelData={dataForExport} path="events" />
      <ContentBox>
        <View style={styles.countBoxesWrap}>
          <AnalyticsCountBoxes items={boxItems} />
        </View>
        <View style={styles.split} dataSet={{ media: ids.split }}>
          <View style={styles.leftItem}>
            <AnalyticsRequest
              title="Event Request"
              requests={analyticsData?.adminGetMemberStats.events?.requests}
              refetch={(val: number) =>
                refetch({
                  numDays: val,
                })
              }
            />
          </View>
        </View>
        <View style={styles.contentBox}>
          <AnalyticsEventTable data={eventAnalytics} refetch={(val: number) => refetchContentRows({ numDays: val })} />
        </View>
      </ContentBox>
    </>
  );
};

export { AnalyticsEvent };
