import { CheckIcon } from '@components/general/icons/training-icons';
import { Training } from '@gql/generated/generated';
import React from 'react';
import { Text, View } from 'react-native';
import RenderHtml from 'react-native-render-html';

import { ids, styles } from './style';

interface TopicBoxProps {
  title: string;
  children?: React.ReactNode;
}

const TopicBox = ({ title, children }: TopicBoxProps) => (
  <View style={styles.topicsBox}>
    <Text style={styles.topicBoxTitle}>{title}</Text>
    {children}
  </View>
);

interface TopicListItemProps {
  title: string;
  disableBottomMargin?: boolean;
  isDot?: boolean;
}

const TopicListItem = ({ title, disableBottomMargin, isDot }: TopicListItemProps) => (
  <View style={[styles.topicItem, disableBottomMargin ? null : styles.topicItemTextMargin]}>
    {isDot ? <View style={styles.dot} /> : <CheckIcon style={styles.checkIcon} />}
    <Text style={styles.topicItemText}>{title}</Text>
  </View>
);

const tagsStyles = {
  p: {
    margin: 0,
  },
  ul: {
    marginTop: 0,
    marginBottom: 8,
  },
};

const Overview = ({
  learningObjectives,
  overview,
  requirements,
  whoIsCourseFor,
}: {
  learningObjectives?: string[] | null;
  overview?: string | null;
  requirements?: string | null;
  whoIsCourseFor?: string | null;
}) => (
  <View style={styles.wrap}>
    <TopicBox title="What you will learn">
      <View style={styles.topicItemsWrap}>
        <View style={styles.eachItem}>
          {learningObjectives
            ? learningObjectives
                .slice(0, learningObjectives.length / 2)
                .map((objective, index) => (
                  <TopicListItem key={index} title={objective} disableBottomMargin={index + 1 === (learningObjectives?.length || 0) / 2} />
                ))
            : null}
        </View>
        <View style={styles.eachItem}>
          {learningObjectives
            ? learningObjectives
                .slice(learningObjectives.length / 2, learningObjectives.length)
                .map((objective, index) => (
                  <TopicListItem key={index} title={objective} disableBottomMargin={index + 1 === learningObjectives?.length} />
                ))
            : null}
        </View>
      </View>
    </TopicBox>
    <Text style={styles.detailText}>{overview && <RenderHtml defaultTextProps={{ selectable: true }} source={{ html: overview }} />}</Text>
    <View style={styles.splitTopicWrap} dataSet={{ media: ids.splitTopicWrap }}>
      <View style={styles.eachTopic} dataSet={{ media: ids.eachTopic }}>
        <TopicBox title="Requirements">
          <Text style={[styles.topicItemText, { marginLeft: 0 }]}>
            {requirements ? <RenderHtml source={{ html: requirements }} defaultTextProps={{ selectable: true }} tagsStyles={tagsStyles} /> : null}
          </Text>
        </TopicBox>
      </View>
      <View style={styles.eachTopic} dataSet={{ media: ids.eachTopic }}>
        <TopicBox title="Who is this course for">
          {whoIsCourseFor ? <RenderHtml source={{ html: whoIsCourseFor }} defaultTextProps={{ selectable: true }} tagsStyles={tagsStyles} /> : null}
        </TopicBox>
      </View>
    </View>
  </View>
);

export { Overview };
