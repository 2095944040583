import { ActivityIndicator, StyleProp, View, ViewStyle } from 'react-native';

export function ActivityIndicatorTRN({
  style = {},
  viewStyle = {},
  isFullscreen = false,
  size = 'large',
  color = '#213470',
}: {
  isFullscreen?: boolean;
  size?: 'small' | 'large';
  color?: string;
  style?: StyleProp<ViewStyle>;
  viewStyle?: StyleProp<ViewStyle>;
}) {
  const fullscreenStyles: ViewStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    position: 'absolute',
  };

  return (
    <View style={isFullscreen ? { ...fullscreenStyles, ...(viewStyle as ViewStyle) } : viewStyle}>
      <ActivityIndicator style={[style]} size={size} color={color} />
    </View>
  );
}
