import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

interface Props extends SvgProps {
  color?: string;
}

const HomeIcon = (props: Props) => (
  <Svg width={19} height={19} fill="none" {...props}>
    <Path
      d="M10.03.377a1 1 0 0 0-1.06 0L1.91 4.789A3 3 0 0 0 .5 7.333v7.892a3 3 0 0 0 3 3h2a1 1 0 0 0 1-1v-5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v5a1 1 0 0 0 1 1h2a3 3 0 0 0 3-3V7.333a3 3 0 0 0-1.41-2.544L10.03.377Z"
      fill={props.color || '#5C5F62'}
    />
  </Svg>
);

export { HomeIcon };
