import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
    padding: 12,
    borderRadius: 4,
    backgroundColor: '#F6F6F7',
  },
  userWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  nameWrap: {
    marginLeft: 8,
  },
  nameText: {
    fontSize: 14,
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
    marginBottom: 4,
  },
  professionText: {
    color: '#A3AAB0',
    fontSize: 12,
    fontFamily: 'OpenSans_600SemiBold',
  },
  comment: {
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
    color: '#0D1738',
    width: '100%',
  },
  actionWrap: {
    marginTop: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionTouch: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionText: {
    fontSize: 12,
    fontFamily: 'OpenSans_600SemiBold',
    color: '#6D7175',
    marginRight: 8,
    paddingVertical: 2,
    paddingHorizontal: 4,
    borderRadius: 4,
  },
  actionCount: {
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
    color: '#6D7175',
    marginLeft: 8,
  },
  rightBorder: {
    borderRightWidth: 1,
    borderRightColor: '#6D7175',
    paddingRight: 8,
    marginRight: 12,
  },
  ratingWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  ratingBox: {
    width: 100,
  },
  ratingDate: {
    marginLeft: 10,
    fontSize: 12,
    color: '#6D7175',
    fontFamily: 'OpenSans_400Regular',
  },
});
