import { TableDropdown } from '@components/back-office/tables/table-dropdown';

import { useQueryFilters } from '../useGridFilters';

const pollStatusItems = [
  { id: 'All', content: 'All' },
  { id: 'Open', content: 'Open' },
  { id: 'Closed', content: 'Closed' },
];

export const PollStatusFilter = () => {
  const { filters, setFilterValue } = useQueryFilters(['pollStatus']);

  return (
    <TableDropdown
      placeholder={filters.pollStatus || 'Poll Status'}
      items={pollStatusItems}
      onSelectItem={(id) => setFilterValue('pollStatus', id)}
      onReset={filters.pollStatus ? () => setFilterValue('pollStatus', null) : undefined}
      isSelected={!!filters.pollStatus}
    />
  );
};
