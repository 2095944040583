import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  title: {
    color: '#0D1738',
    fontSize: 16,
    fontFamily: 'OpenSans_600SemiBold',
  },
  subtitle: {
    color: '#6D7175',
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
    marginTop: 16,
  },
  searchHead: {
    width: '100%',
    padding: 20,
    borderBottomColor: '#EDEEEF',
    borderBottomWidth: 1,
  },
  searchBottom: {
    width: '100%',
    padding: 20,
  },
  webTitleWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  clearHistoryText: {
    color: '#2C6ECB',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
  },
  suggestionTouch: {
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  suggestionText: {
    fontSize: 14,
    marginLeft: 10,
  },
  searchSubtitle: {
    marginTop: 0,
  },
  suggestionSubtitle: {
    marginBottom: 12,
    marginTop: 0,
  },
  seeAllWrap: {
    marginTop: 20,
  },
});
