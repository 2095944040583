import { AvatarWithName } from '@components/back-office/editor/avatar-with-name';
import { GenericTable, GenericTableRowItem } from '@components/back-office/tables/generic-table';
import { TableHeader } from '@components/back-office/tables/table-header';
import { ButtonGeneral } from '@components/general/button-general';
import { ContentBox } from '@components/general/layouts/content-box';
import {
  ClubMembershipRequestStatus,
  SubscriptionPlanType,
  useAdminGetAllOrganisationsQuery,
  useSubscribeGoCardlessCustomerMutation,
  useUpdateClubMembershipRequestStatusMutation,
} from '@gql/generated/generated';
import { useIndexResourceState } from '@shopify/polaris';
import { IndexTableHeading } from '@shopify/polaris/build/ts/latest/src/components/IndexTable';
import { NonEmptyArray } from '@shopify/polaris/build/ts/latest/src/types';
import { styles as sharedStyles } from '@styles/BackOffice/shared';
import { Text, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { styles } from './styles';

export const MembershipRequest = () => {
  const navigate = useNavigate();

  const { data: organisationsData } = useAdminGetAllOrganisationsQuery({
    variables: {
      filters: {
        clubMembershipRequestStatus: [ClubMembershipRequestStatus.PENDING],
      },
    },
  });

  const [updateClubMembershipRequestStatus] = useUpdateClubMembershipRequestStatusMutation();
  const [subscribeGoCardlessCustomer] = useSubscribeGoCardlessCustomerMutation();

  const tableHeadings: NonEmptyArray<IndexTableHeading> = [
    { title: 'Organisation Name' },
    { title: 'Current Membership' },
    { title: 'Requested Membership' },
    { title: 'Request Status' },
  ];

  const rows =
    organisationsData?.adminGetAllOrganisations?.organisations?.map(({ id, organisationInfo, subscription }, i: number) => {
      return {
        id: id ?? '',
        title: <AvatarWithName name={organisationInfo?.companyName} i={i} />,
        currentMembership: subscription?.subscriptionPlanType ?? SubscriptionPlanType.FREE,
        membershipRequest: SubscriptionPlanType.CLUB,
        requestStatus: subscription?.clubMembershipRequestStatus,
      };
    }) ?? [];

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(rows);

  const handleMenuClick = async (id: string, optionId: string) => {
    const selectedOrg = organisationsData?.adminGetAllOrganisations?.organisations?.find((el) => el.id === id);

    switch (optionId) {
      case 'View User': {
        navigate(`/user-editor/${typeof selectedOrg?.manager === 'string' ? selectedOrg?.manager : ''}`);
        break;
      }
      case 'Accept': {
        await updateClubMembershipRequestStatus({
          variables: {
            orgId: id,
            clubMembershipRequestStatus: ClubMembershipRequestStatus.APPROVED,
          },
        });
        break;
      }
      case 'Deny': {
        await updateClubMembershipRequestStatus({
          variables: {
            orgId: id,
            clubMembershipRequestStatus: ClubMembershipRequestStatus.REJECTED,
          },
        });
        break;
      }
      default:
        break;
    }
  };

  return (
    <View style={sharedStyles.wrap}>
      {/* header */}
      <View style={sharedStyles.titleWrap}>
        <Text style={styles.memberRequestHeaderTitle}>Membership Requests</Text>
        <ButtonGeneral label="Back to Users" bold arrowLeft onPress={() => navigate(-1)} />
      </View>

      {/* content */}
      <ContentBox style={{ marginTop: 20 }}>
        <View style={sharedStyles.inner}>
          <TableHeader page={0} setPage={() => {}} hasNextPage={false} />
          {/* table */}
          <View style={sharedStyles.table}>
            <ContentBox>
              {rows && rows.length > 0 ? (
                <GenericTable
                  headings={tableHeadings}
                  rows={rows}
                  resourceName={{ singular: 'user', plural: 'users' }}
                  selectedResources={selectedResources}
                  allResourcesSelected={allResourcesSelected}
                  handleSelectionChange={handleSelectionChange}
                  handleMenuClick={handleMenuClick}
                  customMenuItems={[
                    { id: 'View User', content: 'View User' },
                    { id: 'Accept', content: 'Accept' },
                    { id: 'Deny', content: 'Deny' },
                  ]}
                />
              ) : null}
            </ContentBox>
          </View>
        </View>
      </ContentBox>
    </View>
  );
};
