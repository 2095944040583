import { useEffect, useState } from 'react';

import { searchAlgolia } from '../services/algolia';

export function useSearchAlgolia({
  query,
  hitsPerPage,
  isPublished,
  indexName,
  excludeIds,
}: {
  query: string;
  hitsPerPage: number;
  isPublished: boolean;
  indexName: string;
  excludeIds: string[];
}) {
  const [results, setResults] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (query) {
      (async () => {
        try {
          setIsLoading(true);
          console.log(query, excludeIds);

          const hits = await searchAlgolia({ isPublished, indexName, query, hitsPerPage, excludeIds });
          setResults(hits as any[]);
        } catch (err) {
          console.error('ERROR - useSearchAlgolia - useEffect():', err);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [query, excludeIds.length]);

  return [results, isLoading] as [any[], boolean];
}
