export const percentageChange = (prev?: number | null, curr?: number) => {
  if (!prev || prev === 0) {
    if (curr && curr > 0) return 100;
    return 0;
  }
  const percent = (((curr || 0) - prev) / prev) * 100;
  return Math.round(percent);
};

export const percentageChangePerDay = (data: number[]) =>
  data.map((day, i) => {
    if (i === 0) return 0;
    return percentageChange(data[i - 1], day);
  });

export const calculateTodaysStats = (today: number, yesterday: number) => {
  return {
    count: today,
    percentageChange: percentageChange(yesterday, today),
  };
};
