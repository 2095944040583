import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  memberCardWrapper: {
    flexDirection: 'row',
  },
  infoContainer: {
    width: 40,
    flexDirection: 'row',
  },
  avatarContainer: {
    width: 40,
    height: 40,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 50,
  },
  status: {
    position: 'absolute',
    width: 12,
    height: 12,
    bottom: 0,
    right: 0,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: '#fff',
  },
  displayNone: {
    display: 'none',
  },
  online: {
    backgroundColor: '#AEE9D1',
  },
  away: {
    backgroundColor: '#FED3D1',
  },
  infoTextContainer: {
    flexDirection: 'column',
    marginLeft: 16,
  },
  nameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 140,
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
    lineHeight: 20,
    alignSelf: 'center',
    color: '#0D1738',
  },
  tagContainer: {
    backgroundColor: '#D3E5FE',
    borderRadius: 15,
    marginLeft: 8,
  },
  tagTitle: {
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
    color: '#0D1738',
    lineHeight: 16,
    paddingVertical: 2,
    paddingHorizontal: 8,
  },
  jobTitle: {
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    lineHeight: 20,
    color: '#202223',
  },
  editButton: {
    color: '#2C6ECB',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
  },
  editModeWrap: {
    borderBottomColor: '#EDEEEF',
    borderBottomWidth: 1,
  },
  editLabel: {
    fontFamily: 'OpenSans_500Medium',
    fontSize: 14,
    color: '#0D1738',
  },
  editInput: {
    flex: 1,
    marginLeft: 40,
  },
  editContactDetailWrap: {
    borderTopWidth: 0,
    paddingTop: 7,
    marginBottom: 0,
  },
  editRoleWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  roleFormWrap: {
    borderTopWidth: 0,
    paddingTop: 7,
    marginTop: 0,
    paddingRight: 0,
    flex: 1,
  },
  datesWrap: {
    flexDirection: 'row',
    flex: 1,
    paddingBottom: 24,
    paddingLeft: 40,
  },
  datesText: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    color: '#6D7175',
  },
});
