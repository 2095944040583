import { mediaQuery } from '@utils/misc';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
  },
  titleText: {
    fontSize: 20,
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
  },
  itemTitleText: {
    fontSize: 14,
    color: '#000000',
    marginBottom: 4,
    fontFamily: 'OpenSans_600SemiBold',
  },
  dateText: {
    fontSize: 12,
    color: '#2C6ECB',
    fontFamily: 'OpenSans_600SemiBold',
    marginBottom: 4,
  },
  topSection: {
    paddingHorizontal: 20,
    paddingBottom: 24,
    [mediaQuery.forMobile]: {
      paddingHorizontal: 0,
    },
  },
  titleWrap: {
    padding: 20,
    [mediaQuery.forTabletDown]: {
      padding: 0,
      paddingTop: 16,
      paddingBottom: 20,
    },
  },
  topWrap: {
    paddingBottom: 20,
    [mediaQuery.forTabletDown]: {
      paddingBottom: 0,
    },
  },
  emptyTopWrap: {
    paddingBottom: 0,
  },
  bottomSection: {
    borderTopWidth: 1,
    borderTopColor: '#EDEEEF',
    padding: 20,
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 6,
    paddingBottom: 6,
    [mediaQuery.forTabletDown]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  itemImage: {
    height: 56,
    width: 56,
    borderRadius: 4,
  },
  itemInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 9,
    maxWidth: '70%',
    flex: 1,
  },
  linkButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonText: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    color: '#202223',
    marginRight: 4,
  },
  arrowImage: {
    height: 10,
    width: 12,
    marginLeft: 6,
  },
  buttonWrap: {
    padding: 20,
  },
});
