import { ActionList } from '@shopify/polaris';
import { View } from 'react-native';

import { styles } from './style';

interface Props {
  onReset?: () => void;
}

const ResetButton = ({ onReset }: Props) => {
  if (onReset) {
    return (
      <View style={styles.resetButton}>
        <ActionList
          actionRole="menuitem"
          items={[
            {
              content: 'Reset',
              onAction: () => {
                onReset();
              },
              destructive: true,
            },
          ]}
        />
      </View>
    );
  }

  return <></>;
};

export default ResetButton;
