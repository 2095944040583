import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrapper: {
    marginBottom: 18,
  },
  wrap: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  left: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 26,
    color: '#0D1738',
    marginRight: 24,
  },
  exportText: {
    marginLeft: 6,
    color: '#FFFFFF',
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
  },
});
