import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  title: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 16,
    color: '#0D1738',
    marginBottom: 10,
  },
  subtitle: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    color: '#0D1738',
    marginBottom: 18,
  },
  inputWrap: {
    marginBottom: 20,
  },
});
