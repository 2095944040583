import { ActivityIndicatorTRN } from '@components/general/activity-indicator/ActivityIndicator';
import { ContentBox } from '@components/general/layouts/content-box';
import { Wrapper } from '@components/general/layouts/wrapper/Wrapper';
import { TabTitle } from '@components/general/tab-title';
import { PulseContentItem } from '@components/roadmap/pulse-content-item';
import { RoadmapPulseContext } from '@context/RoadmapPulseContext';
import { DocumentAsset } from '@gql/generated/generated';
import { useGetObjectsAlgolia } from '@hooks/useGetObjectsAlgolia';
import { ContentAlgolia } from '@pages/back-office/roadmap-back-office/score-associations-edit/ScoreAssociationsEdit';
import { Banner, Select } from '@shopify/polaris';
import { truncate } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { Text, TouchableOpacity, View, ViewBase } from 'react-native';
import { useNavigate, useParams } from 'react-router-native';

import { styles } from './style';
import { getQuestionScore, getSubCategoryRecommendation } from '../pulse-result-plan/utils';

export function PulseRecommendations() {
  const [activeTab, setActiveTab] = useState<'content' | 'training'>('content');
  const roadmapPulseContext = useContext(RoadmapPulseContext);
  if (!roadmapPulseContext) throw new Error('roadmap pulse context has not been provided');
  const { scoreAssns, isLoading: isRoadmapPulseLoading, selectedPulse, isSelectedPulseLatest } = roadmapPulseContext;
  const { roadmapPulseId, sectionId, subCategoryId } = useParams();
  const navigate = useNavigate();

  const subCategories = useMemo(() => {
    if (sectionId && !isRoadmapPulseLoading && scoreAssns.length && subCategoryId && isSelectedPulseLatest) {
      const category = scoreAssns.find((s) => s.name === sectionId);
      return category ? category.subCategories : [];
    }
    return [];
  }, [sectionId, isRoadmapPulseLoading, scoreAssns, subCategoryId, isSelectedPulseLatest]);

  const recommendations = useMemo(() => {
    const defaultReturn = { content: [], trainings: [] };
    if (subCategories.length && selectedPulse && sectionId && subCategoryId && isSelectedPulseLatest) {
      const subCategory = subCategories.find((s) => s.name === subCategoryId);
      if (!subCategory) return defaultReturn;
      // Get the section and question from the category
      const section = selectedPulse.sections.find((s) => s.name === sectionId);
      if (!section) return defaultReturn;
      const question = section.questions.find((q) => q.subcategory === subCategoryId);
      if (!question) return defaultReturn;
      const score = getQuestionScore(question);
      if (!score) return defaultReturn;
      const recommendations = getSubCategoryRecommendation(subCategory, score);
      return recommendations;
    }
    return defaultReturn;
  }, [subCategories, selectedPulse, sectionId, subCategoryId, isSelectedPulseLatest]);

  const [contentRecommendations, isContentRecommendationsLoading] = useGetObjectsAlgolia(
    recommendations.content.map((id) => ({
      id,
      indexName: 'content',
    }))
  );

  const [trainingRecommendations, isTrainingRecommendationsLoading] = useGetObjectsAlgolia(
    recommendations.trainings.map((id) => ({
      id,
      indexName: 'trainings',
    }))
  );

  const isLoading = useMemo(
    () => isTrainingRecommendationsLoading || isContentRecommendationsLoading || isRoadmapPulseLoading,
    [isTrainingRecommendationsLoading, isContentRecommendationsLoading, isRoadmapPulseLoading]
  );

  const subCategoryOptions = useMemo(() => {
    if (subCategories.length) {
      return subCategories.map((s) => {
        return {
          label: s.name!,
          value: s.name!,
        };
      });
    }
    return [];
  }, [subCategories]);

  function goToSection() {
    navigate(`/roadmap/pulse/${roadmapPulseId}/result/${sectionId}`);
  }

  function goToSubCategory(subCategoryId: string) {
    navigate(`/roadmap/pulse/${roadmapPulseId}/result/${sectionId}/${subCategoryId}`);
  }

  if (isLoading) {
    return <ActivityIndicatorTRN isFullscreen />;
  }

  return (
    <Wrapper style={styles.wrapper}>
      <ContentBox style={styles.contentBox}>
        <TouchableOpacity onPress={goToSection}>
          <Text style={styles.backText}>{`< Back`}</Text>
        </TouchableOpacity>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 16,
          }}
        >
          <View style={{ width: 'fit-content' }}>
            <Select label="" onChange={goToSubCategory} value={subCategoryId} options={subCategoryOptions} />
          </View>
          <View style={styles.infoWrap}>
            <Text style={styles.infoPercentage}>0%</Text>
            <Text style={styles.infoComplete}>Complete</Text>
          </View>
        </View>
        {!isSelectedPulseLatest && (
          <View style={{ marginTop: 16 }}>
            <Banner>
              <Text>You can only view recommended content from the latest pulse and only see previously completed content.</Text>
            </Banner>
          </View>
        )}
        <View style={styles.tabHead}>
          <View style={styles.tabInner}>
            <TabTitle title="Content" active={activeTab === 'content'} onPress={() => setActiveTab('content')} />
            <TabTitle title="Training" active={activeTab === 'training'} onPress={() => setActiveTab('training')} />
          </View>
        </View>
        {activeTab === 'content' &&
          (contentRecommendations as unknown as ContentAlgolia[]).map((r, i) => (
            <PulseContentItem
              id={r.id}
              key={`${activeTab}-${r.id}-${i}`}
              title={r.title}
              description={truncate(r.description, { length: 60 })}
              type="content"
              subtitle=""
              isComplete={false}
              image={r.coverImage as DocumentAsset}
            />
          ))}
        {activeTab === 'training' &&
          (trainingRecommendations as unknown as ContentAlgolia[]).map((r, i) => (
            <PulseContentItem
              id={r.id}
              key={`${activeTab}-${r.id}-${i}`}
              title={r.title}
              description={truncate(r.description, { length: 60 })}
              type="trainings"
              subtitle=""
              isComplete={false}
              image={r.coverImage as DocumentAsset}
            />
          ))}
      </ContentBox>
    </Wrapper>
  );
}
