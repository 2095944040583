import { Platform } from 'react-native';

import { Marketplace as MarketplaceWeb } from './Marketplace';
import { MarketplaceNative } from './Marketplace.native';

const Marketplace = Platform.select({
  native: () => MarketplaceNative,
  default: () => MarketplaceWeb,
})();

export { Marketplace };
