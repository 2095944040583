import { SortBy, TrainingSortBy, UserType } from '@gql/generated/generated';
import { useReducer } from 'react';

export enum FilterActionType {
  SET_CATEGORY = 'Category',
  SET_JOB_ROLE = 'Job Role',
  SET_LENGTH = 'Length',
  SET_MEMBER_TYPE = 'Member Type',
  SET_POPULARITY = 'Popularity',
  SET_CONTENT_TYPE = 'Content Type',
  SET_DATE = 'Date',
  RESET = 'RESET',
  SET_EVENT_TYPE = 'Event Type',
  SET_DURATION = 'Duration',
  SET_SORT_BY = 'Sort By',
}

export interface FilterAction {
  type: FilterActionType;
  payload: any;
}

export interface FiltersState {
  category: string[];
  popularity: number[];
  memberType: UserType[];
  jobRole: string[];
  length: string[];
  contentType: string[];
  date: { end: number | null; start: number | null };
  eventType: string[];
  sortBy: SortBy | TrainingSortBy | null;
}

const initialFilters = {
  category: [],
  popularity: [],
  memberType: [],
  jobRole: [],
  length: [],
  contentType: [],
  date: { end: null, start: null },
  eventType: [],
  duration: [],
  sortBy: null,
};

const reducer = (state: FiltersState, action: FilterAction) => {
  const { type, payload } = action;

  switch (type) {
    case FilterActionType.SET_CATEGORY:
      return {
        ...state,
        category: payload,
      };
    case FilterActionType.SET_MEMBER_TYPE:
      return {
        ...state,
        memberType: payload,
      };
    case FilterActionType.SET_JOB_ROLE:
      return {
        ...state,
        jobRole: payload,
      };
    case FilterActionType.SET_POPULARITY:
      if (payload.length && state.popularity.includes(payload[0])) {
        return {
          ...state,
          popularity: [],
        };
      }
      return {
        ...state,
        popularity: payload.map((num: string) => Number(num)),
      };
    case FilterActionType.SET_LENGTH:
      return {
        ...state,
        length: payload,
      };
    case FilterActionType.SET_CONTENT_TYPE:
      return {
        ...state,
        contentType: payload,
      };
    case FilterActionType.SET_DATE:
      return {
        ...state,
        date: payload,
      };
    case FilterActionType.SET_EVENT_TYPE:
      return {
        ...state,
        eventType: payload,
      };
    case FilterActionType.SET_DURATION:
      return {
        ...state,
        duration: payload,
      };
    case FilterActionType.SET_SORT_BY:
      return {
        ...state,
        sortBy: payload[0],
      };
    case FilterActionType.RESET:
      return initialFilters;
    default:
      return state;
  }
};

const useFilters = (initialSortBy: SortBy | TrainingSortBy): [FiltersState, React.Dispatch<FilterAction>] => {
  const [filters, dispatch] = useReducer(reducer, {
    ...initialFilters,
    sortBy: initialSortBy,
  });

  return [filters, dispatch];
};

export { useFilters };
