import React from 'react';
import { Text, TextInput, TextInputProps, View } from 'react-native';

import { styles } from './style';

interface FormInputProps extends TextInputProps {
  label: string;
}

const FormInput = ({ label, ...rest }: FormInputProps) => {
  return (
    <View style={{ margin: 4 }}>
      <Text style={{ marginBottom: 4 }}>{label}</Text>
      <TextInput style={styles.textInput} {...rest} />
    </View>
  );
};

export { FormInput };
