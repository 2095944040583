import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes, uploadString } from 'firebase/storage';

const uploadFile = async (file: File, path: string) => {
  // Get a reference to the storage service, which is used to create references in your storage bucket
  const storage = getStorage();

  // Create a storage reference from our storage service
  const storageRef = ref(storage, path);

  // 'file' comes from the Blob or File API
  const snapshot = await uploadBytes(storageRef, file);

  const downloadUrl = await getDownloadURL(snapshot.ref);

  return downloadUrl;
};

const uploadBase64ToFirebase = async (base64String: string, path: string) => {
  const storage = getStorage();

  const storageRef = ref(storage, path);

  const snapshot = await uploadString(storageRef, base64String, 'base64', {
    contentType: 'image/png',
  });

  const downloadUrl = await getDownloadURL(snapshot.ref);

  return downloadUrl;
};

const deleteFileFromStorage = async (path: string) => {
  const storage = getStorage();

  const fileRef = ref(storage, path);

  return await deleteObject(fileRef);
};

export { uploadFile, uploadBase64ToFirebase, deleteFileFromStorage };
