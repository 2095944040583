import { AnalyticsCountBox, Props as AnalyticsCountBoxProps } from '@components/org/analytics/analytics-count-box';
import React from 'react';
import { View } from 'react-native';

import { ids, styles } from './style';

interface Props {
  items?: AnalyticsCountBoxProps[];
}

const AnalyticsCountBoxes = ({ items }: Props) => (
  <View style={styles.wrap} dataSet={{ media: ids.wrap }}>
    {items?.map((item, index) => (
      <AnalyticsCountBox
        key={`${item.title}${index}`}
        title={item.title}
        count={item.count}
        countInfo={item.countInfo}
        percent={item.percent}
        percentInfo={item.percentInfo}
      />
    ))}
  </View>
);

export { AnalyticsCountBoxes };
