import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const DocumentIcon = () => (
  <Svg width="16" height="20" viewBox="0 0 16 20" fill="none">
    <Path
      d="M10.6492 0C10.8763 0 11.0966 0.0772808 11.2739 0.219131L15.6247 3.69976C15.8619 3.88953 16 4.17684 16 4.48062V19C16 19.5523 15.5523 20 15 20H1C0.447715 20 0 19.5523 0 19V1C0 0.447715 0.447715 0 1 0H10.6492ZM2 2V18H14V7H10.5C9.7203 7 9.07955 6.40511 9.00687 5.64446L9 5.5V2H2ZM14 4.961L11 2.561V5H14V4.961Z"
      fill="#4A4A4A"
    />
  </Svg>
);

export { DocumentIcon };
