import { ButtonGeneral } from '@components/general/button-general';
import { ChevronUpIcon } from '@components/general/icons';
import { SearchIcon } from '@components/general/icons/SearchIcon';
import {
  RecommendedContentOrTraining,
  useDeleteRecommendationsMutationMutation,
  useUpdateRecommendationsMutationMutation,
} from '@gql/generated/generated';
import { Params } from '@pages/back-office/roadmap-back-office/score-ranking/utils';
import { Popover, TextField } from '@shopify/polaris';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { ImageSourcePropType, Text, TouchableOpacity, View } from 'react-native';
import { useParams } from 'react-router-native';

import { ContentCard } from './ContentCard';
import { ResultSearchCard } from './ResultSearchCard';
import { styles } from './style';
import { useAlgoliaSearch } from '../../../../pages/search/useAlgoliaSearch';
import { getUniqueCategories } from '../../../../utils/misc';

interface Props {
  header: string;
  count: number;
  data: RecommendedContentOrTraining[];
  indexName: string;
}

export const ContentContainer = ({ header, count, data, indexName }: Props) => {
  const [expand, setExpand] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [searchBoxValue, setSearchBoxValue] = useState('');

  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [updateRecommendations, { loading: updatingRecommendations }] = useUpdateRecommendationsMutationMutation();

  const [deleteRecommendations] = useDeleteRecommendationsMutationMutation();

  const params = useParams() as Params;
  const { categoryId, subcategoryId, score } = params;

  const sectionId = `${categoryId}/${subcategoryId}/${score}`;

  const toggleExpandContent = () => {
    setExpand(!expand);
  };

  const handleSearchInput = (value: string) => {
    setSearchBoxValue(value);
  };

  const { results } = useAlgoliaSearch({
    query: searchBoxValue,
    indexName,
    hitsPerPage: 4,
    publishedOnly: false,
  });

  useEffect(() => {
    if (results.length > 0) {
      setShowSearch(true);
    }
  }, [results]);

  const searchBar = (
    <TextField
      suffix={
        <View style={{ alignItems: 'center' }}>
          <SearchIcon />
        </View>
      }
      placeholder="Type the first 3 letters"
      label="addContent"
      labelHidden
      value={searchBoxValue}
      onChange={(value) => handleSearchInput(value)}
      autoComplete="off"
    />
  );

  const handlePressAdd = async () => {
    const checkedResults = results.filter((r) => checkedItems.includes(r.objectID));
    await updateRecommendations({
      variables: {
        input: {
          recommendations: checkedResults.map((el) => ({
            itemId: el.objectID,
            title: el.title,
            type: indexName,
            score: +score,
          })),
          sectionId,
        },
      },
    });
  };

  return (
    <View style={{ backgroundColor: '#F6F6F7' }}>
      <TouchableOpacity
        onPress={toggleExpandContent}
        style={{
          backgroundColor: '#C9CCCF',
          height: 36,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          paddingHorizontal: 12,
        }}
      >
        <Text style={styles.textBoldDark}>{`${header} (${count})`}</Text>
        {expand ? (
          <ChevronUpIcon />
        ) : (
          <ChevronUpIcon
            style={{
              transform: [{ rotate: '180deg' }],
            }}
          />
        )}
      </TouchableOpacity>

      {expand && (
        <View>
          <View
            style={{
              paddingVertical: 16,
              paddingHorizontal: 20,
            }}
          >
            <Text style={styles.textRegularDark}>Add new content</Text>

            <Popover active={showSearch} activator={searchBar} onClose={() => setShowSearch(false)} fullWidth>
              {results.map(({ title, date, categories, category, objectID, coverImage, coverImageUrl }: any) => {
                return (
                  <ResultSearchCard
                    coverImage={coverImageUrl || coverImage}
                    title={title}
                    dateDuration={dayjs(new Date(date)).format('DD MMM YY')}
                    tags={getUniqueCategories((categories || category).map((el: any) => el.substring(0, el.indexOf(':'))))}
                    type={indexName}
                    id={objectID}
                    isChecked={checkedItems.includes(objectID)}
                    setIsChecked={() => {
                      setCheckedItems(checkedItems.includes(objectID) ? checkedItems.filter((el) => el !== objectID) : [...checkedItems, objectID]);
                    }}
                  />
                );
              })}
              {results.length === 0 ? <Text style={{ padding: 20 }}>Sorry, no search results found</Text> : null}

              {results.length > 0 ? (
                <ButtonGeneral label="Add" style={{ margin: 20 }} onPress={handlePressAdd} isLoading={updatingRecommendations} />
              ) : null}
            </Popover>

            {/* card wrapper */}
            {data?.map((card, index) => {
              return (
                <ContentCard
                  key={index}
                  coverImage={card.coverImage ?? undefined}
                  category={card.categories ?? []}
                  title={card.title}
                  description={card.description ?? ''}
                  instructor={card.instructorName ?? ''}
                  jobRoles={card.jobRoles?.join(', ') ?? ''}
                  statistic={` ${Number(card.averageStars).toFixed(1)} (${card?.commentCount} reviews) ${
                    !!card.shareCount || card.shareCount === 0 ? `• ${card.shareCount} shares` : ''
                  }  `}
                  userType={card.userType?.join(', ') ?? ''}
                  type={card.type}
                  id={card?.itemId}
                  handlePressRemove={deleteRecommendations}
                  score={score ?? ''}
                  sectionId={sectionId}
                />
              );
            })}
          </View>
        </View>
      )}
    </View>
  );
};
