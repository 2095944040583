export const formikInitialValues = {
  blockedAccess: {
    content: {
      newBusinessDevelopment: [],
      accountManagement: [],
      productsServices: [],
      candidates: [],
      people: [],
      systemsTechnology: [],
      finance: [],
      operations: [],
      marketing: [],
      businessStrategyPlanning: [],
    },
    training: {
      newBusinessDevelopment: [],
      accountManagement: [],
      productsServices: [],
      candidates: [],
      people: [],
      systemsTechnology: [],
      finance: [],
      operations: [],
      marketing: [],
      businessStrategyPlanning: [],
    },
    events: {
      newBusinessDevelopment: [],
      accountManagement: [],
      productsServices: [],
      candidates: [],
      people: [],
      systemsTechnology: [],
      finance: [],
      operations: [],
      marketing: [],
      businessStrategyPlanning: [],
    },
  },
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  country: '',
  jobPosition: '',
  accountType: '',
  profilePhotoUrl: '',
};
