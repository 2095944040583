import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const ShareIcon = () => (
  <Svg width={16} height={17} fill="none">
    <Path
      fill="#5C5F62"
      d="M13 10.222c-.807 0-1.537.324-2.077.844l-4.96-2.481c.015-.12.037-.238.037-.363s-.022-.243-.037-.363l4.96-2.481c.54.52 1.27.844 2.077.844 1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3c0 .125.022.243.037.363l-4.96 2.48A2.986 2.986 0 0 0 3 5.223c-1.654 0-3 1.346-3 3s1.346 3 3 3c.807 0 1.537-.324 2.077-.844l4.96 2.48c-.015.12-.037.239-.037.364 0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3z"
    />
  </Svg>
);

export { ShareIcon };
