import { SwitchButton } from '@components/marketplace/marketplace-head-menu/switch-button/SwitchButton';

import { useQueryFilters } from '../useGridFilters';

export const IsHighlightedFilter = () => {
  const { filters, setFilterValue } = useQueryFilters(['isHighlighted']);

  return (
    <SwitchButton
      title="Highlighted"
      isActive={filters.isHighlighted}
      setIsActive={(val: boolean) => setFilterValue('isHighlighted', val)}
      needDisclosure={false}
    />
  );
};
