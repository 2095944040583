import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  labelText: {
    color: '#6E757C',
  },
  circleContainer: {
    height: 14,
    width: 14,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: '#6E757C',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    marginBottom: 15,
  },
  largeCircleContainer: {
    height: 20,
    width: 20,
    borderColor: 'green',
  },
  circle: {
    height: 6,
    width: 6,
    borderRadius: 6,
    backgroundColor: '#6E757C',
  },
  largeCircle: {
    height: 12,
    width: 12,
    borderRadius: 12,
  },
  blueBackground: {
    backgroundColor: '#0D1738',
  },
  lightBlueBackground: {
    backgroundColor: '#2C6ECB',
  },
  blueBorder: {
    borderColor: '#0D1738',
  },
  lightBlueBorder: {
    borderColor: '#2C6ECB',
  },
  fullWidthContainer: {
    width: '100%',
    justifyContent: 'space-between',
  },
  noMargins: {
    marginRight: 0,
  },
});
