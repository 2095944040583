import { ListItem } from '@components/account/list-item';
import { TitleBox } from '@components/account/title-box';
import { ContentBox } from '@components/general/layouts/content-box';
import { useGetAuthTokenAndUserId } from '@hooks/useGetAuthTokenAndUserId';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { Formik, FormikValues } from 'formik';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { View } from 'react-native';
import { useNavigate } from 'react-router-native';
import * as Yup from 'yup';

import { PasswordForm } from './forms/password-form/PasswordForm';
import { styles } from '../settings/style';

const Password = () => {
  const navigate = useNavigate();
  const [openEditMode, setOpenEditMode] = useState({
    changePassword: false,
  });

  const [selected, setSelected] = useState({
    generatePassword: true,
    setManually: false,
  });

  const { user } = useGetAuthTokenAndUserId();

  const initialValues = {
    generated: nanoid(16),
    manualPassword: '',
    confirmManualPassword: '',
    currentPassword: '',
  };

  const onSubmit = async (values: FormikValues, actions: { resetForm: (args: any) => void }) => {
    if (!user || !user.email) return;
    const newPassword = selected.generatePassword ? values.generated : values.confirmManualPassword;

    const credential = EmailAuthProvider.credential(user.email, values.currentPassword);
    await reauthenticateWithCredential(user, credential)
      .then((newUser) => {
        updatePassword(newUser.user, newPassword)
          .then(() => {
            closeEditMode('changePassword');
            actions.resetForm({
              values: { ...initialValues, generated: nanoid(16) },
            });
          })
          .catch((error) => {
            console.error(error?.message || 'Password could not be reset');
          });
      })
      .catch((error) => {
        console.error(error?.message || 'Password could not be reset');
      });
  };

  const closeEditMode = (key: string) => {
    setOpenEditMode({ ...openEditMode, [key]: false });
  };

  return (
    <View style={styles.wrap}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          currentPassword: Yup.string().required('Please enter your current password'),
          generated: selected.generatePassword ? Yup.string().required('Password cannot be empty') : Yup.string(),
          manualPassword: selected.setManually ? Yup.string().required('Please enter a new password') : Yup.string(),
          confirmManualPassword: selected.setManually
            ? Yup.string()
                .required('Please confirm your new password')
                .oneOf([Yup.ref('manualPassword')], 'Passwords do not match')
            : Yup.string(),
        })}
      >
        <ContentBox>
          <TitleBox title="Password" backTitle="Back to Settings" onPressBack={() => navigate('/account/settings')} />
          {!openEditMode.changePassword ? (
            <ListItem
              title={`It's a good idea to use a strong password 
that you don't use elsewhere`}
              edit="Change Password"
              onPress={() => setOpenEditMode({ changePassword: true })}
              titleStyle={{
                fontSize: 14,
                color: '#6D7175',
                fontFamily: 'Opensans_400Regular',
              }}
            />
          ) : (
            <PasswordForm selected={selected} setSelected={setSelected} closeEditMode={() => closeEditMode('changePassword')} />
          )}
        </ContentBox>
      </Formik>
    </View>
  );
};

export { Password };
