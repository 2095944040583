import { SwitchButton } from '@components/marketplace/marketplace-head-menu/switch-button/SwitchButton';

import { useQueryFilters } from '../useGridFilters';

export const SpecialOffersFilter = () => {
  const { filters, setFilterValue } = useQueryFilters(['specialOffer']);

  return (
    <SwitchButton
      title="Special Offer"
      isActive={filters.specialOffer}
      setIsActive={(val: boolean) => setFilterValue('specialOffer', val)}
      needDisclosure={false}
    />
  );
};
