import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
  },
  textWrapper: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  image: {
    resizeMode: 'cover',
    height: 300,
    width: '100%',
    marginTop: 20,
  },
  video: {
    marginTop: 20,
  },
});
