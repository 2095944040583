import { ButtonGeneral, ButtonType } from '@components/general/button-general';
import { AuthWrapper } from '@components/sign-up/auth-wrapper';
import { useVerifyUserEmailMutation } from '@gql/generated/generated';
import { useEffect } from 'react';
import { ActivityIndicator, Platform, View } from 'react-native';
import { useNavigate, useParams } from 'react-router-native';

const EmailVerified = () => {
  const navigate = useNavigate();
  const { id, token } = useParams<{ id: string; token: string }>();

  const [verifyUser, { loading, error }] = useVerifyUserEmailMutation();

  useEffect(() => {
    if (id && token) {
      verifyUser({ variables: { verifyUserEmailId: id, token } }).catch(console.error);
    }
  }, [id, token, verifyUser]);

  if (loading) {
    return (
      <View
        style={{
          height: Platform.OS === 'web' ? '100vh' : '100%',
          width: Platform.OS === 'web' ? '100vw' : '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  }

  if (error) {
    return (
      <AuthWrapper title="Error" subtitle={`There was an error verifying your email.\n${error.message}`}>
        <View>
          <ButtonGeneral label="Back to home" onPress={() => navigate('/')} type={ButtonType.user} />
        </View>
      </AuthWrapper>
    );
  }

  return (
    <AuthWrapper title="Email verified" subtitle="Your account has been set, please click the button below to start growing your business.">
      <View>
        <ButtonGeneral label="Get started" onPress={() => navigate('/')} type={ButtonType.user} />
      </View>
    </AuthWrapper>
  );
};

export { EmailVerified };
