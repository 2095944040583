import { SelectOption } from '@components/general/form/select-option';
import { CompleteModal } from '@components/general/modals/complete-modal';
import { CategoryButton } from '@components/roadmap/category-button';
import { Card } from '@components/roadmap/pulse/priorities/card';
import { Column, Role, TColumn } from '@components/roadmap/pulse/priorities/column';
import { PulsePrioritiesWrapper } from '@components/roadmap/pulse-priorities-wrapper';
import { useEmployerGetRoadmapQuery } from '@gql/generated/generated';
import { ActionListSection } from '@shopify/polaris';
import { quarterOfYear } from '@utils/misc';
import React, { useState } from 'react';
import { View } from 'react-native';

import { useBoard } from './hooks';
import { ids, styles } from './style';

export const columns: TColumn[] = [
  { id: 'todo', role: 'todo', title: 'To do' },
  { id: 'inprogress', role: 'inprogress', title: 'In progress' },
  { id: 'completed', role: 'completed', title: 'Completed' },
];

type TCardColumns = {
  [column in Role]?: string[];
};

type TQuarter = 1 | 2 | 3 | 4;

export type TBoard = {
  [quarter in TQuarter]?: TCardColumns;
};

const Priorities = () => {
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const { board, setBoard, loading: loadingBoard, updating: updatingBoard } = useBoard();

  const [selectedQuarter, setSelectedQuarter] = useState(quarterOfYear(new Date()) as TQuarter);

  const isSubcategoryOnTheBoard = (subcategoryId: string) =>
    Object.values(board?.[selectedQuarter] || {})
      .flat()
      .includes(subcategoryId);

  const handleSubcategorySelection = (selectedSubcategories: string[]) => {
    const newTodos = selectedSubcategories.filter((subcategoryId) => !isSubcategoryOnTheBoard(subcategoryId));

    const filterUnselectedSubcategories = ([column, columnSubcategories]: [string, string[]]) => [
      column,
      columnSubcategories.filter((subcat) => selectedSubcategories.includes(subcat)) || [],
    ];

    setBoard({
      ...board,
      [selectedQuarter]: {
        ...board?.[selectedQuarter],
        ...Object.fromEntries(
          Object.entries({
            ...board?.[selectedQuarter],
            todo: [...(board?.[selectedQuarter]?.todo || []), ...newTodos],
          }).map(filterUnselectedSubcategories)
        ),
      },
    });
  };

  const { data: { employerGetRoadmap: roadmapData } = {}, loading: loadingRoadmap } = useEmployerGetRoadmapQuery();

  const moveSubcategoryToColumn = (subcategoryIdToMove: string, targetColumn: Role) => {
    const filterSubcategoryFromNonTargetColumns = ([column, subcategoryIds]: [string, string[]]) => [
      column,
      subcategoryIds.filter(
        (subcategoryId) => subcategoryId !== subcategoryIdToMove || (subcategoryId === subcategoryIdToMove && column === targetColumn)
      ),
    ];

    setBoard({
      ...board,
      [selectedQuarter]: Object.fromEntries(
        Object.entries({
          ...board?.[selectedQuarter],
          [targetColumn]: [...(board?.[selectedQuarter]?.[targetColumn] || []), subcategoryIdToMove],
        }).map(filterSubcategoryFromNonTargetColumns)
      ),
    });
  };

  const getCardIdsForColumn = ({ role }: TColumn) => board?.[selectedQuarter]?.[role] || [];

  type renderSubcategoryCardsProps = {
    subcategoryIds: string[];
    column: TColumn;
  };

  const renderSubcategoryCards = ({ subcategoryIds, column }: renderSubcategoryCardsProps) => {
    if (!roadmapData) {
      return null;
    }

    const normalisedSections =
      roadmapData?.pulse
        ?.at(-1)
        ?.sections?.filter((section): section is typeof section & { id: string; name: string } => !!section.id && !!section.name) || [];

    const sectionsWithFilteredQuestions = normalisedSections
      .map((section) => ({
        ...section,
        questions: section.questions.filter((question) => subcategoryIds.includes(question.id)),
      }))
      .filter(({ questions }) => !!questions.length);

    const cards = sectionsWithFilteredQuestions.map((section) => {
      const subcategories = section.questions.filter((question): question is typeof question & { title: string } => !!question?.subcategory);

      return subcategories.map((subcategory) => {
        const dropdownSections: ActionListSection[] = [
          {
            title: 'MOVE TO',
            items: columns.map((column) => ({
              content: column.title,
              onAction: () => {
                moveSubcategoryToColumn(subcategory.id, column.role);
              },
            })),
          },
          {
            title: '',
            items: [
              {
                content: 'Remove',
                onAction: () => {
                  const clonedBoard = { ...board };
                  const boardQuarter = clonedBoard[selectedQuarter];
                  const boardQuarterColumn = boardQuarter?.[column.id];
                  const filteredBoardQuarterColumn = boardQuarterColumn?.filter((cardId) => cardId !== subcategory.id);
                  // @ts-ignore
                  clonedBoard[selectedQuarter][column.id] = filteredBoardQuarterColumn;
                  setBoard(clonedBoard);
                },
              },
            ],
          },
        ];

        return (
          <Card
            key={subcategory.id}
            roadmapData={roadmapData}
            title={section.name}
            section={section}
            items={[subcategory]}
            dropdownSections={dropdownSections}
          />
        );
      });
    });

    return cards;
  };

  const categoryButton = (
    <CategoryButton
      key="category-button"
      placeholderText="Add/Remove Priorities"
      selectedSubcategories={Object.values(board?.[selectedQuarter] || {}).flat()}
      setSelectedSubcategories={handleSubcategorySelection}
      disabled={loadingBoard || updatingBoard}
    />
  );

  const quarterSelector = (
    <SelectOption
      value={String(selectedQuarter)}
      onChange={(option) => setSelectedQuarter(Number(option) as TQuarter)}
      options={['Quarter 1: January - March', 'Quarter 2: April - June', 'Quarter 3: July - September', 'Quarter 4: October - December'].map(
        (quarterTitle, index) => ({
          label: quarterTitle,
          value: String(index + 1),
        })
      )}
    />
  );

  return (
    <PulsePrioritiesWrapper title="Priorities" quarterSelector={quarterSelector} categoryButton={categoryButton}>
      <View style={styles.boardWrap} dataSet={{ media: ids.boardWrap }}>
        {columns.map((column) => {
          const subcategoryIds = getCardIdsForColumn(column);
          return (
            <Column key={column.id} testId={`column-${column.role}`} {...{ ...column, total: subcategoryIds.length }}>
              {renderSubcategoryCards({
                subcategoryIds,
                column,
              })}
            </Column>
          );
        })}
      </View>
      <CompleteModal
        isVisible={isSuccessModalVisible}
        onClose={() => setIsSuccessModalVisible(false)}
        title="Success!"
        description="You have successfully moved ‘Candidates’ category to Quarter 2"
        primaryButtonTitle="Continue Calendar"
        secondaryButtonTitle="Back to Pulse"
      />
    </PulsePrioritiesWrapper>
  );
};

export { Priorities };
