import { Assignments } from '@components/general/icons/account-icons/Assignment';
import { HelpIcon } from '@components/general/icons/account-icons/HelpIcon';
import { PlusCircleIcon } from '@components/general/icons/feed-icons/PlusCircleIcon';
import { ContentBox } from '@components/general/layouts/content-box';
import { Pill, PillVariant } from '@components/general/pill';
import { TouchableOpacityLink } from '@components/general/touchable-opacity-link';
import { useAuth } from '@hooks/useAuth';
import { userProfileImage } from '@utils/userProfileImage';
import React, { useEffect, useState } from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';

import { styles } from './style';

interface LinkTextProps {
  title: string;
  onPress?: () => void;
  underline?: boolean;
  isBold?: boolean;
}

const LinkText = ({ title, onPress, underline, isBold }: LinkTextProps) => (
  <TouchableOpacity onPress={onPress}>
    <Text style={[isBold ? styles.boldLink : styles.normalText, styles.linkText, underline && styles.underlineLink]}>{title}</Text>
  </TouchableOpacity>
);

interface MenuItemProps {
  title: string;
  linkText?: string;
  href?: string;
  onPressLink?: () => void;
  content?: React.ReactNode;
  subtitle?: string;
  hasBoldLink?: boolean;
  onPress?: () => void;
}

const MenuItem = ({ title, linkText, href, onPressLink, content, subtitle, hasBoldLink, onPress }: MenuItemProps) => (
  <TouchableOpacityLink style={styles.menuItemWrap} onPress={onPress} href={href}>
    <View style={styles.menuTitleWrap}>
      <Text style={styles.boldText}>{title}</Text>
      {linkText && <LinkText title={linkText} onPress={onPressLink} isBold={hasBoldLink} />}
    </View>
    {subtitle && <Text style={styles.normalText}>{subtitle}</Text>}
    {content}
  </TouchableOpacityLink>
);

interface NavItemProps {
  title: string;
  icon?: React.ReactNode;
  href?: string;
  onPress?: () => void;
}
const NavItem = ({ title, icon, onPress, href }: NavItemProps) => (
  <TouchableOpacityLink onPress={onPress} style={styles.navItem} href={href}>
    {icon}
    <Text style={styles.navItemTitle}>{title}</Text>
  </TouchableOpacityLink>
);

const AccountBox = () => {
  const [userType, setUserType] = useState('FREE');
  const { navigate, user, handleSignOut } = useAuth();

  useEffect(() => {
    const plan = user?.subscription?.plan ? user.subscription.plan.toUpperCase() : 'FREE';

    setUserType(plan);
  }, [user?.subscription?.plan]);

  return (
    <ContentBox style={styles.content}>
      <View style={styles.head}>
        <Image source={userProfileImage(user)} style={styles.image} />
        <View style={{ flex: 1 }}>
          <Text style={[styles.boldText, styles.name]}>
            {user?.accountInfo?.firstName || ''} {user?.accountInfo?.lastName || ''}
          </Text>
          <Text style={[styles.normalText, styles.occupationText]}>{user?.accountInfo?.companyInfo.jobPosition}</Text>
          <Pill variant={PillVariant.LightBlue} text={userType} />
        </View>
      </View>
      <MenuItem title="Account" subtitle="Manage your account" href="/account/settings" onPress={() => navigate('/account/settings')} />
      <MenuItem title="Organisation" subtitle="Manage my organisation" hasBoldLink href="/org/settings" onPress={() => navigate('/org/settings')} />
      <NavItem title="Saved Items" icon={<PlusCircleIcon />} href="/org/saved" onPress={() => navigate('/org/saved')} />
      <NavItem
        title="Assignments"
        icon={<Assignments style={styles.assignmentIcon} />}
        href="/org/assignment"
        onPress={() => navigate('/org/assignment')}
      />
      <NavItem title="Help" icon={<HelpIcon />} href="/account/help" onPress={() => navigate('/account/help')} />
      <NavItem title="Sign out" onPress={handleSignOut} />
    </ContentBox>
  );
};

export { AccountBox };
