import { FilterRadioButton } from '@components/filter-mobile/filter-radio-button';
import { FilterWrapper } from '@components/filter-mobile/filter-wrapper';
import React, { useState } from 'react';

export enum SortOptions {
  newestFirst = 'Newest First',
  oldestFirst = 'Oldest First',
  mostPopular = 'Most Popular',
}

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSave: (sortOption: SortOptions) => void;
}

const SortBy = ({ isVisible, onClose, onSave }: Props) => {
  const [sortValue, setSortValue] = useState<SortOptions>();
  return (
    <FilterWrapper
      headerTitle="Sort By"
      isVisible={isVisible}
      onClose={onClose}
      buttonTitle="Save"
      isDoneButtonDisabled={!sortValue}
      onDone={() => sortValue && onSave(sortValue)}
    >
      <FilterRadioButton
        title={SortOptions.newestFirst}
        isSelected={sortValue === SortOptions.newestFirst}
        onSelect={() => setSortValue(SortOptions.newestFirst)}
      />
      <FilterRadioButton
        title={SortOptions.oldestFirst}
        isSelected={sortValue === SortOptions.oldestFirst}
        onSelect={() => setSortValue(SortOptions.oldestFirst)}
      />
      <FilterRadioButton
        title={SortOptions.mostPopular}
        isSelected={sortValue === SortOptions.mostPopular}
        onSelect={() => setSortValue(SortOptions.mostPopular)}
      />
    </FilterWrapper>
  );
};

export { SortBy };
