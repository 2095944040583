import { Platform } from 'react-native';

import { Help as HelpWeb } from './Help';
import { HelpNative } from './Help.native';

const Help = Platform.select({
  native: () => HelpNative,
  default: () => HelpWeb,
})();

export { Help };
