import StyleSheet from 'react-native-media-query';

export const { styles: editPageStyles } = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 12,
  },
  headerMainTitle: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 26,
    lineHeight: 32,
    color: '#0D1738',
    marginBottom: 16,
  },
  headerBackBtn: {
    borderWidth: 1,
    borderColor: '#BABFC3',
    borderRadius: 4,
    width: 36,
    height: 36,
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerArrowLeft: {
    width: 7,
    height: 12,
  },
  sectionTitle: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 16,
    lineHeight: 20,
    color: '#0D1738',
    marginBottom: 20,
  },
  sectionEditTitle: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#2C6ECB',
  },
  // user info
  userTextInfo: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#202223',
    paddingTop: 2,
  },
  userTextInfoEmphasis: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 20,
    color: '#202223',
    paddingHorizontal: 2,
    paddingTop: 2,
  },
  userTextInfoTitle: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 20,
    color: '#0D1738',
    paddingTop: 2,
  },
});
