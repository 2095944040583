import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { ISvgProps } from '../../../../types';

const HelpIcon = (props: ISvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <Path
      fill="#fff"
      clipRule="evenodd"
      fillRule="evenodd"
      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8.9 1.602H7.191v-.113c.009-1.573.393-1.803 1.1-2.226.074-.044.152-.091.234-.142.584-.367 1.033-.83 1.033-1.507 0-.758-.594-1.25-1.333-1.25-.68 0-1.325.318-1.369 1.226H5.043C5.091 3.754 6.545 2.8 8.235 2.8c1.845 0 3.115 1.158 3.115 2.79 0 1.106-.555 1.83-1.444 2.362l-.19.115c-.632.38-.807.486-.816 1.422v.113zm.137 2.41a1.075 1.075 0 0 1-1.063 1.062c-.59 0-1.067-.474-1.062-1.063a1.054 1.054 0 0 1 1.062-1.053c.57 0 1.058.474 1.063 1.053z"
    />
  </Svg>
);

export { HelpIcon };
