import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  paragraph: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    color: '#0D1738',
  },
  h1: {
    fontSize: 32,
    lineHeight: 36,
    fontFamily: 'OpenSans_600SemiBold',
    color: '#0D1738',
    marginBottom: 20,
  },
  h2: {
    fontSize: 24,
    lineHeight: 30,
    fontFamily: 'OpenSans_600SemiBold',
    color: '#0D1738',
    marginBottom: 16,
  },
  h3: {
    fontSize: 20,
    lineHeight: 24,
    fontFamily: 'OpenSans_600SemiBold',
    color: '#0D1738',
    marginBottom: 12,
  },
  h4: {
    fontSize: 16,
    lineHeight: 20,
    fontFamily: 'OpenSans_600SemiBold',
    color: '#0D1738',
    marginBottom: 8,
  },
  h5: {
    fontSize: 14,
    lineHeight: 18,
    fontFamily: 'OpenSans_600SemiBold',
    color: '#0D1738',
    marginBottom: 8,
  },
  h6: {
    fontSize: 12,
    lineHeight: 16,
    fontFamily: 'OpenSans_600SemiBold',
    color: '#0D1738',
    marginBottom: 8,
  },
  tableWrapper: {
    overflow: 'scroll',
  },
  table: {
    width: '100%',
  },
});
