import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { ISvgProps } from 'types';

const CategoriesIcon = ({ color = '#5C5F62', ...props }: ISvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none" {...props}>
    <Path
      stroke="#5C5F62"
      d="M14.314 15.25a1.917 1.917 0 1 1 0-3.833 1.917 1.917 0 0 1 0 3.833zm0-6.667a1.917 1.917 0 1 1 0-3.833 1.917 1.917 0 0 1 0 3.833zM7.647 15.25a1.917 1.917 0 1 1 0-3.833 1.917 1.917 0 0 1 0 3.833zm0-6.667a1.917 1.917 0 1 1 0-3.833 1.917 1.917 0 0 1 0 3.833z"
    />
  </Svg>
);

export { CategoriesIcon };
