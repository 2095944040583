import { FormikTextArea } from '@components/back-office/editor/formik-text-area/FormikTextArea';
import { ButtonLayout } from '@pages/account/profile/button-layout/ButtonLayout';
import { CheckMenuFormik } from '@pages/account/profile/form-elements/checkbox-menu-formik/CheckMenuFormik';
import { DropMenuFormik } from '@pages/account/profile/form-elements/drop-menu-formik/DropMenuFormik';
import { InputFormik } from '@pages/account/profile/form-elements/input-formik/InputFormik';
import { FormikProps, FormikValues, useFormikContext } from 'formik';
import { Text, View } from 'react-native';

import {
  additionalTechnologyOptions,
  annualRevenueOptions,
  contractorManagementSupportOptions,
  crmOptions,
  geographyServicedOptions,
  headOfficeAreaOptions,
  industryOptions,
  marketingResourceExpertOptions,
  numEmployeeOptions,
  reasonsForJoiningOptions,
  recruitmentTypeOptions,
} from './businessInfoMenusOptions';
import { styles } from './styles';

interface BusinessInfoEditModeProps {
  closeEditMode: () => void;
}

const BusinessInfoEditMode = ({ closeEditMode }: BusinessInfoEditModeProps) => {
  const { handleSubmit }: FormikProps<FormikValues> = useFormikContext();

  return (
    <View style={styles.wrap}>
      <CheckMenuFormik data={industryOptions} formikKey="businessField" title="Industry" />
      <CheckMenuFormik data={recruitmentTypeOptions} formikKey="recruitmentType" title="Type of Recruitment" />
      <View style={styles.wrapPopover}>
        <Text style={styles.popoverTitle}>Number of Employees</Text>
        <DropMenuFormik formikKey="noOfEmployees" placeholder="Please Select" data={numEmployeeOptions} styleObj={{ marginTop: 4 }} />
      </View>
      <View style={styles.wrapPopover}>
        <Text style={styles.popoverTitle}>Annual Revenue</Text>
        <DropMenuFormik formikKey="annualRevenue" placeholder="Please Select" data={annualRevenueOptions} styleObj={{ marginTop: 4 }} />
      </View>
      <CheckMenuFormik data={geographyServicedOptions} formikKey="geographyServiced" title="Geography Serviced" />
      <View style={styles.wrapPopover}>
        <Text style={styles.popoverTitle}>Head Office Location (Area)</Text>
        <DropMenuFormik formikKey="headOfficeArea" placeholder="Please Select" data={headOfficeAreaOptions} styleObj={{ marginTop: 4 }} />
      </View>
      <View style={styles.wrapPopover}>
        <Text style={styles.popoverTitle}>Head Office Location (City)</Text>
        <InputFormik formikKey="headOfficeCity" placeholder="Add City Name" />
      </View>
      <View style={styles.wrapPopover}>
        <Text style={styles.popoverTitle}>CRM in use</Text>
        <DropMenuFormik formikKey="crm" placeholder="Please Select" data={crmOptions} styleObj={{ marginTop: 4 }} />
      </View>
      <CheckMenuFormik data={additionalTechnologyOptions} formikKey="additionalTechnologies" title="Additional Recruitment Technologies in Use" />
      <View style={styles.wrapPopover}>
        <Text style={styles.popoverTitle}>Contractor Management Support</Text>
        <DropMenuFormik
          formikKey="contractorManagementSupport"
          placeholder="Please Select"
          data={contractorManagementSupportOptions}
          styleObj={{ marginTop: 4 }}
        />
      </View>
      <View style={styles.wrapPopover}>
        <Text style={styles.popoverTitle}>Marketing resource/expertise</Text>
        <DropMenuFormik
          formikKey="marketingResourceOrExpertise"
          placeholder="Please Select"
          data={marketingResourceExpertOptions}
          styleObj={{ marginTop: 4 }}
        />
      </View>
      <View style={styles.wrapPopover}>
        <Text style={[styles.popoverTitle, { marginBottom: 8 }]}>Your biggest challenge over the next 90 days?</Text>
        <FormikTextArea fieldName="ninetyDayChallenge" />
      </View>

      <CheckMenuFormik
        data={reasonsForJoiningOptions}
        formikKey="reasonsForJoining"
        title="What are the 3 main reasons you joined TRN - tick any 3:"
      />
      <ButtonLayout closeEditMode={closeEditMode} submitCloseHander={handleSubmit} />
    </View>
  );
};

export { BusinessInfoEditMode };
