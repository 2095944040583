import Svg, { Path } from 'react-native-svg';
import { ISvgProps } from 'types';

export const SavedIcon = (props: ISvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <Path
      fill="#2C6ECB"
      d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10zm-4.726-2.06a1 1 0 1 0-1.414-1.414l-5.127 5.127-1.96-1.96a1 1 0 1 0-1.413 1.414l2.666 2.667a1 1 0 0 0 1.415 0l5.833-5.833z"
    />
  </Svg>
);
