import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  textBoldDark: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 20,
    color: '#0D1738',
    // 30px is the width of the button which is positioned absolutely
    maxWidth: 'calc(100% - 30px)',
  },
  textBoldDarkBlue: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 20,
    color: '#2C6ECB',
  },
  textBoldDark_12: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 12,
    lineHeight: 16,
    color: '#0D1738',
  },
  textRegularGray: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    lineHeight: 16,
    color: '#6D7175',
  },
  saveItemsWrap: {
    width: '100%',
    paddingBottom: 120,
    marginLeft: 40,
  },
  saveItemWrap: {
    flexDirection: 'row',
    marginBottom: 16,
    borderRadius: 8,
    borderBottomColor: '#EDEEEF',
    borderBottomWidth: 1,
    borderRightColor: '#EDEEEF',
    borderRightWidth: 1,
    height: 177,
    overflow: 'hidden',
  },
  saveItemBadgeImageWrapper: {
    width: 278,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    position: 'relative',
  },
  saveItemImage: {
    width: '100%',
    height: '100%',
  },
  saveItemBadges: {
    position: 'absolute',
    top: 12,
    left: 12,
  },
  saveItemInfoContainer: {
    margin: 20,
    justifyContent: 'space-between',
    flex: 5,
  },
  saveItemContentContainer: {
    flexDirection: 'row',
  },
  saveItemAvatar: {
    height: 24,
    width: 24,
    marginRight: 4,
    borderRadius: 12,
  },
  saveItemPersonalInfo: {
    flexDirection: 'row',
    height: 24,
    marginTop: 4,
    alignItems: 'center',
  },
  saveItemStatisticContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    maxWidth: '100%',
  },
  saveItemCategoryTag: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    lineHeight: 16,
    height: 20,
    color: '#FFFFFF',
    marginLeft: 8,
    paddingVertical: 2,
    paddingHorizontal: 8,
    borderRadius: 10,
    backgroundColor: '#2C6ECB',
  },
});
