import { ButtonGeneral, ButtonVariant } from '@components/general/button-general';
import { LeftArrow, RightArrow } from '@components/general/icons';
import { Row } from '@components/general/row';
import { View, ViewProps } from 'react-native';

import { styles } from './style';

const SavedItemContainer = ({
  children,
  setPageSelected,
  pageSelected,
  total,
}: ViewProps & {
  setPageSelected: (val: number) => void;
  pageSelected: number;
  total: number;
}) => {
  return (
    <>
      <View style={styles.saveItemsWrap}>
        {children}
        <Row style={{ justifyContent: 'flex-end', marginTop: 21, marginRight: 40 }}>
          <ButtonGeneral
            variant={ButtonVariant.Secondary}
            onPress={() => setPageSelected(pageSelected - 1)}
            disabled={pageSelected === 0}
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          >
            <LeftArrow />
          </ButtonGeneral>
          <ButtonGeneral
            variant={ButtonVariant.Secondary}
            disabled={(pageSelected + 1) * 9 >= total}
            onPress={() => setPageSelected(pageSelected + 1)}
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          >
            <RightArrow />
          </ButtonGeneral>
        </Row>
      </View>
    </>
  );
};

export { SavedItemContainer };
