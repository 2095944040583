import {
  RoadmapPulse,
  RoadmapPulseQuestion,
  RoadmapPulseScoreAssnCategory,
  RoadmapPulseScoreAssnSubCategory,
  RoadmapPulseSection,
} from '@gql/generated/generated';

export function getQuestionScore(question: RoadmapPulseQuestion) {
  if (!question.answer?.value) {
    return 0;
  }
  const optionIndex = question.options.findIndex((o) => o?.value === question.answer?.value);
  if (optionIndex === -1) return 0;
  return question.options[optionIndex]?.score ?? 0;
}

export function getSubCategoryRecommendation(subCategory: RoadmapPulseScoreAssnSubCategory, score: number) {
  return subCategory.levels.reduce(
    (acc, level) => {
      if (level.score >= score && score !== 0) {
        acc.content = [...acc.content, ...(level.content ?? [])] as string[];
        acc.trainings = [...acc.trainings, ...(level.trainings ?? [])] as string[];
        acc.content = Array.from(new Set(acc.content));
        acc.trainings = Array.from(new Set(acc.trainings));
      }
      return acc;
    },
    { content: [] as string[], trainings: [] as string[] }
  );
}

export function getSubCategoriesRecommendations(section: RoadmapPulseSection, subCategories: RoadmapPulseScoreAssnSubCategory[]) {
  return section.questions.reduce(
    (acc, q) => {
      const score = getQuestionScore(q);
      const subCategory = subCategories.find((s) => s.name === q.subcategory);
      if (!subCategory || score === 0) return acc;
      const { content, trainings } = getSubCategoryRecommendation(subCategory, score);
      acc.content = [...acc.content, ...content] as string[];
      acc.trainings = [...acc.trainings, ...trainings] as string[];
      return acc;
    },
    { content: [] as string[], trainings: [] as string[] }
  );
}

export function getCategoryRecommendation(
  categoryName: string,
  categoryScores: { name: string; score: number }[],
  scoreAssns: RoadmapPulseScoreAssnCategory[],
  selectedPulse: RoadmapPulse | null
) {
  // Check roadmap pulse and score assns exist
  if (!categoryScores || scoreAssns.length === 0 || !selectedPulse) {
    return { content: [], trainings: [] };
  }
  const scoreAssnCategoryIndex = scoreAssns.findIndex((c) => c.name === categoryName);
  if (scoreAssnCategoryIndex === -1) return { content: [], trainings: [] };
  const scoreAssnCategories = scoreAssns[scoreAssnCategoryIndex];
  const sectionIndex = selectedPulse.sections.findIndex((s) => s.name === categoryName);
  if (sectionIndex === -1) return { content: [], trainings: [] };
  const section = selectedPulse.sections[sectionIndex];
  return getSubCategoriesRecommendations(section, scoreAssnCategories.subCategories);
}
