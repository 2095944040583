import { useFormikContext } from 'formik';
import React from 'react';

import { ButtonLayout } from '../../button-layout/ButtonLayout';
import { CheckboxMenuReasonToJoin } from '../../form-elements/checkbox-menu-formik/CheckboxMenuReasonToJoin';

interface formProps {
  closeEditMode: () => void;
}

const ReasonsForm = ({ closeEditMode }: formProps) => {
  const { handleSubmit } = useFormikContext();

  const submitCloseHander = () => {
    handleSubmit();
    closeEditMode();
  };

  return (
    <>
      <CheckboxMenuReasonToJoin />
      <ButtonLayout closeEditMode={closeEditMode} submitCloseHander={submitCloseHander} />
    </>
  );
};

export { ReasonsForm };
