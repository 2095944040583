import { TableDropdown } from '@components/back-office/tables/table-dropdown';
import { isPartnerType, partnerTypeFilterCheckboxList } from '@utils/partners';

import { useQueryFilters } from '../useGridFilters';

export const TiersFilter = () => {
  const { filters, setFilterValue } = useQueryFilters(['partnerType']);

  const partnerType = filters.partnerType || [];

  const partnerTypeItems = partnerTypeFilterCheckboxList(partnerType);

  return (
    <TableDropdown
      needFluidContent
      placeholder={partnerType.length > 0 ? partnerType.join(', ') : 'Tiers'}
      items={partnerTypeItems}
      onSelectItem={(id) => {
        if (!isPartnerType(id)) return;

        if (partnerType.includes(id)) {
          setFilterValue(
            'partnerType',
            partnerType.filter((el: string) => el !== id)
          );
          setFilterValue(
            'partnerType',
            partnerType.filter((el: string) => el !== id)
          );
        } else {
          setFilterValue('partnerType', [...partnerType, id]);
        }
      }}
      onReset={partnerType.length > 0 ? () => setFilterValue('partnerType', []) : undefined}
      isSelected={!!filters.partnerType}
    />
  );
};
