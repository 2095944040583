import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  titleWrap: {
    padding: 20,
    paddingBottom: 0,
  },
  title: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 20,
    color: '#0D1738',
  },
  tabWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: '#EDEEEF',
    marginBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  tabItems: {
    flexDirection: 'row',
  },
  seeAllText: {
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    color: '#2C6ECB',
  },
});
