import { SelectOption } from '@components/general/form/select-option';
import { TabTitle } from '@components/general/tab-title';
import { PageStat, PageStats } from '@gql/generated/generated';
import { capitalizeFirstLetter } from '@utils/misc';
import { fromSecondsToHoursOrMins } from '@utils/trainings';
import React from 'react';
import { Image, Text, View } from 'react-native';

import { ids, styles } from './style';

const options = [
  { label: 'Last 30 days', value: '30' },
  { label: 'Last 15 days', value: '15' },
];

const TableHead = () => (
  <View style={styles.cellWrap}>
    <View style={styles.rowOne} />
    <View style={styles.rowTwo}>
      <Text style={styles.tableTitles}>Title</Text>
    </View>
    <View style={styles.rowThree}>
      <Text style={styles.tableTitles}>Avg. Time</Text>
    </View>
    <View style={styles.rowThree}>
      <Text style={styles.tableTitles}>Type</Text>
    </View>
    <View style={styles.rowFour}>
      <Text style={styles.tableTitles}>Category</Text>
    </View>
    <View style={styles.rowFive} />
  </View>
);

interface TableContentProps {
  index: number;
  title: string;
  avgTime: string;
  type: string;
  category?: string | null;
  userImages: string[];
}

const TableContent = ({ index, title, avgTime, type, category, userImages }: TableContentProps) => (
  <View style={[styles.cellWrap, styles.cellContentWrap]}>
    <View style={styles.rowOne}>
      <Text style={styles.boldText}>{index}.</Text>
    </View>
    <View style={styles.rowTwo}>
      <Text style={styles.tableTitleText}>{title}</Text>
    </View>
    <View style={styles.rowThree}>
      <Text style={styles.regularText}>{avgTime}</Text>
    </View>
    <View style={styles.rowFour}>
      <Text style={styles.regularText}>{type}</Text>
    </View>
    <View style={styles.rowFive}>
      <Text style={styles.regularText} ellipsizeMode="tail" numberOfLines={1}>
        {category}
      </Text>
    </View>
    <View style={styles.rowSix}>
      <View style={styles.imagesWrap}>
        {userImages.slice(0, 3).map((image) => (
          <Image style={styles.image} source={{ uri: image }} />
        ))}
        {userImages.length > 3 && (
          <View style={[styles.countWrap, styles.image]}>
            <Text style={styles.countText}>{userImages.length - 3}+</Text>
          </View>
        )}
      </View>
    </View>
  </View>
);

interface Props {
  activeTab: number;
  setActiveTab: (value: number) => void;
  setSelectedDays: (value: string) => void;
  selectedDays: string;
  refetch: (value: { numDays: number }) => void;
  content?: {
    __typename?: 'PageStats' | undefined;
    mostTime: Partial<PageStat>[];
    mostViews: Partial<PageStat>[];
  };
}

const AnalyticsContentHub = ({ activeTab, setActiveTab, setSelectedDays, selectedDays, refetch, content }: Props) => {
  const pages = activeTab === 1 ? content?.mostViews : content?.mostTime;

  return (
    <View>
      <View style={styles.titleWrap} dataSet={{ media: ids.titleWrap }}>
        <Text style={styles.title}>Content Hub</Text>
        <View style={styles.selectWrap}>
          <SelectOption
            onChange={(selected) => {
              setSelectedDays(selected);
              refetch({ numDays: Number(selected) });
            }}
            value={selectedDays}
            options={options}
          />
        </View>
      </View>
      <View style={styles.tabWrap}>
        <TabTitle title="Most Visited" active={activeTab === 1} onPress={() => setActiveTab(1)} />
        <TabTitle title="Most time-spent" active={activeTab === 2} onPress={() => setActiveTab(2)} />
      </View>
      <View style={styles.tableWrap}>
        <View style={styles.tableInner}>
          <TableHead />
          {pages?.map((item, index) => {
            if (item.title && item.users) {
              return (
                <TableContent
                  key={item.route}
                  index={index + 1}
                  title={item.title}
                  avgTime={fromSecondsToHoursOrMins((item?.averageTimePerMemberMins || 0) * 60)}
                  type={capitalizeFirstLetter(item.type)}
                  category={item.categories?.[0]}
                  userImages={item.users.map((user) => user.img || '')}
                />
              );
            }
          })}
        </View>
      </View>
    </View>
  );
};

export { AnalyticsContentHub };
