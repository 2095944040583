import { ListItem } from '@components/account/list-item';
import { TitleBox } from '@components/account/title-box';
import { ContentBox } from '@components/general/layouts/content-box';
import { useUserContext } from '@context/UserContext';
import { View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { styles } from './style';

const Settings = () => {
  const { currentUser } = useUserContext();
  const navigate = useNavigate();

  return (
    <View style={styles.wrap}>
      <ContentBox>
        <TitleBox title="Settings" />
        <ListItem
          title="Profile"
          subtitle="Change your personal info or profile photo"
          onPress={() => navigate('profile')}
          titleStyle={styles.title}
          subtitleStyle={styles.subtitle}
          containerStyles={{
            paddingBottom: 0,
          }}
        />
        <ListItem
          title="Job Role"
          subtitle="View company details and your assigned job role"
          onPress={() => navigate('job-role')}
          titleStyle={styles.title}
          subtitleStyle={styles.subtitle}
          containerStyles={{
            paddingBottom: 0,
          }}
        />
        <ListItem
          title="Password"
          subtitle="Change your password"
          onPress={() => navigate('password')}
          titleStyle={styles.title}
          subtitleStyle={styles.subtitle}
          containerStyles={{
            paddingBottom: 0,
          }}
        />
        {currentUser?.role?.isEmployer && (
          <>
            {/* <ListItem
              title="Subscription Plan"
              subtitle="View or upgrade your plan"
              onPress={() => navigate("subscription-plan")}
              titleStyle={styles.title}
              subtitleStyle={styles.subtitle}
              containerStyles={{
                paddingBottom: 0,
              }}
            /> */}
            {/*<ListItem*/}
            {/*  title="Payment"*/}
            {/*  subtitle="View or add your new payment method "*/}
            {/*  onPress={() => navigate("payment")}*/}
            {/*  titleStyle={styles.title}*/}
            {/*  subtitleStyle={styles.subtitle}*/}
            {/*/>*/}
          </>
        )}
      </ContentBox>
    </View>
  );
};

export { Settings };
