import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const HelpIcon = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 9H7v-.148C7 7.976 7.306 7.353 8 7c.385-.195 1-.568 1-1a1.001 1.001 0 0 0-2 0H5c0-1.654 1.346-3 3-3s3 1 3 3-2 2.165-2 3Zm-2 4h2v-2H7v2ZM8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Z"
      fill="#0D1738"
    />
  </Svg>
);

export { HelpIcon };
