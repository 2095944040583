import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  highlightedEventsContainer: {
    borderRadius: 8,
    padding: 16,
    // @ts-ignore
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)',
  },
  container: {
    width: 315,
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    marginBottom: 20,
    padding: 20,
  },
  title: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 16,
    lineHeight: 20,
    marginBottom: 20,
  },
  topRow: { justifyContent: 'space-between' },
});
