import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrapper: {
    padding: 16,
  },
  flatListStyle: {
    paddingTop: 16,
    paddingBottom: 432,
  },
  paginationWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  paginationButtonWrap: {
    alignSelf: 'flex-start',
  },
  paginationButtonLeft: {
    minWidth: 36,
    width: 36,
    height: 36,
    padding: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
});
