import { AuthWrapper } from '@components/sign-up/auth-wrapper';
import { useSendPasswordResetEmailMutation } from '@gql/generated/generated';
import { getErrorMessage } from '@utils/misc';
import { AuthError } from 'firebase/auth';
import { Alert, Text, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { ids, styles } from './style';

interface Props {
  email: string;
}

const CheckEmail = ({ email }: Props) => {
  const navigate = useNavigate();
  const [sendPasswordResetEmail] = useSendPasswordResetEmailMutation();

  const handleResendEmail = async () => {
    try {
      await sendPasswordResetEmail({
        variables: { input: { email } },
      });
      Alert.alert('Successful', 'Email resent');
    } catch (error: unknown) {
      const e = error as AuthError;
      Alert.alert('Unsuccessful', getErrorMessage(e));
    }
  };

  return (
    <AuthWrapper onPressBack={() => navigate(-1)}>
      <View>
        <Text style={styles.title} dataSet={{ media: ids.title }}>
          Check your email
        </Text>
        <Text style={styles.subtitle} dataSet={{ media: ids.subtitle }}>
          We have sent a password reset link to
          <Text style={styles.registerLink}>&nbsp;{email}</Text>
        </Text>
        <Text style={[styles.subtitle, styles.disableMargin]} dataSet={{ media: ids.subtitle }}>
          If you don’t see the email in your inbox, check your spam folder or
          <Text style={styles.registerLink} onPress={handleResendEmail}>
            &nbsp;click to resend
          </Text>
        </Text>
      </View>
    </AuthWrapper>
  );
};

export { CheckEmail };
