import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  container: {
    marginTop: 20,
    marginLeft: 40,
  },
  infoTextContainer: {
    marginBottom: 20,
  },
  infoText: {
    fontSize: 14,
    color: '#6D7175',
    lineHeight: 20,
    fontFamily: 'OpenSans_400Regular',
  },
  reasonTitleContainer: {
    marginBottom: 12,
  },
  reasonTitle: {
    fontSize: 14,
    color: '#0D1738',
    lineHeight: 20,
    fontFamily: 'OpenSans_600SemiBold',
  },
  reasonContainer: {
    marginBottom: 20,
  },
});
