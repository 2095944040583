import { ButtonLayout } from '@pages/account/profile/button-layout/ButtonLayout';
import { InputFormik } from '@pages/account/profile/form-elements/input-formik/InputFormik';
import { InputFormikContainer } from '@pages/account/profile/form-elements/input-formik/InputFormikContainer';
import { useFormikContext } from 'formik';
import React from 'react';
import { View } from 'react-native';

interface FormProps {
  closeEditMode: () => void;
}

const WebsiteForm = ({ closeEditMode }: FormProps) => {
  const { handleSubmit } = useFormikContext();

  const submitCloseHandler = () => {
    handleSubmit();
    closeEditMode();
  };
  return (
    <>
      <InputFormikContainer label="Website">
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
          }}
        >
          <InputFormik placeholder="Website" formikKey="website" styleInput={{ flex: 1 / 2 }} />
        </View>
      </InputFormikContainer>
      <ButtonLayout closeEditMode={closeEditMode} submitCloseHander={submitCloseHandler} />
    </>
  );
};

export { WebsiteForm };
