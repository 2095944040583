import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  head: {
    padding: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 20,
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
  },
  selectWrap: {
    width: 160,
  },
  tableWrap: {
    overflow: 'scroll',
  },
  tableInner: {
    minWidth: 800,
  },
  cellWrap: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
  itemCellTwo: {
    width: '20%',
  },
  itemCell: {
    width: '17.5%',
  },
  firstItemCell: {
    width: '30%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemCellThree: {
    width: '40%',
  },
  lastItemCell: {
    width: '10%',
    alignItems: 'flex-end',
  },
  cellTitle: {
    fontSize: 12,
    fontFamily: 'OpenSans_600SemiBold',
    color: '#6D7175',
  },
  itemRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 16,
    paddingRight: 16,
    width: '100%',
    borderTopWidth: 1,
    borderTopColor: '#EDEEEF',
  },
  userImage: {
    height: 40,
    width: 40,
    borderRadius: 20,
  },
  presenceCircle: {
    height: 12,
    width: 12,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: '#FFFFFF',
    position: 'absolute',
    bottom: 0,
    left: 30,
  },
  userName: {
    fontSize: 14,
    color: '#0D1738',
    marginLeft: 16,
    marginRight: 8,
    fontFamily: 'OpenSans_600SemiBold',
  },
  cellText: {
    fontSize: 14,
    color: '#0D1738',
    fontFamily: 'OpenSans_400Regular',
    width: '100%',
  },
});
