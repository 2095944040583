import { Platform } from 'react-native';

import { SearchModal as SearchModalWeb } from './SearchModal';
import { SearchModal as SearchModalNative } from './SearchModal.native';

const SearchModal = Platform.select({
  native: () => SearchModalNative,
  default: () => SearchModalWeb,
})();

export { SearchModal };
