import { ButtonGeneral, ButtonVariant } from '@components/general/button-general';
import { PlusIcon } from '@components/general/icons/feed-icons';
import { PopoverSelect } from '@components/general/popover-select/PopoverSelect';
import { Instructor, Speaker } from '@gql/generated/generated';
import { useFormikContext } from 'formik';
import React from 'react';
import { Text, View } from 'react-native';

import { styles } from './style';
import { Context, FormikFieldBase } from '../types';

interface PersonRowProps {
  personIndex: number;
  personLabel: string;
  formikFieldBase: FormikFieldBase;
  existingPeople: (Speaker | Instructor)[];
  handlePress: () => void;
}

const PersonRow = ({ personIndex, formikFieldBase, personLabel, existingPeople, handlePress }: PersonRowProps) => {
  const { values, setFieldValue } = useFormikContext<Context>();

  const currentValue = values[formikFieldBase]?.[personIndex];

  const popoverSelectItems = existingPeople
    ?.map(({ id, name }) => ({
      id,
      label: name,
      value: id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label) || 0);

  const handlePressSelect = (val: string) => {
    const selectedPerson = existingPeople?.find((person) => person?.id === val);
    setFieldValue(`${formikFieldBase}[${personIndex}]`, selectedPerson);
  };

  const handlePressAddNew = () => {
    handlePress();
  };

  return (
    <View>
      <View style={personIndex > 0 && styles.personRow}>
        <Text style={{ fontFamily: 'OpenSans_600SemiBold' }}>
          {personLabel} {personIndex + 1}
        </Text>
        <View style={styles.speakerSubRow}>
          <View style={{ marginTop: 10 }}>
            <PopoverSelect
              items={popoverSelectItems}
              onPress={handlePressSelect}
              buttonLabel={currentValue?.name ? currentValue?.name : `Select ${personLabel.toLowerCase()}`}
              initialItem={
                <ButtonGeneral variant={ButtonVariant.Secondary} onPress={handlePressAddNew}>
                  <PlusIcon />
                  <Text style={{ marginLeft: 4 }}>Add new {personLabel.toLowerCase()}</Text>
                </ButtonGeneral>
              }
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export { PersonRow };
