import { PointerArrowLeft } from '@components/general/icons/account-icons/PointerArrowLeft';
import React from 'react';
import { TextInput, TouchableOpacity, View } from 'react-native';

import { styles } from './style';

interface Props {
  onPressBack?: () => void;
  value: string;
  onChange: (value: string) => void;
}

const SearchHeader = ({ onPressBack, onChange, value }: Props) => (
  <View style={styles.wrap}>
    <View style={styles.headerInner}>
      <TouchableOpacity style={styles.backTouch} onPress={onPressBack}>
        <PointerArrowLeft />
      </TouchableOpacity>
      <TextInput style={styles.searchInput} placeholder="Search for anything" autoFocus onChangeText={onChange} value={value} />
    </View>
  </View>
);

export { SearchHeader };
