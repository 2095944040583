import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {},
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 40,
  },
  backButton: {
    borderWidth: 1,
    borderColor: 'rgb(186, 191, 195)',
    borderRadius: 4,
    width: 36,
    height: 36,
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  breadcrumb: {
    display: 'flex',
    flexDirection: 'row',
  },
  breadcrumbItem: {
    fontSize: 26,
  },
  breadcrumbSeperator: {
    marginHorizontal: 10,
  },
  collapsible: {
    backgroundColor: '#C9CCCF',
    height: 36,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    paddingHorizontal: 12,
  },
  searchIcon: {
    width: 16,
    height: 16,
    paddingTop: 2,
  },
  title: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 20,
    color: '#0D1738',
    width: 'fit-content',
  },
  date: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 16,
    color: '#2C6ECB',
    marginVertical: 5,
    width: 'fit-content',
  },
  viewContent: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 16,
    color: '#2C6ECB',
    textDecorationLine: 'underline',
    marginRight: 4,
  },
  imageContainer: {
    position: 'relative',
    marginRight: 20,
    width: '35%',
  },
  image: {
    width: '100%',
    height: 177,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  imageTagContainer: {
    position: 'absolute',
    top: 12,
    left: 12,
    flexDirection: 'row',
  },
  contentContainer: {
    position: 'relative',
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    shadowColor: 'rgba(0, 0, 0, 0.15)',
    shadowRadius: 4,
    shadowOffset: { height: 0.5, width: 0 },
  },
  contentTitle: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 20,
    color: '#0D1738',
  },
  contentDescription: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    lineHeight: 16,
    color: '#6D7175',
    width: 500,
  },
  contentJobRoles: {
    marginLeft: 10,
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 12,
    lineHeight: 16,
    color: '#0D1738',
  },
  feedbackTitle: {
    marginBottom: 20,
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 16,
    lineHeight: 20,
    color: '#202223',
  },
});
