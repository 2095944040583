import { User } from '@gql/generated/generated';
import { usePostHog } from 'posthog-js/react';
import { useState, useEffect } from 'react';

type PostHogUserData = {
  email?: string;
  accountType?: string | null;
  companyName?: string;
  companyId?: string | null;
  subscriptionPlanType?: string | null;
  isAdmin?: boolean;
  isEmployer?: boolean;
  isSuperAdmin?: boolean;
  isUser?: boolean;
};

const getPostHogUserData = (user: User): PostHogUserData => {
  const { accountInfo, accountType, role, subscriptionDetails } = user;
  const companyInfo = accountInfo?.companyInfo;
  const email = accountInfo?.email;
  const companyName = companyInfo?.name;
  const companyId = companyInfo?.id;
  const subscriptionPlanType = subscriptionDetails?.subscriptionPlanType;
  const isAdmin = role?.isAdmin;
  const isEmployer = role?.isEmployer;
  const isSuperAdmin = role?.isSuperAdmin;
  const isUser = role?.isUser;
  return {
    email,
    accountType,
    companyName,
    companyId,
    subscriptionPlanType,
    isAdmin,
    isEmployer,
    isSuperAdmin,
    isUser,
  };
};

export const usePostHogSession = (user: User | undefined) => {
  const [postHogSessionLoggedIn, setPostHogSessionLoggedIn] = useState<boolean>(false);
  const posthog = usePostHog();

  useEffect(() => {
    if (user) {
      const userData = getPostHogUserData(user as User);
      if (!postHogSessionLoggedIn) {
        setPostHogSessionLoggedIn(true);
        posthog.identify(userData.email, userData);
        posthog.setPersonPropertiesForFlags({ email: userData.email });
        const companyId = userData.companyId || 'unknown';
        posthog.group('company', companyId, { name: userData.companyName });
      }
    } else {
      if (postHogSessionLoggedIn) {
        posthog.reset();
        setPostHogSessionLoggedIn(false);
        posthog.setPersonPropertiesForFlags({});
      }
    }
  }, [posthog, user, postHogSessionLoggedIn]);
};
