import { Image } from '@components/general/image-from-storage';
import { DocumentAsset } from '@gql/generated/generated';
import React from 'react';
import { ImageSourcePropType, Text, View } from 'react-native';

import { styles } from './style';

interface Props {
  coverImage?: DocumentAsset;
  title: string;
  description: string;
  furtherInfo: string;
}

export const ModalChildren = ({ coverImage, title, description, furtherInfo }: Props) => {
  return (
    <View style={styles.modalWrapper}>
      <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
        <Image path={coverImage?.storagePath} style={styles.modalImage} />
        <View style={{ width: 195, marginLeft: 12 }}>
          <Text style={styles.textBoldDark}>{title}</Text>
          <Text style={styles.textRegularDark}>{description}</Text>
          <Text style={styles.textRegularGraySmall}>{furtherInfo}</Text>
        </View>
      </View>
      <Text style={styles.textRegularWarning}>
        Removing this content from the list might affect users who are currently in the progress of completing their tasks.
      </Text>
    </View>
  );
};
