import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  fieldTitle: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#202223',
    marginBottom: 4,
  },
  fileUploader: {
    marginTop: 12,
    marginBottom: 12,
  },
  speakerSubRow: {
    marginBottom: 16,
  },
  socialRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
  },
  personRow: {
    marginTop: 30,
    paddingTop: 30,
    borderColor: '#ECEEF7',
    borderTopWidth: 0.5,
  },
  flexOne: {
    flex: 1,
  },
  marginRight: {
    marginRight: 16,
  },
  deleteFileImage: {
    width: 110,
    height: 20,
    marginTop: 12,
  },
});
