import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
    paddingTop: 40,
    paddingLeft: 40,
    paddingBottom: 20,
  },
  wrapBorder: {
    borderBottomColor: '#EDEEEF',
    borderBottomWidth: 1,
  },
  title: {
    fontFamily: 'OpenSans_500Medium',
    fontSize: 26,
    color: '#0D1738',
  },
  subTitle: {
    color: '#2C6ECB',
    fontFamily: 'OpenSans_400Regular',
    marginRight: 45,
  },
  navWrap: {
    marginBottom: 14,
  },
});
