import { mediaQuery } from '@utils/misc';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    paddingBottom: 40,
  },
  titleWrap: {
    paddingLeft: 40,
    paddingTop: 40,
    paddingBottom: 20,
    [mediaQuery.forMobile]: {
      paddingLeft: 20,
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  title: {
    fontSize: 20,
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
  },
  tableWrap: {
    overflow: 'scroll',
  },
  tableInner: {
    minWidth: 800,
  },
  cellWrap: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 8,
    paddingLeft: 40,
    paddingRight: 40,
  },
  itemCellTwo: {
    width: '25%',
  },
  itemCell: {
    width: '17.5%',
  },
  firstItemCell: {
    width: '35%',
  },
  lastItemCell: {
    width: '5%',
    alignItems: 'flex-end',
  },
  cellTitle: {
    fontSize: 12,
    fontFamily: 'OpenSans_600SemiBold',
    color: '#6D7175',
  },
  lastCellBorder: {
    width: '100%',
    borderBottomColor: '#EDEEEF',
    borderBottomWidth: 1,
  },
  pagination: {
    marginTop: '20px',
    alignItems: 'flex-end',
    paddingRight: 40,
    [mediaQuery.forMobile]: {
      paddingRight: 20,
    },
  },
});
