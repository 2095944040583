import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { ISvgProps } from '../../../../types';

const LinkedInIcon = ({ color = '#0D1738', ...props }: ISvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <Path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.923.916c-.556 0-1.006.45-1.006 1.006v12.155c0 .555.45 1.006 1.006 1.006h12.155c.556 0 1.006-.45 1.006-1.006V1.922c0-.556-.45-1.006-1.006-1.006H1.923zm2.174 4.4a1.229 1.229 0 1 0 0-2.458 1.229 1.229 0 0 0 0 2.457zm2.354.908h2.037v.933s.553-1.106 2.056-1.106c1.342 0 2.453.661 2.453 2.676v4.247h-2.11V9.241c0-1.188-.635-1.319-1.118-1.319-1.003 0-1.175.866-1.175 1.474v3.578H6.45v-6.75zm-1.283 0H3.025v6.75h2.143v-6.75z"
    />
  </Svg>
);

export { LinkedInIcon };
