import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  dropMenuContainer: {
    flex: 1,
    borderWidth: 1,
    borderColor: '#BABFC3',
    borderRadius: 4,
    marginRight: 8,
    paddingHorizontal: 8,
    paddingVertical: 8,
    marginBottom: 16,
  },
});
