import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  textRegularGray: {
    color: '#6D7175',
    fontSize: 12,
    lineHeight: 20,
    fontFamily: 'OpenSans_400Regular',
  },
  textRegularBlue: {
    color: '#2C6ECB',
    fontSize: 14,
    lineHeight: 20,
    fontFamily: 'OpenSans_400Regular',
  },
});
