import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const ThreeDots = (props: SvgProps) => (
  <Svg width={16} height={4} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 2a2 2 0 1 1-4.001-.001A2 2 0 0 1 4 2Zm6 0a2 2 0 1 1-4.001-.001A2 2 0 0 1 10 2Zm6 0a2 2 0 1 1-4.001-.001A2 2 0 0 1 16 2Z"
      fill="#5C5F62"
    />
  </Svg>
);

export { ThreeDots };
