import { RelatedPartners } from '@components/home/related-partners';
import { SideProfile } from '@components/home/side-profile';
import { Partner } from '@gql/generated/generated';
import { View } from 'react-native';

import { styles } from './style';

interface LeftSidePanelProps {
  marketplaceItems?: Partial<Partner>[];
}

const LeftSidePanel = ({ marketplaceItems }: LeftSidePanelProps) => (
  <View>
    <View style={styles.sideProfileWrap}>
      <SideProfile />
    </View>
    {marketplaceItems ? <RelatedPartners partners={marketplaceItems} /> : null}
  </View>
);

export { LeftSidePanel };
