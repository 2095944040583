import { SelectOption } from '@components/general/form/select-option';
import { JobTitle, useGetJobTitlesQuery } from '@gql/generated/generated';
import { ButtonLayout } from '@pages/account/profile/button-layout/ButtonLayout';
import { DropMenuFormik, DropMenuFormikProps } from '@pages/account/profile/form-elements/drop-menu-formik/DropMenuFormik';
import { InputFormik } from '@pages/account/profile/form-elements/input-formik/InputFormik';
import { InputFormikContainer } from '@pages/account/profile/form-elements/input-formik/InputFormikContainer';
import { Photoform } from '@pages/account/profile/forms/photo-form/Photoform';
import { EN_COUNTRIES, EN_UK_COUNTIES } from '@pages/back-office/analytics-back-office/world';
import { useFormikContext } from 'formik';
import React from 'react';
import { Text, View } from 'react-native';

import { styles } from './style';

const countries = [...EN_UK_COUNTIES, ...EN_COUNTRIES];

interface MemberInfoEditProps {
  lastLogin?: string;
  memberSince?: string;
  handleEditMode?: () => void;
  needButtonLayout?: boolean;
}

type MemberInfo = {
  country: string;
};

const MemberInfoEdit = ({ lastLogin, memberSince, handleEditMode, needButtonLayout = true }: MemberInfoEditProps) => {
  const { data } = useGetJobTitlesQuery();

  const { handleSubmit, setFieldValue, values, errors, touched } = useFormikContext<MemberInfo>();

  const jobTitles = data?.getJobTitles?.jobTitles?.map((jobTitle: JobTitle) => ({
    value: jobTitle.name,
  }));
  const roles: DropMenuFormikProps['data'] = [
    { label: 'Admin', value: 'Employer' },
    { label: 'Team member', value: 'Employee' },
  ];

  const submitCloseHandler = () => {
    handleSubmit();
    handleEditMode?.();
  };

  return (
    <View style={styles.editModeWrap}>
      <InputFormikContainer
        label="Name"
        styleContainer={{
          borderTopWidth: 0,
        }}
        styleLabel={styles.editLabel}
      >
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <InputFormik formikKey="firstName" placeholder="First name" />
          <InputFormik formikKey="lastName" placeholder="Last name" />
        </View>
      </InputFormikContainer>
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <View style={styles.editInput}>
          <Text style={styles.editLabel}>Job Title</Text>
          <DropMenuFormik formikKey="jobPosition" placeholder="Please select" data={jobTitles || []} />
        </View>
        <View style={[styles.editInput, { marginRight: 40, marginLeft: 0 }]}>
          <Text
            style={[
              styles.editLabel,
              {
                marginBottom: 8,
              },
            ]}
          >
            Country
          </Text>
          <SelectOption
            placeholder="Country"
            options={countries || []}
            value={values.country}
            onChange={(selected) => setFieldValue('country', selected)}
            error={touched.country && errors.country ? (errors.country as string) : undefined}
          />
        </View>
      </View>
      <InputFormikContainer label="Contact details" styleContainer={styles.editContactDetailWrap} styleLabel={styles.editLabel}>
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <InputFormik formikKey="email" placeholder="Email" />
          <InputFormik formikKey="phoneNumber" placeholder="Phone number" />
        </View>
      </InputFormikContainer>
      <View style={styles.editRoleWrap}>
        <InputFormikContainer
          label="Role"
          styleContainer={styles.roleFormWrap}
          styleLabel={[
            styles.editLabel,
            {
              marginBottom: 0,
            },
          ]}
        >
          <DropMenuFormik formikKey="accountType" placeholder="Please select" data={roles} labelField="label" />
        </InputFormikContainer>
        <View style={styles.datesWrap}>
          {lastLogin && memberSince ? (
            <>
              <View
                style={{
                  marginRight: 52,
                }}
              >
                <Text
                  style={[
                    styles.datesText,
                    {
                      marginBottom: 8,
                    },
                  ]}
                >
                  Last logged in
                </Text>
                <Text style={styles.datesText}>{lastLogin}</Text>
              </View>
              <View>
                <Text style={styles.datesText}>Member since</Text>
                <Text style={styles.datesText}>{memberSince}</Text>
              </View>
            </>
          ) : null}
        </View>
      </View>
      <Photoform
        hasButtonsLayout={false}
        containerStyle={{
          borderTopWidth: 0,
          paddingTop: 0,
        }}
        labelStyle={styles.editLabel}
        isCurrentUsersProfile={false}
        fieldName="profilePhotoUrl"
      />
      {needButtonLayout ? <ButtonLayout closeEditMode={handleEditMode} submitCloseHander={submitCloseHandler} /> : null}
    </View>
  );
};

export { MemberInfoEdit };
