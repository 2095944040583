import { ListItem } from '@components/account/list-item';
import { TitleBox } from '@components/account/title-box';
import { ContentBox } from '@components/general/layouts/content-box';
import { useUserContext } from '@context/UserContext';
import { useFeatureFlag } from '@hooks/useFeatureFlag';
import { FEATURE_FLAGS } from '@utils/featureFlags';
import { ActivityIndicator, Text, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { styles } from './style';

const Members = () => {
  const { currentUser, loadingCurrentUser, refetch } = useUserContext();
  const isTrainingProgressEnabled = useFeatureFlag([FEATURE_FLAGS.TRAINING_PROGRESS]);

  const navigate = useNavigate();
  const addMemberButton = (
    <View style={styles.buttonWrap}>
      <Text style={styles.buttonTextPlus}>+</Text>
      <Text style={styles.buttonText}>Add Member</Text>
    </View>
  );
  return (
    // TOOD: Fix the fonts for the title and subtitle
    <ContentBox>
      <TitleBox title="Members" />
      <ListItem title="All members" subtitle="See who's in your organisation" onPress={() => navigate('all-members')} />
      {currentUser?.role?.isEmployer && (
        <ListItem
          title="Add a new member"
          subtitle="Create a new member profile and send the invitation to join TRN platform"
          button={addMemberButton}
          onPress={() => navigate('add-new')}
        />
      )}
      {isTrainingProgressEnabled && currentUser?.role?.isEmployer && (
        <ListItem
          title="Training Progress"
          subtitle="Monitor the progress of your members through TRN World training courses"
          onPress={() => navigate('training-progress')}
        />
      )}
      {loadingCurrentUser && <ActivityIndicator size="large" color="#213470" style={{ paddingBottom: 20 }} />}
    </ContentBox>
  );
};

export { Members };
