import { useUserContext } from '@context/UserContext';
import { FEATURE_FLAGS, FeatureFlagKey } from '@utils/featureFlags';
import { usePostHog } from 'posthog-js/react';

export function useFeatureFlag(featureFlags: FeatureFlagKey[]) {
  const posthog = usePostHog();
  const user = useUserContext();
  const isEmployer = user?.currentUser?.role?.isEmployer;

  return featureFlags.every((f) => {
    switch (f) {
      case FEATURE_FLAGS.TRAINING_PROGRESS: {
        if (!isEmployer || !posthog) {
          return false;
        }
        return posthog.isFeatureEnabled(f);
      }
      default: {
        return posthog.isFeatureEnabled(f);
      }
    }
  });
}
