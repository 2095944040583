import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
    padding: 20,
    borderTopColor: '#EDEEEF',
    borderTopWidth: 1,
  },
  loadMore: {
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: '#BABFC3',
    width: 180,
    height: 36,
    alignSelf: 'center',
    marginTop: 12,
  },
  loadMoreText: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 24,
    color: '#202223',
    textAlign: 'center',
  },
});
