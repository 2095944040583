import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    borderTopColor: '#EDEEEF',
    borderTopWidth: 1,
    padding: 40,
  },
  title: {
    fontSize: 20,
    color: '#202223',
    marginBottom: 28,
    fontFamily: 'OpenSans_600SemiBold',
  },
  greyText: {
    fontSize: 14,
    color: '#6D7175',
    marginBottom: 20,
    fontFamily: 'OpenSans_400Regular',
  },
  rowInputWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
    zIndex: 1,
  },
  inputItem: {
    width: '49%',
  },
  textAreaWrap: {
    marginBottom: 16,
  },
  submitWrap: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  submitItem: {
    marginRight: 8,
  },
});
