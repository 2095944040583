import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  tabItems: {
    flexDirection: 'row',
  },
  seeAllText: {
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    color: '#2C6ECB',
  },
  itemWrap: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 8,
    paddingBottom: 8,
  },
  itemActive: {
    backgroundColor: '#E5EFFD',
  },
  dotWrap: {
    width: '10%',
    maxWidth: 12,
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: '#2C6ECB',
  },
  itemContent: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  itemImageWrap: {
    maxWidth: 64,
    width: '30%',
  },
  itemImage: {
    width: 52,
    height: 52,
    borderRadius: 6,
  },
  itemDetail: {
    width: '70%',
  },
  itemTitle: {
    fontSize: 14,
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
  },
  itemDescription: {
    fontSize: 12,
    color: '#0D1738',
    fontFamily: 'OpenSans_400Regular',
  },
  itemInfo: {
    color: '#6D7175',
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
  },
});
