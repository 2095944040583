import Svg, { Path, SvgProps } from 'react-native-svg';

const EditIcon = (props: SvgProps) => (
  <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" scale={0.8}>
    <Path
      d="M14.8456 1.40233L18.5974 5.15493L19.2231 4.52909C20.259 3.493 20.259 1.8134 19.2231 0.777311C18.1869 -0.259104 16.5067 -0.259104 15.4705 0.777311L14.8456 1.40233Z"
      fill="#5C5F62"
    />
    <Path d="M16.8748 6.87396L13.123 3.12135L1.21784 15.0271L0 19.9979L4.96962 18.7797L16.8748 6.87396Z" fill={props.fill} />
  </Svg>
);

export { EditIcon };
