import { styles } from '@components/account/list-item/style';
import { OrganisationInfo } from '@gql/generated/generated';
import React from 'react';
import { StyleProp, Text, TextStyle, TouchableOpacity, View } from 'react-native';

interface BusinessInfoDetailsProps {
  onPress: () => void;
  organisationInfo: OrganisationInfo;
}

interface BusinessInfoItemProps {
  title: string;
  subtitle: string;
  subTitleStyles?: StyleProp<TextStyle>;
}

const BusinessInfoItem = ({ subtitle, title, subTitleStyles }: BusinessInfoItemProps) => {
  return (
    <View style={styles.leftWrap}>
      <Text style={[styles.title]}>{title}</Text>
      <Text style={[styles.subtitle, subTitleStyles]}>{subtitle}</Text>
    </View>
  );
};

const BusinessInfoDetails = ({ onPress, organisationInfo }: BusinessInfoDetailsProps) => {
  const {
    businessField,
    recruitmentType,
    noOfEmployees,
    annualRevenue,
    geographyServiced,
    headOfficeArea,
    headOfficeCity,
    crm,
    contractorManagementSupport,
    marketingResourceOrExpertise,
    additionalTechnologies,
    reasonsForJoining,
    ninetyDayChallenge,
  } = organisationInfo;
  return (
    <TouchableOpacity style={[styles.wrap, { borderTopWidth: 0 }]} onPress={onPress}>
      <View
        style={{
          flex: 1,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <BusinessInfoItem
            subTitleStyles={{
              marginLeft: 4,
            }}
            title="Industry"
            subtitle={businessField?.map((el) => `• ${el}`).join('\n') || ''}
          />
          <Text style={styles.editText}>Edit</Text>
        </View>
        <BusinessInfoItem title="Type of Recruitment" subtitle={recruitmentType?.map((el) => `${el}`).join('\n') || ''} />
        <BusinessInfoItem title="Number of Employees" subtitle={noOfEmployees || ''} />
        <BusinessInfoItem title="Annual Revenue" subtitle={annualRevenue || ''} />
        <BusinessInfoItem title="Geography Serviced" subtitle={geographyServiced?.map((el) => `${el}`).join('\n') || ''} />
        <BusinessInfoItem title="Head Office Location (Area)" subtitle={headOfficeArea || ''} />
        <BusinessInfoItem title="Head Office Location (City)" subtitle={headOfficeCity || ''} />
        <BusinessInfoItem title="CRM in use" subtitle={crm || ''} />
        <BusinessInfoItem
          title="Additional Recruitment Technologies in Use"
          subtitle={additionalTechnologies?.map((el) => `• ${el}`).join('\n') || ''}
        />
        <BusinessInfoItem title="Contractor Management Support" subtitle={contractorManagementSupport || ''} />
        <BusinessInfoItem title="Marketing resource/expertise" subtitle={marketingResourceOrExpertise || ''} />
        <BusinessInfoItem title="Your biggest challenge in the next 90 days?" subtitle={ninetyDayChallenge || ''} />
        <BusinessInfoItem
          title="What are the 3 main reasons you joined TRN – tick any 3:"
          subtitle={reasonsForJoining?.map((el) => `• ${el}`).join('\n') || ''}
        />
      </View>
    </TouchableOpacity>
  );
};

export { BusinessInfoDetails };
