import { getDownloadURL, ref } from 'firebase/storage';
import { useCallback, useEffect, useState } from 'react';

import { storage } from '../firebase';

export const useGetFile = (storagePath?: string | null) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string>();

  const getFile = useCallback(async (path: string) => {
    try {
      const downloadUrl = await getDownloadURL(ref(storage, path));
      setFileUrl(downloadUrl);
    } catch (e) {
      setIsError(true);
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (!storagePath) {
      setIsError(true);
      return;
    }
    getFile(storagePath);
  }, [getFile, storagePath]);

  return { fileUrl, isError };
};
