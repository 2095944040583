import { ListItem } from '@components/account/list-item';
import { TitleBox } from '@components/account/title-box';
import { ContentBox } from '@components/general/layouts/content-box';
import { useUserContext } from '@context/UserContext';
import { useUpdateUserMutation } from '@gql/generated/generated';
import { Formik, FormikValues } from 'formik';
import { useState } from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { AddressForm } from './forms/address-form/AddressForm';
import { ContactDetailForm } from './forms/contact-detail-form/ContactDetailForm';
import { FocusForm } from './forms/focus-form/FocusForm';
import { NameForm } from './forms/name-form/NameForm';
import { Photoform } from './forms/photo-form/Photoform';
import { ReasonsForm } from './forms/reasons-form/ReasonsForm';
import { defaultReasonsForJoining, initialValuesProfile, ReasonsInterface } from '../settings/initialValues';
import { clearFormikState, filterUnwantedInputKeysForSubmission } from '../settings/settingsUtils';
import { styles } from '../settings/style';

interface ReasonForJoining {
  strategy: ReasonsInterface;
  community: ReasonsInterface;
  business: ReasonsInterface;
  account: ReasonsInterface;
  ideas: ReasonsInterface;
  leadership: ReasonsInterface;
  team: ReasonsInterface;
}

const Profile = () => {
  const { currentUser, refetch } = useUserContext();

  const biggestFocusString =
    currentUser?.biggestFocus && currentUser.biggestFocus.length > 0 ? currentUser?.biggestFocus?.map((el: string) => ` • ${el} \n`) : null;
  const reasonsForJoiningString =
    currentUser?.reasonsForJoining && currentUser.reasonsForJoining.length > 0
      ? currentUser?.reasonsForJoining?.map((el: string) => ` • ${el} \n`)
      : null;

  const [file, setFile] = useState(null);
  const [openEditMode, setOpenEditMode] = useState({
    name: false,
    contactDetails: false,
    address: false,
    biggestFocus: false,
    reasonsForJoining: false,
    photo: false,
  });
  const [selectedCat, setSelectedCat] = useState([]);
  const initialReasonsForJoining: ReasonForJoining = {
    ...defaultReasonsForJoining,
  };
  const navigate = useNavigate();

  const closeEditMode = (key: string) => {
    setOpenEditMode({ ...openEditMode, [key]: false });
  };

  const inputKeysToBeSubmitted = [
    ...(!openEditMode.name ? ['firstName', 'lastName'] : []),
    ...(!openEditMode.contactDetails ? ['email', 'phoneNumber'] : []),
    ...(!openEditMode.address ? ['houseNumber', 'addressOne', 'addressTwo', 'postCode', 'city', 'country'] : []),
    ...(!openEditMode.biggestFocus ? ['biggestFocus'] : []),
    ...(!openEditMode.reasonsForJoining ? ['reasonsForJoining'] : []),
    ...(!openEditMode.photo ? ['profilePhotoUrl'] : []),
  ];

  const reasonArrayHandler = (values: any) => {
    const reasonArray: string[] = [];
    Object.keys(values.reasonsForJoining).forEach((key) => {
      if (values.reasonsForJoining[key].selected) {
        reasonArray.push(values.reasonsForJoining[key].value);
      }
    });
    return reasonArray;
  };

  const [updateUser] = useUpdateUserMutation();

  const handleSubmit = (values: FormikValues) => {
    if (currentUser) {
      setSelectedCat(values.biggestFocus);
      const modifyValues = {
        ...values,
        reasonsForJoining: reasonArrayHandler(values).length === 0 ? '' : reasonArrayHandler(values),
      };

      updateUser({
        variables: {
          input: {
            user: filterUnwantedInputKeysForSubmission(modifyValues, inputKeysToBeSubmitted),
          },
        },
      }).catch(console.error);
      clearFormikState(values, { ...initialValuesProfile, orgLogoUrl: '' });
    }
  };

  const selectedReasonForJoining = (): ReasonForJoining => {
    const objNew: ReasonForJoining = initialReasonsForJoining;
    currentUser?.reasonsForJoining?.forEach((reason) => {
      Object.keys(objNew).forEach((key) => {
        // @ts-ignore FIXME: bad type
        if (objNew[key].value === reason) {
          // @ts-ignore
          objNew[key].selected = true;
        }
      });
    });
    return objNew;
  };

  const userInitialVaules = {
    ...initialValuesProfile,
    firstName: currentUser?.accountInfo?.firstName || '',
    lastName: currentUser?.accountInfo?.lastName || '',
    email: currentUser?.accountInfo?.email || '',
    phoneNumber: currentUser?.accountInfo?.phoneNumber || '',
    country: currentUser?.accountInfo?.country || '',
    city: currentUser?.accountInfo?.city || '',
    postcode: currentUser?.accountInfo?.postCode || '',
    addressOne: currentUser?.accountInfo?.addressOne || '',
    biggestFocus: currentUser?.biggestFocus || [],
    reasonsForJoining: selectedReasonForJoining(),
  };

  if (!currentUser) return null;

  return (
    <View style={styles.wrap}>
      <Formik initialValues={userInitialVaules} onSubmit={handleSubmit}>
        <ContentBox
          style={{
            marginBottom: 20,
          }}
        >
          <TitleBox title="Profile" backTitle="Back to Settings" onPressBack={() => navigate('/account/settings')} />
          {!openEditMode.name ? (
            <ListItem
              title="Name"
              subtitle={currentUser?.accountInfo?.firstName ? `${currentUser?.accountInfo?.firstName} ${currentUser?.accountInfo?.lastName}` : ''}
              edit="Edit"
              onPress={() => setOpenEditMode({ ...openEditMode, name: true })}
            />
          ) : (
            <NameForm closeEditMode={() => closeEditMode('name')} />
          )}
          {!openEditMode.contactDetails ? (
            <ListItem
              title="Contact details"
              subtitle={`${currentUser?.accountInfo?.email ? currentUser?.accountInfo?.email : ''}
${currentUser?.accountInfo?.phoneNumber ? currentUser?.accountInfo?.phoneNumber : ''}`}
              edit="Edit"
              onPress={() => setOpenEditMode({ ...openEditMode, contactDetails: true })}
            />
          ) : (
            <ContactDetailForm closeEditMode={() => closeEditMode('contactDetails')} />
          )}
          {!openEditMode.address ? (
            // TODO: Remove dummy data
            <ListItem
              title="Address"
              subtitle={`${currentUser?.accountInfo?.houseNumber ? currentUser?.accountInfo?.houseNumber : ''}, ${
                currentUser?.accountInfo?.addressOne ? currentUser?.accountInfo?.addressOne : ''
              }, 
${currentUser?.accountInfo?.addressTwo ? currentUser?.accountInfo?.addressTwo : ''}
${currentUser?.accountInfo?.postCode ? currentUser?.accountInfo?.postCode : ''} 
${currentUser?.accountInfo?.city ? currentUser?.accountInfo?.city : ''}, ${
                currentUser?.accountInfo?.country ? currentUser?.accountInfo?.country : ''
              }`}
              edit="Edit"
              onPress={() => setOpenEditMode({ ...openEditMode, address: true })}
            />
          ) : (
            <AddressForm closeEditMode={() => closeEditMode('address')} />
          )}
          {!openEditMode.biggestFocus ? (
            <ListItem
              title="What's your biggest focus in the next 90 days"
              subtitle={biggestFocusString?.[0] ?? ''}
              edit="Edit"
              onPress={() => setOpenEditMode({ ...openEditMode, biggestFocus: true })}
            />
          ) : (
            <FocusForm closeEditMode={() => closeEditMode('biggestFocus')} />
          )}
          {!openEditMode.reasonsForJoining ? (
            // TODO: Remove dummy data
            <ListItem
              title="What are the 3 main reasons you joined TRN"
              subtitle={reasonsForJoiningString?.[0] ?? ''}
              edit="Edit"
              onPress={() => setOpenEditMode({ ...openEditMode, reasonsForJoining: true })}
            />
          ) : (
            <ReasonsForm closeEditMode={() => closeEditMode('reasonsForJoining')} />
          )}
          {!openEditMode.photo ? (
            <>
              {' '}
              <ListItem title="Photo" edit="Edit" onPress={() => setOpenEditMode({ ...openEditMode, photo: true })} />
              <Image
                source={currentUser?.profilePhotoUrl ? { uri: currentUser.profilePhotoUrl } : require('../../../../assets/img/Avatar.png')}
                style={{
                  width: 120,
                  height: 120,
                  marginLeft: 40,
                  marginBottom: 40,
                  borderRadius: 60,
                }}
              />
            </>
          ) : (
            <Photoform closeEditMode={() => closeEditMode('photo')} fieldName="profilePhotoUrl" />
          )}
          <View style={styles.deleteButtonContainer}>
            <TouchableOpacity style={styles.deleteButton} onPress={() => navigate('/account/settings/profile/delete')}>
              <Text style={styles.deleteButtonText}>Delete Account</Text>
            </TouchableOpacity>
          </View>
        </ContentBox>
      </Formik>
    </View>
  );
};

export { Profile };
