import { Col } from '@components/general/col';
import { textStyles } from '@styles/text';
import { FormikProps, FormikValues, useFormikContext } from 'formik';
import { useState } from 'react';
import { StyleProp, Text, TextInput, TextInputProps, TextStyle, View } from 'react-native';

import { styles } from './styles';

interface inputProps extends TextInputProps {
  placeholder: string;
  formikKey: string;
  styleInput?: StyleProp<TextStyle>;
  placeholderTextColor?: string;
  needIcon?: boolean;
  Icon?: React.ReactNode;
}

const InputFormik = ({ placeholder, formikKey, styleInput, placeholderTextColor, needIcon = false, Icon, ...textInputProps }: inputProps) => {
  const [focusView, setFocusView] = useState(false);
  const { values, handleChange, handleBlur, errors, touched }: FormikProps<FormikValues> = useFormikContext();

  const handleBlurAndFocusView = () => {
    handleBlur(formikKey);
    setFocusView(false);
  };

  const Input = (
    <TextInput
      onChangeText={handleChange(formikKey)}
      onBlur={handleBlurAndFocusView}
      // @ts-ignore // FIXME: unknown type
      value={values[formikKey]}
      placeholder={placeholder}
      style={[
        !needIcon
          ? styles.formInput
          : {
              marginLeft: 8,
              flex: 1,
              borderWidth: 0,
              ...({ outline: 'none' } as any),
            },
        styleInput,
        !!errors[formikKey] &&
          !!touched[formikKey] && {
            borderColor: 'rgba(197, 40, 12, 1)',
            backgroundColor: 'rgba(253, 226, 221, 1)',
          },
      ]}
      secureTextEntry={formikKey.toLowerCase().includes('password')}
      placeholderTextColor={placeholderTextColor}
      onFocus={() => setFocusView(true)}
      {...textInputProps}
    />
  );

  return needIcon ? (
    <View
      style={[
        styles.formInput,
        {
          borderColor: focusView ? '#000' : '#BABFC3',
          borderWidth: focusView ? 2 : 1,
          paddingHorizontal: focusView ? 11 : 12,
          paddingVertical: focusView ? 7 : 8,
        },
      ]}
    >
      {Icon}
      {Input}
    </View>
  ) : (
    <Col style={{ flex: 1 }}>
      {Input}

      {errors[formikKey as keyof typeof errors] && touched[formikKey as keyof typeof touched] && (
        <Text style={textStyles.error}>{errors[formikKey] as string}</Text>
      )}
    </Col>
  );
};

export { InputFormik };
