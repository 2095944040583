import { ReactElement } from 'react';
import { Text, View } from 'react-native';

import { styles, ids } from './style';

export const HilightBanner = ({ children }: { children: ReactElement }) => (
  <View style={styles.hilightBanner}>
    <Text style={styles.hilightBannerText} dataSet={{ media: ids.hilightBannerText }}>
      {children}
    </Text>
  </View>
);
