import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { ISvgProps } from '../../../types';

const DownloadIcon = (props: ISvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={14} height={16} fill="none" {...props}>
    <Path
      fill="#4A4A4A"
      d="M13 14a1 1 0 1 1 0 2H1a1 1 0 1 1 0-2h12zM7 0a1 1 0 0 1 1 1v6h1.008a.857.857 0 0 1 .74 1.289L7.74 11.731a.857.857 0 0 1-1.48 0L4.252 8.289A.857.857 0 0 1 4.992 7H6V1a1 1 0 0 1 1-1z"
    />
  </Svg>
);

export { DownloadIcon };
