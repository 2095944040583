import { ApolloCache, ApolloError } from '@apollo/client';
import { FacebookIconDark, InstagramIconDark, LinkedInIconDark, TwitterIconDark } from '@components/general/icons';
import { ModalContainer } from '@components/org/saved/modals/ModalContainer';
import { GenericModalLayout } from '@components/org/saved/modals/generic-modal-layout/GenericModalLayout';
import { useToast } from '@context/ToastContext';
import {
  CollectionName,
  GetAllInstructorsDocument,
  GetAllSpeakersDocument,
  Instructor,
  namedOperations,
  Speaker,
  useDeleteInstructorMutation,
  useDeleteSpeakerMutation,
  useUpdateInstructorMutation,
  useUpdateSpeakerMutation,
} from '@gql/generated/generated';
import { TextField } from '@shopify/polaris';
import { customFirestoreId } from '@utils/misc';
import { Formik } from 'formik';
import { Modal, Text, View } from 'react-native';
import * as Yup from 'yup';

import { styles } from './style';
import { EditFormProps, PersonTypeName } from './types';
import { FileUploader } from '../uploader';

const updateCache = ({
  newData,
  store,
  queryDoc,
  existingData,
  personType,
}: {
  newData?: Instructor | Speaker;
  store: ApolloCache<any>;
  queryDoc: typeof GetAllInstructorsDocument | typeof GetAllSpeakersDocument;
  existingData: Instructor[] | Speaker[];
  personType: PersonTypeName;
}) => {
  if (!newData) return;
  store.modify({
    id: `${personType}:${newData?.id}`,
    fields: {
      name() {
        return newData.name;
      },
      description() {
        return newData.description || null;
      },
      photo() {
        return newData.photo || null;
      },
      socials() {
        return newData.socials || null;
      },
    },
  });
  const existingIdsInCache = existingData.map((item) => item.id);

  if (!existingIdsInCache.includes(newData.id)) {
    const updatedData = [...(existingData || []), newData];
    store.writeQuery({
      query: queryDoc,
      data: personType === 'Instructor' ? { getAllInstructors: updatedData } : { getAllSpeakers: updatedData },
    });
  }
};

export const EditForm = ({ editPerson, setEditPerson, personType, setFieldValue, formikFieldBase }: EditFormProps) => {
  const { addToast } = useToast();

  const [updateInstructor, { loading: loadingInstructor }] = useUpdateInstructorMutation({
    update: (store, { data }) => {
      const instructorsInCache = store.readQuery({
        query: GetAllInstructorsDocument,
      }) as {
        getAllInstructors: Instructor[];
      };
      updateCache({
        existingData: instructorsInCache.getAllInstructors,
        newData: data?.updateInstructor,
        queryDoc: GetAllInstructorsDocument,
        store,
        personType,
      });
    },
  });

  const [updateSpeaker, { loading }] = useUpdateSpeakerMutation({
    update: (store, { data }) => {
      const speakersInCache = store.readQuery({
        query: GetAllSpeakersDocument,
      }) as {
        getAllSpeakers: Speaker[];
      };

      updateCache({
        existingData: speakersInCache.getAllSpeakers,
        newData: data?.updateSpeaker,
        queryDoc: GetAllSpeakersDocument,
        store,
        personType,
      });
    },
  });

  const deletionEventHandlers = {
    onCompleted: () => {
      addToast('success', `Successfully deleted ${personType.toLowerCase()}`);
    },
    onError: (error: ApolloError) => {
      addToast('error', `Error deleting ${personType.toLowerCase()}`);
      console.error({ error });
    },
  };

  const [deleteSpeaker] = useDeleteSpeakerMutation({
    awaitRefetchQueries: true,
    refetchQueries: [namedOperations.Query.getEvent, namedOperations.Query.getAllSpeakers],
    ...deletionEventHandlers,
  });

  const [deleteInstructor] = useDeleteInstructorMutation({
    awaitRefetchQueries: true,
    refetchQueries: [namedOperations.Query.getTraining, namedOperations.Query.getAllInstructors],
    ...deletionEventHandlers,
  });

  return (
    <Formik
      initialValues={{
        id: editPerson?.person?.id ?? customFirestoreId(),
        name: editPerson?.person?.name,
        description: editPerson?.person?.description,
        socials: editPerson?.person?.socials,
        photo: editPerson?.person?.photo,
      }}
      validationSchema={Yup.object().shape({
        id: Yup.string().required('This field is required'),
        name: Yup.string().required('Please provide a name'),
        description: Yup.string().required('Please provide a description'),
        photo: Yup.object().required('A photo is required').typeError('A photo is required'),
      })}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={async (values) => {
        if (!values.id || !values.name) {
          throw new Error('Missing id and name');
        }

        const input = {
          id: values?.id,
          name: values.name,
          description: values.description,
          socials: {
            facebook: values.socials?.facebook,
            instagram: values.socials?.instagram,
            twitter: values.socials?.twitter,
            linkedin: values.socials?.linkedin,
          },
        };

        if (personType === 'Speaker') {
          await updateSpeaker({
            variables: {
              input,
            },
          });
        }

        if (personType === 'Instructor') {
          await updateInstructor({
            variables: {
              input,
            },
          });
        }

        setFieldValue(`${formikFieldBase}[${editPerson?.i}]`, values);

        return setEditPerson(undefined);
      }}
    >
      {({ handleSubmit, setFieldValue, values, errors }) => {
        return (
          <Modal visible={!!editPerson} transparent>
            <ModalContainer>
              <GenericModalLayout
                title={editPerson?.person ? `Edit ${personType.toLowerCase()}` : `Add new ${personType.toLowerCase()}`}
                closeModal={() => setEditPerson(undefined)}
                hasButtonLayout
                buttonName="Save"
                withCancel
                withCustomSecondaryButton={!!editPerson?.person?.id}
                secondaryButtonText="Delete Permanently"
                onActionCustomSecondaryButton={async () => {
                  const personId = editPerson?.person?.id;
                  if (!personId) {
                    return;
                  }

                  console.log({ editPerson, personType, formikFieldBase });

                  if (window.confirm(`Do you really want to delete ${editPerson.person?.name ?? 'this person'}?`)) {
                    const mutationFn = personType === 'Speaker' ? deleteSpeaker : deleteInstructor;

                    await mutationFn({
                      variables: {
                        id: personId,
                      },
                    });

                    setEditPerson(undefined);
                  }
                }}
                handleSubmit={handleSubmit}
                loading={loading || loadingInstructor}
              >
                {editPerson && (
                  <View style={styles.modalContainer}>
                    <View style={styles.speakerSubRow}>
                      <Text style={[styles.fieldTitle, { marginTop: 15 }]}>Name</Text>
                      <TextField
                        label="Name"
                        placeholder={`${personType} name`}
                        labelHidden
                        value={values?.name}
                        onChange={(value) => setFieldValue('name', value)}
                        autoComplete="off"
                        error={errors.name}
                      />
                    </View>

                    <View style={styles.speakerSubRow}>
                      <Text style={styles.fieldTitle}>About</Text>
                      <TextField
                        label="About"
                        placeholder={`About details about the ${personType}`}
                        labelHidden
                        value={values?.description ?? ''}
                        onChange={(value) => setFieldValue('description', value)}
                        multiline={4}
                        autoComplete="off"
                        error={errors.description}
                      />
                    </View>
                    <View style={styles.speakerSubRow}>
                      <Text style={[styles.fieldTitle]}>Photo</Text>
                      <View style={[styles.fileUploader]}>
                        <FileUploader
                          initialAsset={editPerson.person?.photo ?? null}
                          assetInstruction={{
                            instructionType: 'one-to-one',
                            collectionId: personType === 'Speaker' ? CollectionName.SPEAKERS : CollectionName.INSTRUCTORS,
                            documentId: values.id,
                            key: 'photo',
                          }}
                          actionHint="All files should be less than 50 MB."
                          validFileTypes={['image/gif', 'image/jpeg', 'image/png']}
                          formikFieldname="photo"
                          errorMessage={errors.photo}
                        />
                      </View>
                    </View>
                    <View style={styles.speakerSubRow}>
                      <Text style={styles.fieldTitle}>Links</Text>
                      <View>
                        <View style={styles.socialRow}>
                          <View style={[styles.flexOne, styles.marginRight]}>
                            <TextField
                              label="Facebook Link"
                              labelHidden
                              value={values?.socials?.facebook ?? ''}
                              onChange={(value) => setFieldValue('socials.facebook', value)}
                              autoComplete="off"
                              prefix={
                                <View>
                                  <FacebookIconDark />
                                </View>
                              }
                            />
                          </View>
                          <View style={styles.flexOne}>
                            <TextField
                              label="Instagram Link"
                              labelHidden
                              value={values.socials?.instagram ?? ''}
                              onChange={(value) => setFieldValue('socials.instagram', value)}
                              autoComplete="off"
                              prefix={
                                <View>
                                  <InstagramIconDark />
                                </View>
                              }
                            />
                          </View>
                        </View>
                        <View style={styles.socialRow}>
                          <View style={[styles.flexOne, styles.marginRight]}>
                            <TextField
                              label="Twitter Link"
                              labelHidden
                              value={values?.socials?.twitter ?? ''}
                              onChange={(value) => setFieldValue('socials.twitter', value)}
                              autoComplete="off"
                              prefix={
                                <View>
                                  <TwitterIconDark />
                                </View>
                              }
                            />
                          </View>
                          <View style={styles.flexOne}>
                            <TextField
                              label="LinkedIn Link"
                              labelHidden
                              value={values.socials?.linkedin ?? ''}
                              onChange={(value) => setFieldValue('socials.linkedin', value)}
                              autoComplete="off"
                              prefix={
                                <View>
                                  <LinkedInIconDark />
                                </View>
                              }
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                )}
              </GenericModalLayout>
            </ModalContainer>
          </Modal>
        );
      }}
    </Formik>
  );
};
