import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const FileIcon = (props: SvgProps) => (
  <Svg width={12} height={16} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 9h8V7H2v2Zm0 4h8v-2H2v2Zm0-8h4V3H2v2Zm6-5H1.5A1.5 1.5 0 0 0 0 1.5v13A1.5 1.5 0 0 0 1.5 16h9a1.5 1.5 0 0 0 1.5-1.5V4L8 0Z"
      fill="#0D1738"
    />
  </Svg>
);

export { FileIcon };
