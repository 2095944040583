import { mediaQuery } from '@utils/misc';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 20,
    paddingTop: 40,
    borderBottomWidth: 1,
    borderBottomColor: '#EDEEEF',
    [mediaQuery.forMobile]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
    },
  },
  backTouch: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  backText: {
    color: '#0D1738',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    marginLeft: 10,
  },
  titleWrap: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: 'OpenSans_600SemiBold',
    color: '#0D1738',
    fontSize: 26,
  },
  exportText: {
    marginLeft: 6,
    color: '#0D1738',
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
  },
});
