import '@utils/sentry';
import { HilightBanner } from '@components/general/hilight-page-banner/HilightBanner';
import { MobileNavbar } from '@components/general/mobile-navbar';
import { Navbar as NavbarComponent } from '@components/navbar';
import { WelcomePopup } from '@components/popup/WelcomePopup';
import Snowfall from '@components/snowfall/Snowfall';
import { RoadmapPulseProvider } from '@context/RoadmapPulseContext';
import { SelectedUsersAssignModalContextProvider } from '@context/SelectedUsersAssignModalContext';
import { ToastContextProvider } from '@context/ToastContext';
import { useUserContext } from '@context/UserContext';
import { OpenSans_400Regular, OpenSans_500Medium, OpenSans_600SemiBold, OpenSans_700Bold, useFonts } from '@expo-google-fonts/open-sans';
import { useHubSpotPageTracking } from '@hooks/useHubSpotTracking';
import { Account } from '@pages/account/account';
import { ContactSupport } from '@pages/account/contact-support';
import { DeleteAccount } from '@pages/account/delete-account';
import { Help } from '@pages/account/help';
import { HelpRequest } from '@pages/account/help-request';
import { JobRole } from '@pages/account/job-role';
import { NotificationSettings } from '@pages/account/notification-settings';
import { AllNotifications } from '@pages/account/notification-settings/all-notifications';
import { Assignment as AssignmentNotificationSettings } from '@pages/account/notification-settings/assignment';
import { Content as ContentNotificationSettings } from '@pages/account/notification-settings/content';
import { Events as EventsNotificationSettings } from '@pages/account/notification-settings/events';
import { Feed as FeedNotificationSettings } from '@pages/account/notification-settings/feed';
import { ManageNotifications } from '@pages/account/notification-settings/manage-notifications/ManageNotifications';
import { SavedItems as SavedItemsNotificationSettings } from '@pages/account/notification-settings/saved-items';
import { Training as TrainingNotificationSettings } from '@pages/account/notification-settings/training';
import { Password } from '@pages/account/password';
import { Payment } from '@pages/account/payment/Payment';
import { Profile } from '@pages/account/profile';
import { EditProfile } from '@pages/account/profile/edit-profile/EditProfile';
import { PersonalInfo } from '@pages/account/profile/personal-info';
import { Settings } from '@pages/account/settings';
import { SubscriptionPlan } from '@pages/account/subscription-plan/SubscriptionPlan';
import { BackOffice } from '@pages/back-office';
import { ContentView } from '@pages/content/content-view';
import { EventNative, EventWeb } from '@pages/events/event-view';
import { IndexPageLayout } from '@pages/events/index-page-layout';
import { ForgotPassword } from '@pages/forgot-password';
import { Home } from '@pages/home';
import { Login } from '@pages/login';
import { Marketplace } from '@pages/marketplace';
import { MarketplaceIndividualView } from '@pages/marketplace-individual-view';
import { NotFound } from '@pages/not-found';
import { AnalyticsIndex } from '@pages/org/analytics/analytics-index';
import { AnalyticsMembers } from '@pages/org/analytics/analytics-members';
import { AnalyticsTrnWorld } from '@pages/org/analytics/analytics-trn-world';
import { Assignment } from '@pages/org/assignment';
import { AssignmentListNative } from '@pages/org/assignment-list-native';
import { BusinessInfo } from '@pages/org/business-info/BusinessInfo';
import { ContactInfo } from '@pages/org/contact-info/ContactInfo';
import { Members } from '@pages/org/members';
import { AddNewMember } from '@pages/org/members/add-new-member/AddNewMember';
import { AllMembers } from '@pages/org/members/all-members/AllMembers';
import { MemberView } from '@pages/org/members/member-view';
import { TrainingProgress } from '@pages/org/members/training-progress';
import { OrganizationSettings } from '@pages/org/organization-settings/OrganizationSettings';
import { Saved } from '@pages/org/saved';
import { SavedListNative } from '@pages/org/saved-list-native';
import { SavedTypeNative } from '@pages/org/saved-type-native';
import { ResetPassword } from '@pages/reset-password';
import { GoalIndex } from '@pages/roadmap/goal/goal-index';
import { NorthStarIndex } from '@pages/roadmap/north-star/north-star-index';
import { Priorities } from '@pages/roadmap/priorities';
import { PulseContent } from '@pages/roadmap/pulse/pulse-content';
import { PulseIndex } from '@pages/roadmap/pulse/pulse-index';
import { PulseRecommendations } from '@pages/roadmap/pulse/pulse-recommendations/PulseRecommendations';
import { PulseResultPlan } from '@pages/roadmap/pulse/pulse-result-plan';
import { PulseResult } from '@pages/roadmap/pulse/pulse-result-plan/PulseResult';
import { RoadmapIntro } from '@pages/roadmap/roadmap-intro';
import { SearchResults } from '@pages/search/search-results';
import { SignUp } from '@pages/sign-up';
import { EmailVerified } from '@pages/sign-up/email-verified';
import { VerifyEmail } from '@pages/sign-up/verify-email';
import { Welcome } from '@pages/sign-up/welcome';
import { Lesson } from '@pages/training/lesson';
import { TrainingDetail } from '@pages/training/training-detail';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { styles } from '@styles/Popup/mainPopup';
import { UpdateContext } from '@utils/UpdateContext';
import { FEATURE_FLAGS } from '@utils/featureFlags';
import { navbarRoutes } from '@utils/misc';
import { PageVariant } from '@utils/models';
import ExpoConstants from 'expo-constants';
import { User } from 'firebase/auth';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ActivityIndicator, /* Image */ Platform, View, Text, Linking } from 'react-native';
import { Host } from 'react-native-portalize';
import { Navigate, Route, Routes, useLocation, useNavigate, useRoutes } from 'react-router-native';

// import LogoWhite from "../assets/img/LogoWhite.png";

import { RouteFeatureFlag } from './RouteFeatureFlag';
import { auth } from './firebase';

const userRole: 'admin' | 'user' = ExpoConstants.expoConfig!.extra!.userRole as 'admin' | 'user';
console.assert(['admin', 'user'].includes(userRole), 'Expected USER_ROLE to be set to user or admin');

const { Frame /*ContextualSaveBar, Button*/ } = Platform.select({
  native: () => ({
    ContextualSaveBar: null,
    Frame: null,
    Button: null,
  }),
  default: () => ({
    ContextualSaveBar: require('@shopify/polaris').ContextualSaveBar,
    Frame: require('@shopify/polaris').Frame,
    Button: require('@shopify/polaris').Button,
  }),
})();

const storeData = async (value: string) => {
  const result = await AsyncStorage.setItem('@authTRNworld', value);

  return result;
};

const getData = async () => {
  const result = await AsyncStorage.getItem('@authTRNworld');

  if (result !== null) return result;
  else return '';
};

const NativeNavbar = ({ isSignedIn, isVerified }: { isSignedIn: boolean; isVerified: boolean }) =>
  useRoutes(
    navbarRoutes.map((path) => ({
      path,
      element: <NavbarComponent userRole={userRole} isSignedIn={isSignedIn} isVerified={isVerified} />,
    }))
  );

const NativeMobileNavbar = () =>
  useRoutes(
    navbarRoutes.slice(0, 4).map((path) => ({
      path,
      element: <MobileNavbar />,
    }))
  );

const NavBarWrapper = ({ isShowingBanner, children }: { isShowingBanner: boolean; children: ReactElement }) => {
  return (
    <View
      style={{
        position: 'fixed',
        zIndex: 1,
        top: isShowingBanner ? 56 : 0,
        width: '100%',
      }}
    >
      {children}
    </View>
  );
};

const RedirectToLogin = () => {
  const { pathname } = useLocation();
  const to = pathname ? `/login?redirect=${pathname}` : `/login`;
  return <Navigate to={to} replace />;
};

const PageTitle = ({ title }: { title: string }) => {
  const location = useLocation();

  useEffect(() => {
    document.title = `TRNWorld ${title}`;
  }, [location, title]);

  return null;
};

export const Index = () => {
  const { pathname } = useLocation();
  const navigation = useNavigate();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [hasUpdated, setHasUpdated] = useState(0);
  const [isPopUp, setIsPopUp] = useState(false);
  const [atAuth, setAtAuth] = useState(true);
  const [currentUser, setCurrentUser] = useState<User | null>(null); // this is the Firebase Auth User object
  const [hasTriedAuth, setHasTriedAuth] = useState(false);

  const { currentUser: currentContextUser, pendingEmailVerification } = useUserContext(); // this is the Firestore User collection document object

  // hubspot page tracking
  useHubSpotPageTracking();

  // this effect shows a WelcomePopup to the user when they visit for the first time
  // however the `setAtAuth(false);` (on L170) is disabling it, probably because the WelcomePopup is not wired up to do anything
  useEffect(() => {
    // WelcomePopup
    if (isSignedIn && atAuth) {
      getData()
        .then((e) => {
          setIsPopUp(Platform.OS === 'web' && !e);
          !e && storeData('true');
        })
        .catch(() => setIsPopUp(false));
    }
    setAtAuth(false);
  }, [atAuth, isSignedIn]);

  useEffect(() => {
    // Session Persistence
    return auth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        setIsSignedIn(true);
        setCurrentUser(user);
      } else if (user && !user.emailVerified) {
        console.error('User not verified, signing out and redirecting to verify email page');
        setIsSignedIn(false);
        auth.signOut().then(() => {
          if (!pathname.includes('email-verified')) {
            navigation('/verify-email', { state: { userId: user.uid, email: user.email } });
          }
        });
      } else {
        setIsSignedIn(false);
      }
      setHasTriedAuth(true);
    });
  }, [navigation, pathname]);

  // useEffect(() => {
  //   if (
  //     hasTriedAuth &&
  //     !isSignedIn &&
  //     Platform.OS === "web" &&
  //     !redirectPaths.includes(pathname)
  //   ) {
  //     navigation(`/?redirect=${pathname}`);
  //   }
  // }, [hasTriedAuth]);

  const [fontsLoaded] = useFonts({
    OpenSans_400Regular,
    OpenSans_500Medium,
    OpenSans_600SemiBold,
    OpenSans_700Bold,
  });

  const isVerified = !!currentUser?.emailVerified;
  const isFreeMember = currentContextUser?.subscription?.plan?.toLowerCase() === 'free';
  const hasPendingEmailVerification = !!pendingEmailVerification?.email && pendingEmailVerification?.email !== currentContextUser?.accountInfo?.email;
  const isShowingBanner = isFreeMember || hasPendingEmailVerification;

  const AuthStack = useCallback(() => {
    if (userRole === 'user') {
      return (
        <View
          style={{
            ...(Platform.OS === 'web' && { marginTop: isShowingBanner ? 11 : -45 }),
            height: '100%',
          }}
        >
          <Snowfall />
          <ToastContextProvider>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <PageTitle title="Home page" />
                    <Home />
                  </>
                }
              />
              <Route
                path="/content"
                element={
                  <>
                    <PageTitle title="Content index page" />
                    <IndexPageLayout variant={PageVariant.content} />
                  </>
                }
              />

              <Route
                path="/content/:id"
                element={
                  <>
                    <PageTitle title="Content details page" />
                    <ContentView />
                  </>
                }
              />
              <Route
                path="/marketplace"
                element={
                  <>
                    <PageTitle title="Partners index page" />
                    <Marketplace />
                  </>
                }
              />
              <Route
                path="/marketplace/:id"
                element={
                  <>
                    <PageTitle title="Partners details page" />
                    <MarketplaceIndividualView />
                  </>
                }
              />
              <Route
                path="/verify-email"
                element={
                  <>
                    <PageTitle title="Verify Email page" />
                    <VerifyEmail />
                  </>
                }
              />

              <Route
                path="/events"
                element={
                  <>
                    <PageTitle title="Events index page" />
                    <IndexPageLayout variant={PageVariant.event} />
                  </>
                }
              />
              <Route
                path="/events/:id"
                element={
                  <>
                    <PageTitle title="Event details page" />
                    {Platform.OS === 'web' ? <EventWeb /> : <EventNative />}
                  </>
                }
              />
              <Route
                path="/account"
                element={
                  <>
                    <PageTitle title="Account page" />
                    <Account withHelp isUserAccount />
                  </>
                }
              >
                <Route
                  path="/account/help"
                  element={
                    <>
                      <PageTitle title="Account Help page" />
                      <Help />
                    </>
                  }
                />
                <Route
                  path="/account/help/request"
                  element={
                    <>
                      <PageTitle title="Account Help Request page" />
                      <HelpRequest />
                    </>
                  }
                />
                <Route
                  path="/account/help/contact-support"
                  element={
                    <>
                      <PageTitle title="Account Help Contact-Support page" />
                      <ContactSupport />
                    </>
                  }
                />
                <Route
                  path="/account/settings"
                  element={
                    <>
                      <PageTitle title="Account Settings page" />
                      <Settings />
                    </>
                  }
                />
                <Route
                  path="/account/settings/subscription-plan"
                  element={
                    <>
                      <PageTitle title="Account Settings Subscription-Plan page" />
                      <SubscriptionPlan />
                    </>
                  }
                />
                <Route
                  path="/account/settings/payment"
                  element={
                    <>
                      <PageTitle title="Account Settings Payment page" />
                      <Payment />
                    </>
                  }
                />
                <Route
                  path="/account/notification-settings"
                  element={
                    <>
                      <PageTitle title="Account Notifications-Settings page" />
                      <NotificationSettings />
                    </>
                  }
                />
                <Route
                  path="/account/notification-settings/content"
                  element={
                    <>
                      <PageTitle title="Account Notifications-Settings Content page" />
                      <ContentNotificationSettings />
                    </>
                  }
                />
                <Route
                  path="/account/notification-settings/events"
                  element={
                    <>
                      <PageTitle title="Account Notifications-Settings Events page" />
                      <EventsNotificationSettings />
                    </>
                  }
                />
                <Route
                  path="/account/notification-settings/training"
                  element={
                    <>
                      <PageTitle title="Account Notifications-Settings Training page" />
                      <TrainingNotificationSettings />
                    </>
                  }
                />
                <Route
                  path="/account/notification-settings/assignment"
                  element={
                    <>
                      <PageTitle title="Account Notifications-Settings Assignment page" />
                      <AssignmentNotificationSettings />
                    </>
                  }
                />
                <Route
                  path="/account/notification-settings/feed"
                  element={
                    <>
                      <PageTitle title="Account Notifications-Settings Feed page" />
                      <FeedNotificationSettings />
                    </>
                  }
                />
                <Route
                  path="/account/notification-settings/saved-items"
                  element={
                    <>
                      <PageTitle title="Account Notifications-Settings Saved-Items page" />
                      <SavedItemsNotificationSettings />
                    </>
                  }
                />
                <Route
                  path="/account/all-notifications"
                  element={
                    <>
                      <PageTitle title="Account All-Notifications page" />
                      <AllNotifications />
                    </>
                  }
                />
                <Route
                  path="/account/manage-notifications"
                  element={
                    <>
                      <PageTitle title="Account Manage-Notifications page" />
                      <ManageNotifications />
                    </>
                  }
                />
                <Route
                  path="/account/settings/profile"
                  element={
                    <>
                      <PageTitle title="Account Settings Profile page" />
                      <Profile />
                    </>
                  }
                />
                <Route
                  path="/account/settings/profile/delete"
                  element={
                    <>
                      <PageTitle title="Account Settings Profile Delete page" />
                      <DeleteAccount />
                    </>
                  }
                />
                <Route
                  path="/account/settings/profile/edit"
                  element={
                    <>
                      <PageTitle title="Account Settings Profile Edit page" />
                      <EditProfile />
                    </>
                  }
                />
                <Route
                  path="/account/settings/profile/edit/personal-info"
                  element={
                    <>
                      <PageTitle title="Account Settings Profile Edit Personal-Info page" />
                      <PersonalInfo />
                    </>
                  }
                />
                <Route
                  path="/account/settings/job-role"
                  element={
                    <>
                      <PageTitle title="Account Settings Job-Role page" />
                      <JobRole />
                    </>
                  }
                />
                <Route
                  path="/account/settings/password"
                  element={
                    <>
                      <PageTitle title="Account Settings Password page" />
                      <Password />
                    </>
                  }
                />
              </Route>

              <Route
                path="/org/saved"
                element={
                  <>
                    <PageTitle title="Org Saved page" />
                    <Saved />
                  </>
                }
              />
              <Route
                path="/org/saved/:type"
                element={
                  <>
                    <PageTitle title="Org Saved type page" />
                    <SavedTypeNative />
                  </>
                }
              />
              <Route
                path="/org/saved/list"
                element={
                  <>
                    <PageTitle title="Org Saved List page" />
                    <SavedListNative />
                  </>
                }
              />
              <Route
                path="/org/assignment/list"
                element={
                  <>
                    <PageTitle title="Org Assignment List page" />
                    <AssignmentListNative />
                  </>
                }
              />
              <Route
                path="/org/assignment/native"
                element={
                  <>
                    <PageTitle title="Org Assigment Native page" />
                    <Assignment variant={PageVariant.assignment} />
                  </>
                }
              />
              <Route
                path="/org"
                element={
                  <>
                    <PageTitle title="Org page" />
                    <Account isUserAccount={false} withHelp={false} />
                  </>
                }
              >
                <Route
                  path="/org/analytics"
                  element={
                    <>
                      <PageTitle title="Org Analytics page" />
                      <AnalyticsIndex />
                    </>
                  }
                />
                <Route
                  path="/org/analytics/members"
                  element={
                    <>
                      <PageTitle title="Org Analytics Members page" />
                      <AnalyticsMembers />
                    </>
                  }
                />
                <Route
                  path="/org/analytics/trn-world"
                  element={
                    <>
                      <PageTitle title="Org Analytics Trn-World page" />
                      <AnalyticsTrnWorld />
                    </>
                  }
                />
                <Route
                  path="/org/assignment"
                  element={
                    <>
                      <PageTitle title="Org Assignment page" />
                      <Assignment variant={PageVariant.assignment} />
                    </>
                  }
                />
                <Route
                  path="/org/settings"
                  element={
                    <>
                      <PageTitle title="Org Settings page" />
                      <OrganizationSettings />
                    </>
                  }
                />
                <Route
                  path="/org/settings/contact-info"
                  element={
                    <>
                      <PageTitle title="Org Settings Contact-Info page" />
                      <ContactInfo />
                    </>
                  }
                />
                <Route
                  path="/org/settings/business-info"
                  element={
                    <>
                      <PageTitle title="Org Settings Business-Info page" />
                      <BusinessInfo />
                    </>
                  }
                />
                <Route
                  path="/org/members"
                  element={
                    <>
                      <PageTitle title="Org Members page" />
                      <Members />
                    </>
                  }
                />
                <Route
                  path="/org/members/training-progress"
                  element={
                    <>
                      <PageTitle title="Org Members Training-Progress page" />
                      <TrainingProgress />
                    </>
                  }
                />
                <Route
                  path="/org/members/all-members"
                  element={
                    <>
                      <PageTitle title="Org Members All-Members page" />
                      <AllMembers />
                    </>
                  }
                />
                <Route
                  path="/org/members/add-new"
                  element={
                    <>
                      <PageTitle title="Org Members Add-New page" />
                      <AddNewMember />
                    </>
                  }
                />
                <Route
                  path="/org/members/:id"
                  element={
                    <>
                      <PageTitle title="Org Members member details page" />
                      <MemberView />
                    </>
                  }
                />
              </Route>
              <Route
                path="/training"
                element={
                  <>
                    <PageTitle title="Training index page" />
                    <IndexPageLayout variant={PageVariant.training} />
                  </>
                }
              />
              <Route
                path="/training/:id"
                element={
                  <>
                    <PageTitle title="Training details page" />
                    <TrainingDetail />
                  </>
                }
              />
              <Route
                path="/training/:id/lesson/:moduleId/:lessonId"
                element={
                  <>
                    <PageTitle title="Training Lesson details page" />
                    <Lesson />
                  </>
                }
              />
              <Route
                path="/search/results/:query"
                element={
                  <>
                    <PageTitle title="Search Results page" />
                    <SearchResults />
                  </>
                }
              />
              <Route
                path="/search/suggestions"
                element={
                  <>
                    <PageTitle title="Search Suggestions page" />
                    <SearchResults />
                  </>
                }
              />
              <Route
                path="/email-verified/:id/:token"
                element={
                  <>
                    <PageTitle title="Email Verified page" />
                    <EmailVerified />
                  </>
                }
              />
              <Route
                path="/roadmap"
                element={
                  <>
                    <PageTitle title="Roadmap page" />
                    <RouteFeatureFlag
                      featureFlags={[FEATURE_FLAGS.ROADMAP]}
                      component={
                        <RoadmapPulseProvider>
                          <RoadmapIntro />
                        </RoadmapPulseProvider>
                      }
                    />
                  </>
                }
              />
              <Route
                path="/roadmap/north-star"
                element={
                  <>
                    <PageTitle title="Roadmap North Star page" />
                    <RouteFeatureFlag featureFlags={[FEATURE_FLAGS.ROADMAP_NORTH_STAR]} component={<NorthStarIndex />} />
                  </>
                }
              />
              <Route
                path="/roadmap/goal"
                element={
                  <>
                    <PageTitle title="Roadmap Goal page" />
                    <RouteFeatureFlag featureFlags={[FEATURE_FLAGS.ROADMAP_GOAL]} component={<GoalIndex />} />
                  </>
                }
              />
              <Route
                path="/roadmap/pulse"
                element={
                  <>
                    <PageTitle title="Roadmap Pulse page" />
                    <RouteFeatureFlag
                      featureFlags={[FEATURE_FLAGS.ROADMAP_PULSE]}
                      component={
                        <RoadmapPulseProvider>
                          <PulseIndex />
                        </RoadmapPulseProvider>
                      }
                    />
                  </>
                }
              />
              <Route
                path="/roadmap/pulse/:roadmapPulseId/result/"
                element={
                  <>
                    <PageTitle title="Roadmap Pulse Result page" />
                    <RouteFeatureFlag
                      featureFlags={[FEATURE_FLAGS.ROADMAP_PULSE, FEATURE_FLAGS.ROADMAP_PULSE_RESULT]}
                      component={
                        <RoadmapPulseProvider>
                          <PulseResult />
                        </RoadmapPulseProvider>
                      }
                    />
                  </>
                }
              />
              <Route
                path="/roadmap/pulse/:roadmapPulseId/result/:sectionId"
                element={
                  <>
                    <PageTitle title="Roadmap Pulse Result Section page" />
                    <RouteFeatureFlag
                      featureFlags={[FEATURE_FLAGS.ROADMAP_PULSE]}
                      component={
                        <RoadmapPulseProvider>
                          <PulseResultPlan />
                        </RoadmapPulseProvider>
                      }
                    />
                  </>
                }
              />
              <Route
                path="/roadmap/pulse/:roadmapPulseId/result/:sectionId/:subCategoryId"
                element={
                  <>
                    <PageTitle title="Roadmap Pulse Result Category details Content page" />
                    <RouteFeatureFlag
                      featureFlags={[FEATURE_FLAGS.ROADMAP_PULSE]}
                      component={
                        <RoadmapPulseProvider>
                          <PulseRecommendations />
                        </RoadmapPulseProvider>
                      }
                    />
                  </>
                }
              />
              <Route
                path="/roadmap/priorities"
                element={
                  <>
                    <PageTitle title="Roadmap Priorities page" />
                    <RouteFeatureFlag featureFlags={[FEATURE_FLAGS.ROADMAP_PRIORITIES]} component={<Priorities />} />
                  </>
                }
              />
              <Route
                path="/reset-password"
                element={
                  <>
                    <PageTitle title="Reset Password page" />
                    <ResetPassword />
                  </>
                }
              />
              <Route
                path="/login"
                element={
                  <>
                    <PageTitle title="Login page" />
                    <Navigate to="/" />
                  </>
                }
              />
              <Route
                path="/signup/1"
                element={
                  <>
                    <PageTitle title="Signup page" />
                    <Navigate to="/" />
                  </>
                }
              />
              <Route
                path="*"
                element={
                  <>
                    <PageTitle title="Not Found page" />
                    <NotFound />
                  </>
                }
              />
            </Routes>
          </ToastContextProvider>
          {Platform.OS !== 'web' && <NativeMobileNavbar />}
        </View>
      );
    } else if (userRole === 'admin') return <BackOffice />;
  }, [isShowingBanner]);

  const UnVerifiedStack = useMemo(
    () => (
      <Routes>
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/email-verified/:id/:token" element={<EmailVerified />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    ),
    []
  );

  const UnAuthStack = useMemo(
    () => (
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/login" element={<Login setIsSignedIn={setIsSignedIn} />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/signup/" element={<Navigate to="/signup/1" />} />
        <Route path="/signup/:stage" element={<SignUp />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/email-verified/:id/:token" element={<EmailVerified />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<RedirectToLogin />} />
      </Routes>
    ),
    []
  );

  const stack = useMemo(() => {
    if (isSignedIn) {
      if (!isVerified) {
        return (
          <>
            <Helmet>
              <title>TRN World</title>
            </Helmet>
            {UnVerifiedStack}
          </>
        );
      }
      return AuthStack();
    }
    return (
      <>
        <Helmet>
          <title>TRN World</title>
        </Helmet>
        {UnAuthStack}
      </>
    );
  }, [AuthStack, UnAuthStack, UnVerifiedStack, isSignedIn, isVerified]);

  // const handleDiscardAction = () => {
  //   setHasUpdated(0);
  //   navigation("/content");
  // };

  if (!fontsLoaded || !hasTriedAuth) {
    return (
      <View
        style={{
          height: Platform.OS === 'web' ? '100vh' : '100%',
          width: Platform.OS === 'web' ? '100vw' : '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  } else
    return (
      <>
        <Helmet />
        <SelectedUsersAssignModalContextProvider>
          <Frame>
            <Host>
              <View
                style={[
                  styles.popupBackGround,
                  {
                    ...(isPopUp && isSignedIn && { overflow: 'hidden', zIndex: -1 }),
                  },
                ]}
              >
                {/* Welcome Popup */}
                <View
                  style={[
                    styles.popupBackGround,
                    {
                      ...(isPopUp &&
                        isSignedIn &&
                        userRole === 'user' && { alignItems: 'center', backgroundColor: 'rgba(112, 116, 121, 0.78)', zIndex: 1 }),
                    },
                  ]}
                >
                  {isPopUp && isSignedIn && userRole === 'user' ? (
                    <WelcomePopup
                      props={{
                        setIsPopUp,
                        isPopUp,
                        isSignedIn,
                      }}
                    />
                  ) : null}
                </View>
                <UpdateContext.Provider
                  value={{
                    state: hasUpdated,
                    update: setHasUpdated,
                  }}
                >
                  {/* TODO - commented out as unclear why we have this */}
                  {/* {Platform.OS === "web" && !!hasUpdated && (
                      <div style={{ height: 0, overflow: "hidden" }}>
                        <Image
                          source={LogoWhite}
                          style={{
                            width: 165,
                            height: 27,
                            position: "absolute",
                            zIndex: 1000,
                            marginTop: 15,
                            marginLeft: 46,
                          }}
                        />
                        <Frame>
                          <ContextualSaveBar
                            fullWidth
                            message="Unsaved changes"
                            saveAction={{
                              onAction: () => setHasUpdated(2),
                              loading: false,
                              disabled: false,
                            }}
                            discardAction={{
                              onAction: handleDiscardAction,
                            }}
                          />
                        </Frame>
                        <Button
                          style={{
                            width: 64,
                            height: 36,
                            position: "absolute",
                            zIndex: 10000,
                          }}
                        >
                          Save
                        </Button>
                      </div>
                    )} */}
                  {isShowingBanner && (
                    <HilightBanner>
                      {hasPendingEmailVerification ? (
                        <Text>
                          You have a pending email address update to {pendingEmailVerification.email} - please click the verification link in your
                          inbox.
                        </Text>
                      ) : (
                        <Text>
                          You are exploring TRN World as a FREE member.{' '}
                          <Text
                            onPress={() => Linking.openURL('https://therecruitmentnetwork.com/membership/')}
                            style={{ textDecorationLine: 'underline' }}
                          >
                            Upgrade
                          </Text>{' '}
                          to a premium membership for exclusive content, insights, and access to our network of recruitment leaders.
                        </Text>
                      )}
                    </HilightBanner>
                  )}
                  <NavBarWrapper isShowingBanner={isShowingBanner}>
                    <>
                      {Platform.OS !== 'web' && <NativeNavbar isSignedIn={isSignedIn} isVerified={isVerified} />}
                      {Platform.OS === 'web' && (
                        <>
                          <NavbarComponent userRole={userRole} isSignedIn={isSignedIn} isVerified={isVerified} />
                          {isSignedIn && <MobileNavbar />}
                        </>
                      )}
                    </>
                  </NavBarWrapper>
                  {stack}
                </UpdateContext.Provider>
              </View>
            </Host>
          </Frame>
        </SelectedUsersAssignModalContextProvider>
      </>
    );
};
