import { CancelIcon } from '@components/general/icons/org-icons';
import { LargePlayIcon } from '@components/general/icons/training-icons';
import { ModalPosition, ModalWrapper } from '@components/general/modals/modal-wrapper';
import { useVideo } from '@hooks/useVideo';
import { AVPlaybackStatusSuccess, ResizeMode, Video } from 'expo-av';
import React, { useEffect, useRef, useState } from 'react';
import { Platform, Text, TouchableOpacity, View } from 'react-native';

import { styles } from './style';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  videoUri: string | null;
  title: string;
  subtitle: string;
}

const RoadmapTutorial = ({ isVisible, onClose, videoUri, title, subtitle }: Props) => {
  const video = useRef<Video>(null);
  const [status, setStatus] = useState<AVPlaybackStatusSuccess>();
  const { handleVideoAspectRatio, videoRatio } = useVideo();

  useEffect(() => {
    if (video.current) {
      video.current?.setVolumeAsync(0.1);
    }
  }, [video]);

  return (
    <ModalWrapper isVisible={isVisible} position={ModalPosition.center} contentStyle={styles.wrapper} onClose={onClose}>
      <View style={styles.titleWrap}>
        <View>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.subtitle}>{subtitle}</Text>
        </View>
        <TouchableOpacity onPress={onClose}>
          <CancelIcon />
        </TouchableOpacity>
      </View>
      {videoUri ? (
        <View style={styles.videoWrap}>
          <Video
            ref={video}
            style={styles.video}
            videoStyle={[styles.video, Platform.OS !== 'web' && { aspectRatio: videoRatio }]}
            source={{
              uri: videoUri,
            }}
            shouldPlay
            useNativeControls
            resizeMode={ResizeMode.CONTAIN}
            isLooping={false}
            onPlaybackStatusUpdate={(status) => setStatus(() => status as AVPlaybackStatusSuccess)}
            onReadyForDisplay={handleVideoAspectRatio}
          />
          {!status?.isPlaying ? (
            <TouchableOpacity
              style={styles.playIconWrap}
              onPress={() => (status?.isPlaying ? video.current?.pauseAsync() : video.current?.playAsync())}
            >
              <LargePlayIcon />
            </TouchableOpacity>
          ) : null}
        </View>
      ) : null}
    </ModalWrapper>
  );
};

export { RoadmapTutorial };
