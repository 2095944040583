import { TitleBox } from '@components/account/title-box';
import { Tab } from '@components/org/saved/tabs/Tab';
import { TabModule } from '@components/org/saved/tabs/TabModule';
import { ButtonLayout } from '@pages/account/profile/button-layout/ButtonLayout';
import { PageVariant } from '@utils/models';
import { Field, useFormikContext } from 'formik';
import { useState } from 'react';
import { Text, View } from 'react-native';

import { AccordionMenu } from './accordion-menu/AccordionMenu';
import { AccordionMenuOptions } from './accordion-menu/AccordionMenuOptions';
import { styles } from './styles';

interface Props {
  userBlockedAccess: any;
}

const BlockAccess = ({ userBlockedAccess }: Props) => {
  const [activeTab, setActiveTab] = useState('Content');
  const [accordionEditMode, setAccordionEditMode] = useState({
    marketing: false,
    newBusinessDevelopment: false,
    accountManagement: false,
    productsServices: false,
    candidates: false,
    people: false,
    systemsTechnology: false,
    operations: false,
    businessStrategyPlanning: false,
  });
  const { handleSubmit } = useFormikContext();
  const handleTabPress = (title: string) => {
    setActiveTab(title);
  };
  const toggleAccordionEditMode = (key: string) => {
    setAccordionEditMode((prevState) => ({
      ...prevState,
      // @ts-ignore FIXME: bad typing
      [key]: !prevState[key],
    }));
  };
  const onSubmit = (id: string) => {
    handleSubmit();
    toggleAccordionEditMode(id);
    close();
  };

  const tabOptions = [
    {
      title: 'Content',
      active: true,
    },
    {
      title: 'Events',
      active: false,
    },
    {
      title: 'Training',
      active: false,
    },
  ];

  const lengthValueForBlockedAccessCat = (obj: any, option: string) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const arrayLength = obj[key]?.length;
        if (key === option) {
          return arrayLength;
        }
      }
    }
  };

  return (
    <View style={styles.wrap}>
      <TitleBox
        title="Block Access"
        extraStyles={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      />
      <TabModule withTimeDropDown={false} variant={PageVariant.member}>
        {tabOptions.map((tab, index) => (
          <Tab key={index} title={tab.title} active={activeTab === tab.title} onPress={() => handleTabPress(tab.title)} />
        ))}
      </TabModule>
      <Text style={styles.subTitle}>Once you've blocked a category, the member can no longer see and access the content through TRN.</Text>
      {AccordionMenuOptions.map((option, index) => (
        <AccordionMenu
          title={option.title}
          key={option.id}
          index={index}
          lastIndex={AccordionMenuOptions.length - 1}
          blockedAccessCatLength={lengthValueForBlockedAccessCat(userBlockedAccess?.[activeTab.toLowerCase()], option.id) || 0}
        >
          {!(accordionEditMode as any)[option.id] ? (
            <View style={styles.accordionOption}>
              <View>
                {userBlockedAccess
                  ? Object.keys(userBlockedAccess?.[activeTab.toLowerCase()]).map((key) => {
                      if (key === option.id) {
                        return userBlockedAccess?.[activeTab.toLowerCase()][key]?.map((item: any) => (
                          <Text key={item} style={styles.accordionOptionText}>
                            {item}
                          </Text>
                        ));
                      }
                    })
                  : null}
              </View>
              <Text onPress={() => toggleAccordionEditMode(option.id)} style={styles.accordionEdit}>
                Edit
              </Text>
            </View>
          ) : (
            <View style={styles.accordionOptionEditWrap}>
              {option.options.map((blocked) => (
                <View key={blocked.name} style={styles.accordionOptionEditMenu}>
                  <Field name={'blockedAccess.' + activeTab.toLowerCase() + '.' + option.id} type="checkbox" value={blocked.name} />
                  <Text style={styles.accordionOptionEditMenuText}>{blocked.name}</Text>
                </View>
              ))}
              <View
                style={{
                  marginTop: 4,
                }}
              >
                <ButtonLayout submitCloseHander={() => onSubmit(option.id)} closeEditMode={() => toggleAccordionEditMode(option.id)} />
              </View>
            </View>
          )}
        </AccordionMenu>
      ))}
    </View>
  );
};

export { BlockAccess };
