import { ListItem } from '@components/account/list-item';
import { TitleBox } from '@components/account/title-box';
import { ContentBox } from '@components/general/layouts/content-box';
import { CompanyNameForm } from '@components/org/forms/company-name-form/CompanyNameForm';
import { MediaForm } from '@components/org/forms/media-form/MediaForm';
import { WebsiteForm } from '@components/org/forms/website-form/WebsiteForm';
import { useUserContext } from '@context/UserContext';
import { useEmployerGetOrganisationQuery, useUpdateOrganisationMutation } from '@gql/generated/generated';
import { AddressForm } from '@pages/account/profile/forms/address-form/AddressForm';
import { ContactDetailForm } from '@pages/account/profile/forms/contact-detail-form/ContactDetailForm';
import { Photoform } from '@pages/account/profile/forms/photo-form/Photoform';
import { urlToImageSource } from '@utils/urlToImageSource';
import { Formik, FormikValues } from 'formik';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Image, View } from 'react-native';
import { useNavigate } from 'react-router-native';

const ContactInfo = () => {
  const navigate = useNavigate();
  const { currentUser } = useUserContext();
  const [editMode, setEditMode] = useState({
    companyName: false,
    website: false,
    socialMedia: false,
    contactDetail: false,
    address: false,
    logo: false,
  });
  const [initialValues, setInitialValues] = useState<FormikValues>({});

  const closeEditMode = (key: string) => {
    setEditMode({ ...editMode, [key]: false });
  };

  const organisationId = currentUser?.accountInfo?.companyInfo.id;

  const { data: organisationData, loading: fetchingOrganisation } = useEmployerGetOrganisationQuery({
    skip: !organisationId,
  });

  const { organisationInfo } = organisationData?.employerGetOrganisation || {};

  const {
    companyName,
    website,
    phoneNumber,
    email,
    houseNumber,
    addressOne,
    addressTwo,
    postCode,
    city,
    country,
    orgLogoUrl,
    facebook,
    instagram,
    linkedin,
    twitter,
  } = organisationInfo || {};

  useEffect(() => {
    // TODO: get all data from backend request and set it as initial values
    setInitialValues({
      companyName: companyName || '',
      website: website || '',
      phoneNumber: phoneNumber || '',
      email: email || '',
      houseNumber: houseNumber || '',
      addressOne: addressOne || '',
      addressTwo: addressTwo || '',
      postCode: postCode || '',
      city: city || '',
      country: country || '',
      orgLogoUrl: orgLogoUrl || '',
      facebook: facebook || '',
      instagram: instagram || '',
      linkedin: linkedin || '',
      twitter: twitter || '',
    });
  }, [organisationData]);

  const [updateOrganisation, { loading: updateInProgress }] = useUpdateOrganisationMutation();

  const updateOrganisationHandler = async (values: FormikValues) => {
    await updateOrganisation({
      variables: {
        input: {
          ...values,
        },
      },
    });
  };

  const onSubmit = async (values: FormikValues) => {
    await updateOrganisationHandler(values);
  };

  if (fetchingOrganisation || updateInProgress) {
    return (
      <View>
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  }

  return (
    <Formik onSubmit={onSubmit} enableReinitialize initialValues={initialValues}>
      <ContentBox style={{ marginBottom: 20 }}>
        <TitleBox title="Contact Info" backTitle="Back To Settings" onPressBack={() => navigate(-1)} />
        {!editMode.companyName ? (
          <ListItem title="Company Name" edit="Edit" subtitle={companyName} onPress={() => setEditMode({ ...editMode, companyName: true })} />
        ) : (
          <CompanyNameForm closeEditMode={() => closeEditMode('companyName')} />
        )}
        {!editMode.website ? (
          <ListItem title="Website" edit="Edit" subtitle={website ?? ''} onPress={() => setEditMode({ ...editMode, website: true })} />
        ) : (
          <WebsiteForm closeEditMode={() => closeEditMode('website')} />
        )}
        {!editMode.socialMedia ? (
          <ListItem
            title="Social Media"
            edit="Edit"
            onPress={() => setEditMode({ ...editMode, socialMedia: true })}
            needMediaBlocked
            facebook={facebook || 'Not Set'}
            instagram={instagram || 'Not Set'}
            linkedin={linkedin || 'Not Set'}
            twitter={twitter || 'Not Set'}
          />
        ) : (
          <MediaForm closeEditMode={() => closeEditMode('socialMedia')} />
        )}
        {!editMode.contactDetail ? (
          <ListItem
            title="Contact Detail"
            edit="Edit"
            onPress={() => setEditMode({ ...editMode, contactDetail: true })}
            subtitle={`${!email && !phoneNumber ? 'Not Set' : `${email || ''} ${phoneNumber || ''}`}`}
          />
        ) : (
          <ContactDetailForm closeEditMode={() => closeEditMode('contactDetail')} />
        )}
        {!editMode.address ? (
          <ListItem
            title="Address"
            edit="Edit"
            onPress={() => setEditMode({ ...editMode, address: true })}
            subtitle={`${houseNumber ? `${houseNumber}, ` : ''}${addressOne ? `${addressOne}, ` : ''}${addressTwo ? `${addressTwo}, ` : ''}${
              postCode ? `${postCode}, ` : ''
            }${city ? `${city}, ` : ''}${country || ''}`}
          />
        ) : (
          <AddressForm closeEditMode={() => closeEditMode('address')} />
        )}
        {!editMode.logo ? (
          <>
            {' '}
            <ListItem title="Logo" edit="Edit" onPress={() => setEditMode({ ...editMode, logo: true })} />
            <Image
              source={urlToImageSource(orgLogoUrl)}
              style={{
                width: 120,
                height: 120,
                marginLeft: 40,
                marginBottom: 40,
                borderRadius: 60,
              }}
            />
          </>
        ) : (
          <Photoform closeEditMode={() => closeEditMode('logo')} fieldName="orgLogoUrl" />
        )}
      </ContentBox>
    </Formik>
  );
};

export { ContactInfo };
