import { ActionList, Button, Popover, Icon } from '@shopify/polaris';
import { ActionListItemDescriptor } from '@shopify/polaris/build/ts/latest/src/types';
import { HorizontalDotsMinor } from '@shopify/polaris-icons';
import React, { useState } from 'react';
import { View } from 'react-native';

import ResetButton from '../table-header/ResetButton';

export interface IDropdownItem {
  content?: string | null;
  prefix?: React.ReactNode | null;
  suffix?: React.ReactNode | null;
  onAction?: (() => void) | null;
  id: string;
}

interface Props {
  items: IDropdownItem[];
  placeholder?: string | null;
  onSelectItem?: (id: string) => void;
  disableDisclosure?: boolean;
  paddingHorizontal?: number;
  minWidth?: number;
  needFluidContent?: boolean;
  textLeft?: boolean;
  ellipsisIcon?: boolean;
  onReset?: () => void;
  disabled?: boolean;
  isSelected?: boolean;
}

// TODO rebuild this component so that <ActionList> dropdown van use TouchableOpacityLink
const TableDropdown = ({
  items,
  placeholder,
  onSelectItem,
  disableDisclosure,
  paddingHorizontal = 0,
  minWidth,
  needFluidContent = false,
  textLeft = false,
  ellipsisIcon = false,
  onReset,
  disabled,
  isSelected,
}: Props) => {
  const [isActive, setIsActive] = useState(false);

  const newItems: ActionListItemDescriptor[] = items.map(
    (item): ActionListItemDescriptor => ({
      id: item.id,
      content: item.content ?? undefined,
      suffix: item.suffix,
      prefix: item.prefix,
      onAction: () => {
        onSelectItem?.(item.id);
        setIsActive(!isActive);
      },
    })
  );

  return (
    <View
      style={{
        height: 36,
      }}
    >
      <Popover
        fluidContent={needFluidContent}
        fullWidth={!needFluidContent}
        active={isActive}
        preferredAlignment="left"
        activator={
          <View style={{ paddingHorizontal, minWidth }}>
            <Button
              fullWidth
              disabled={disabled}
              textAlign={textLeft ? 'left' : 'center'}
              disclosure={disableDisclosure ? undefined : isActive ? 'up' : 'down'}
              onClick={() => {
                // close any active popovers
                document.body.click();
                setIsActive(!isActive);
              }}
              icon={ellipsisIcon ? <Icon source={HorizontalDotsMinor} color="base" /> : undefined}
              monochrome={!!isSelected}
            >
              {placeholder ?? undefined}
            </Button>
          </View>
        }
        autofocusTarget="first-node"
        onClose={() => setIsActive(!isActive)}
      >
        <ResetButton onReset={onReset} />
        <ActionList actionRole="menuitem" items={newItems} />
      </Popover>
    </View>
  );
};

export { TableDropdown };
