import { Image } from '@components/general/image-from-storage';
import { INITIALS_CIRCLE_BACKGROUND_COLORS, InitialsCircle } from '@components/general/initials-circle';
import { textStyles } from '@styles/text';
import { useMemo } from 'react';
import { View, Text } from 'react-native';

export const AvatarWithName = ({
  imagePath,
  imageSrc,
  fontSize,
  size,
  name,
  isSubdued = false,
  gap,
  i,
}: {
  imagePath?: string | null;
  imageSrc?: string | null;
  size?: number;
  fontSize?: number;
  gap?: number;
  isSubdued?: boolean;
  name: string;
  i: number;
}) => {
  const image = useMemo(() => {
    if (!imagePath && !imageSrc) {
      const initials = name
        .split(' ')
        .map((word) => word[0]?.toUpperCase())
        .join('')
        .slice(0, 2);

      return (
        <InitialsCircle size={size ?? 40} fontSize={fontSize ?? 20} initials={initials} backgroundColor={INITIALS_CIRCLE_BACKGROUND_COLORS[i % 6]} />
      );
    }
    return <Image path={imagePath} imageUrl={imageSrc} style={{ width: 40, height: 40, borderRadius: 20 }} />;
  }, [imagePath, imageSrc, name, i]);

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: gap ?? 10,
      }}
    >
      {image}
      <Text style={isSubdued ? textStyles.subduedTextSmall : textStyles.semiBoldSmall}>{name}</Text>
    </View>
  );
};
