import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  container: {
    flex: 1,
  },
  wrap: {
    width: '100%',
    maxWidth: 586,
  },
  postItem: {
    marginBottom: 20,
  },
  flatList: {
    paddingTop: 10,
    paddingBottom: 150,
  },
});
