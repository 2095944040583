import { Col } from '@components/general/col';
import { ChoiceList } from '@shopify/polaris';
import { textStyles } from '@styles/text';
import { getIn, useFormikContext } from 'formik';
import { Text } from 'react-native';

interface LessonType {
  formikFieldBase: string;
}

const LessonTypeSelect = ({ formikFieldBase }: LessonType) => {
  const { setFieldValue, values, getFieldMeta } = useFormikContext<any>();

  const fieldName = `${formikFieldBase}.lessonType`;

  const handleLessonTypeSelect = (value: string[]) => {
    setFieldValue(fieldName, value.includes('video') ? 'video' : 'document');
  };

  const cv = getIn(values, fieldName);

  const meta = getFieldMeta(fieldName);

  return (
    <Col>
      <Text style={[textStyles.semiBoldSmall, { marginBottom: 8 }]}>Lesson Type:</Text>
      <ChoiceList
        title="Lesson Type"
        choices={[
          { label: 'Video', value: 'video' },
          { label: 'Document', value: 'document' },
        ]}
        titleHidden
        selected={[cv]}
        onChange={handleLessonTypeSelect}
      />
      {meta.error && meta.touched ? <Text style={textStyles.error}>{meta.error}</Text> : null}
    </Col>
  );
};

export { LessonTypeSelect };
