import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  spaceBetween: {
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  saveButtonText: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    color: '#202223',
    lineHeight: 20,
  },
  title: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 26,
    lineHeight: 32,
    marginBottom: 12,
    color: '#0D1738',
  },
  eventTypeText: {
    fontFamily: 'OpenSans_400Regular',
    color: '#0D1738',
    fontSize: 14,
    lineHeight: 20,
  },
  plusIcon: {
    width: 12,
    height: 12,
    marginRight: 8,
  },
  checkIcon: {
    color: '#3d75c7',
  },
  subduedText: {
    fontFamily: 'OpenSans_400Regular',
    color: '#6D7175',
    fontSize: 12,
    lineHeight: 16,
  },
  contentBox: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  dateText: {
    fontFamily: 'OpenSans_600SemiBold',
    color: '#2C6ECB',
    fontSize: 14,
    lineHeight: 20,
  },
  linkText: {
    fontFamily: 'OpenSans_400Regular',
    color: '#2C6ECB',
    fontSize: 14,
    lineHeight: 20,
    marginLeft: 8,
  },
  marginRight: {
    marginRight: 8,
  },
});
