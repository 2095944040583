import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const LockIcon = (props: SvgProps) => (
  <Svg width={12} height={16} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 6V5c0-2.757-2.243-5-5-5S1 2.243 1 5v1a1 1 0 0 0-1 1v7.5A1.5 1.5 0 0 0 1.5 16h9a1.5 1.5 0 0 0 1.5-1.5V7a1 1 0 0 0-1-1Zm-6 7h2V9H5v4Zm4-7H3V5c0-1.654 1.346-3 3-3s3 1.346 3 3v1Z"
      fill={props.color || '#0D1738'}
    />
  </Svg>
);

export { LockIcon };
