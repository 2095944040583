import { RegisterModal } from '@components/events/modals/register-modal';
import { SuccessModal } from '@components/events/modals/success-modal';
import { ButtonGeneral, ButtonVariant } from '@components/general/button-general';
import { DownloadIcon } from '@components/general/icons';
import { ClockIcon, LocationPinIcon } from '@components/general/icons/event-icons';
import { FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon } from '@components/general/icons/social-icons';
import { IndexPageHeader } from '@components/general/index-page-header';
import { InitialsCircle } from '@components/general/initials-circle';
import { Wrapper } from '@components/general/layouts/wrapper/Wrapper';
import { IListModalItem, ListModal } from '@components/general/modals/list-modal';
import { Pill, PillVariant } from '@components/general/pill';
import { SideListBox } from '@components/general/side-list-box';
import { useGetAllEventsQuery, useGetEventQuery } from '@gql/generated/generated';
import { useGetFile } from '@hooks/useGetFileUrl';
import { useIndexPageDetail } from '@hooks/useIndexPageDetail';
import { getInitials } from '@utils/misc';
import { ModalContent } from '@utils/models';
import React, { useState } from 'react';
import { Dimensions, ImageBackground, Linking, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { RenderHTML } from 'react-native-render-html';
import { useLocation, useNavigate, useParams } from 'react-router-native';

import { ids, styles } from './style';

enum SuccessModalContent {
  closed,
  savedEvent,
  addedEvent,
}

interface SpeakerSocialProps {
  icon: React.ReactNode;
  link: string;
}

const SpeakerSocial = ({ icon, link }: SpeakerSocialProps) => (
  <TouchableOpacity onPress={() => Linking.openURL(link)} style={styles.socialIconTouch}>
    {icon}
  </TouchableOpacity>
);

interface EventButtonProps {
  icon?: React.ReactNode;
  title: string;
  onPress?: () => void;
}

const EventButton = ({ icon, title, onPress }: EventButtonProps) => (
  <ButtonGeneral variant={ButtonVariant.Secondary} onPress={onPress} hoverOutline="#4490FF">
    {icon ? <View style={{ marginRight: 6 }}>{icon}</View> : null}
    <Text style={styles.saveButtonText}>{title}</Text>
  </ButtonGeneral>
);

// TODO: Native mobile events page
export const EventNative = () => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // TODO: Get event by slug so we can have nicer urls  (e.g. /events/this-is-the-event-name instead of /events/1)
  const { data } = useGetEventQuery({
    variables: { id },
  });
  const eventData = data?.getEvent.__typename === 'Event' ? data.getEvent : null;

  const { data: allEventsData } = useGetAllEventsQuery({
    variables: {
      page: 1,
      limit: 6,
      filters: {
        status: 'Publish',
      },
    },
    skip: !eventData?.categories,
  });
  const events = allEventsData?.getAllEvents?.events;

  const [successModalType, setSuccessModalType] = useState(SuccessModalContent.closed);
  const [isAddToCalendarVisible, setIsAddToCalendarVisible] = useState(false);
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);

  const { listModalType, setListModalType, handleItemsSelect, handleListModalItems } = useIndexPageDetail();

  const handleListModalItemsSelect = async (item: IListModalItem, index: number) => {
    switch (listModalType) {
      case ModalContent.save:
        setSuccessModalType(SuccessModalContent.savedEvent);
        break;
      default:
        await handleItemsSelect(item, index);
        break;
    }
  };

  const { fileUrl } = useGetFile(eventData?.coverImage?.storagePath);

  return (
    <Wrapper style={styles.wrapper} dataSetMedia={ids.wrapper}>
      <IndexPageHeader
        title="Events"
        onPressBack={() => navigate(-1)}
        onSave={() => setListModalType(ModalContent.save)}
        onShare={() => setListModalType(ModalContent.share)}
      />
      <ScrollView contentContainerStyle={styles.scrollContent}>
        <>
          <ImageBackground source={{ uri: fileUrl }} style={styles.coverImage} imageStyle={styles.coverImageStyle}>
            <View style={styles.coverImagePill}>
              <Pill variant={PillVariant.LightBlue} text={eventData && eventData.userType?.toString()} />
            </View>
          </ImageBackground>
          <View style={{ marginBottom: 24, marginTop: 16 }}>
            <Text style={styles.eventTitle}>{eventData && eventData.title}</Text>
            {eventData && eventData.organizer ? (
              <View style={styles.eventItems}>
                <InitialsCircle size={20} fontSize={10} initials={getInitials(eventData.organizer)} />
                <Text style={styles.eventItemText}>{eventData.organizer}</Text>
              </View>
            ) : null}
            {eventData && eventData.dateFrom ? (
              <View style={styles.eventItems}>
                <ClockIcon />
                <Text style={styles.eventDateText}>
                  {new Date(eventData.dateFrom).toLocaleString()} - {eventData.dateTo ? new Date(eventData.dateTo).toLocaleString() : null}
                </Text>
              </View>
            ) : null}
            {eventData && eventData.location ? (
              <View style={styles.eventItems}>
                <LocationPinIcon />
                <Text style={styles.locationText}>{eventData.location}</Text>
              </View>
            ) : null}
          </View>
          {eventData && eventData.description ? (
            <RenderHTML source={{ html: eventData.description }} contentWidth={Dimensions.get('window').width} />
          ) : null}
          <View style={styles.buttonWrap}>
            <EventButton title="Download Resources" icon={<DownloadIcon />} />
          </View>
          {eventData && eventData.jobRoles ? (
            <View style={styles.jobRoleWrap}>
              <Text style={styles.jobRoleText}>Job role:</Text>
              {eventData.jobRoles.map((role: string) => (
                <View style={styles.roleItem}>
                  <Pill text={role} variant={PillVariant.Light} withMarginRight />
                </View>
              ))}
            </View>
          ) : null}
          {eventData && eventData.speakers ? (
            <View style={styles.aboutSpeakerWrap}>
              <Text style={styles.aboutSpeakerTitle}>About the Speaker</Text>
              {eventData.speakers.map((speaker) => (
                <View>
                  <View style={styles.eventItems}>
                    <InitialsCircle initials={getInitials(speaker?.name || '')} backgroundColor="#FFC96B" size={40} />
                    <Text style={[styles.description, styles.speakerName]}>{speaker.name}</Text>
                  </View>
                  <Text style={[styles.description, styles.speakerDescription]}>{speaker.description}</Text>
                  <View style={styles.eventItems}>
                    <Text style={styles.followSpeakerText}>Follow on:</Text>
                    {speaker.socials?.facebook ? <SpeakerSocial icon={<FacebookIcon />} link={speaker.socials.facebook} /> : null}
                    {speaker.socials?.instagram ? <SpeakerSocial icon={<InstagramIcon />} link={speaker.socials.instagram} /> : null}
                    {speaker.socials?.twitter ? <SpeakerSocial icon={<TwitterIcon />} link={speaker.socials.twitter} /> : null}
                    {speaker.socials?.linkedin ? <SpeakerSocial icon={<LinkedInIcon />} link={speaker.socials.linkedin} /> : null}
                  </View>
                </View>
              ))}
            </View>
          ) : null}
          <View>
            {events && (
              <SideListBox
                linkText="View all events"
                onPressLink={() => navigate(-1)}
                title="Related Events"
                items={events.map(({ coverImage, title, dateFrom, id: eventID, location }) => ({
                  image: coverImage,
                  title,
                  date: dateFrom,
                  linkUrl: `/events/${eventID}`,
                  onPress: () => navigate(`/events/${eventID}`),
                  bottomRow: <Text style={styles.relatedEventLocation}>{location}</Text>,
                }))}
              />
            )}
          </View>
        </>
      </ScrollView>
      <View style={styles.registerBottom}>
        <ButtonGeneral label="Register" onPress={() => setIsRegisterModalVisible(true)} />
      </View>
      <ListModal
        isVisible={listModalType !== ModalContent.closed}
        items={handleListModalItems(pathname)}
        onClose={() => setListModalType(ModalContent.closed)}
        onSelectItem={handleListModalItemsSelect}
      />
      <SuccessModal
        isVisible={successModalType !== SuccessModalContent.closed}
        onClose={() => setSuccessModalType(SuccessModalContent.closed)}
        title={successModalType === SuccessModalContent.savedEvent ? 'Saved!' : 'Event added!'}
        description="The event has been added to your Apple Calendar"
      >
        {successModalType === SuccessModalContent.savedEvent && (
          <View style={styles.savedEventWrap}>
            <Text style={[styles.description, styles.savedEventDescription]}>The event has been saved in your&nbsp;</Text>
            <TouchableOpacity>
              <Text style={[styles.description, styles.savedTouch]}>Saved Items</Text>
            </TouchableOpacity>
          </View>
        )}
      </SuccessModal>
      <RegisterModal
        isVisible={isRegisterModalVisible}
        onClose={() => setIsRegisterModalVisible(false)}
        calendlyLink={eventData?.calendlyLink || undefined}
      />
    </Wrapper>
  );
};
