import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  content: {
    padding: 20,
    maxWidth: 278,
    minHeight: 130,
  },
  contentTitle: {
    color: '#6D7175',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    marginBottom: 8,
  },
  countWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  count: {
    color: '#0D1738',
    fontSize: 26,
    fontFamily: 'OpenSans_600SemiBold',
  },
  countInfo: {
    color: '#6D7175',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    marginLeft: 8,
  },
  percentWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  percentText: {
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
    marginLeft: 10,
  },
  percentInfo: {
    color: '#6D7175',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
  },
  activityIndicator: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
});
