import { NotificationSwitch } from '@components/account/notification-switch';
import { TitleBox } from '@components/account/title-box';
import { ContentBox } from '@components/general/layouts/content-box';
import { useGetUserSettingsQuery, useUpdateUserSettingsMutation } from '@gql/generated/generated';
import { ButtonLayout } from '@pages/account/profile/button-layout/ButtonLayout';
import React, { useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { useNavigate } from 'react-router-native';

const ManageNotifications = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [userSettings, setUserSettings] = useState<any>(null);
  const [saveUserSettingsMutation, _] = useUpdateUserSettingsMutation();
  const navigate = useNavigate();

  const { data, refetch } = useGetUserSettingsQuery({
    pollInterval: 0, // Explicitly do not re-fetch
  });

  useEffect(() => {
    if (data?.getUserSettings?.__typename === 'settings' && loading === true && saving === false) {
      setUserSettings(data.getUserSettings);
      setLoading(false);
    }
  }, [data, loading, saving]);

  useEffect(() => {
    if (data?.getUserSettings?.__typename === 'settings') {
      setSaving(false);
    }
  }, [data]);

  const saveUserSettings = () => {
    if (!userSettings) return;

    setSaving(true);
    setLoading(true);

    // Need to remove typename before submitting.
    const { __typename: omitted, ...emailNotif } = userSettings.emailNotif;
    const { __typename: omitted2, ...platformNotif } = userSettings.platformNotif;

    saveUserSettingsMutation({
      variables: {
        input: {
          emailNotif,
          platformNotif,
        },
      },
    })
      .then((data) => {
        if (data.data?.updateUserSettings?.settings?.__typename == 'settings') {
          refetch();
        } else {
          throw new Error('Error saving settings.');
        }
      })
      .catch((_err) => {
        // TODO: Log err to sentry.
        refetch();
      });
  };

  return (
    <>
      <View
        style={{
          flex: 1,
        }}
      >
        <ContentBox>
          <TitleBox
            title="Manage Notification Settings"
            backTitle="Back to Notifications"
            onPressBack={() => navigate('/account/notification-settings')}
          />
          <View
            style={{
              width: '100%',
              paddingHorizontal: 40,
              paddingVertical: 24,
            }}
          >
            {loading ? (
              <Text
                style={{
                  fontFamily: 'OpenSans_600SemiBold',
                  fontSize: 14,
                  lineHeight: 20,
                }}
              >
                Loading notification settings...
              </Text>
            ) : (
              <>
                <ButtonLayout
                  closeEditMode={() => {
                    refetch();
                  }}
                  submitCloseHander={saveUserSettings}
                  labelCancelButton="Reset"
                  isSubmitting={saving}
                />
                <Text
                  style={{
                    fontFamily: 'OpenSans_600SemiBold',
                    fontSize: 14,
                    lineHeight: 20,
                  }}
                >
                  In-App Notifications
                </Text>

                <NotificationSwitch
                  title="New content"
                  isActive={!!userSettings?.platformNotif?.newContent}
                  onValueChange={(value) => {
                    setUserSettings({
                      ...(userSettings || {}),
                      platformNotif: {
                        ...(userSettings.platformNotif || {}),
                        newContent: value,
                      },
                    });
                  }}
                />
                <NotificationSwitch
                  title="New events"
                  isActive={!!userSettings?.platformNotif?.newEvents}
                  onValueChange={(value) => {
                    setUserSettings({
                      ...(userSettings || {}),
                      platformNotif: {
                        ...(userSettings.platformNotif || {}),
                        newEvents: value,
                      },
                    });
                  }}
                />
                <NotificationSwitch
                  title="New training"
                  isActive={!!userSettings?.platformNotif?.newTraining}
                  onValueChange={(value) => {
                    setUserSettings({
                      ...(userSettings || {}),
                      platformNotif: {
                        ...(userSettings.platformNotif || {}),
                        newTraining: value,
                      },
                    });
                  }}
                />

                <Text
                  style={{
                    marginTop: 24,
                    fontFamily: 'OpenSans_600SemiBold',
                    fontSize: 14,
                    lineHeight: 20,
                  }}
                >
                  Email Notifications
                </Text>

                <NotificationSwitch
                  title="New content"
                  isActive={!!userSettings?.emailNotif?.newContent}
                  onValueChange={(value) => {
                    setUserSettings({
                      ...(userSettings || {}),
                      emailNotif: {
                        ...(userSettings.emailNotif || {}),
                        newContent: value,
                      },
                    });
                  }}
                />
                <NotificationSwitch
                  title="New events"
                  isActive={!!userSettings?.emailNotif?.newEvents}
                  onValueChange={(value) => {
                    setUserSettings({
                      ...(userSettings || {}),
                      emailNotif: {
                        ...(userSettings.emailNotif || {}),
                        newEvents: value,
                      },
                    });
                  }}
                />
                <NotificationSwitch
                  title="New training"
                  isActive={!!userSettings?.emailNotif?.newTraining}
                  onValueChange={(value) => {
                    setUserSettings({
                      ...(userSettings || {}),
                      emailNotif: {
                        ...(userSettings.emailNotif || {}),
                        newTraining: value,
                      },
                    });
                  }}
                />
              </>
            )}
          </View>
        </ContentBox>
      </View>
    </>
  );
};

export { ManageNotifications };
