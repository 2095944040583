import { gql, useLazyQuery } from "@apollo/client";

const AUTOCOMPLETE_CITY = gql`
  query autocompleteLocation($query: String!, $type: String!) {
    autocompleteLocation(query: $query, type: $type) {
      placeId
      description
      name
      postalCode
    }
  }
`;

export function useAutocompleteLocation() {
  return useLazyQuery(AUTOCOMPLETE_CITY);
}
