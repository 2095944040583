import { ContentBox } from '@components/general/layouts/content-box';
import { Comments } from '@components/home/post/comment/comments/Comments';
import { PostBody } from '@components/home/post/post-body';
import { PostFooter } from '@components/home/post/post-footer';
import { PostHead } from '@components/home/post/post-head';
import { CollectionName, Comment, ContentType, DocumentAsset } from '@gql/generated/generated';
import { useState } from 'react';
import { View } from 'react-native';

import { styles } from './style';

export interface Props {
  id: string;
  name: string;
  date: string;
  postType: string;
  caption: string;
  image?: DocumentAsset | null;
  totalRatings: number;
  postRating: number;
  totalShares: number;
  comments: Comment[];
  userRating?: number | null;
  avatar?: string;
  isCommented?: boolean;
  totalComments: number;
}

const Post = ({
  id,
  name,
  date,
  postType,
  postRating,
  caption,
  image,
  totalShares,
  comments,
  totalRatings,
  userRating,
  avatar,
  isCommented,
  totalComments,
}: Props) => {
  const [isCommentsVisible, setIsCommentsVisible] = useState(false);
  return (
    <View style={styles.wrap}>
      <ContentBox>
        <PostHead avatar={avatar} name={name} date={date} postType={postType} />
        <PostBody caption={caption} asset={image} />

        <PostFooter
          isRated={!!userRating}
          userRating={userRating}
          totalRatings={totalRatings}
          itemId={id}
          collectionName={CollectionName.POSTS}
          itemType={ContentType.POST}
          postRating={postRating}
          totalComments={totalComments}
          totalShares={totalShares}
          onPressComment={() => setIsCommentsVisible(!isCommentsVisible)}
          isCommented={isCommented}
          postCaption={caption}
        />
        {isCommentsVisible && (
          <Comments
            comments={comments}
            commentedItemType={ContentType.POST}
            collectionName={CollectionName.POSTS}
            commentedItemId={id}
            totalComments={totalComments}
          />
        )}
      </ContentBox>
    </View>
  );
};

export { Post };
