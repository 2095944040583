import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  pill: {
    borderRadius: 35,
    ...(Platform.OS === 'web' && { height: 20 }),
    paddingVertical: 2,
    paddingHorizontal: 12,
    justifyContent: 'center',
  },
  pillSmall: {
    paddingVertical: 2,
    paddingHorizontal: 8,
  },
  darkGrey: { backgroundColor: '#E4E5E7' },
  lightGrey: { backgroundColor: '#EBEDF0' },
  greyBlue: { backgroundColor: '#828EB0' },
  darkBlue: { backgroundColor: '#0D1738' },
  gold: { backgroundColor: '#B98900' },
  lightBlue: { backgroundColor: '#2C6ECB' },
  green: { backgroundColor: '#23827F' },
  red: { backgroundColor: '#D72C0D' },
  withMarginRight: {
    marginRight: 4,
  },
  surfaceSuccess: {
    backgroundColor: '#AEE9D1',
  },
  highlight: { backgroundColor: '#2C6ECB' },
  text: {
    color: '#202223',
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    lineHeight: 16,
    width: '100%',
  },
  lightText: { color: '#FFFFFF' },
  outline: {
    backgroundColor: 'transparent',
    borderColor: '#485057',
    borderWidth: 1,
  },
  slim: {
    backgroundColor: '#EBEDF0',
    paddingHorizontal: 8,
    paddingVertical: 0,
  },
  slimText: {
    backgroundColor: '#EBEDF0',
    fontSize: 9,
    height: 20,
    justifyContent: 'center',
  },
  allowShrink: {
    flexShrink: 1,
  },
});
