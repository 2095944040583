import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { styles } from './style';

interface Props {
  children: React.ReactNode;
  disableShadow?: boolean;
  style?: StyleProp<ViewStyle>;
  dataSetMedia?: string;
  testId?: string;
}

const ContentBox = ({ children, disableShadow, style, dataSetMedia, testId }: Props) => (
  <View style={[styles.wrap, !disableShadow && styles.shadow, style && style]} testID={testId || ''} dataSet={{ media: dataSetMedia || '' }}>
    {children}
  </View>
);

export { ContentBox };
