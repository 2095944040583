import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { ISvgProps } from '../../../types';

const FacebookIconDark = (props: ISvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none" {...props}>
    <Path
      fill="#5C5F62"
      d="M11.954 24.053h.01-.01zm.085 0h-.004.01-.006zm-.121 0h.011-.012zm.16 0h-.007.011-.004zm-.196 0h.008-.008zm.234 0h-.006.008-.002zm.039 0h-.003.01-.007zm-.318 0h.01-.01zm.357-.001h-.007.013-.006zm-.394 0h.013-.013zm.432-.001h-.008.012-.004zm-.468 0h.012-.012zm-.044-.001h.01-.01zm.55 0h.01-.01zm-.588 0h.014-.015zm.627 0h-.005.014-.008zm.04-.002h-.01.014-.005zm-.703 0h.015-.015zm-.033 0h.01-.01l-.009-.001h.009zm.774 0h-.01.01l.009-.001h-.01zm.038-.002h-.005.016-.01zm-.861 0h.016-.016zm-.035-.002h.017-.017zm.935 0h-.01.017-.007zm-.97-.001h.017-.017zm1.008 0h-.013.017-.004zm-1.056-.002h.018-.018zm1.094 0h-.004.018-.014zm-1.128-.002h.018-.019zm1.167 0h-.009.018-.01zm-1.202-.002.019.001h-.019zm1.24 0-.013.001h.019-.006zm.038-.001h-.016.014l.018-.001h-.015zm-1.308 0h.014-.017l-.015-.001h.018zm1.347-.003h-.008.02-.012zm-1.4 0h.02-.019zm-.033-.002h.019-.019zm1.471 0h-.012.02-.008zm-1.503-.002h.017-.016l-.016-.001h.015zm1.541 0h-.016.017l.015-.001h-.016zm.038-.003h-.005.018-.013zm-1.629 0h.018-.018zm-.034-.003.018.001h-.018zm1.702 0-.01.001h.018-.008zm-1.735-.002.016.001h-.013l-.003-.001zm1.773 0-.013.001.016-.001h-.003zm-1.819-.004.015.001h-.014zm1.857.001h-.004.015-.011zm.038-.003h-.007.014-.007zm-1.93 0h.015-.015zm-.034-.003h.013-.012zm2.002 0h-.009.012H13zm-2.039-.003h.004-.004zm2.077 0h.003-.003zm.038-.003h-.004.009-.005zm-2.155 0h.009-.009zm-.035-.004h.006-.006zm2.228 0h-.004.006-.002zm-2.988-.095C4.391 23.007 0 18.038 0 12.053c0-6.623 5.377-12 12-12s12 5.377 12 12c0 5.985-4.391 10.953-10.125 11.854v-8.385h2.796l.532-3.469h-3.328v-2.25c0-.95.465-1.875 1.956-1.875h1.513V4.975s-1.374-.234-2.686-.234c-2.742 0-4.533 1.661-4.533 4.668v2.644H7.078v3.469h3.047v8.385z"
    />
  </Svg>
);

export { FacebookIconDark };
