import { DocumentAsset } from '@gql/generated/generated';
import { getDownloadURL, ref } from 'firebase/storage';
import { useCallback, useEffect, useState } from 'react';

import { storage } from '../firebase';

export const useGetFiles = (files?: DocumentAsset[] | null) => {
  const [filesWithUrl, setFilesWithUrl] = useState<(DocumentAsset & { downloadUrl: string })[]>();

  const getFiles = useCallback(async (files: DocumentAsset[]) => {
    try {
      const updatedFiles: (DocumentAsset & { downloadUrl: string })[] = [];

      for (const file of files) {
        const downloadUrl = await getDownloadURL(ref(storage, file.storagePath));
        updatedFiles.push({ ...file, downloadUrl });
      }

      setFilesWithUrl(updatedFiles);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (!files) return;
    getFiles(files);
  }, [files, getFiles]);

  return { filesWithUrl };
};
