import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const PlayIcon = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16ZM6.4 5.49v5.02a.4.4 0 0 0 .598.348l4.394-2.51a.4.4 0 0 0 0-.695L6.998 5.142a.4.4 0 0 0-.598.347Z"
      fill="#5C5F62"
    />
  </Svg>
);

export { PlayIcon };
