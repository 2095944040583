import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  head: {
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
    alignItems: 'center',
  },
  image: {
    height: 103,
    width: 103,
    borderRadius: 51,
    borderWidth: 1,
    borderColor: '#DFE4EA',
  },
  memberBox: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    marginTop: -10,
    marginBottom: 16,
    backgroundColor: '#2C6ECB',
    borderRadius: 15,
  },
  memberText: {
    color: '#FFFFFF',
  },
  boldText: {
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
    color: '#202223',
  },
  boldLink: {
    fontSize: 12,
    fontFamily: 'OpenSans_600SemiBold',
  },
  normalText: {
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
    color: '#6D7175',
  },
  name: {
    marginBottom: 4,
    textAlign: 'center',
  },
  menuItemWrap: {
    borderTopWidth: 1,
    borderTopColor: '#EDEEEF',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
  },
  menuTitleWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 4,
  },
  linkText: {
    color: '#2C6ECB',
  },
  underlineLink: {
    textDecorationLine: 'underline',
  },
  navItem: {
    borderTopWidth: 1,
    borderTopColor: '#EDEEEF',
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  navItemTitle: {
    fontSize: 14,
    color: '#202223',
    fontFamily: 'OpenSans_400Regular',
    marginLeft: 10,
  },
  assignmentIcon: {
    marginLeft: -2,
    marginRight: -2,
  },
});
