import { ContentBox } from '@components/general/layouts/content-box';
import { Wrapper } from '@components/general/layouts/wrapper/Wrapper';
import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { ids, styles } from './style';

interface Props {
  title: string;
  children: React.ReactNode;
  quarterSelector: JSX.Element;
  categoryButton: JSX.Element;
}

const PulsePrioritiesWrapper = ({ title, children, quarterSelector, categoryButton }: Props) => {
  const navigate = useNavigate();

  return (
    <Wrapper style={styles.wrapper}>
      <ContentBox style={styles.contentBox} dataSetMedia={ids.contentBox}>
        <TouchableOpacity onPress={() => navigate(-1)}>
          <Text style={styles.backText}>{`< Back`}</Text>
        </TouchableOpacity>
        <Text style={styles.title}>{title}</Text>
        <View style={styles.headingWrap} dataSet={{ media: ids.headingWrap }}>
          <View style={styles.selectWrap}>{quarterSelector}</View>
          <View style={styles.selectWrap}>{categoryButton}</View>
        </View>
        {children}
      </ContentBox>
    </Wrapper>
  );
};

export { PulsePrioritiesWrapper };
