import 'setimmediate';
import enPolarisTranslations from '@shopify/polaris/locales/en.json';
import { PostHogProvider } from 'posthog-js/react';
import { Helmet } from 'react-helmet';
import { AppRegistry } from 'react-native';
import { BrowserRouter } from 'react-router-dom';

import { Index } from './src';

import '@shopify/polaris/build/esm/styles.css';
import { UserContextProvider } from '@context/UserContext';
import { ApolloProvider } from '@apollo/client';

import { useCreateApolloClient } from './src/apolloClient';

import { AppProvider } from '@shopify/polaris';
import { POSTHOG_HOST, POSTHOG_PROXY_HOST, POSTHOG_API_KEY } from '@env';

export function App() {
  const apolloClient = useCreateApolloClient();

  return (
    <PostHogProvider
      apiKey={POSTHOG_API_KEY}
      options={{
        api_host: POSTHOG_PROXY_HOST,
        ui_host: POSTHOG_HOST,
      }}
    >
      <AppProvider i18n={enPolarisTranslations}>
        <Helmet>
          <style>{'body { background-color: rgb(237, 237, 237); }'}</style>
        </Helmet>

        <ApolloProvider client={apolloClient}>
          <BrowserRouter>
            <UserContextProvider>
              <Index />
            </UserContextProvider>
          </BrowserRouter>
        </ApolloProvider>
      </AppProvider>
    </PostHogProvider>
  );
}

AppRegistry.registerComponent('trn_frontend', () => App);
