import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { ISvgProps } from '../../../../types';

const AnalyticsIcon = ({ color = '#fff', ...props }: ISvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={15} fill="none" {...props}>
    <Path
      fill={color}
      d="M10.387 14.672a.752.752 0 0 1-.46-.158.87.87 0 0 1-.292-.418l-2.46-7.405-1.64 4.786a.875.875 0 0 1-.265.389.742.742 0 0 1-.847.084.834.834 0 0 1-.324-.33l-1.75-3.192-.9 1.476a.796.796 0 0 1-.507.346.746.746 0 0 1-.585-.134.882.882 0 0 1-.338-.54.947.947 0 0 1 .098-.645l1.6-2.627a.804.804 0 0 1 .296-.295.719.719 0 0 1 .39-.095.747.747 0 0 1 .383.119c.117.074.214.18.284.306l1.548 2.824 1.817-5.308a.87.87 0 0 1 .294-.413.754.754 0 0 1 .456-.157h.004c.164 0 .324.057.458.16a.871.871 0 0 1 .29.417l2.359 7.096 2.523-9.665c.096-.368.402-.626.752-.635.345-.022.667.235.777.6l1.6 5.254a.95.95 0 0 1-.026.685.874.874 0 0 1-.192.28.787.787 0 0 1-.277.175.736.736 0 0 1-.625-.056.82.82 0 0 1-.248-.22.908.908 0 0 1-.149-.31l-.784-2.569-2.49 9.538a.885.885 0 0 1-.276.45.76.76 0 0 1-.466.186h-.028"
    />
  </Svg>
);

export { AnalyticsIcon };
