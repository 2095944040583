import { textStyles } from '@styles/text';
import { Field, FieldProps } from 'formik';
import React from 'react';
import { Text } from 'react-native';

import { styles } from './style';

interface FormikInputProps extends React.HTMLProps<HTMLInputElement> {
  fieldName: string;
  label?: string;
}

const FormikInput = ({ fieldName, label, ...rest }: FormikInputProps) => {
  return (
    <>
      {label ? (
        <label htmlFor={fieldName} style={styles.label}>
          {label}
        </label>
      ) : null}
      <Field name={fieldName}>
        {({ field, form: { setFieldValue, getFieldMeta } }: FieldProps) => {
          const meta = getFieldMeta(fieldName);

          return (
            <>
              <input
                id={fieldName}
                style={styles.inputText}
                name={fieldName}
                value={field.value}
                onBlur={field.onBlur}
                onChange={(event) => {
                  const { value } = event.target;
                  setFieldValue(fieldName, value);
                }}
                {...rest}
              />
              {meta.error && meta.touched ? <Text style={textStyles.error}>{meta.error}</Text> : null}
            </>
          );
        }}
      </Field>
    </>
  );
};

export { FormikInput };
