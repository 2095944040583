import { SubmitListener } from '@components/general/submit-listener-formik';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { styles } from './style';
import Avatar from '../../../../../assets/img/Avatar.png';
import { CheckboxComponent } from '../checkbox-component';

export const MainTable = ({
  isBulkToggled,
  isEvents,
  isContent,
  isPosts,
  isTrainings,
  isPolls,
  setSideEditor,
  isUsers,
  isSettings,
  setUserSelected,
  data,
  total,
  setBulkIds,
  setBulkAction,
}: any) => {
  const [indexSelected, setIndexSelected] = useState(-1);
  const [indexToggled, setIndexToggled] = useState(-1);
  const [allTicked, setAllTicked] = useState(false);

  const [flagUntick, setFlagUntick] = useState(false);

  const navigation = useNavigate();

  const rows: any[] = data
    ? data
    : [
        {
          title: isUsers || isSettings ? 'Firstname Lastname' : 'Digital Marketing Process for Recruitment Agencies',
          author: 'Username',
          category: isSettings ? 'Administrator' : 'Marketing',
          date: 'dd/mm/yyyy',
          userType: 'User Type',
          eventType: 'Online',
        },
      ];

  const pollStatus: string[] = ['Open', 'Closed'];

  const flatPollStatus: any[] = pollStatus.flatMap((i) => [i, i, i, i, i]);

  return (
    <View style={[styles.dataTable, { overflow: 'hidden' }]}>
      <Formik
        initialValues={{
          all: false,
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
          7: false,
          8: false,
          9: false,
          10: false,
          11: false,
        }}
        onSubmit={async (values, { setSubmitting, setFieldValue }) => {
          setBulkIds(values);

          if (values.all) {
            const asArray = Object.entries(values);
            const filtered = asArray.filter((value) => !value[1] && value[0] !== 'all');

            setFlagUntick(true);

            filtered.forEach((e: any, index: any) => setFieldValue(e[0], true));
          } else if (!values.all && flagUntick) {
            const asArray = Object.entries(values);
            const filtered = asArray.filter((value) => value[1] && value[0] !== 'all');

            setFlagUntick(false);

            filtered.forEach((e: any, index: any) => setFieldValue(e[0], false));
          }
        }}
      >
        {(formik) => (
          <Form>
            <SubmitListener formik={formik} />
            <View style={styles.headers}>
              {isBulkToggled && (
                <View style={{ marginLeft: 19, marginTop: 7 }}>
                  <CheckboxComponent handleChange={formik.handleChange} value={formik.values.all} name="all" />
                </View>
              )}
              <Text
                style={[
                  styles.titleText,
                  (isUsers || isSettings) && {
                    marginLeft: 137,
                  },
                  isBulkToggled && { marginLeft: 9 },
                ]}
              >
                {isPolls ? 'Topic' : isUsers || isSettings ? 'Name' : 'Title'}
              </Text>
              <Text
                style={[
                  styles.authorText,
                  isUsers && {
                    marginRight: -30,
                    marginLeft: 173,
                  },
                  isSettings && { marginLeft: 143 },
                  isBulkToggled && { marginLeft: 250 },
                  isEvents && { marginLeft: 289 },
                ]}
              >
                {isEvents ? 'Speaker' : isPolls ? 'Poll Status' : isUsers ? 'Organisation' : isSettings ? 'Username' : 'Author'}
              </Text>
              {!isPolls && (
                <Text
                  style={[
                    styles.categoryText,
                    isUsers && {
                      marginRight: -10,
                    },
                    isEvents && { marginLeft: 109 },
                  ]}
                >
                  {isUsers ? 'Status' : isSettings ? 'Role' : 'Category'}
                </Text>
              )}
              {isEvents && <Text style={[styles.dateText, isEvents && { marginLeft: 107 }]}>Event Type</Text>}
              <Text style={[styles.dateText, isEvents && { marginLeft: 72 }]}>Date</Text>
              {isUsers && (
                <Text
                  style={[
                    styles.eventTypeText,
                    {
                      width: 91,
                    },
                  ]}
                >
                  Last logged in
                </Text>
              )}
              {!isPolls && !isSettings && (
                <Text
                  style={[
                    styles.userTypeText,
                    isEvents
                      ? {
                          marginLeft: 103,
                        }
                      : isUsers
                      ? {
                          marginLeft: 40,
                          marginRight: -30,
                        }
                      : {
                          marginLeft: 150,
                        },
                  ]}
                >
                  User Type
                </Text>
              )}
              {isUsers && <Text style={styles.memberSinceText}>Member since</Text>}
              {isSettings && (
                <TouchableOpacity style={styles.flexCreate} onPress={() => setUserSelected(-1)}>
                  <Text style={styles.createBtn}>+</Text>
                  <Text style={styles.createBtnText}>Create new User</Text>
                </TouchableOpacity>
              )}
            </View>
            <View
              style={[
                styles.table,
                (isPolls || isSettings) && {
                  width: 695,
                },
                isUsers && {
                  height: 806,
                },
              ]}
            >
              {rows.map((row: any, index: number) => {
                return (
                  <View key={index} style={{ height: 80 }}>
                    <TouchableOpacity
                      onPress={() => {
                        if (indexToggled === index) setIndexToggled(-1);
                        else setIndexToggled(index);
                      }}
                    >
                      {index > 0 && (
                        <View
                          style={[
                            styles.separation,
                            (isPolls || isSettings) && {
                              width: 606,
                            },
                          ]}
                        />
                      )}
                      <View style={styles.row}>
                        {isBulkToggled && (
                          <View style={{ marginLeft: -16 }}>
                            <CheckboxComponent
                              disabled={formik.values.all}
                              handleChange={formik.handleChange}
                              // @ts-ignore: Unreachable code error
                              value={formik.values[index + 1]}
                              name={index + 1}
                            />
                          </View>
                        )}
                        {(isUsers || isSettings) && <Image source={Avatar} style={styles.avatarUser} />}
                        <View>
                          <Text
                            numberOfLines={indexToggled === index ? 1 : 2}
                            style={[
                              styles.rowTitle,
                              isBulkToggled && {
                                marginLeft: 10,
                                marginRight: 73,
                              },
                              isUsers && {
                                width: 183,
                              },
                            ]}
                          >
                            {row.title}
                          </Text>
                          {indexToggled === index && (
                            <View style={[styles.toggledRow, isBulkToggled ? { marginLeft: 6 } : { marginLeft: -20 }]}>
                              {!isUsers && (
                                <TouchableOpacity>
                                  <Text style={styles.subTitle}>View</Text>
                                </TouchableOpacity>
                              )}
                              <TouchableOpacity
                                onPress={() => {
                                  if (isEvents) {
                                    navigation('/events-editor', {
                                      state: { title: 'Edit Event' },
                                    });
                                  }
                                  if (isPosts) {
                                    navigation('/post-editor', {
                                      state: { title: 'Edit Post' },
                                    });
                                  }
                                  if (isContent) {
                                    navigation('/content-editor', {
                                      state: {
                                        title: 'Edit Content',
                                        id: rows[index] && rows[index].id,
                                      },
                                    });
                                  }
                                  if (isTrainings) {
                                    navigation('/trainings-editor', {
                                      state: { title: 'Edit Training' },
                                    });
                                  }
                                  if (isUsers) {
                                    navigation('/user-editor', {
                                      state: { title: row.title },
                                    });
                                  }
                                  if (isPolls) setSideEditor(row.title);
                                  if (isSettings) setUserSelected(index);
                                }}
                              >
                                <Text style={styles.subTitle}>Edit</Text>
                              </TouchableOpacity>
                              <TouchableOpacity
                                onPress={async () => {
                                  await setBulkIds([data[index].id]);
                                  setBulkAction('Duplicate');
                                }}
                              >
                                <Text style={styles.subTitle}>Duplicate</Text>
                              </TouchableOpacity>
                              {isUsers && (
                                <TouchableOpacity>
                                  <Text style={styles.subTitle}>Suspend</Text>
                                </TouchableOpacity>
                              )}
                              <TouchableOpacity
                                onPress={async () => {
                                  await setBulkIds([data[index].id]);
                                  setBulkAction('Delete');
                                }}
                              >
                                <Text style={styles.subTitleRed}>Delete</Text>
                              </TouchableOpacity>
                            </View>
                          )}
                        </View>
                        {isPolls && <View style={flatPollStatus[index] === 'Open' ? styles.onlinePoll : styles.offlinePoll} />}
                        <Text style={[styles.rowAuthor, isSettings && { marginLeft: -100 }]}>
                          {isPolls ? '  ' + flatPollStatus[index] : row.author}
                        </Text>
                        {isUsers ? (
                          <View style={styles.rowStatus} />
                        ) : (
                          !isPolls && (
                            <Text style={styles.rowCategory}>{typeof row.category === 'object' ? row.category.join(', ') : row.category}</Text>
                          )
                        )}
                        {isEvents && <Text style={[styles.rowEventType, isEvents && { marginLeft: 50 }]}>{row.eventType}</Text>}
                        {!isSettings && (
                          <Text
                            style={[
                              styles.rowDate,
                              isUsers && {
                                marginLeft: 90,
                              },
                            ]}
                          >
                            {row.date}
                          </Text>
                        )}
                        {!isPolls && !isSettings && (
                          <Text
                            style={[
                              styles.rowUserType,
                              isUsers && {
                                marginLeft: 27,
                              },
                              isEvents && { marginLeft: 50 },
                            ]}
                          >
                            {typeof row.userType === 'object' ? row.userType.join(', ') : row.userType}
                          </Text>
                        )}
                        {isUsers && <Text style={styles.rowMemberSince}>dd/mm/yyyy</Text>}
                        <TouchableOpacity
                          onPress={() => {
                            if (indexSelected === index) setIndexSelected(-1);
                            else setIndexSelected(index);
                          }}
                        >
                          <Text
                            style={[
                              styles.options,
                              isUsers && {
                                marginLeft: 35,
                              },
                              isEvents && { marginLeft: 12 },
                            ]}
                          >
                            ...
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </TouchableOpacity>
                  </View>
                );
              })}
              <View
                style={[
                  styles.separation,
                  (isPolls || isSettings) && {
                    width: 606,
                  },
                ]}
              />
              <View style={styles.footerTable}>
                <Text style={styles.footerTableText}>
                  Showing {data?.length} of {total} results
                </Text>
              </View>
            </View>
          </Form>
        )}
      </Formik>
      {indexSelected > -1 && (
        <View
          style={[
            styles.popup,
            { marginTop: indexSelected * 80 + 114 },
            {
              width: 94,
              height: 121,
            },
            isPolls && {
              marginLeft: 600,
            },
            isUsers && {
              width: 108,
              height: 93,
            },
            isSettings && {
              marginLeft: 580,
              marginTop: indexSelected * 81 + 60,
            },
            indexSelected === 10 && {
              marginTop: 871,
              marginLeft: 1010,
            },
          ]}
        >
          {!isUsers && (
            <TouchableOpacity style={styles.optionWrapper}>
              {/* <Image source={ViewIcn} style={styles.viewIcn} /> */}
              <Text style={styles.optionPopup}>View</Text>
            </TouchableOpacity>
          )}
          <TouchableOpacity
            onPress={() => {
              if (isEvents) {
                navigation('/editor', { state: { title: 'Edit Event' } });
              }
              if (isPosts) {
                navigation('/editor', { state: { title: 'Edit Post' } });
              }
              if (isContent) {
                navigation('/editor', {
                  state: {
                    title: 'Edit Content',
                    id: rows[indexSelected] && rows[indexSelected].id,
                  },
                });
              }
              if (isTrainings) {
                navigation('/editor', { state: { title: 'Edit Training' } });
              }
              if (isUsers) {
                navigation('/user_editor', {
                  state: { title: rows[indexSelected].title },
                });
              }
              if (isPolls) setSideEditor(rows[indexSelected].title);
              if (isSettings) setUserSelected(indexSelected);
            }}
            style={styles.optionWrapper}
          >
            {/* <Image source={Write} style={styles.writeIcn} /> */}
            <Text style={styles.optionPopup}>Edit</Text>
          </TouchableOpacity>
          {
            <TouchableOpacity
              style={styles.optionWrapper}
              onPress={async () => {
                await setBulkIds([data[indexSelected].id]);
                setBulkAction('Duplicate');
              }}
            >
              {/* <Image source={Copy} style={styles.copyIcn} /> */}
              <Text style={styles.optionPopup}>Duplicate</Text>
            </TouchableOpacity>
          }
          {isUsers && (
            <TouchableOpacity style={styles.optionWrapper}>
              {/* <Image source={Suspend} style={styles.deleteIcn} /> */}
              <Text style={styles.optionPopup}>Suspend</Text>
            </TouchableOpacity>
          )}
          <TouchableOpacity
            style={styles.optionWrapper}
            onPress={async () => {
              await setBulkIds([data[indexSelected].id]);
              setBulkAction('Delete');
            }}
          >
            {/* <Image source={Delete} style={styles.deleteIcn} /> */}
            <Text style={styles.optionPopupDelete}>Delete</Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};
