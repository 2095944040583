import React, { ReactElement, useState } from 'react';
import { ActivityIndicator, Platform, Text, TouchableOpacity, TouchableOpacityProps } from 'react-native';

import { styles } from './style';
import { ChevronLeftMirror } from '../icons/ChevronLeftMirror';

export enum ButtonVariant {
  Primary,
  Secondary,
}

export enum ButtonType {
  user,
  backOffice,
}

interface ButtonProps extends TouchableOpacityProps {
  label?: string;
  bold?: boolean;
  arrowLeft?: boolean;
  variant?: ButtonVariant;
  withMarginRight?: boolean;
  fromContent?: boolean;
  visibleFilters?: any;
  onPress?: () => void;
  isLoading?: boolean;
  rounded?: boolean;
  hoverOutline?: string;
  type?: ButtonType;
  icon?: ReactElement;
}

const ButtonGeneral = ({
  label,
  bold,
  arrowLeft,
  children,
  variant = ButtonVariant.Primary,
  withMarginRight = false,
  style,
  disabled,
  fromContent,
  visibleFilters,
  isLoading,
  onPress,
  rounded = false,
  hoverOutline,
  type,
  icon,
  ...rest
}: ButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <TouchableOpacity
      style={[
        styles.button,
        type === ButtonType.user && styles.userButton,
        variant === ButtonVariant.Secondary && styles.secondary,
        variant === ButtonVariant.Secondary && isHovered && styles.secondaryContainerHover,
        withMarginRight && styles.marginRight,
        disabled && styles.disabled,
        rounded && styles.rounded,
        !!hoverOutline &&
          isHovered &&
          Platform.OS === 'web' && {
            // @ts-ignore
            outlineColor: hoverOutline,
            outlineStyle: 'solid',
            outlineWidth: 2,
            borderColor: 'white',
          },
        style,
        fromContent && {
          height: 46,
        },
        fromContent &&
          visibleFilters?.length > 1 && {
            paddingLeft: 20,
            width: 50,
          },
        isHovered && variant === ButtonVariant.Primary && { backgroundColor: '#10266D' },
      ]}
      disabled={disabled || isLoading}
      // Hover states only applicable to web so we can ignore ts error below
      // @ts-ignore
      onMouseEnter={() => setIsHovered(true)}
      // @ts-ignore
      onMouseLeave={() => setIsHovered(false)}
      onPress={onPress}
      {...rest}
    >
      {isLoading ? (
        <ActivityIndicator size="small" color={variant === ButtonVariant.Secondary ? '#202223' : '#FFFFFF'} />
      ) : (
        <>
          {arrowLeft ? <ChevronLeftMirror /> : ''}
          {icon}
          {label ? (
            <Text
              style={[styles.text, variant === ButtonVariant.Secondary && styles.textSecondary, bold && styles.labelText, icon && { marginLeft: 10 }]}
            >
              {label}
            </Text>
          ) : (
            children
          )}
        </>
      )}
    </TouchableOpacity>
  );
};

export { ButtonGeneral };
