export function getUniqueRandomIndexes(arr: any[], limit: number): number[] {
  if (limit <= 0 || arr.length < limit) {
    console.error('Limit must be greater than 0 and less than or equal to the length of the array.');
    return arr.map((_, i) => i);
  }

  const selectedIndexes = new Set<number>();

  while (selectedIndexes.size < limit) {
    const randomIndex = Math.floor(Math.random() * arr.length);
    selectedIndexes.add(randomIndex);
  }

  return Array.from(selectedIndexes);
}
