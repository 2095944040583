import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  container: {
    backgroundColor: '#FFFFFF',
    ...(Platform.OS === 'web' && { height: 'max-content' }),
    borderRadius: 8,
    paddingHorizontal: 20,
    paddingVertical: 30,
    marginBottom: 20,
  },
});
