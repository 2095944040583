import { useGetFile } from '@hooks/useGetFileUrl';
import { ImageStyle, Image as ReactNativeImage, StyleProp } from 'react-native';

export const Image = ({
  path,
  style,
  imageUrl,
  fallbackImagePath,
}: {
  path?: string | null;
  imageUrl?: string | null;
  fallbackImagePath?: string | null;
  style: StyleProp<ImageStyle>;
}) => {
  const { fileUrl, isError } = useGetFile(path);
  const imgUrl = imageUrl ?? fileUrl;

  return <ReactNativeImage source={{ uri: isError && fallbackImagePath ? fallbackImagePath : imgUrl }} style={style} />;
};
