import { CheckboxComponent } from '@components/back-office/tables/checkbox-component';
import { DoubleArrow } from '@components/general/icons/DoubleArrow';
import { ActionList, Popover } from '@shopify/polaris';
import { FormikValues, useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { styles } from './styles';
import { InputFormikContainer } from '../input-formik/InputFormikContainer';

const CheckboxMenuReasonToJoin = () => {
  const { values, handleChange, resetForm }: FormikValues = useFormikContext();
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const activator = (
    <InputFormikContainer label="What are the 3 main reasons you joined TRN – tick any 3:">
      <View style={styles.dropMenuContainer}>
        <TouchableOpacity
          onPress={toggleActive}
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text>Please Select</Text>
          <DoubleArrow />
        </TouchableOpacity>
      </View>
    </InputFormikContainer>
  );

  const { reasonsForJoining } = values;

  const selectedReasonsForJoiningCount = Object.keys(reasonsForJoining).reduce((acc, key) => {
    return reasonsForJoining[key].selected ? acc + 1 : acc;
  }, 0);

  return (
    <>
      <Popover active={active} activator={activator} onClose={toggleActive} preferredPosition="below" preferredAlignment="left" fullWidth>
        <ActionList
          actionRole="menuitem"
          items={[
            {
              content: 'Reset',
              onAction: () => {
                resetForm();
              },
              destructive: true,
            },
            {
              content: 'To access experts and advisors for input into my strategy',
              prefix: (
                <CheckboxComponent
                  disabled={selectedReasonsForJoiningCount === 3 && !values.reasonsForJoining.strategy.selected}
                  name="reasonsForJoining.strategy.selected"
                  value={values.reasonsForJoining.strategy.selected}
                  handleChange={handleChange}
                />
              ),
            },
            {
              content: 'To see what others are doing and to benchmark my business and my approach against others',
              prefix: (
                <CheckboxComponent
                  disabled={selectedReasonsForJoiningCount === 3 && !values.reasonsForJoining.community.selected}
                  name="reasonsForJoining.community.selected"
                  value={values.reasonsForJoining.community.selected}
                  handleChange={handleChange}
                />
              ),
            },
            {
              content: 'To help scale my business',
              prefix: (
                <CheckboxComponent
                  disabled={selectedReasonsForJoiningCount === 3 && !values.reasonsForJoining.business.selected}
                  name="reasonsForJoining.business.selected"
                  value={values.reasonsForJoining.business.selected}
                  handleChange={handleChange}
                />
              ),
            },
            {
              content: 'To hold me to account',
              prefix: (
                <CheckboxComponent
                  disabled={selectedReasonsForJoiningCount === 3 && !values.reasonsForJoining.account.selected}
                  name="reasonsForJoining.account.selected"
                  value={values.reasonsForJoining.account.selected}
                  handleChange={handleChange}
                />
              ),
            },
            {
              content: 'To access different ideas',
              prefix: (
                <CheckboxComponent
                  disabled={selectedReasonsForJoiningCount === 3 && !values.reasonsForJoining.ideas.selected}
                  name="reasonsForJoining.ideas.selected"
                  value={values.reasonsForJoining.ideas.selected}
                  handleChange={handleChange}
                />
              ),
            },
            {
              content: 'To develop myself as a recruitment business leader',
              prefix: (
                <CheckboxComponent
                  disabled={selectedReasonsForJoiningCount === 3 && !values.reasonsForJoining.leadership.selected}
                  name="reasonsForJoining.leadership.selected"
                  value={values.reasonsForJoining.leadership.selected}
                  handleChange={handleChange}
                />
              ),
            },
            {
              content: 'To develop my team',
              prefix: (
                <CheckboxComponent
                  disabled={selectedReasonsForJoiningCount === 3 && !values.reasonsForJoining.team.selected}
                  name="reasonsForJoining.team.selected"
                  value={values.reasonsForJoining.team.selected}
                  handleChange={handleChange}
                />
              ),
            },
          ]}
        />
      </Popover>
    </>
  );
};

export { CheckboxMenuReasonToJoin };
