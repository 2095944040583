import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

import { ISvgProps } from '../../../types';

const LinkIconDark = (props: ISvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none" {...props}>
    <Circle cx={12} cy={12.053} r={12} fill="#5C5F62" />
    <Path
      fill="#fff"
      d="M18.53 7.065a3.34 3.34 0 0 0-2.344-.97c-.848 0-1.696.323-2.343.97l-1.016 1.017c.354.187.69.412.982.703.074.074.138.153.205.23l.89-.889a1.768 1.768 0 0 1 1.282-.531c.484 0 .94.188 1.282.53.343.343.531.799.531 1.283a1.8 1.8 0 0 1-.53 1.282l-3.125 3.125c-.34.321-.797.53-1.28.53s-.94-.189-1.282-.531a1.817 1.817 0 0 1-.553-1.283c0-.486.189-.94.531-1.283l.104-.081c-.05-.092-.1-.186-.176-.26a1.053 1.053 0 0 0-.752-.312c-.28 0-.541.109-.74.304a3.304 3.304 0 0 0 2.868 4.946c.848 0 1.696-.324 2.343-.97l3.123-3.125c.666-.667.99-1.547.97-2.42a3.264 3.264 0 0 0-.97-2.265zm-8.546 8.11-.888.888a1.89 1.89 0 0 1-1.282.532c-.484 0-.94-.189-1.282-.531A1.801 1.801 0 0 1 6 14.78c0-.484.188-.94.53-1.282l3.125-3.124c.34-.343.776-.53 1.28-.53s.94.188 1.282.53a1.8 1.8 0 0 1 .531 1.283 1.8 1.8 0 0 1-.53 1.282l-.083.083c.049.092.099.185.175.261a1.064 1.064 0 0 0 1.494.007 3.304 3.304 0 0 0-.525-3.975 3.338 3.338 0 0 0-2.344-.971c-.848 0-1.695.323-2.341.97L5.47 12.439a3.303 3.303 0 0 0-.971 2.343 3.31 3.31 0 0 0 3.314 3.313c.848 0 1.696-.324 2.343-.97l1.016-1.017a4.05 4.05 0 0 1-.982-.704c-.075-.073-.139-.152-.207-.23z"
    />
  </Svg>
);

export { LinkIconDark };
