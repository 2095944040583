import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  container: {
    borderRadius: 4,
    flexDirection: 'row',
    width: '49%',
    marginRight: 20,
  },
  imageContainer: {
    padding: 8,
    borderRadius: 4,
    borderColor: '#EDEEEF',
    borderWidth: 1,
  },
  image: {
    width: 128,
    height: 128,
    resizeMode: 'contain',
  },
  contentContainer: {
    width: '100%',
    marginLeft: 16,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 26,
    marginBottom: 4,
    lineHeight: 32,
  },
  categories: {
    color: '#0D1738',
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    lineHeight: 20,
  },
  socialLinksContainer: {
    flexDirection: 'row',
    marginTop: 4,
  },
});
