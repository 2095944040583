// Following recommended feature flag implementation: https://github.com/PostHog/posthog/blob/master/frontend/src/lib/constants.tsx

export const FEATURE_FLAGS = {
  TRAINING_PROGRESS: 'training-progress',
  DOMAIN_REDIRECT_WHITELIST: 'domain-redirect-whitelist',
  ROADMAP: 'roadmap',
  ROADMAP_NORTH_STAR: 'roadmap-north-star',
  ROADMAP_GOAL: 'roadmap-goal',
  ROADMAP_PULSE: 'roadmap-pulse',
  ROADMAP_PULSE_RESULT: 'roadmap-pulse-result',
  ROADMAP_PRIORITIES: 'roadmap-priorities',
  ROADMAP_PULSE_SCORE_ASSNS: 'roadmap-pulse-score-assns',
  LET_IT_SNOW: 'let-it-snow',
};
export type FeatureFlagKey = (typeof FEATURE_FLAGS)[keyof typeof FEATURE_FLAGS];
