import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  container: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: '#1653CE',
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerText: {
    fontSize: 20,
    color: 'white',
    textAlign: 'center',
    lineHeight: 40,
  },
});
