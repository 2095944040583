import { Tooltip } from '@shopify/polaris';
import { uniq } from 'lodash';
import React from 'react';
import { View } from 'react-native';

import { Pill, PillVariant } from '../pill/Pill';
import { Row } from '../row';
import { TooltipWithChild } from '../tooltip';

interface PillRowWithTooltipProps {
  items: string[];
  shortenedItems: string[];
  variant?: PillVariant;
}

const PillRowWithTooltip = ({ items, shortenedItems, variant = PillVariant.Light }: PillRowWithTooltipProps) => {
  return (
    <Row>
      <TooltipWithChild toolTipText={`${items?.join(', ')}`}>
        {uniq(shortenedItems).map((el, i) => (
          <View key={`${el}-${i}`} style={{ marginBottom: 4, width: 'min-content' }}>
            <Pill text={el} withMarginRight={i != shortenedItems.length - 1} variant={variant} />
          </View>
        ))}
      </TooltipWithChild>
    </Row>
  );
};

export { PillRowWithTooltip };
