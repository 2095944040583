import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const CommentIcon = (props: SvgProps) => (
  <Svg width={15} height={14} fill="none" {...props}>
    <Path
      d="M13.5 9a1.334 1.334 0 0 1-1.333 1.333h-8L1.5 13V2.333A1.333 1.333 0 0 1 2.833 1h9.334A1.333 1.333 0 0 1 13.5 2.333V9Z"
      stroke="#6D7175"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export { CommentIcon };
