import { useGetMemberStatsQuery, useGetUserTrainingsForOrgQuery } from '@gql/generated/generated';
import { useMemo, useState } from 'react';

import { calculateTodaysStats, percentageChangePerDay } from './helpers';

const useMembersOverview = () => {
  const [selected, setSelected] = useState('week');

  const { data, refetch } = useGetMemberStatsQuery({
    variables: {
      // Default days is 1 week, we fetch extra day to calculate percentages
      numDays: 8,
    },
  });

  const refetchData = (days: number) => {
    refetch({
      numDays: days,
    });
  };

  const members = data?.employerGetMemberStats.members;

  const dataForGraph = useMemo(() => {
    let displayLabels = members?.dates?.slice(1);

    if (!!displayLabels?.length && displayLabels?.length > 7) {
      displayLabels = displayLabels.filter((_, i) => i % 2 === 0);
    }

    return {
      labels: members?.dates?.slice(1),
      newMembers: percentageChangePerDay(members?.newMembers || []).slice(1),
      activeMembers: percentageChangePerDay(members?.activeMembers || []).slice(1),
      averageTime: percentageChangePerDay(members?.averageTimePerMember || []).slice(1),
      displayLabels,
    };
  }, [members]);

  return {
    members,
    refetchData,
    selected,
    setSelected,
    dataForGraph,
  };
};

const useTrainingProgress = () => {
  const [page, setPage] = useState(1);

  const limit = 10;

  const variables = {
    input: {
      page,
      limit,
    },
  };

  const {
    data: users,
    loading,
    refetch: refetchTraining,
  } = useGetUserTrainingsForOrgQuery({
    variables,
  });

  return {
    page,
    setPage,
    users: users?.employerGetUsers,
    loading,
    refetchTraining,
  };
};

export const useMembersAnalytics = () => {
  const { members, refetchData, selected, setSelected, dataForGraph } = useMembersOverview();

  const { page, setPage, users, loading, refetchTraining } = useTrainingProgress();

  const dataForExport = useMemo(() => {
    const newMembers = members?.newMembers || [];
    const activeMembers = members?.activeMembers || [];
    const averageTime = members?.averageTimePerMember || [];

    return [
      {
        columns: [
          { label: 'Date', value: 'date' },
          { label: 'New members', value: 'new' },
          { label: 'Active members', value: 'active' },
          { label: 'Average time spent (mins/member)', value: 'time' },
        ],
        data: members?.dates.map((date, i) => {
          return {
            date,
            new: newMembers[i],
            active: activeMembers[i],
            time: averageTime[i],
          };
        }),
        name: 'Member overview data',
      },
      {
        columns: [
          { label: 'Name', value: 'name' },
          { label: 'Job title', value: 'job' },
          { label: 'Training hours (total)', value: 'hours' },
          { label: 'Courses enrolled', value: 'courses' },
        ],
        data: users?.users?.map((user) => {
          const { accountInfo, statistics } = user;
          return {
            name: `${accountInfo?.firstName} ${accountInfo?.lastName}`,
            job: accountInfo?.companyInfo.jobPosition,
            hours: statistics?.cumulativeTrainingInSeconds || 0,
            courses: user.trainingsProgress?.length || 0,
          };
        }),
        name: 'Training progress',
      },
    ];
  }, [members, users]);

  const averageTimeSpentToday = useMemo(() => {
    if (!members) return null;

    const averageTimePerMemberToday = members?.averageTimePerMember?.slice(-1)?.[0] || 0;

    const averageTimePerMemberYesterday = members?.averageTimePerMember?.slice(-2)?.[0] || 0;

    return calculateTodaysStats(averageTimePerMemberToday, averageTimePerMemberYesterday);
  }, [members]);

  const activeMembersToday = useMemo(() => {
    if (!members) return null;
    const activeTimeToday = members?.activeMembers?.slice(-1)?.[0] || 0;

    const activeTimeYesterday = members?.activeMembers?.slice(-2)?.[0] || 0;

    return calculateTodaysStats(activeTimeToday, activeTimeYesterday);
  }, [members]);

  const newMembersToday = useMemo(() => {
    if (!members) return null;
    const newMembersToday = members?.newMembers?.slice(-1)?.[0] || 0;
    const newMembersYesterday = members?.newMembers?.slice(-2)?.[0] || 0;

    return calculateTodaysStats(newMembersToday, newMembersYesterday);
  }, [members]);

  return {
    averageTimeSpentToday,
    activeMembersToday,
    newMembersToday,
    selected,
    setSelected,
    dataForGraph,
    refetchData,
    dataForExport,
    refetchTraining,
    users: users?.users,
    page,
    setPage,
    loading,
  };
};
