import { mediaQuery } from '@utils/misc';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    borderBottomColor: '#E1E3E5',
    borderBottomWidth: 1,
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 20,
    paddingRight: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  borderDisabled: {
    borderTopWidth: 0,
  },
  leftContent: {
    flexDirection: 'row',
    width: '50%',
    [mediaQuery.forMobile]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  image: {
    height: 52,
    width: 52,
    borderRadius: 6,
    marginRight: 16,
  },
  title: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 16,
    color: '#0D1738',
  },
  description: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    color: '#0D1738',
  },
  subtitle: {
    color: '#6D7175',
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
  },
  rightContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonWrap: {
    width: 161,
    marginLeft: 8,
    [mediaQuery.forMobile]: {
      marginRight: 8,
      marginLeft: 0,
    },
  },
  buttonText: {
    color: '#FFFFFF',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
  },
});
