import { ButtonGeneral, ButtonVariant } from '@components/general/button-general';
import { PointerArrowRight } from '@components/general/icons/account-icons/PointerArrowRight';
import { DocumentAsset } from '@gql/generated/generated';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Image as ReactNativeImage, Linking, Platform, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { ids, styles } from './style';
import ArrowRight from '../../../../assets/img/arrow-right.png';
import { DiscountIcon } from '../icons/DiscountIcon';
import { Image } from '../image-from-storage';
import { Tooltip } from '../tooltip';
import { TouchableOpacityLink } from '../touchable-opacity-link';

export interface ListItemType {
  title?: string | null;
  date?: number | null;
  id?: string | null;
  image?: DocumentAsset | null;
  imageUrl?: string | null;
  hasSpecialOffer?: boolean;
  onPress?: () => void;
  bottomRow?: React.ReactNode;
  withTime?: boolean;
  linkUrl?: string | null;
}

interface SideListBoxProps {
  title: string;
  items?: ListItemType[] | null;
  imageUrl?: string | null;
  linkText?: string;
  linkLocation?: string;
  marginTop?: number;
  onPressLink?: () => void;
  withTime?: boolean;
}

const ListItem = ({ id, title, image, hasSpecialOffer = false, bottomRow, date, onPress, imageUrl, linkUrl, withTime = true }: ListItemType) => {
  const [isHovered, setIsHovered] = useState(false);
  const formattedDate = date && dayjs(new Date(date)).format(`D MMM YY ${withTime ? '• HH:mm' : ''}`);
  return (
    <TouchableOpacityLink
      style={[styles.listItem, isHovered && { backgroundColor: '#F6F6F7' }]}
      href={linkUrl}
      onPress={onPress}
      key={id}
      // @ts-ignore
      onMouseEnter={() => setIsHovered(true)}
      // @ts-ignore
      onMouseLeave={() => setIsHovered(false)}
    >
      <Image path={image?.storagePath} style={styles.itemImage} imageUrl={imageUrl} />
      <View style={styles.itemInfo}>
        <View
          style={{
            flexDirection: 'row',
            flex: 1,
          }}
        >
          <Tooltip text={title} textStyles={{ fontWeight: 'semibold' }} />
          {hasSpecialOffer ? <DiscountIcon /> : null}
        </View>
        <Text style={styles.dateText}>{formattedDate}</Text>
        {bottomRow}
      </View>
    </TouchableOpacityLink>
  );
};

const SideListBox = ({ title, items, linkText, linkLocation, marginTop, onPressLink, withTime = true }: SideListBoxProps) => {
  const navigate = useNavigate();
  const handleLinkPress = () => {
    if (linkLocation) {
      navigate(linkLocation);
    } else if (onPressLink) {
      onPressLink();
    }
  };

  const handItemPress = (linkUrl: string) => {
    if (linkUrl && linkUrl.startsWith('http')) {
      Linking.openURL(linkUrl);
    } else if (linkUrl) {
      navigate(linkUrl);
    }
  };

  return (
    <View style={[styles.container, !!marginTop && { marginTop }]} dataSet={{ media: ids.container }}>
      <View style={items?.length ? styles.topWrap : styles.emptyTopWrap}>
        {items?.length ? (
          <View style={styles.titleWrap}>
            <Text style={styles.titleText}>{title}</Text>
          </View>
        ) : null}
        {items?.map((value, index) => (
          <ListItem
            {...value}
            key={`${value?.id}${index}`}
            withTime={withTime}
            onPress={() => (value.linkUrl ? handItemPress(value.linkUrl) : value.onPress ? value.onPress() : null)}
          />
        ))}
      </View>
      {Platform.OS === 'web' ? (
        linkText && linkLocation ? (
          <View style={styles.bottomSection}>
            <TouchableOpacityLink style={styles.linkButton} href={linkLocation} onPress={() => navigate(linkLocation)}>
              <Text>{linkText}</Text>
              <ReactNativeImage source={ArrowRight} style={styles.arrowImage} />
            </TouchableOpacityLink>
          </View>
        ) : null
      ) : (
        <View style={styles.buttonWrap}>
          <ButtonGeneral variant={ButtonVariant.Secondary} onPress={handleLinkPress}>
            <Text style={styles.buttonText}>{linkText}</Text>
            <PointerArrowRight />
          </ButtonGeneral>
        </View>
      )}
    </View>
  );
};

export { SideListBox };
