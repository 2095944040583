import { CaretDown } from '@components/general/icons/account-icons/CaretDown';
import { CaretUp } from '@components/general/icons/account-icons/CaretUp';
import { ContentBox } from '@components/general/layouts/content-box';
import React from 'react';
import { ActivityIndicator, Text, View } from 'react-native';

import { styles } from './style';

export interface Props {
  title: string;
  count?: number;
  percent?: number;
  countInfo?: string;
  percentInfo?: string;
}

const AnalyticsCountBox = ({ title, count, percent, percentInfo, countInfo }: Props) => (
  <ContentBox style={styles.content}>
    <Text style={styles.contentTitle}>{title}</Text>
    {typeof percent === 'number' && typeof count === 'number' ? (
      <>
        <View style={styles.countWrap}>
          <Text style={styles.count}>{count}</Text>
          {countInfo ? <Text style={styles.countInfo}>{countInfo}</Text> : null}
        </View>
        <View style={styles.percentWrap}>
          {percent >= 0 ? <CaretUp fill="#23827F" /> : <CaretDown fill="#D72C0D" />}
          <Text style={[styles.percentText, { color: percent >= 0 ? '#23827F' : '#D72C0D' }]}>{percent}%</Text>
          <Text style={styles.percentInfo}>&nbsp;{percentInfo}</Text>
        </View>
      </>
    ) : (
      <View style={styles.activityIndicator}>
        <ActivityIndicator size="large" color="#213470" />
      </View>
    )}
  </ContentBox>
);

export { AnalyticsCountBox };
