import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 20,
    marginRight: 30,
  },
  cancelButton: {
    paddingHorizontal: 35,
    paddingVertical: 5,
    borderRadius: 35,
    backgroundColor: '#ffffff',
    borderColor: '#8C9196',
    color: '#0D1738',
    borderWidth: 0.5,
  },
  cancelContainer: {
    marginRight: 8,
    alignItems: 'center',
  },
  saveButton: {
    marginLeft: 8,
    paddingHorizontal: 24,
    paddingVertical: 5,
    borderRadius: 35,
    color: '#ffffff',
  },
  saveContainer: {
    backgroundColor: '#0D1738',
    borderRadius: 35,
  },
});
