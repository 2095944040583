import Constants from 'expo-constants';
import StyleSheet from 'react-native-media-query';

const statusBarHeight = Constants.statusBarHeight;
export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
    height: statusBarHeight - 20 + 64,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    paddingRight: 28,
    borderBottomColor: '#EDEEEF',
    borderBottomWidth: 1,
    paddingBottom: 16,
  },
  flexHeader: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  backTouch: {
    paddingLeft: 28,
    marginRight: 16,
    paddingTop: 4,
    paddingBottom: 4,
  },
  title: {
    fontSize: 20,
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
  },
  titleMargin: {
    marginLeft: 20,
  },
  roundTouch: {
    borderColor: '#5C5F62',
    borderWidth: 1,
    width: 28,
    height: 28,
    borderRadius: 14,
    alignItems: 'center',
    justifyContent: 'center',
  },
  roundTouchMargin: {
    marginRight: 20,
  },
});
