import { mediaQuery } from '@utils/misc';
import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  title: {
    marginBottom: 8,
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 26,
    lineHeight: 32,
    color: '#0D1738',
    textAlign: 'center',
    [mediaQuery.forMobile]: {
      textAlign: 'left',
      width: '100%',
      marginTop: 0,
    },
  },
  subtitle: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#6D7175',
    marginBottom: 40,
    textAlign: 'center',
    [mediaQuery.forMobile]: {
      textAlign: 'left',
      width: '100%',
      color: '#0D1738',
      fontSize: 16,
    },
  },
  subContainer: {
    [mediaQuery.forMobile]: {
      width: '100%',
    },
  },
  forgotPasswordTouch: {
    marginTop: 16,
    alignSelf: 'flex-end',
  },
  forgotPassword: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#2C6ECB',
    textAlign: 'right',
    marginBottom: 12,
    [mediaQuery.forMobile]: {
      textAlign: 'left',
    },
  },
  register: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 16,
  },
  noAccount: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#202223',
  },
  registerLink: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#2C6ECB',
  },
  errorText: {
    flex: 1,
    color: '#D72C0D',
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
  },
  errorTextContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingBottom: 6,
  },
  inputWrap: {
    marginBottom: 16,
  },
  submitButton: {
    ...(Platform.OS === 'web' && { height: 36 }),
  },
});
