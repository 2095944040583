import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  headerContainer: {
    flex: 1,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#E1E3E5',
  },
  header: {
    flexGrow: 1,
    flexDirection: 'row',
    paddingVertical: 8,
    paddingHorizontal: 40,
  },
  headerTitle: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 140,
    fontSize: 12,
    fontFamily: 'OpenSans_600SemiBold',
    lineHeight: 20,
    color: '#6D7175',
  },
  headerMainTitle: {
    flexGrow: 2,
    flexShrink: 1,
    flexBasis: 340,
    fontSize: 12,
    fontFamily: 'OpenSans_600SemiBold',
    lineHeight: 20,
    color: '#6D7175',
  },
  headerButton: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 30,
  },
});
