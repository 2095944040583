import { AnalyticsContentTable } from '@components/back-office/analytics/analytics-content-table';
import { AnalyticsCountBoxes } from '@components/back-office/analytics/analytics-count-boxes';
import { AnalyticsHeader } from '@components/back-office/analytics/analytics-header';
import { AnalyticsRequest } from '@components/back-office/analytics/analytics-request';
import { ContentBox } from '@components/general/layouts/content-box';
import React from 'react';
import { View } from 'react-native';

import { styles, ids } from './style';
import { useContentAnalytics } from '../hooks/use-content-analytics';

const AnalyticsContent = () => {
  const { boxItems, analyticsData, refetch, refetchContentRows, contentAnalytics, dataForExport } = useContentAnalytics();

  return (
    <>
      <AnalyticsHeader excelData={dataForExport} path="content" />
      <ContentBox>
        <View style={styles.countBoxesWrap}>
          <AnalyticsCountBoxes items={boxItems} />
        </View>
        <View style={styles.split} dataSet={{ media: ids.split }}>
          <View style={styles.leftItem}>
            <AnalyticsRequest
              title="Content Request"
              requests={analyticsData?.adminGetMemberStats.content?.requests}
              refetch={(val: number) =>
                refetch({
                  numDays: val,
                })
              }
            />
          </View>
        </View>
        <View style={styles.contentBox}>
          <AnalyticsContentTable data={contentAnalytics} refetch={(val: number) => refetchContentRows({ numDays: val })} title="Content Analytics" />
        </View>
      </ContentBox>
    </>
  );
};

export { AnalyticsContent };
