import { ModalContainer } from '@components/org/saved/modals/ModalContainer';
import { ConfirmationModalContent } from '@components/org/saved/modals/confirmation-modal/ConfirmationModalContent';
import { GenericModalLayout } from '@components/org/saved/modals/generic-modal-layout/GenericModalLayout';
import { selectedUsersAssignModalContext } from '@context/SelectedUsersAssignModalContext';
import { useUserContext } from '@context/UserContext';
import { ListType, SavedListAction, useAssignItemsToUsersMutation, useUpdateSavedListMutation } from '@gql/generated/generated';
import { ActionList, Popover } from '@shopify/polaris';
import { useContext, useState } from 'react';
import { Text, Image, ActivityIndicator, Modal } from 'react-native';

import { styles } from './styles';
import Plus from '../../../../assets/img/Plus.png';
import { ButtonGeneral, ButtonVariant } from '../button-general';
import { DotsButton } from '../dots-button';
import { CirclePlus } from '../icons/CirclePlus';
import { ProductReturnMinor } from '../icons/ProductReturnMinor';
import { SavedIcon } from '../icons/SavedIcon';
import { CheckIcon } from '../icons/event-icons/CheckIcon';
import { PortalAssignModal } from '../modals/portal-assign-modal/PortalAssignModal';

export const SaveMenu = ({
  id,
  type,
  additionalDetail,
  title,
  coverImage,
  activator = 'Dots',
  categories,
}: {
  id: string;
  type: 'Content' | 'Event' | 'Training';
  additionalDetail: string[];
  categories: string[];
  title?: string | null;
  coverImage?: string | null;
  activator?: 'Save' | 'Dots';
}) => {
  const { currentUser } = useUserContext();
  const [isSaveMenuVisible, setIsSaveMenuVisible] = useState(false);
  const [isSaved, setIsSaved] = useState(!!currentUser?.savedItems?.find((item) => item.id === id));
  const [successErrorMessage, setSuccessErrorMessage] = useState('');
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [updateSavedList, { loading }] = useUpdateSavedListMutation();
  const [isAssignmentModalVisible, setIsAssignmentModalVisible] = useState(false);

  const { setSelectedUsers, selectedUsers } = useContext(selectedUsersAssignModalContext);
  const canAssign = currentUser?.role?.isEmployer;

  const handlePressSave = async (action: SavedListAction) => {
    await updateSavedList({
      variables: {
        input: {
          action,
          item: {
            id,
            type,
          },
          listType: ListType.SAVED_ITEMS,
        },
      },
      update: (store) => {
        if (action === SavedListAction.ADD_TO_USER) {
          store.modify({
            id: `User:${currentUser?.id}`,
            fields: {
              savedItems() {
                return [...(currentUser?.savedItems ?? []), { id, type }];
              },
            },
          });
        }
        if (action === SavedListAction.REMOVE) {
          store.modify({
            id: `User:${currentUser?.id}`,
            fields: {
              savedItems() {
                return currentUser?.savedItems?.filter((item) => item.id !== id) || [];
              },
            },
          });
        }
      },
    });
    return setIsSaved(!isSaved);
  };
  const SaveActivator = (
    <ButtonGeneral
      style={{
        ...styles.buttonStyle,
        backgroundColor: isSaved ? '#e6f0fd' : '#fff',
        borderColor: isSaved ? '#e6f0fd' : '#c2c7ca',
      }}
      variant={ButtonVariant.Secondary}
      onPress={() => {
        document.body.click();
        setIsSaveMenuVisible(!isSaveMenuVisible);
      }}
      hoverOutline="#4490FF"
    >
      {isSaved ? <CheckIcon style={{ marginRight: 6 }} /> : <Image source={Plus} style={styles.plusIcon} />}
      <Text style={styles.saveButtonText}>{isSaved ? 'Saved' : 'Save'}</Text>
    </ButtonGeneral>
  );

  const DotActivator = (
    <DotsButton
      onPress={() => {
        document.body.click();
        setIsSaveMenuVisible(!isSaveMenuVisible);
      }}
    />
  );

  const [updateUsersAssignments] = useAssignItemsToUsersMutation();

  const updateUsersAssignmentsHandler = async () => {
    if (!id) return;
    try {
      await updateUsersAssignments({
        variables: {
          input: {
            action: SavedListAction.ADD_TO_USER,
            item: {
              id,
              type,
            },
            userIds: selectedUsers.map((user) => user.id),
          },
        },
      });
      setIsAssignmentModalVisible(false);
      setIsSuccessModalVisible(true);
      setSuccessErrorMessage('The Assignment has been successfully added to the selected users');
      setSelectedUsers([]);
    } catch (error) {
      setSuccessErrorMessage('Something went wrong, please try again later or contact support');
    }
  };

  return (
    <>
      <PortalAssignModal
        title={title}
        image={coverImage!}
        modalVisible={isAssignmentModalVisible}
        setModalVisible={setIsAssignmentModalVisible}
        updateUsersAssignmentsHandler={updateUsersAssignmentsHandler}
        contentType={type}
        additionalDetail={[type, ...additionalDetail]}
        categories={categories}
      />
      <Modal visible={isSuccessModalVisible} transparent>
        <ModalContainer>
          <GenericModalLayout
            title="Assignment selected"
            closeModal={() => setIsSuccessModalVisible(false)}
            hasButtonLayout
            buttonName="Close"
            onActionButtonPress={() => setIsSuccessModalVisible(false)}
            withCancel={false}
          >
            <ConfirmationModalContent mainText={successErrorMessage} secondaryText="" />
          </GenericModalLayout>
        </ModalContainer>
      </Modal>
      <Popover
        active={isSaveMenuVisible}
        activator={activator === 'Save' ? SaveActivator : DotActivator}
        onClose={() => setIsSaveMenuVisible(false)}
        preferredAlignment="right"
      >
        <ActionList
          actionRole="menuitem"
          items={[
            !isSaved
              ? {
                  content: 'Save',
                  prefix: loading ? <ActivityIndicator /> : <CirclePlus />,
                  onAction: () => handlePressSave(SavedListAction.ADD_TO_USER),
                }
              : {
                  prefix: loading ? <ActivityIndicator /> : <SavedIcon />,
                  onAction: () => handlePressSave(SavedListAction.REMOVE),
                  active: true,
                  helpText: <Text style={styles.savedText}>Saved</Text>,
                },
            ...(canAssign
              ? [
                  {
                    content: 'Assign',
                    prefix: <ProductReturnMinor />,
                    onAction: () => setIsAssignmentModalVisible(true),
                  },
                ]
              : []),
          ]}
        />
      </Popover>
    </>
  );
};
