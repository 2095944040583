import { FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon } from '@components/general/icons';
import { Text, View } from 'react-native';

import { styles } from './styles';

interface MediaBlockProps {
  facebook?: string;
  instagram?: string;
  linkedin?: string;
  twitter?: string;
}

const MediaBlock = ({ facebook, instagram, linkedin, twitter }: MediaBlockProps) => {
  return (
    <>
      <View style={styles.wrapMedia}>
        <FacebookIcon color="#0D1738" />
        <Text style={styles.mediaText}>{facebook || ''}</Text>
      </View>
      <View style={styles.wrapMedia}>
        <InstagramIcon color="#0D1738" />
        <Text style={styles.mediaText}>{instagram || ''}</Text>
      </View>
      <View style={styles.wrapMedia}>
        <LinkedInIcon color="#0D1738" />
        <Text style={styles.mediaText}>{linkedin || ''}</Text>
      </View>
      <View style={styles.wrapMedia}>
        <TwitterIcon color="#0D1738" />
        <Text style={styles.mediaText}>{twitter || ''}</Text>
      </View>
    </>
  );
};

export { MediaBlock };
