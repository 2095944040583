import Constants from 'expo-constants';
import StyleSheet from 'react-native-media-query';

const statusBarHeight = Constants.statusBarHeight;
export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
    height: statusBarHeight - 10 + 64,
    justifyContent: 'flex-end',
    paddingRight: 28,
    borderBottomColor: '#EDEEEF',
    borderBottomWidth: 1,
    paddingBottom: 16,
  },
  headerInner: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  backTouch: {
    width: '20%',
    alignItems: 'flex-end',
    paddingRight: 18,
  },
  searchInput: {
    borderWidth: 0.5,
    borderColor: '#6D7175',
    paddingLeft: 16,
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: 35,
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    width: '80%',
    height: 32,
  },
});
