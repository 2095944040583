import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  background: {
    backgroundColor: '#FFFFFF',
    ...(Platform.OS === 'web' && { height: 'max-content' }),
    paddingBottom: 30,
    width: 874,
    marginTop: 12,
    borderRadius: 8,
    paddingLeft: 20,
  },
  editor: {
    width: 808,
    height: 464,
    overflow: 'scroll',
    borderWidth: 2,
    borderColor: '#ECEEF7',
    marginTop: 22,
    marginBottom: 10,
  },
  separation: {
    borderTopWidth: 0.5,
    borderTopColor: '#ECEEF7',
    width: 751,
    position: 'absolute',
    marginLeft: 60,
    marginTop: 500,
  },
  wordCount: {
    backgroundColor: '#FFFFFF',
    width: 812,
    height: 30,
    marginLeft: 29,
    paddingLeft: 30,
    paddingTop: 10,
    position: 'absolute',
    marginTop: 510,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderColor: '#ECEEF7',
    borderBottomWidth: 2,
    borderLeftWidth: 2,
    borderRightWidth: 2,
  },
  title: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 22,
    lineHeight: 28,
    color: '#0D1738',
    marginBottom: 16,
  },
  smallText: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 10.5,
    lineHeight: 10.5,
    color: '#6E757C',
  },
  sectionsEdit: {
    display: 'flex',
    flexDirection: 'row',
  },
  titleEdit: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    lineHeight: 14.52,
    color: '#0D1738',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: '#C9CCCF',
    backgroundColor: '#FFFFFF',
    paddingVertical: 10,
    paddingHorizontal: 15,
    width: 834,
  },
  summaryText: {
    width: 808,
    height: 139,
    backgroundColor: '#FFFFFF',
    borderColor: '#ECEEF7',
    borderRadius: 8,
    borderWidth: 2,
    marginTop: 10,
  },
  metaData: {
    width: 808,
    height: 374,
    backgroundColor: '#FFFFFF',
    borderColor: '#ECEEF7',
    borderRadius: 8,
    borderWidth: 2,
    marginTop: 10,
    marginLeft: 29,
  },
  documentUpload: {
    width: 808,
    backgroundColor: '#FFFFFF',
    borderColor: '#ECEEF7',
    borderRadius: 8,
    borderWidth: 2,
    marginTop: 10,
    marginLeft: 29,
  },
  saveBtn: {
    width: 258,
    height: 38,
    backgroundColor: '#8497AD',
    borderRadius: 8,
    marginLeft: 30,
  },
  saveText: {
    marginLeft: 86,
    marginTop: 11,
    width: 86,
    height: 15,
    textAlign: 'center',
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 12,
    lineHeight: 14.52,
    color: '#FFFFFF',
  },
  publishTools: {
    width: 315,
    ...(Platform.OS === 'web' && { height: 'max-content' }),
    paddingHorizontal: 20,
    borderColor: '#ECEEF6',
    borderRadius: 8,
    borderWidth: 2,
    backgroundColor: '#FFFFFF',
    marginLeft: 40,
    paddingBottom: 22,
    marginBottom: 20,
  },
  coverImage: {
    width: 315,
    height: 247,
    borderColor: '#ECEEF6',
    borderRadius: 8,
    borderWidth: 2,
    backgroundColor: '#FFFFFF',
    marginLeft: 40,
    paddingHorizontal: 20,
  },
  subTitle: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 20,
    color: '#202223',
    marginTop: 20,
    marginBottom: 4,
  },
  subTitleSpeaker: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 16,
    lineHeight: 20,
    color: '#202223',
    marginBottom: 8,
  },
  subTitleSmall: {
    fontFamily: 'OpenSans_500Medium',
    fontSize: 15,
    lineHeight: 18.15,
    color: '#0D1738',
    marginLeft: 35,
    marginTop: 14,
    width: 187,
    borderBottomWidth: 0.5,
    borderBottomColor: '#ECEEF7',
    paddingBottom: 11,
    marginBottom: 8,
  },
  summaryEdit: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    lineHeight: 14.52,
    color: '#0D1738',
    borderWidth: 2,
    borderRadius: 8,
    borderColor: '#ECEEF7',
    backgroundColor: '#FFFFFF',
    marginLeft: 29,
    paddingTop: 10,
    paddingHorizontal: 15,
    width: 656,
    height: 72,
  },
  wordCountSummary: {
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    fontFamily: 'OpenSans_400Regular',
    fontSize: 7.8,
    lineHeight: 9.44,
    color: '#6E757C',
    marginTop: 109,
    marginLeft: 605,
  },
  wordCountSummaryFail: {
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    fontFamily: 'OpenSans_400Regular',
    fontSize: 7.8,
    lineHeight: 9.44,
    color: '#FF0000',
    marginTop: 109,
    marginLeft: 605,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  smallSave: {
    textAlign: 'center',
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 12,
    lineHeight: 14.52,
    color: '#FFFFFF',
    width: 92,
    marginLeft: 6,
    marginTop: 1,
    backgroundColor: '#8497AD',
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 8,
  },
  fieldTitle: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#202223',
    marginBottom: 4,
  },
  seoTitlesWrapper: {
    width: 751,
    height: 50,
    borderWidth: 2,
    borderColor: '#ECEEF6',
    borderRadius: 8,
    marginLeft: 35,
    paddingHorizontal: 10,
    paddingTop: 6,
  },
  tagsInput: {
    backgroundColor: '#FFFFFF',
    overflowY: 'scroll',
    outline: 'none',
    borderColor: '#ECEEF7',
  },
  tagTextStyle: {
    fontFamily: 'OpenSans_500Medium',
    fontSize: 12,
    lineHeight: 14.52,
    color: '#6E757C',
  },
  tagContainerStyle: {
    borderWidth: 1,
    borderColor: '#ECEEF7',
    borderRadius: 15,
    paddingHorizontal: 10,
    paddingVertical: 3,
    marginRight: 8,
  },
  slugWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  inputSlug: {
    width: 446,
    height: 38,
    borderWidth: 2,
    borderColor: '#ECEEF7',
    borderRadius: 8,
    padding: 10,
    marginTop: -13,
  },
  inputMetaDesc: {
    fontFamily: 'OpenSans_500Medium',
    fontSize: 12,
    lineHeight: 14.52,
    color: '#6E757C',
    borderWidth: 2,
    borderColor: '#ECEEF7',
    borderRadius: 8,
    marginLeft: 34,
    marginTop: 0,
    width: 751,
    height: 46,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  addBtn: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 18,
    lineHeight: 14.52,
    color: '#4A4A4A',
    backgroundColor: '#F1F1F1',
    borderRadius: 15,
    paddingVertical: 3,
    paddingHorizontal: 6,
  },
  addTitle: {
    fontFamily: 'OpenSans_500Medium',
    fontSize: 12,
    lineHeight: 14.52,
    color: '#0D1738',
    marginRight: 10,
    marginTop: 3,
  },
  addBtnDoc: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    marginLeft: 639,
    marginTop: 15,
  },
  addBtnVideo: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    marginLeft: 639,
    marginTop: 153,
  },
  fileUploader: {
    marginTop: 12,
    marginBottom: 12,
  },
  backgroundUpload: {
    width: 303,
    height: 65,
    backgroundColor: '#F2F4F8',
    borderRadius: 5,
    cursor: 'pointer',
  },
  innerUpload: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#8497AD',
    borderStyle: 'dashed',
    marginHorizontal: 8,
    marginVertical: 6,
    width: 287,
    height: 54,
  },
  uploadImg: {
    width: 21,
    height: 21,
    marginTop: 15,
    marginRight: 10,
  },
  dropText: {
    fontFamily: 'OpenSans_700Bold',
    fontSize: 12,
    lineHeight: 24,
    color: '#6E757C',
    marginTop: 9,
  },
  formatFile: {
    fontFamily: 'OpenSans_500Medium',
    fontSize: 8.7,
    lineHeight: 24,
    color: '#A3A9B0',
    marginTop: -9,
  },
  uploadRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  inputTitleFile: {
    width: 328,
    height: 38,
    borderWidth: 2,
    borderColor: '#ECEEF7',
    borderRadius: 8,
    marginLeft: 12,
    paddingHorizontal: 10,
  },
  overflowDocs: {
    height: 90,
    // overflowY: 'scroll'
  },
  hideScrollBar: {
    width: 17,
    height: 255,
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    marginTop: 30,
    marginLeft: 787,
    zIndex: 1,
    borderBottomRightRadius: 8,
  },
  publishRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  descWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  StatusImg: {
    width: 15,
    height: 15,
    marginLeft: 17,
    marginTop: 4,
  },
  publishTitle: {
    fontFamily: 'OpenSans_500Medium',
    fontSize: 12,
    lineHeight: 24,
    color: '#6E757C',
    marginLeft: 6,
  },
  publishDropdown: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#F1F1F1',
    borderRadius: 25,
    width: 136,
    height: 27,
    marginRight: 13,
  },
  publishDropdownText: {
    fontFamily: 'OpenSans_500Medium',
    fontSize: 12,
    lineHeight: 21,
    color: '#6E757C',
    paddingHorizontal: 14,
    paddingVertical: 3,
  },
  polygonSmall: {
    width: 8,
    height: 6,
    marginTop: 11,
    marginRight: 11,
  },
  DateImg: {
    width: 15,
    height: 15,
    marginLeft: 17,
    marginTop: 4,
  },
  publishDateText: {
    fontFamily: 'OpenSans_500Medium',
    fontSize: 12,
    lineHeight: 21,
    color: '#6E757C',
    marginLeft: -45,
  },
  writeImg: {
    width: 11,
    height: 11,
  },
  datePicker: {
    width: 100,
    display: 'flex',
    flexDirection: 'row',
  },
  datePickerBg: {
    backgroundColor: '#F1F1F1',
    padding: 5,
    borderRadius: 15,
    height: 20,
    width: 20,
    marginRight: 46,
    marginLeft: 7,
    marginTop: 1,
  },
  CategoryImg: {
    width: 13,
    height: 13,
    marginLeft: 18,
    marginTop: 4,
  },
  squareTagsView: {
    width: 228,
    height: 75,
    marginVertical: 11,
    marginHorizontal: 15,
    borderWidth: 2,
    borderColor: '#ECEEF7',
    borderRadius: 8,
  },
  UserTypeImg: {
    width: 12,
    height: 12,
    marginLeft: 18,
    marginTop: 4,
  },
  checkBoxRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 25,
    marginLeft: -15,
  },
  checkBoxText: {
    fontFamily: 'OpenSans_500Medium',
    fontSize: 12,
    lineHeight: 24,
    color: '#6E757C',
    marginLeft: 5,
    marginTop: -5,
  },
  JobRolesImg: {
    width: 19,
    height: 19,
    marginLeft: 15,
  },
  ContentTypeImg: {
    width: 16,
    height: 16,
    marginLeft: 18,
    marginTop: 2,
  },
  publishRowContent: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
  },
  squareTagsInput: {
    width: 228,
    height: 62,
    borderRadius: 8,
    borderColor: '#ECEEF7',
    borderWidth: 2,
    marginLeft: 17,
    paddingHorizontal: 10,
    paddingVertical: 8,
  },
  wordCountSmall: {
    position: 'absolute',
    fontFamily: 'OpenSans_500Medium',
    fontSize: 7.8,
    lineHeight: 9.44,
    color: '#6E757C',
    marginTop: 573,
    marginLeft: 169,
    backgroundColor: '#FFFFFF',
  },
  coverUpload: {
    backgroundColor: '#F0F2F4',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    width: 223,
    height: 133,
    marginLeft: 16,
    marginTop: 10,
  },
  uploadImgCover: {
    width: 26,
    height: 26,
    marginBottom: 15,
  },
  deleteImg: {
    width: 12,
    height: 15,
  },
  DeleteWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 6,
    marginLeft: 17,
  },
  deleteBtnText: {
    fontFamily: 'OpenSans_500Medium',
    fontSize: 12,
    lineHeight: 14.52,
    color: '#6E757C',
    marginLeft: 5,
    marginTop: 2,
  },
  speakerContainer: {
    height: 500,
    ...(Platform.OS === 'web' && { height: 'max-content' }),
  },
  headerSpeaker: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderColor: '#ECEEF7',
    marginTop: 20,
    marginBottom: 20,
  },
  addBtnSpeaker: {
    display: 'flex',
    flexDirection: 'row',
    height: 21,
    marginTop: 15,
  },
  speakerSubRow: {
    // display: 'flex',
    // flexDirection: 'row'
    marginBottom: 16,
  },
  nameInput: {
    height: 29,
    borderColor: '#ECEEF7',
    borderWidth: 2,
    borderRadius: 8,
    marginTop: 4,
    marginBottom: 16,
    paddingHorizontal: 12,
    paddingVertical: 8,
    fontFamily: 'OpenSans_500Medium',
    fontSize: 12,
    lineHeight: 14.52,
    color: '#6E757C',
  },
  aboutInput: {
    height: 60,
    borderColor: '#ECEEF7',
    borderWidth: 2,
    borderRadius: 8,
    paddingHorizontal: 12,
    paddingVertical: 8,
    fontFamily: 'OpenSans_500Medium',
    fontSize: 12,
    lineHeight: 14.52,
    color: '#6E757C',
    marginBottom: 16,
  },
  socialRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
  },
  linkInput: {
    backgroundColor: '#F1F1F1',
    borderRadius: 25,
    width: 152,
    height: 27,
    marginLeft: 5,
    paddingHorizontal: 18,
  },
  avatarImg: {
    width: 54,
    height: 54,
    marginTop: 20,
    marginLeft: 39,
  },
  fb: {
    width: 20,
    height: 20,
    marginLeft: 42,
    marginTop: 3,
  },
  is: {
    width: 20,
    height: 20,
    marginLeft: 15,
    marginTop: 3,
  },
  in: {
    width: 20,
    height: 20,
    marginLeft: 15,
    marginTop: 4,
  },
  tw: {
    width: 20,
    height: 20,
    marginLeft: 42,
    marginTop: 4,
  },
  speakerRow: {
    marginTop: 30,
    paddingTop: 30,
    borderColor: '#ECEEF7',
    borderTopWidth: 0.5,
  },
  EventDetails: {
    ...(Platform.OS === 'web' && { height: 'max-content' }),
    marginTop: 20,
    borderWidth: 2,
    borderColor: '#ECEEF6',
    borderRadius: 8,
  },
  EventRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: 16,
  },
  EventLeft: {
    display: 'flex',
    flexDirection: 'row',
  },
  EventTypeImg: {
    width: 18,
    height: 18,
    marginTop: 3,
  },
  TicketImg: {
    width: 19,
    height: 19,
    marginTop: 2,
  },
  CalendarImg: {
    width: 15,
    height: 15,
    marginTop: 2,
    marginLeft: 2,
    marginRight: 3,
  },
  TimeImg: {
    width: 18,
    height: 18,
    marginTop: 3,
    marginLeft: 2,
    marginRight: 1,
  },
  CityImg: {
    width: 14,
    height: 14,
    marginTop: 4,
    marginLeft: 4,
    marginRight: 4,
  },
  switchBulk: {
    marginTop: 2,
  },
  amountTicket: {
    backgroundColor: '#F1F1F1',
    borderRadius: 25,
    width: 80,
    height: 27,
    fontFamily: 'OpenSans_500Medium',
    fontSize: 12,
    lineHeight: 24,
    color: '#6E757C',
    paddingHorizontal: 13,
    paddingVertical: 2,
  },
  smallIndication: {
    fontFamily: 'OpenSans_500Medium',
    fontSize: 9,
    lineHeight: 21,
    color: '#0D1738',
  },
  calendarInput: {
    backgroundColor: '#F1F1F1',
    borderRadius: 25,
    width: 66,
    height: 27,
  },
  cityDropdown: {
    backgroundColor: '#F1F1F1',
    borderRadius: 25,
    width: 136,
    height: 27,
    fontFamily: 'OpenSans_500Medium',
    fontSize: 12,
    lineHeight: 21,
    color: '#6E757C',
    paddingHorizontal: 10,
    paddingVertical: 3,
  },
  container: {
    width: 808,
    ...(Platform.OS === 'web' && { height: 'max-content' }),
    borderColor: '#ECEEF6',
    borderRadius: 8,
    borderWidth: 2,
    marginLeft: 29,
    marginTop: 13,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  key: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 12,
    lineHeight: 15,
    color: '#0D1738',
    width: 81,
    marginLeft: 16,
  },
  addBtnPlus: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 17,
    lineHeight: 15,
    color: '#0D1738',
    backgroundColor: '#F1F1F1',
    borderRadius: 15,
    width: 21,
    height: 21,
    paddingHorizontal: 5,
    paddingVertical: 2,
    marginTop: 9,
    marginLeft: 10,
  },
  text: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#202223',
    marginBottom: 20,
  },
  createBtnWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: 32,
    width: 117,
    borderRadius: 4,
    marginTop: 20,
    backgroundColor: '#0D1738',
    justifyContent: 'center',
    alignItems: 'center',
  },
  createBtn: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 20,
    color: '#FFFFFF',
  },
  formLabelText: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 16,
    marginBottom: 4,
  },
  label: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    color: '#202223',
    marginBottom: 4,
  },
});
