import { textStyles } from '@styles/text';
import { Field, FieldProps } from 'formik';
import React from 'react';
import { Text } from 'react-native';

import { styles } from './style';

interface FormikTextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  fieldName: string;
  label?: string;
}

const FormikTextArea = ({ fieldName, label, maxLength, ...inputProps }: FormikTextAreaProps) => {
  return (
    <>
      {label ? (
        <label htmlFor={fieldName} style={styles.label}>
          {label}
        </label>
      ) : null}
      <Field name={fieldName}>
        {({ field, form }: FieldProps) => {
          const { getFieldMeta, values } = form;
          const characterCount = values[fieldName] ? values[fieldName].length : 0;

          const meta = getFieldMeta(fieldName);

          return (
            <>
              <div style={{ position: 'relative' }}>
                <textarea
                  id={fieldName}
                  style={styles.inputText}
                  name={fieldName}
                  rows={4}
                  value={field.value}
                  maxLength={maxLength}
                  {...inputProps}
                  onBlur={field.onBlur}
                  onChange={(event) => {
                    const { value } = event.target;
                    form.setFieldValue(fieldName, value);
                  }}
                />
                {maxLength ? (
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 10,
                      right: 10,
                    }}
                  >
                    <Text style={textStyles.subduedTextSmall}>
                      Characters: {characterCount}/{maxLength}
                    </Text>
                  </div>
                ) : null}
              </div>
              {meta.error && meta.touched ? <Text style={textStyles.error}>{meta.error}</Text> : null}
              {/* Imperatively setting errors using Formik has
                  issue so we show this message to users but don't
                  properly set the error and prevent form submission.
                  https://github.com/jaredpalmer/formik/issues/1278
              */}
              {maxLength && characterCount > maxLength ? <Text style={textStyles.error}>Maximum word count exceeded</Text> : null}
            </>
          );
        }}
      </Field>
    </>
  );
};

export { FormikTextArea };
