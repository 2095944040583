import { Assignments } from '@components/general/icons/account-icons/Assignment';
import { MembersIcon } from '@components/general/icons/account-icons/MembersIcon';
import { NotificationIcon } from '@components/general/icons/account-icons/NotificationIcon';
import { SettingsIcon } from '@components/general/icons/account-icons/SettingsIcon';
import React from 'react';

interface NavItemType {
  icon?: React.ReactNode;
  title: string;
  navLink: string;
  adminOnly?: boolean;
}

export interface AccountProps {
  options: NavItemType[];
}
export const userNavItems: NavItemType[] = [
  {
    icon: <SettingsIcon />,
    title: 'Settings',
    navLink: '/account/settings',
  },
  {
    icon: <NotificationIcon />,
    title: 'Notifications',
    navLink: '/account/notification-settings',
  },
];

export const organizationNavItems: NavItemType[] = [
  {
    icon: <Assignments />,
    title: 'Assignments',
    navLink: '/org/assignment',
  },
  {
    icon: <MembersIcon />,
    title: 'Members',
    navLink: '/org/members',
  },
  {
    icon: <SettingsIcon />,
    title: 'Settings',
    navLink: '/org/settings',
    adminOnly: true,
  },
];
