import { useUserContext } from '@context/UserContext';
import {
  TrainingSortBy,
  SortBy,
  useGetFeaturedCardEventsQuery,
  useGetFeaturedCardTrainingsQuery,
  useGetAllCardEventsQuery,
  useGetAllCardTrainingsQuery,
  useGetAllCardContentQuery,
  Training,
  Event,
  DocumentContentType,
  UserType,
} from '@gql/generated/generated';
import { FilterActionType, useFilters } from '@hooks/useFilters';
import { FilterOptions, PageVariant } from '@utils/models';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-native';

const eventFilterOptions = [
  FilterOptions.sortBy,
  FilterOptions.category,
  FilterOptions.date,
  FilterOptions.memberType,
  FilterOptions.jobRole,
  FilterOptions.eventType,
];

const trainingFilterOptions = [
  FilterOptions.sortBy,
  FilterOptions.category,
  FilterOptions.duration,
  FilterOptions.date,
  FilterOptions.memberType,
  FilterOptions.jobRole,
  FilterOptions.popularity,
];

const contentFilterOptions = [
  FilterOptions.sortBy,
  FilterOptions.category,
  FilterOptions.date,
  FilterOptions.popularity,
  FilterOptions.memberType,
  FilterOptions.length,
  FilterOptions.jobRole,
  FilterOptions.contentType,
];

const variants = {
  [PageVariant.training]: {
    initialFilterValue: TrainingSortBy.RECOMMENDED,
    filterOptions: trainingFilterOptions,
    title: 'Training',
  },
  [PageVariant.event]: {
    initialFilterValue: SortBy.MOST_RECENT,
    filterOptions: eventFilterOptions,
    title: 'Events',
  },
  [PageVariant.content]: {
    initialFilterValue: SortBy.MOST_RECENT,
    filterOptions: contentFilterOptions,
    title: 'Content',
  },
};

export const useGetCardContent = ({ variant }: { variant: PageVariant.training | PageVariant.content | PageVariant.event }) => {
  const { isFreeUser } = useUserContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const [pageSelected, setPageSelectedInner] = useState(1);
  const setPageSelected = useMemo(
    () => (page: number) => {
      setSearchParams((params) => {
        params.set('selectedPage', page.toString());
        return params;
      });
      setPageSelectedInner(page);
    },
    [setSearchParams]
  );

  useEffect(() => {
    const selectedPage = Number(searchParams.get('selectedPage') || '1');
    setPageSelectedInner(selectedPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant]);

  const [filters, setFilters] = useFilters(variants[variant].initialFilterValue);

  // Dispatch an update for Free user filtering into the useFilters reducer.
  // NB isFreeUser will only potentially return true after the useReducer has
  // already been created in useFilters - so this can't be set in the initalState for useFilters reducer.
  // NB this filter value will be used in both a refetch of the filtered card data and by the <Filters>
  // UI component in <IndexPageLayout.web>
  useEffect(() => {
    if (isFreeUser) {
      setFilters({
        type: FilterActionType.SET_MEMBER_TYPE,
        payload: [UserType.FREE],
      });
    }
  }, [isFreeUser, setFilters]);

  const filterOptions = variants[variant].filterOptions;

  const [toastMessage, setToastMessage] = useState<string>('');

  const { data: highlightedEventsData, error: highlightedEventsError } = useGetFeaturedCardEventsQuery({
    variables: {
      page: 1,
      limit: 10,
      filters: {
        isHighlighted: true,
      },
    },
    skip: variant !== PageVariant.event,
  });

  const { data: highlightedTrainingsData, error: highlightedTrainingsError } = useGetFeaturedCardTrainingsQuery({
    variables: {
      page: 1,
      limit: 10,
      filters: {
        isHighlighted: true,
      },
    },
    skip: variant !== PageVariant.training,
  });

  const {
    data: eventsData,
    loading,
    error: eventError,
  } = useGetAllCardEventsQuery({
    variables: {
      page: pageSelected,
      limit: 9,
      filters: {
        ...(filters?.jobRole.length > 0 && { jobRoles: filters?.jobRole }),
        ...(filters?.memberType.length > 0 && {
          userTypes: filters?.memberType,
        }),
        ...(filters?.category.length > 0 && { categories: filters?.category }),
        ...(filters?.eventType.length > 0 && {
          eventTypes: filters?.eventType,
        }),
        ...(filters?.sortBy && { sortBy: filters?.sortBy as SortBy }),
        ...(filters?.date && {
          dateTo: filters.date.end,
          dateFrom: filters.date.start,
        }),
      },
    },
    skip: variant !== PageVariant.event,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: trainingData,
    loading: trainingLoading,
    error: trainingsError,
  } = useGetAllCardTrainingsQuery({
    variables: {
      page: pageSelected,
      limit: 9,
      filters: {
        ...(filters?.jobRole.length > 0 && { jobRoles: filters?.jobRole }),
        ...(filters?.memberType.length > 0 && {
          userTypes: filters?.memberType,
        }),
        ...(filters?.category.length > 0 && { categories: filters?.category }),
        ...(filters?.sortBy && { sortBy: filters?.sortBy as TrainingSortBy }),
        ...(filters?.popularity.length > 0 && {
          popularity: filters?.popularity[0],
        }),
        ...(filters?.date && {
          dateTo: filters.date.end,
          dateFrom: filters.date.start,
        }),
      },
    },
    skip: variant !== PageVariant.training,
  });

  const { loading: contentLoading, data: contentData } = useGetAllCardContentQuery({
    variables: {
      page: pageSelected,
      limit: 9,
      filters: {
        status: 'Publish',
        ...(filters?.category.length > 0 && {
          categories: filters?.category,
        }),
        ...(filters?.date?.start && {
          dateFrom: filters.date.start,
          dateTo: filters.date.end,
        }),
        ...(filters?.popularity.length > 0 && {
          popularity: Number(filters?.popularity[0]),
        }),
        ...(filters?.memberType.length > 0 && {
          userTypes: filters?.memberType,
        }),
        ...(filters?.length.length > 0 && {
          length: filters?.length,
        }),
        ...(filters?.jobRole.length > 0 && {
          jobRoles: filters?.jobRole,
        }),
        ...(filters?.contentType.length > 0 && {
          contentTypes: filters?.contentType as DocumentContentType[],
        }),
        ...(filters?.sortBy && {
          sortBy: filters?.sortBy as SortBy,
        }),
      },
    },
    skip: variant !== PageVariant.content,
  });

  const content = contentData?.getAllContent.content;

  const isLoading = trainingLoading || loading;

  const events = eventsData?.getAllEvents?.events;

  const highlightedEvents = highlightedEventsData?.getAllEvents.events;
  const highlightedTrainings = highlightedTrainingsData?.getAllTrainings.trainings;

  const queryError = eventError || highlightedEventsError || trainingsError || highlightedTrainingsError;

  useEffect(() => {
    if (queryError) {
      setToastMessage('Something went wrong');
    }
  }, [queryError]);

  const highlightedContent = highlightedEvents || highlightedTrainings;

  const sortedHighlightedContent =
    (highlightedContent?.length &&
      ([...highlightedContent]?.sort((a, b) => {
        if (a.highlightedRank && b.highlightedRank) {
          return a.highlightedRank - b.highlightedRank;
        }
        return 0;
      }) as Event[] | Training[])) ||
    [];

  const trainings = trainingData?.getAllTrainings?.trainings;

  const hasNextPageTraining = trainingData?.getAllTrainings?.hasNextPage;
  const hasNextPageEvents = eventsData?.getAllEvents?.hasNextPage;
  const hasNextPageContent = contentData?.getAllContent?.hasNextPage;

  const hasNextPage = hasNextPageTraining || hasNextPageEvents || hasNextPageContent;

  const data = useMemo(() => {
    switch (variant) {
      case PageVariant.content:
        return content;

      case PageVariant.event:
        return events;

      case PageVariant.training:
        return trainings;
    }
  }, [content, events, trainings, variant]);

  return {
    data,
    hasNextPageTraining,
    hasNextPageEvents,
    sortedHighlightedContent,
    isLoading,
    setPageSelected,
    setFilters,
    filters,
    toastMessage,
    pageSelected,
    filterOptions,
    loading,
    trainingLoading,
    title: variants[variant].title,
    contentData,
    contentLoading,
    hasNextPage,
    setToastMessage,
  };
};
