import { AnalyticsTrainingItem } from '@components/org/analytics/analytics-training-item';
import { useUserContext } from '@context/UserContext';
import { EmployerUserOverview } from '@gql/generated/generated';
import { Pagination } from '@shopify/polaris';
import React, { useState } from 'react';
import { ActivityIndicator, Text, View } from 'react-native';

import { styles } from './style';

const TableHead = () => (
  <View style={styles.cellWrap}>
    <View style={styles.firstItemCell}>
      <Text style={styles.cellTitle}>Name</Text>
    </View>
    <View style={styles.itemCellTwo}>
      <Text style={styles.cellTitle}>Job Title</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellTitle}>Training Total</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellTitle}>#Courses Enrolled</Text>
    </View>
    <View style={styles.lastItemCell} />
  </View>
);

interface Props {
  loading: boolean;
  users: EmployerUserOverview[] | undefined;
  setPage: (value: number) => void;
  page: number;
  refetch: (
    args: Partial<{
      input: {
        page: number;
        limit: number;
        filters: {
          organisation: string;
        };
      };
    }>
  ) => void;
}

const AnalyticsTrainingProgress = ({ loading, users, setPage, page, refetch }: Props) => {
  const { currentUser } = useUserContext();

  const limit = 10;

  const variables = {
    input: {
      page,
      limit,
      filters: {
        organisation: currentUser?.accountInfo?.companyInfo?.id || '',
      },
    },
  };

  const handleExpand = (index: number) => {
    setExpandedIndex(expandedIndex === index ? undefined : index);
  };

  const [expandedIndex, setExpandedIndex] = useState<number>();

  return (
    <View style={styles.wrap}>
      <View style={styles.titleWrap}>
        <Text style={styles.title}>Training Progress</Text>
      </View>
      <View style={styles.tableWrap}>
        <View style={styles.tableInner}>
          <TableHead />
          {loading && !users?.length ? (
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ActivityIndicator size="large" color="#213470" />
            </View>
          ) : null}
          {users?.map((item, index) => (
            <View key={item.id}>
              <AnalyticsTrainingItem {...item} onExpand={() => handleExpand(index)} isExpanded={index === expandedIndex} />
              {index === users.length - 1 ? <View style={styles.lastCellBorder} /> : null}
            </View>
          ))}
          <View style={styles.pagination}>
            <Pagination
              hasPrevious={page > 1}
              onPrevious={() => {
                setPage(page - 1);
                refetch({
                  input: {
                    ...variables.input,
                    page: page - 1,
                  },
                });
              }}
              // Making an assumption that if the api returns the limit, there is probably another page
              hasNext={users?.length === limit}
              onNext={() => {
                setPage(page + 1);
                refetch({
                  input: {
                    ...variables.input,
                    page: page + 1,
                  },
                });
              }}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export { AnalyticsTrainingProgress };
