import { FilterListItem } from '@components/filter-mobile/filter-list-item';
import { IndexPageHeader } from '@components/general/index-page-header';
import { Wrapper } from '@components/general/layouts/wrapper/Wrapper';
import { Pill, PillVariant } from '@components/general/pill';
import { PageVariant } from '@utils/models';
import React from 'react';
import { useNavigate, useParams } from 'react-router-native';

const SavedTypeNative = () => {
  const navigate = useNavigate();
  const { type } = useParams();

  const getTitle = () => {
    if (type === 'org') {
      return 'Saved to my organisation';
    } else {
      return 'Saved to me';
    }
  };

  return (
    <Wrapper>
      <IndexPageHeader title={getTitle()} onPressBack={() => navigate(-1)} />
      <FilterListItem
        title="Content"
        onPress={() =>
          navigate('/org/saved/list', {
            state: { variant: PageVariant.content },
          })
        }
        rightContent={<Pill text="3" variant={PillVariant.LightBlue} />}
      />
      <FilterListItem
        title="Event"
        onPress={() => navigate('/org/saved/list', { state: { variant: PageVariant.event } })}
        rightContent={<Pill text="15" variant={PillVariant.LightBlue} />}
      />
      <FilterListItem
        title="Training"
        onPress={() =>
          navigate('/org/saved/list', {
            state: { variant: PageVariant.training },
          })
        }
        rightContent={<Pill text="15" variant={PillVariant.LightBlue} />}
      />
    </Wrapper>
  );
};

export { SavedTypeNative };
