import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  wrap: {
    borderTopWidth: 1,
    borderTopColor: '#EDEEEF',
    paddingBottom: 20,
  },
  subTitle: {
    fontSize: 14,
    lineHeight: 20,
    color: '#6D7175',
    fontFamily: 'OpenSans_400Regular',
    marginLeft: 40,
    marginTop: 20,
    marginBottom: 16,
  },
  accordionOption: {
    paddingHorizontal: 16,
    paddingBottom: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  accordionOptionText: {
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    color: '#D72C0D',
    marginBottom: 8,
  },
  accordionEdit: {
    color: '#2C6ECB',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
  },
  accordionOptionEditWrap: {
    paddingHorizontal: 16,
    paddingBottom: 12,
    marginTop: 8,
  },
  accordionOptionEditMenu: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  accordionOptionEditMenuText: {
    fontSize: 14,
    lineHeight: 20,
    marginLeft: 8,
    fontFamily: 'OpenSans_400Regular',
  },
});
