import { Col } from '@components/general/col';
import { TrashIcon } from '@components/general/icons/org-icons';
import { Row } from '@components/general/row';
import { Toast } from '@components/general/toast';
import { useUserContext } from '@context/UserContext';
import { AssetType, useCreateAssetMutation, useDeleteAssetMutation, useUpdateAssetMutation } from '@gql/generated/generated';
import { useGetFile } from '@hooks/useGetFileUrl';
import { DropZone, InlineError, Spinner } from '@shopify/polaris';
import { deleteFileFromStorage, uploadFile as uploadFileToStorage } from '@utils/firebase';
import mime from '@utils/mime-db-1.52.0-filtered-minified.json';
import { customFirestoreId } from '@utils/misc';
import { useFormikContext } from 'formik';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { CurrentAssetThumbnail } from './CurrentAssetThumbnail';
import type { Asset, FileUploadContextProps, FileUploadContextProviderType, GenericDropZoneProps, MimeType } from './GenericDropzoneTypes';
import { styles } from './style';
import { convertMimeTypeToAssetType } from './utils';

const FileUploadContext = React.createContext<FileUploadContextProps>(null as any);

export const FileUploadContextProvider = ({ children, assetInstruction, initialAsset }: FileUploadContextProviderType) => {
  const assetIdRef = useRef(initialAsset?.id ?? customFirestoreId());
  const [filename, setFilename] = useState<string>(initialAsset?.name ?? '');
  const [isUploading, setIsUploading] = useState(false);
  const [asset, setAsset] = useState<Asset | null>(initialAsset);
  const [error, setError] = useState<string | null>(null);
  const [localFile, setLocalFile] = useState<File | null>(null);

  const [createAsset] = useCreateAssetMutation();
  const [updateAssetMutation] = useUpdateAssetMutation();
  const [deleteAssetMutation] = useDeleteAssetMutation();

  useEffect(() => {
    if (!initialAsset) return;
    setAsset(initialAsset);
    setFilename(initialAsset.name);
    assetIdRef.current = initialAsset.id;
  }, [initialAsset]);

  const updateAsset = async () => {
    if (!asset) return;
    try {
      setError(null);
      if (asset?.name !== filename && filename.length > 0) {
        return updateAssetMutation({
          variables: {
            input: {
              id: assetIdRef.current,
              name: filename,
            },
          },
        });
      }
    } catch (e) {
      setError('Update failed');
    }
  };

  const deleteAsset = async () => {
    setError(null);
    try {
      if (asset) {
        await deleteAssetMutation({
          variables: {
            id: asset.id,
          },
        });

        setLocalFile(null);
        setAsset(null);
        setFilename('');
        const path = asset.storagePath;
        await deleteFileFromStorage(path);
      }
    } catch (e) {
      setError('Delete failed');
    }
  };

  const { currentUser } = useUserContext();

  const uploadFile = useCallback(
    async (fileToUpload: File, duration?: number | null) => {
      try {
        setError(null);
        setIsUploading(true);

        const trimmedFilename = filename ? filename.trim() : fileToUpload.name.trim();

        if (!filename) {
          setFilename(trimmedFilename);
        }
        const fileExtension = fileToUpload.name.split('.').slice(-1);

        const storagePath = `Users/${currentUser?.id}/${assetIdRef.current}.${fileExtension}`;
        await uploadFileToStorage(fileToUpload, storagePath);

        const asset = await createAsset({
          variables: {
            input: {
              id: assetIdRef.current,
              storagePath,
              assetType: convertMimeTypeToAssetType(fileToUpload.type),
              ...assetInstruction,
              size: fileToUpload.size,
              type: fileToUpload.type,
              name: trimmedFilename,
              ...(duration ? { duration } : {}),
            },
          },
        });

        if (!asset.data?.createAsset) {
          throw new Error('No asset');
        }

        setAsset(asset.data.createAsset);
        setLocalFile(null);
      } catch (error) {
        setError('Upload failed');
      } finally {
        setIsUploading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assetInstruction.collectionId, assetInstruction.documentId, assetInstruction.instructionType, createAsset, currentUser?.id, filename]
  );

  return (
    <FileUploadContext.Provider
      value={{
        setFilename,
        filename,
        isUploading,
        uploadFile,
        asset,
        error,
        updateAsset,
        deleteAsset,
        localFile,
        setLocalFile,
      }}
    >
      {children}
    </FileUploadContext.Provider>
  );
};

// Can decorate a form by adding info about files being uploaded
const FileUploadFormDecorator = ({ fieldName }: { fieldName: string }) => {
  const { asset } = useContext(FileUploadContext);
  const { setFieldValue } = useFormikContext<any>();

  useEffect(() => {
    setFieldValue(fieldName, asset);
  }, [asset, fieldName, setFieldValue]);

  return null;
};

const FileUploadFilenameInput = ({ title, placeholder }: { title: string; placeholder: string }) => {
  const { setFilename, filename, updateAsset } = useContext(FileUploadContext);
  return (
    <View style={{ marginBottom: 8 }}>
      <label htmlFor="Title" style={styles.label}>
        {title}
      </label>

      <input
        id="Title"
        style={styles.inputText}
        value={filename}
        placeholder={placeholder}
        onChange={(event) => {
          const { value } = event.target;
          setFilename(value);
        }}
        onBlur={updateAsset}
      />
    </View>
  );
};

const DeleteButton = ({ onPress }: { onPress: () => void }) => {
  return (
    <TouchableOpacity
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 6,
        alignSelf: 'flex-end',
      }}
      onPress={onPress}
    >
      <TrashIcon fill="#6D7175" />
      <Text style={styles.deleteText}>Delete</Text>
    </TouchableOpacity>
  );
};

const defaultMIMETypes: MimeType[] = [
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'video/mp4',
  'image/gif',
  'image/jpeg',
  'image/png',
];

const GenericDropZone = ({
  style,
  actionHint,
  validFileTypes = defaultMIMETypes,
  removeFromUI,
  errorMessage,
  formikFieldname,
}: GenericDropZoneProps) => {
  const { isUploading, asset, uploadFile, error, deleteAsset, localFile, setLocalFile } = useContext(FileUploadContext);

  const [toastMessage, setToastMessage] = useState<string | null>(null);

  useEffect(() => {
    if (error) {
      setToastMessage(error);
    }
  }, [error]);

  const { fileUrl } = useGetFile(asset?.storagePath);

  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handleLoadedMetadata = useCallback(
    (file: File) => {
      if (!uploadFile) {
        return setToastMessage('Cannot upload file');
      }
      const video = videoRef.current;

      const duration = Number((video?.duration || 0).toFixed(0));

      return uploadFile(file, duration);
    },
    [uploadFile]
  );

  const handleDropZoneDrop = useCallback(
    async (_files: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
      if (!uploadFile) {
        return setToastMessage('Cannot upload file');
      }
      setToastMessage(null);
      const fileToUpload = acceptedFiles[0];
      if (validFileTypes?.includes(fileToUpload.type as MimeType)) {
        setLocalFile(fileToUpload);
        if (convertMimeTypeToAssetType(fileToUpload.type) !== AssetType.VIDEO) {
          return uploadFile(fileToUpload);
        }
      } else {
        setToastMessage('File type not valid');
      }
    },
    [setLocalFile, uploadFile, validFileTypes]
  );

  const uploadUI = useMemo(() => {
    if (asset && fileUrl) {
      return <CurrentAssetThumbnail id={asset.id} assetType={asset?.assetType} url={fileUrl} assetName={asset.name} />;
    }
    if (localFile) {
      return (
        <>
          <CurrentAssetThumbnail
            id={localFile.name}
            assetType={convertMimeTypeToAssetType(localFile.type)}
            url={window.URL.createObjectURL(localFile)}
            videoRef={videoRef}
            handleLoadedMetadata={() => handleLoadedMetadata(localFile)}
            assetName={localFile.name}
          />
          {isUploading ? (
            <Row style={styles.innerContainer}>
              <Spinner accessibilityLabel="Uploading spinner" size="large" />
            </Row>
          ) : null}
        </>
      );
    }

    const validExtensions = validFileTypes.map((type) => mime[type] || []).flat();

    const generatedActionHint = `Accepts .${validExtensions.slice(0, -1).join(', .')} and .${validExtensions.at(-1)}`;

    return <DropZone.FileUpload actionHint={actionHint || generatedActionHint} />;
  }, [actionHint, asset, fileUrl, handleLoadedMetadata, isUploading, localFile, validFileTypes]);

  const handleDelete = async () => {
    try {
      if (removeFromUI) {
        removeFromUI();
      }
      if (deleteAsset) {
        await deleteAsset();
      }
    } catch (e) {}
  };

  return (
    <Col style={style}>
      <DropZone disabled={isUploading || Boolean(asset)} variableHeight={false} onDrop={handleDropZoneDrop} allowMultiple={false} error>
        {uploadUI}
      </DropZone>
      {asset ? <DeleteButton onPress={handleDelete} /> : null}
      {!asset && !localFile && removeFromUI ? <DeleteButton onPress={removeFromUI} /> : null}
      {errorMessage && formikFieldname ? (
        <View style={{ marginTop: 8 }}>
          <InlineError message={errorMessage} fieldID={formikFieldname} />
        </View>
      ) : null}
      <Toast text={toastMessage || ''} active={!!toastMessage} onDismiss={() => setToastMessage(null)} error duration={5000} />
    </Col>
  );
};

export { GenericDropZone, FileUploadFilenameInput, FileUploadFormDecorator };
