import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    marginVertical: 20,
  },
  button: {
    borderColor: '#C9CCCF',
    borderWidth: 1,
    borderRadius: 5,
    paddingVertical: 8,
  },
  modalText: {
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    color: '#202223',
    lineHeight: 20,
    marginBottom: 4,
  },
  list: {
    maxHeight: 150,
    borderColor: '#EDEEEF',
    borderWidth: 1,
    borderRadius: 8,
  },
});
