import { FormikInput } from '@components/back-office/editor/formik-input';
import { ButtonGeneral, ButtonVariant } from '@components/general/button-general';
import { AuthWrapper } from '@components/sign-up/auth-wrapper';
import { useResetUserPasswordMutation } from '@gql/generated/generated';
import { textStyles } from '@styles/text';
import { Formik } from 'formik';
import { FC, useState } from 'react';
import { Text, View } from 'react-native';
import { useSearchParams } from 'react-router-native';
import * as Yup from 'yup';

interface INewPassword {
  onDone: () => void;
}

const NewPassword: FC<INewPassword> = ({ onDone }) => {
  const [customError, setCustomError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const [resetUserPassword] = useResetUserPasswordMutation();

  return (
    <AuthWrapper title="Set new password" subtitle="Your new password must be different from the previously used password.">
      <View>
        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          onSubmit={async (values, { setSubmitting }) => {
            // submit password reset
            const token = searchParams.get('token');

            if (token) {
              try {
                const reset = await resetUserPassword({
                  variables: { input: { token, password: values.password } },
                });

                if (reset.data?.resetUserPassword.__typename === 'Success') {
                  setIsSuccess(true);
                  onDone();
                } else {
                  throw new Error('There was an error resetting your password. Please contact us.');
                }
              } catch (e) {
                let message = 'Unknown error, please contact us.';
                if (e instanceof Error) message = e.message;

                // handle error
                setCustomError(message);
              }
            }
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string().required('This field is required').min(8, 'Password must be at least 8 characters'),
            confirmPassword: Yup.string()
              .oneOf([Yup.ref('password'), null], "Passwords don't match")
              .required('This field is required'),
          })}
        >
          {({ handleSubmit, isSubmitting }) => (
            <View>
              <View style={{ marginBottom: 10 }}>
                <FormikInput placeholder="Password" fieldName="password" type="password" />
              </View>
              <View style={{ marginBottom: 16 }}>
                <FormikInput placeholder="Confirm Password" fieldName="confirmPassword" type="password" />
              </View>
              <ButtonGeneral
                label={isSuccess ? 'Password Reset!' : 'Reset Password'}
                onPress={handleSubmit}
                disabled={isSubmitting}
                isLoading={isSubmitting}
                style={isSuccess ? { backgroundColor: '#33A5A1', borderColor: '#33A5A1' } : null}
                variant={ButtonVariant.Primary}
              />
              {customError ? <Text style={textStyles.error}>{customError}</Text> : null}
            </View>
          )}
        </Formik>
      </View>
    </AuthWrapper>
  );
};

export { NewPassword };
