import { PlayIcon } from '@components/general/icons/account-icons/PlayIcon';
import { PointerArrowRight } from '@components/general/icons/account-icons/PointerArrowRight';
import { MediaBlock } from '@components/org/media-block/MediaBlock';
import { ModalContainer } from '@components/org/saved/modals/ModalContainer';
import { GenericModalLayout } from '@components/org/saved/modals/generic-modal-layout/GenericModalLayout';
import React, { useState } from 'react';
import { StyleProp, Text, TextStyle, TouchableOpacity, View, ViewStyle, Modal } from 'react-native';

import { styles } from './style';

export interface ListItemProps {
  title: string;
  secondaryTitle?: string;
  onPress?: () => void;
  subtitle?: string;
  secondarySubtitle?: string;
  edit?: string;
  containerStyles?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  subtitleStyle?: StyleProp<TextStyle>;
  button?: React.ReactNode;
  needMediaBlocked?: boolean;
  facebook?: string;
  instagram?: string;
  linkedin?: string;
  twitter?: string;
  videoSrc?: string;
}

const ListItem = ({
  title,
  secondaryTitle,
  onPress,
  containerStyles,
  subtitle,
  secondarySubtitle,
  edit,
  titleStyle,
  subtitleStyle,
  button = null,
  needMediaBlocked = false,
  facebook,
  instagram,
  linkedin,
  twitter,
  videoSrc,
}: ListItemProps) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  return (
    <TouchableOpacity style={[styles.wrap, edit ? styles.wrapEdit : null, containerStyles]} onPress={videoSrc ? toggleModal : onPress}>
      <View
        style={{
          flex: 1,
        }}
      >
        <View style={styles.leftWrap}>
          <Text style={[styles.title, titleStyle]}>{title}</Text>
          {subtitle ? <Text style={[styles.subtitle, subtitleStyle]}>{subtitle}</Text> : null}
          {needMediaBlocked ? <MediaBlock facebook={facebook} instagram={instagram} linkedin={linkedin} twitter={twitter} /> : null}
        </View>
        {secondaryTitle ? (
          <View style={styles.leftWrap}>
            <Text style={styles.title}>{secondaryTitle}</Text>
            {secondarySubtitle ? <Text style={styles.subtitle}>{secondarySubtitle}</Text> : null}
          </View>
        ) : null}
      </View>
      {button ? button : edit ? <Text style={styles.editText}>{edit}</Text> : videoSrc ? <PlayIcon /> : <PointerArrowRight />}
      <Modal visible={isModalVisible} transparent animationType="fade">
        <ModalContainer>
          <GenericModalLayout
            title={title}
            closeModal={() => setModalVisible(false)}
            hasButtonLayout
            buttonName="Close"
            onActionButtonPress={() => setModalVisible(false)}
            withCancel={false}
          >
            <View>
              <iframe
                height="100%"
                width="100%"
                src={videoSrc}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </View>
          </GenericModalLayout>
        </ModalContainer>
      </Modal>
    </TouchableOpacity>
  );
};

export { ListItem };
