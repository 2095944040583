import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SelectCaretIcon = (props: SvgProps) => (
  <Svg width={6} height={10} fill="none" {...props}>
    <Path
      d="M5.324 4H.676c-.563 0-.878-.603-.53-1.014L2.468.24a.708.708 0 0 1 1.062 0l2.324 2.746C6.202 3.397 5.887 4 5.324 4ZM.676 6h4.648c.563 0 .878.603.53 1.014L3.532 9.76a.708.708 0 0 1-1.062 0L.145 7.014C-.202 6.603.113 6 .676 6Z"
      fill="#5C5F62"
    />
  </Svg>
);

export { SelectCaretIcon };
