import { SearchBar } from '@components/back-office/tables/search-bar';

import { useQueryFilters } from '../useGridFilters';

export const SearchFilter = () => {
  const { filters, setFilterValue } = useQueryFilters(['search']);

  return (
    <SearchBar
      setSearchTerm={(term: string) => {
        setFilterValue('search', term);
      }}
      searchTerm={filters.search}
    />
  );
};
