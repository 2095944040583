import { SelectOption } from '@components/general/form/select-option';
import { ContentBox } from '@components/general/layouts/content-box';
import { ContentStatsRecord } from '@gql/generated/generated';
import React, { useState } from 'react';
import { Text, View } from 'react-native';

import { styles } from './style';

const options = [
  { label: 'This Week', value: '7' },
  { label: 'This month', value: new Date().getDate().toString() },
];

const TableHead = () => (
  <View style={styles.cellWrap}>
    <View style={styles.firstItemCell} />
    <View style={styles.itemCellTwo}>
      <Text style={styles.cellTitle}>Title</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellTitle}>No. of Download</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellTitle}>No. of Visitors&nbsp;</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellTitle}>Avg. Time Spent</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellTitle}>Rating</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellTitle}>Share</Text>
    </View>
  </View>
);

interface ItemRowProps {
  index: number;
  title: string;
  noOfDownloads: number;
  noOfVisitors: number;
  avgTimeSpent: number;
  rating?: number | null;
  share: number;
}

const ItemRow = ({ index, title, noOfDownloads, noOfVisitors, avgTimeSpent, rating, share }: ItemRowProps) => (
  <View style={styles.itemRow}>
    <View style={styles.firstItemCell}>
      <Text style={styles.boldCellText}>{index}</Text>
    </View>
    <View style={styles.itemCellTwo}>
      <Text style={styles.boldCellText} numberOfLines={1} ellipsizeMode="tail">
        {title}
      </Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellText}>{noOfDownloads}</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellText}>{noOfVisitors}</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellText}>{avgTimeSpent} min/user</Text>
    </View>

    <View style={styles.itemCell}>
      <Text style={styles.cellText}>{rating || '-'}</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellText}>{share}</Text>
    </View>
  </View>
);

const AnalyticsContentTable = ({ data, refetch, title }: { data?: ContentStatsRecord[]; refetch: (val: number) => void; title: string }) => {
  const [value, setValue] = useState('7');
  return (
    <ContentBox>
      <View style={styles.head}>
        <Text style={styles.title}>{title}</Text>
        <View style={styles.selectWrap}>
          <SelectOption
            onChange={(val) => {
              setValue(val);
              refetch(Number(val));
            }}
            value={value}
            options={options}
          />
        </View>
      </View>
      <View style={styles.tableWrap}>
        <View style={styles.tableInner}>
          <TableHead />
          {data?.map((item, index) => (
            <ItemRow
              key={item.id}
              index={index + 1}
              title={item.title}
              noOfDownloads={item.numDownloads}
              noOfVisitors={item.numVisitors}
              avgTimeSpent={item.avgTimeMins}
              rating={item.avgRating}
              share={item.numShares}
            />
          ))}
        </View>
      </View>
    </ContentBox>
  );
};

export { AnalyticsContentTable };
