import { ContextUser } from '@context/UserContext';
import { FormikValues } from 'formik';

import { JobRoleInterface, ProfileInterface } from './initialValues';

export const clearFormikState = (values: FormikValues, initialValues: ProfileInterface | JobRoleInterface) => {
  Object.keys(values).forEach((key) => {
    // @ts-ignore FIXME: bad type
    values[key] = initialValues[key];
  });
};

export const filterUnwantedInputKeysForSubmission = (values: FormikValues, inputKeysToBeSubmitted: string[]) => {
  const input = {};
  inputKeysToBeSubmitted.forEach((key: string) => {
    if (values[key] !== '') {
      // @ts-ignore FIXME: bad type
      input[key] = values[key];
    }
  });
  return input;
};
