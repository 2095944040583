import { Text, View } from 'react-native';

import { styles } from './style';

interface PercentageCalc {
  symbol: string;
  color: string;
}

function calculatePercentageColor(percentage: number): PercentageCalc {
  let symbol: string;
  let color: string;

  if (percentage < 30) {
    symbol = '-';
    color = '#D72C0D';
  } else {
    symbol = '+';
    color = '#23827F';
  }

  return { symbol, color };
}

interface Props {
  title: string;
  number: number;
  numberLabel?: string;
  percentage: number;
  percentageLabel: string;
}

export const AnalyticCard = ({ title, number, numberLabel, percentage, percentageLabel }: Props) => {
  return (
    <View style={styles.cardWrapper}>
      <Text style={styles.cardTitle}>{title}</Text>
      <View style={styles.cardRow}>
        <Text style={styles.cardNumber}>{number}</Text>
        <Text style={styles.cardPercentageLabel}>{numberLabel}</Text>
      </View>
      <View style={[styles.cardRow, { marginBottom: 0 }]}>
        <Text style={[styles.cardPercentage, { color: calculatePercentageColor(percentage).color }]}>{`${
          calculatePercentageColor(percentage).symbol
        }${percentage}%`}</Text>
        <Text style={[styles.cardPercentageLabel, { marginLeft: 4 }]}>{percentageLabel}</Text>
      </View>
    </View>
  );
};
