import { AuthWrapper } from '@components/sign-up/auth-wrapper';
import { useSendVerificationEmailMutation } from '@gql/generated/generated';
import { useGetAuthTokenAndUserId } from '@hooks/useGetAuthTokenAndUserId';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { Alert, Text, View } from 'react-native';
import { useLocation, useNavigate } from 'react-router-native';

import { ids, styles } from './style';

const VerifyEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [sendVerificationEmail, { loading }] = useSendVerificationEmailMutation();

  const { user } = useGetAuthTokenAndUserId();
  const email = user ? user.email : location.state?.email;

  useEffect(() => {
    if (!email || user?.emailVerified) {
      navigate('/');
    }
  }, [user?.emailVerified, email, navigate]);

  const handleResendEmailVerification = async () => {
    try {
      const userId: string | undefined = user?.uid || location.state?.userId;

      if (!userId) {
        navigate('/');
        console.error('No user id found');
        Sentry.captureException(new Error('No user id found'));
        return;
      }

      await sendVerificationEmail({
        variables: { input: { userId } },
      });

      Alert.alert('Success', 'Verification email resent');
    } catch (e) {
      const error = e as { message: string };
      console.error(error);

      if (error.message === 'User is already verified.') {
        navigate('/');
        return;
      }

      Alert.alert('Failed', error?.message || 'Encountered an error');
      Sentry.captureException(error);
    }
  };

  if (!email) {
    return <></>;
  }

  return (
    <>
      <AuthWrapper title="Check your email" subtitle={`We have sent a verification link to ${email}`} onPressBack={() => navigate(-1)}>
        <View>
          <Text style={styles.subtitle} dataSet={{ media: ids.subtitle }}>
            If you don’t see the email in your inbox, check your spam folder or
            <Text style={styles.registerLink} onPress={handleResendEmailVerification}>
              &nbsp;{loading ? 'Sending' : 'click to resend'}
            </Text>
          </Text>
        </View>
      </AuthWrapper>
    </>
  );
};

export { VerifyEmail };
