import { FormSection } from '@components/back-office/editor/form-section';
import { UserGeneralHeader } from '@components/back-office/users/user-general-section-header';
import { UserOrganization, UserOrganizationForm } from '@components/back-office/users/user-organization';
import { useAdminGetAllOrganisationsQuery } from '@gql/generated/generated';
import { useState } from 'react';

type Props = {
  editingExistingUser?: boolean;
};

export const OrgSection = ({ editingExistingUser }: Props) => {
  const isTogglable = !!editingExistingUser;

  const [editMode, setEditMode] = useState(!editingExistingUser);
  const { data: organisationsData } = useAdminGetAllOrganisationsQuery();

  const Component = editMode ? UserOrganizationForm : UserOrganization;

  return (
    <FormSection>
      <UserGeneralHeader title="Organisation" editButton={isTogglable} editMode={editMode} setEditMode={() => setEditMode(!editMode)} />
      <Component organisationsData={organisationsData?.adminGetAllOrganisations.organisations} />
    </FormSection>
  );
};
