import { ButtonGeneral, ButtonType, ButtonVariant } from '@components/general/button-general';
import { MenuHorizontalIcon } from '@components/general/icons/MenuHorizontalIcon';
import { InfoIcon } from '@components/general/icons/roadmap';
import { ContentBox } from '@components/general/layouts/content-box';
import { Wrapper } from '@components/general/layouts/wrapper/Wrapper';
import { Row } from '@components/general/row';
import { RoadmapPulseContext } from '@context/RoadmapPulseContext';
import { useFeatureFlag } from '@hooks/useFeatureFlag';
import { useFirstVisitTutorial } from '@hooks/useFirstVisitTutorial';
import { useGetRoadmapVideos } from '@hooks/useGetRoadmapVideos';
import { ActionList, Button, Popover, Select, Tooltip } from '@shopify/polaris';
import { getDifferenceInDays, getHumanReadableCooldown } from '@utils/TIme';
import { FEATURE_FLAGS } from '@utils/featureFlags';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ActivityIndicator, Platform, Text, TouchableOpacity, View } from 'react-native';
import RNPopover from 'react-native-popover-view';
import { useNavigate } from 'react-router-native';

import { ids, styles } from './style';
import { RoadmapTutorial } from '../roadmap-tutorial';

interface Props {
  title: string;
  totalQuestion: number;
  totalAnswered: number;
  children: React.ReactNode;
  hasSeeResultsButton?: boolean;
}

const DAYS = 7;

const RoadmapPulseWrapper = ({ title, totalAnswered, totalQuestion, children, hasSeeResultsButton = false }: Props) => {
  // Navigation
  const navigate = useNavigate();
  const [showInfoMenu, setShowInfoMenu] = useState(false);

  const [tutorialModal, setTutorialModal] = useState<{ videoUri?: string; title: string; subtitle: string } | null>(null);

  // Popover
  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(() => setPopoverActive((popoverActive) => !popoverActive), []);

  const isRoadmapPulseResultEnabled = useFeatureFlag([FEATURE_FLAGS.ROADMAP_PULSE_RESULT]);

  // Roadmap Context
  const roadmapPulseContext = useContext(RoadmapPulseContext);
  if (!roadmapPulseContext) {
    throw new Error('Failed to load roadmap pulse context');
  }
  const { latestPulse, selectedPulse, roadmapPulses, createRoadmapPulse, deleteRoadmapPulse, selectRoadmapPulse, isLoading } = roadmapPulseContext;

  const isCreatedDisabled = useMemo(() => {
    if (!latestPulse || !latestPulse?.createdAt) return false;
    const timestampNow = new Date().getTime();
    const days = getDifferenceInDays(timestampNow, latestPulse.createdAt);
    return days <= DAYS;
  }, [latestPulse]);

  const createdDisabledText = useMemo(() => {
    if (isCreatedDisabled) {
      const humanReadableCooldown = getHumanReadableCooldown(latestPulse?.createdAt!, DAYS);
      return `You can start another in ${humanReadableCooldown}`;
    }
    return '';
  }, [isCreatedDisabled]);

  const isDeleteDisabled = useMemo(() => {
    // No pulse
    if (!selectedPulse || !selectedPulse?.id || !selectedPulse?.createdAt) return true;
    // Check last pulse
    if (roadmapPulses.length === 1) return true;
    // Check pulse has not been created for more than ${DAYS} days
    const timestampNow = new Date().getTime();
    const days = getDifferenceInDays(timestampNow, selectedPulse.createdAt);
    return days >= DAYS;
  }, [selectedPulse, roadmapPulses]);

  const deleteDisabledText = useMemo(() => {
    // No pulse
    if (!selectedPulse || !selectedPulse?.id || !selectedPulse?.createdAt) {
      return 'There are no pulses to delete';
    }
    // Check last pulse
    if (roadmapPulses.length === 1) {
      return 'You cannot delete the last pulse';
    }
    // Check pulse has not been created for more than 7 days
    const timestampNow = new Date().getTime();
    const days = getDifferenceInDays(timestampNow, selectedPulse.createdAt);
    if (days >= DAYS) {
      return `You cannot delete a pulse that is greater than ${days} days old`;
    }
    return '';
  }, [selectedPulse, roadmapPulses]);

  const [roadmapVideos] = useGetRoadmapVideos();

  const roadmapTutorialModal = {
    videoUri: roadmapVideos?.pulse,
    title: 'Pulse',
    subtitle: 'Your business health check',
  };

  const [setFirstVisitTutorial] = useFirstVisitTutorial({
    roadmapType: 'pulse',
    deps: [roadmapVideos?.tutorial],
    cb: () => {
      setTutorialModal(roadmapTutorialModal);
    },
  });

  function onCloseTutorialModal() {
    setTutorialModal(null);
    setFirstVisitTutorial(false);
  }

  function changeRoadmapPulse(id: string) {
    if (!id) return;
    selectRoadmapPulse(id);
  }

  if (isLoading) {
    return (
      <View
        style={{
          width: '100vw',
          height: '100vh',
          position: 'absolute',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 99,
        }}
      >
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  }

  const Activator = <Button icon={<MenuHorizontalIcon />} onClick={togglePopoverActive} textAlign="end" />;

  return (
    <Wrapper style={styles.wrapper}>
      <ContentBox style={styles.contentBox} dataSetMedia={ids.contentBox}>
        <TouchableOpacity onPress={() => navigate('/roadmap')}>
          <Text style={styles.backText}>{`< Back`}</Text>
        </TouchableOpacity>
        <View
          style={{
            marginVertical: 20,
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <Select
              label="Select a Pulse"
              value={selectedPulse?.id ?? ''}
              onChange={changeRoadmapPulse}
              options={roadmapPulses.map((p, index) => ({
                label: new Date(p?.createdAt ?? 0).toLocaleDateString(),
                value: p?.id ?? index.toString(),
              }))}
            />
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                gap: 4,
                height: 'fit-content',
              }}
            >
              <Popover
                active={popoverActive}
                activator={Activator}
                autofocusTarget="first-node"
                onClose={togglePopoverActive}
                fixed={false}
                fluidContent
              >
                <ActionList
                  items={[
                    {
                      content: 'New Pulse',
                      helpText: createdDisabledText,
                      disabled: isCreatedDisabled,
                      onAction: () => {
                        createRoadmapPulse();
                      },
                    },
                    {
                      content: 'Delete Latest Pulse',
                      helpText: deleteDisabledText,
                      disabled: isDeleteDisabled,
                      onAction: () => {
                        deleteRoadmapPulse(selectedPulse?.id!);
                      },
                    },
                  ]}
                />
              </Popover>
            </View>
          </View>
        </View>
        {!selectedPulse && <Text>You have not yet created a pulse</Text>}
        {selectedPulse && (
          <View style={styles.headingWrap} dataSet={{ media: ids.headingWrap }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '70%',
              }}
            >
              <Text style={styles.title}>{title}</Text>
              <Text style={{ width: '100%', marginVertical: 10 }}>
                Your moment-in-time business health check. Answer all questions below for an instant analysis of your business, along with tailored
                coaching, content, and training recommendations. It takes no more than 10 minutes.
              </Text>
              <Text style={styles.description}>
                {totalAnswered} out of {totalQuestion} questions answered
              </Text>
            </View>
            <View>
              {totalAnswered === 0 && (
                <View style={styles.infoWrap}>
                  <Text style={styles.infoPercentage}>0%</Text>
                  <Text style={styles.infoComplete}>Complete</Text>
                  {Platform.OS === 'web' ? (
                    <Tooltip
                      content={
                        <Text style={styles.tooltipText}>
                          Complete all sections to see your suggested content and training to improve your business.
                        </Text>
                      }
                    >
                      <TouchableOpacity>
                        <InfoIcon />
                      </TouchableOpacity>
                    </Tooltip>
                  ) : (
                    <RNPopover
                      isVisible={showInfoMenu}
                      onRequestClose={() => setShowInfoMenu(false)}
                      from={
                        <TouchableOpacity onPress={() => setShowInfoMenu(true)}>
                          <InfoIcon />
                        </TouchableOpacity>
                      }
                    >
                      <Text style={styles.tooltipText}>
                        Complete all sections to see your suggested content and training to improve your business.
                      </Text>
                    </RNPopover>
                  )}
                </View>
              )}
              <Row>
                {hasSeeResultsButton && isRoadmapPulseResultEnabled ? (
                  <ButtonGeneral
                    label="See Results"
                    type={ButtonType.user}
                    variant={ButtonVariant.Primary}
                    onPress={() => navigate(`/roadmap/pulse/${selectedPulse.id}/result`)}
                    style={{ marginRight: 8 }}
                  />
                ) : null}
                <ButtonGeneral
                  label="Tutorial"
                  type={ButtonType.user}
                  variant={ButtonVariant.Secondary}
                  onPress={() => setTutorialModal(roadmapTutorialModal)}
                  style={{ width: 'fit-content' }}
                />
              </Row>
            </View>
          </View>
        )}
        {children}
      </ContentBox>
      {tutorialModal && tutorialModal.videoUri && (
        <RoadmapTutorial
          isVisible={!!tutorialModal}
          onClose={onCloseTutorialModal}
          title={`Welcome to ${tutorialModal.title}`}
          subtitle={tutorialModal.subtitle}
          videoUri={tutorialModal.videoUri}
        />
      )}
    </Wrapper>
  );
};

export { RoadmapPulseWrapper };
