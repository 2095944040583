import { IListModalItem } from '@components/general/modals/list-modal';
import { saveOptions, shareOptions } from '@utils/misc';
import { ModalContent } from '@utils/models';
import { useState } from 'react';
import { Linking } from 'react-native';

const useIndexPageDetail = () => {
  const [listModalType, setListModalType] = useState(ModalContent.closed);

  const handleListModalItems = (pathname: string) => {
    switch (listModalType) {
      case ModalContent.share:
        return shareOptions(pathname);
      case ModalContent.save:
        return saveOptions;
      default:
        return [];
    }
  };

  const handleItemsSelect = async (item: IListModalItem, index: number) => {
    switch (listModalType) {
      case ModalContent.download:
      case ModalContent.share:
        await Linking.openURL(item.link as string);
        break;
      case ModalContent.save:
        break;
    }
    setListModalType(ModalContent.closed);
  };

  return {
    listModalType,
    setListModalType,
    handleListModalItems,
    handleItemsSelect,
  };
};

export { useIndexPageDetail };
