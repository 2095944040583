import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  text: {
    fontFamily: 'OpenSans_400Regular',
    color: '#2C6ECB',
    fontSize: 14,
    lineHeight: 20,
    textDecorationLine: 'underline',
  },
});
