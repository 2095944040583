import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  billingAddressText: {
    fontSize: 16,
    fontFamily: 'OpenSans_400Regular',
    color: '#202223',
    lineHeight: 24,
  },
  editButton: {
    color: '#2C6ECB',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
  },
});
