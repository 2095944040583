import React from 'react';
import { View, ViewProps } from 'react-native';

import { ids, styles } from './style';

const Col = ({ children, style, ...rest }: ViewProps) => (
  <View style={[styles.col, style]} {...rest} dataSet={{ media: ids.col }}>
    {children}
  </View>
);

export { Col };
