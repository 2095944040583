import { Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';

export function NotFound() {
  const navigate = useNavigate();
  const goHome = () => navigate('/');
  return (
    <View
      style={{
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* @ts-ignore */}
      <Text style={{ fontSize: '10rem' }}>404</Text>
      {/* @ts-ignore */}
      <Text style={{ fontSize: '2rem', textAlign: 'center' }}>
        Finding the path to success in recruitment isn't easy...let's take you to a better&nbsp;
        <TouchableOpacity onPress={goHome}>
          <Text
            style={{
              color: '#2C6ECB',
              textDecorationLine: 'underline',
            }}
          >
            page
          </Text>
        </TouchableOpacity>
        .
      </Text>
    </View>
  );
}
