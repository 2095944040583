import { ActionList, ActionListItemDescriptor, Checkbox, Icon } from '@shopify/polaris';
import { ArrowLeftMinor } from '@shopify/polaris-icons';
import { FormikHelpers } from 'formik';
import { Dispatch, SetStateAction } from 'react';

type ActionListItemDescriptorWithDefinedId = ActionListItemDescriptor & {
  id: string;
};

type Props = {
  items: ActionListItemDescriptor[];
  selectedSubcategories: string[];
  setSelectedCategory: Dispatch<SetStateAction<string>>;
  setFieldValue: FormikHelpers<any>['setFieldValue'];
  disabled?: boolean;
};

export const Subcategories = ({ items, setSelectedCategory, selectedSubcategories, setFieldValue, disabled = false }: Props) => {
  const filteredItems = items.filter((item): item is ActionListItemDescriptorWithDefinedId => item.id !== undefined);

  return (
    <ActionList
      actionRole="menuitem"
      items={[
        {
          content: 'Categories',
          prefix: <Icon source={ArrowLeftMinor} />,
          disabled,
          onAction: () => setSelectedCategory(''),
        },
        {
          content: 'Select all',
          disabled,
          onAction: () => {
            filteredItems.forEach(({ id }) => {
              setFieldValue(id, true);
            });
          },
        },
        ...filteredItems.map(({ id, content }) => {
          const checked = selectedSubcategories.includes(id);

          const checkbox = (
            <Checkbox
              name={id}
              id={id}
              labelHidden
              label
              disabled={disabled}
              checked={checked}
              onChange={(newChecked: boolean, id: string) => {
                setFieldValue(id, !checked);
              }}
            />
          );

          const onAction = () => {
            setFieldValue(id, !checked);
          };

          return {
            content,
            onAction,
            disabled,
            prefix: checkbox,
          };
        }),
      ]}
    />
  );
};
