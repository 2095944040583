import { CategoryButton } from '@components/back-office/tables/category-button';
import { ProfileInterface } from '@pages/account/settings/initialValues';
import { useFormikContext } from 'formik';
import { useState } from 'react';

import { ButtonLayout } from '../../button-layout/ButtonLayout';
import { InputFormikContainer } from '../../form-elements/input-formik/InputFormikContainer';

interface formProps {
  closeEditMode: () => void;
}

const FocusForm = ({ closeEditMode }: formProps) => {
  const [displayCategoryModal, setDisplayCategoryModal] = useState(false);
  const {
    handleSubmit,
    values: { biggestFocus },
    setFieldValue,
  } = useFormikContext<ProfileInterface>();

  const closeSubmitHandler = () => {
    handleSubmit();
    closeEditMode();
  };

  return (
    <>
      <InputFormikContainer label="What's your biggest focus in the next 90 days – tick any 2:">
        <CategoryButton
          selectedCat={biggestFocus}
          setSelectedCat={(categories) => {
            setFieldValue('biggestFocus', categories);
          }}
          isEditor
          displayCategoryModal={displayCategoryModal}
          setDisplayCategoryModal={setDisplayCategoryModal}
          placeholderText="Please Select"
          placeholderTextStyle={{
            fontSize: 14,
            fontFamily: 'OpenSans_400Regular',
          }}
        />
      </InputFormikContainer>
      <ButtonLayout closeEditMode={closeEditMode} submitCloseHander={closeSubmitHandler} />
    </>
  );
};

export { FocusForm };
