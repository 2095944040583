import React, { createContext, ReactNode, useState } from 'react';

interface selectedUsersAssignModalContextProviderProps {
  children?: ReactNode;
}

interface SelectedUsersType {
  id: string;
  name: string;
}

interface selectedUsersAssignModalContextProps {
  selectedUsers: SelectedUsersType[];
  setSelectedUsers: (selectedUsers: SelectedUsersType[]) => void;
  handleRemoveUser: (userId: string) => void;
  handleAddUser: (userId: SelectedUsersType) => void;
}

const selectedUsersAssignModalContext = createContext<selectedUsersAssignModalContextProps>({
  selectedUsers: [],
  setSelectedUsers: () => {},
  handleRemoveUser: () => {},
  handleAddUser: () => {},
});

export const SelectedUsersAssignModalContextProvider = ({ children }: selectedUsersAssignModalContextProviderProps) => {
  const [selectedUsers, setSelectedUsers] = useState<SelectedUsersType[]>([]);

  const handleRemoveUser = (userId: string) => {
    setSelectedUsers(selectedUsers.filter((user) => user.id !== userId));
  };

  const handleAddUser = (user: SelectedUsersType) => {
    if (selectedUsers.find((u) => u.id === user.id)) return;
    setSelectedUsers([...selectedUsers, { id: user.id, name: user.name }]);
  };

  return (
    <selectedUsersAssignModalContext.Provider
      value={{
        selectedUsers,
        setSelectedUsers,
        handleRemoveUser,
        handleAddUser,
      }}
    >
      {children}
    </selectedUsersAssignModalContext.Provider>
  );
};

export { selectedUsersAssignModalContext };
