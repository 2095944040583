import { AvatarWithName } from '@components/back-office/editor/avatar-with-name';
import { ActivityIndicatorTRN } from '@components/general/activity-indicator/ActivityIndicator';
import { ButtonGeneral } from '@components/general/button-general';
import { DotsSaveMenu } from '@components/general/dots-save-menu';
import { ChevronUpIcon, LeftArrow } from '@components/general/icons';
import { SearchIcon } from '@components/general/icons/SearchIcon';
import { ExternalSmallMinor } from '@components/general/icons/back-office-icons/ExternalSmallMinor';
import { StarIcon } from '@components/general/icons/feed-icons';
import { Image as RNImage } from '@components/general/image-from-storage';
import { ContentBox } from '@components/general/layouts/content-box';
import { Pill, PillVariant } from '@components/general/pill';
import { PillRowWithTooltip } from '@components/general/pill-row-with-tooltip';
import { TooltipWithChild } from '@components/general/tooltip';
import { TouchableOpacityLink } from '@components/general/touchable-opacity-link';
import { ScoreAssociationsContext } from '@context/ScoreAssociations';
import { APP_URL } from '@env';
import { RoadmapPulseScoreAssnSubCategoryLevelContentType } from '@gql/generated/generated';
import { useDebounce } from '@hooks/useDebounce';
import { useSearchAlgolia } from '@hooks/useSearchAlgolia';
import { Checkbox, Combobox, Listbox, Select, TextField } from '@shopify/polaris';
import { truncateString } from '@utils/misc';
import dayjs from 'dayjs';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Linking, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate, useParams } from 'react-router-native';

import { styles } from './style';

type IndexName = 'content' | 'trainings' | 'events';

export type ContentAlgolia = {
  id: string;
  title: string;
  authorName: string;
  authorId: string;
  description: string;
  coverImage?: { storagePath?: string };
  categories: string[];
  jobRoles: string[];
  publishedAt: number;
  averageStars: number;
  commentCount: number;
  shareCount: number;
  isFree: boolean;
  isPlus: boolean;
  isClub: boolean;
};

const scoreOptions = [...Array(5).keys()].map((key) => {
  const score = String(key + 1);
  return {
    label: score,
    value: score,
  };
});

function Header() {
  const { categoryId, subcategoryId, score } = useParams();
  const navigate = useNavigate();

  function goToScore(score: string) {
    navigate(`/Roadmap/score-recommendations/edit/${categoryId}/${subcategoryId}/${score}`);
  }

  function goToScoreAssociations() {
    navigate('/Roadmap/score-recommendations');
  }
  return (
    <View style={styles.headerContainer}>
      <TouchableOpacity style={styles.backButton} onPress={goToScoreAssociations}>
        <LeftArrow />
      </TouchableOpacity>
      <View style={styles.breadcrumb}>
        <Text style={[styles.breadcrumbItem]}>{categoryId}</Text>
        <Text style={[styles.breadcrumbItem, styles.breadcrumbSeperator]}>{'>'}</Text>
        <Text style={[styles.breadcrumbItem]}>{subcategoryId}</Text>
        <Text style={[styles.breadcrumbItem, styles.breadcrumbSeperator]}>{'>'}</Text>
        <Select label="" value={score} options={scoreOptions} onChange={goToScore} />
      </View>
    </View>
  );
}

function SearchItem({
  content,
  type,
  toggleSelection,
  isSelected,
}: {
  content: ContentAlgolia;
  type: RoadmapPulseScoreAssnSubCategoryLevelContentType;
  toggleSelection: (id: string) => void;
  isSelected: (id: string) => boolean;
}) {
  const { id, title, categories, publishedAt, coverImage } = content;
  const publishedDate = dayjs(new Date(publishedAt)).format('DD MMM YY');
  const shortenedItems: string[] = Array.from(new Set(categories?.map((el: string) => el.split(':')[0])));
  const contentUrl = `${APP_URL}/${type.toLowerCase()}/${id}`;
  function goToContent() {
    Linking.openURL(contentUrl);
  }

  return (
    <Listbox.Option key={id} value={id}>
      <TouchableOpacity
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
          padding: 10,
        }}
        onPress={() => toggleSelection(id)}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {/* We have disabled pointerEvents as when you select the checkbox it would close the dropdown */}
          <View style={{ marginHorizontal: 10 }} pointerEvents="none">
            <Checkbox label="" checked={isSelected(id)} />
          </View>
          <View>
            <RNImage
              path={coverImage?.storagePath}
              fallbackImagePath={require('/assets/img/SaveItemIamge3.png')}
              style={{ width: 56, height: 56, borderRadius: 4, marginRight: 8 }}
            />
          </View>
          <View>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.date}>{publishedDate}</Text>
            {categories.length > 0 && (
              <TooltipWithChild toolTipText={categories.join(', ')}>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  {shortenedItems.map((c: string, i: number) => (
                    <View style={{ marginBottom: 5 }} key={`${c}-${i}`}>
                      <Pill variant={PillVariant.DarkGrey} text={c} withMarginRight />
                    </View>
                  ))}
                </View>
              </TooltipWithChild>
            )}
          </View>
        </View>
        <TouchableOpacityLink
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 20 }}
          href={contentUrl}
          onPress={goToContent}
        >
          <Text style={styles.viewContent}>View page</Text>
          <ExternalSmallMinor />
        </TouchableOpacityLink>
      </TouchableOpacity>
    </Listbox.Option>
  );
}

function AssignedContent({
  id,
  title,
  coverImage,
  categories,
  description,
  authorName,
  jobRoles,
  averageStars,
  commentCount,
  shareCount,
  isFree,
  isPlus,
  isClub,
  type,
  unassignContent,
}: ContentAlgolia & { type: RoadmapPulseScoreAssnSubCategoryLevelContentType; unassignContent: () => void }) {
  const shortenedItems = categories?.slice(0, 2).map((el) => el.split(':')[0]);
  const jobRolesStr = jobRoles.join(', ');
  const userTypesStr = [
    { isEnabled: isFree, text: 'Free' },
    { isEnabled: isPlus, text: 'Plus' },
    { isEnabled: isClub, text: 'Club' },
  ]
    .filter((u) => u.isEnabled)
    .map((u) => u.text)
    .join(', ');
  const contentUrl = `${APP_URL}/${type.toLowerCase()}/${id}`;
  function goToContent() {
    Linking.openURL(contentUrl);
  }

  const stats = `${Number(averageStars).toFixed(1)} (${commentCount} reviews) ${!!shareCount || shareCount === 0 ? `• ${shareCount} shares` : ''}`;
  return (
    <View style={styles.contentContainer}>
      {/* Image */}
      <View style={styles.imageContainer}>
        <RNImage path={coverImage?.storagePath} fallbackImagePath={require('/assets/img/SaveItemIamge3.png')} style={styles.image} />
        <View style={styles.imageTagContainer}>
          <PillRowWithTooltip shortenedItems={shortenedItems} items={categories} />
        </View>
      </View>
      {/* Content */}
      <View style={{ display: 'flex', justifyContent: 'space-between', marginVertical: 16 }}>
        {/* Top Content */}
        <View>
          <Text style={[styles.contentTitle, { marginBottom: 4 }]}>{title}</Text>
          <Text style={styles.contentDescription} numberOfLines={2}>
            {description}
          </Text>
        </View>
        {/* Bottom Content */}
        <View>
          {/* User & Job Roles */}
          <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {authorName && <AvatarWithName name={authorName} isSubdued fontSize={12} i={1} gap={5} size={25} />}
            <Text style={styles.contentJobRoles}>
              <TooltipWithChild toolTipText={`${jobRolesStr}`}>
                <Text numberOfLines={2}>Job Role: {truncateString(jobRolesStr, 35)}</Text>
              </TooltipWithChild>
            </Text>
          </View>
          {/* Reviews & User Access Types */}
          <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <StarIcon />
              <Text style={{ marginLeft: 5 }}>{stats}</Text>
            </View>
            <View style={{ marginLeft: 10 }}>
              <Pill variant={PillVariant.LightBlue} text={userTypesStr} />
            </View>
          </View>
        </View>
      </View>
      <View
        style={{
          position: 'absolute',
          top: 16,
          right: 16,
        }}
      >
        <DotsSaveMenu
          actionItems={[
            {
              content: 'View',
              onAction: goToContent,
            },
            {
              content: 'Remove',
              destructive: true,
              onAction: unassignContent,
            },
          ]}
        />
      </View>
    </View>
  );
}

function ContentAssignment({ type, indexName }: { type: RoadmapPulseScoreAssnSubCategoryLevelContentType; indexName: IndexName }) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const { categoryId, subcategoryId, score } = useParams();
  const [searchDebounced, setSearchDebounced] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const scoreAssociationContext = useContext(ScoreAssociationsContext);
  const {
    updateScoreAssnsLevelLoading,
    assignScoreAssociationContent,
    unassignScoreAssociationContent,
    assignedContentMetadata,
    assignedContentMetadataLoading,
    assignedTrainingMetadata,
    assignedTrainingMetadataLoading,
  } = scoreAssociationContext!;

  const assignedMetadata: { data: any[]; loading: boolean } | null = useMemo(() => {
    if (type === RoadmapPulseScoreAssnSubCategoryLevelContentType.CONTENT) {
      return { data: assignedContentMetadata, loading: assignedContentMetadataLoading };
    }
    if (type === RoadmapPulseScoreAssnSubCategoryLevelContentType.TRAININGS) {
      return { data: assignedTrainingMetadata, loading: assignedTrainingMetadataLoading };
    }
    return null;
  }, [type, assignedContentMetadataLoading, assignedContentMetadata, assignedTrainingMetadataLoading, assignedTrainingMetadata]);

  const [selectedContent, setSelectedContent] = useState<string[]>([]);
  useDebounce(
    () => {
      if (search.length < 3) return;
      setSearchDebounced(search);
    },
    300,
    [search]
  );
  const title = `${type[0].toUpperCase()}${type.substring(1, type.length)}`;

  const [searchResults, searchLoading] = useSearchAlgolia({
    hitsPerPage: 10,
    isPublished: true,
    query: searchDebounced,
    indexName,
    excludeIds: assignedContentMetadata.map((c) => c.id) as string[],
  });

  function toggleIsCollpased() {
    setIsCollapsed((prev) => !prev);
  }

  function toggleSelection(id: string) {
    const selectionIndex = selectedContent.findIndex((s) => s === id);
    setSelectedContent((prevState) => {
      const state = [...prevState];
      if (selectionIndex !== -1) {
        state.splice(selectionIndex, 1);
      } else {
        state.push(id);
      }
      return state;
    });
  }

  function isSelected(id: string) {
    return selectedContent.includes(id);
  }

  function resetState() {
    setSearch('');
    setSearchDebounced('');
    setSelectedContent([]);
  }

  async function assignContent() {
    try {
      if (!categoryId || !subcategoryId) return;
      await assignScoreAssociationContent({
        scoreLevel: Number(score),
        contentType: type,
        contentIDs: selectedContent,
        categoryName: categoryId,
        subCategoryName: subcategoryId,
      });
      resetState();
    } catch (err) {
      console.error('ERROR - assignContent():', err);
    }
  }

  async function unassignContent(contentId: string) {
    try {
      if (!categoryId || !subcategoryId) return;
      await unassignScoreAssociationContent({
        scoreLevel: Number(score),
        contentType: type,
        contentIDs: [contentId],
        categoryName: categoryId,
        subCategoryName: subcategoryId,
      });
      resetState();
    } catch (err) {
      console.error('ERROR - unassignContent():', err);
    }
  }

  return (
    <>
      <TouchableOpacity style={styles.collapsible} onPress={toggleIsCollpased}>
        <Text>{title}</Text>
        <ChevronUpIcon
          style={
            isCollapsed
              ? {
                  transform: [{ rotate: '180deg' }],
                }
              : {}
          }
        />
      </TouchableOpacity>
      {!isCollapsed && (
        <View style={{ padding: 20 }}>
          <View>
            <Combobox
              allowMultiple
              activator={
                <Combobox.TextField
                  prefix={<SearchIcon style={styles.searchIcon} />}
                  onChange={setSearch}
                  suffix={
                    <ButtonGeneral
                      label={`Add ${type}`}
                      onPress={assignContent}
                      isLoading={updateScoreAssnsLevelLoading}
                      disabled={selectedContent.length === 0}
                      style={{
                        position: 'absolute',
                        right: -10,
                        top: -16,
                        height: 32,
                        width: 130,
                      }}
                    />
                  }
                  label={`Add ${type}`}
                  value={search}
                  placeholder="Type the first 3 letters"
                  autoComplete="off"
                />
              }
            >
              <>
                <Listbox>
                  {searchDebounced.length >= 3 && !searchLoading ? (
                    <View>
                      {searchResults.map((r: any) => (
                        <SearchItem key={r.id} content={r as ContentAlgolia} type={type} isSelected={isSelected} toggleSelection={toggleSelection} />
                      ))}
                    </View>
                  ) : null}
                  {searchLoading && <Listbox.Loading accessibilityLabel="Loading" />}
                </Listbox>
              </>
            </Combobox>
          </View>
          {/* Selected items */}
          {assignedMetadata &&
            !assignedMetadata.loading &&
            assignedMetadata.data.map((m: ContentAlgolia) => (
              <AssignedContent key={m.id} unassignContent={() => unassignContent(m.id)} type={type} {...m} />
            ))}
          {assignedMetadata && assignedMetadata.loading && <ActivityIndicatorTRN viewStyle={{ marginVertical: 40 }} />}
        </View>
      )}
    </>
  );
}

export function ScoreAssociationsEdit() {
  const { categoryId, subcategoryId, score } = useParams();
  const scoreAssociationContext = useContext(ScoreAssociationsContext);
  if (!categoryId || !subcategoryId || !score) throw new Error('Invalid params');
  if (!scoreAssociationContext) throw new Error('ScoreAssociationsContext not provided');
  const { selectedScoreAssnLevel, getRoadmapPulseScoreAssnsLoading, isValidParams, updateScoreAssociationFeedback, updateScoreAssnsLevelLoading } =
    scoreAssociationContext;

  const [feedbackTitle, setFeedbackTitle] = useState<string | null>(null);
  const [feedbackText, setFeedbackText] = useState<string | null>(null);

  useEffect(() => {
    if (selectedScoreAssnLevel) {
      setFeedbackTitle(selectedScoreAssnLevel.feedbackTitle);
      setFeedbackText(selectedScoreAssnLevel.feedbackText);
    }
  }, [selectedScoreAssnLevel]);

  const hasFeedbackChanged = useMemo(() => {
    if (selectedScoreAssnLevel) {
      if (selectedScoreAssnLevel.feedbackTitle !== feedbackTitle) {
        return true;
      }
      if (selectedScoreAssnLevel.feedbackText !== feedbackText) {
        return true;
      }
    }
    return false;
  }, [selectedScoreAssnLevel, feedbackText, feedbackTitle, updateScoreAssnsLevelLoading]);

  if (getRoadmapPulseScoreAssnsLoading) {
    return <ActivityIndicatorTRN />;
  }

  if (!isValidParams) {
    return (
      <View>
        <Text>Invalid params provided</Text>
      </View>
    );
  }

  async function onChangeFeedback() {
    try {
      if (!hasFeedbackChanged || !categoryId || !subcategoryId || !score) return;
      await updateScoreAssociationFeedback({
        categoryName: categoryId,
        subCategoryName: subcategoryId,
        scoreLevel: Number(score),
        // We shouldn't need to provide content type when updating feedback
        contentType: RoadmapPulseScoreAssnSubCategoryLevelContentType.CONTENT,
        feedbackTitle,
        feedbackText,
      });
    } catch (err) {
      console.error('ERROR - onChangeFeedback():', err);
    }
  }

  return (
    <View style={styles.container}>
      <Header />
      <ContentBox style={{ padding: 20 }}>
        <ContentAssignment type={RoadmapPulseScoreAssnSubCategoryLevelContentType.CONTENT} indexName="content" />
        <ContentAssignment type={RoadmapPulseScoreAssnSubCategoryLevelContentType.TRAININGS} indexName="trainings" />
      </ContentBox>
      <ContentBox style={{ padding: 20, marginTop: 20 }}>
        <Text style={styles.feedbackTitle}>Feedback</Text>
        <TextField placeholder="Title" label="Title" value={feedbackTitle ?? ''} onChange={(text) => setFeedbackTitle(text)} autoComplete="off" />
        <View style={{ marginVertical: 5 }} />
        <TextField
          placeholder="Description"
          label="Description"
          value={feedbackText ?? ''}
          onChange={(text) => setFeedbackText(text)}
          multiline={12}
          autoComplete="off"
        />
        <View style={{ marginVertical: 5 }} />
        <ButtonGeneral disabled={!hasFeedbackChanged} onPress={onChangeFeedback} label="Update feedback" isLoading={updateScoreAssnsLevelLoading} />
      </ContentBox>
    </View>
  );
}
