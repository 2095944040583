import { Button, Listbox, Popover, Scrollable } from '@shopify/polaris';
import React, { useState } from 'react';

interface PopoverSelectProps {
  items?: Item[];
  finalItem?: React.ReactNode;
  onPress?: (val: any) => void;
  buttonLabel: string;
  disabled?: boolean;
  initialItem?: React.ReactNode;
}

interface Item {
  label: string;
  id?: string | null;
  value?: string | null;
  finalItem?: React.ReactNode;
  initialItem?: React.ReactNode;
}

const PopoverSelect = ({ items, buttonLabel, onPress, finalItem, disabled, initialItem }: PopoverSelectProps) => {
  const [pickerOpen, setPickerOpen] = useState(false);

  const handleOpenPicker = () => {
    setPickerOpen(true);
  };

  const handleClosePicker = () => {
    setPickerOpen(false);
  };

  const handleSegmentSelect = (segmentIndex: string) => {
    if (!onPress) return;
    onPress(segmentIndex);

    handleClosePicker();
  };

  const activator = (
    <div>
      <Button onClick={handleOpenPicker} fullWidth textAlign="left" disabled={disabled}>
        {buttonLabel}
      </Button>
    </div>
  );

  const segmentList = items?.map(({ label, id, value }) => {
    if (!value) return null;
    return (
      <Listbox.Option key={id} value={value} selected={false}>
        <Listbox.TextOption selected={false}>{label}</Listbox.TextOption>
      </Listbox.Option>
    );
  });

  const listboxMarkup = (
    <>
      {initialItem}
      <Listbox enableKeyboardControl onSelect={handleSegmentSelect}>
        {segmentList}
      </Listbox>
      {finalItem}
    </>
  );

  return (
    <div>
      <Popover
        active={pickerOpen}
        activator={activator}
        ariaHaspopup="listbox"
        preferredAlignment="left"
        autofocusTarget="first-node"
        onClose={handleClosePicker}
      >
        <Popover.Pane>
          <div
            style={{
              alignItems: 'stretch',
              borderTop: '1px solid #DFE3E8',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'stretch',
              position: 'relative',
              width: '100%',
              height: '100%',
              overflow: 'hidden',
            }}
          >
            <Scrollable
              shadow
              style={{
                position: 'relative',
                width: '400px',
                height: '292px',
                padding: 'var(--p-space-2) 0',
                borderBottomLeftRadius: 'var(--p-border-radius-2)',
                borderBottomRightRadius: 'var(--p-border-radius-2)',
              }}
            >
              {listboxMarkup}
            </Scrollable>
          </div>
        </Popover.Pane>
      </Popover>
    </div>
  );
};

export { PopoverSelect };
