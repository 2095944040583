import { useEffect, useRef } from 'react';

type DebouncedCallback = () => any;

const useDebounce = (cb: DebouncedCallback, delay: number, dep: any[]) => {
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  return useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      if (cb) {
        cb();
      }
    }, delay);
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, dep);
};

export { useDebounce };
