import { CheckEmail } from '@pages/forgot-password/check-email';
import { EnterEmail } from '@pages/forgot-password/enter-email';
import { useState } from 'react';

export const ForgotPassword: any = () => {
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState('');

  const handleEnterEmailDone = (value: string) => {
    setEmail(value);
    setStep(1);
  };

  switch (step) {
    case 1:
      return <CheckEmail email={email} />;
    default:
      return <EnterEmail onDone={handleEnterEmailDone} />;
  }
};
