import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const CoursesIcon = (props: SvgProps) => (
  <Svg width={12} height={16} fill="none" {...props}>
    <Path
      d="M3.012 2h9v-.5a1.5 1.5 0 0 0-1.5-1.5h-7.5a3 3 0 0 0-3 3v11.5a1.5 1.5 0 0 0 1.5 1.5h9a1.5 1.5 0 0 0 1.5-1.5v-9a1.5 1.5 0 0 0-1.5-1.5h-.5v5l-2-2-2 2V4h-4V3a1 1 0 0 1 1-1Z"
      fill="#0D1738"
    />
  </Svg>
);

export { CoursesIcon };
