import { GoCardLessIcon } from '@components/general/icons/account-icons/GoCardLessIcon';
import { GoCardlessCustomer } from '@gql/generated/generated';
import { Text, View } from 'react-native';

import { styles } from './styles';

interface GoCardLessInfoProps {
  goCardLessCustomer?: GoCardlessCustomer | null;
}

export const GoCardLessInfo = ({ goCardLessCustomer }: GoCardLessInfoProps) => {
  return (
    <View style={styles.rightSideContainer}>
      <View style={styles.image}>
        <GoCardLessIcon />
      </View>
      <View style={styles.paymentInfoContainer}>
        {goCardLessCustomer?.bankDetails?.accountNumberEnding ? (
          <>
            <View style={styles.paymentInfo}>
              <Text style={styles.paymentInfoTitle}>Company Name</Text>
              <Text style={styles.paymentInfoTitle}>Account Number</Text>
              <Text style={styles.paymentInfoTitle}>Postcode</Text>
            </View>
            <View>
              <Text style={styles.paymentInfoValue}>{goCardLessCustomer?.bankDetails?.accountHolderName}</Text>
              <Text style={styles.paymentInfoValue}>
                ••••••
                {goCardLessCustomer?.bankDetails?.accountNumberEnding}
              </Text>
              <Text style={styles.paymentInfoValue}>{goCardLessCustomer?.bankDetails?.bankName}</Text>
            </View>
          </>
        ) : null}
      </View>
    </View>
  );
};
