import { ButtonGeneral } from '@components/general/button-general';
import { ListModal } from '@components/general/modals/list-modal';
import React from 'react';
import { Linking, Text } from 'react-native';

import { styles } from './style';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  calendlyLink?: string;
}

const RegisterModal = ({ isVisible, onClose, calendlyLink }: Props) => {
  const handleRegister = async () => {
    if (calendlyLink) {
      await Linking.openURL(calendlyLink);
    }
  };

  return (
    <ListModal isVisible={isVisible} onClose={onClose}>
      <Text style={styles.calendarModalTitle}>Join the event</Text>
      <Text style={styles.calendarModalItemText}>You will now be redirected to Calendly</Text>
      <ButtonGeneral label="Register" onPress={handleRegister} />
    </ListModal>
  );
};

export { RegisterModal };
