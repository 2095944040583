import { SelectOption } from '@components/general/form/select-option';
import { ExcelSheetDownload } from '@components/org/analytics/analytics-header/ExcelSheetDownload.web';
import { Sheet } from '@components/org/analytics/analytics-header/ExcelSheetDownloadTypes';
import React, { useState } from 'react';
import { View, Text } from 'react-native';
import { useNavigate } from 'react-router-native';

import { styles } from './style';

const selectOptions = [
  { label: 'General', value: 'general' },
  { label: 'Content', value: 'content' },
  { label: 'Events', value: 'events' },
  { label: 'Training', value: 'training' },
];

type Path = 'training' | 'events' | 'content' | 'general';

const AnalyticsHeader = ({ excelData, path }: { excelData: Sheet[]; path: Path }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(path);

  const handleChange = (option: string) => {
    setValue(option as Path);
    navigate(`/Analytics/${option}`);
  };

  return (
    <View style={styles.wrapper}>
      <View style={styles.wrap}>
        <View style={styles.left}>
          <Text style={styles.title}>Analytics</Text>
          <SelectOption onChange={handleChange} value={value} options={selectOptions} />
        </View>
        <ExcelSheetDownload sheets={excelData} variant="primary" />
      </View>
    </View>
  );
};

export { AnalyticsHeader };
