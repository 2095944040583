import { StatusTag, TagStatus } from '@components/back-office/editor/status-tag';
import { GridPage } from '@components/back-office/grid/GridPage';
import { useQueryFilters } from '@components/back-office/grid/useGridFilters';
import { ContentType, PollStatus, Status, useAdminGetAllPollsQuery, useDeletePollMutation, useUpdatePollMutation } from '@gql/generated/generated';
import { IndexTableHeading } from '@shopify/polaris/build/ts/latest/src/components/IndexTable';
import { NonEmptyArray } from '@shopify/polaris/build/ts/latest/src/types';
import { pastTensify } from '@utils/misc';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useNavigate } from 'react-router-native';

import { DeleteConfirmationModal } from '../../../components/back-office/editor/delete-confirmation-modal';

const activeFilters = ['search', 'status', 'dateFrom', 'dateTo', 'pollStatus'];

export const PollsBackOffice = () => {
  const [pollIdToBeDeleted, setPollIdToBeDeleted] = useState<string | undefined>();

  const navigate = useNavigate();
  const { page, filters } = useQueryFilters(activeFilters);

  const { data, loading, refetch } = useAdminGetAllPollsQuery({
    variables: {
      page,
      limit: 10,
      filters,
    },
  });

  const [deletePoll] = useDeletePollMutation({
    update: (store, _result, options) => {
      const id = options?.variables?.id;
      if (id) {
        const normalizedId = store.identify({
          id,
          __typename: `${ContentType.POLL}`,
        });
        store.evict({ id: normalizedId });
        store.gc();
      }
    },
  });
  const [archivePoll] = useUpdatePollMutation({
    update: (store, _result, options) => {
      const itemId = options?.variables?.input?.id;
      if (itemId) {
        store.modify({
          id: `${ContentType.POLL}:${itemId}`,
          fields: {
            status() {
              return Status.ARCHIVE;
            },
          },
        });
      }
    },
  });

  const { polls, hasNextPage, total } = data?.adminGetAllPolls || {};

  const tableHeadings: NonEmptyArray<IndexTableHeading> = [
    { title: 'Topic' },
    { title: 'Author' },
    { title: 'Poll status' },
    { title: 'Last modified' },
    { title: 'Close on' },
    { title: 'Status' },
    { title: '' },
  ];

  const rows =
    polls &&
    polls.map(({ id, pollQuestion, closeDate, authorName, createdAt, updatedAt, status, pollStatus }) => {
      const getPollStatus = () => {
        if (pollStatus === PollStatus.CLOSED) {
          return {
            status: TagStatus.Inactive,
            text: 'Closed',
          };
        } else if (pollStatus === PollStatus.OPEN) {
          return { status: TagStatus.Active, text: 'Open' };
        }
        return {
          status: TagStatus.Default,
          text: 'N/A',
        };
      };
      return {
        id,
        title: pollQuestion,
        authorName,
        pollStatus: <StatusTag status={getPollStatus().status}>{getPollStatus().text}</StatusTag>,
        date: dayjs(updatedAt ?? createdAt).format('DD/MM/YYYY'),
        closeDate: dayjs(closeDate).format('DD/MM/YYYY'),
        status: pastTensify(status),
      };
    });

  const handleMenuClick = async (itemId: string, optionID: string) => {
    switch (optionID) {
      case 'View':
        return navigate(`/poll-results/${itemId}` as string);
      case 'Edit':
        return navigate(`/poll-editor/${itemId}`);
      case 'Archive':
        await archivePoll({
          variables: {
            input: {
              id: itemId,
              status: Status.ARCHIVE,
            },
          },
        });
        break;
      case 'Delete':
        setPollIdToBeDeleted(itemId);
        break;
      default:
        break;
    }
  };

  const handlePressDelete = async () => {
    if (pollIdToBeDeleted) {
      await deletePoll({ variables: { id: pollIdToBeDeleted } });
    }
  };

  return (
    <>
      <DeleteConfirmationModal
        title="Are you sure you want to delete this poll?"
        onClose={() => setPollIdToBeDeleted(undefined)}
        onDeleteConfirm={async () => {
          await handlePressDelete();
          setPollIdToBeDeleted(undefined);
        }}
        visible={!!pollIdToBeDeleted}
      />
      <GridPage
        title="Polls"
        menuItems={[
          { id: 'View', content: 'View' },
          { id: 'Edit', content: 'Edit' },
          { id: 'Archive', content: 'Archive' },
          { id: 'Delete', content: 'Delete' },
        ]}
        newLink={{
          url: '/poll-editor',
          title: 'Create New Poll',
          label: 'Create Poll',
        }}
        activeFilters={activeFilters}
        rows={rows || []}
        loading={loading}
        tableHeadings={tableHeadings}
        onBulkAction={() => refetch()}
        onMenuItem={handleMenuClick}
        pagination={{
          perPage: 10,
          page,
          totalItems: total,
          hasNextPage,
        }}
      />
    </>
  );
};
