import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  modalContent: {
    width: '100%',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  modalInner: {
    paddingTop: 8,
    paddingBottom: 40,
    paddingLeft: 28,
    paddingRight: 28,
  },
  dashWrap: {
    alignItems: 'center',
    marginBottom: 28,
  },
  dash: {
    backgroundColor: '#D9D9D9',
    borderRadius: 8,
    width: 60,
    height: 4,
  },
  itemTouch: {
    width: '100%',
    height: 36,
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconWrap: {
    marginRight: 4,
  },
  itemText: {
    color: '#0D1738',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
  },
});
