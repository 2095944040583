import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
  },
  title: {
    color: '#202223',
    fontSize: 17,
    lineHeight: 28,
  },
  subtitle: {
    color: '#202223',
    marginTop: 0,
    lineHeight: 24,
    fontFamily: 'OpenSans_400Regular',
  },
});
