import { getInitialsFromName } from '@utils/misc';
import React from 'react';
import { Image, Text, View } from 'react-native';

import { styles } from './style';
import { SocialButtons } from '../../general/icons';
import { INITIALS_CIRCLE_BACKGROUND_COLORS, InitialsCircle } from '../../general/initials-circle';
import { Row } from '../../general/row';

interface SpeakerSectionProps {
  speaker: any;
  index: number;
}

const SpeakerSection = ({ speaker, index }: SpeakerSectionProps) => {
  const Initials = getInitialsFromName(speaker.name);

  const { socials, photo } = speaker;
  return (
    <>
      <Row style={styles.container}>
        {photo && photo.length > 0 ? (
          <Image
            source={photo[0]}
            style={{
              width: 40,
              height: 40,
              borderRadius: 20,
            }}
          />
        ) : (
          <InitialsCircle initials={Initials} backgroundColor={INITIALS_CIRCLE_BACKGROUND_COLORS[index % 4]} />
        )}

        <View style={styles.infoSection}>
          <Text style={styles.speakerText}>About the speaker</Text>
          <Text style={styles.nameText}>{speaker.name}</Text>
        </View>
      </Row>
      <Text style={styles.descriptionText}>{speaker.description}</Text>
      <View style={styles.socialButtonsContainer}>
        <SocialButtons
          twitterLink={socials?.twitter}
          facebookLink={socials?.facebook}
          instagramLink={socials?.instagram}
          linkedInLink={socials?.linkedin}
          id="eventId"
          contentType="Events"
        />
      </View>
    </>
  );
};

export { SpeakerSection };
