import { percentageChange } from '@components/org/analytics/helpers';
import { useGetAdminContentBoxesQuery, useGetAdminContentRowsQuery, useGetAdminContentStatsQuery } from '@gql/generated/generated';
import { useMemo } from 'react';

export const useContentAnalytics = () => {
  const { data: analyticsData, refetch } = useGetAdminContentStatsQuery();
  const { data: rowsData, refetch: refetchContentRows } = useGetAdminContentRowsQuery();
  const { data: boxData } = useGetAdminContentBoxesQuery();

  const boxTitles = ['No. of Downloads', 'Share event', 'Average Time Spent'];

  const stats = boxData?.adminGetMemberStats.content;
  const boxItems = useMemo(() => {
    if (!stats)
      return boxTitles.map((item) => ({
        title: item,
      }));
    return [
      {
        title: 'No. of Downloads',
        count: stats?.numDownloads.curr ?? 0,
        countInfo: 'times',
        percent: percentageChange(stats?.numDownloads.prev, stats?.numDownloads.curr),
        percentInfo: 'than last month',
      },
      {
        title: 'Share event',
        count: stats?.numShares.curr ?? 0,
        countInfo: 'times',
        percent: percentageChange(stats?.numShares.prev, stats?.numShares.curr),
        percentInfo: 'than last month',
      },
      {
        title: 'Average Time Spent',
        count: stats?.avgTimeMins.curr ?? 0,
        countInfo: 'min/day/user',
        percent: percentageChange(stats?.avgTimeMins.prev, stats?.avgTimeMins.curr),
        percentInfo: 'than last month',
      },
    ];
  }, [stats]);

  const dataForExport = useMemo(() => {
    if (!rowsData || !analyticsData) return [];
    return [
      {
        columns: [
          { label: 'Title', value: 'title' },
          { label: 'Avg. time (mins/member)', value: 'time' },
          { label: 'Total downloads', value: 'downloads' },
          { label: 'Visitors', value: 'visitors' },
          { label: 'Rating', value: 'rating' },
          { label: 'Shares', value: 'shares' },
        ],
        data: rowsData?.adminGetMemberStats.content?.contentAnalytics.map((item) => {
          return {
            title: item.title,
            time: item.avgTimeMins,
            downloads: item.numDownloads,
            visitors: item.numVisitors,
            rating: item.avgRating ?? '-',
            shares: item.numShares,
          };
        }),
        name: 'Content analytics',
      },
      {
        columns: [
          { label: 'Date', value: 'date' },
          {
            label: 'Name',
            value: 'name',
          },
          {
            label: 'Message',
            value: 'message',
          },
        ],
        data: analyticsData?.adminGetMemberStats.content?.requests.map((request) => {
          return {
            date: request.date,
            name: request.user.name ?? '-',
            message: request.message,
          };
        }),
        name: 'Content requests',
      },
    ];
  }, [rowsData, analyticsData]);

  return {
    boxItems,
    analyticsData,
    refetch,
    refetchContentRows,
    dataForExport,
    contentAnalytics: rowsData?.adminGetMemberStats.content?.contentAnalytics,
  };
};
