import { CheckBox } from '@components/checkbox';
import React, { useState } from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';

import { styles } from './style';
import Avatar from '../../../../assets/img/Avatar.png';
import DeleteMember from '../../../../assets/img/DeleteMember.png';
import UserTypeImg from '../../../../assets/img/UserTypeFilter.png';
import { DropdownTopLabel } from '../../general/dropdown-top-label';
import { FieldTopLabel } from '../../general/field-top-label';
import { MainTable } from '../tables/main-table/MainTable';

export const RoleSettings = ({ setIsBackSettings }: any) => {
  const [page, setPage] = useState(1);
  const [userSelected, setUserSelected] = useState(-2);

  const userRoles = [
    {
      label: 'Administrator',
      value: 'Administrator',
    },
    {
      label: 'Author',
      value: 'Author',
    },
  ];

  const handlePagination = (action: string) => {
    if (action === 'previous' && page > 1) setPage(page - 1);
    if (action === 'next' && page < 15) setPage(page + 1);
  };

  return (
    <View style={styles.flexRow}>
      <View style={styles.paginationWrapper}>
        <TouchableOpacity onPress={() => handlePagination('previous')}>
          <Text style={styles.paginationLeft}>{'<'}</Text>
        </TouchableOpacity>
        <Text style={styles.paginationLeft}>{page}</Text>
        <Text style={styles.paginationCenter}>of</Text>
        <Text style={styles.paginationRight}>15</Text>
        <TouchableOpacity onPress={() => handlePagination('next')}>
          <Text style={styles.paginationRight}>{'>'}</Text>
        </TouchableOpacity>
      </View>
      <View style={{ marginTop: 34, marginLeft: -7 }}>
        <MainTable isSettings setUserSelected={setUserSelected} />
      </View>
      {userSelected > -2 && (
        <View style={styles.site}>
          <View style={styles.flexRow}>
            <Image source={Avatar} style={styles.avatarBig} />
            {userSelected === -1 ? (
              <Text style={styles.titleCreate}>Create a new User</Text>
            ) : (
              <View>
                <Text style={styles.titleCreate}>Firstname Lastname</Text>
                <View style={styles.flexRow}>
                  <Text style={[styles.detailTitle, { marginHorizontal: 15 }]}>Username</Text>
                  <Text style={styles.rolePill}>Administrator</Text>
                </View>
              </View>
            )}
          </View>
          <View style={styles.separation} />
          <Text style={styles.detailTitle}>Info</Text>
          <View style={[styles.flexWrap, { width: 326 }]}>
            <FieldTopLabel label="Username *" size={322} />
            <FieldTopLabel label="Email Address *" size={158} />
            <FieldTopLabel label="Phone Number" size={158} />
            <FieldTopLabel label="Firstname" size={158} />
            <FieldTopLabel label="Lastname" size={158} />
          </View>
          <View style={styles.separation} />
          <Text style={styles.detailTitle}>Role</Text>
          <View style={styles.flexRow}>
            <Image source={UserTypeImg} style={styles.userRoleImg} />
            <Text
              style={[
                styles.subTitle,
                {
                  marginRight: 20,
                  marginBottom: 35,
                },
              ]}
            >
              User Role
            </Text>
            <DropdownTopLabel size={135} options={userRoles} label="" />
          </View>
          <View style={styles.flexRow}>
            <View style={[styles.wrapperCheckbox, { marginBottom: 15, marginLeft: 0 }]}>
              <View style={styles.borderCheckbox}>
                {/* @ts-ignore FIXME: bad component */}
                <CheckBox boxStyle={styles.checkbox} colorActive="#6E757C" value onChangeValue={() => console.error('Not implemented')} />
              </View>
              <Text style={[styles.labelCheckbox, { marginTop: 3, marginLeft: 4 }]}>Send User an email notification with generated password</Text>
            </View>
          </View>
          <View style={[styles.flexRow, { marginTop: 20 }]}>
            <TouchableOpacity onPress={() => console.error('Not implemented')}>
              <Text style={styles.mainBtn}>Cancel</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => userSelected === -1 && setIsBackSettings(true)}>
              <Text style={styles.mainBtn}>{userSelected > -1 ? 'Save' : 'Create'}</Text>
            </TouchableOpacity>
          </View>
          {userSelected > -1 && (
            <View style={[styles.flexRow, { marginTop: 12 }]}>
              <Image source={DeleteMember} style={styles.deleteUserImg} />
              <Text style={styles.deleteText}>Delete User</Text>
            </View>
          )}
        </View>
      )}
    </View>
  );
};
