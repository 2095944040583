import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  title: {
    color: '#0D1738',
    fontSize: 20,
    fontFamily: 'OpenSans_600SemiBold',
    marginBottom: 28,
  },
  contentBox: {
    width: '100%',
    height: 260,
    overflow: 'scroll',
  },
  itemWrap: {
    borderBottomWidth: 1,
    borderBottomColor: '#EDEEEF',
  },
  itemTouch: {
    padding: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  itemTitle: {
    color: '#202223',
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
  },
  itemContent: {
    padding: 20,
  },
  rateText: {
    color: '#6D7175',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    marginBottom: 8,
  },
  percentWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  percentText: {
    fontSize: 20,
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
    marginRight: 8,
  },
  greenText: {
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
    color: '#23827F',
    marginLeft: 8,
  },
  dateText: {
    color: '#6D7175',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
  },
  imagesWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
    ...(Platform.OS === 'web' && { gap: 8 }),
  },
  image: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  countWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#EDEEEF',
  },
  countText: {
    color: '#6D7175',
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
  },
});
