import { DocumentAsset } from '@gql/generated/generated';
import { useOutsideClick } from '@hooks/useOutsideClick';
import { forceDownloadFirebaseFile } from '@utils/forceDownloadFirebaseFile';
import { formatFileTitle } from '@utils/misc';
import { useState } from 'react';
import { Platform, Text, TouchableOpacity, View } from 'react-native';

import { styles, ids } from './style';
import { ButtonGeneral, ButtonVariant } from '../button-general';
import { DownloadIcon } from '../icons';

const DownloadItem = ({
  file,
  onDownload,
}: {
  file: DocumentAsset & {
    downloadUrl: string;
  };
  onDownload?: (
    file: DocumentAsset & {
      downloadUrl: string;
    }
  ) => void;
}) => {
  return (
    <TouchableOpacity
      onPress={async () => {
        onDownload && onDownload(file);
        forceDownloadFirebaseFile(file);
      }}
    >
      <Text style={styles.actionList} dataSet={{ media: ids.actionList }} numberOfLines={1}>
        {formatFileTitle(file.name, file.size)}
      </Text>
    </TouchableOpacity>
  );
};

export const DownloadFilesButton = ({
  filesWithUrl,
  onDownload,
  buttonTitle = 'Download Resources',
}: {
  filesWithUrl?: (DocumentAsset & { downloadUrl: string })[] | null;
  onDownload?: (
    file: DocumentAsset & {
      downloadUrl: string;
    }
  ) => void;
  buttonTitle?: string;
}) => {
  const [isDownloadMenuVisible, setIsDownloadMenuVisible] = useState(false);

  useOutsideClick(() => setIsDownloadMenuVisible(false));

  const DownloadButton = (
    <ButtonGeneral
      style={[styles.buttonStyle, Platform.OS !== 'web' && styles.downloadActivatorStyle]}
      variant={ButtonVariant.Secondary}
      onPress={() => {
        if (filesWithUrl?.length && filesWithUrl.length === 1) {
          onDownload && onDownload(filesWithUrl[0]);
          forceDownloadFirebaseFile(filesWithUrl[0]);
        } else {
          setIsDownloadMenuVisible(!isDownloadMenuVisible);
        }
      }}
      hoverOutline="#4490FF"
      disabled={!filesWithUrl?.length}
    >
      <View style={{ marginRight: 4 }}>
        <DownloadIcon />
      </View>
      <Text style={styles.saveButtonText}>{buttonTitle}</Text>
    </ButtonGeneral>
  );

  return (
    <View style={styles.downloadContainer}>
      {DownloadButton}
      {isDownloadMenuVisible ? (
        <View style={styles.downloadList}>
          {filesWithUrl?.map((file) => (
            <DownloadItem key={file.id} file={file} onDownload={onDownload} />
          ))}
        </View>
      ) : null}
    </View>
  );
};
