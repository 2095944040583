import { GestureResponderEvent, TouchableOpacity, TouchableOpacityProps } from 'react-native';

export type TouchableOpacityLinkProps = Omit<typeof TouchableOpacity, 'prototype'> &
  TouchableOpacityProps & {
    href: string | null | undefined;
    onPress?: () => void;
  };

// see https://stackoverflow.com/questions/72197067/rn-web-href-in-touchableopacity-onpress-navigation-onright-click-context-m
export const TouchableOpacityLink = ({ href, onPress, ...rest }: TouchableOpacityLinkProps) => {
  return (
    <TouchableOpacity
      {...rest}
      accessibilityRole="link"
      onPress={(e: GestureResponderEvent) => {
        e.preventDefault();
        onPress?.();
      }}
      // @ts-ignore
      href={href}
    />
  );
};
