import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    height: '100%',
  },
  infoWrap: {
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#EDEEEF',
    paddingBottom: 0,
  },
  titleMargin: {
    marginBottom: 24,
  },
  infoTitle: {
    fontSize: 16,
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
  },
  infoItem: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    marginBottom: 24,
  },
  infoItemLeft: {
    width: '30%',
  },
  infoItemRight: {
    width: '70%',
  },
  infoItemQuestion: {
    color: '#6D7175',
    fontFamily: 'OpenSans_400Regular',
    fontSize: 16,
  },
  infoItemAnswer: {
    color: '#0D1738',
    fontFamily: 'OpenSans_400Regular',
    fontSize: 16,
    width: '100%',
  },
  questionItem: {
    marginBottom: 14,
  },
  answerItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  answerDot: {
    height: 4,
    width: 4,
    borderRadius: 2,
    backgroundColor: '#0D1738',
    marginRight: 8,
  },
  answerText: {
    color: '#0D1738',
    fontFamily: 'OpenSans_400Regular',
    fontSize: 16,
  },
  editButtonWrap: {
    position: 'absolute',
    bottom: 40,
    left: 12,
    right: 12,
  },
});
