import { ContentBox } from '@components/general/layouts/content-box';
import { Image, ImageSourcePropType, Text, View } from 'react-native';

import { styles } from './style';

interface Props {
  image?: ImageSourcePropType;
  name?: string;
}

export const StatisticUserCard = ({ image, name }: Props) => {
  return (
    <ContentBox style={{ padding: 20, flex: 1 }}>
      <Text style={styles.mainTitle}>User</Text>
      <View
        style={{
          marginVertical: 8,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <View style={styles.imageContainer}>
          <Image source={image ? image : require('/assets/img/Avatar.png')} style={styles.image} />
        </View>
        <Text style={styles.nameText}>{name ? name : '-'}</Text>
      </View>
    </ContentBox>
  );
};
