import { ThreeDots } from '@components/general/icons/training-icons/ThreeDots';
import { IndexPageHeader } from '@components/general/index-page-header';
import { Wrapper } from '@components/general/layouts/wrapper/Wrapper';
import { ItemType, OrgModalLayout } from '@components/org/org-modal-layout';
import { Props as SearchItemProps, SearchItem } from '@components/search/search-item';
import { AssignmentsItemDetails, Event, GetUserAssignmentsQuery } from '@gql/generated/generated';
import { getModuleLessons } from '@utils/misc';
import { PageVariant } from '@utils/models';
import React, { useState } from 'react';
import { FlatList, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { styles } from './style';

interface ListItemProps extends SearchItemProps {
  onPress?: () => void;
  onPressOption?: () => void;
}

const ListItem = (props: ListItemProps) => (
  <TouchableOpacity style={styles.itemWrap} onPress={props.onPress}>
    <View style={styles.leftItem}>
      <SearchItem {...props} />
    </View>
    <TouchableOpacity style={styles.optionTouch} onPress={props.onPressOption}>
      <ThreeDots />
    </TouchableOpacity>
  </TouchableOpacity>
);

interface Props {
  title: string;
  trainings?: GetUserAssignmentsQuery['getUserAssignments']['assignedItems'];
  contents?: GetUserAssignmentsQuery['getUserAssignments']['assignedItems'];
  events?: GetUserAssignmentsQuery['getUserAssignments']['assignedItems'] | Event[];
  pageVariant: PageVariant;
  itemTypes: ItemType[];
  onPressItem: (item: AssignmentsItemDetails, itemType: ItemType, variant: PageVariant) => void;
  isSuccessVisible?: boolean;
  successInfo?: { title: string; message: string };
  isUndoSuccessVisible?: boolean;
  onUndoSuccess?: () => void;
}

const OrgMobileLayout = ({
  title,
  trainings,
  contents,
  events,
  pageVariant,
  itemTypes,
  onPressItem,
  isSuccessVisible,
  successInfo,
  onUndoSuccess,
}: Props) => {
  const navigate = useNavigate();
  const [isOptionModalVisible, setIsOptionModalVisible] = useState(false);
  const [optionValue, setOptionValue] = useState<{
    value?: AssignmentsItemDetails;
    variant?: PageVariant;
  }>();

  const handleOptionPress = (value: AssignmentsItemDetails, variant: PageVariant) => {
    setOptionValue({ value, variant });
    setIsOptionModalVisible(true);
  };

  const renderItem = ({ item }: { item: AssignmentsItemDetails }) => {
    if (pageVariant === PageVariant.event) {
      return (
        <View style={styles.eachItem}>
          <ListItem
            asset={item?.coverImage}
            title={item?.title || ''}
            date={item?.date?.toString() || ''}
            subtitle={item?.location || ''}
            onPress={() => navigate(`/events/${item.id}`)}
            onPressOption={() => handleOptionPress(item, PageVariant.event)}
          />
        </View>
      );
    } else if (pageVariant === PageVariant.content) {
      return (
        <View style={styles.eachItem}>
          <ListItem
            asset={item?.coverImage}
            title={item?.title || ''}
            date={item?.date?.toString() || ''}
            categories={item?.categories || []}
            onPress={() => navigate(`/content/${item.id}`)}
            onPressOption={() => handleOptionPress(item, PageVariant.content)}
          />
        </View>
      );
    } else if (pageVariant === PageVariant.training) {
      return (
        <View style={styles.eachItem}>
          <ListItem
            asset={item?.coverImage}
            title={item?.title || ''}
            date={`${getModuleLessons(item.modules)?.length}`}
            isRating
            subtitle={`${item.averageStars} • ${item.shareCount || 0} shares`}
            onPress={() => navigate(`/training/${item.id}`)}
            onPressOption={() => handleOptionPress(item, PageVariant.training)}
          />
        </View>
      );
    } else {
      return (
        <SearchItem
          imageUrl="https://kaboompics.com/cache/8/f/4/4/8/8f448441e8c6ffb8254285163fb061e0d1895733.jpeg"
          title="The art of negotiation"
          date="12 Aug 22"
          categories={['Marketing', 'Sales', 'Operation']}
        />
      );
    }
  };

  const getData = () => {
    switch (pageVariant) {
      case PageVariant.training:
        return trainings ?? [];
      case PageVariant.event:
        return events ?? [];
      case PageVariant.content:
        return contents ?? [];
      default:
        return [];
    }
  };

  const handleItemPress = (itemType: ItemType) => {
    if (optionValue?.value && onPressItem && optionValue.variant) {
      onPressItem(optionValue.value, itemType, optionValue.variant);
    }
  };

  return (
    <Wrapper>
      <IndexPageHeader title={title} onPressBack={() => navigate(-1)} />
      <FlatList
        // FIXME: fix types
        data={getData() as any}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        contentContainerStyle={styles.flatList}
      />
      <OrgModalLayout
        onClose={() => setIsOptionModalVisible(false)}
        isVisible={isOptionModalVisible}
        itemTypes={itemTypes}
        onPressItem={handleItemPress}
        isSuccessVisible={isSuccessVisible}
        successInfo={successInfo}
        isUndoSuccessVisible={isSuccessVisible}
        onUndoSuccess={onUndoSuccess}
        value={optionValue?.value}
        variant={optionValue?.variant}
      />
    </Wrapper>
  );
};

export { OrgMobileLayout };
