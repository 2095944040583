import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  subTitlePerson: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 20,
    color: '#202223',
  },
  personContainer: {
    height: 500,
    ...(Platform.OS === 'web' && { height: 'max-content' }),
  },
  headerPerson: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderColor: '#ECEEF7',
  },
  speakerSubRow: {
    marginBottom: 16,
  },
  fieldTitle: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#202223',
    marginBottom: 4,
  },
  flexOne: {
    flex: 1,
  },
  marginRight: {
    marginRight: 16,
  },
  socialRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
  },
  fileUploader: {
    marginTop: 12,
    marginBottom: 12,
  },
  modalContainer: {
    padding: 12,
  },
});
