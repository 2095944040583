import { TableDropdown } from '@components/back-office/tables/table-dropdown';
import { ExportIcon } from '@components/general/icons/ExportIcon';
import { useAdminGetRoadmapQuery, useEmployerGetRoadmapQuery } from '@gql/generated/generated';
import { GoalView } from '@pages/back-office/roadmap-back-office/goal-view';
import { NorthStarView } from '@pages/back-office/roadmap-back-office/north-star-view';
import { PulseView } from '@pages/back-office/roadmap-back-office/pulse-view';
import csvDownload from 'json-to-csv-export';
import { useEffect, useState } from 'react';
import { ActivityIndicator, Image, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate, useParams } from 'react-router-native';

import { styles } from './style';

interface IFile {
  question: string;
  answer: string;
}
export const ViewRoadMapLayout = () => {
  const navigate = useNavigate();
  const { id, plan } = useParams();

  const [selectedItem, setSelectedItem] = useState('North Star');

  const { data, loading } = useAdminGetRoadmapQuery({
    variables: { id: id as string },
  });

  useEffect(() => {
    plan === 'goal' && setSelectedItem('Goal');
    plan === 'pulse' && setSelectedItem('Pulse');
  }, []);

  const items = [
    { id: 'North Star', content: 'North Star' },
    { id: 'Goal', content: 'Goal' },
    { id: 'Pulse', content: 'Pulse' },
  ];

  function convertToCSV(fileToConvert: any) {
    let arrayObject: any = [];
    if (fileToConvert === 'North Star') {
      arrayObject = data?.adminGetRoadmap.northStar?.[0];
    } else if (fileToConvert === 'Goal') {
      arrayObject = data?.adminGetRoadmap.goal?.[0];
    } else {
      arrayObject = data?.adminGetRoadmap.pulse?.[0];
    }

    const file: IFile[] = [];
    arrayObject.sections.map((section: any) =>
      section.questions.map((question: any) =>
        typeof question.answer.value === 'object'
          ? question.answer.value.map((answer: any) => file.push({ question: question.question, answer }))
          : file.push({
              question: question.question,
              answer: question.answer.value,
            })
      )
    );

    return file;
  }

  const dataToConvert = {
    data: data && convertToCSV(selectedItem),
    filename: 'QuestionAndAnswers',
    delimiter: ',',
    headers: ['Question', 'Answer'],
  };

  if (loading) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  }

  return (
    <>
      {/* header */}
      <View style={styles.header}>
        <View style={{ flexDirection: 'row' }}>
          <TouchableOpacity
            onPress={() => {
              navigate(-1);
            }}
            style={styles.headerBackBtn}
          >
            <Image source={require('/assets/img/ArrowLeft.png')} style={styles.headerArrowLeft} />
          </TouchableOpacity>
          <Text style={styles.headerMainTitle}>{data?.adminGetRoadmap.companyName}</Text>
          <TableDropdown items={items} placeholder={selectedItem} onSelectItem={(id) => setSelectedItem(id)} minWidth={124} />
        </View>

        <TouchableOpacity
          style={styles.exportBtn}
          // @ts-ignore FIXME: bad type
          onPress={() => csvDownload(dataToConvert)}
        >
          <ExportIcon style={styles.exportBtnIcon} />
          <Text style={styles.exportBtnText}>Export</Text>
        </TouchableOpacity>
      </View>

      {/* content */}
      {selectedItem === 'North Star' && <NorthStarView northStarData={data?.adminGetRoadmap?.northStar ?? []} />}
      {selectedItem === 'Goal' && <GoalView goalData={data?.adminGetRoadmap?.goal ?? []} />}
      {selectedItem === 'Pulse' && <PulseView pulseData={data?.adminGetRoadmap?.pulse ?? []} />}
    </>
  );
};
