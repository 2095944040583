import { FilterCheckbox } from '@components/filter-mobile/filter-checkbox';
import { FilterWrapper } from '@components/filter-mobile/filter-wrapper';
import React, { useState } from 'react';
import { FlatList } from 'react-native';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onSave: (contentTypes: string[]) => void;
  subCategories: string[];
}

const SubCategory = ({ isVisible, onClose, onSave, subCategories }: Props) => {
  const [selectedSubCategory, setSelectedSubCategory] = useState<string[]>([]);

  const handleSelect = (subCategory: string) => {
    if (selectedSubCategory.includes(subCategory)) {
      const index = selectedSubCategory.indexOf(subCategory);
      const contentTypesCopy = [...selectedSubCategory];
      contentTypesCopy.splice(index, 1);
      setSelectedSubCategory(contentTypesCopy);
    } else {
      setSelectedSubCategory([...selectedSubCategory, subCategory]);
    }
  };

  const renderItem = ({ item }: { item: string }) => (
    <FilterCheckbox title={item} isSelected={selectedSubCategory.includes(item)} onToggle={() => handleSelect(item)} />
  );

  return (
    <FilterWrapper
      headerTitle="Sub Category"
      isVisible={isVisible}
      onClose={onClose}
      buttonTitle="Save"
      isDoneButtonDisabled={!selectedSubCategory.length}
      onDone={() => selectedSubCategory.length && onSave(selectedSubCategory)}
    >
      <FlatList data={subCategories} renderItem={renderItem} keyExtractor={(item) => item} />
    </FilterWrapper>
  );
};

export { SubCategory };
