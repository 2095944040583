import { TableDropdown } from '@components/back-office/tables/table-dropdown';

import { useQueryFilters } from '../useGridFilters';

const postTypeItems = [
  { id: 'Announcement', content: 'Announcement' },
  { id: 'News', content: 'News' },
];

export const PostTypeFilter = () => {
  const { filters, setFilterValue } = useQueryFilters(['postType']);

  return (
    <TableDropdown
      placeholder={filters.postType || 'Post Type'}
      items={postTypeItems}
      onSelectItem={(id) => setFilterValue('postType', id)}
      onReset={filters.postType ? () => setFilterValue('postType', null) : undefined}
    />
  );
};
