import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  tableWrapper: {
    flex: 1,
  },
  tableHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: '#E1E3E5',
  },
  headerTitle: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 12,
    lineHeight: 20,
    color: '#6D7175',
  },
  boldDarkText: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 12,
    lineHeight: 20,
    color: '#0D1738',
  },
  borderBottomGray: {
    borderBottomWidth: 1,
    borderBottomColor: '#E1E3E5',
  },
  rowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  scrollView: {
    height: 280,
  },
  rowsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: '#E1E3E5',
  },
  rowItem: {
    padding: 20,
  },
  rowText: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    lineHeight: 20,
    color: '#0D1738',
  },
  subcategoryItem: {
    alignItems: 'flex-start',
    paddingVertical: 8,
    paddingHorizontal: 20,
  },
});
