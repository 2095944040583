import { mediaQuery } from '@utils/misc';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
  },
  title: {
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 20,
    marginBottom: 16,
  },
  subtitle: {
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
    color: '#0D1738',
    marginBottom: 20,
  },
  accordionWrap: {
    borderWidth: 1,
    borderColor: '#EDEEEF',
    width: '100%',
  },
  accordionHeader: {
    width: '100%',
    padding: 16,
    backgroundColor: '#F6F6F7',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [mediaQuery.forMobile]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  leftHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  arrowIcon: {
    marginRight: 12,
  },
  headerText: {
    fontSize: 14,
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
  },
  accordionContent: {
    padding: 16,
  },
  lessonWrap: {
    marginBottom: 24,
  },
  disableLessonMargin: {
    marginBottom: 0,
  },
  lessonHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 20,
    [mediaQuery.forMobile]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    flex: 1,
  },
  lessonTitleWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  readText: {
    color: '#202223',
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
  },
  lessonTitle: {
    marginLeft: 10,
    marginRight: 14,
  },
  previewWrap: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [mediaQuery.forMobile]: {
      marginTop: 4,
    },
  },
  previewText: {
    textDecorationLine: 'underline',
    color: '#2C6ECB',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
  },
  lessonDescription: {
    color: '#6D7175',
    fontSize: 14,
    marginTop: 8,
    [mediaQuery.forMobile]: {
      paddingLeft: 0,
      width: '100%',
    },
  },
  success: {
    color: '#23827F',
  },
});
