import { useCallback, useState } from 'react';
import { Platform } from 'react-native';

let Checkbox: any;

Platform.select({
  native: () => {
    Checkbox = <></>;
  },
  default: () => {
    Checkbox = require('@satel/formik-polaris').Checkbox;
  },
})();

export const CheckboxComponent = ({ isAll, allTicked, setAllTicked, name, value, handleChange, disabled }: any) => {
  return <Checkbox checked={value} onChange={handleChange} name={name} disabled={disabled} />;
};
