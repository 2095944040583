import { Image, Text, TouchableOpacity, View } from 'react-native';

import { ids, styles } from './style';
import Grid from '../../../assets/img/grid.png';
import LightBulb from '../../../assets/img/lightbulb.png';

export const WelcomePopup = ({ props }: any) => {
  const { isPopUp, setIsPopUp, isSignedIn } = props;

  return (
    <>
      <TouchableOpacity
        onPress={() => setIsPopUp(false)}
        style={[
          styles.popupBackGround,
          {
            ...(isPopUp && isSignedIn && { overflow: 'hidden' }),
          },
        ]}
      />
      <View style={styles.popup} dataSet={{ media: ids.popup }}>
        <Text style={styles.title}>Welcome!</Text>
        <Text style={styles.subTitle}>We are so happy to have you here.</Text>
        <Text style={styles.subTitle}>Please select your first step to start.</Text>
        <View style={styles.tutorial}>
          <View style={styles.tutoChoice}>
            <TouchableOpacity style={styles.tutoBtn}>
              <Image source={LightBulb} style={styles.lightBulb} />
            </TouchableOpacity>
            <Text style={styles.titleChoice}>Let us guide you</Text>
            <Text style={styles.subTitleChoice}>Follow our instructions to get the most benefits from our platform</Text>
          </View>
          <View style={styles.tutoChoice}>
            <TouchableOpacity style={styles.tutoBtn}>
              <Image source={Grid} style={styles.grid} />
            </TouchableOpacity>
            <Text style={styles.titleChoice}>View plans</Text>
            <Text style={styles.subTitleChoice}>Find the most suitable plan to grow your business</Text>
          </View>
        </View>
        <TouchableOpacity onPress={() => setIsPopUp(false)} style={styles.wrapLink}>
          <Text style={styles.skip}>Later</Text>
        </TouchableOpacity>
      </View>
    </>
  );
};
