import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  itemWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftItem: {
    width: '95%',
  },
  optionTouch: {
    padding: 8,
  },
  flatList: {
    padding: 20,
  },
  eachItem: {
    marginBottom: 12,
  },
});
