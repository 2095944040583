import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

interface Props extends SvgProps {
  color?: string;
}

const CheckIcon = (props: Props) => (
  <Svg width={10} height={8} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.723.237a.94.94 0 0 1 .053 1.277L4.41 7.707A.834.834 0 0 1 3.799 8a.83.83 0 0 1-.622-.265L.25 4.64a.94.94 0 0 1 0-1.278.82.82 0 0 1 1.207 0l2.297 2.43L8.517.293A.821.821 0 0 1 9.723.237Z"
      fill={props.color ? props.color : '#0D1738'}
    />
  </Svg>
);

export { CheckIcon };
