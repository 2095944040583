import { IFieldTypes, IQuestion, ISubmodule } from '@components/back-office/roadmap/roadmap-models';
import { RoadmapPulseSection, RoadmapPulseTemplateQuestionAnswerType, Section } from '@gql/generated/generated';
import { isNotNull } from '@utils/isNotNull';
import { IQuestion as GeneralQuestion, ISubmodule as GeneralSubmodule } from '@utils/models';

export const roadmapSectionToSubmodule = (section: Section): ISubmodule | null => {
  if (!section.id) return null;
  return {
    id: section.id,
    name: section.name ?? '',
    questions: section.questions.map(
      (question): IQuestion => ({
        id: question.id,
        question: question.question,
        subcategory: question?.subcategory ?? '',
        description: question?.description ?? '',
        answer: question.answer.hasAnswered && question.answer.value ? question.answer.value : '',
        type: question.type as IFieldTypes,
        options: question.options ?? [],
        placeholder: question.placeholder ?? undefined,
      })
    ),
  };
};

export const roadmapPulseSectionToSubmodule = (section: RoadmapPulseSection): ISubmodule | null => {
  if (!section?.id) return null;
  return {
    id: section.id,
    name: section.name ?? '',
    questions: section.questions.map(
      (question, index): IQuestion => ({
        id: question?.id ?? index.toString(),
        question: question.question,
        subcategory: question?.subcategory ?? '',
        description: question?.description ?? '',
        answer: question?.answer?.value ? question?.answer?.value : '',
        answerType: question?.answerType,
        options: question.options.map((o, i) => ({
          label: o?.label ?? '',
          value: o?.value ?? i.toString(),
        })),
        placeholder: question.placeholder ?? undefined,
      })
    ),
  };
};

export const roadmapSubmoduleToGeneralSubmodule = (submodule: ISubmodule | null): GeneralSubmodule | null => {
  if (!submodule) return null;
  return {
    ...submodule,
    questions: submodule.questions
      .map(
        (question): GeneralQuestion => ({
          ...question,
        })
      )
      .filter(isNotNull),
  };
};
