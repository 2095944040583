import { mediaQuery } from '@utils/misc';
import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  itemsWrap: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    ...(Platform.OS === 'web' && { gap: 20 }),
    [mediaQuery.forTabletDown]: {
      justifyContent: 'center',
    },
  },
});
