import { DatePicker, Range } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { styles } from './style';

interface Props {
  label: string;
  value?: Date;
  placeholder?: string;
  onChange: (date?: Date) => void;
}

const today = new Date();

const FilterDatePicker = ({ label, value, placeholder, onChange }: Props) => {
  const [showDateFrom, setShowDateFrom] = React.useState(false);

  const [{ pickerMonth, pickerYear }, setDate] = useState({
    pickerMonth: today.getMonth(),
    pickerYear: today.getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState({
    start: today,
    end: today,
  });

  const handleMonthChange = useCallback((pickerMonth: any, pickerYear: any) => setDate({ pickerMonth, pickerYear }), []);
  return (
    <View>
      <TouchableOpacity style={styles.wrap} onPress={() => setShowDateFrom(!showDateFrom)}>
        <Text style={styles.label}>{label}</Text>
        <Text style={styles.label}>{value ? value.toDateString() : placeholder}</Text>
      </TouchableOpacity>
      {showDateFrom && (
        <DatePicker
          month={pickerMonth}
          year={pickerYear}
          onChange={(v: Range) => {
            onChange(v.start);
            setSelectedDates(v);
            setShowDateFrom(false);
          }}
          onMonthChange={handleMonthChange}
          selected={selectedDates}
        />
      )}
    </View>
  );
};

export { FilterDatePicker };
