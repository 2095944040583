import { HtmlParser } from '@components/general/html-parser/HtmlParser';
import { AssetType, DocumentAsset } from '@gql/generated/generated';
import { useGetFile } from '@hooks/useGetFileUrl';
import { useVideo } from '@hooks/useVideo';
import { ResizeMode, Video } from 'expo-av';
import { Dimensions, Image, Platform, View } from 'react-native';
import RenderHtml from 'react-native-render-html';

import { styles } from './style';

export interface Props {
  caption?: string;
  asset?: DocumentAsset | null;
}

const PostBody = ({ caption, asset }: Props) => {
  const { handleVideoAspectRatio, videoRatio, paddingTop } = useVideo();

  const { fileUrl } = useGetFile(asset?.storagePath);

  return (
    <View style={styles.wrap}>
      {caption ? (
        <View style={styles.textWrapper}>
          {Platform.OS === 'web' ? (
            <HtmlParser htmlString={caption} />
          ) : (
            <RenderHtml source={{ html: caption }} contentWidth={Dimensions.get('window').width} />
          )}
        </View>
      ) : null}
      {asset?.assetType === AssetType.IMAGE ? <Image source={{ uri: fileUrl }} style={styles.image} /> : null}
      {asset?.assetType === AssetType.VIDEO && fileUrl ? (
        <View style={styles.video}>
          <Video
            style={[
              Platform.OS === 'web'
                ? {
                    paddingTop,
                  }
                : { aspectRatio: videoRatio },
            ]}
            source={{
              uri: fileUrl,
            }}
            useNativeControls
            resizeMode={ResizeMode.CONTAIN}
            videoStyle={{
              height: '100%',
              width: '100%',
            }}
            isLooping
            onReadyForDisplay={handleVideoAspectRatio}
          />
        </View>
      ) : null}
    </View>
  );
};

export { PostBody };
