import { FormikDatePickerDropdown } from '@components/back-office/editor/formik-date-picker-dropdown';

import { useQueryFilters } from '../useGridFilters';

export const DateToFilter = () => {
  const { filters, setFilterValue } = useQueryFilters(['dateTo']);

  return (
    <FormikDatePickerDropdown
      dateValue={filters.dateTo}
      placeholderText="To"
      onReset={filters.dateTo ? () => setFilterValue('dateTo', null) : undefined}
      onChange={(v) => setFilterValue('dateTo', v.start.getTime())}
    />
  );
};
