import { InputError, Props as InputErrorProps } from '@components/general/form/input-error';
import { Label, Props as LabelProps } from '@components/general/form/label';
import React from 'react';
import { TextInput, TextInputProps, View } from 'react-native';

import { styles } from './style';

export interface Props extends LabelProps, InputErrorProps, TextInputProps {
  onChangeText?: (value: string) => void;
}

const TextArea = (props: Omit<Props, 'name'>) => {
  const { label, error, isOptional, onChangeText, value } = props;
  return (
    <View style={styles.wrap}>
      {label && <Label label={label} isOptional={isOptional} />}
      <TextInput
        {...props}
        multiline
        style={styles.input}
        placeholderTextColor="#6D7175"
        underlineColorAndroid="transparent"
        onChangeText={onChangeText}
      />
      {error && <InputError error={error} />}
    </View>
  );
};

export { TextArea };
