import { LinkIcon } from '@components/general/icons/LinkIcon';
import { pluralize } from '@utils/misc';
import { Text, View } from 'react-native';

import { styles } from './style';
import { LocationPinIcon } from '../icons/event-icons';
import { OpenURLButton } from '../open-url-button';
import { Row } from '../row';
import { SaveMenu } from '../save-menu';

export enum TabsVariant {
  Content = 'Content',
  Event = 'Event',
}

interface HeaderRowProps {
  eventId: string;
  title?: string | null;
  coverImage?: string | null;
  datePublished?: string | null;
  readTime?: string | null;
  eventDate?: string | null;
  address?: string | null;
  eventType?: string | null;
  views?: number | null;
  shareCount?: number | null;
  categories?: string[];
}

const HeaderRow = ({
  eventId,
  title,
  coverImage,
  datePublished,
  readTime,
  eventDate,
  address,
  eventType,
  views,
  shareCount,
  categories,
}: HeaderRowProps) => {
  return (
    <>
      <Row style={styles.spaceBetween}>
        <View style={styles.contentBox}>
          <Text style={styles.title}>{title}</Text>
          {datePublished && readTime && (
            <Text style={styles.subduedText}>
              {datePublished} • {readTime}
            </Text>
          )}
          {eventDate ? <Text style={styles.dateText}>{eventDate}</Text> : null}
          {address ? (
            <Row style={{ alignItems: 'center', marginTop: 2 }}>
              <View style={{ marginRight: 8 }}>
                <LocationPinIcon />
              </View>
              <OpenURLButton text={address} url={`https://www.google.com/maps/place/${address}`} />
            </Row>
          ) : null}
          {eventType === 'Virtual' ? (
            <Row style={{ marginTop: 2, alignItems: 'center' }}>
              <LinkIcon style={{ marginRight: 4 }} />
              <Text style={styles.eventTypeText}>Virtual Event</Text>
            </Row>
          ) : null}
        </View>
        <View>
          <SaveMenu
            id={eventId}
            type="Event"
            coverImage={coverImage}
            title={title}
            additionalDetail={[`${views} ${pluralize(views ?? 0, 'view')}`, `${shareCount} ${pluralize(shareCount ?? 0, 'share')}`]}
            categories={categories ?? []}
          />
        </View>
      </Row>
    </>
  );
};

export { HeaderRow };
