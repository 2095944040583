import { StarIcon } from '@components/general/icons/feed-icons';
import React, { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';

import { styles } from './style';

interface Props {
  rating?: number | null;
  onRate?: (rate: number) => void;
  canRate?: boolean;
}

interface StarProps {
  index: number;
  onRate?: (rate: number) => void;
  rating: number;
  hoverIndex: number;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const Star = ({ index, onRate, hoverIndex, onMouseEnter, onMouseLeave, rating }: StarProps) => {
  const isHighlighted = hoverIndex >= index || rating >= index;
  const color = isHighlighted ? '#FFC132' : '#BABEC3';

  return (
    <TouchableOpacity
      onPress={() => {
        if (onRate) {
          onRate && onRate(index);
        }
      }}
      // @ts-ignore
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <StarIcon strokeColor={color} fillColor={color} />
    </TouchableOpacity>
  );
};

const Rating = ({ rating, onRate, canRate }: Props) => {
  const [hoverIndex, setHoverIndex] = useState(0);

  const handleMouseEnter = (index: number) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(0);
  };

  if (canRate) {
    return (
      <View style={styles.wrap}>
        {[1, 2, 3, 4, 5].map((i) => (
          <Star
            key={i}
            index={i}
            onRate={onRate}
            rating={rating ?? 0}
            hoverIndex={hoverIndex}
            onMouseEnter={() => handleMouseEnter(i)}
            onMouseLeave={handleMouseLeave}
          />
        ))}
      </View>
    );
  } else if (rating) {
    return (
      <View style={styles.wrap}>
        {[1, 2, 3, 4, 5].map((i) => (
          <StarIcon key={i} strokeColor={rating >= i ? '#FFC132' : '#BABEC3'} fillColor={rating >= i ? '#FFC132' : '#BABEC3'} />
        ))}
      </View>
    );
  }

  return null;
};

export { Rating };
