import { CONTENT_TYPES } from './constants';
import { DocumentContentType } from '../../../gql/generated/generated';

/**
 * Map an array of DocumentContentType values to human-readable strings.
 */
export const getLabelsForContentTypes = (contentTypes: DocumentContentType[]) => {
  return contentTypes.map((contentType) => {
    const humanReadableLabel = CONTENT_TYPES.find((contentTypeObj) => contentTypeObj.value === contentType)?.label;
    return humanReadableLabel ?? contentType;
  });
};
