import { FilterCheckbox } from '@components/filter-mobile/filter-checkbox';
import { ButtonGeneral } from '@components/general/button-general';
import { IndexPageHeader } from '@components/general/index-page-header';
import { Wrapper } from '@components/general/layouts/wrapper/Wrapper';
import React from 'react';
import { ScrollView, Text, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { styles } from './style';

const PersonalInfo = () => {
  const navigate = useNavigate();

  return (
    <Wrapper style={styles.wrap}>
      <IndexPageHeader title="Personal Info" onPressBack={() => navigate(-1)} />
      <ScrollView>
        <View style={styles.infoWrap}>
          <Text style={styles.infoTitle}>What's your biggest focus in the next 90 days?</Text>
          <Text style={styles.subtitle}>Tick any 2:</Text>
        </View>
        <FilterCheckbox title="Marketing Strategy" />
      </ScrollView>
      <View style={styles.buttonWrap}>
        <ButtonGeneral label="Save" />
      </View>
    </Wrapper>
  );
};

export { PersonalInfo };
