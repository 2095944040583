import { FilterListItem } from '@components/filter-mobile/filter-list-item';
import { SubCategory } from '@components/filter-mobile/filter-options/sub-category';
import { FilterWrapper } from '@components/filter-mobile/filter-wrapper';
import React, { useState } from 'react';
import { FlatList } from 'react-native';

export interface ICategory {
  category: string;
  subCategory: string[];
}

const categories: ICategory[] = [
  {
    category: 'Marketing',
    subCategory: ['Marketing Strategy', 'Social Media', 'Content Marketing', 'Marketing Measuring and Reporting', 'Marketing Systems and Technology'],
  },
  {
    category: 'New Business Development',
    subCategory: [],
  },
  {
    category: 'Account Management',
    subCategory: [],
  },
  {
    category: 'Products and Services',
    subCategory: [],
  },
  {
    category: 'Candidates',
    subCategory: [],
  },
  {
    category: 'People',
    subCategory: [],
  },
  {
    category: 'Systems and Technology',
    subCategory: [],
  },
  {
    category: 'Operations',
    subCategory: [],
  },
];

interface Props {
  isVisible: boolean;
  onClose?: () => void;
  onSave?: (categories: ICategory[]) => void;
}

const Category = ({ isVisible, onClose, onSave }: Props) => {
  const [isSubCategoryVisible, setIsSubCategoryVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<ICategory>();
  const [selectedSubCategory, setSelectedSubCategory] = useState<ICategory[]>([]);

  const handleOptionSelect = (item: ICategory) => {
    setSelectedCategory(item);
    setIsSubCategoryVisible(true);
  };

  const handleSelectedSubCategories = (subCategories: string[]) => {
    const categoryIndex = selectedSubCategory.findIndex((item) => item.category === selectedCategory?.category);
    if (categoryIndex > -1) {
      const selectedSubCategoryCopy = [...selectedSubCategory];
      selectedSubCategoryCopy.splice(categoryIndex, 1);
      setSelectedSubCategory([
        ...selectedSubCategoryCopy,
        {
          category: selectedCategory?.category as string,
          subCategory: subCategories,
        },
      ]);
    } else {
      setSelectedSubCategory([
        ...selectedSubCategory,
        {
          category: selectedCategory?.category as string,
          subCategory: subCategories,
        },
      ]);
    }
    setIsSubCategoryVisible(false);
  };

  const handleDone = () => {
    if (onSave) {
      onSave(selectedSubCategory);
    }
  };

  const renderItem = ({ item }: { item: ICategory }) => <FilterListItem title={item.category} onPress={() => handleOptionSelect(item)} />;
  return (
    <FilterWrapper headerTitle="Category" isVisible={isVisible} onClose={onClose} buttonTitle="Save" onDone={handleDone}>
      <FlatList data={categories} renderItem={renderItem} keyExtractor={(item) => item.category} />
      <SubCategory
        isVisible={isSubCategoryVisible}
        onClose={() => setIsSubCategoryVisible(false)}
        onSave={handleSelectedSubCategories}
        subCategories={(selectedCategory && selectedCategory.subCategory) || []}
      />
    </FilterWrapper>
  );
};

export { Category };
