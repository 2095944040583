import React from 'react';
import { ScrollView, View } from 'react-native';

import { QuestionAnswerRow } from './QuestionAnswerRow';
import { styles } from './style';
import { IRoadMap } from '../roadmap-models';

interface Props {
  data: IRoadMap[];
}

export const QuestionAnswerTable = ({ data }: Props) => {
  return (
    <View
      style={{
        padding: 16,
      }}
    >
      {data?.map((module, index: number) => (
        <ScrollView key={index} style={styles.scrollView}>
          {module.submodules?.map((submodule, index) => (
            <QuestionAnswerRow key={index} submodule={submodule} />
          ))}
        </ScrollView>
      ))}
    </View>
  );
};
