import { CommentItem } from '@components/home/post/comment/comment-item';
import { PostComment } from '@components/home/post/comment/post-comment';
import { useUserContext } from '@context/UserContext';
import { CollectionName, Comment, ContentType } from '@gql/generated/generated';
import { useComments } from '@hooks/useComments';
import { userProfileImage } from '@utils/userProfileImage';
import { TouchableOpacity, View, Text, ActivityIndicator } from 'react-native';

import { styles } from './style';

interface Props {
  comments: Comment[];
  commentedItemType: ContentType;
  commentedItemId: string;
  collectionName: CollectionName;
  totalComments?: number;
}

const Comments = ({ comments, commentedItemType, commentedItemId, collectionName, totalComments = 0 }: Props) => {
  const { currentUser } = useUserContext();

  const optimisticUserDetails = {
    userId: currentUser?.id || '',
    userName: `${currentUser?.accountInfo?.firstName} ${currentUser?.accountInfo?.lastName}` || '',
    userJobTitle: currentUser?.accountInfo?.companyInfo.jobPosition || '',
    userProfilePhotoUrl: currentUser?.profilePhotoUrl || '',
    userAvatarIndex: currentUser?.defaultAvatarIndex || 0,
  };

  const { createComment, fetchMoreComments, loadingMoreComments, submittingComment } = useComments({
    totalComments,
    commentedItemId,
    commentedItemType,
  });

  const handleComment = async (comment: string) => {
    await createComment({
      variables: {
        input: {
          commentedItemId,
          commentedItemType,
          text: comment,
          collectionName,
        },
      },
      optimisticResponse: (vars) => {
        return {
          createComment: {
            __typename: 'Comment',
            id: 'TEMP_COMMENT_ID',
            ...vars.input,
            ...optimisticUserDetails,
            createdAt: Date.now(),
            replies: [],
            likedBy: [],
            rating: null,
          },
        };
      },
    });
  };

  return (
    <View style={styles.wrap}>
      <PostComment
        placeholder="Add a comment.."
        firstName={currentUser?.accountInfo?.firstName}
        lastName={currentUser?.accountInfo?.lastName}
        avatar={userProfileImage(currentUser)}
        buttonTitle="Post"
        onSubmit={handleComment}
        isLoading={submittingComment}
      />
      {currentUser?.id ? <CommentItem comments={comments} /> : null}
      {loadingMoreComments ? <ActivityIndicator size="large" color="#213470" /> : null}
      {comments.length < totalComments && !loadingMoreComments ? (
        <TouchableOpacity
          style={styles.loadMore}
          onPress={() =>
            fetchMoreComments({
              variables: {
                input: {
                  collectionName,
                  commentedItemId,
                  limit: 4,
                  offsetId: [...comments].pop()?.id,
                },
              },
            })
          }
        >
          <Text style={styles.loadMoreText}>Load more comments</Text>
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

export { Comments };
