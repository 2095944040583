import { DocumentContentType, SortBy, UserType } from '@gql/generated/generated';
import { filterValidPartnerTypes } from '@utils/partners';
import { useSearchParams } from 'react-router-native';

export const useQueryFilters = (activeFilters: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const search = searchParams.get('search');
  const page = searchParams.get('page');

  const status = searchParams.get('status');
  const dateFrom = searchParams.get('dateFrom');
  const dateTo = searchParams.get('dateTo');
  const accountStatus = searchParams.get('accountStatus');
  const eventTypes = searchParams.get('eventTypes');
  const contentTypes = searchParams.get('contentTypes');
  const memberSince = searchParams.get('memberSince');
  const pollStatus = searchParams.get('pollStatus');
  const postType = searchParams.get('postType');
  const sortBy = searchParams.get('sortBy');
  const specialOffer = searchParams.get('specialOffer');
  const isHighlighted = searchParams.get('isHighlighted');
  const partnerType = searchParams.get('partnerType');
  const userTypes = searchParams.get('userTypes');
  const categories = searchParams.get('categories');
  const role = searchParams.get('role');

  const setFilterValue = (property: string, value: any) => {
    if (property !== 'page') {
      setSearchParams((params) => {
        params.set('page', '1');
        return params;
      });
    }
    if (value === undefined || value === null || value?.length === 0) {
      setSearchParams((params) => {
        params.delete(property);
        return params;
      });
    } else {
      setSearchParams((params) => {
        params.set(property, value);
        return params;
      });
    }
  };

  return {
    page: Number(page) || 1,
    filters: {
      ...(search && activeFilters.includes('search') && { search }),
      ...(status && activeFilters.includes('status') && { status }),
      ...(dateFrom && activeFilters.includes('dateFrom') && { dateFrom: Number(dateFrom) }),
      ...(dateTo && activeFilters.includes('dateTo') && { dateTo: Number(dateTo) }),
      ...(accountStatus && activeFilters.includes('accountStatus') && { accountStatus }),
      ...(eventTypes &&
        activeFilters.includes('eventTypes') && {
          eventTypes: eventTypes.split(','),
        }),
      ...(contentTypes &&
        activeFilters.includes('contentTypes') && {
          contentTypes: contentTypes.split(',') as DocumentContentType[],
        }),
      ...(memberSince &&
        activeFilters.includes('memberSince') && {
          memberSince: Number(memberSince),
        }),
      ...(pollStatus && activeFilters.includes('pollStatus') && { pollStatus }),
      ...(postType && activeFilters.includes('postType') && { postType }),
      ...(sortBy && activeFilters.includes('sortBy') && { sortBy: sortBy as SortBy }),
      ...(specialOffer &&
        activeFilters.includes('specialOffer') && {
          specialOffer: specialOffer !== 'false',
        }),
      ...(isHighlighted &&
        activeFilters.includes('isHighlighted') && {
          isHighlighted: isHighlighted !== 'false',
        }),
      ...(partnerType &&
        activeFilters.includes('partnerType') && {
          partnerType: filterValidPartnerTypes(partnerType.split(',')),
        }),
      ...(userTypes &&
        activeFilters.includes('userTypes') && {
          userTypes: userTypes.split(',') as UserType[],
        }),
      ...(categories &&
        activeFilters.includes('categories') && {
          categories: categories.split(','),
        }),
      ...(role && activeFilters.includes('role') && { role }),
    },
    setFilterValue,
  };
};
