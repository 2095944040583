import { Row } from '@components/general/row';
import { ChoiceList } from '@shopify/polaris';
import { styles as editorStyles } from '@styles/BackOffice/editor';
import { textStyles } from '@styles/text';
import { useFormikContext } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { Switch, Text, View } from 'react-native';

import { styles } from './style';

interface IFormikHighlightBanner {
  highlights: any[];
}

const FormikHighlightBanner: FC<IFormikHighlightBanner> = ({ highlights }) => {
  const [choices, setChoices] = useState<any[]>([]);

  useEffect(() => {
    const sortedHighlights =
      highlights &&
      [...highlights].sort((a: any, b: any) => {
        return a.highlightedRank - b.highlightedRank;
      });

    const firstHighlighted = sortedHighlights && sortedHighlights.find((event) => event.highlightedRank === 1);
    const secondHighlighted = sortedHighlights && sortedHighlights.find((event) => event.highlightedRank === 2);
    const thirdHighlighted = sortedHighlights && sortedHighlights.find((event) => event.highlightedRank === 3);
    const fourthHighlighted = sortedHighlights && sortedHighlights.find((event) => event.highlightedRank === 4);

    setChoices([
      {
        label: firstHighlighted?.title || '(Empty)',
        value: '1',
        disabled: !!firstHighlighted?.title,
      },
      {
        label: secondHighlighted?.title || '(Empty)',
        value: '2',
        disabled: !!secondHighlighted?.title,
      },
      {
        label: thirdHighlighted?.title || '(Empty)',
        value: '3',
        disabled: !!thirdHighlighted?.title,
      },
      {
        label: fourthHighlighted?.title || '(Empty)',
        value: '4',
        disabled: !!fourthHighlighted?.title,
      },
    ]);
  }, [highlights]);

  const {
    setFieldValue,
    values: { isHighlighted, highlightedRank, title },
  } = useFormikContext<any>();

  const handleChangeHighlightedOption = (val: string[]) => {
    const valueAsNumber = Number(val[0]);
    setFieldValue('highlightedRank', valueAsNumber);
    const swopIndex = choices.findIndex((choice) => choice.label === title);
    if (choices[swopIndex]) {
      choices[swopIndex].label = '(Empty)';
      choices[swopIndex].disabled = false;
    }
    if (choices[valueAsNumber - 1]) {
      choices[valueAsNumber - 1].label = title;
      choices[valueAsNumber - 1].disabled = true;
    }

    setChoices(choices);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Banner Highlights</Text>

      <Text style={{ marginBottom: 16 }}>
        To show this event as a highlight event. Turn on the toggle and select the order to display in the slideshow.
      </Text>

      <View style={styles.highlightedEventsContainer}>
        <Row style={styles.topRow}>
          <Text style={textStyles.subduedTextSmall}>SHOW IN HIGHLIGHTS</Text>
          <Switch
            style={editorStyles.switchBulk}
            trackColor={{
              false: '#8B9197',
              true: '#2C6ECB',
            }}
            //@ts-expect-error type
            activeThumbColor="#FFFFFF"
            onValueChange={() => {
              if (isHighlighted) {
                setFieldValue('isHighlighted', false);
                setFieldValue('highlightedRank', null);
              } else setFieldValue('isHighlighted', true);
            }}
            value={isHighlighted}
          />
        </Row>
        {isHighlighted ? (
          <ChoiceList
            title="High"
            choices={choices}
            titleHidden
            selected={highlightedRank ? [highlightedRank.toString()] : []}
            onChange={handleChangeHighlightedOption}
          />
        ) : null}
      </View>
    </View>
  );
};

export { FormikHighlightBanner };
