import { SmallCloseIcon } from '@components/general/icons/SmallCloseIcon';
import { selectedUsersAssignModalContext } from '@context/SelectedUsersAssignModalContext';
import { useGetUsersWithContentAccessQuery } from '@gql/generated/generated';
import { useContext, useMemo, useState } from 'react';
import { FlatList, Pressable, Text, TextInput, TouchableOpacity, View } from 'react-native';

import { styles } from './styles';

const ModalDropx = ({ categories, contentType }: { categories?: string[]; contentType?: string | null }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const { selectedUsers, handleAddUser, handleRemoveUser } = useContext(selectedUsersAssignModalContext);

  const contentAccessType = useMemo(() => {
    if (contentType === 'Content') return 'content';
    if (contentType === 'Event') return 'events';
    if (contentType === 'Training') return 'training';
  }, [contentType]);

  const { data: users } = useGetUsersWithContentAccessQuery({
    variables: {
      input: {
        page: 1,
        limit: 500, // TODO this number should not be capped
        contentAccess: {
          [contentAccessType as string]: categories,
        },
      },
    },
    skip: !contentAccessType,
    fetchPolicy: 'cache-and-network',
  });

  const companyUsers = users?.employerGetUsers?.users
    .filter((user) => user.id)
    .map((user) => {
      return {
        id: user.id!,
        name:
          user.accountInfo?.firstName && user.accountInfo?.lastName
            ? user.accountInfo?.firstName + ' ' + user.accountInfo?.lastName
            : user.accountInfo?.firstName || user.accountInfo?.lastName || '',
        hasAccess: user.hasAccess,
      };
    });

  const filteredItems = companyUsers?.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 100);
  };

  return (
    <View style={styles.lowerViewInputWrap}>
      <Text style={styles.lowerViewInputLabel}>Assign to:</Text>
      <TextInput
        value={searchTerm}
        onChangeText={(text) => setSearchTerm(text)}
        placeholder="Type the first 3 letters"
        onFocus={() => setIsFocused(true)}
        style={styles.lowerViewInput}
      />
      {isFocused ? (
        <FlatList
          data={filteredItems}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => (
            <TouchableOpacity
              style={{ padding: 8 }}
              onPress={() => {
                handleAddUser(item);
                handleBlur();
              }}
              disabled={!item.hasAccess}
            >
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Text style={{ color: !item.hasAccess ? 'gray' : 'auto' }}>{item.name}</Text>
                {!item.hasAccess ? <Text style={{ color: 'gray' }}>No access</Text> : null}
              </View>
            </TouchableOpacity>
          )}
          style={[styles.lowerViewDropboxMenu, { maxHeight: 150 }]}
        />
      ) : selectedUsers.length > 0 ? (
        <View style={styles.lowerViewDropboxMenuSelection}>
          {selectedUsers.map(({ id, name }) => {
            return (
              <View key={id} style={styles.lowerViewDropboxMenuSelectionTextWrap}>
                <Text style={styles.lowerViewDropboxMenuSelectionText}>{name}</Text>
                <Pressable onPress={() => handleRemoveUser(id)}>
                  <SmallCloseIcon />
                </Pressable>
              </View>
            );
          })}
        </View>
      ) : null}
    </View>
  );
};

export { ModalDropx };
