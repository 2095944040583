import { Col } from '@components/general/col';
import { Text, TouchableOpacity, View } from 'react-native';

import { styles } from './style';

interface TabItemProps {
  title: string;
  notification?: number;
  onPress?: () => void;
  active?: boolean;
}

const Tab = ({ title, notification, active, onPress }: TabItemProps) => {
  return (
    <Col>
      <TouchableOpacity style={styles.tabItem} onPress={onPress}>
        {title !== 'All' ? (
          <View style={styles.tabTitleWrap}>
            <Text style={styles.tabItemTitle}>{title}</Text>
            {notification ? (
              <View style={styles.tabItemNotificationWrap}>
                <Text style={styles.tabItemNotificationText}>{notification}</Text>
              </View>
            ) : null}
          </View>
        ) : (
          <>
            <Text style={styles.tabItemTitle}>{title}</Text>
          </>
        )}
      </TouchableOpacity>
      {active ? <View style={styles.tabItemActive} /> : null}
    </Col>
  );
};

export { Tab };
