import { ListItem, ListItemProps } from '@components/account/list-item';
import { TitleBox } from '@components/account/title-box';
import { ContentBox } from '@components/general/layouts/content-box';
import { View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { styles } from './style';

const StyledListItem = ({ onPress, title, subtitle, ...rest }: ListItemProps) => {
  return (
    <ListItem
      title={title}
      subtitle={subtitle}
      onPress={onPress}
      titleStyle={styles.title}
      subtitleStyle={styles.subtitle}
      containerStyles={{
        paddingBottom: 0,
      }}
      {...rest}
    />
  );
};

const Help = () => {
  const navigate = useNavigate();
  return (
    <View style={styles.wrap}>
      <ContentBox>
        <TitleBox title="Help" />
        <StyledListItem
          title="Requests"
          subtitle="View your previous requests or make new requests for content and events."
          onPress={() => navigate('request')}
        />
        <StyledListItem title="Contact our support team" onPress={() => navigate('contact-support')} containerStyles={{ paddingBottom: 20 }} />
        {/* <StyledListItem
          title="New to TRN guide"
          subtitle="Watch our tutorials to show you how to work on TRN platform set by step."
          videoSrc="https://www.youtube.com/embed/O5O3yK8DJCc?si=GPtWZ-6FpbLordnt"
        />
        <StyledListItem
          title="Account"
          subtitle="Learn how to manage your account settings."
          videoSrc="https://www.youtube.com/embed/O5O3yK8DJCc?si=GPtWZ-6FpbLordnt"
        />
        <StyledListItem
          title="Plan & Payment"
          subtitle="Learn how to manage and upgrade your plan."
          videoSrc="https://www.youtube.com/embed/O5O3yK8DJCc?si=GPtWZ-6FpbLordnt"
        />
        <StyledListItem
          title="Roadmap"
          subtitle="Roadmap guide, how to improve your roadmap goal."
          videoSrc="https://www.youtube.com/embed/O5O3yK8DJCc?si=GPtWZ-6FpbLordnt"
        /> */}
      </ContentBox>
    </View>
  );
};

export { Help };
