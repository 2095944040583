import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
    height: '100%',
    backgroundColor: '#FFFFFF',
  },
  titleWrap: {
    paddingTop: 16,
    paddingLeft: 20,
    marginBottom: 16,
  },
  title: {
    color: '#0D1738',
    fontSize: 16,
    fontFamily: 'OpenSans_600SemiBold',
  },
  subtitle: {
    color: '#6D7175',
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
    marginTop: 16,
  },
  tabBorder: {
    width: '100%',
  },
  tabContent: {
    padding: 16,
  },
  recentItemWrap: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  recentItemLeft: {
    width: '90%',
  },
  recentItemFull: {
    width: '100%',
  },
  cancelTouch: {
    padding: 10,
  },
  modalInner: {
    backgroundColor: 'rgba(13, 23, 56, 0.87)',
    position: 'fixed',
    top: 53,
    right: 0,
    left: 0,
    bottom: 0,
  },
  modalHide: {
    display: 'none',
  },
  modalCenter: {
    alignItems: 'center',
  },
  searchBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    maxWidth: 393,
  },
});
