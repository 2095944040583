import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 20,
  },
  headLeft: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headLogo: {
    height: 56,
    width: 56,
    borderRadius: 4,
    marginRight: 8,
  },
  postName: {
    fontSize: 14,
    color: '#000000',
    fontFamily: 'OpenSans_600SemiBold',
  },
  postDate: {
    fontSize: 12,
    color: '#0D1738',
    fontFamily: 'OpenSans_400Regular',
  },
  postType: {
    fontSize: 12,
    color: '#6D7175',
    fontFamily: 'OpenSans_400Regular',
  },
});
