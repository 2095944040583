import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrapper: {
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 25,
    paddingTop: 8,
  },
});
