import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 20,
    width: '100%',
  },
  title: {
    fontSize: 26,
    fontFamily: 'OpenSans_600SemiBold',
    marginBottom: 20,
  },
  instructions: {
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
    marginBottom: 12,
    paddingHorizontal: 12,
  },
  subduedTextSmall: {
    fontFamily: 'OpenSans_400Regular',
    color: '#6D7175',
    fontSize: 12,
    textAlign: 'justify',
    marginTop: 8,
    paddingHorizontal: 12,
  },
});
