import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    height: '100%',
    justifyContent: 'space-between',
    paddingBottom: 40,
  },
  buttonWrap: {
    paddingLeft: 12,
    paddingRight: 12,
  },
});
