import { User } from '@gql/generated/generated';
import { styles } from '@pages/home/style';
import { PageVariant } from '@utils/models';
import { View } from 'react-native';

import { SavedOrAssignedContent } from '../saved/Saved';

interface Props {
  variant: PageVariant.assignment | PageVariant.member;
  member?: User;
  refetchMember?: () => Promise<any>;
}

const Assignment = ({ variant = PageVariant.assignment, member, refetchMember }: Props) => {
  return (
    <>
      <View
        style={[
          ...(variant !== PageVariant.member
            ? [
                styles.container,
                {
                  paddingTop: 0,
                  marginRight: 20,
                  width: 956,
                },
              ]
            : []),
        ]}
      >
        <SavedOrAssignedContent title="Assignments" variant={variant} member={member} refetchMember={refetchMember} />
      </View>
    </>
  );
};

export { Assignment };
