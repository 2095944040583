import { DeleteAccountModal } from '@components/account/delete-account-modal';
import { DeletedAccountModal } from '@components/account/deleted-account-modal';
import { ButtonGeneral } from '@components/general/button-general';
import { Input } from '@components/general/form/input';
import { InputError } from '@components/general/form/input-error';
import { SelectOption } from '@components/general/form/select-option';
import { TextArea } from '@components/general/form/text-area';
import { GreenCircleCheckIcon } from '@components/general/icons/event-icons';
import { IndexPageHeader } from '@components/general/index-page-header';
import { Wrapper } from '@components/general/layouts/wrapper/Wrapper';
import { ListModal } from '@components/general/modals/list-modal';
import { useAuth } from '@hooks/useAuth';
import { Formik, FormikValues } from 'formik';
import React, { useState } from 'react';
import { Text, View } from 'react-native';
import * as Yup from 'yup';

import { styles } from './style';

const categoryOptions = [
  { label: 'Back Office', value: 'Back Office' },
  { label: 'End User', value: 'End User' },
  { label: 'Other', value: 'Other' },
];

const HelpNative = () => {
  const { navigate, handleSignOut } = useAuth();
  const [isSubscribed] = useState(true);
  const [isCompleteModalVisible, setIsCompleteModalVisible] = useState(false);
  const [isDeleteAccountModalVisible, setIsDeleteAccountModalVisible] = useState(false);
  const [isDeletedAccountModalVisible, setIsDeletedAccountModalVisible] = useState(false);

  const onSubmit = (values: FormikValues) => {
    setIsCompleteModalVisible(true);
  };

  const backToProfile = () => {
    setIsCompleteModalVisible(false);
    navigate(-1);
  };

  const handleAccountDeleted = () => {
    setIsDeleteAccountModalVisible(false);
    setIsDeletedAccountModalVisible(true);
  };

  const backToHome = () => {
    handleSignOut();
    setIsDeletedAccountModalVisible(false);
  };

  return (
    <Wrapper>
      <IndexPageHeader title="Help" onPressBack={() => navigate(-1)} />
      {isSubscribed ? (
        <View style={styles.wrapInnerNative}>
          <Text style={styles.titleText}>Contact our support team</Text>
          <Text style={styles.description}>
            Contact our Member Support Team using the form below. Our experts will get back to you within 48 hrs.
          </Text>
          <Formik
            initialValues={{
              category: '',
              subject: '',
              description: '',
            }}
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({
              category: Yup.string().required('This field is required'),
              subject: Yup.string().required('This field is required'),
            })}
          >
            {({ values, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
              <>
                <View style={styles.inputWrap}>
                  <SelectOption
                    label="Category"
                    placeholder="Please select"
                    value={values.category}
                    options={categoryOptions}
                    onChange={(value) => setFieldValue('category', value)}
                  />
                  {touched.category && errors.category && <InputError error={errors.category} />}
                </View>
                <View style={styles.inputWrap}>
                  <Input
                    label="Subject"
                    value={values.subject}
                    onChangeText={handleChange('subject')}
                    error={touched.subject && errors.subject ? errors.subject : undefined}
                  />
                </View>
                <View style={styles.inputWrap}>
                  <TextArea
                    label="Issue"
                    placeholder="Please describe your issue"
                    value={values.description}
                    onChangeText={handleChange('description')}
                    error={touched.description && errors.description ? errors.description : undefined}
                  />
                </View>
                <View style={styles.buttonWrap}>
                  <ButtonGeneral onPress={handleSubmit} label="Submit" />
                </View>
              </>
            )}
          </Formik>
        </View>
      ) : (
        <View style={styles.wrapInnerNative}>
          <Text style={styles.titleText}>Contact our support team</Text>
          <Text style={styles.description}>Become a full member of The Recruitment Network Club and get access to our full support.</Text>
          <Text style={styles.description}>For any questions please contact enquiries@thereceruitmentnetwork.com Or call us at at 800.232.8284</Text>
        </View>
      )}
      <View style={styles.deleteAccountWrap}>
        <ButtonGeneral label="DELETE ACCOUNT" style={styles.deleteButton} onPress={() => setIsDeleteAccountModalVisible(true)} />
      </View>
      <DeleteAccountModal
        isVisible={isDeleteAccountModalVisible}
        onClose={() => setIsDeleteAccountModalVisible(false)}
        onDone={handleAccountDeleted}
      />
      <DeletedAccountModal isVisible={isDeletedAccountModalVisible} onClose={backToHome} />
      <ListModal isVisible={isCompleteModalVisible} onClose={() => setIsCompleteModalVisible(false)}>
        <View style={styles.successTitleWrap}>
          <GreenCircleCheckIcon />
          <Text style={styles.successTitle}>The form has been submitted!</Text>
        </View>
        <Text style={styles.successDescription}>TRN Team will look into your request and get back to you within 48 working hours.</Text>
        <ButtonGeneral onPress={backToProfile} label="Back to profile" />
      </ListModal>
    </Wrapper>
  );
};

export { HelpNative };
