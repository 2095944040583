import { Post } from '@components/home/post/post';
import { useUserContext } from '@context/UserContext';
import { FeedItem, PollStatus } from '@gql/generated/generated';
import dayjs from 'dayjs';
import React from 'react';
import { ActivityIndicator, FlatList, KeyboardAvoidingView, NativeScrollEvent, NativeSyntheticEvent, Platform, View } from 'react-native';

import { styles } from './style';
import { pluralize } from '../../../utils/misc';
import { Poll } from '../poll';

interface FeedProps {
  feedItems: FeedItem[];
  loadingMoreFeedItems: boolean;
  onFlatListScroll: ((event: NativeSyntheticEvent<NativeScrollEvent>) => void) | undefined;
}

const Wrap = ({ children }: { children: React.ReactNode }) => {
  if (Platform.OS === 'web') {
    return <>{children}</>;
  } else {
    return (
      <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={styles.container}>
        {children}
      </KeyboardAvoidingView>
    );
  }
};

export const Feed = ({ feedItems, loadingMoreFeedItems, onFlatListScroll }: FeedProps) => {
  const { currentUser } = useUserContext();

  function getTimeRemainingUntilPollCloses(closeDateAsDate: Date): string {
    const now = new Date();
    const timeDifference = closeDateAsDate.getTime() - now.getTime();

    if (timeDifference <= 0) {
      return 'Poll closed';
    }

    const daysRemaining = Math.round(timeDifference / (1000 * 60 * 60 * 24));

    if (daysRemaining <= 7) {
      if (daysRemaining === 7) {
        return '1 week left';
      }
      return `${daysRemaining} ${pluralize(daysRemaining, 'day')} left`;
    }

    const weeksRemaining = Math.round(daysRemaining / 7);
    return `${weeksRemaining} weeks left`;
  }

  const renderPosts = (feedItem: FeedItem) => {
    if (feedItem.__typename === 'Poll') {
      const {
        id,
        publishedAt,
        description,
        pollQuestion,
        voteCount,
        pollOptions,
        canCurrentUserVote,
        pollStatus,
        closeDate,
        averageStars,
        comments,
        numRatings,
        userRating,
        commentCount,
      } = feedItem;

      const pollDateAsDate = publishedAt ? new Date(publishedAt) : new Date();
      const closeDateAsDate = new Date(closeDate);

      const timeLeft = getTimeRemainingUntilPollCloses(closeDateAsDate);

      return (
        <View style={styles.postItem} key={id}>
          <Poll
            id={id}
            authorName="The Recruitment Network"
            date={dayjs(pollDateAsDate).format('DD MMM YYYY')}
            closeDate={dayjs(closeDateAsDate).format('DD MMM YYYY')}
            description={description}
            pollQuestion={pollQuestion}
            voteCount={voteCount}
            pollOptions={pollOptions}
            canCurrentUserVote={canCurrentUserVote}
            hasPollClosed={pollStatus === PollStatus.CLOSED}
            comments={comments || []}
            timeLeft={timeLeft}
            numRatings={numRatings}
            userRating={userRating}
            rating={averageStars}
            totalComments={commentCount}
            isCommented={comments.some((el) => el.userId === currentUser?.id)}
            user={currentUser}
          />
        </View>
      );
    } else if (feedItem.__typename === 'Post') {
      const { id, content, type, publishedAt, coverImage, comments, numRatings, shareCount, averageStars, userRating, commentCount } = feedItem;

      const dateAsDate = publishedAt ? new Date(publishedAt) : new Date();

      return (
        <View style={styles.postItem} key={id}>
          <Post
            id={id}
            name="The Recruitment Network"
            date={dayjs(dateAsDate).format('DD MMM YYYY')}
            postType={type}
            caption={content as string}
            image={coverImage}
            totalRatings={numRatings}
            postRating={averageStars}
            totalShares={shareCount}
            comments={comments || []}
            totalComments={commentCount}
            userRating={userRating}
            isCommented={comments.some((el) => el.userId === currentUser?.id)}
          />
        </View>
      );
    }
  };

  const renderItem = ({ item }: { item: FeedItem }) => <>{renderPosts(item)}</>;

  return (
    <Wrap>
      <View style={styles.wrap}>
        {Platform.OS !== 'web' ? (
          <FlatList
            onScroll={onFlatListScroll}
            data={feedItems || []}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
            scrollEventThrottle={1}
            contentContainerStyle={styles.flatList}
            showsVerticalScrollIndicator={false}
          />
        ) : feedItems && feedItems.length ? (
          feedItems.map((feedItem) => renderPosts(feedItem))
        ) : null}
        {loadingMoreFeedItems ? (
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <ActivityIndicator size="large" color="#213470" />
          </View>
        ) : null}
      </View>
    </Wrap>
  );
};
