import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  contents: {
    maxWidth: 587,
    padding: 20,
    paddingBottom: 0,
    gap: 10,
    marginBottom: 10,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  ddGuarantee: {
    width: '100%',
    height: 200,
    resizeMode: 'contain',
  },
  ddGuaranteeHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingBottom: 8,
  },
  directDebitLogo: {
    width: 131,
    height: 41.67,
  },
});
