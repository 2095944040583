import { FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon } from '@components/general/icons/social-icons';
import { ButtonLayout } from '@pages/account/profile/button-layout/ButtonLayout';
import { InputFormik } from '@pages/account/profile/form-elements/input-formik/InputFormik';
import { InputFormikContainer } from '@pages/account/profile/form-elements/input-formik/InputFormikContainer';
import { useFormikContext } from 'formik';
import React from 'react';
import { View } from 'react-native';

interface FormProps {
  closeEditMode: () => void;
}

const MediaForm = ({ closeEditMode }: FormProps) => {
  const { handleSubmit } = useFormikContext();

  const submitCloseHandler = () => {
    handleSubmit();
    closeEditMode();
  };

  return (
    <>
      <InputFormikContainer label="Social Media">
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
          }}
        >
          <InputFormik placeholder="Facebook" formikKey="facebook" needIcon Icon={<FacebookIcon />} />
          <InputFormik placeholder="Instagram" formikKey="instagram" needIcon Icon={<InstagramIcon />} />
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
          }}
        >
          <InputFormik placeholder="Twitter" formikKey="twitter" needIcon Icon={<TwitterIcon />} />
          <InputFormik placeholder="Linkedin" formikKey="linkedin" needIcon Icon={<LinkedInIcon />} />
        </View>
      </InputFormikContainer>
      <ButtonLayout closeEditMode={closeEditMode} submitCloseHander={submitCloseHandler} />
    </>
  );
};

export { MediaForm };
