import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  dropDownContainer: {
    flex: 1,
    borderWidth: 1,
    borderColor: '#BABFC3',
    borderRadius: 4,
    marginRight: 8,
    marginTop: 8,
    marginBottom: 16,
  },
  dropDownPlaceholder: {
    color: '#0D1738',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
  },
  dropDownItem: {
    flex: 1,
    paddingHorizontal: 8,
  },
  error: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    marginVertical: 4,
    color: 'red',
  },
});
