import { SENTRY_DSN, POSTHOG_PROXY_HOST } from '@env';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [Sentry.replayIntegration()],

  // Capture Replay for 10% of all sessions,
  // replaysSessionSampleRate: 0.1,

  // Capture Replay 100% of sessions with an error
  replaysOnErrorSampleRate: 1.0,

  ignoreErrors: [POSTHOG_PROXY_HOST],
  denyUrls: [POSTHOG_PROXY_HOST],

  // beforeSendTransaction(event) {
  //   console.log('>>>> beforeSendTransaction', event);
  //   return event;
  // },

  // beforeSend(event) {
  //   console.log('>>>> beforeSend', event);
  //   return event;
  // },

  beforeBreadcrumb(breadcrumb) {
    if (breadcrumb.data?.url?.startsWith?.(POSTHOG_PROXY_HOST)) {
      // console.log('>>>> excluding breadcrumb', breadcrumb.data.url);
      return null;
    }
    return breadcrumb;
  },
});
