import { Unit, useGetActivityReportQuery, useGetContentHubStatsQuery, useGetEngagementStatsQuery } from '@gql/generated/generated';
import { useMemo, useState } from 'react';

const useActivityReport = () => {
  const [type, setType] = useState<'training' | 'content' | 'events'>('content');
  const [unit, setUnit] = useState<Unit>(Unit.DAY);

  const { data: activityData, refetch } = useGetActivityReportQuery({
    variables: {
      unit: Unit.DAY,
    },
  });

  const refetchActivity = (unit: Unit) => {
    refetch({
      unit,
    });
  };

  const activityReport = activityData?.employerGetMemberStats.trnWorld?.activityReport;

  const chartData = useMemo(() => {
    let labels = activityReport?.dates || [];
    if (activityReport?.dates && activityReport?.dates.length > 7 && unit !== Unit.YEAR && labels?.length) {
      const labelsClone = [...labels];
      labels = labelsClone.map((label, i) => {
        if (i % 3 === 0) {
          return label;
        } else return '';
      });
    }
    return {
      labels,
      datasets: [
        {
          data: activityReport?.[type] || [0],
          color: () => '#1653CE',
          strokeWidth: 2,
        },
      ],
    };
  }, [activityReport, type]);

  return {
    activityReport,
    activityData,
    chartData,
    unit,
    setUnit,
    setType,
    type,
    refetchActivity,
  };
};

const useContentHub = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [selectedDays, setSelectedDays] = useState('30');

  const { data: contentHub, refetch: refetchContentHub } = useGetContentHubStatsQuery({
    variables: {
      numDays: Number(selectedDays),
    },
  });

  const pageStats = contentHub?.employerGetMemberStats.trnWorld?.pageStats;

  return {
    contentHub,
    pageStats,
    activeTab,
    setActiveTab,
    refetchContentHub,
    selectedDays,
    setSelectedDays,
  };
};

export const useTRNWorld = () => {
  const { activityReport, activityData, chartData, unit, setUnit, setType, type, refetchActivity } = useActivityReport();

  const { pageStats, activeTab, setActiveTab, selectedDays, refetchContentHub, setSelectedDays } = useContentHub();

  const { data: engagementData } = useGetEngagementStatsQuery();

  const dataForExport = useMemo(() => {
    const content = activityReport?.content || [];
    const training = activityReport?.training || [];
    const events = activityReport?.events || [];

    return [
      {
        columns: [
          { label: 'Date', value: 'date' },
          { label: 'Content (avg. mins/member)', value: 'content' },
          { label: 'Training (avg. mins/member)', value: 'training' },
          { label: 'Events (avg. mins/member)', value: 'events' },
        ],
        data: activityReport?.dates.map((date, i) => {
          return {
            date,
            content: content[i],
            training: training[i],
            events: events[i],
          };
        }),
        name: 'Activity report',
      },
      {
        columns: [
          { label: 'Job role', value: 'role' },
          { label: 'This month (avg. hours/member)', value: 'hours' },
          { label: 'Last month (avg. hours/member)', value: 'last' },
          { label: 'Num members', value: 'num' },
        ],
        data: engagementData?.employerGetMemberStats.trnWorld?.engagementByRole.map((role) => {
          return {
            role: role.jobRole,
            hours: Math.round((role.averageTimePerMemberMinsMTD / 60) * 2) / 2,
            last: Math.round((role.averageTimePerMemberMinsLastMonth / 60) * 2) / 2,
            num: role.users?.length,
          };
        }),
        name: 'Engagement report',
      },
      {
        columns: [
          { label: 'Title', value: 'title' },
          { label: 'Avg. time (mins/member)', value: 'time' },
          { label: 'Total views', value: 'views' },
          { label: 'Unique views', value: 'views' },
          { label: 'Type', value: 'type' },
          { label: 'Category', value: 'category' },
        ],
        data: pageStats?.mostTime?.map((item) => {
          return {
            title: item.title,
            time: item.averageTimePerMemberMins,
            views: item.totalPageViews,
            unique: item.users?.length,
            type: item.type,
            category: item.categories?.join(','),
          };
        }),
        name: 'Content hub (most time)',
      },
      {
        columns: [
          { label: 'Title', value: 'title' },
          { label: 'Avg. time (mins/member)', value: 'time' },
          { label: 'Total views', value: 'views' },
          { label: 'Unique views', value: 'views' },
          { label: 'Type', value: 'type' },
          { label: 'Category', value: 'category' },
        ],
        data: pageStats?.mostViews?.map((item) => {
          return {
            title: item.title,
            time: item.averageTimePerMemberMins,
            views: item.totalPageViews,
            unique: item.users?.length,
            type: item.type,
            category: item.categories?.join(','),
          };
        }),
        name: 'Content hub (most views)',
      },
    ];
  }, [activityData, pageStats, engagementData]);

  return {
    chartData,
    unit,
    setUnit,
    setType,
    type,
    refetchActivity,
    dataForExport,
    pageStats,
    activeTab,
    setActiveTab,
    refetchContentHub,
    selectedDays,
    setSelectedDays,
    engagementData,
  };
};
