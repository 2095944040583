import { GridPage } from '@components/back-office/grid/GridPage';
import { useQueryFilters } from '@components/back-office/grid/useGridFilters';
import { APP_URL } from '@env';
import { ContentType, Status, useAdminGetAllEventsQuery, useDeleteEventMutation, useUpsertEventMutation } from '@gql/generated/generated';
import { IndexTableHeading } from '@shopify/polaris/build/ts/latest/src/components/IndexTable';
import { NonEmptyArray } from '@shopify/polaris/build/ts/latest/src/types';
import { textStyles } from '@styles/text';
import { getUniqueCategories, truncateString } from '@utils/misc';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Linking, Text, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { DeleteConfirmationModal } from '../../../components/back-office/editor/delete-confirmation-modal';

const activeFilters = [
  'search',
  'status',
  'dateFrom',
  'dateTo',
  'categories',
  'userTypes',
  'eventTypes',
  // "isHighlighted",
];

export const EventsBackOffice = () => {
  const navigation = useNavigate();
  const [eventIdToBeDeleted, setEventIdToBeDeleted] = useState<string | undefined>();

  const { page, filters } = useQueryFilters(activeFilters);

  const [deleteEvent] = useDeleteEventMutation({
    update: (store, _result, options) => {
      const id = options?.variables?.id;
      if (id) {
        const normalizedId = store.identify({
          id,
          __typename: `${ContentType.EVENT}`,
        });
        store.evict({ id: normalizedId });
        store.gc();
      }
    },
  });
  const [archiveEvent] = useUpsertEventMutation({
    update: (store, _result, options) => {
      const itemId = options?.variables?.input?.id;
      if (itemId) {
        store.modify({
          id: `${ContentType.EVENT}:${itemId}`,
          fields: {
            status() {
              return Status.ARCHIVE;
            },
          },
        });
      }
    },
  });

  const { data, loading, refetch } = useAdminGetAllEventsQuery({
    variables: {
      page,
      limit: 10,
      filters,
    },
  });

  const { events, hasNextPage, total } = data?.adminGetAllEvents || {};

  const tableHeadings: NonEmptyArray<IndexTableHeading> = [
    { title: 'Title' },
    { title: 'Speaker' },
    { title: 'Category' },
    { title: 'Event Type' },
    { title: 'Last modified' },
    { title: 'User Type' },
  ];

  const rows = events?.length
    ? events.map((event) => {
        return {
          id: event.id,
          title: (
            <View style={{ maxWidth: 350, paddingVertical: '8px' }}>
              <Text style={textStyles.semiBoldSmall}>{event.title ?? '-'}</Text>
            </View>
          ),
          speaker: event.speakers ? event.speakers.map((speaker) => speaker.name).join(', ') : '',
          categories: truncateString(getUniqueCategories(event.categories).join(', '), 20),
          eventType: event.type,
          updatedAt: dayjs(event.updatedAt ?? event.createdAt).format('DD/MM/YYYY'),
          userTypes: event.userType?.join(', '),
        };
      })
    : [];

  const handleMenuClick = async (itemId: string, optionID: string) => {
    switch (optionID) {
      case 'View':
        return Linking.openURL(`${APP_URL}/events/${itemId}` as string);
      case 'Edit':
        return navigation(`/events-editor/${itemId}`);
      case 'Archive':
        await archiveEvent({
          variables: {
            input: {
              id: itemId,
              status: Status.ARCHIVE,
            },
          },
        });
        break;
      case 'Delete':
        setEventIdToBeDeleted(itemId);
        break;
      default:
        break;
    }
  };

  const handlePressDelete = async () => {
    if (eventIdToBeDeleted) {
      await deleteEvent({ variables: { id: eventIdToBeDeleted } });
    }
  };

  return (
    <>
      <DeleteConfirmationModal
        title="Are you sure you want to delete this event?"
        onClose={() => setEventIdToBeDeleted(undefined)}
        onDeleteConfirm={async () => {
          await handlePressDelete();
          setEventIdToBeDeleted(undefined);
        }}
        visible={!!eventIdToBeDeleted}
      />
      <GridPage
        title="Events"
        menuItems={[
          { id: 'View', content: 'View' },
          { id: 'Edit', content: 'Edit' },
          { id: 'Archive', content: 'Archive' },
          { id: 'Delete', content: 'Delete' },
        ]}
        newLink={{
          url: '/events-editor',
          title: 'Add New Event',
          label: 'Add Event',
        }}
        activeFilters={activeFilters}
        rows={rows}
        loading={loading}
        tableHeadings={tableHeadings}
        onBulkAction={() => refetch()}
        onMenuItem={handleMenuClick}
        pagination={{
          perPage: 10,
          page,
          totalItems: total,
          hasNextPage,
        }}
      />
    </>
  );
};
