import { GenericTable, GenericTableRowItem } from '@components/back-office/tables/generic-table';
import { ActivityIndicatorTRN } from '@components/general/activity-indicator/ActivityIndicator';
import { ContentBox } from '@components/general/layouts/content-box';
import { TabTitle } from '@components/general/tab-title';
import { ScoreAssociationsContext } from '@context/ScoreAssociations';
import { Select } from '@shopify/polaris';
import { IndexTableHeading } from '@shopify/polaris/build/ts/latest/src/components/IndexTable';
import { NonEmptyArray } from '@shopify/polaris/build/ts/latest/src/types';
import { useContext, useMemo } from 'react';
import { ActivityIndicator, ScrollView, Text, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { styles } from './style';

type Option = {
  label: string;
  value: string;
};

function Table({ rows, handleMenuClick }: { rows: GenericTableRowItem[]; handleMenuClick?: (id: string, optionId: string) => void }) {
  const headings: NonEmptyArray<IndexTableHeading> = [
    { title: 'Score' },
    { title: 'No. of Content' },
    { title: 'No. of Training' },
    { title: 'Last Update' },
    { title: '' },
  ];

  const customMenuItems = [{ id: 'Edit', content: 'Edit' }];

  return (
    <GenericTable
      headings={headings}
      rows={rows}
      resourceName={{ singular: 'score', plural: 'scores' }}
      customMenuItems={customMenuItems}
      handleMenuClick={handleMenuClick}
      withCheckbox={false}
    />
  );
}

export function ScoreAssociations() {
  const navigate = useNavigate();

  const scoreAssociationContext = useContext(ScoreAssociationsContext);
  if (!scoreAssociationContext) throw new Error('Score Association Provider not declared above');
  const {
    roadmapPulseScoreAssns,
    getRoadmapPulseScoreAssnsLoading,
    isCategoryValid,
    selectedCategoryName,
    selectedSubcategoryName,
    setSubCategoryName,
    isSubcategoryValid,
    getDefaultSubcategory,
    selectedScoreAssnSubcategory,
  } = scoreAssociationContext;

  const categoryOptions = useMemo(() => {
    if (roadmapPulseScoreAssns.length) {
      return roadmapPulseScoreAssns.map((c) => ({ label: c.name, value: c.name }));
    }
    return [];
  }, [roadmapPulseScoreAssns.length]);

  const subcategoryOptions = useMemo(() => {
    if (roadmapPulseScoreAssns.length && selectedCategoryName) {
      const category = roadmapPulseScoreAssns.find((c) => c.name === selectedCategoryName);
      if (!category) return [];
      return category.subCategories.map((s) => ({
        label: s.name,
        value: s.name,
      }));
    }
    return [];
  }, [roadmapPulseScoreAssns.length, selectedCategoryName]);

  async function onChangeCategory(categoryName: string) {
    if (!isCategoryValid(categoryName)) return;
    const subcategory = getDefaultSubcategory(null, categoryName);
    setSubCategoryName(null, categoryName, subcategory?.name ?? '');
  }

  async function onChangeSubcategory(subcategoryName: string) {
    if (!isSubcategoryValid(selectedCategoryName ?? '', subcategoryName)) {
      return;
    }
    setSubCategoryName(null, selectedCategoryName ?? '', subcategoryName);
  }

  function navigateToEditPage(id: string, optionId: string) {
    if (optionId === 'Edit') {
      navigate(`edit/${selectedCategoryName}/${selectedSubcategoryName}/${id}`);
    }
  }

  if (getRoadmapPulseScoreAssnsLoading) {
    return <ActivityIndicatorTRN />;
  }

  return (
    <>
      <View style={styles.titleWrap}>
        <Text style={styles.backOfficeHeaderTitle}>Score Recommendations</Text>
      </View>
      <ContentBox>
        <View style={{ padding: 20 }}>
          <View style={{ width: 'fit-content' }}>
            <Select label="" value={selectedCategoryName ?? ''} onChange={onChangeCategory} options={categoryOptions as Option[]} />
          </View>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            {subcategoryOptions.map(({ label, value }) => (
              <TabTitle
                key={value}
                active={value === selectedSubcategoryName}
                title={label ?? ''}
                onPress={() => onChangeSubcategory(value as string)}
              />
            ))}
          </ScrollView>
          <View style={{ marginTop: 40 }}>
            <Table
              handleMenuClick={navigateToEditPage}
              rows={
                selectedScoreAssnSubcategory?.levels?.map((s) => ({
                  id: String(s.score),
                  score: s.score,
                  contentCount: s.content.length,
                  trainingCount: s.trainings.length,
                  lastUpdated: 'N/A',
                })) ?? []
              }
            />
          </View>
        </View>
      </ContentBox>
    </>
  );
}
