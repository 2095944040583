import { ButtonGeneral, ButtonVariant } from '@components/general/button-general';
import { CaretDown } from '@components/general/icons/account-icons/CaretDown';
import { CaretUp } from '@components/general/icons/account-icons/CaretUp';
import { ContentBox } from '@components/general/layouts/content-box';
import { GetAdminTrainingStatsQuery, Lesson } from '@gql/generated/generated';
import { pluralize } from '@utils/misc';
import { fromMinutesToNearestHalfDay } from '@utils/trainings';
import React, { useState } from 'react';
import { Text, View } from 'react-native';

import { styles } from './style';

interface ITrainingTableItem {
  title?: string;
  noOfEnrollments: number;
  successRate: number;
  avgTime: string;
  noOfReviews: number;
  rating: number;
  share: number;
}

interface ItemRowProps extends ITrainingTableItem {
  onExpand: () => void;
  isExpanded: boolean;
  index: number;
}

const ItemRow = ({ share, title, noOfEnrollments, successRate, avgTime, noOfReviews, rating, onExpand, index, isExpanded }: ItemRowProps) => (
  <View style={styles.itemRow}>
    <View style={styles.firstItemCell}>
      <Text style={styles.trainingModuleTitle}>{index}</Text>
    </View>
    <View style={styles.itemCellTwo}>
      <Text style={styles.trainingModuleTitle} ellipsizeMode="tail" numberOfLines={1}>
        {title}
      </Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellText}>{noOfEnrollments}</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellText}>{successRate}%</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellText}>{avgTime}/user</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellText}>{noOfReviews}</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellText}>{rating} stars</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellText}>{share}</Text>
    </View>
    <View style={styles.lastItemCell}>
      <ButtonGeneral variant={ButtonVariant.Secondary} label={isExpanded ? 'Close' : 'Expand'} onPress={onExpand}>
        {isExpanded ? <CaretUp /> : <CaretDown />}
      </ButtonGeneral>
    </View>
  </View>
);

const TableHead = () => (
  <View style={styles.itemRow}>
    <View style={styles.firstItemCell} />
    <View style={styles.itemCellTwo}>
      <Text style={styles.cellTitle}>Title</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellTitle}>No. of Enrollments</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellTitle}>Success Rate</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellTitle}>Avg. Time</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellTitle}>No. of Reviews</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellTitle}>Rating</Text>
    </View>
    <View style={styles.itemCell}>
      <Text style={styles.cellTitle}>Shares</Text>
    </View>
    <View style={styles.lastItemCell} />
  </View>
);

const ProgressItem = ({ lesson, title }: { lesson: Omit<Lesson, 'durationInSeconds'>; title: string }) => {
  // const [isUsersVisible, setIsUsersVisible] = useState(false);

  return (
    <View style={styles.progressItemWrap}>
      <View style={styles.trainingModuleTitleWrap}>
        <Text style={styles.trainingModuleTitle} ellipsizeMode="tail" numberOfLines={1}>
          {`${title}: ${lesson.lessonTitle}`}
        </Text>
      </View>
      <View style={styles.progressBar}>
        <View style={[styles.progressInner, { width: `${lesson.completionStats?.percentageCompleted}%` }]}>
          <Text
            style={{
              ...styles.progressInnerText,
              color: lesson.completionStats?.percentageCompleted ? 'white' : '#6D7175',
              position: 'absolute',
            }}
          >
            {lesson.completionStats?.percentageCompleted}%
          </Text>
        </View>
      </View>
      <Text style={styles.trainingModuleTitle}>
        {lesson.completionStats?.users.length} {pluralize(lesson.completionStats?.users.length || 0, 'student')}
      </Text>
      {/* TODO: implement popover */}
      {/* <Popover
        active={isUsersVisible}
        activator={
          <TouchableOpacity onPress={() => setIsUsersVisible(!isUsersVisible)}>
            {isUsersVisible ? (
              <View style={styles.viewTouch}>
                <CloseCircleIcon />
                <Text
                  style={[
                    styles.lessonsText,
                    styles.viewText,
                    styles.viewClose,
                  ]}
                >
                  Close
                </Text>
              </View>
            ) : (
              <View style={styles.viewTouch}>
                <EyeIcon />
                <Text style={[styles.lessonsText, styles.viewText]}>View</Text>
              </View>
            )}
          </TouchableOpacity>
        }
        onClose={() => setIsUsersVisible(false)}
      >
        <ContentBox style={styles.usersPopover}>
          {students.map((student, index) => (
            <View style={styles.userItem} key={student.id}>
              <View style={styles.userItemCellOne}>
                <Text style={styles.userName}>{index + 1}.</Text>
              </View>
              <View style={styles.userItemCell}>
                <View style={styles.userImageWrap}>
                  <Image
                    source={{ uri: student.image }}
                    style={styles.userImage}
                  />
                  <Text
                    style={styles.userName}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    {student.name}
                  </Text>
                </View>
              </View>
              <View style={styles.userItemCell}>
                <Text
                  style={styles.userCellText}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {student.jobTitle}
                </Text>
              </View>
              <View style={styles.userItemCell}>
                <Text
                  style={styles.userCellText}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {student.company}
                </Text>
              </View>
              <View style={styles.userItemLastCell}>
                <Text
                  style={styles.userName}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {student.userType}
                </Text>
              </View>
            </View>
          ))}
        </ContentBox>
      </Popover> */}
      <Text style={styles.lessonsText}>{lesson.completionStats?.membership.free} Free</Text>
      <Text style={styles.lessonsText}>{lesson.completionStats?.membership.plus} Plus</Text>
      <Text style={styles.lessonsText}>{lesson.completionStats?.membership.club} Club</Text>
    </View>
  );
};

interface TrainingItemProps {
  module: {
    durationInSeconds?: number | null;
    lessons: Omit<Lesson, 'durationInSeconds'>[];
    moduleDescription: string;
    moduleTitle: string;
  };
  isExpanded: boolean;
  onExpand: () => void;
  index: number;
}

const TrainingItem = ({ module, isExpanded, onExpand, index }: TrainingItemProps) => {
  return (
    <View style={styles.trainingWrap}>
      <View style={styles.trainingInner}>
        <View style={styles.trainingModuleWrap}>
          <Text style={[styles.trainingModuleTitle, styles.trainingModuleIndex]}>Module {index}:</Text>
          <Text style={styles.trainingModuleTitle}>{module.moduleTitle}</Text>
        </View>
        <ButtonGeneral label={isExpanded ? 'Close' : 'View'} onPress={onExpand} />
      </View>
      {isExpanded && (
        <View style={styles.trainingInfoWrap}>
          <View>
            <Text style={styles.lessonsText}>
              {module.lessons.length} {pluralize(module.lessons.length, 'Lesson')}
            </Text>
            <Text style={styles.lessonsText}>
              {Math.floor((module?.durationInSeconds || 0) / 60)} {pluralize(Math.floor((module?.durationInSeconds || 0) / 60), 'Min')}
            </Text>
          </View>
          <View style={styles.lessonsWrap}>
            {module.lessons.map((lesson, i) => (
              <ProgressItem key={lesson.id} lesson={lesson} title={`${i + 1}`} />
            ))}
          </View>
        </View>
      )}
    </View>
  );
};

interface Props extends Omit<ItemRowProps, 'title'> {
  isExpanded: boolean;
  training: {
    __typename?: 'Training' | undefined;
    id?: string | null | undefined;
    title: string;
    averageStars?: number | null | undefined;
    shareCount?: number | null | undefined;
    modules?:
      | {
          __typename?: 'Module' | undefined;
          id: string;
          moduleDescription: string;
          moduleTitle: string;
          durationInSeconds?: number | null;
          lessons: Omit<Lesson, 'durationInSeconds'>[];
        }[]
      | null
      | undefined;
  };
  enrolledUsers: string[];
}

const AnalyticsTrainingItem = ({
  training,
  isExpanded,
  onExpand,
  avgTime,
  index,
  noOfEnrollments,
  noOfReviews,
  rating,
  share,
  successRate,
}: Props) => {
  const [expandedIndex, setExpandedIndex] = useState<number>();

  const handleExpand = (index: number) => {
    setExpandedIndex(expandedIndex === index ? undefined : index);
  };

  return (
    <View style={styles.moduleWrap}>
      <ItemRow
        title={training?.title}
        avgTime={avgTime}
        index={index}
        isExpanded={isExpanded}
        noOfEnrollments={noOfEnrollments}
        noOfReviews={noOfReviews}
        onExpand={onExpand}
        rating={rating}
        share={share}
        successRate={successRate}
      />
      {isExpanded
        ? training.modules?.map((module, index) => (
            <TrainingItem
              index={index + 1}
              key={module.id}
              module={module}
              isExpanded={expandedIndex === index}
              onExpand={() => handleExpand(index)}
            />
          ))
        : null}
    </View>
  );
};

type AdminTrainingStats = GetAdminTrainingStatsQuery['adminGetMemberStats']['training'];

const BackOfficeAnalyticsTrainingItem = ({ training }: { training?: AdminTrainingStats | null }) => {
  const [expandedIndex, setExpandedIndex] = useState<number>();

  const handleExpand = (index: number) => {
    setExpandedIndex(expandedIndex === index ? undefined : index);
  };

  return (
    <ContentBox>
      <View style={styles.head} />
      <View style={styles.tableWrap}>
        <View style={styles.tableInner}>
          <TableHead />
          {training?.trainings?.map((item, index) => (
            <AnalyticsTrainingItem
              training={item.training}
              key={item.id}
              onExpand={() => handleExpand(index)}
              isExpanded={index === expandedIndex}
              avgTime={fromMinutesToNearestHalfDay(item.avgTimeMins)}
              index={index + 1}
              noOfEnrollments={item.enrollmentCount}
              noOfReviews={item.training.comments?.length || 0}
              rating={item.training?.averageStars || 0}
              share={item.training?.shareCount || 0}
              successRate={item.successRate}
              enrolledUsers={item.enrolledUsers}
            />
          ))}
        </View>
      </View>
    </ContentBox>
  );
};

export { BackOfficeAnalyticsTrainingItem };
