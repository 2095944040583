import { DotsSaveMenu } from '@components/general/dots-save-menu';
import { TooltipWithChild } from '@components/general/tooltip';
import { ModalContainer } from '@components/org/saved/modals/ModalContainer';
import { ConfirmationModalContent } from '@components/org/saved/modals/confirmation-modal/ConfirmationModalContent';
import { GenericModalLayout } from '@components/org/saved/modals/generic-modal-layout/GenericModalLayout';
import { useUserContext } from '@context/UserContext';
import { useDeleteEmployeeMutation, useDeleteUserMutation, useEmployerUpdateUserMutation } from '@gql/generated/generated';
import { Tooltip } from '@shopify/polaris';
import dayjs from 'dayjs';
import { deleteUser } from 'firebase/auth';
import { useState } from 'react';
import { Image, Modal, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { styles } from './styles';

interface Role {
  isAdmin: boolean;
  isEmployer: boolean;
  isSuperAdmin: boolean;
  isUser: boolean;
}

interface Status {
  isOnline: boolean;
  verified: boolean;
  accountStatus?: string | null;
}

interface AllMemberCardProps {
  id: string;
  profileURL?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  role?: Role | null;
  jobPosition?: string | null;
  lastLoggedInDate?: Date | null;
  registrationDate?: Date | null;
  status?: Status | null;
  onAction: () => void;
}

const AllMemberCard = ({
  id,
  profileURL,
  firstName,
  lastName,
  jobPosition,
  lastLoggedInDate,
  registrationDate,
  role,
  status,
  onAction,
}: AllMemberCardProps) => {
  const [showModal, setShowModal] = useState(false);
  const [updateUser] = useEmployerUpdateUserMutation();
  const [deleteEmployee] = useDeleteEmployeeMutation();
  const navigate = useNavigate();
  const { currentUser } = useUserContext();
  const isEmployer = currentUser?.role?.isEmployer;

  function updateAccountStatus(id: string, accountStatus: string) {
    try {
      updateUser({
        variables: {
          input: {
            user: {
              accountStatus,
              suspendedFrom: null,
              suspendedTo: null,
            },
            id,
          },
        },
      });
      setShowModal(true);
    } catch (error) {
      console.log(error);
    }
  }

  const deleteUser = async (id: string) => {
    try {
      await deleteEmployee({
        variables: {
          id,
        },
      });
      onAction();
    } catch (error) {
      console.log(error);
    }
  };

  const employerWebItems = isEmployer
    ? [
        {
          content: status?.accountStatus === 'Active' ? 'Suspend' : 'Activate',
          onAction: () => updateAccountStatus(id, status?.accountStatus === 'Active' ? 'Suspended' : 'Active'),
        },
        {
          content: 'Delete',
          onAction: () => deleteUser(id),
          destructive: true,
          //TOOD: Add delete functionality
        },
      ]
    : [];

  const webMenuItem = [
    {
      content: 'View',
      onAction: () => navigate(`/org/members/${id}`),
    },
    ...employerWebItems,
  ];

  const userRole = (role?: Role | null) => {
    if (role?.isEmployer) return 'Admin';
  };

  return (
    <View>
      <Modal visible={showModal} transparent>
        <ModalContainer>
          <GenericModalLayout
            title="User Suspended"
            closeModal={() => {
              onAction();
              setShowModal(false);
            }}
            hasButtonLayout
            buttonName="Back to All Members"
            onActionButtonPress={() => {
              onAction();
              setShowModal(false);
            }}
            withCancel={false}
          >
            <ConfirmationModalContent
              mainText={`The User ${firstName} ${lastName} has been suspended, to reactivate the user please visit the Member Info > Enable Member`}
              secondaryText=""
            />
          </GenericModalLayout>
        </ModalContainer>
      </Modal>
      <View style={styles.wrapper}>
        <View style={styles.rowContainer}>
          {/* image and status container */}
          <View style={styles.avatarContainer}>
            <Image source={profileURL ? profileURL : require('/assets/img/Avatar.png')} style={styles.avatar} />
            <View style={[styles.status, status?.isOnline ? styles.online : styles.away]} />
          </View>
          {/* name and tag container */}
          <TouchableOpacity onPress={() => navigate(`/org/members/${id}`)} style={styles.nameContainer}>
            <Text style={[styles.textContainer, styles.textContainerName]}>{`${firstName} ${lastName}`}</Text>
            <View style={userRole(role) ? [styles.tagContainer] : null}>
              <Text style={styles.tagTitle}>{userRole(role)}</Text>
            </View>
          </TouchableOpacity>
        </View>
        {/* job title */}
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: 20,
          }}
        >
          <TooltipWithChild toolTipText={jobPosition} width={100}>
            <View style={[styles.textContainer, { alignItems: 'center' }]}>
              <Text numberOfLines={1} ellipsizeMode="tail" style={[styles.textContainerInfo, { width: 110 }]}>
                {jobPosition}
              </Text>
            </View>
          </TooltipWithChild>
        </View>
        {/* last logged */}
        <Text style={[styles.textContainer, styles.textContainerInfo]}>{dayjs(lastLoggedInDate).format('DD/MM/YYYY')}</Text>
        {/* member since */}
        <Text style={[styles.textContainer, styles.textContainerInfo]}>{dayjs(registrationDate).format('DD/MM/YYYY')}</Text>
        {/* buttons */}
        <DotsSaveMenu actionItems={webMenuItem} stylesPopover={styles.dotButton} />
      </View>
    </View>
  );
};

export { AllMemberCard };
