import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY } from '@env';
import algoliaClient from 'algoliasearch';

const client = algoliaClient(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);

export async function getObjects(objects: { indexName: string; id: string }[]) {
  try {
    const response = await client.multipleGetObjects(objects.map((o) => ({ indexName: o.indexName, objectID: o.id })));
    return response.results;
  } catch (err) {
    console.error('ERROR - algolia - getObjects():', err);
    throw err;
  }
}

type SearchAlgoliaParams = {
  query: string;
  indexName: string;
  hitsPerPage: number;
  excludeIds: string[];
  isPublished: boolean;
};

export async function searchAlgolia({ query, indexName, hitsPerPage, isPublished, excludeIds }: SearchAlgoliaParams) {
  try {
    const facetExcludeIds = excludeIds.map((id) => `objectID:-${id}`);
    const publishFilter = isPublished ? "status:'Publish'" : '';
    const index = client.initIndex(indexName);
    const search = await index.search(query, {
      hitsPerPage,
      filters: publishFilter,
      facetFilters: facetExcludeIds,
    });
    return search.hits;
  } catch (err) {
    console.error('ERROR - algolia - getObjects():', err);
    throw err;
  }
}
