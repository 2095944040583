import { useEffect } from 'react';

const roadmapTypes = ['roadmap', 'pulse', 'north-star', 'goals'] as const;
export type RoadmapType = (typeof roadmapTypes)[number];

export function useFirstVisitTutorial({
  roadmapType,
  shouldAutomaticallySetFirstVisit = false,
  deps,
  cb,
}: {
  roadmapType: RoadmapType;
  cb: () => void;
  shouldAutomaticallySetFirstVisit?: boolean;
  deps: any[];
}) {
  if (!roadmapTypes.includes(roadmapType)) throw new Error(`Invalid roadmap type provided to useFirstVisitTutorial`);
  const KEY = `is_first_visit_${roadmapType}`;

  function setIsFirstVisit(value: boolean) {
    localStorage.setItem(KEY, JSON.stringify(value));
  }

  useEffect(() => {
    const isValidDeps = deps.every((d) => !!d);
    if (!isValidDeps) return;
    const isFirstVisit = localStorage.getItem(KEY);
    const isFirstVisitBoolean = isFirstVisit === null ? true : isFirstVisit === 'true';
    if (isFirstVisitBoolean) {
      if (shouldAutomaticallySetFirstVisit) {
        setIsFirstVisit(false);
      }
      cb();
    }
  }, [...deps]);

  return [setIsFirstVisit] as [(value: boolean) => void];
}
