import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  headerSectionTitle: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 16,
    lineHeight: 20,
    color: '#0D1738',
    marginBottom: 20,
  },
  tabsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#E1E3E5',
  },
  tabButton: {
    position: 'relative',
    alignItems: 'center',
    paddingVertical: 16,
    paddingHorizontal: 30,
  },
  tabButtonActive: {
    width: '100%',
    height: 3,
    backgroundColor: '#2C6ECB',
    position: 'absolute',
    bottom: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  tabButtonText: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#6D7175',
  },
  tabButtonTextActive: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#0D1738',
  },

  fieldText: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    color: '#202223',
    marginLeft: 8,
  },
});
