import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  container: {
    marginBottom: 26,
  },
  descriptionText: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 24,
    color: '#0D1738',
    marginBottom: 24,
  },
  nameText: {
    fontSize: 20,
    lineHeight: 28,
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
  },
  socialButtonsContainer: {
    marginVertical: 6,
  },
  speakerText: {
    fontSize: 12,
    color: '#6E757C',
  },
  infoSection: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 8,
  },
});
