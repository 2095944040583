import { ButtonGeneral, ButtonType } from '@components/general/button-general';
import { Input } from '@components/general/form/input';
import { InputError } from '@components/general/form/input-error';
import { PhoneInput } from '@components/general/form/phone-input';
import { AuthWrapper } from '@components/sign-up/auth-wrapper';
import { ErrorBox } from '@components/sign-up/error-box';
import { AccountType, useGetOrganisationByDomainLazyQuery, useIsEmailRegistrableLazyQuery } from '@gql/generated/generated';
import * as Sentry from '@sentry/react';
import { createAccountValidationSchema } from '@utils/validators';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { ids, styles } from './style';

interface RoleButtonProps {
  active?: boolean;
  title: string;
  onPress: () => void;
}

const RoleButton = ({ active, title, onPress }: RoleButtonProps) => (
  <TouchableOpacity style={styles.role} onPress={onPress}>
    <Text style={[styles.buttonRole, active && styles.buttonRoleSelected]}>{title}</Text>
  </TouchableOpacity>
);

export interface CreateAccountInput {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  accountType: AccountType;
}

interface Props {
  values: CreateAccountInput;
  onPressBack: () => void;
  onPressForward: (values: CreateAccountInput) => void;
}

const CreateAccount = ({ values, onPressBack, onPressForward }: Props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [isEmailRegistrable] = useIsEmailRegistrableLazyQuery();

  const [getOrgFromEmail] = useGetOrganisationByDomainLazyQuery();

  const onSubmit = async (formikValues: CreateAccountInput) => {
    setIsLoading(true);
    setErrorMsg(''); // reset error message for user retries.
    try {
      const { error: isEmailRegistrableError } = await isEmailRegistrable({
        variables: { email: formikValues.email },
      });

      if (isEmailRegistrableError) {
        throw isEmailRegistrableError;
      }

      const { data, error } = await getOrgFromEmail({
        variables: { email: formikValues.email },
      });

      const isExistingOrganisation = data?.getOrganisationByDomain;
      if (error && error?.message !== 'Organisation not found') {
        throw error;
      }

      onPressForward({
        ...formikValues,
        accountType: isExistingOrganisation ? AccountType.EMPLOYEE : AccountType.EMPLOYER,
      });
    } catch (error) {
      setErrorMsg((error as Error).message || 'Something went wrong');
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthWrapper
      isKeyboardAvoidingView
      title="Create a new account"
      subtitle="Let’s level up your business together in 3 simple steps!"
      onPressBack={onPressBack}
    >
      <View>
        <Formik
          initialValues={{
            firstName: values.firstName || '',
            lastName: values.lastName || '',
            email: values.email || '',
            phoneNumber: values.phoneNumber || '',
            accountType: values.accountType || '',
          }}
          onSubmit={onSubmit}
          validationSchema={createAccountValidationSchema}
        >
          {({ values: val, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
            <View>
              {errorMsg ? <ErrorBox error={errorMsg} /> : null}
              {errors.accountType ? (
                <View style={styles.inputWrap}>
                  <InputError error={errors.accountType as string} />
                </View>
              ) : null}
              <View style={styles.inputWrap}>
                <Input
                  placeholder="First Name"
                  value={val.firstName}
                  onChangeText={handleChange('firstName')}
                  error={touched.firstName && errors.firstName ? (errors.firstName as string) : undefined}
                />
              </View>
              <View style={styles.inputWrap}>
                <Input
                  placeholder="Last Name"
                  value={val.lastName}
                  onChangeText={handleChange('lastName')}
                  error={touched.lastName && errors.lastName ? (errors.lastName as string) : undefined}
                />
              </View>
              <View style={styles.inputWrap}>
                <Input
                  placeholder="Email Address"
                  value={val.email}
                  onChangeText={handleChange('email')}
                  keyboardType="email-address"
                  autoCapitalize="none"
                  error={touched.email && errors.email ? (errors.email as string) : undefined}
                />
              </View>
              <View style={styles.inputWrap}>
                <PhoneInput onChangeText={(phoneNumber) => setFieldValue('phoneNumber', phoneNumber)} placeholder="Phone Number" />
              </View>
              <ButtonGeneral bold label="Continue" isLoading={isLoading} onPress={handleSubmit} type={ButtonType.user} style={styles.submitButton} />
            </View>
          )}
        </Formik>
        <View style={styles.register} dataSet={{ media: ids.register }}>
          <Text style={styles.noAccount}>Already have an account?</Text>
          <TouchableOpacity onPress={() => navigate('/login')}>
            <Text style={styles.registerLink}>&nbsp;Login</Text>
          </TouchableOpacity>
        </View>
      </View>
    </AuthWrapper>
  );
};

export { CreateAccount };
