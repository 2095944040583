import { Row } from '@components/general/row';
import { useUserContext } from '@context/UserContext';
import {
  ClubMembershipRequestStatus,
  GoCardlessCustomer,
  Maybe,
  SubscriptionPlanType,
  useDeleteSubscriptionGoCardlessCustomerMutation,
  useUpdateClubMembershipRequestStatusMutation,
} from '@gql/generated/generated';
import { textStyles } from '@styles/text';
import { Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { styles } from './styles';

interface SubscriptionPlanInfoProps {
  upgradePlanAction: () => void;
  hasCompletedBusinessProfile: boolean;
  hasCompletedBillingAddress: boolean;
  hasCompletedPaymentMethod: boolean;
  selectedNewPlan: string | null;
  goCardLessCustomer?: GoCardlessCustomer;
  clubMembershipRequestStatus?: Maybe<ClubMembershipRequestStatus>;
}

const SubscriptionPlanInfo = ({
  upgradePlanAction,
  hasCompletedBusinessProfile,
  hasCompletedBillingAddress,
  hasCompletedPaymentMethod,
  selectedNewPlan,
  goCardLessCustomer,
  clubMembershipRequestStatus,
}: SubscriptionPlanInfoProps) => {
  const navigate = useNavigate();
  const { currentUser } = useUserContext();

  const currentPlan = goCardLessCustomer?.subscription?.subscriptionPlanType || SubscriptionPlanType.FREE;

  const currentPlanText = goCardLessCustomer?.subscription?.subscriptionPlanAsText;

  const orgId = currentUser?.accountInfo?.companyInfo.id;

  const [deleteSubscriptionGoCardLessCustomer] = useDeleteSubscriptionGoCardlessCustomerMutation();
  const deleteSubscriptionGoCardLessCustomerHandler = () => {
    deleteSubscriptionGoCardLessCustomer();
  };
  const [updateClubMembershipRequestStatus] = useUpdateClubMembershipRequestStatusMutation();

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          {!hasCompletedBusinessProfile ? (
            <>
              <Text style={styles.title}>You must fill in your Company Profile prior to Upgrading. </Text>
              <Text style={styles.titleButton} onPress={() => navigate('/org/settings/business-info')}>
                Finish Business Profile
              </Text>
            </>
          ) : null}
        </View>
        {currentUser ? (
          <TouchableOpacity
            style={[styles.upgradeButtonContainer, !hasCompletedBusinessProfile && styles.upgradeButtonContainerDisabled]}
            onPress={upgradePlanAction}
            disabled={!hasCompletedBusinessProfile}
          >
            <Text style={[styles.upgradeButton, !hasCompletedBusinessProfile && styles.upgradeButtonDisabled]}>
              {currentPlan === SubscriptionPlanType.PLUS || currentPlan === SubscriptionPlanType.CLUB ? 'Change Plan' : 'Upgrade Plan'}
            </Text>
          </TouchableOpacity>
        ) : null}
      </View>
      <View style={styles.currentPlanContainer}>
        {clubMembershipRequestStatus === ClubMembershipRequestStatus.PENDING ? (
          <Text style={[styles.currentPlanPrice, { marginLeft: 0, marginVertical: 30 }]}>
            You currently have a Club membership application pending. If your application is successful, your subscription will be updated to Club
            with immediate effect.{' '}
            <Text
              style={styles.link}
              onPress={() => {
                if (orgId) {
                  updateClubMembershipRequestStatus({
                    variables: {
                      clubMembershipRequestStatus: ClubMembershipRequestStatus.CANCELED,
                      orgId,
                    },
                  });
                }
              }}
            >
              Cancel application
            </Text>
          </Text>
        ) : null}
        {selectedNewPlan === SubscriptionPlanType.FREE ? (
          <Text style={[styles.currentPlanPrice, { marginLeft: 0, marginVertical: 30 }]}>
            To downgrade your plan to our Free plan, simply cancel your subscription.
          </Text>
        ) : null}
        <Text style={styles.currentPlanTitle}>Current Plan</Text>
        <Row>
          <Text style={styles.currentPlan}>{currentPlanText || 'Free'}</Text>
          {currentPlan === SubscriptionPlanType.FREE ? null : (
            <Text style={styles.currentPlanPrice}>
              {/* TODO: database as source of truth for these plan prices */}
              {currentPlan === SubscriptionPlanType.PLUS ? '£249' : '£599'} per month{' '}
            </Text>
          )}
        </Row>
      </View>
      <View style={styles.renewDateContainer}>
        {currentUser && currentPlan !== SubscriptionPlanType.FREE ? (
          <>
            <Text style={styles.renewDateTitle}>Renewal Date</Text>
            <Text style={styles.renewDate}>{goCardLessCustomer?.subscription?.nextChargeDate}</Text>
          </>
        ) : null}
      </View>
      {goCardLessCustomer?.subscription?.status === 'active' ? (
        <TouchableOpacity style={styles.cancelPlanButton} onPress={deleteSubscriptionGoCardLessCustomerHandler}>
          <Text style={styles.cancelPlanButtonText}>Cancel Subscription</Text>
        </TouchableOpacity>
      ) : null}

      {selectedNewPlan ? (
        <>
          {!hasCompletedBillingAddress || !hasCompletedPaymentMethod ? (
            <Text style={[styles.upgradeButton, { marginBottom: 4 }]}>To upgrade to {selectedNewPlan}, you need to:</Text>
          ) : null}
          {hasCompletedBillingAddress ? null : <Text style={textStyles.error}>Add a billing address</Text>}
          {hasCompletedPaymentMethod ? null : <Text style={textStyles.error}>Add a payment method</Text>}
        </>
      ) : null}
    </View>
  );
};

export { SubscriptionPlanInfo };
