import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
  },
  deleteButtonContainer: {
    borderTopColor: '#EDEEEF',
    borderTopWidth: 1,
    flexDirection: 'row',
  },
  deleteButton: {
    backgroundColor: '#FF0000',
    borderRadius: 10,
    paddingVertical: 5,
    paddingHorizontal: 35,
    marginLeft: 40,
    marginTop: 20,
    marginBottom: 40,
  },
  deleteButtonText: {
    color: '#fff',
    fontSize: 16,
    fontFamily: 'OpenSans_600SemiBold',
  },
  title: {
    color: '#202223',
    fontSize: 17,
    lineHeight: 28,
  },
  subtitle: {
    color: '#202223',
    marginTop: 0,
    lineHeight: 24,
    fontFamily: 'OpenSans_400Regular',
  },
});
