import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  outerContainer: {
    backgroundColor: 'rgba(13, 23, 56, 0.78)',
    height: '100%',
    width: '100%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    backgroundColor: 'white',
    borderRadius: 12,
    width: '80%',
  },
  crossButton: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  iframe: {
    borderRadius: 12,
    height: '90vh',
  },
});
