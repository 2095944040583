import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  photoWrap: {
    marginRight: 8,
  },
  commentInput: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#EDEEEF',
    padding: 8,
    fontSize: 14,
    color: '#6E757C',
    fontFamily: 'OpenSans_400Regular',
    marginRight: 8,
    flexGrow: 3,
  },
});
