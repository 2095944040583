import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  tabsContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#E1E3E5',
  },
  tabButton: {
    position: 'relative',
    alignItems: 'center',
    paddingVertical: 16,
    paddingHorizontal: 20,
  },
  tabButtonActive: {
    width: '100%',
    height: 3,
    backgroundColor: '#2C6ECB',
    position: 'absolute',
    bottom: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  tabButtonText: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#6D7175',
  },
  tabButtonTextActive: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#0D1738',
  },
});
