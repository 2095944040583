import { ContentBox } from '@components/general/layouts/content-box';
import { useSearchBarLayoutContext } from '@components/search/search-bar-layout-context';
import { EmptySearch } from '@pages/search/empty-search';
import { ModuleSearch } from '@pages/search/module-search';
import React, { useEffect, useState } from 'react';
import { Pressable } from 'react-native';

import { styles } from './style';
import { useAlgoliaSearch } from '../useAlgoliaSearch';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  query: string;
}

const SearchModal = ({ isVisible, onClose, query }: Props) => {
  const { eventsResults, contentResults, trainingsResults, querySuggestions, partnersResults, isLoading } = useAlgoliaSearch({
    query,
  });
  const [topSpacing, setTopSpacing] = useState(0);
  const [leftSpacing, setLeftSpacing] = useState(0);
  const { addSearchBarLayoutObserver } = useSearchBarLayoutContext();
  useEffect(() => {
    return addSearchBarLayoutObserver((layout) => {
      setTopSpacing(layout.y);
      setLeftSpacing(layout.x);
    });
  }, []);

  return (
    <Pressable
      style={[
        styles.modalInner,
        !isVisible && styles.modalHide,
        leftSpacing === 0 ? styles.modalCenter : {},
        { top: styles.modalInner.top + (topSpacing - 10) },
      ]}
      onPress={onClose}
    >
      <ContentBox style={[styles.searchBox, { left: leftSpacing }]}>
        {query && query.length > 2 ? (
          <ModuleSearch
            query={query}
            eventsResults={eventsResults}
            trainingsResults={trainingsResults}
            contentResults={contentResults}
            partnersResults={partnersResults}
            onClose={onClose}
            isLoading={isLoading}
          />
        ) : (
          <EmptySearch onClose={onClose} querySuggestions={querySuggestions} />
        )}
      </ContentBox>
    </Pressable>
  );
};

export { SearchModal };
