import { mediaQuery } from '@utils/misc';
import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
    marginTop: 40,
    height: '100%',
  },
  title: {
    fontSize: 20,
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
    marginBottom: 20,
  },
  courseItem: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 178,
    [mediaQuery.forMobile]: {
      minHeight: 56,
    },
  },
  viewContainer: {
    backgroundColor: 'white',
    paddingVertical: 4,
    paddingHorizontal: 16,
    borderRadius: 36,
    position: 'absolute',
    top: 78,
  },
  courseImageWrap: {
    width: '30%',
    height: '100%',
    [mediaQuery.forMobile]: {
      width: '20%',
    },
    justifyContent: 'center',
    alignItems: 'center',
  },
  courseImage: {
    height: '100%',
    width: '100%',
    padding: 8,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    display: 'flex',
    [mediaQuery.forMobile]: {
      display: 'none',
    },
  },
  courseImageMobile: {
    borderRadius: 8,
    height: 56,
    maxWidth: 56,
    display: 'none',
    [mediaQuery.forMobile]: {
      display: 'flex',
    },
  },
  courseImageInner: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  courseTag: {
    borderRadius: 35,
    backgroundColor: '#E4E5E7',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 12,
    paddingRight: 12,
    ...(Platform.OS === 'web' && { width: 'fit-content' }),
    marginBottom: 8,
  },
  courseTagText: {
    color: '#202223',
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
  },
  courseRight: {
    width: '70%',
    height: '100%',
    padding: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    [mediaQuery.forMobile]: {
      width: '80%',
      padding: 0,
    },
  },
  courseRightInner: {
    width: '90%',
    [mediaQuery.forMobile]: {
      width: '100%',
    },
  },
  threeDotsTouchWrap: {
    display: 'flex',
    [mediaQuery.forMobile]: {
      display: 'none',
    },
  },
  threeDotsTouch: {
    marginTop: 8,
  },
  courseName: {
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
    color: '#0D1738',
    [mediaQuery.forMobile]: {
      fontSize: 12,
      marginBottom: 4,
    },
  },
  courseDescription: {
    marginTop: 10,
    marginBottom: 8,
    display: 'flex',
    [mediaQuery.forMobile]: {
      display: 'none',
    },
  },
  totalHoursText: {
    color: '#2C6ECB',
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
    [mediaQuery.forMobile]: {
      fontSize: 12,
    },
  },
  instructorWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
    marginBottom: 8,
    display: 'flex',
    [mediaQuery.forMobile]: {
      display: 'none',
    },
  },
  instructorName: {
    color: '#6D7175',
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
  },
  jobRole: {
    display: 'flex',
    flex: 1,
    color: '#0D1738',
    fontSize: 12,
    fontFamily: 'OpenSans_600SemiBold',
  },
  reviewsWrap: {
    marginTop: 4,
    flexDirection: 'row',
    alignItems: 'center',
    [mediaQuery.forMobile]: {
      marginTop: 2,
    },
  },
  reviewsText: {
    color: '#6D7175',
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
    marginLeft: 4,
  },
  enrolledWrap: {
    backgroundColor: '#2C6ECB',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 15,
    marginLeft: 4,
    display: 'flex',
    [mediaQuery.forMobile]: {
      marginTop: 0,
      display: 'none',
    },
  },
  enrolledText: {
    color: '#FFFFFF',
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
  },
});
