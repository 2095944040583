import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { ISvgProps } from '../../../../types';

const FacebookIcon = ({ color = '#0D1738', ...props }: ISvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <Path
      fill={color}
      d="M.5 8c0 3.708 2.708 6.792 6.25 7.417l.042-.034-.042-.008v-5.292H4.875V8H6.75V6.333c0-1.875 1.208-2.916 2.917-2.916.541 0 1.125.083 1.666.166V5.5h-.958c-.917 0-1.125.458-1.125 1.042V8h2l-.333 2.083H9.25v5.292a6.166 6.166 0 0 1-.042.008l.042.034c3.542-.625 6.25-3.709 6.25-7.417C15.5 3.875 12.125.5 8 .5S.5 3.875.5 8z"
    />
  </Svg>
);

export { FacebookIcon };
