import { ButtonGeneral } from '@components/general/button-general';
import { MarketplaceIcon, RightArrow } from '@components/general/icons';
import { HelpIcon } from '@components/general/icons/account-icons/HelpIcon';
import { NotificationIcon } from '@components/general/icons/account-icons/NotificationIcon';
import { PlusCircleIcon } from '@components/general/icons/feed-icons/PlusCircleIcon';
import { CloseIconSmall } from '@components/general/icons/search/CloseIconSmall';
import { UserIcon } from '@components/general/icons/training-icons';
import { IndexPageHeader } from '@components/general/index-page-header';
import { Pill, PillVariant } from '@components/general/pill';
import { useAssignment } from '@hooks/useAssignment';
import { useAuth } from '@hooks/useAuth';
import { PageVariant } from '@utils/models';
import { useNativeSafeAreaInsets } from '@utils/useNativeSafeAreaInsets';
import { userProfileImage } from '@utils/userProfileImage';
import React, { useEffect, useMemo, useState } from 'react';
import { Image, ImageSourcePropType, Modal, ScrollView, Text, TouchableOpacity, View } from 'react-native';

import { styles } from './style';

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

interface NavItemProps {
  title: string;
  icon?: React.ReactNode;
  onPress?: () => void;
}
const NavItem = ({ title, icon, onPress }: NavItemProps) => (
  <TouchableOpacity onPress={onPress} style={styles.navItem}>
    {icon}
    <Text style={[styles.navItemTitle, styles.navItemTitleMargin]}>{title}</Text>
  </TouchableOpacity>
);

interface AssignmentBoxProps extends NavItemProps {
  isBold?: boolean;
  badge?: number;
}
const AssignmentBox = ({ title, icon, onPress, isBold, badge }: AssignmentBoxProps) => (
  <TouchableOpacity style={[styles.navItem, styles.assignmentItem]} onPress={onPress}>
    <Text style={[styles.navItemTitle, isBold && styles.navItemTitleBold]}>{title}</Text>
    {badge ? (
      <View style={styles.badge}>
        <Text style={styles.badgeText}>{badge}</Text>
      </View>
    ) : null}
    {icon}
  </TouchableOpacity>
);

interface UserBoxProps {
  image: ImageSourcePropType;
  name: string;
  profession: string;
  userType: string;
}

const UserBox = ({ image, name, profession, userType }: UserBoxProps) => {
  console.log('Got here.');
  return (
    <View style={[styles.navItem, styles.assignmentItem]}>
      <View style={styles.userInfoWrap}>
        <Image source={image} style={styles.userImage} />
        <View style={styles.userNameWrap}>
          <Text style={styles.userName}>{name}</Text>
          <Text style={styles.profession}>{profession}</Text>
        </View>
      </View>
      <Pill variant={PillVariant.LightBlue} text={userType} />
    </View>
  );
};

const AccountMobile = ({ isVisible, onClose }: Props) => {
  const [userType, setUserType] = useState('FREE');
  const { navigate, handleSignOut, user } = useAuth();
  const { eventItems, contentItems, trainingItems } = useAssignment();

  const userAvatarImage = userProfileImage(user);

  const handlePress = (path: string, state?: unknown) => {
    navigate(path, { state });
    onClose();
  };

  useEffect(() => {
    const plan = user?.subscription?.plan ? user.subscription.plan.toUpperCase() : 'FREE';

    setUserType(plan);
  }, [user?.subscription?.plan]);

  const safeAreaInsets = useNativeSafeAreaInsets();

  return (
    <Modal visible={isVisible} animationType="slide">
      <IndexPageHeader
        isBackButtonHidden
        title="Account"
        rightContent={
          <TouchableOpacity onPress={onClose} style={styles.closeTouch}>
            <CloseIconSmall color="#0D1738" />
          </TouchableOpacity>
        }
      />
      <ScrollView
        automaticallyAdjustContentInsets={false}
        automaticallyAdjustsScrollIndicatorInsets={false}
        contentContainerStyle={{
          paddingBottom: safeAreaInsets.bottom,
        }}
        scrollIndicatorInsets={{ bottom: safeAreaInsets.bottom }}
      >
        <UserBox
          image={userAvatarImage}
          name={user?.accountInfo ? `${user.accountInfo.firstName} ${user.accountInfo.lastName}` : ''}
          profession={user?.accountInfo?.companyInfo?.jobPosition || ''}
          userType={userType}
        />
        <NavItem title="Profile" icon={<UserIcon />} onPress={() => handlePress('/account/settings/profile')} />
        <NavItem title="Saved Items" icon={<PlusCircleIcon />} onPress={() => handlePress('/org/saved')} />
        <NavItem title="Notifications" icon={<NotificationIcon />} onPress={() => handlePress('/account/notification-settings')} />
        <NavItem title="Partners" icon={<MarketplaceIcon color="#0D1738" />} onPress={() => handlePress('/marketplace')} />
        <NavItem title="Help" icon={<HelpIcon />} onPress={() => handlePress('/account/help')} />
        <View style={styles.assignmentWrap}>
          <AssignmentBox title="Assignment" isBold icon={<RightArrow />} onPress={() => handlePress('/org/assignment/native')} />
        </View>
        <AssignmentBox
          title="Content"
          badge={contentItems?.length || undefined}
          onPress={() =>
            handlePress('/org/assignment/list', {
              variant: PageVariant.content,
            })
          }
        />
        <AssignmentBox
          title="Events"
          badge={eventItems?.length || undefined}
          onPress={() => handlePress('/org/assignment/list', { variant: PageVariant.event })}
        />
        <AssignmentBox
          title="Training"
          badge={trainingItems?.length || undefined}
          onPress={() =>
            handlePress('/org/assignment/list', {
              variant: PageVariant.training,
            })
          }
        />
        <View style={styles.buttonWrap}>
          <ButtonGeneral label="Log out" onPress={handleSignOut} />
        </View>
      </ScrollView>
    </Modal>
  );
};

export { AccountMobile };
