import { PlanBlock } from '@components/roadmap/plan-block';
import { RoadmapPulseWrapper } from '@components/roadmap/roadmap-pulse-wrapper';
import { RoadmapPulseContext } from '@context/RoadmapPulseContext';
import { useUserContext } from '@context/UserContext';
import { FormModalPulse } from '@pages/roadmap/form-modal-pulse';
import { useRoadmapSubmodules } from '@pages/roadmap/hooks/useRoadmapSubmodules';
import { getNonEmptyAnswersPercentage, getQuestionAndAnswerCount } from '@pages/roadmap/utils';
import { roadmapPulseSectionToSubmodule } from '@utils/roadmapSectionToSubmodule';
import { useContext } from 'react';
import { ActivityIndicator, View } from 'react-native';

import { ids, styles } from './style';

const PulseIndex = () => {
  const { submodule, isVisible, handlePlay, setIsVisible } = useRoadmapSubmodules();
  const { loadingCurrentUser } = useUserContext();
  const roadmapPulseContext = useContext(RoadmapPulseContext);
  if (!roadmapPulseContext) throw new Error('Roadmap pulse context not setup');
  const { selectedPulse, isLoading, isSelectedPulseLatest } = roadmapPulseContext;

  const pulseSectionsData = selectedPulse && getQuestionAndAnswerCount(selectedPulse.sections);

  if (loadingCurrentUser || isLoading) {
    return (
      <View
        style={{
          width: '100vw',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          zIndex: 99,
        }}
      >
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  }

  return (
    <RoadmapPulseWrapper
      title="Pulse"
      totalAnswered={pulseSectionsData?.answerCount ?? 0}
      totalQuestion={pulseSectionsData?.questionCount ?? 0}
      hasSeeResultsButton
    >
      <View style={styles.wrap} dataSet={{ media: ids.wrap }}>
        {selectedPulse?.sections &&
          selectedPulse.sections.map((section) => {
            const percentageComplete = getNonEmptyAnswersPercentage(section.questions);

            return (
              <PlanBlock
                key={section.id}
                title={section.name ?? ''}
                percent={Number(percentageComplete)}
                totalQuestion={section.questions.length}
                hidePlay={!isSelectedPulseLatest}
                onPlay={() => {
                  const submodule = roadmapPulseSectionToSubmodule(section);
                  if (submodule) {
                    handlePlay(submodule);
                  }
                }}
              />
            );
          })}
      </View>
      {submodule && selectedPulse?.id && (
        <FormModalPulse
          isVisible={isVisible}
          id={selectedPulse.id}
          onClose={() => setIsVisible(false)}
          submodule={submodule}
          roadmapSection="pulse"
        />
      )}
    </RoadmapPulseWrapper>
  );
};

export { PulseIndex };
