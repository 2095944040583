import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const ClockIcon = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path
      d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8Zm0 14.4A6.407 6.407 0 0 1 1.6 8c0-3.529 2.871-6.4 6.4-6.4 3.529 0 6.4 2.871 6.4 6.4 0 3.529-2.871 6.4-6.4 6.4Zm.8-6.731V4a.8.8 0 0 0-1.6 0v4c0 .213.084.416.234.566l2.4 2.4a.799.799 0 1 0 1.132-1.132L8.8 7.67Z"
      fill="#8B9197"
    />
  </Svg>
);

export { ClockIcon };
