import { CategoryButton } from '@components/back-office/tables/category-button';
import { useState } from 'react';

import { useQueryFilters } from '../useGridFilters';
import { useLocation } from 'react-router-native';

export const CategoriesFilter = () => {
  const [displayCategoryModal, setDisplayCategoryModal] = useState(false);
  const location = useLocation();
  const pathnameLowerCase = location.pathname.toLowerCase();
  const isEvent = pathnameLowerCase === '/events';

  const { filters, setFilterValue } = useQueryFilters(['categories']);

  const value = filters.categories || [];

  return (
    <CategoryButton
      setSelectedCat={(v) => setFilterValue('categories', v)}
      displayCategoryModal={displayCategoryModal}
      setDisplayCategoryModal={setDisplayCategoryModal}
      selectedCat={value}
      isEvent={isEvent}
      isSelected={!!filters.categories}
    />
  );
};
