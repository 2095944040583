import { useFormikContext } from 'formik';
import { View } from 'react-native';

import { ButtonLayout } from '../../button-layout/ButtonLayout';
import { InputFormik } from '../../form-elements/input-formik/InputFormik';
import { InputFormikContainer } from '../../form-elements/input-formik/InputFormikContainer';

interface formProps {
  closeEditMode: () => void;
}

const ContactDetailForm = ({ closeEditMode }: formProps) => {
  const { handleSubmit } = useFormikContext();

  const submitCloseHander = () => {
    handleSubmit();
    closeEditMode();
  };

  return (
    <>
      <InputFormikContainer label="Contact Detail">
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <InputFormik formikKey="email" placeholder="Email" />
          <InputFormik formikKey="phoneNumber" placeholder="Phone Number" />
        </View>
      </InputFormikContainer>
      <ButtonLayout closeEditMode={closeEditMode} submitCloseHander={submitCloseHander} />
    </>
  );
};

export { ContactDetailForm };
