import { User } from 'firebase/auth';
import { useEffect, useState } from 'react';

import { auth } from '../firebase';

const useGetAuthTokenAndUserId = () => {
  const [userId, setUserId] = useState<string>('');
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        setUserId(user.uid);
      } else {
        setUserId('');
        setUser(null);
      }
    });
  }, []);

  return {
    userId,
    user,
  };
};

export { useGetAuthTokenAndUserId };
