import { User, useCreateSessionMutation, useUpdateSessionMutation } from '@gql/generated/generated';
import { useEffect } from 'react';
import { useLocation } from 'react-router-native';

import { UseAddPageInfoToSession, UseAddPageInfoToSessionProps, UseSessionStorage } from './useSessionStorageTypes';

interface Session {
  sessionId?: string;
  pageView: {
    route: string;
    title?: string;
    type?: 'training' | 'event' | 'content';
    categories?: string[];
  };
  sessionEnd: number;
  userId: string;
}

const getCurrentSession = () => {
  const currentSession = localStorage.getItem('session');
  return currentSession ? (JSON.parse(currentSession) as Session) : null;
};

const corePath = (route: string) => {
  // for training, we only care about the course, not the individual lesson
  const regex = /training\/(.+)\/lesson/g;
  if (route.match(regex)) {
    return route.split('/').slice(0, 3).join('/');
  }
  return route;
};

export const useAddPageInfoToSession: UseAddPageInfoToSession = ({ title, type, categories }: UseAddPageInfoToSessionProps) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const parsedSession = getCurrentSession();
    if (!parsedSession) return;
    const updatedSession = {
      ...parsedSession,
      pageView: {
        route: corePath(pathname),
        title,
        type,
        categories,
      },
    };
    return localStorage.setItem('session', JSON.stringify(updatedSession));
  }, [title, type, categories?.length]);
};

export const useSessionStorage: UseSessionStorage = (user?: User) => {
  const [createSession] = useCreateSessionMutation();
  const [updateSession] = useUpdateSessionMutation();

  const location = useLocation();

  useEffect(() => {
    const parsedSession = getCurrentSession();
    if (!parsedSession) return;

    const pageView = parsedSession.pageView;

    // if the updated location is the same as the last location, ignore
    if (pageView.route === corePath(location.pathname)) return;

    const updatedSession = {
      ...parsedSession,
      pageView: {
        route: corePath(location.pathname),
      },
    };

    return localStorage.setItem('session', JSON.stringify(updatedSession));
  }, [location]);

  const createNewSession = async (parsedSession: Session) => {
    const endTime = Date.now();
    try {
      await createSession({
        variables: {
          input: {
            pageView: parsedSession.pageView,
          },
        },
      }).then(({ data }) => {
        localStorage.setItem(
          'session',
          JSON.stringify({
            userId: user?.id,
            sessionEnd: endTime,
            sessionId: data?.createSession,
            pageView: parsedSession.pageView,
          })
        );
      });
      // swallow error and try again next interval
    } catch (e) {}
  };

  const updateCurrentSession = async (parsedSession: Session) => {
    const updatedSessionEnd = Date.now();
    try {
      if (!parsedSession.sessionId || !parsedSession.pageView) {
        localStorage.removeItem('session');
      } else {
        await updateSession({
          variables: {
            input: {
              id: parsedSession.sessionId,
              pageView: parsedSession.pageView,
            },
          },
        });
      }
    } catch (e) {
    } finally {
      // we update the sessionEnd time in LS so that we can try again in 1min without the session being ended
      localStorage.setItem(
        'session',
        JSON.stringify({
          ...parsedSession,
          sessionEnd: updatedSessionEnd,
        })
      );
    }
  };

  useEffect(() => {
    const runSession = () => {
      if (!user) return;
      if (!document.hasFocus()) return;
      const parsedSession = getCurrentSession();

      if (!parsedSession) {
        // if no session in LS start basic session without sessionId
        return localStorage.setItem(
          'session',
          JSON.stringify({
            userId: user?.id,
            sessionEnd: Date.now(),
            pageView: { route: corePath(location.pathname) },
          })
        );
      } else if (
        // if session exists in LS but the session ended more than 5 mins ago,
        // clear local storage
        Date.now() - parsedSession.sessionEnd >
        300000
      ) {
        return localStorage.removeItem('session');
      } else if (parsedSession.sessionId) {
        // update the session in LS and firestore
        return updateCurrentSession(parsedSession);
      } else {
        // else the session exists in LS, has not expired but has not been created in firestore
        return createNewSession(parsedSession);
      }
    };

    runSession();

    const interval = setInterval(runSession, 60000);

    () => clearInterval(interval);
  }, [user, document]);
};
