import { SelectOption } from '@components/general/form/select-option';
import { ContentBox } from '@components/general/layouts/content-box';
import { defaultLineChartStyles } from '@components/org/analytics/analytics-members-overview';
import React, { useState } from 'react';
import { ActivityIndicator, LayoutChangeEvent, Text, View } from 'react-native';
import { LineChart } from 'react-native-chart-kit';

import { ids, styles } from './style';

const AnalyticsAveragePageTime = ({
  time,
  dates,
  refetch,
  loading,
}: {
  time?: number[];
  dates?: string[];
  refetch: (value: { numDays: number }) => void;
  loading: boolean;
}) => {
  const labels = dates?.length && dates?.length > 7 ? dates?.map((date, i) => (i % 3 === 0 ? date : '')) : dates;

  const [selected, setSelected] = useState('');
  const [chartWidth, setChartWidth] = useState(300);
  const data = {
    labels: labels || [],
    datasets: [
      {
        data: time || [],
        color: () => '#1653CE',
        strokeWidth: 1,
      },
    ],
  };

  const onChartWrapLayoutChange = (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout;
    setChartWidth(width);
  };

  return (
    <ContentBox style={styles.contentBox} dataSetMedia={ids.contentBox}>
      <View style={styles.titleWrap} dataSet={{ media: ids.titleWrap }}>
        <Text style={styles.title}>Average time on page</Text>
        <View style={styles.selectWrap}>
          <SelectOption
            onChange={(value) => {
              setSelected(value);
              refetch({ numDays: Number(value) });
            }}
            value={selected}
            options={[
              { label: 'This Week', value: '8' },
              { label: 'This Month', value: `${new Date().getDate()}` },
            ]}
          />
        </View>
      </View>
      <View style={styles.contentInner} onLayout={onChartWrapLayoutChange}>
        {!loading ? (
          <LineChart data={data} width={chartWidth} height={220} withVerticalLines={false} yAxisSuffix="mins" chartConfig={defaultLineChartStyles} />
        ) : (
          <ActivityIndicator size="large" color="#213470" />
        )}
      </View>
    </ContentBox>
  );
};

export { AnalyticsAveragePageTime };
