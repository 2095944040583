import { ButtonGeneral, ButtonVariant } from '@components/general/button-general';
import { CloseIcon } from '@components/general/icons/CloseIcon';
import { ModalWrapper } from '@components/general/modals/modal-wrapper';
import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { styles } from './style';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  subtitle?: string;
  primaryButtonTitle?: string;
  onPrimaryButtonPress?: () => void;
  secondaryButtonTitle?: string;
  onSecondaryButtonPress?: () => void;
  children?: React.ReactNode;
  isLoading?: boolean;
}

const QuestionModal = ({
  isVisible,
  onClose,
  title,
  primaryButtonTitle,
  secondaryButtonTitle,
  onPrimaryButtonPress,
  onSecondaryButtonPress,
  children,
  subtitle,
  isLoading,
}: Props) => (
  <ModalWrapper testID="question-modal" isVisible={isVisible}>
    <View style={styles.header}>
      <View>
        <Text style={styles.title}>{title}</Text>
        {subtitle ? <Text style={styles.subtitle}>{subtitle}</Text> : null}
      </View>
      <TouchableOpacity onPress={onClose}>
        <CloseIcon />
      </TouchableOpacity>
    </View>
    {children || null}
    <View style={styles.footer}>
      {secondaryButtonTitle ? (
        <ButtonGeneral withMarginRight variant={ButtonVariant.Secondary} label={secondaryButtonTitle} onPress={onSecondaryButtonPress} />
      ) : null}
      {primaryButtonTitle ? <ButtonGeneral label={primaryButtonTitle || ''} onPress={onPrimaryButtonPress} isLoading={isLoading} /> : null}
    </View>
  </ModalWrapper>
);

export { QuestionModal };
