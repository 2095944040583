import { ModalContainer } from '@components/org/saved/modals/ModalContainer';
import { GenericModalLayout } from '@components/org/saved/modals/generic-modal-layout/GenericModalLayout';
import { Linking, Modal, Text, View } from 'react-native';

import { styles } from './styles';

type PaymentConfirmationModalProps = {
  visible: boolean;
  closeModal: () => void;
  onActionButtonPress: () => void;
  account: Account;
};

type Account = {
  name: string;
  number: string;
  sortCode: string;
};

export const PaymentConfirmationModal = ({ visible, closeModal, onActionButtonPress, account }: PaymentConfirmationModalProps) => {
  return (
    <Modal visible={visible} transparent>
      <ModalContainer>
        <GenericModalLayout
          title="Check your details are correct"
          closeModal={closeModal}
          hasButtonLayout
          buttonName="Proceed"
          onActionButtonPress={onActionButtonPress}
          withCancel={false}
          footerContainerStyle={{
            borderTopWidth: 0,
          }}
          actionButtonStyle={{
            paddingHorizontal: 35,
            paddingVertical: 5,
            borderRadius: 35,
          }}
        >
          <PaymentConfirmationModalContent account={account} />
        </GenericModalLayout>
      </ModalContainer>
    </Modal>
  );
};

const PaymentConfirmationModalContent = ({ account }: { account: Account }) => (
  <View style={styles.contents}>
    <Text style={styles.text}>
      Account Name: {account.name}
      {'\n'}Account Number: {account.number}
      {'\n'}Sort Code: {account.sortCode}
      {'\n\n'}Your payments will appear as 'RecruitmentNetwork' on your bank statements.
      {'\n\n'}You may cancel this Direct Debit at any time by contacting TRN or your bank.
      {'\n\n'}The Recruitment Network
      {'\n'}
      <Text style={styles.link} onPress={() => Linking.openURL('mailto:support@therecruitmentnetwork.com')}>
        support@therecruitmentnetwork.com
      </Text>
      {'\n'}
      <Text style={styles.link} onPress={() => Linking.openURL('tel:+448442728990')}>
        +44 (0)844 272 8990
      </Text>
    </Text>
  </View>
);
