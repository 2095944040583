import { ButtonGeneral, ButtonType } from '@components/general/button-general';
import { WelcomeIllustration } from '@components/general/icons/WelcomeIllustration';
import { AuthWrapper } from '@components/sign-up/auth-wrapper';
import { useUrlQuery } from '@hooks/useUrlQuery';
import { canSignUp } from '@utils/platform';
import React from 'react';
import { Image, Platform, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { styles } from './style';
import Logo from '../../../../assets/img/logo.png';

interface WrapProps {
  children: React.ReactNode;
}

const Wrap = ({ children }: WrapProps) => (Platform.OS === 'web' ? <AuthWrapper>{children}</AuthWrapper> : <View>{children}</View>);

const Welcome = () => {
  const query = useUrlQuery();
  const navigate = useNavigate();
  const redirect = query.get('redirect');

  return (
    <View style={styles.wrap}>
      {Platform.OS !== 'web' && <Image source={Logo} style={styles.logo} />}
      <Wrap>
        <View style={styles.wrapInner}>
          <WelcomeIllustration />
          <Text style={styles.text}>Be more profitable. Increase shareholder value. Scale your business.</Text>
          <View style={styles.buttonWrap}>
            <ButtonGeneral
              bold
              label="Log in"
              type={ButtonType.user}
              onPress={() => navigate(`/login${redirect ? `?redirect=${redirect}` : ''}`)}
              style={styles.submitButton}
            />
          </View>
          {canSignUp() ? (
            <View style={styles.registerWrap}>
              <Text style={styles.registerText}>Don’t have an account?&nbsp;</Text>
              <TouchableOpacity onPress={() => navigate('/signup/1')}>
                <Text style={styles.registerButton}>Register here</Text>
              </TouchableOpacity>
            </View>
          ) : null}
        </View>
      </Wrap>
      <View />
    </View>
  );
};

export { Welcome };
