import { EmployerGetUserQuery } from '@gql/generated/generated';
import { PageVariant } from '@utils/models';
import dayjs from 'dayjs';
import { useState } from 'react';
import { View } from 'react-native';

import { MemberDetails } from './member-details';
import { MemberInfo } from './member-info';
import { MemberInfoEdit } from './member-info/MemberInfoEdit';
import { styles } from './style';

interface MemberViewCardProps {
  variant?: PageVariant;
  isEmployer: boolean;
  currentMember?: EmployerGetUserQuery['employerGetUser'];
}

const MemberViewCard = ({ variant, isEmployer, currentMember }: MemberViewCardProps) => {
  const [editMode, setEditMode] = useState(false);

  const profileAvatar = currentMember?.profilePhotoUrl ? { uri: currentMember?.profilePhotoUrl } : undefined;
  const firstName = currentMember?.accountInfo?.firstName ?? '';
  const lastName = currentMember?.accountInfo?.lastName ?? '';
  const email = currentMember?.accountInfo?.email ?? '';
  const phoneNumber = currentMember?.accountInfo?.phoneNumber ?? '';
  const role = currentMember?.role?.isEmployer ? 'Admin' : 'Team member';
  const jobPosition = currentMember?.accountInfo?.companyInfo?.jobPosition ?? '';
  const lastLoggedInDate = currentMember?.lastLoggedInDate;
  const registrationDate = currentMember?.registrationDate;
  const isOnline = currentMember?.status?.isOnline;

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  return (
    <>
      {!editMode ? (
        <View style={[styles.container, variant === PageVariant.member && isEmployer ? styles.containerBorder : null]}>
          {currentMember ? (
            <>
              <MemberInfo
                avatar={profileAvatar}
                firstName={firstName}
                lastName={lastName}
                status={isOnline}
                tag={role}
                jobTitle={jobPosition}
                isEmployer={isEmployer}
                handleEditMode={handleEditMode}
              />
              <MemberDetails
                email={email}
                phone={phoneNumber}
                role={role}
                lastLogged={dayjs(lastLoggedInDate).format('DD/MM/YYYY')}
                memberSince={dayjs(registrationDate).format('DD/MM/YYYY')}
              />
            </>
          ) : null}
        </View>
      ) : (
        <MemberInfoEdit
          lastLogin={dayjs(lastLoggedInDate).format('DD/MM/YYYY')}
          memberSince={dayjs(registrationDate).format('DD/MM/YYYY')}
          handleEditMode={handleEditMode}
        />
      )}
    </>
  );
};

export { MemberViewCard };
