import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: 40,
    height: 40,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#4CA1DC',
  },
  photo: {
    width: '100%',
    height: '100%',
    borderRadius: 20,
  },
  initials: {
    color: '#FFFFFF',
    textTransform: 'uppercase',
    fontSize: 20,
    fontFamily: 'OpenSans_500Medium',
  },
});
