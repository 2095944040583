import { getRoadmapProgressColor } from '@utils/misc';
import React from 'react';
import { View } from 'react-native';
import Progress from 'react-native-circular-progress-indicator';

import { styles } from './style';

interface Props {
  percent: number;
  isTitleVisible?: boolean;
  isDisabled?: boolean;
}

const CircularProgress = ({ percent, isTitleVisible, isDisabled }: Props) => {
  const defaultColor = getRoadmapProgressColor(percent).color;
  const defaultFillColor = getRoadmapProgressColor(percent).fillColor;
  const color = isDisabled ? '#BDC1CC' : defaultColor;
  const fillColor = isDisabled ? '#BDC1CC' : defaultFillColor;
  return (
    <View>
      <Progress
        clockwise={false}
        value={percent}
        inActiveStrokeOpacity={0.4}
        inActiveStrokeColor={color}
        title={isTitleVisible ? `${percent}%` : ''}
        titleStyle={styles.title}
        activeStrokeColor={fillColor}
        activeStrokeWidth={20}
        inActiveStrokeWidth={20}
        initialValue={percent}
        showProgressValue={false}
      />
    </View>
  );
};

export { CircularProgress };
