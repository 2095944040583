import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { ISvgProps } from '../../../../types';

const LabelIcon = ({ color = '#fff', ...props }: ISvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} fill="none" {...props}>
    <Path
      fill={color}
      d="M8.889.097h5.333C15.204.097 16 .893 16 1.875v5.333c0 .683-.26 1.365-.781 1.886l-6.222 6.222a2.667 2.667 0 0 1-3.772 0L.781 10.872a2.667 2.667 0 0 1 0-3.772L7.003.878A2.659 2.659 0 0 1 8.89.097zm2.667 6.222a1.778 1.778 0 1 0 0-3.555 1.778 1.778 0 0 0 0 3.555z"
    />
  </Svg>
);

export { LabelIcon };
