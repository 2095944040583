import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const CheckIcon = (props: SvgProps) => (
  <Svg width={12} height={10} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m3.293 9.707-3-3a.999.999 0 1 1 1.414-1.414l2.236 2.236 6.298-7.18a.999.999 0 1 1 1.518 1.3l-7 8a1 1 0 0 1-.72.35 1.017 1.017 0 0 1-.746-.292Z"
      fill="#3d75c7"
    />
  </Svg>
);

export { CheckIcon };
