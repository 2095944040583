import { mediaQuery } from '@utils/misc';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  container: {
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    paddingTop: 16,
    paddingBottom: 16,
    [mediaQuery.forMobile]: {
      paddingTop: 0,
    },
  },
  leftWrap: {
    marginRight: 20,
    width: 228,
    position: 'relative',
    [mediaQuery.forTabletDown]: {
      display: 'none',
    },
  },
  rightWrap: {
    marginLeft: 20,
    width: 350,
    position: 'relative',
    [mediaQuery.forTabletDown]: {
      display: 'none',
    },
  },
});
