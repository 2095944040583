import { Checkbox } from '@shopify/polaris';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { Tab } from './FormikCategoriesAvailable';

interface FormikCategoriesDropdownProps {
  tabActive: Tab;
}

export const FormikCategoriesDropdown = ({ tabActive }: FormikCategoriesDropdownProps) => {
  const [mainCategories, setMainCategories] = useState([
    {
      name: 'Marketing',
      value: 'marketing',
      subCategories: [
        { name: 'Marketing Strategy', value: false },
        { name: 'Social Media', value: false },
        { name: 'Brand', value: false },
        { name: 'Content Marketing', value: false },
        { name: 'Marketing Measuring and Reporting', value: false },
        { name: 'Marketing Systems and Technology', value: false },
      ],
      displaySub: false,
    },
    {
      name: 'New Business Development',
      value: 'newBusinessDevelopment',
      subCategories: [
        { name: 'New Business Development Strategy', value: false },
        { name: 'Building a Sales Culture', value: false },
        { name: 'Tenders, Proposals and Pitches', value: false },
        { name: 'Market and Competitor Analysis', value: false },
        { name: 'New Business Development Data and Reporting', value: false },
        { name: 'Sales Systems and Technology', value: false },
      ],
      displaySub: false,
    },
    {
      name: 'Account Management',
      value: 'accountManagement',
      subCategories: [
        { name: 'Account Management Strategy', value: false },
        { name: 'Account Penetration', value: false },
        { name: 'Customer Retention', value: false },
        { name: 'The Client Experience', value: false },
        { name: 'Sales Systems and Technology', value: false },
      ],
      displaySub: false,
    },
    {
      name: 'Product and Service',
      value: 'productsServices',
      subCategories: [
        { name: 'Products and Services Strategy', value: false },
        { name: 'Contract Recruitment', value: false },
        { name: 'Talent Solutions and Reoccurring Revenues', value: false },
        { name: 'Search', value: false },
        { name: 'Pricing', value: false },
        { name: 'Packaging and Taking to Market', value: false },
      ],
      displaySub: false,
    },
    {
      name: 'Candidates',
      value: 'candidates',
      subCategories: [
        { name: 'Candidate Strategy', value: false },
        { name: 'Candidate Marketing', value: false },
        { name: 'Candidate Sourcing', value: false },
        { name: 'The Candidate Experience', value: false },
        { name: 'Candidate Engagement', value: false },
        { name: 'Candidate Systems and Technology', value: false },
      ],
      displaySub: false,
    },
    {
      name: 'People',
      value: 'people',
      subCategories: [
        { name: 'People Strategy', value: false },
        { name: 'Personal Effectiveness', value: false },
        {
          name: 'Leadership and Building a High Performance Team',
          value: false,
        },
        { name: 'Learning and Development', value: false },
        { name: 'Employee Engagement', value: false },
        { name: 'People Systems and Technology', value: false },
      ],
      displaySub: false,
    },
    {
      name: 'System and Technology',
      value: 'systemsTechnology',
      subCategories: [
        { name: 'Systems and Technology Strategy', value: false },
        { name: 'The Recruitment Tech Supplier Landscape', value: false },
        { name: 'Marketing Systems and Technology', value: false },
        { name: 'Sales Systems and Technology', value: false },
        { name: 'People Systems and Technology', value: false },
        { name: 'Candidate Systems and Technology', value: false },
        { name: 'Operations Systems and Technology', value: false },
      ],
      displaySub: false,
    },
    {
      name: 'Operations',
      value: 'operations',
      subCategories: [
        { name: 'Operational Strategy', value: false },
        { name: 'Finance', value: false },
        { name: 'Compliance, Legals and Terms', value: false },
        { name: 'Data and Analytics', value: false },
        { name: 'Process Management', value: false },
        { name: 'Operations Systems and Technology', value: false },
      ],
      displaySub: false,
    },
    {
      name: 'Business Strategy & Planning',
      value: 'businessStrategyPlanning',
      subCategories: [
        { name: 'Vision and Purpose', value: false },
        { name: 'Strategic Business Planning', value: false },
        { name: 'Creating Value and Exit Strategy', value: false },
      ],
      displaySub: false,
    },
  ]);

  const { values, setFieldValue } = useFormikContext<any>();

  const contentType = tabActive.toLowerCase();
  const categoriesAvailable = values?.categoriesAvailable;

  const addAllSubcategories = (newChecked: boolean, category: string, contentTypes: string[]) => {
    return contentTypes.reduce((updatedCategories, contentType) => {
      return {
        ...updatedCategories,
        [contentType]: {
          ...updatedCategories[contentType],
          [category]: newChecked ? mainCategories.find((el) => el.value === category)?.subCategories.map((sub) => sub.name) : [],
        },
      };
    }, categoriesAvailable);
  };

  const handleChangeMainCategory = (newChecked: boolean, category: string) => {
    if (contentType === 'all') {
      const updatedCategoriesAvailable = addAllSubcategories(newChecked, category, ['events', 'training', 'content']);
      setFieldValue('categoriesAvailable', updatedCategoriesAvailable);
    } else {
      const updatedCategoriesAvailable = addAllSubcategories(newChecked, category, [contentType]);
      setFieldValue('categoriesAvailable', updatedCategoriesAvailable);
    }
  };

  const updateCategoriesAvailable = (categoriesAvailable: any, contentTypes: string[], category: string, subcategory: string) => {
    return contentTypes.reduce((updatedCategories, contentType) => {
      return {
        ...updatedCategories,
        [contentType]: {
          ...updatedCategories[contentType],
          [category]: updatedCategories[contentType][category].includes(subcategory)
            ? updatedCategories[contentType][category].filter((item: string) => item !== subcategory)
            : [...updatedCategories[contentType][category], subcategory],
        },
      };
    }, categoriesAvailable);
  };

  const handleChangeSubcategories = (category: string, subcategory: string) => {
    if (contentType === 'all') {
      const updatedCategoriesAvailable = updateCategoriesAvailable(categoriesAvailable, ['events', 'training', 'content'], category, subcategory);
      setFieldValue('categoriesAvailable', updatedCategoriesAvailable);
    } else {
      const updatedCategoriesAvailable = updateCategoriesAvailable(categoriesAvailable, [contentType], category, subcategory);
      setFieldValue('categoriesAvailable', updatedCategoriesAvailable);
    }
  };

  const toggleSubcategories = (category: string) => {
    mainCategories.map((cat) => {
      if (cat.name === category) {
        cat.displaySub = !cat.displaySub;
        setMainCategories([...mainCategories]);
      }
    });
  };

  const checkSubcategoryTrueForAllContentTypes = (category: string, subcategory: string) => {
    const contentTypes = ['events', 'training', 'content'];

    return contentTypes.reduce((acc, el) => {
      return acc && categoriesAvailable[el][category]?.includes(subcategory);
    }, true);
  };

  const checkCategoryTrueForAllContentTypes = (category: string, totalSubcategories: number) => {
    const contentTypes = ['events', 'training', 'content'];

    return contentTypes.reduce((acc, el) => {
      return acc && categoriesAvailable[el][category]?.length === totalSubcategories;
    }, true);
  };
  return (
    <View style={{ marginTop: 20 }}>
      {mainCategories.map((category, index) => (
        <View key={index}>
          <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 2 }}>
            <Checkbox
              checked={
                contentType === 'all'
                  ? checkCategoryTrueForAllContentTypes(category.value, category.subCategories.length)
                  : categoriesAvailable[contentType][category.value]?.length === category.subCategories.length
              }
              onChange={(checked) => handleChangeMainCategory(checked, category.value)}
              value={category.value}
              id={category.name}
              name={category.name}
              label={category.name}
              labelHidden
            />
            <TouchableOpacity onPress={() => toggleSubcategories(category.name)} key={index}>
              <Text
                style={[
                  {
                    fontFamily: 'OpenSans_400Regular',
                    fontSize: 14,
                    lineHeight: 20,
                    marginLeft: 4,
                    color: '#202223',
                  },
                ]}
                key={index}
              >
                {category.name}
              </Text>
            </TouchableOpacity>
          </View>
          {category.displaySub && (
            <View style={{ marginLeft: 20 }}>
              {category.subCategories?.map((sub, index) => (
                <View style={{ flexDirection: 'row' }} key={index}>
                  <Checkbox
                    checked={
                      contentType === 'all'
                        ? checkSubcategoryTrueForAllContentTypes(category.value, sub.name)
                        : categoriesAvailable[contentType][category.value]?.includes(sub.name)
                    }
                    onChange={(checked, value) => handleChangeSubcategories(category.value, sub.name)}
                    value={sub.name}
                    id={sub.name}
                    name={sub.name}
                    label={sub.name}
                    labelHidden
                  />
                  <Text
                    style={[
                      {
                        fontFamily: 'OpenSans_400Regular',
                        fontSize: 14,
                        lineHeight: 20,
                        marginLeft: 4,
                      },
                      sub.value ? { color: '#8C9196' } : { color: '#202223' },
                    ]}
                  >
                    {sub.name}
                  </Text>
                </View>
              ))}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};
