import { DocumentAsset } from '@gql/generated/generated';
import { getBlob, ref } from 'firebase/storage';

import { storage } from '../firebase';

export const forceDownloadFirebaseFile = async (
  file: DocumentAsset & {
    downloadUrl: string;
  }
) => {
  const blob = await getBlob(ref(storage, file.downloadUrl));
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;

  a.download = file.name;
  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
