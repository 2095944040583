import React, { useState } from 'react';
import { GestureResponderEvent, Platform, TouchableOpacity, View } from 'react-native';

import { styles } from './style';

interface DotsButtonProps {
  onPress?: () => void;
}

interface WrapperProps extends DotsButtonProps {
  children: React.ReactNode;
}

const Wrapper = ({ children, onPress }: WrapperProps) => {
  const [isHovered, setIsHovered] = useState(false);
  if (Platform.OS === 'web') {
    return (
      <TouchableOpacity
        style={[styles.dotsContainer, isHovered && styles.dotsContainerHover]}
        // Hover states only applicable to web so we can ignore ts error below
        // @ts-ignore
        onMouseEnter={() => setIsHovered(true)}
        // @ts-ignore
        onMouseLeave={() => setIsHovered(false)}
        onPress={(e: GestureResponderEvent) => {
          e.preventDefault();
          onPress?.();
        }}
      >
        {children}
      </TouchableOpacity>
    );
  } else {
    return (
      <View
        style={[styles.dotsContainer, isHovered && styles.dotsContainerHover]}
        // Hover states only applicable to web so we can ignore ts error below
        // @ts-ignore
        onMouseEnter={() => setIsHovered(true)}
        // @ts-ignore
        onMouseLeave={() => setIsHovered(false)}
      >
        {children}
      </View>
    );
  }
};

const DotsButton = ({ onPress }: DotsButtonProps) => (
  <Wrapper onPress={onPress}>
    {[1, 2, 3].map((value, index) => (
      <View
        style={[
          styles.dot,
          {
            marginRight: index === 2 ? 0 : 2,
          },
        ]}
        key={value}
      />
    ))}
  </Wrapper>
);

export { DotsButton };
