import { TitleBox } from '@components/account/title-box';
import { ButtonGeneral, ButtonVariant } from '@components/general/button-general';
import { ChevronUpIcon, LeftArrow, RightArrow } from '@components/general/icons';
import { ContentBox } from '@components/general/layouts/content-box';
import { Row } from '@components/general/row';
import { TooltipWithChild } from '@components/general/tooltip';
import { OrgMemberTrainingProgress, useEmployerGetOrgTrainingProgressQuery } from '@gql/generated/generated';
import { useFeatureFlag } from '@hooks/useFeatureFlag';
import { FEATURE_FLAGS } from '@utils/featureFlags';
import React, { useState } from 'react';
import { ActivityIndicator, Text, View, TouchableOpacity } from 'react-native';
import { useNavigate } from 'react-router-native';

import { styles } from './style';
import { TrainingProgressCourse } from './training-progress-course';

function JobPosition({ title = '' }: { title: string | null | undefined }) {
  return (
    <View style={styles.jobPositionContainer}>
      <TooltipWithChild toolTipText={title} width={100}>
        <View style={[styles.textContainer, { alignItems: 'center' }]}>
          <Text numberOfLines={1} ellipsizeMode="tail" style={[styles.textContainerInfo, { width: 180 }]}>
            {title}
          </Text>
        </View>
      </TooltipWithChild>
    </View>
  );
}

function Fullname({ name = '' }: { name: string | null | undefined }) {
  return (
    <View style={styles.nameContainer}>
      <Text style={[styles.textContainer, styles.textContainerName]}>{name}</Text>
    </View>
  );
}

function DropdownButton({ isHidden, trainingProgressLength }: { isHidden: boolean; trainingProgressLength: number }) {
  return (
    <View
      style={[
        styles.dropdownButton,
        // @ts-ignore
        {
          transform: isHidden ? [{ rotate: '180deg' }] : 'unset',
          opacity: trainingProgressLength === 0 ? 0.6 : 1,
        },
      ]}
    >
      <ChevronUpIcon color="#0D1738" />
    </View>
  );
}

function TrainingProgressCard({ member }: { member: OrgMemberTrainingProgress }) {
  const [isHidden, setIsHidden] = useState(true);
  const fullname = `${member.firstName} ${member.lastName}`;

  function toggleHidden() {
    if (member.trainingsProgress?.length === 0) return;
    setIsHidden((prevState) => !prevState);
  }

  return (
    <View>
      <TouchableOpacity
        style={[
          styles.wrapper,
          {
            // @ts-ignore
            cursor: member.trainingsProgress?.length === 0 ? 'unset' : 'pointer',
          },
        ]}
        onPress={toggleHidden}
      >
        <View style={styles.rowContainer}>
          <Fullname name={fullname} />
          <JobPosition title={member?.jobPosition} />
          <Text>{member.trainingsProgress?.length}</Text>
          <DropdownButton isHidden={isHidden} trainingProgressLength={member.trainingsProgress?.length ?? 0} />
        </View>
      </TouchableOpacity>
      {!isHidden && (
        <View>
          {member.trainingsProgress?.map((trainingProgress) => (
            <TrainingProgressCourse key={`${member.userId}-${trainingProgress.trainingId}`} trainingProgress={trainingProgress} />
          ))}
        </View>
      )}
    </View>
  );
}

export function TrainingProgress() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { data: { employerGetOrgTrainingProgress } = {}, loading } = useEmployerGetOrgTrainingProgressQuery({
    variables: {
      input: {
        page,
        limit: 10,
      },
    },
  });

  const { membersTrainingsProgress, hasNextPage } = employerGetOrgTrainingProgress || {};

  const isTrainingProgressEnabled = useFeatureFlag([FEATURE_FLAGS.TRAINING_PROGRESS]);

  if (!isTrainingProgressEnabled) {
    navigate('/org/members');
  }

  if (loading || !membersTrainingsProgress) {
    return <ActivityIndicator size="large" color="#213470" style={{ paddingVertical: 20 }} />;
  }

  return (
    <ContentBox>
      <TitleBox title="Training Progress" backTitle="Back to Members" hasBottomBorder onPressBack={() => navigate(-1)} />
      <View style={styles.headerContainer}>
        <View style={styles.header}>
          <Text style={[styles.headerMainTitle, { width: 333 }]}>Name</Text>
          <Text style={[styles.headerTitle, { width: 330 }]}>Job Title</Text>
          <Text style={styles.headerTitle}>#Courses Enrolled</Text>
          <Text style={styles.headerButton} />
        </View>
      </View>
      <View style={styles.cardsWrapper}>
        <>
          {membersTrainingsProgress
            .filter(({ userId }) => userId)
            .map((membersTrainingsProgress) => (
              <TrainingProgressCard key={membersTrainingsProgress.userId} member={membersTrainingsProgress} />
            ))}
        </>
        <Row
          style={{
            justifyContent: 'flex-end',
            marginTop: 20,
            marginRight: 40,
          }}
        >
          <ButtonGeneral
            variant={ButtonVariant.Secondary}
            onPress={() => setPage(page - 1)}
            disabled={page === 1}
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          >
            <LeftArrow />
          </ButtonGeneral>
          <ButtonGeneral
            variant={ButtonVariant.Secondary}
            disabled={!hasNextPage}
            onPress={() => setPage(page + 1)}
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          >
            <RightArrow />
          </ButtonGeneral>
        </Row>
      </View>
    </ContentBox>
  );
}
