import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
  },
  commentItem: {
    marginTop: 12,
  },
  replyItem: {
    marginTop: 12,
    paddingLeft: 40,
  },
  ratingTotalText: {
    color: '#0D1738',
    fontSize: 16,
    fontFamily: 'OpenSans_600SemiBold',
    marginBottom: 16,
  },
});
