import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    fontSize: 14,
    color: '#0D1738',
    marginLeft: 18,
    fontFamily: 'OpenSans_400Regular',
  },
});
