import { ButtonGeneral, ButtonType } from '@components/general/button-general';
import { CompleteModal } from '@components/general/modals/complete-modal';
import { TooltipWithChild } from '@components/general/tooltip';
import { PulseScoreCircle } from '@components/roadmap/pulse-score-circle';
import { getRoadmapProgressColor } from '@utils/misc';
import React, { useState } from 'react';
import { Text, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { ids, styles } from './style';

interface Props {
  sectionId: string;
  subCategoryId?: string | null;
  title: string;
  score: number;
  percent: number | null;
  contentPieces: number;
  trainingCourses: number;
  feedbackTitle?: string;
  feedbackText?: string;
  maxScore: number;
  disabled?: boolean;
  disabledTooltip?: string;
  type: 'category' | 'subcategory';
}

const PulseCategoryItem = ({
  sectionId,
  title,
  score,
  percent,
  contentPieces,
  trainingCourses,
  feedbackTitle,
  feedbackText,
  maxScore,
  type,
  disabled = false,
  disabledTooltip = '',
  subCategoryId = null,
}: Props) => {
  const isSubCategory = type === 'subcategory';
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
  const navigate = useNavigate();

  function toggleFeedback() {
    if (!isSubCategory) return;
    setIsFeedbackModalVisible((prev) => !prev);
  }

  return (
    <View style={[styles.wrap, { borderTopColor: getRoadmapProgressColor((score / maxScore) * 100).color }]}>
      <View style={styles.topWrap}>
        <View style={styles.circularWrap} dataSet={{ media: ids.circularWrap }}>
          <PulseScoreCircle score={score} maxScore={maxScore} backgroundColor={getRoadmapProgressColor((score / maxScore) * 100).fillColor} />
        </View>
        <View style={styles.contentWrap} dataSet={{ media: ids.contentWrap }}>
          <Text style={styles.percentageText}> {percent === null || (contentPieces === 0 && trainingCourses === 0) ? '-' : `${percent}%`}</Text>
          <View style={styles.progressWrap}>
            <View style={[styles.progressInner, { width: `${percent}%` }]} />
          </View>

          <Text style={styles.doneText}>
            <Text style={styles.bold}>{contentPieces}</Text> content pieces
            {'\n'}
            <Text style={styles.bold}>{trainingCourses}</Text> training courses
          </Text>
        </View>
      </View>
      <View style={styles.bottomWrap} dataSet={{ media: ids.bottomWrap }}>
        <Text style={styles.title}>{title}</Text>
        <View style={styles.btnWrap}>
          {!isSubCategory ? (
            <View style={styles.eachButtonWrap}>
              <TooltipWithChild toolTipText={disabled ? disabledTooltip : ''}>
                <ButtonGeneral disabled={disabled} label="View plan" type={ButtonType.user} onPress={() => navigate(`${sectionId}`)} />
              </TooltipWithChild>
            </View>
          ) : (
            <View style={styles.eachButtonWrap}>
              <ButtonGeneral label="Content" type={ButtonType.user} onPress={() => navigate(`${subCategoryId}`)} />
              {feedbackTitle && feedbackText && (
                <>
                  <View style={{ marginTop: 5 }} />
                  <ButtonGeneral label="Feedback" type={ButtonType.user} onPress={toggleFeedback} />
                </>
              )}
            </View>
          )}
        </View>
      </View>
      <CompleteModal
        isVisible={isFeedbackModalVisible}
        onClose={() => setIsFeedbackModalVisible(false)}
        title={feedbackTitle || ''}
        htmlString={feedbackText}
      />
    </View>
  );
};

export { PulseCategoryItem };
