import { ISubmodule } from '@utils/models';
import { useState } from 'react';

const useRoadmapSubmodules = () => {
  const [submodule, setSubmodule] = useState<ISubmodule>();
  const [isVisible, setIsVisible] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);

  const handlePlay = (submodule: ISubmodule) => {
    setSubmodule(submodule);
    setIsVisible(true);
  };

  const handleStart = () => {
    setIsPreviewModalVisible(false);
    setIsVisible(true);
  };

  return {
    submodule,
    isVisible,
    isPreviewModalVisible,
    handlePlay,
    handleStart,
    setIsPreviewModalVisible,
    setIsVisible,
  };
};

export { useRoadmapSubmodules };
