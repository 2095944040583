import { mediaQuery } from '@utils/misc';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  topRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
    [mediaQuery.forMobile]: {
      flexDirection: 'column',
    },
  },
  star: {
    height: 15,
    width: 15,
    marginRight: 8,
  },
  subduedText: {
    fontFamily: 'OpenSans_400Regular',
    color: '#6D7175',
    fontSize: 12,
    lineHeight: 16,
  },
  subHeading: {
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 12,
    lineHeight: 16,
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
  },
  shareContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 4,
    [mediaQuery.forMobile]: {
      marginLeft: 0,
    },
  },
  categoryContainer: {
    flexWrap: 'wrap',
    marginBottom: 8,
  },
  jobRolesContainer: {
    flexWrap: 'wrap',
    flex: 1,
    marginBottom: 20,
  },
  pillContainer: {
    flex: 1,
    gap: 6,
    marginBottom: 6,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  countsContainer: {
    marginTop: 4,
  },
  webIcon: {
    marginRight: 10,
  },
});
