import { User } from '@gql/generated/generated';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useLocation } from 'react-router-native';

type HubSpotUserData = {
  email?: string;
  trnw_account_type?: string | null;
  trnw_job_position?: string | null;
  trnw_phone_number?: string | null;
  trnw_subscription_plan?: string | null;
};

const getHubSpotUserData = (user: User): HubSpotUserData => {
  const { accountInfo, accountType, subscriptionDetails } = user;
  const companyInfo = accountInfo?.companyInfo;
  const email = accountInfo?.email;
  const phoneNumber = accountInfo?.phoneNumber;
  const jobPosition = companyInfo?.jobPosition;
  const subscriptionPlanType = subscriptionDetails?.subscriptionPlanType;
  return {
    // NB this is the protected 'email' property on a hubspot contact
    email,
    // NB other fields are prefixed with `trnw_` to avoid accidental overwrites. They are also grouped separately in hubspot contacts as `TRNW user data`
    trnw_account_type: accountType,
    trnw_job_position: jobPosition,
    trnw_phone_number: phoneNumber,
    trnw_subscription_plan: subscriptionPlanType,
  };
};

export const useHubSpotSession = (user: User | undefined) => {
  const [hubSpotSessionLoggedIn, setHubSpotSessionLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    if (Platform.OS === 'web') {
      // NB for web app window._hsq is set for the first time in index.html
      const hsq = window._hsq;
      if (user) {
        const { email, trnw_account_type, trnw_job_position, trnw_phone_number, trnw_subscription_plan } = getHubSpotUserData(user as User);

        if (!hubSpotSessionLoggedIn) {
          setHubSpotSessionLoggedIn(true);
          // use 'identify' to create hubspot contacts for existing users that don't yet have contacts
          // NB new user hubspot contacts are created in the signUpResolver on the backend
          hsq.push([
            'identify',
            {
              email,
              trnw_account_type,
              trnw_job_position,
              trnw_phone_number,
              trnw_subscription_plan,
            },
          ]);
        }
      } else {
        if (hubSpotSessionLoggedIn) {
          setHubSpotSessionLoggedIn(false);
        }
      }
    }
  }, [user, hubSpotSessionLoggedIn]);
};

export const useHubSpotPageTracking = () => {
  const location = useLocation();
  useEffect(() => {
    if (Platform.OS === 'web') {
      // NB for web app window._hsq is set for the first time in index.html
      const hsq = window._hsq;
      hsq.push(['setPath', location.pathname]);
      hsq.push(['trackPageView']);
    }
  }, [location]);
};

// ********************************
// NB CustomBehavioralEvents are only available with Marketing Hub Enterprise accounts.
// TRNW currently only has Marketing Hub Professional account
// Leaving this code in for potential account upgrade
export enum HubspotCustomBehavioralEvent {
  Login = 'Login',
  UserCreated = 'UserCreated',
}
export const trackHubspotCustomBehavioralEvent = <T>(name: HubspotCustomBehavioralEvent, properties: T) => {
  // TODO integrate hubspot for apps
  if (Platform.OS === 'web') {
    // NB for web app window._hsq is set for the first time in index.html
    const hsq = window._hsq;
    hsq.push(['trackCustomBehavioralEvent', { name, properties }]);
  }
};
