import { useToast } from '@context/ToastContext';
import { ContextualSaveBar } from '@shopify/polaris';
import { useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-native';

interface FormikSaveBarProps {
  successMessage?: string;
}

const FormikSaveBar = ({ successMessage }: FormikSaveBarProps) => {
  const navigate = useNavigate();
  const { values, submitForm, errors, isSubmitting } = useFormikContext();
  const [showSaveBar, setShowSaveBar] = useState(false);
  const { addToast } = useToast();

  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      setShowSaveBar(true);
    }
    didMountRef.current = true;
  }, [values]);

  const flattenArray = (input: (string | object)[]): string[] => {
    const result: string[] = [];

    const flattenHelper = (arr: (string | object)[]): void => {
      for (const item of arr) {
        if (typeof item === 'string') {
          result.push(item);
        } else if (typeof item === 'object' && !Array.isArray(item)) {
          for (const key in item) {
            if (typeof (item as any)[key] === 'string') {
              result.push((item as any)[key]);
            } else if (Array.isArray((item as any)[key])) {
              flattenHelper((item as any)[key]);
            } else if (typeof (item as any)[key] === 'object' && !Array.isArray((item as any)[key])) {
              flattenHelper([(item as any)[key]]);
            }
          }
        } else if (Array.isArray(item)) {
          flattenHelper(item);
        }
      }
    };
    flattenHelper(input);
    return result;
  };

  const handleClickSave = async () => {
    if (Object.keys(errors).length > 0) {
      const errorString = flattenArray(Object.values(errors));

      addToast('error', `There was an error with your request. ${errorString.join(' ')}`);
    } else {
      try {
        await submitForm();
        setShowSaveBar(false);
        if (successMessage) {
          addToast('success', successMessage);
        }
      } catch (e) {
        console.log(e);
        addToast('error', `There was an error with your request. ${e}`);
      }
    }
  };

  const handleDiscard = () => {
    setShowSaveBar(false);
    navigate(-1);
  };

  return (
    <>
      {showSaveBar ? (
        <ContextualSaveBar
          fullWidth
          message="Unsaved changes"
          saveAction={{
            onAction: handleClickSave,
            loading: isSubmitting,
            disabled: false,
          }}
          discardAction={{
            onAction: handleDiscard,
          }}
        />
      ) : null}
    </>
  );
};

export { FormikSaveBar };
