import { Text, View } from 'react-native';

import { styles } from './styles';

const AllMembersHeader = () => {
  return (
    <View style={styles.headerContainer}>
      <View style={styles.header}>
        <Text style={styles.headerMainTitle}>Name</Text>
        <Text style={styles.headerTitle}>Job Title</Text>
        <Text style={styles.headerTitle}>Last Logged in</Text>
        <Text style={styles.headerTitle}>Member since</Text>
        <Text style={styles.headerButton} />
      </View>
    </View>
  );
};

export { AllMembersHeader };
