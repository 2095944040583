/**
 * When removing null or undefined values from arrays we can use .filter(item => item != null)
 * but TypeScript doesn't change the type. Use this function to filter out null and undefined
 * values and change the type. Example:
 *
 * ```
 * const array: (number | null)[] = [0, 1, null, 2]
 * const notNullArray = array.filter(isNotNull) // type is number[]
 * const stillNullArray = array.filter(item => item != null) // type is (number | null)[]
 * ```
 */
export function isNotNull<T>(item: T | null | undefined): item is T {
  return item != null;
}
