import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { ISvgProps } from 'types';

export const ChevronLeftMirror = ({ color = '#5C5F62', ...props }: ISvgProps) => (
  <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 16a.997.997 0 0 1-.707-.293l-5-5a.999.999 0 0 1 0-1.414l5-5a.999.999 0 1 1 1.414 1.414L8.414 10l4.293 4.293A.999.999 0 0 1 12 16Z"
      fill="#fff"
    />
  </Svg>
);
