import { CloseIconSmall } from '@components/general/icons/search';
import { IndexPageHeader } from '@components/general/index-page-header';
import { TabTitle } from '@components/general/tab-title';
import React, { useState } from 'react';
import { Modal, TouchableOpacity, View } from 'react-native';

import { NotificationItem } from './notification-item';
import { styles } from './style';

enum TabType {
  all = 'All',
  unread = 'Unread',
}

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const NotificationBox = ({ isVisible, onClose }: Props) => {
  const [activeTab, setActiveTab] = useState(TabType.all);
  return (
    <Modal visible={isVisible} animationType="slide">
      <IndexPageHeader
        isBackButtonHidden
        title="Notifications"
        rightContent={
          <TouchableOpacity onPress={onClose}>
            <CloseIconSmall color="#0D1738" />
          </TouchableOpacity>
        }
      />
      <View style={styles.tabWrap}>
        <View style={styles.tabItems}>
          <TabTitle title={TabType.all} active={activeTab === TabType.all} onPress={() => setActiveTab(TabType.all)} />
          <TabTitle title={TabType.unread} active={activeTab === TabType.unread} onPress={() => setActiveTab(TabType.unread)} />
        </View>
      </View>
      <NotificationItem
        image="https://kaboompics.com/cache/3/2/6/5/5/326557238f3358ad59f9997b7fa01769dffd72cf.jpeg"
        title="The Art of Negotiation"
        description="Access for Success Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        bottomText="Events • 12 Aug, 12:34 • London"
        onPress={() => {}}
      />
    </Modal>
  );
};

export { NotificationBox };
