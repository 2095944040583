import { ButtonGeneral } from '@components/general/button-general';
import { UploadIcon } from '@components/general/icons/org-icons';
import { styles } from '@components/org/analytics/analytics-header/style';
import React from 'react';
import { Text } from 'react-native';
import XLSX from 'xlsx';

import { CellData, ExcelData, variants } from './ExcelSheetDownloadTypes';

const ExcelSheetDownload: React.FC<ExcelData> = ({ sheets, variant = 'secondary' }) => {
  return (
    <ButtonGeneral
      variant={variants[variant].variant}
      onPress={() => {
        const workbook = XLSX.utils.book_new();
        sheets.forEach((sheet) => {
          const sheetData: CellData[][] = [[]];
          sheet.columns.forEach((col) => {
            sheetData[0].push(col.label);
          });
          sheet.data?.forEach((values) => {
            const row: CellData[] = [];
            sheetData.push(row);
            sheet.columns.forEach((cols) => {
              row.push(values[cols.value]);
            });
          });
          const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
          XLSX.utils.book_append_sheet(workbook, worksheet, sheet.name);
        });

        const contents = XLSX.write(workbook, { type: 'base64' });
        const element = document.createElement('a');
        element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + contents);
        element.setAttribute('download', 'Download.xlsx');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      }}
    >
      <UploadIcon fill={variants[variant].iconFillColor} />
      <Text style={{ ...styles.exportText, color: variants[variant].color }}>Export</Text>
    </ButtonGeneral>
  );
};

export { ExcelSheetDownload };
