import { truncateString, camelizeKebab } from '@utils/misc';
import parse, { DOMNode, domToReact, HTMLReactParserOptions } from 'html-react-parser';
import { Text, View } from 'react-native';

import { styles } from './style';

interface HtmlParserProps {
  htmlString: string;
  isTruncated?: boolean;
  htmlStyleWhitelist?: string[] | undefined;
}

type Headers = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

function HtmlParser({ htmlString, isTruncated, htmlStyleWhitelist }: HtmlParserProps) {
  const options: HTMLReactParserOptions = {
    // @ts-ignore - the type definition for replace is incorrect, the Element type is not recognized
    replace: ({ name, attribs, children }: DOMNode) => {
      let attribsStyle = {};
      if (htmlStyleWhitelist && name && attribs.style) {
        attribsStyle = attribs.style
          .split(';')
          .filter((s: string) => !!s)
          .reduce((acc: Record<string, string>, curr: string) => {
            const [key, value] = curr.split(':');
            const styleKey = camelizeKebab(key);
            if (htmlStyleWhitelist?.includes(styleKey)) {
              acc[styleKey] = value?.trim();
            }
            return acc;
          }, {});
      }

      const blockStyle: Record<string, string | undefined> = { display: 'block' };
      let headerStyle = {};
      switch (name) {
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
        case 'h6':
          headerStyle = { ...styles[name as Headers], ...attribsStyle, ...blockStyle };
          return <Text style={headerStyle}>{domToReact(children, options)}</Text>;
        case 'p':
          return (
            /* 
              Converting to native <Text> component does not properly render margins on web
              in this case, so we need to use <p> instead.
            */
            <p style={{ ...styles.paragraph, ...attribsStyle, ...blockStyle }}>{domToReact(children, options)}</p>
          );
        case 'span':
          return <span style={{ ...styles.paragraph, ...attribsStyle }}>{domToReact(children, options)}</span>;
        case 'img':
          return <img src={attribs.src} style={{ ...attribsStyle, maxWidth: '100%' }} />;
        case 'table':
          return (
            <div style={styles.tableWrapper}>
              <table style={{ ...styles.table, ...attribsStyle }}>{domToReact(children, options)}</table>
            </div>
          );
        default:
          break;
      }
    },
  };

  const parsedHtml = parse(htmlString, options);

  const truncatedHtml = isTruncated ? truncateString(Array.isArray(parsedHtml) ? parsedHtml[0].props.children : parsedHtml, 70) : parsedHtml;

  return <View>{truncatedHtml}</View>;
}

export { HtmlParser };
