import { ModalContainer } from '@components/org/saved/modals/ModalContainer';
import { GenericModalLayout } from '@components/org/saved/modals/generic-modal-layout/GenericModalLayout';
import { Modal, Text, View } from 'react-native';

import { styles } from './styles';

type PaymentSuccessModalProps = {
  visible: boolean;
  closeModal: () => void;
};

export const PaymentSuccessModal = ({ visible, closeModal }: PaymentSuccessModalProps) => {
  return (
    <Modal visible={visible} transparent>
      <ModalContainer>
        <GenericModalLayout
          title="Direct Debit set up successfully"
          closeModal={closeModal}
          hasButtonLayout
          buttonName="Close"
          onActionButtonPress={closeModal}
          withCancel={false}
          footerContainerStyle={{
            borderTopWidth: 0,
          }}
          actionButtonStyle={{
            paddingHorizontal: 35,
            paddingVertical: 5,
            borderRadius: 35,
          }}
        >
          <View style={styles.contents}>
            <Text style={styles.text}>
              You will receive a notification via email confirming your Direct Debit has been set up within 3 working days.
            </Text>
          </View>
        </GenericModalLayout>
      </ModalContainer>
    </Modal>
  );
};
