import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const ClockIcon = (props: SvgProps) => (
  <Svg width={16} height={16} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8Zm2.293-4.707A.997.997 0 0 1 9.586 11L7.293 8.707A.997.997 0 0 1 7 8V4a1 1 0 1 1 2 0v3.586l2 2a.999.999 0 0 1-.707 1.707Z"
      fill="#0D1738"
    />
  </Svg>
);

export { ClockIcon };
