import { ActivityIndicatorTRN } from '@components/general/activity-indicator/ActivityIndicator';
import { PulseCategoryItem } from '@components/roadmap/pulse-category-item';
import { PulseResultWrapper } from '@components/roadmap/pulse-result-wrapper';
import { RoadmapPulseContext } from '@context/RoadmapPulseContext';
import { useContext, useMemo } from 'react';
import { View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { ids, styles } from './style';
import { getCategoryRecommendation, getQuestionScore } from './utils';

export const PulseResult = () => {
  const navigate = useNavigate();
  const roadmapPulseContext = useContext(RoadmapPulseContext);
  if (!roadmapPulseContext) throw new Error('You must provide roadmap pulse context');

  const { scoreAssns, selectedPulse, isLoading } = roadmapPulseContext;

  const categoryScores = useMemo(() => {
    if (!selectedPulse) {
      return null;
    }
    return selectedPulse.sections.map((category) => {
      const totalCategoryScore = category.questions.reduce((acc, question) => {
        acc += getQuestionScore(question);
        return acc;
      }, 0);
      return {
        name: category.name,
        score: totalCategoryScore,
      };
    });
  }, [selectedPulse]);

  const hasScoreForCategories = useMemo(() => {
    if (!selectedPulse) return [];
    return selectedPulse?.sections.map((s) => ({
      name: s.name,
      hasAnsweredAll: s.questions.every((q) => q.answer?.value),
    }));
  }, [selectedPulse]);

  const totalScore = useMemo(() => {
    if (!categoryScores) return 0;
    return categoryScores.reduce((acc, category) => {
      acc += category.score;
      return acc;
    }, 0);
  }, [categoryScores]);

  const title = useMemo(() => {
    let str = 'Pulse Results';
    if (selectedPulse?.createdAt) {
      const date = new Date(selectedPulse.createdAt).toLocaleDateString();
      str += ` - ${date}`;
    }
    return str;
  }, [selectedPulse?.createdAt]);

  function goToPulse() {
    navigate('/roadmap/pulse');
  }

  if (isLoading) {
    return <ActivityIndicatorTRN isFullscreen />;
  }

  return (
    <PulseResultWrapper title={title} scoreOutOf100={totalScore} onBack={goToPulse}>
      <View style={styles.itemsWrap} dataSet={{ media: ids.itemsWrap }}>
        {!isLoading &&
          categoryScores &&
          scoreAssns.map((scoreAssn) => {
            const maxScore = scoreAssn.subCategories.length * 5;
            const totalCategoryScoreIndex = categoryScores.findIndex((c) => c.name === scoreAssn.name);
            const { content, trainings } = getCategoryRecommendation(scoreAssn.name, categoryScores, scoreAssns, selectedPulse);
            const hasScoreForCategory = hasScoreForCategories.find((c) => c.name === scoreAssn.name);
            return (
              <PulseCategoryItem
                key={scoreAssn.name}
                sectionId={scoreAssn.name}
                title={scoreAssn.name}
                score={categoryScores?.[totalCategoryScoreIndex]?.score ?? 0}
                percent={null}
                contentPieces={content.length}
                trainingCourses={trainings.length}
                maxScore={maxScore}
                disabled={!hasScoreForCategory?.hasAnsweredAll}
                disabledTooltip="You must answer all of the questions for this given category to view the plan"
                type="category"
              />
            );
          })}
      </View>
    </PulseResultWrapper>
  );
};
