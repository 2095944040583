import { COMMENT_FRAGMENT, COMMENT_REPLY_FRAGMENT } from '@gql/Comments/comments';
import { ContentType, useCreateCommentMutation, useGetCommentsMutation } from '@gql/generated/generated';

export const useComments = ({
  totalComments,
  commentedItemType,
  commentedItemId,
}: {
  totalComments: number;
  commentedItemType: ContentType;
  commentedItemId: string;
}) => {
  const [createComment, { loading: submittingComment }] = useCreateCommentMutation({
    update: (store, { data: postedComment }) => {
      const newComment = postedComment?.createComment;
      if (!newComment) return;
      const type = newComment.commentedItemType;
      store.modify({
        id: `${type}:${newComment.commentedItemId}`,
        fields: {
          userHasCommented() {
            return true;
          },
          commentCount() {
            if (postedComment.createComment.text.length > 0) {
              return totalComments + 1;
            }
            return 0;
          },
          comments(existingCommentRefs = [], { readField }) {
            const newCommentRef = store.writeFragment({
              fragment: COMMENT_FRAGMENT,
              data: newComment,
            });
            if (existingCommentRefs.some((ref: { ref: string }) => readField('id', ref) === newComment.id)) {
              return existingCommentRefs;
            }
            return [newCommentRef, ...existingCommentRefs];
          },
        },
      });
    },
  });

  const [fetchMoreComments, { loading: loadingMoreComments }] = useGetCommentsMutation({
    update: (store, { data: comment }) => {
      const newComments = comment?.getComments;
      if (!newComments) return;
      store.modify({
        id: `${commentedItemType}:${commentedItemId}`,
        fields: {
          comments(existingCommentRefs = []) {
            const newCommentRefs = newComments.map((comment) =>
              store.writeFragment({
                fragment: COMMENT_REPLY_FRAGMENT,
                data: comment,
              })
            );

            return [...existingCommentRefs, ...newCommentRefs];
          },
        },
      });
    },
  });

  return {
    createComment,
    submittingComment,
    loadingMoreComments,
    fetchMoreComments,
  };
};
