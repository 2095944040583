import { Platform } from 'react-native';

import { Assignment as AssignmentWeb } from './Assignment';
import { AssignmentNative } from './Assignment.native';

const Assignment = Platform.select({
  native: () => AssignmentNative,
  default: () => AssignmentWeb,
})();

export { Assignment };
