import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    padding: 20,
  },
  description: {
    fontSize: 14,
    color: '#0D1738',
    marginBottom: 20,
    fontFamily: 'OpenSans_400Regular',
  },
  title: {
    fontSize: 20,
    color: '#0D1738',
    marginBottom: 8,
    fontFamily: 'OpenSans_600SemiBold',
  },
});
