import { mediaQuery } from '@utils/misc';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrapper: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  contentBox: {
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 80,
    paddingRight: 80,
    [mediaQuery.forTabletDown]: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  backText: {
    color: '#2C6ECB',
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    textDecorationLine: 'underline',
  },
  headingWrap: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 40,
    [mediaQuery.forTabletDown]: {
      marginBottom: 20,
    },
  },
  title: {
    fontFamily: 'OpenSans_600SemiBold',
    color: '#0D1738',
    fontSize: 26,
    marginBottom: 4,
    width: 'max-content',
  },
  description: {
    fontSize: 14,
    color: '#0D1738',
    fontFamily: 'OpenSans_400Regular',
    width: 'max-content',
  },
  infoWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  infoPercentage: {
    fontFamily: 'OpenSans_600SemiBold',
    color: '#0D1738',
    fontSize: 26,
    marginRight: 4,
  },
  infoComplete: {
    color: '#8B9197',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    marginRight: 4,
  },
  tooltipText: {
    fontSize: 14,
    color: '#0D1738',
    fontFamily: 'OpenSans_400Regular',
  },
});
