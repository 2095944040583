import { calculateContainerMinHeight } from '@components/back-office/tables/generic-table/utils';
import { ButtonGeneral } from '@components/general/button-general';
import { ContentBox } from '@components/general/layouts/content-box';
import { useToast } from '@context/ToastContext';
import { useBulkUpdateMutation } from '@gql/generated/generated';
import { Pagination, useIndexResourceState } from '@shopify/polaris';
import React, { FC } from 'react';
import { View, Text, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useNavigate } from 'react-router-native';

import { GridFilters } from './GridFilters';
import { styles } from './styles';
import { useQueryFilters } from './useGridFilters';
import { GenericTable } from '../tables/generic-table';

interface NewLink {
  url: string;
  label: string;
  title: string;
}

export interface IPagination {
  perPage: number;
  page: number;
  totalItems?: number;
  hasNextPage?: boolean | null;
}

interface MenuItem {
  id: string;
  content: string;
}
interface IGridPage {
  title: string;
  newLink?: NewLink;
  rows: any[];
  loading: boolean;
  tableHeadings: any;
  pagination: IPagination;
  activeFilters: string[];
  isUsersTable?: boolean;
  menuItems: MenuItem[];
  onBulkAction?: () => void;
  onMenuItem: (id: string, optionId: string) => void;
}

const PaginationFilter = ({ pagination }: { pagination: IPagination }) => {
  const { page, setFilterValue } = useQueryFilters(['page']);

  const value = page || 1;
  return (
    <Pagination
      hasPrevious={value > 1}
      onPrevious={() => setFilterValue('page', value - 1)}
      hasNext={!!pagination.hasNextPage}
      onNext={() => setFilterValue('page', value + 1)}
    />
  );
};

export const GridPage: FC<IGridPage> = ({
  title,
  newLink,
  rows,
  loading,
  tableHeadings,
  pagination,
  activeFilters,
  isUsersTable,
  onBulkAction,
  onMenuItem,
  menuItems,
}) => {
  const navigation = useNavigate();
  const [bulkUpdate] = useBulkUpdateMutation();
  const containerMinHeight = calculateContainerMinHeight();
  const { addToast } = useToast();

  const { selectedResources, allResourcesSelected, handleSelectionChange, clearSelection } = useIndexResourceState(rows);

  const handleBulkAction = async (action: string) => {
    if (!selectedResources.length) {
      return;
    }
    const response = await bulkUpdate({
      variables: {
        ids: selectedResources,
        action,
        section: title,
      },
    });

    if (response?.data?.bulkUpdate.__typename === 'Success' && response?.data?.bulkUpdate.message) {
      addToast('success', response?.data?.bulkUpdate.message);
    }

    clearSelection();
    !!onBulkAction &&
      setTimeout(() => {
        onBulkAction();
      }, 5000);
  };

  return (
    <View style={{ flexDirection: 'column' }}>
      <View style={[styles.headerWrapper]}>
        <View style={styles.headerTitle}>
          <Text style={styles.title}>{title}</Text>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
          {isUsersTable && <ButtonGeneral label="Membership Request" bold withMarginRight onPress={() => navigation('/users/member-request')} />}
          {newLink && (
            <TouchableOpacity
              onPress={() =>
                navigation(newLink.url, {
                  state: { title: newLink.title },
                })
              }
              style={styles.createBtnWrapper}
            >
              <Text style={styles.createBtn}>{newLink.label}</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
      <ContentBox style={{ padding: 16 }}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            gap: 100,
          }}
        >
          <GridFilters
            activeFilters={activeFilters}
            handleBulkAction={onBulkAction ? handleBulkAction : undefined}
            isUsersTable={!!isUsersTable}
            selectedResources={selectedResources}
          />
          <View>
            <PaginationFilter pagination={pagination} />
          </View>
        </View>
        <View style={{ marginTop: 16 }} />
        {rows && rows.length > 0 ? (
          <GenericTable
            customMenuItems={menuItems}
            headings={tableHeadings}
            rows={rows}
            resourceName={{ singular: 'content', plural: 'content' }}
            handleMenuClick={onMenuItem}
            itemsPerPage={pagination.perPage}
            selectedResources={selectedResources}
            allResourcesSelected={allResourcesSelected}
            handleSelectionChange={handleSelectionChange}
            currentPage={pagination.page}
            totalItems={pagination.totalItems}
            withCheckbox={!!onBulkAction}
          />
        ) : null}
        {loading ? (
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: containerMinHeight,
            }}
          >
            <ActivityIndicator size="large" color="#213470" />
          </View>
        ) : null}
      </ContentBox>
    </View>
  );
};
