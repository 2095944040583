import { FilterListItem } from '@components/filter-mobile/filter-list-item';
import { Length } from '@components/filter-mobile/filter-options/Length';
import { Category, ICategory } from '@components/filter-mobile/filter-options/category';
import { ContentType, ContentTypeOptions } from '@components/filter-mobile/filter-options/content-type';
import { Date } from '@components/filter-mobile/filter-options/date';
import { Duration, DurationOptions } from '@components/filter-mobile/filter-options/duration';
import { EventType, EventTypeOptions } from '@components/filter-mobile/filter-options/event-type';
import { JobRole, JobRoleOptions } from '@components/filter-mobile/filter-options/job-role';
import { MemberOptions, MemberType } from '@components/filter-mobile/filter-options/member-type';
import { Popularity } from '@components/filter-mobile/filter-options/popularity';
import { SortBy, SortOptions } from '@components/filter-mobile/filter-options/sort-by';
import { FilterWrapper } from '@components/filter-mobile/filter-wrapper';
import { FilterIcon } from '@components/general/icons/training-icons';
import { FilterOptions } from '@utils/models';
import React, { useState } from 'react';
import { ActivityIndicator, FlatList, Text, TouchableOpacity } from 'react-native';

import { styles } from './styles';

export interface IFilterData {
  sortBy?: SortOptions;
  memberTypes?: MemberOptions[];
  jobRoles?: JobRoleOptions[];
  contentTypes?: ContentTypeOptions[];
  category?: ICategory[];
  date?: { from?: Date; to?: Date };
  popularity?: number;
  length?: { min: string; max: string };
  eventTypes?: EventTypeOptions[];
  duration?: DurationOptions[];
}

interface Props {
  filterOptions: FilterOptions[];
  onSubmit: (filterData: IFilterData) => void;
  isLoading?: boolean;
}

const FilterModal = ({ filterOptions, onSubmit, isLoading }: Props) => {
  const [filterData, setFilterData] = useState<IFilterData>({});
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [isSortByVisible, setIsSortByVisible] = useState(false);
  const [isMemberTypeVisible, setIsMemberTypeVisible] = useState(false);
  const [isJobRoleVisible, setIsJobRoleVisible] = useState(false);
  const [isContentTypeVisible, setIsContentTypeVisible] = useState(false);
  const [isCategoryVisible, setIsCategoryVisible] = useState(false);
  const [isDateVisible, setIsDateVisible] = useState(false);
  const [isPopularityVisible, setIsPopularityVisible] = useState(false);
  const [isLengthVisible, setIsLengthVisible] = useState(false);
  const [isEventTypesVisible, setIsEventTypesVisible] = useState(false);
  const [isDurationVisible, setIsDurationVisible] = useState(false);

  const handleOptionSelect = (item: FilterOptions) => {
    switch (item) {
      case FilterOptions.sortBy:
        setIsSortByVisible(true);
        break;
      case FilterOptions.memberType:
        setIsMemberTypeVisible(true);
        break;
      case FilterOptions.jobRole:
        setIsJobRoleVisible(true);
        break;
      case FilterOptions.contentType:
        setIsContentTypeVisible(true);
        break;
      case FilterOptions.category:
        setIsCategoryVisible(true);
        break;
      case FilterOptions.date:
        setIsDateVisible(true);
        break;
      case FilterOptions.popularity:
        setIsPopularityVisible(true);
        break;
      case FilterOptions.length:
        setIsLengthVisible(true);
        break;
      case FilterOptions.eventType:
        setIsEventTypesVisible(true);
        break;
      case FilterOptions.duration:
        setIsDurationVisible(true);
        break;
      default:
        break;
    }
  };

  const handleModalClose = () => {
    setIsSortByVisible(false);
    setIsMemberTypeVisible(false);
    setIsJobRoleVisible(false);
    setIsContentTypeVisible(false);
    setIsCategoryVisible(false);
    setIsDateVisible(false);
    setIsPopularityVisible(false);
    setIsLengthVisible(false);
    setIsEventTypesVisible(false);
    setIsDurationVisible(false);
  };

  const handleSave = (data: IFilterData) => {
    setFilterData({ ...filterData, ...data });
    handleModalClose();
  };

  const handleSubmit = () => {
    onSubmit(filterData);
    setIsFilterModalVisible(false);
  };

  const handleSelectedCategory = (categories: ICategory[]) =>
    categories.reduce(
      (agg: string, category: ICategory) => `${agg}${agg.length ? ', ' : ''}${category.category}: ${category.subCategory.length} subcategories`,
      ''
    );

  const getFilterDate = () => {
    let text = '';
    if (filterData.date?.from) {
      text = `From: ${filterData.date.from.toDateString()}`;
    }
    if (filterData.date?.to) {
      text = `${text}${text.length ? ' ,' : ''}To: ${filterData.date.to.toDateString()}`;
    }
    return text;
  };

  const getRightText = (option: FilterOptions) => {
    switch (option) {
      case FilterOptions.sortBy:
        return filterData?.sortBy?.toString() || '';
      case FilterOptions.category:
        return filterData?.category ? handleSelectedCategory(filterData.category) : '';
      case FilterOptions.duration:
        return filterData?.duration?.toString() || '';
      case FilterOptions.memberType:
        return filterData?.memberTypes?.toString() || '';
      case FilterOptions.contentType:
        return filterData?.contentTypes?.toString() || '';
      case FilterOptions.eventType:
        return filterData?.eventTypes?.toString() || '';
      case FilterOptions.jobRole:
        return filterData?.jobRoles?.toString() || '';
      case FilterOptions.date:
        return getFilterDate();
      case FilterOptions.popularity:
        return filterData?.popularity ? `${filterData.popularity} stars and up` : '';
      case FilterOptions.length:
        return filterData?.length ? `Min: ${filterData.length.min} Max: ${filterData.length.max}` : '';
      default:
        return '';
    }
  };

  const renderItem = ({ item }: { item: FilterOptions }) => (
    <FilterListItem title={item} onPress={() => handleOptionSelect(item)} rightText={getRightText(item)} />
  );
  return (
    <>
      <TouchableOpacity style={styles.filterButton} onPress={() => setIsFilterModalVisible(true)}>
        {isLoading ? (
          <ActivityIndicator />
        ) : (
          <>
            <FilterIcon />
            <Text style={styles.buttonText}>Filter</Text>
          </>
        )}
      </TouchableOpacity>
      <FilterWrapper
        headerTitle="Filter"
        isVisible={isFilterModalVisible}
        onClose={() => setIsFilterModalVisible(false)}
        buttonTitle="Submit"
        onDone={handleSubmit}
        headerRightContent={
          <TouchableOpacity onPress={() => setFilterData({})}>
            <Text style={styles.resetButton}>Reset</Text>
          </TouchableOpacity>
        }
      >
        <FlatList data={filterOptions} renderItem={renderItem} keyExtractor={(item) => item} />
        <SortBy isVisible={isSortByVisible} onClose={() => setIsSortByVisible(false)} onSave={(sortBy) => handleSave({ sortBy })} />
        <MemberType
          isVisible={isMemberTypeVisible}
          onClose={() => setIsMemberTypeVisible(false)}
          onSave={(memberTypes) => handleSave({ memberTypes })}
        />
        <JobRole isVisible={isJobRoleVisible} onClose={() => setIsJobRoleVisible(false)} onSave={(jobRoles) => handleSave({ jobRoles })} />
        <ContentType
          isVisible={isContentTypeVisible}
          onClose={() => setIsContentTypeVisible(false)}
          onSave={(contentTypes) => handleSave({ contentTypes })}
        />
        <Category isVisible={isCategoryVisible} onClose={() => setIsCategoryVisible(false)} onSave={(category) => handleSave({ category })} />
        <Date isVisible={isDateVisible} onClose={() => setIsDateVisible(false)} onSave={(date) => handleSave({ date })} />
        <Popularity
          isVisible={isPopularityVisible}
          onClose={() => setIsPopularityVisible(false)}
          onSave={(popularity) => handleSave({ popularity })}
        />
        <Length isVisible={isLengthVisible} onClose={() => setIsLengthVisible(false)} onSave={(length) => handleSave({ length })} />
        <EventType
          isVisible={isEventTypesVisible}
          onClose={() => setIsEventTypesVisible(false)}
          onSave={(eventTypes) => handleSave({ eventTypes })}
        />
        <Duration isVisible={isDurationVisible} onClose={() => setIsDurationVisible(false)} onSave={(duration) => handleSave({ duration })} />
      </FilterWrapper>
    </>
  );
};

export { FilterModal };
