import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  title: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 22,
    lineHeight: 28,
    color: '#0D1738',
  },
  contentBox: {
    padding: 20,
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 1.0,
    shadowColor: 'rgba(0, 0, 0, 0.05)',
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    width: '100%',
  },
  contentBoxTitle: {
    fontFamily: 'OpenSans_500Medium',
    fontSize: 16,
    lineHeight: 20,
    color: '#0D1738',
  },
  wrap: {
    width: '100%',
  },
  titleWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  inner: {
    width: '100%',
    padding: 16,
    overflowX: 'scroll',
  },
  table: {
    marginTop: 16,
  },
});
