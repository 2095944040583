import { ContentBox } from '@components/general/layouts/content-box';
import { SideListBox } from '@components/general/side-list-box';
import { Event, Post } from '@gql/generated/generated';
import React from 'react';
import { Text, View } from 'react-native';

import { ids, styles } from './style';

interface RightSidePanelProps {
  upcomingEvents?: Event[];
  newsItems?: Pick<Post, 'id' | 'coverImage' | 'title' | 'link' | 'createdAt' | 'publishedAt' | 'summary'>[] | null;
}

const ItemBottomRow = ({ title, isTruncated }: { title: string; isTruncated?: boolean }) => (
  <Text numberOfLines={isTruncated ? 1 : undefined} style={styles.itemTitle}>
    {title}
  </Text>
);

export const RightSidePanel = ({ upcomingEvents, newsItems }: RightSidePanelProps) => {
  const eventItems = upcomingEvents
    ? upcomingEvents.map(({ id, title, dateFrom, coverImage, type, location }) => ({
        id,
        title,
        date: dateFrom,
        image: coverImage,
        linkUrl: `events/${id}`,
        bottomRow: <ItemBottomRow title={type === `Virtual` ? 'Virtual' : location ? location : ''} />,
      }))
    : [];

  const newsItemsWithDate = newsItems
    ? newsItems.map(({ id, title, publishedAt, coverImage, link, summary }) => ({
        id,
        title,
        date: publishedAt,
        image: coverImage,
        linkUrl: link,
        bottomRow: <ItemBottomRow isTruncated title={summary || ''} />,
      }))
    : [];

  return (
    <>
      <View style={styles.mainContainer} dataSet={{ media: ids.mainContainer }}>
        <ContentBox style={styles.itemWrap}>
          <SideListBox title="Upcoming Events" items={eventItems} linkText="View all Events" linkLocation="/events" marginTop={0} />
        </ContentBox>
        <ContentBox>
          <SideListBox title="News" items={newsItemsWithDate} withTime={false} />
        </ContentBox>
      </View>
    </>
  );
};
