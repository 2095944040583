import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    height: '100%',
  },
  infoWrap: {
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#EDEEEF',
    paddingBottom: 0,
  },
  titleMargin: {
    marginBottom: 18,
  },
  infoTitle: {
    fontSize: 16,
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
  },
  subtitle: {
    color: '#6D7175',
    fontFamily: 'OpenSans_400Regular',
    fontSize: 16,
  },
  editButtonWrap: {
    position: 'absolute',
    bottom: 40,
    left: 12,
    right: 12,
  },
  inputWrap: {
    marginBottom: 12,
  },
  selectWrap: {
    width: '100%',
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  buttonWrap: {
    padding: 20,
  },
});
