import { TabTitle as TabItem } from '@components/general/tab-title';
import React, { MutableRefObject } from 'react';
import { Platform, ScrollView } from 'react-native';

import { styles } from './style';

export interface TabContentPosition {
  overview: number;
  curriculum: number;
  instructor: number;
  reviews: number;
  relatedCourses: number;
}

export enum TabTitle {
  overview = 'Overview',
  curriculum = 'Curriculum',
  instructor = 'Instructor',
  reviews = 'Reviews',
  relatedCourses = 'RelatedCourses',
}

interface Props {
  scrollRef?: MutableRefObject<ScrollView>;
  contentPosition?: TabContentPosition;
}

const TrainingTab = ({ scrollRef, contentPosition }: Props) => {
  const [activeTab, setActiveTab] = React.useState(TabTitle.overview);

  const handlePressTab = (tabTitle: TabTitle, position?: number) => {
    setActiveTab(tabTitle);
    if (Platform.OS === 'web') {
      const elementOffset = document.getElementById(tabTitle)?.offsetTop;
      if (elementOffset) {
        window.scrollTo({
          top: elementOffset + 40,
        });
      }
    } else {
      scrollRef?.current?.scrollTo({
        y: position,
        animated: true,
      });
    }
  };

  return (
    <ScrollView style={styles.wrap} horizontal showsHorizontalScrollIndicator={false}>
      <TabItem
        title="Overview"
        active={activeTab === TabTitle.overview}
        onPress={() => handlePressTab(TabTitle.overview, contentPosition?.overview)}
      />

      <TabItem
        title="Curriculum"
        active={activeTab === TabTitle.curriculum}
        onPress={() => handlePressTab(TabTitle.curriculum, contentPosition?.curriculum)}
      />
      <TabItem
        title="Instructor"
        active={activeTab === TabTitle.instructor}
        onPress={() => handlePressTab(TabTitle.instructor, contentPosition?.instructor)}
      />
      <TabItem title="Reviews" active={activeTab === TabTitle.reviews} onPress={() => handlePressTab(TabTitle.reviews, contentPosition?.reviews)} />
      <TabItem
        title="Related Courses"
        active={activeTab === TabTitle.relatedCourses}
        onPress={() => handlePressTab(TabTitle.relatedCourses, contentPosition?.relatedCourses)}
      />
    </ScrollView>
  );
};

export { TrainingTab };
