import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const LargePlayIcon = (props: SvgProps) => (
  <Svg width={9} height={10} fill="none" {...props}>
    <Path d="M8.344 4.117a1 1 0 0 1 0 1.766L1.72 9.412A1 1 0 0 1 .25 8.53V1.47A1 1 0 0 1 1.72.587l6.624 3.53Z" fill="#717171" />
  </Svg>
);

export { LargePlayIcon };
