import { mediaQuery } from '@utils/misc';
import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  subContainer: {
    [mediaQuery.forMobile]: {
      width: '100%',
    },
  },
  linkWrap: {
    alignItems: 'center',
    marginTop: 16,
  },
  registerLink: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#2C6ECB',
  },
  inputWrap: {
    marginBottom: 16,
  },
  submitButton: {
    ...(Platform.OS === 'web' && { height: 36 }),
  },
});
