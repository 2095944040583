import { ContentBox } from '@components/general/layouts/content-box';
import { usePollOptions } from '@components/home/poll/usePollOptions';
import { ContextUser } from '@context/UserContext';
import { CollectionName, Comment, ContentType, PollOption, useCastPollVoteMutation } from '@gql/generated/generated';
import { pluralize } from '@utils/misc';
import { PostType } from '@utils/models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Animated, FlatList, Text, TouchableOpacity, View } from 'react-native';

import { styles } from './style';
import { Comments } from '../post/comment/comments/Comments';
import { PostBody } from '../post/post-body';
import { PostFooter } from '../post/post-footer';
import { PostHead } from '../post/post-head';

interface PollItemProps {
  selected: boolean;
  text: string;
  percentage: string;
  onPress: () => void;
  disabled?: boolean;
  hasVoted: boolean;
}

const PollItem = ({ text, percentage, onPress, disabled, hasVoted }: PollItemProps) => {
  const animation = useMemo(() => new Animated.Value(0), []);
  const inputRange = [0, 100];
  const outputRange = ['0%', '100%'];

  useEffect(() => {
    const handleAnimation = () => {
      const toValue = Number(percentage);

      Animated.timing(animation, {
        toValue,
        duration: 200,
        useNativeDriver: false,
      }).start();
    };

    if (hasVoted) {
      handleAnimation();
    }
  }, [hasVoted, animation, percentage]);

  const animatedWidth = animation.interpolate({ inputRange, outputRange });
  return (
    <TouchableOpacity disabled={disabled} style={[styles.pollItem, disabled && styles.pollItemActive]} onPress={onPress}>
      {hasVoted && (
        <Animated.View
          style={[
            {
              ...styles.pollActive,
              width: animatedWidth,
            },
            Number(percentage) === 100 && styles.pollActiveFull,
          ]}
        />
      )}
      <View style={[styles.pollTextWrap, hasVoted && styles.pollTextWrapActive]}>
        <Text style={styles.pollText}>{text}</Text>
        {!!Number(percentage) && hasVoted && <Text style={styles.pollPercentText}>{percentage}%</Text>}
      </View>
    </TouchableOpacity>
  );
};

interface PollProps {
  id: string;
  description: string;
  authorName: string;
  date: string;
  avatar?: string;
  closeDate: string;
  pollQuestion: string;
  pollOptions: PollOption[];
  voteCount: number;
  timeLeft: string;
  canCurrentUserVote: boolean;
  comments: Comment[];
  numRatings: number;
  userRating?: number | null;
  rating: number;
  isCommented?: boolean;
  totalComments: number;
  hasPollClosed: boolean;
  user: ContextUser | undefined;
}

const Poll = ({
  id,
  description,
  avatar,
  date,
  closeDate,
  timeLeft,
  pollQuestion,
  pollOptions,
  voteCount,
  authorName,
  canCurrentUserVote,
  hasPollClosed,
  comments,
  numRatings,
  userRating,
  rating,
  isCommented,
  totalComments,
  user,
}: PollProps) => {
  const [isCommentsVisible, setIsCommentsVisible] = useState(false);
  const [castPollVote] = useCastPollVoteMutation();

  const [isVoting, setIsVoting] = useState<boolean>(false);
  const { pollOptionsState, voteCountState, hasVoted, updatePollOptions, updatePollOptionsOptimistically } = usePollOptions(
    pollOptions,
    user?.id || '',
    voteCount
  );

  const handleVote = useCallback(
    async (pollOptionId: string) => {
      try {
        // optimistically update the pollOptions state
        updatePollOptionsOptimistically(pollOptionsState, pollOptionId);
        // update the backend
        const result = await castPollVote({
          variables: { pollOptionId, pollId: id },
        });
        // update again after mutation request returns
        updatePollOptions(result.data?.castPollVote.pollOptions || pollOptionsState);
        setIsVoting(false);
      } catch (e) {
        console.error('>>> handleVote error', e);
        setIsVoting(false);
      }
    },
    [castPollVote, id, pollOptionsState, updatePollOptions, updatePollOptionsOptimistically]
  );

  const renderItem = ({ item }: { item: PollOption }) => (
    <PollItem
      text={item.text}
      percentage={item.percentage}
      disabled={!canCurrentUserVote}
      selected={item.votes?.includes(String(user?.id)) || false}
      hasVoted={hasVoted}
      onPress={() => {
        if (isVoting) return;
        setIsVoting(true);
        handleVote(item.id);
      }}
    />
  );

  return (
    <View style={styles.container}>
      <ContentBox>
        <PostHead avatar={avatar} name={authorName} date={date} postType={PostType.poll} />
        <PostBody caption={description} />
        <View style={styles.pollWrap}>
          <View style={styles.wrap}>
            <Text style={[styles.boldText, styles.title]}>{pollQuestion}</Text>
            <View>
              <FlatList data={pollOptionsState} renderItem={renderItem} keyExtractor={({ id }: PollOption) => id} />
            </View>
            <View style={styles.voteTextWrap}>
              <Text style={styles.boldText}>
                {voteCountState} {pluralize(voteCountState, 'Vote')}
              </Text>
              <View style={styles.dot} />
              <Text style={styles.normalText}>
                Poll {hasPollClosed ? 'ended' : 'ends'} on {closeDate}
              </Text>
            </View>
          </View>
        </View>
        <PostFooter
          isRated={!!userRating}
          totalRatings={numRatings}
          postRating={rating}
          timeLeft={timeLeft}
          voteCount={voteCountState}
          onPressComment={() => setIsCommentsVisible(!isCommentsVisible)}
          itemId={id}
          itemType={ContentType.POLL}
          collectionName={CollectionName.POLLS}
          isCommented={isCommented}
          totalComments={totalComments}
        />
        {isCommentsVisible && (
          <Comments
            comments={comments}
            commentedItemType={ContentType.POLL}
            collectionName={CollectionName.POLLS}
            commentedItemId={id}
            totalComments={totalComments}
          />
        )}
      </ContentBox>
    </View>
  );
};

export { Poll };
