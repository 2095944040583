import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  contentBox: {
    width: '100%',
    padding: 20,
    marginBottom: 16,
  },
  flexSpace: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  topWrap: {
    width: '100%',
  },
  title: {
    color: '#0D1738',
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
  },
  middleWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pillWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  pill: {
    backgroundColor: '#E4E5E7',
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 15,
  },
  pillText: {
    color: '#0D1738',
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
  },
  percentCompleteText: {
    color: '#6D7175',
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
    marginLeft: 4,
  },
  showMoreText: {
    color: '#0D1738',
    fontSize: 12,
    textDecorationLine: 'underline',
    fontFamily: 'OpenSans_400Regular',
  },
  expandedContent: {
    marginTop: 12,
  },
  expandedItem: {
    marginBottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  expandedItemText: {
    color: '#0D1738',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
  },
  threeDotsTouch: {
    padding: 20,
  },
});
