import { mediaQuery } from '@utils/misc';
import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    width: '100%',
    overflow: 'visible',
  },
  back: {
    color: '#2C6ECB',
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    textDecorationLine: 'underline',
  },
  title: {
    fontSize: 26,
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
    marginTop: 8,
    marginBottom: 8,
  },
  initialsWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  instructorName: {
    color: '#6D7175',
    fontSize: 12,
    fontFamily: 'OpenSans_400Regular',
    marginLeft: 4,
  },
  moduleTitleWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 12,
    [mediaQuery.forMobile]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  moduleTitle: {
    color: '#0D1738',
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
    ...(Platform.OS !== 'web' && { marginBottom: 8 }),
  },
  completionText: {
    display: 'none',
    [mediaQuery.forMobile]: {
      display: 'flex',
    },
  },
  lessonTitle: {
    fontFamily: 'OpenSans_400Regular',
  },
  nextWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
    [mediaQuery.forMobile]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: 16,
    },
  },
  downloadWrap: {
    paddingTop: 40,
    paddingBottom: 40,
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: '#EDEEEF',
    [mediaQuery.forMobile]: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  downloadInner: {
    ...(Platform.OS === 'web' && { width: 'fit-content' }),
  },
  completeButton: {
    borderWidth: 1,
    borderColor: '#8C9196',
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    height: 36,
    paddingLeft: 12,
    paddingRight: 12,
  },
  completeButtonWrap: {
    width: '100%',
    alignItems: 'flex-end',
    paddingTop: 40,
    [mediaQuery.forMobile]: {
      paddingTop: 20,
    },
  },
  completeButtonInner: {
    width: '100%',
    maxWidth: 343,
    [mediaQuery.forMobile]: {
      width: '50%',
      maxWidth: '100%',
    },
  },
  changeLessonButtonText: {
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 20,
  },
  link: {
    textDecorationLine: 'none',
  },
  buttonText: {
    color: '#FFFFFF',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    lineHeight: 20,
  },
});
