import { FormSection } from '@components/back-office/editor/form-section/FormSection';
import { FormikInput } from '@components/back-office/editor/formik-input';
import { FormikPublishTools } from '@components/back-office/editor/formik-publish-tools/FormikPublishTools';
import { FormikTextArea } from '@components/back-office/editor/formik-text-area/FormikTextArea';
import { UserAvatarIcon } from '@components/general/icons';
import { INITIALS_CIRCLE_BACKGROUND_COLORS, InitialsCircle } from '@components/general/initials-circle';
import { Row } from '@components/general/row';
import { PollStatus, useAdminGetPollQuery, useAdminGetUsersQuery, useCreatePollMutation, useUpdatePollMutation } from '@gql/generated/generated';
import { ActionList, Button, DataTable, Icon, Popover } from '@shopify/polaris';
import { ChevronLeftMinor } from '@shopify/polaris-icons';
import { textStyles } from '@styles/text';
import { UpdateContext } from '@utils/UpdateContext';
import { pluralize } from '@utils/misc';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { ActivityIndicator, Animated, Image, Platform, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate, useParams } from 'react-router-native';
import * as Yup from 'yup';

import { styles } from './style';
import excelIcon from '../../../../../assets/img/ExcelIcon.png';

interface PollItemProps {
  active?: boolean;
  title: string;
  percent: number;
  onPress: () => void;
}

export const PollItem = ({ active, title, percent, onPress }: PollItemProps) => {
  return (
    <TouchableOpacity style={[styles.pollItem, active && styles.pollItemActive]} onPress={onPress}>
      <Animated.View
        style={[
          {
            ...styles.pollActive,
            width: `${percent}%`,
          },
          percent === 100 && styles.pollActiveFull,
        ]}
      />
      <View style={[styles.pollTextWrap, active && styles.pollTextWrapActive]}>
        <Text style={[styles.pollText, percent < 50 ? { color: '#2C6ECB' } : {}]}>{title}</Text>
        {active && <Text style={styles.pollPercentText}>{percent}%</Text>}
      </View>
    </TouchableOpacity>
  );
};

const PollResults = () => {
  const [showOptionResultSelect, setShowOptionResultSelect] = useState(false);

  const [selectedResultUserIds, setSelectedResultUserIds] = useState<any>([]);
  const [selectedResult, setSelectedResult] = useState('');

  const navigation = useNavigate();
  const UpdateStatus = useContext(UpdateContext);

  const { id } = useParams();

  const { data: existingPollData, loading: loadingExistingPoll } = useAdminGetPollQuery({
    variables: { id: id! },
    skip: !id,
  });

  const [createPoll] = useCreatePollMutation();
  const [updatePoll] = useUpdatePollMutation();

  const { data: usersData } = useAdminGetUsersQuery({
    variables: {
      page: 1,
      limit: 99,
      filters: {
        ids: selectedResultUserIds,
      },
    },
    skip: selectedResultUserIds.length === 0,
  });

  const users = usersData?.adminGetUsers;
  const existingPoll = existingPollData?.adminGetPoll;
  const { pollOptions: pollResults } = existingPoll || {};

  const ShowOptionSelectButton = (
    <View style={{ marginBottom: 12 }}>
      <Button
        fullWidth
        textAlign="left"
        disclosure={showOptionResultSelect ? 'up' : 'down'}
        onClick={() => setShowOptionResultSelect(!showOptionResultSelect)}
      >
        {selectedResult || 'Select'}
      </Button>
    </View>
  );

  if (loadingExistingPoll) {
    return (
      <View
        style={{
          height: Platform.OS === 'web' ? '100vh' : '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ActivityIndicator size="large" color="#213470" />
      </View>
    );
  }

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Formik
        initialValues={{
          id: existingPoll?.id ?? null,
          pollQuestion: existingPoll?.pollQuestion ?? '',
          pollOptions: existingPoll?.pollOptions ? existingPoll.pollOptions.map((option) => option.text) : [],
          description: existingPoll?.description ?? '',
          status: existingPoll?.status ?? 'Draft',
          closeDate: existingPoll?.closeDate ?? Date.now(),
          pollStatus: existingPoll?.pollStatus ?? PollStatus.DEFAULT,
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          id: Yup.string(),
          pollQuestion: Yup.string(),
          description: Yup.string(),
          pollOptions: Yup.array(),
          status: Yup.string().required(),
          closeDate: Yup.number(),
        })}
        onSubmit={async (values, actions) => {
          const { id: existingPollId, closeDate, description, pollOptions, pollQuestion, status } = values;

          if (existingPollId) {
            const res = await updatePoll({
              variables: {
                input: {
                  id: existingPollId,
                  closeDate,
                  description,
                  pollOptions,
                  pollQuestion,
                  status,
                },
              },
            });
          } else {
            const res = await createPoll({
              variables: {
                input: {
                  closeDate,
                  description,
                  pollOptions,
                  pollQuestion,
                  status,
                },
              },
            });
          }
        }}
      >
        {(props) => {
          return (
            <>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 12,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    UpdateStatus.update(0);
                    navigation(-1);
                  }}
                  style={{
                    borderWidth: 1,
                    borderColor: '#BABFC3',
                    borderRadius: 4,
                    width: 36,
                    height: 36,
                    marginRight: 8,
                  }}
                >
                  <Icon source={ChevronLeftMinor} />
                </TouchableOpacity>
                <Text style={styles.title}>Poll Results</Text>
              </View>
              <View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                    }}
                  >
                    <FormSection>
                      <View style={{ marginBottom: 16 }}>
                        <Text style={[textStyles.subduedTextMedium, { marginBottom: 4 }]}>Poll Question</Text>
                        <FormikInput fieldName="pollQuestion" label="" placeholder="Add a question here" disabled />
                      </View>
                      <View style={{ marginBottom: 16 }}>
                        <Text style={textStyles.subduedTextMedium}>Poll Options</Text>
                        {pollResults &&
                          pollResults.map(({ id, text, voteCount, percentage, votes }: any) => {
                            return (
                              <PollItem title={text} active percent={percentage} onPress={() => {}} />
                              // <View
                              //   style={{
                              //     backgroundColor: "#E4E5E7",
                              //     marginBottom: 8,
                              //     justifyContent: "space-between",
                              //   }}
                              // >
                              //   <Text>{text}</Text>
                              // </View>
                            );
                          })}
                        {/* <FormikList
                          title="Poll Options"
                          subtitle=""
                          fieldName="pollOptions"
                          initialNumberOfItems={4}
                          placeholder="Poll Option"
                        /> */}
                      </View>
                      <View style={{ marginBottom: 16 }}>
                        {/* TODO: remove negative margin */}
                        <Text style={[textStyles.subduedTextMedium, { marginBottom: -18 }]}>Description</Text>
                        <FormikTextArea fieldName="description" label="" maxLength={150} placeholder="Add poll description here" disabled />
                      </View>

                      <Row
                        style={{
                          marginTop: 16,
                          justifyContent: 'space-between',
                          flex: 1,
                        }}
                      >
                        <Text style={textStyles.subduedTextMedium}>
                          Closes on <Text style={textStyles.semiBoldSmall}>{dayjs(props.values.closeDate).format('D/MM/YYYY')}</Text>
                        </Text>
                        <TouchableOpacity onPress={() => navigation(`/poll-editor/${id}`)}>
                          <Text style={{ color: '#2C6ECB', marginLeft: 4 }}>Edit</Text>
                        </TouchableOpacity>
                      </Row>
                    </FormSection>
                    <FormSection>
                      <View style={{ marginBottom: 16 }}>
                        <Text style={[textStyles.semiBoldMedium, { marginBottom: 4 }]}>Result</Text>
                      </View>
                      <View style={{ marginBottom: 16 }}>
                        <Row
                          style={{
                            justifyContent: 'space-between',
                            marginBottom: 10,
                          }}
                        >
                          <Row>
                            <UserAvatarIcon color="#5C5F62" />
                            <Text style={{ marginLeft: 8 }}>
                              {existingPoll?.voteCount} {pluralize(existingPoll?.voteCount ?? 0, 'vote')}
                            </Text>
                          </Row>
                          <TouchableOpacity>
                            <Row>
                              <Image source={excelIcon} style={{ height: 20, width: 20 }} />
                              <Text style={{ color: '#2C6ECB', marginLeft: 4 }}>Export</Text>
                            </Row>
                          </TouchableOpacity>
                        </Row>
                        <Popover
                          fullWidth
                          active={showOptionResultSelect}
                          activator={ShowOptionSelectButton}
                          onClose={() => setShowOptionResultSelect(!showOptionResultSelect)}
                        >
                          <ActionList
                            actionRole="menuitem"
                            items={
                              pollResults &&
                              pollResults.map((pollResult: any) => ({
                                content: pollResult.text,
                                onAction: () => {
                                  setSelectedResult(pollResult.text);
                                  setSelectedResultUserIds(pollResult.votes);
                                },
                              }))
                            }
                          />
                        </Popover>
                        {/* <Text>{selectedResultUserIds}</Text> */}
                        {/* <Text>{JSON.stringify(users)}</Text> */}
                        {/* 
                        <SimpleTable
                          headers={[]}
                          withHeaders={false}
                          rows={
                            users
                              ? users.map((user, index) => {
                                  return {
                                    index: `${index + 1}.`,
                                    name:
                                      user.accountInfo?.firstName +
                                      " " +
                                      user.accountInfo?.lastName,
                                    jobPosition:
                                      user.accountInfo?.companyInfo.jobPosition,
                                    company: user.accountInfo?.companyInfo.name,
                                    date: dayjs(
                                      new Date(Number(user.registrationDate))
                                    ).format("D/MM/YYYY"),
                                  };
                                })
                              : []
                          }
                          colorCondition={[
                            { label: "Pending", color: "#B98900" },
                            { label: "Failed", color: "#D72C0D" },
                          ]}
                        /> */}
                        {/* {users?.getUsers &&
                          users?.getUsers?.users.map((user) => {
                            return (
                              <View>
                                <Text>
                                  {user.accountInfo?.firstName +
                                    " " +
                                    user.accountInfo?.lastName}
                                </Text>
                                <Text>
                                  {user.accountInfo?.companyInfo.jobPosition}
                                </Text>
                                <Text>{user.accountInfo?.companyInfo.name}</Text>
                                <Text>
                                  Joined
                                  {dayjs(
                                    new Date(Number(user.registrationDate))
                                  ).format("D/MM/YYYY")}
                                </Text>
                              </View>
                            );
                          })} */}
                        {/* {usersData && users.length === 0 ? (
                          <Text style={{ backgroundColor: "red" }}>
                            No Users Found
                          </Text>
                        ) : null} */}

                        {users ? (
                          <DataTable
                            columnContentTypes={['text', 'text', 'text', 'text', 'text']}
                            headings={['', 'Name', 'Job Position', 'Company Name', 'Registration Date']}
                            rows={
                              users
                                ? users.users.map((user, index) => {
                                    if (!user) return [];
                                    return [
                                      `${index + 1}.`,

                                      <Row style={{ alignItems: 'center' }}>
                                        <InitialsCircle
                                          initials={
                                            user.accountInfo?.firstName[0] && user.accountInfo?.lastName[0]
                                              ? user.accountInfo?.firstName[0] + user.accountInfo?.lastName[0]
                                              : ''
                                          }
                                          backgroundColor={INITIALS_CIRCLE_BACKGROUND_COLORS[index % 4]}
                                          size={24}
                                          fontSize={12}
                                        />
                                        <Text style={{ marginLeft: 4 }}>
                                          {user.accountInfo?.firstName} {user.accountInfo?.lastName}
                                        </Text>
                                      </Row>,

                                      user.accountInfo?.companyInfo.jobPosition,
                                      user.accountInfo?.companyInfo.name,
                                      dayjs(new Date(Number(user.registrationDate))).format('D/MM/YYYY'),
                                    ];
                                  })
                                : []
                            }
                          />
                        ) : (
                          <Text
                            style={{
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: 10,
                              flex: 1,
                            }}
                          >
                            No Users Found
                          </Text>
                        )}
                      </View>
                    </FormSection>
                  </View>
                  <View style={{ marginLeft: 20 }}>
                    <FormikPublishTools withClosePoll withCategories={false} withUserType={false} withJobRoles={false} />
                  </View>
                </View>
              </View>
            </>
          );
        }}
      </Formik>
    </View>
  );
};

export { PollResults };
