import { ButtonGeneral, ButtonType, ButtonVariant } from '@components/general/button-general';
import { CheckIcon } from '@components/general/icons/training-icons';
import { InitialsCircle } from '@components/general/initials-circle';
import { LessonProgress } from '@components/training/lesson/lesson-progress';
import { UserLesson, useUpdateTrainingProgressForUserMutation } from '@gql/generated/generated';
import { useGetFiles } from '@hooks/useGetFiles';
import { textStyles } from '@styles/text';
import { downloadFile, getInitials } from '@utils/misc';
import React from 'react';
import { Platform, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { ids, styles } from './style';

interface Props {
  trainingId: string;
  moduleId: string;
  children: React.ReactNode;
  lesson: UserLesson;
  handlePressEnroll?: () => Promise<void>;
}

const LessonWrapper = ({ children, lesson, trainingId, moduleId, handlePressEnroll }: Props) => {
  const navigate = useNavigate();

  const progressPercentage = lesson?.lessonProgressPercentage ?? 0;
  const progressInTraining = lesson?.lessonProgressInTraining ?? '';
  const isLessonEnrolled = !!lesson.userHasEnrolled;
  const isLessonCompleted = !!lesson.userHasCompleted;

  const { filesWithUrl } = useGetFiles(lesson?.downloadFiles);

  const handleDownload = async () => {
    if (filesWithUrl?.length) {
      await Promise.all(filesWithUrl.map((file) => downloadFile(file.downloadUrl)));
    }
  };

  const [updateTrainingProgress, { loading }] = useUpdateTrainingProgressForUserMutation({
    update: (store, { data }) => {
      store.modify({
        id: `UserLesson:${lesson.id}`,
        fields: {
          userHasCompleted() {
            return true;
          },
          trainingCompletionPercentage() {
            return data?.updateTrainingProgressForUser.trainingCompletionPercentage || null;
          },
        },
      });
    },
  });

  const handlePressAction = async () => {
    if (!isLessonEnrolled) {
      return handlePressEnroll && handlePressEnroll().then(() => navigate(0));
    }
    if (isLessonCompleted) {
      window.scrollTo(0, 0);
      return navigate(`/training/${trainingId}/lesson/${lesson.nextLesson?.moduleId}/${lesson.nextLesson?.lessonId}`);
    } else
      return await updateTrainingProgress({
        variables: {
          input: {
            trainingId,
            moduleId,
            lessonId: lesson.id,
            lessonDuration: lesson?.durationInSeconds,
          },
        },
      });
  };

  return (
    <View style={styles.wrap}>
      {Platform.OS === 'web' ? (
        <TouchableOpacity onPress={() => navigate(`/training/${trainingId}`)}>
          <Text style={styles.back}>{'< Back'}</Text>
        </TouchableOpacity>
      ) : null}
      <Text style={styles.title}>{lesson.trainingTitle}</Text>
      <View style={styles.initialsWrap}>
        <InitialsCircle
          initials={(lesson?.instructors?.length && getInitials(lesson.instructors[0].name)) || ''}
          backgroundColor="#E4E5E7"
          size={24}
          fontSize={12}
        />
        <Text style={styles.instructorName}>{lesson?.instructors?.length && lesson.instructors[0].name}</Text>
      </View>
      <View style={styles.moduleTitleWrap} dataSet={{ media: ids.moduleTitleWrap }}>
        <Text style={styles.moduleTitle}>{lesson.moduleTitle}&nbsp;</Text>
        <Text style={[styles.moduleTitle, styles.lessonTitle]}>
          - Lesson {lesson.rank + 1}: {lesson?.lessonTitle}
        </Text>
        <Text style={[styles.moduleTitle, styles.completionText]}>{progressPercentage}% completion</Text>
      </View>
      <LessonProgress progressPercentage={progressPercentage} progressInTraining={progressInTraining} />
      <View style={styles.nextWrap} dataSet={{ media: ids.nextWrap }}>
        <View>
          {lesson.prevLesson ? (
            <ButtonGeneral
              type={ButtonType.user}
              variant={ButtonVariant.Secondary}
              onPress={() => navigate(`/training/${trainingId}/lesson/${lesson.prevLesson?.moduleId}/${lesson.prevLesson?.lessonId}`)}
              style={{ maxWidth: 200 }}
              disabled={!isLessonEnrolled}
            >
              <Text>{'<< '}</Text>
              <Text numberOfLines={1} style={styles.changeLessonButtonText}>{`${lesson.prevLesson.lessonTitle}`}</Text>
            </ButtonGeneral>
          ) : null}
        </View>
        <View>
          {lesson?.nextLesson ? (
            <ButtonGeneral
              type={ButtonType.user}
              variant={ButtonVariant.Secondary}
              onPress={() => navigate(`/training/${trainingId}/lesson/${lesson?.nextLesson?.moduleId}/${lesson.nextLesson?.lessonId}`)}
              disabled={!isLessonEnrolled || !isLessonCompleted}
              style={{ maxWidth: 200 }}
            >
              <Text style={styles.changeLessonButtonText} numberOfLines={1}>{`${lesson.nextLesson.lessonTitle}`}</Text>
              <Text>{'>>'}</Text>
            </ButtonGeneral>
          ) : null}
        </View>
      </View>
      {children}

      <View style={styles.completeButtonWrap}>
        {!isLessonEnrolled || lesson.nextLesson || !isLessonCompleted ? (
          <View style={styles.completeButtonInner} dataSet={{ media: ids.completeButtonInner }}>
            <ButtonGeneral
              type={ButtonType.user}
              onPress={handlePressAction}
              style={Platform.OS === 'web' && { paddingVertical: 16 }}
              isLoading={loading}
              disabled={isLessonCompleted && !lesson.nextLesson}
            >
              <Text style={styles.buttonText}>{!isLessonEnrolled ? 'Enrol' : isLessonCompleted ? 'Next lesson' : 'Complete'}</Text>
            </ButtonGeneral>
          </View>
        ) : (
          <View style={styles.completeButton}>
            <CheckIcon color="#23827F" />
            <Text style={{ ...textStyles.semiBoldMedium, marginLeft: 8 }}>Training complete</Text>
          </View>
        )}
      </View>
    </View>
  );
};

export { LessonWrapper };
