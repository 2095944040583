import { TabTitle } from '@components/general/tab-title';
import React from 'react';
import { ScrollView, View } from 'react-native';

import { styles } from './style';

export enum SearchTabItem {
  all = 'All',
  content = 'Content',
  events = 'Events',
  training = 'Training',
  partner = 'Partners',
}

interface Props {
  active: SearchTabItem;
  onTabPress: (tab: SearchTabItem) => void;
  contentCount: number;
  eventsCount: number;
  trainingsCount: number;
  partnersCount: number;
}

const SearchTab = ({ active, onTabPress, contentCount, eventsCount, trainingsCount, partnersCount }: Props) => (
  <View style={styles.wrap}>
    <ScrollView horizontal showsHorizontalScrollIndicator={false}>
      <TabTitle active={active === SearchTabItem.all} title={SearchTabItem.all} onPress={() => onTabPress(SearchTabItem.all)} />
      <TabTitle
        active={active === SearchTabItem.content}
        title={SearchTabItem.content}
        onPress={() => onTabPress(SearchTabItem.content)}
        pill={`${contentCount}`}
      />
      <TabTitle
        active={active === SearchTabItem.events}
        title={SearchTabItem.events}
        onPress={() => onTabPress(SearchTabItem.events)}
        pill={`${eventsCount}`}
      />
      <TabTitle
        active={active === SearchTabItem.training}
        title={SearchTabItem.training}
        onPress={() => onTabPress(SearchTabItem.training)}
        pill={`${trainingsCount}`}
      />
      <TabTitle
        active={active === SearchTabItem.partner}
        title={SearchTabItem.partner}
        onPress={() => onTabPress(SearchTabItem.partner)}
        pill={`${partnersCount}`}
      />
    </ScrollView>
  </View>
);

export { SearchTab };
