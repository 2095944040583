import { OrgMobileLayout } from '@components/org/org-mobile-layout';
import { ItemType } from '@components/org/org-modal-layout';
import { AssignmentsItemDetails } from '@gql/generated/generated';
import { useAssignment } from '@hooks/useAssignment';
import { PageVariant } from '@utils/models';
import React, { useState } from 'react';
import { Alert } from 'react-native';
import { useLocation, useNavigate } from 'react-router-native';

type ILocationState = {
  variant: PageVariant;
};

const AssignmentListNative = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { variant = PageVariant.saved } = (state as ILocationState) || {};
  // FIXME: Not implemented
  const [isSuccessVisible, _setIsSuccessVisible] = useState(false);
  // FIXME: Not implemented
  const [successInfo, _setSuccessInfo] = useState({
    title: '',
    message: '',
  });
  // FIXME: Not implemented
  const [selectedItem, _setSelectedItem] = useState<{
    item?: AssignmentsItemDetails;
    type?: ItemType;
  }>();

  const { eventItems, contentItems, trainingItems, handleCompletedStatus, removeAssignments } = useAssignment();

  const getItemTypes = () => {
    switch (variant) {
      case PageVariant.content:
        return [ItemType.view, ItemType.markAsDone, ItemType.remove];
      case PageVariant.event:
        return [ItemType.view, ItemType.deregister, ItemType.markAsDone, ItemType.remove];
      case PageVariant.training:
        return [ItemType.start, ItemType.markAsDone, ItemType.remove];
      default:
        return [];
    }
  };

  const handleViewItem = (id: string, variant: PageVariant) => {
    switch (variant) {
      case PageVariant.training:
        navigate(`/training/${id}`);
        break;
      case PageVariant.event:
        navigate(`/events/${id}`);
        break;
      case PageVariant.content:
        navigate(`/content/${id}`);
    }
  };

  const handleItemPress = async (item: AssignmentsItemDetails, type: ItemType, variant: PageVariant) => {
    if (type === ItemType.view) {
      handleViewItem(item.id as string, variant);
    } else if (type === ItemType.remove) {
      await removeAssignments(item.id, item?.type);
      Alert.alert('Success', 'Assignment removed');
    } else if (type === ItemType.markAsDone) {
      await handleCompletedStatus(item.id, variant === PageVariant.content ? 'Content' : variant === PageVariant.training ? 'Training' : 'Event');
      Alert.alert('Success', 'Assignment completed');
    } else if (type === ItemType.start) {
      handleViewItem(item.id, variant);
    }
  };

  const handleUndoSuccess = () => {
    if (selectedItem?.item && selectedItem?.type) {
      // TODO: Undo action
    }
  };

  return (
    <OrgMobileLayout
      title={variant}
      pageVariant={variant}
      itemTypes={getItemTypes()}
      onPressItem={handleItemPress}
      onUndoSuccess={handleUndoSuccess}
      isUndoSuccessVisible
      isSuccessVisible={isSuccessVisible}
      trainings={trainingItems || []}
      events={eventItems || []}
      contents={contentItems || []}
      successInfo={successInfo}
    />
  );
};

export { AssignmentListNative };
