import { mediaQuery } from '@utils/misc';
import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  fullContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'rgb(237, 237, 237)',
  },
  mainContainer: {
    width: 872,
    ...(Platform.OS === 'web' && { height: 'max-content' }),
    backgroundColor: '#FFFFFF',
    borderRadius: 15,
    marginTop: 108,
    marginRight: 35,
    paddingVertical: 25,
    paddingHorizontal: 36,
    marginBottom: 16,
  },
  buttonContainer: { display: 'flex', flexDirection: 'column' },
  labelText: {
    color: '#FFFFFF',
    fontSize: 14,
    fontFamily: 'OpenSans_600SemiBold',
    marginRight: 8,
  },
  contentText: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#0D1738',
    marginBottom: 28,
  },
  spaceBetween: {
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  buttonStyle: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 8,
  },
  fullButton: {
    width: 200,
    marginBottom: 16,
    [mediaQuery.forMobile]: {
      width: '100%',
    },
  },
  saveButtonText: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    color: '#202223',
    lineHeight: 20,
    marginLeft: 4,
    whiteSpace: 'nowrap',
  },
  title: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 26,
    marginBottom: 12,
    color: '#0D1738',
    [mediaQuery.forMobile]: {
      marginBottom: 4,
    },
  },
  plusIcon: {
    width: 12,
    height: 12,
    marginRight: 8,
  },
  subdued: {
    fontFamily: 'OpenSans_400Regular',
    color: '#6D7175',
    fontSize: 12,
    lineHeight: 16,
  },
  semiBold: {
    fontFamily: 'OpenSans_600SemiBold',
  },
  image: {
    height: 365,
    borderRadius: 8,
    width: '100%',
  },
  contentImage: {
    height: 329,
    width: '48%',
  },
  ImgContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  bookmark: {
    position: 'absolute',
    marginLeft: 777,
    marginTop: -380,
    height: 28,
    width: 28,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 13,
  },
  like: {
    width: 21,
    height: 21,
    marginRight: 5,
    marginTop: 2,
  },
  commentBtn: {
    width: 21,
    height: 21,
    marginRight: 5,
    marginTop: 3,
  },
  shareBtn: {
    width: 77,
    height: 29,
  },
  count: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 24,
    color: '#6E757C',
    marginRight: 15,
    marginTop: 3,
  },
  likeWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  commentWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  description: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
    marginBottom: 20,
  },
  date: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    lineHeight: 24,
    color: '#6E757C',
  },
  jobRole: {
    fontFamily: 'OpenSans_500Medium',
    fontSize: 12,
    lineHeight: 24,
    color: '#3B424A',
  },
  readLength: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    lineHeight: 24,
    color: '#6E757C',
  },
  category: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    lineHeight: 16,
    color: '#485057',
    backgroundColor: '#EBEDF0',
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 15,
    textAlign: 'center',
  },

  emptyImgContent: {
    width: 387,
    height: 338,
    marginRight: 29,
  },
  ImgContentRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 19,
    marginBottom: 39,
  },
  ImgContent: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  documentBtn: {
    width: 156,
    height: 48,
    marginTop: 40,
  },
  footer: {
    height: 1,
    width: '100%',
  },
  separation: {
    display: 'flex',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#EDEEEF',
  },
  commentModal: {
    width: 620,
    height: 276,
    backgroundColor: '#FFFFFF',
    padding: 20,
    borderRadius: 4,
    [mediaQuery.forMobile]: {
      width: '90%',
    },
  },
  commentModalWrap: {
    flex: 1,
    alignItems: 'center',
    [mediaQuery.forMobile]: {
      justifyContent: 'center',
    },
  },
  commentInput: {
    width: 580,
    height: 120,
    borderWidth: 1,
    borderColor: '#C9CCCF',
    borderRadius: 4,
    paddingHorizontal: 12,
    paddingVertical: 8,
    marginBottom: 8,
    [mediaQuery.forMobile]: {
      width: '100%',
    },
  },
  actionBtn: {
    display: 'flex',
    flexDirection: 'row',
  },
  iconBtn: {
    width: 20,
    height: 20,
    marginRight: 7,
  },
  textBtn: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 24,
    color: '#6E757C',
  },
  touchableMaxContent: {
    ...(Platform.OS === 'web' && { height: 'max-content' }),
    ...(Platform.OS === 'web' && { width: 'max-content' }),
  },
  commentSection: {
    ...(Platform.OS === 'web' && { height: 'max-content' }),
    backgroundColor: '#FFFFFF',
    [mediaQuery.forMobile]: {
      marginBottom: 16,
    },
  },
  loadMoreTouch: {
    marginTop: 8,
  },
  commentInputSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  avatar: {
    width: 40,
    height: 40,
    marginTop: 10,
    marginLeft: 33,
    marginRight: 7,
  },
  input: {
    flex: 1,
    fontFamily: 'OpenSans_400Regular',
    color: '#6E757C',
    fontSize: 14,
    lineHeight: 20,
    outlineStyle: 'none',
    backgroundColor: 'white',
  },
  inputView: {
    flex: 1,
    marginLeft: 8,
    borderWidth: 0.5,
    borderColor: '#c6cacd',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 35,
    paddingHorizontal: 12,
    paddingVertical: 6,
  },
  commentFlexRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  polygon: {
    width: 6,
    height: 6,
    marginTop: 20,
    marginLeft: -23,
  },
  name: {
    fontFamily: 'OpenSans_600SemiBold',
    color: '#3B424A',
    fontSize: 14,
    lineHeight: 24,
  },
  jobTitle: {
    fontFamily: 'OpenSans_600SemiBold',
    color: '#A3AAB0',
    fontSize: 12,
    lineHeight: 24,
    marginTop: -5,
  },
  commentOutput: {
    fontFamily: 'OpenSans_400Regular',
    color: '#0D1738',
    fontSize: 12,
    lineHeight: 15,
    marginBottom: 5,
  },
  likeOrReply: {
    fontFamily: 'OpenSans_600SemiBold',
    color: '#6D7175',
    fontSize: 12,
    lineHeight: 16,
    marginTop: 7,
    marginBottom: 16,
    marginLeft: 10,
  },
  commentReact: {
    display: 'flex',
    flexDirection: 'row',
  },
  comment: {
    backgroundColor: '#F6F6F7',
    paddingVertical: 12,
    paddingHorizontal: 15,
    borderRadius: 5,
  },
  optionsFilter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: 30,
    marginBottom: 12,
  },
  emoji: {
    width: 17,
    height: 17,
    zIndex: 2,
  },
  emojiBg: {
    zIndex: 1,
    justifyContent: 'center',
    backgroundColor: '#fff',
    paddingLeft: 0,
  },
  loadMore: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    lineHeight: 24,
    color: '#202223',
    textAlign: 'center',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: '#BABFC3',
    width: 180,
    height: 36,
    alignSelf: 'center',
    paddingTop: 5,
  },
  subTitleMenu: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 16,
    lineHeight: 24,
    color: '#6E757C',
    marginLeft: 34,
    marginRight: 32,
    marginBottom: 0,
    marginTop: 9,
  },
  ml4: {
    marginLeft: 4,
  },
  starsPopup: {
    width: 210,
    height: 38,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
    borderRadius: 5,
  },
  clearRating: {
    width: 96,
    height: 38,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
    borderRadius: 5,
  },
  clearRatingText: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 20,
    color: '#202223',
    marginTop: 10,
    marginLeft: 10,
  },
  star: {
    height: 15,
    width: 15,
    marginRight: 8,
  },
  number: {
    fontFamily: 'OpenSans_400Regular',
    fontSize: 14,
    lineHeight: 24,
    color: '#6D7175',
    marginLeft: 4,
    marginTop: 3,
  },
  loadingContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: Platform.OS === 'web' ? '50vh' : '100%',
  },
  ratingContainer: { display: 'flex', width: 120, marginBottom: 8 },
  wrapper: {
    paddingTop: 16,
    height: '100%',
    [mediaQuery.forMobile]: {
      paddingTop: 0,
    },
  },
  statsText: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 16,
    lineHeight: 24,
  },
  rateButtonWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    [mediaQuery.forMobile]: {
      flexDirection: 'column',
    },
  },
});
