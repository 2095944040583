import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const PlusCircleIcon = (props: SvgProps) => (
  <Svg width={16} height={17} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 8.227a1 1 0 0 1-1 1H9v3a1 1 0 0 1-2 0v-3H4a1 1 0 1 1 0-2h3v-3a1 1 0 0 1 2 0v3h3a1 1 0 0 1 1 1Zm-5-8a8 8 0 1 0 0 16 8 8 0 0 0 0-16Z"
      fill="#0D1738"
    />
  </Svg>
);

export { PlusCircleIcon };
