import { TitleBox } from '@components/account/title-box';
import { ContentBox } from '@components/general/layouts/content-box';
import { MemberInfoEdit } from '@components/org/members/member-view/member-info/MemberInfoEdit';
import { ErrorBox } from '@components/sign-up/error-box';
import { AccountType, useAddEmployeeMutation } from '@gql/generated/generated';
import { newMembervalidationSchema } from '@utils/validators';
import { Formik } from 'formik';
import { useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';

import { styles } from './styles';

const AddNewMember = () => {
  const navigate = useNavigate();
  const [addEmployee] = useAddEmployeeMutation();
  const [error, setError] = useState<string | undefined>();
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    country: '',
    jobPosition: '',
    accountType: AccountType.EMPLOYEE,
    profilePhotoUrl: '',
  };

  const onSubmit = async (values: typeof initialValues) => {
    const resp = await addEmployee({
      variables: {
        input: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          accountType: values.accountType,
          jobPosition: values.jobPosition,
          phoneNumber: values.phoneNumber,
          profilePhotoUrl: values.profilePhotoUrl,
          country: values.country,
        },
      },
    });

    if (resp.data?.addEmployee.__typename === 'Success') {
      // TODO Posthog tracking here for user creation

      navigate(-1);
      setError(undefined);
    }

    if (resp.data?.addEmployee.__typename === 'ValidationError') {
      setError(resp.data?.addEmployee.message);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={newMembervalidationSchema}>
      {({ handleSubmit }) => (
        <ContentBox>
          <TitleBox title="Add a New Member" backTitle="Back to All Members" onPressBack={() => navigate(-1)} />
          {error && <ErrorBox error={error} />}
          <MemberInfoEdit needButtonLayout={false} />
          <TouchableOpacity onPress={() => handleSubmit()} style={styles.buttonContainer}>
            <View style={styles.button}>
              <Text style={styles.buttonText}>Send Invitation</Text>
            </View>
          </TouchableOpacity>
        </ContentBox>
      )}
    </Formik>
  );
};

export { AddNewMember };
