import { Roadmap, RoadmapPulseTemplateQuestionAnswerType, UserType } from '@gql/generated/generated';

export enum PostType {
  announcement = 'Announcement',
  news = 'News',
  poll = 'Poll',
}

export interface IComment {
  id: string;
  userName: string;
  avatar?: string;
  userJobTitle: string;
  likedBy: string[];
  totalLikes: number;
  replies?: IComment[];
  text: string;
  rating?: number;
  dateCreated: string;
  commentedItemId: string;
  commentedItemType: string;
  userAvatarIndex: number;
  userId: string;
  ratingDate: string;
  userProfilePhotoUrl: string;
}

export interface IReply {
  id: string;
  userName: string;
  avatar?: string;
  userJobTitle: string;
  totalLikes: number;
  likedBy: string[];
  replies?: IComment[];
  text: string;
  dateCreated?: string;
}

export enum TrainingStatus {
  publish = 'Publish',
}

export enum LessonType {
  document = 'document',
  video = 'video',
}

export interface IVideoFile {
  duration: string;
  src: string;
}

export interface ITrainingLesson {
  lessonTitle: string;
  lessonDescription: string;
  lessonType: LessonType[];
  isPreviewLesson: boolean;
  userType: UserType;
  durationInSeconds: number;
  videoTitle?: string;
  videoFile?: IVideoFile[];
  documentTitle?: string;
  documentFile?: string;
  lessonContent: string;
  downloadFile?: string;
}

export interface ITrainingModule {
  lessons: ITrainingLesson[];
  moduleDescription: string;
  moduleTitle: string;
}

export interface ITrainingInstructor {
  id: string;
  name: string;
  description: string;
  photo?: string[];
  socials: {
    facebook?: string;
    instagram?: string;
    linkedin?: string;
    twitter?: string;
  };
  allCoursesAverageStars?: number;
  totalReviewCount?: number;
  totalStudentCount?: number;
  courses: { averageStars: number; courseId: string }[];
}

export interface ITraining {
  id: string;
  title: string;
  shortDescription: string;
  overview: string;
  requirements: string;
  whoIsCourseFor: string;
  status: TrainingStatus;
  coverImage: string;
  promotionalVideo?: string;
  categories: string[];
  jobRoles: string[];
  userType: UserType[];
  learningObjectives: string[];
  modules: ITrainingModule[];
  instructors?: ITrainingInstructor[];
  numberOfEnrolledUsers: number | null;
  isCurrentUserEnrolled: boolean | null;
  durationInSeconds: number;
  date: number;
  averageStars: number;
  shareCount: number;
  comments: IComment[];
}

export enum PageVariant {
  event = 'Event',
  content = 'Content',
  training = 'Training',
  saved = 'Saved',
  assignment = 'Assignment',
  member = 'Member',
}

export interface IEvent {
  id: number;
  title: string;
  calendlyLink: string;
  description: string;
  category: string[];
  status: string;
  location: string;
  link: string;
  speakers: ITrainingInstructor[];
  organizer: string;
  dateFrom: string;
  dateTo: string;
  summary?: string;
  participants?: string[];
  coverImage: string;
  jobRoles: string[];
  userType: string[];
  isUpdated: boolean;
  type: string;
  seoTags?: string[];
  isHighlighted?: boolean;
  highlightedRank?: number;
}

export interface IContent {
  id: string;
  authorId: string;
  authorName: string;
  averageStars: number;
  category: string[];
  comments: IComment[];
  contentType: string[];
  coverImage: string;
  date: string;
  description: string;
  isUpdated: boolean;
  jobRoles: string[];
  readLength: number;
  shareCount: number;
  status: TrainingStatus;
  summary: string;
  timestamp: string;
  title: string;
  userType: UserType[];
  views: number;
}

export enum ModalContent {
  closed,
  download,
  share,
  save,
}

export enum FilterOptions {
  sortBy = 'Sort By',
  category = 'Category',
  date = 'Date',
  popularity = 'Popularity',
  memberType = 'Member Type',
  length = 'Length',
  jobRole = 'Job Role',
  contentType = 'Content Type',
  eventType = 'Event Type',
  duration = 'Duration',
}

export interface BillingAddress {
  houseNumber: string;
  addressOne: string;
  addressTwo: string;
  city: string;
  country: string;
  postCode: string;
}

export interface PaymentMethodInfo {
  companyName: string;
  postCode: string;
}

export type IFieldTypes =
  | 'text'
  | 'multiple_text'
  | 'textarea'
  | 'number'
  | 'select'
  | 'multiple_select'
  | 'checkbox'
  | 'multiple_checkbox'
  | 'radio'
  | 'multiple_radio'
  | 'date'
  | 'currency'
  | 'email'
  | 'phone_number';

export interface IQuestion {
  id: string;
  question: string;
  answer?: string | { value: string } | null;
  type?: IFieldTypes;
  answerType?: RoadmapPulseTemplateQuestionAnswerType;
  placeholder?: string;
  description?: string | null;
  subcategory?: string | null;
  options: { label: string; value: string }[];
  title?: string;
  subtitle?: string;
}

export interface ISubmodule {
  id: string;
  name: string;
  questions: IQuestion[];
}

export interface IRoadMap {
  id: string;
  name: string;
  submodules: ISubmodule[];
}

export interface IPartners {
  id: string;
  categories: string[];
  content: string;
  date: string;
  links: {
    facebook?: string;
    instagram?: string;
    linkedin?: string;
    twitter?: string;
    website?: string;
  };
  logo?: {
    file: string;
    title: string;
  };
  partnerType: string;
  specialOffer?: {
    details: string;
    link: string;
  };
  status: string;
  summary: string;
  title: string;
}
