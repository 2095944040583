import { ButtonGeneral, ButtonType } from '@components/general/button-general';
import { Input } from '@components/general/form/input';
import { AuthWrapper } from '@components/sign-up/auth-wrapper';
import { ErrorBox } from '@components/sign-up/error-box';
import { useSendPasswordResetEmailMutation } from '@gql/generated/generated';
import { getErrorMessage } from '@utils/misc';
import { AuthError } from 'firebase/auth';
import { Formik, FormikValues } from 'formik';
import React, { useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-native';
import * as Yup from 'yup';

import { ids, styles } from './style';

interface Props {
  onDone: (email: string) => void;
}

const EnterEmail = ({ onDone }: Props) => {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [sendPasswordResetEmail] = useSendPasswordResetEmailMutation();

  const onSubmit = async (values: FormikValues) => {
    setIsLoading(true);
    try {
      await sendPasswordResetEmail({
        variables: { input: { email: values.email } },
      });
      setIsLoading(false);
      onDone(values.email);
    } catch (error: unknown) {
      const e = error as AuthError;
      setErrorMsg(getErrorMessage(e));
      setIsLoading(false);
    }
  };

  return (
    <AuthWrapper title="Forgot password?" subtitle="Don’t worry! We will send you reset instructions." onPressBack={() => navigate(-1)}>
      <View>
        <Formik
          initialValues={{ email: '' }}
          onSubmit={onSubmit}
          validationSchema={Yup.object().shape({
            email: Yup.string().email('Invalid email').required('This field is required'),
          })}
        >
          {({ values, handleChange, handleSubmit, touched, errors }) => (
            <View style={styles.subContainer} dataSet={{ media: ids.subContainer }}>
              <View style={styles.inputWrap}>
                {errorMsg !== '' ? <ErrorBox error={errorMsg} /> : null}
                <Input
                  keyboardType="email-address"
                  placeholder="Email Address"
                  value={values.email}
                  onChangeText={handleChange('email')}
                  error={touched.email && errors.email ? (errors.email as string) : undefined}
                />
              </View>
              <ButtonGeneral
                bold
                label="Reset Password"
                isLoading={isLoading}
                onPress={handleSubmit}
                type={ButtonType.user}
                style={styles.submitButton}
              />
            </View>
          )}
        </Formik>
        <View style={styles.linkWrap}>
          <TouchableOpacity onPress={() => navigate(-1)}>
            <Text style={styles.registerLink}>{'< Back to login'}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </AuthWrapper>
  );
};

export { EnterEmail };
