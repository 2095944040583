import { Col } from '@components/general/col';
import { UserType } from '@gql/generated/generated';
import { Checkbox } from '@shopify/polaris';
import { textStyles } from '@styles/text';
import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { Text } from 'react-native';

interface AccessSelectProps {
  formikFieldBase: string;
}

const AccessSelect = ({ formikFieldBase }: AccessSelectProps) => {
  const { setFieldValue, values } = useFormikContext<any>();

  const fieldName = `${formikFieldBase}.userType`;
  const selected = getIn(values, fieldName);

  const handleAccessSelect = (value: UserType) => {
    if (!selected) {
      setFieldValue(fieldName, [value]);
      return;
    }
    // TODO: Add module and lesson details
    if (selected?.includes(value)) {
      setFieldValue(
        fieldName,
        selected.filter((item: UserType) => item !== value)
      );
    } else {
      setFieldValue(fieldName, [...selected, value]);
    }
  };

  return (
    <Col>
      <Text style={[textStyles.semiBoldSmall, { marginBottom: 8 }]}>Access</Text>
      <Checkbox label="Free" checked={selected?.includes(UserType.FREE)} onChange={() => handleAccessSelect(UserType.FREE)} />
      <Checkbox label="Plus" checked={selected?.includes(UserType.PLUS)} onChange={() => handleAccessSelect(UserType.PLUS)} />
      <Checkbox label="Club" checked={selected?.includes(UserType.CLUB)} onChange={() => handleAccessSelect(UserType.CLUB)} />
    </Col>
  );
};

export { AccessSelect };
