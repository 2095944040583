import { Checkbox, TextField } from '@shopify/polaris';
import { Field, FormikValues, useFormikContext } from 'formik';
import { StyleProp, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { useNavigate } from 'react-router-native';

import { styles } from './styles';

interface ButtonProps {
  buttonContainerStyle?: StyleProp<ViewStyle>;
  buttonTextStyle?: StyleProp<TextStyle>;
  text: string;
  onPress: () => void;
}

const Button = ({ buttonContainerStyle, buttonTextStyle, text, onPress }: ButtonProps) => {
  return (
    <TouchableOpacity style={[styles.button, buttonContainerStyle]} onPress={onPress}>
      <Text style={[styles.buttonText, buttonTextStyle]}>{text}</Text>
    </TouchableOpacity>
  );
};

const DeleteAccountReasons = () => {
  const navigate = useNavigate();
  const { handleSubmit }: FormikValues = useFormikContext();

  const reasonsList = [
    { value: 'I don’t find it useful', label: 'I don’t find it useful' },
    {
      value: 'I don’t understand how it works',
      label: 'I don’t understand how it works',
    },
    {
      value: 'I am worried about the safety of my data',
      label: 'I am worried about the safety of my data',
    },
    {
      value: 'Customer support could not clarify my questions or resolve my issues',
      label: 'Customer support could not clarify my questions or resolve my issues',
    },
    {
      value: 'The contents are not what am I looking for',
      label: 'The contents are not what am I looking for',
    },
    {
      value: 'The pricing is too high',
      label: 'The pricing is too high',
    },
  ];

  return (
    <>
      <Field name="reasons">
        {({ field, form }: FormikValues) => (
          <>
            {reasonsList.map((option) => (
              <View key={option.value} style={styles.reason}>
                <Checkbox
                  label={option.label}
                  checked={field.value.includes(option.value)}
                  onChange={() => {
                    const nextValue = field.value.includes(option.value)
                      ? field.value.filter((value: string) => value !== option.value)
                      : [...field.value, option.value];
                    form.setFieldValue(field.name, nextValue);
                  }}
                  labelHidden
                />
                <Text style={styles.reasonText}>{option.label}</Text>
              </View>
            ))}
          </>
        )}
      </Field>
      <Field name="otherReason">
        {({ field, form }: FormikValues) => (
          <View
            style={{
              marginTop: 10,
              maxWidth: '62.5%',
            }}
          >
            <Text style={[styles.reasonText, { marginLeft: 0 }]}>Please list any other reasons why you might be leaving:</Text>
            <View style={{ marginTop: 4 }}>
              <TextField
                label="otherReason"
                value={field.value}
                onChange={(value) => {
                  form.setFieldValue(field.name, value);
                }}
                autoComplete="off"
                labelHidden
                multiline={5}
                maxHeight={120}
              />
            </View>
          </View>
        )}
      </Field>

      <View style={styles.buttonContainer}>
        <Button text="Cancel" onPress={() => navigate('/account/settings/profile')} buttonContainerStyle={styles.cancelButtonStyle} />
        <Button
          text="Delete your Account"
          onPress={handleSubmit}
          buttonContainerStyle={styles.deleteButton}
          buttonTextStyle={styles.deleteButtonText}
        />
      </View>
    </>
  );
};

export { DeleteAccountReasons };
