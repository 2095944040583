import React from 'react';
import { Text, View } from 'react-native';

import { Title } from './Title';
import { styles } from './style';

export type Role = 'todo' | 'inprogress' | 'completed';

export type TColumn = {
  id: Role;
  role: Role;
  title: string;
  testId?: string;
};

type Props = TColumn & {
  total: number;
  children?: React.ReactNode;
};

export const Column = ({ role, title, total, children, testId }: Props) => (
  <View style={styles.wrap} testID={testId ?? ''}>
    <View style={styles.titleWrap}>
      <Title role={role}>{title}</Title>
      <Text style={styles.countText}>{total}</Text>
    </View>
    {children}
  </View>
);
