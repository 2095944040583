import React from 'react';
import { KeyboardAvoidingView, Modal, Platform, Pressable, TouchableWithoutFeedback, View, ViewStyle } from 'react-native';

import { styles } from './style';

export enum ModalPosition {
  top,
  center,
  bottom,
}

interface Props {
  isVisible: boolean;
  children: React.ReactNode;
  onClose?: () => void;
  position?: ModalPosition;
  contentStyle?: ViewStyle;
  testID?: string;
}

const ModalWrapper = ({ testID = '', isVisible, children, onClose, position, contentStyle }: Props) => (
  <Modal testID={testID} visible={isVisible} animationType="fade" transparent>
    {Platform.OS === 'web' ? (
      <TouchableWithoutFeedback onPress={onClose}>
        <View style={[styles.wrap, position === ModalPosition.top && styles.top, position === ModalPosition.bottom && styles.bottom]}>
          <TouchableWithoutFeedback>
            <View style={[styles.content, contentStyle && contentStyle]}>{children}</View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    ) : (
      <KeyboardAvoidingView behavior="padding">
        <Pressable
          onPress={onClose}
          style={[styles.wrap, position === ModalPosition.top && styles.top, position === ModalPosition.bottom && styles.bottom]}
        >
          <View style={[styles.content, contentStyle && contentStyle]}>{children}</View>
        </Pressable>
      </KeyboardAvoidingView>
    )}
  </Modal>
);

export { ModalWrapper };
