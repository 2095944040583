import * as React from 'react';

const LinkIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#5C5F62"
      d="M4.534 16a4.507 4.507 0 0 1-3.208-1.329 4.54 4.54 0 0 1 0-6.414l1.966-1.964a.999.999 0 1 1 1.414 1.414L2.74 9.671a2.54 2.54 0 0 0 0 3.586c.96.959 2.63.958 3.587 0l1.966-1.964a1 1 0 1 1 1.415 1.414l-1.966 1.964A4.503 4.503 0 0 1 4.534 16zM12 10a.999.999 0 0 1-.707-1.707l1.966-1.964a2.54 2.54 0 0 0 0-3.586c-.961-.959-2.631-.957-3.587 0L7.707 4.707a1 1 0 1 1-1.415-1.414l1.966-1.964A4.503 4.503 0 0 1 11.466 0c1.21 0 2.35.472 3.208 1.329a4.541 4.541 0 0 1 0 6.414l-1.966 1.964A.997.997 0 0 1 12 10zm-6.002 1a.999.999 0 0 1-.707-1.707l4-4a1 1 0 1 1 1.416 1.414l-4.001 4a1 1 0 0 1-.708.293z"
    />
  </svg>
);

export { LinkIcon };
