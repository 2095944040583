import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  textBoldDark: {
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 20,
    lineHeight: 28,
    color: '#0D1738',
  },
});
