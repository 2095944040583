import { Image, ImageSourcePropType, Text, View } from 'react-native';

import { styles } from './style';

interface Props {
  avatar?: ImageSourcePropType;
  firstName: string;
  lastName: string;
  tag?: string;
  status?: boolean;
  jobTitle: string;
  isEmployer: boolean;
  handleEditMode?: () => void;
}

const MemberInfo = ({ avatar, firstName, lastName, tag, jobTitle, status, isEmployer, handleEditMode }: Props) => {
  return (
    <View style={styles.memberCardWrapper}>
      {/* image and status container */}
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          flex: 1,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <View style={styles.avatarContainer}>
            <Image source={avatar ? avatar : require('/assets/img/Avatar.png')} style={styles.avatar} />
            <View style={[styles.status, status ? styles.online : styles.away]} />
          </View>
          {/* name, job, title */}
          <View style={styles.infoTextContainer}>
            <View style={styles.nameContainer}>
              <Text style={styles.textContainer}>{`${firstName} ${lastName}`}</Text>
              {tag === 'Admin' && (
                <View style={tag ? styles.tagContainer : null}>
                  <Text style={styles.tagTitle}>{tag}</Text>
                </View>
              )}
            </View>
            <Text style={styles.jobTitle}>{jobTitle}</Text>
          </View>
        </View>
        {isEmployer ? (
          <Text style={styles.editButton} onPress={handleEditMode}>
            Edit
          </Text>
        ) : null}
      </View>
    </View>
  );
};

export { MemberInfo };
