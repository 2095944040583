import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { ISvgProps } from 'types';

interface ClockIconProps extends ISvgProps {
  backgroundColor?: string;
}

const ClockIconSmall = ({ backgroundColor = '#EBEDF0', ...props }: ClockIconProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <Path
      fill="#0D1738"
      d="M7.992 1.334a6.663 6.663 0 0 0-6.66 6.667c0 3.68 2.98 6.666 6.66 6.666a6.67 6.67 0 0 0 6.673-6.666 6.67 6.67 0 0 0-6.673-6.667zm.007 12a5.332 5.332 0 0 1-5.334-5.333A5.332 5.332 0 0 1 8 2.667a5.332 5.332 0 0 1 5.333 5.334A5.332 5.332 0 0 1 8 13.334z"
    />
    <Path fill="#0D1738" d="M8.332 4.666h-1v4l3.5 2.1.5-.82-3-1.78v-3.5z" />
  </Svg>
);

export { ClockIconSmall };
