import { SearchIcon } from '@components/general/icons/SearchIcon';
import { TextField } from '@shopify/polaris';
import { useCallback } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { styles } from './style';

interface Props {
  setSearchTerm: (value: string) => void;
  searchTerm?: string | null;
  searchBarContainerStyles?: StyleProp<ViewStyle>;
  placeholder?: string;
}

const SearchBar = ({ setSearchTerm, searchTerm, searchBarContainerStyles, placeholder = 'Search' }: Props) => {
  const handleChange = useCallback((newValue: string) => setSearchTerm(newValue), [setSearchTerm]);

  return (
    <View style={[styles.headerSearch, searchBarContainerStyles]}>
      <TextField
        prefix={<SearchIcon style={styles.searchIcon} />}
        placeholder={placeholder}
        label="Search"
        labelHidden
        value={searchTerm || ''}
        onChange={handleChange}
        autoComplete="off"
      />
    </View>
  );
};

export { SearchBar };
