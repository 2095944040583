import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  upperSectionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 36,
  },
  sidePanelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  sidePanelText: {
    color: '#0D1738',
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    lineHeight: 20,
  },
  imageContainer: {
    padding: 20,
    borderRadius: 4,
    borderColor: '#EDEEEF',
    borderWidth: 1,
  },
  mobileImage: {
    width: '100%',
    height: 183,
  },
  mobileInner: {
    padding: 20,
  },
  title: {
    color: '#0D1738',
    fontFamily: 'OpenSans_600SemiBold',
    fontSize: 14,
    marginTop: 16,
    marginBottom: 4,
  },
  industryText: {
    color: '#0D1738',
    fontFamily: 'OpenSans_400Regular',
    fontSize: 12,
    marginBottom: 4,
  },
  socialsWrap: {
    marginTop: 4,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  pillWrap: {
    flexDirection: 'row',
  },
  button: {
    width: 'fit-content',
    paddingVertical: 16,
    marginBottom: 16,
    marginTop: 16,
  },
  buttonText: {
    color: '#FFFFFF',
    fontSize: 14,
    fontFamily: 'OpenSans_400Regular',
    lineHeight: 20,
  },
  specialOfferPill: {
    marginBottom: 16,
  },
});
