import { FormikInput } from '@components/back-office/editor/formik-input';
import { FormikRichText } from '@components/back-office/editor/formik-rich-text';
import { FormikSaveBar } from '@components/back-office/editor/formik-save-bar';
import { ContentContainer } from '@components/back-office/roadmap/score-ranking';
import { ContentBox } from '@components/general/layouts/content-box';
import { CompleteModal } from '@components/general/modals/complete-modal';
import { useGetRecommendationDetailsQuery, useGetRoadmapTemplateQuery, useUpdateRecommendationsMutationMutation } from '@gql/generated/generated';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Image, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate, useParams } from 'react-router-native';

import { styles } from './style';
import { Params, getCategoriesFromRoadmapTemplate, getSectionIdFromParams, getSubcategoriesFromRoadmapTemplate } from './utils';

const TitleFromParams = ({ categoryId, subcategoryId, score }: Params) => {
  const [template, setTemplate] = useState<any>(null);
  const [categoryName, setCategoryName] = useState<string>('');
  const [subcategoryName, setSubcategoryName] = useState<string>('');

  const { data: templateData } = useGetRoadmapTemplateQuery();

  useEffect(() => {
    if (templateData?.getRoadmapTemplate?.__typename === 'RoadmapTemplate') {
      setTemplate(templateData.getRoadmapTemplate);
    }
  }, [templateData]);

  useEffect(() => {
    if (template?.pulse) {
      const categories = getCategoriesFromRoadmapTemplate(template);
      const subcategories = getSubcategoriesFromRoadmapTemplate(template, categoryId);

      const categoryName = categories.find((category) => category.id === categoryId)?.content;
      const subcategoryName = subcategories.find((subcategory) => subcategory.id === subcategoryId)?.subcategory;

      if (categoryName && subcategoryName) {
        setCategoryName(categoryName);
        setSubcategoryName(subcategoryName);
      }
    }
  }, [template]);

  return categoryName === '' || subcategoryName === '' ? null : <>{[categoryName, subcategoryName, `Score ${score}`].join(' > ')}</>;
};

const Loader = () => (
  <View
    style={{
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <ActivityIndicator size="large" color="#213470" />
  </View>
);

export const AddEditRecommendations = () => {
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [updateRecommendations] = useUpdateRecommendationsMutationMutation();

  const params = useParams() as Params;
  const sectionId = getSectionIdFromParams(params);
  const { score } = params;

  const { data: recommendationDetailsData, loading: loadingRecommendationDetails } = useGetRecommendationDetailsQuery({
    variables: { sectionId },
  });

  if (loadingRecommendationDetails) {
    return <Loader />;
  }

  const contents = recommendationDetailsData?.getRecommendationDetails.recommendations?.filter((el) => el?.type === 'content') || [];

  const trainings = recommendationDetailsData?.getRecommendationDetails.recommendations?.filter((el) => el?.type === 'trainings') || [];

  const feedback = recommendationDetailsData?.getRecommendationDetails?.feedback?.[0];

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CompleteModal
        isVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        title="The changes have been saved!"
        description="You can choose to continue adding/editing content  or go back to all score ranking page."
        primaryButtonTitle="Back to the main page"
        secondaryButtonTitle="Continue editing"
        onPrimaryButtonPress={() => navigate('/Roadmap/score-ranking')}
        // TODO: on Secondary button should continue editing
        onSecondaryButtonPress={() => setModalVisible(false)}
        animationType="fade"
      />
      <Formik
        enableReinitialize
        initialValues={{
          title: feedback?.title ?? '',
          description: feedback?.text ?? '',
        }}
        onSubmit={async (values) => {
          try {
            const { title, description } = values;
            await updateRecommendations({
              variables: {
                input: {
                  sectionId,
                  feedback: { title, text: description, score: +score },
                },
              },
            });
          } catch (error) {
            console.log({ error });
          }
          setModalVisible(true);
        }}
      >
        <>
          <FormikSaveBar />

          {/* header */}
          <View style={styles.header}>
            <View style={{ flexDirection: 'row' }}>
              <TouchableOpacity
                onPress={() => {
                  navigate(-1);
                }}
                style={styles.headerBackBtn}
              >
                <Image source={require('/assets/img/ArrowLeft.png')} style={styles.headerArrowLeft} />
              </TouchableOpacity>
              <Text style={styles.headerMainTitle}>
                <TitleFromParams {...params} />
              </Text>
            </View>
          </View>

          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <ContentBox style={{ padding: 20 }}>
                <ContentContainer header="Content" indexName="content" count={contents?.length ?? 0} data={contents} />
                <ContentContainer header="Trainings" indexName="trainings" count={trainings?.length ?? 0} data={trainings || []} />
              </ContentBox>

              <ContentBox style={{ marginTop: 20, padding: 20 }}>
                <View>
                  <Text style={styles.textBoldDark}>Score Details</Text>
                </View>

                <View style={{ marginTop: 20, marginBottom: 16 }}>
                  <FormikInput fieldName="title" label="Title" placeholder="Enter the title" />
                </View>

                <FormikRichText fieldName="description" title="Description" subtitle="" placeholder="Write about the score details" />
              </ContentBox>
            </View>
          </View>
        </>
      </Formik>
    </View>
  );
};
