import { InputError } from '@components/general/form/input-error';
import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { styles } from './style';

interface RadioItemProps {
  value: boolean;
  onChange: () => void;
  label?: string;
}

const RadioItem = ({ value, onChange, label }: RadioItemProps) => (
  <TouchableOpacity style={styles.wrap} onPress={onChange}>
    <View style={[styles.radioBorder, value && styles.active]}>{value && <View style={styles.inner} />}</View>
    {label ? <Text style={styles.title}>{label}</Text> : null}
  </TouchableOpacity>
);

interface MultipleRadioButtonProps {
  options: { label: string; value: string }[];
  value: string;
  onChange: (value: string) => void;
}

const MultipleRadioButton = ({ options, value, onChange }: MultipleRadioButtonProps) => (
  <View style={styles.multipleRadio}>
    {options.map((item) => (
      <View style={styles.radioItem}>
        <RadioItem value={item.value === value} onChange={() => onChange(item.value)} label={item.label} />
      </View>
    ))}
  </View>
);

export interface Props {
  value: boolean | string;
  onChange: (value?: string) => void;
  label?: string;
  error?: string;
  options?: { label: string; value: string }[];
}

const RadioButton = ({ error, value, onChange, label, options }: Props) => (
  <View>
    {options?.length ? (
      <MultipleRadioButton options={options} value={value as string} onChange={(value) => onChange(value)} />
    ) : (
      <RadioItem value={Boolean(value)} onChange={onChange} label={label} />
    )}
    {error ? <InputError error={error} /> : null}
  </View>
);

export { RadioButton };
