import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SearchIcon = (props: SvgProps) => (
  <Svg width={17} height={17} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.889 10.227a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm9.707 4.293-4.82-4.82a5.968 5.968 0 0 0 1.113-3.473 6 6 0 0 0-12 0 6 6 0 0 0 6 6 5.968 5.968 0 0 0 3.473-1.113l4.82 4.82a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414Z"
      fill="#5C5F62"
    />
  </Svg>
);

export { SearchIcon };
