import { mediaQuery } from '@utils/misc';
import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  wrap: {
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
    borderBottomWidth: 1,
    borderBottomColor: '#EDEEEF',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [mediaQuery.forMobile]: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
      flexDirection: 'column',
    },
    [mediaQuery.forTabletOnly]: {
      overflow: 'scroll',
      ...(Platform.OS === 'web' && { gap: 16 }),
    },
  },
});
