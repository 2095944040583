import { TableDropdown } from '@components/back-office/tables/table-dropdown';

import { useQueryFilters } from '../useGridFilters';

const userStatusItems = [
  { id: 'Active', content: 'Active' },
  { id: 'Inactive', content: 'Inactive' },
  { id: 'Suspend', content: 'Suspended' },
  { id: 'Delete', content: 'Deleted' },
];

export const AccountStatusFilter = () => {
  const { filters, setFilterValue } = useQueryFilters(['accountStatus']);

  return (
    <TableDropdown
      placeholder={filters.accountStatus ? filters.accountStatus : 'Status'}
      items={userStatusItems}
      onSelectItem={(id: string) => setFilterValue('accountStatus', id)}
      onReset={filters.accountStatus ? () => setFilterValue('accountStatus', null) : undefined}
      isSelected={!!filters.accountStatus}
    />
  );
};
