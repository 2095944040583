import { RightArrow } from '@components/general/icons';
import { RoadmapQuestion, Section } from '@gql/generated/generated';
import { ActionListItemDescriptor, Icon } from '@shopify/polaris';
import { Dispatch, SetStateAction } from 'react';

export const formatPulseCategoriesAsActionListItems = ({
  sections,
  setSelectedCategory,
}: {
  sections: Section[];
  setSelectedCategory: Dispatch<SetStateAction<string>>;
}) => {
  type FilterOutputType = Section & {
    id: string;
    name: string;
    maxScore?: number;
  };

  const isValid = (section: Section): section is FilterOutputType => !!section.id && !!section.name && section.name !== 'About You';

  const mapper = ({ id, name }: FilterOutputType): ActionListItemDescriptor => ({
    id,
    content: name,
    onAction: () => {
      setSelectedCategory(id);
    },
    suffix: <Icon source={RightArrow} />,
  });

  return sections.filter(isValid).map(mapper) || [];
};

export const formatSubcategoriesAsActionListItems = (subcategories?: RoadmapQuestion[]): ActionListItemDescriptor[] => {
  type FilterOutputType = RoadmapQuestion & {
    title: string;
  };

  const isValid = (question: RoadmapQuestion): question is FilterOutputType => !!question?.subcategory;

  return (
    subcategories?.filter(isValid).map(({ id, subcategory }) => ({
      id,
      content: subcategory ?? '',
    })) || []
  );
};
