import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const MapPinFill = (props: SvgProps) => (
  <Svg width={13} height={16} fill="none" {...props}>
    <Path
      fill="#5C5F62"
      fillRule="evenodd"
      d="M6.98 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Zm0-8c-3.31 0-6 2.87-6 6.4 0 6 5.4 9.35 5.63 9.49.11.07.24.11.37.11s.26-.04.37-.11c.23-.14 5.63-3.49 5.63-9.49 0-3.53-2.69-6.4-6-6.4Z"
      clipRule="evenodd"
    />
  </Svg>
);

export { MapPinFill };
