import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  header: {
    padding: 20,
    borderBottomColor: '#C9CCCF',
    borderBottomWidth: 0.5,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    color: '#0D1738',
    fontSize: 20,
    fontFamily: 'OpenSans_600SemiBold',
  },
  subtitle: {
    color: '#6D7175',
    fontSize: 14,
    marginTop: 8,
    fontFamily: 'OpenSans_400Regular',
  },
  footer: {
    borderTopColor: '#C9CCCF',
    borderTopWidth: 0.5,
    padding: 14,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});
